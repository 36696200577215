import { Gesture } from '@use-gesture/vanilla';
import isEqual from 'lodash/isEqual';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, scan } from 'rxjs/operators';

import { MODE_SWITCH_CLICK_THRESHOLD } from '../../common/Globals';
import Utils from '../../common/Utils';
import type { SelectedHotSpot } from '../../types/internal';
import GestureController from '../GestureController';
import type { DragEvent, ExtDragEvent, MoveEvent } from '../GestureController/types';

export default class GuestController extends GestureController {
  protected addEventListeners() {
    const dragSubject$ = new Subject<DragEvent>();
    const moveSubject$ = new Subject<MoveEvent>();

    let isDragging = false;
    const gesture = new Gesture(
      this.canvas,
      {
        onDragStart: (state: any) => {
          isDragging = true;
          dragSubject$.next({ xy: state.xy, type: 'start', timestamp: Date.now() });
        },
        onDragEnd: (state: any) => {
          dragSubject$.next({
            xy: state.xy,
            type: 'end',
            timestamp: Date.now(),
            initial: state.initial,
          });
          isDragging = false;
        },
        onMove: (state: any) => {
          moveSubject$.next({ xy: state.xy, timestamp: Date.now() });
        },
      },
      {
        transform: ([x, y]) => [x - (this.boundingRect.left || 0), y - (this.boundingRect.top || 0)],
      }
    );

    const moveSubscription = moveSubject$
      .pipe(
        filter(() => !this.isTransitioning && !isDragging),
        distinctUntilChanged((prev, current) => isEqual(prev.xy, current.xy))
      )
      .subscribe(({ xy: [x, y] }) => {
        const selectedHotSpot = this.getHotSpotOnPosition({ x, y });
        this.setSelectedHotSpot(selectedHotSpot);
      });

    const dragSubscription = dragSubject$
      .pipe(
        filter(() => !this.isTransitioning),
        distinctUntilChanged((prev, current) => prev.type === current.type && isEqual(prev.xy, current.xy)),
        scan<DragEvent, ExtDragEvent>(
          (prev, current) => {
            if (current.type === 'start' || prev.type === 'end') {
              return {
                ...current,
                sceneSpeed: { pitch: 0, yaw: 0 },
                deltaPos: { pitch: 0, yaw: 0 },
                isMoved: false,
              };
            }

            const currentPos = Utils.xyToPos(current.xy);
            const lastPos = Utils.xyToPos(prev.xy);
            const deltaPos = this.getScenePosDelta(currentPos, lastPos);
            const sceneSpeed = this.getSceneSpeed(deltaPos, current.timestamp - prev.timestamp, prev.sceneSpeed);

            return {
              ...current,
              sceneSpeed,
              deltaPos,
              isMoved: lastPos.x !== currentPos.x || lastPos.y !== currentPos.y,
            };
          },
          {
            type: 'end',
            xy: [0, 0],
            timestamp: Date.now(),
            initial: [0, 0],
            sceneSpeed: { pitch: 0, yaw: 0 },
            deltaPos: { pitch: 0, yaw: 0 },
            isMoved: false,
          }
        ),
        filter(({ type, isMoved }) => type !== 'drag' || isMoved)
      )
      .subscribe((current) => {
        this.onDrag(current);
      });

    const cleanUp = () => {
      this.selectedHotSpot = { index: -1 };
      moveSubscription.unsubscribe();
      dragSubscription.unsubscribe();
      gesture.destroy();
    };

    return cleanUp;
  }

  protected onDrag(current: ExtDragEvent) {
    const currentPos = Utils.xyToPos(current.xy);
    switch (current.type) {
      case 'start': {
        const selectedHotSpot = this.getHotSpotOnPosition(currentPos);
        const hotSpot = this.hotSpots[selectedHotSpot.index];

        this.setSelectedHotSpot(hotSpot?.type === 'hotspot-info' ? selectedHotSpot : { index: -1 });
        break;
      }
      case 'end': {
        const initialPos = Utils.xyToPos(current.initial);
        const distance = Math.sqrt((currentPos.x - initialPos.x) ** 2 + (currentPos.y - initialPos.y) ** 2);
        if (this.selectedHotSpot.index > -1 && distance < MODE_SWITCH_CLICK_THRESHOLD) {
          const hotSpot = this.hotSpots[this.selectedHotSpot.index];

          if (hotSpot?.type === 'hotspot-info') {
            this.clickSelectedHotSpot();
          }
        }

        break;
      }
      default:
        console.warn('type is not supported', current.type);
    }
  }

  protected setSelectedHotSpot(selectedHotSpot: SelectedHotSpot) {
    if (
      this.selectedHotSpot.index !== selectedHotSpot.index ||
      this.selectedHotSpot.isArrow !== selectedHotSpot.isArrow
    ) {
      const hotSpot = this.hotSpots[selectedHotSpot.index];

      if (hotSpot?.type === 'hotspot-info') {
        this.selectedHotSpot = selectedHotSpot;
        this.selectHotSpot(selectedHotSpot);
        this.canvas.style.cursor = 'pointer';
      } else {
        this.canvas.style.cursor = 'default';
        this.selectedHotSpot = { index: -1 };
        this.selectHotSpot(this.selectedHotSpot);
      }
    }
  }

  protected async clickSelectedHotSpot() {
    await this.clickHotSpot(this.selectedHotSpot);
  }
}
