import { jsx as _jsx } from "react/jsx-runtime";
import SpinnerIcon from '../../common/components/SpinnerIcon';
import usePreloading from '../hooks/usePreloading';
const PreloadScreen = () => {
    const { preloading } = usePreloading();
    if (preloading) {
        return (_jsx("div", { className: "z-scene-loading animate-fade-in absolute left-1/2 top-1/2 h-[76px] w-[76px] -translate-x-1/2 -translate-y-1/2 opacity-0", children: _jsx(SpinnerIcon, { className: "h-full w-full" }) }));
    }
    return null;
};
export default PreloadScreen;
