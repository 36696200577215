import { ChangeEvent, forwardRef } from "react";
import { classNamesUtil } from "utils/classNamesUtil";

interface InputProps {
  name?: string;
  inputValue: string;
  isError?: boolean;
  inputPlaceholder?: string;
  inputHeight?: "default" | "small" | "large";
  inputKind: "text" | "number" | "password" | "email" | "tel" | "search";
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      name,
      isError,
      inputKind,
      inputValue,
      inputPlaceholder,
      inputHeight = "default",
      handleInputChange,
      onClick,
    },
    ref
  ) => {
    return (
      <div
        className={classNamesUtil(
          inputHeight === "small" ? "h-[50px] pl-5 p-1" : "",
          inputHeight === "default" ? "p-1 lg:px-2 lg:py-0.5" : "",
          "border rounded-md",
          isError ? "border-giraffe-red" : "border-giraffe-gray-dark"
        )}
      >
        <div className="relative z-0 w-full">
          <input
            ref={ref}
            name={name}
            placeholder=" "
            type={inputKind}
            value={inputValue}
            onChange={(e) => handleInputChange(e)}
            onClick={onClick}
            className="block w-full px-0 pt-3.5 font-secondary text-sm text-giraffe-dark pb-0.5 mt-0 bg-transparent appearance-none focus:outline-none focus:ring-0 focus:border-black"
          />
          <label
            htmlFor={name}
            className="absolute text-sm text-giraffe-gray-lightest font-primary font-bold duration-300 top-2.5 -z-1 origin-0 tracking-wide"
          >
            {inputPlaceholder}
          </label>
        </div>
      </div>
    );
  }
);

export default Input;
