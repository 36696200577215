import Utils from '../common/Utils';

// @note -- not multiple-engine-instance safe, fix if there is a need for 2 engines in a single app being performance tested

// TODO: somehow make this optional, so we can remove it entirely for production builds
//       __DEV__ is is good enough for now

// @todo -- this should be put under the dev panel
abstract class Performance {
  private static debugDiv: HTMLElement | null | undefined;

  public vendor = 'unknown';

  public isPerformanceEnabled = false;
  public isCustomMeasurementEnabled = false;

  private frameTimeHistory: number[] = [];

  private measurementAvgFrameTimes: number[] = [];
  private measurementMaxFrameTimes: number[] = [];
  private measurementMaxFrameRates: number[] = [];

  private previousFrameTimeCounterStamp = 0;
  private ready = false;

  protected abstract gl?: WebGLRenderingContext | null;
  protected abstract previousFrameTimeStamp: number;

  private static get debugContainer() {
    if (__PERF_MEASURER__) {
      if (Performance.debugDiv === undefined) {
        if (!Performance.debugDiv) {
          const ddiv = document.createElement('div');
          ddiv.id = 'performance-info';
          ddiv.style.position = 'absolute';
          ddiv.style.top = '0px';
          ddiv.style.left = '370px';
          ddiv.style.zIndex = '9999';
          Performance.debugDiv = document.body.appendChild(ddiv);
        }

        Performance.debugDiv = document.getElementById('performance-info');
        if (Performance.debugDiv) {
          Performance.debugDiv.style.position = 'absolute';
        }
      }
    }
    return Performance.debugDiv;
  }

  public enablePerformanceMeasurer(): void {
    if (__PERF_MEASURER__) {
      this.isPerformanceEnabled = true;
    }
  }

  public disablePerformanceMeasurer(): void {
    if (__PERF_MEASURER__) {
      this.isPerformanceEnabled = false;
      this.isCustomMeasurementEnabled = false;
    }
  }

  public startCustomPerformanceMeasurement(): void {
    if (__PERF_MEASURER__) {
      this.measurementAvgFrameTimes = [];
      this.measurementMaxFrameTimes = [];
      this.measurementMaxFrameRates = [];
      this.isCustomMeasurementEnabled = true;
      this.enablePerformanceMeasurer();
    }
  }

  public stopCustomPerformanceMeasurement(): void {
    if (__PERF_MEASURER__) {
      this.isCustomMeasurementEnabled = false;
    }
  }

  protected initPerformance(): void {
    if (__PERF_MEASURER__) {
      if (this.gl) {
        console.log('VENDOR:', this.vendor);
        this.ready = true;
      }
    }
  }

  protected updatePerformanceInfo(frameTimeStamp: number, callbackIfIsOn?: () => void): void {
    if (__PERF_MEASURER__) {
      if (!this.ready) return;

      if (!this.isPerformanceEnabled) {
        this.hidePerformanceInfo();
        return;
      }

      if (callbackIfIsOn) {
        callbackIfIsOn();
      }

      const delta = frameTimeStamp - this.previousFrameTimeStamp;

      this.frameTimeHistory.unshift(delta);

      if (this.frameTimeHistory.length > 100) {
        this.frameTimeHistory.pop();
      }

      if (Performance.debugContainer && frameTimeStamp - this.previousFrameTimeCounterStamp > 1000) {
        Performance.debugContainer.style.display = 'block';
        const avgFrameTime = Utils.roundFloat(Utils.getAverageValue(this.frameTimeHistory), 2);
        const maxFrameTime = Utils.roundFloat(Utils.getMaxValue(this.frameTimeHistory), 2);
        const frameRate = Utils.roundFloat(1000 / avgFrameTime);

        if (this.isCustomMeasurementEnabled) {
          this.measurementAvgFrameTimes.unshift(avgFrameTime);
          this.measurementMaxFrameTimes.unshift(maxFrameTime);
          this.measurementMaxFrameRates.unshift(frameRate);
        }

        let innerHTML = `<span style="font-size: 10px;">${this.frameTimeHistory.length} frame average:</span>
          <span id="performance-frame-rate">${frameRate}</span> Hz
           → <span id="performance-frame-time">${Utils.roundFloat(avgFrameTime, 2)}</span> ms
          (↑ <span id="performance-max-frame-time">${maxFrameTime}</span>)
          <span style="font-size: 10px;">[ <span id="performance-vendor">${this.vendor}</span> ]</span>`;

        if (this.measurementAvgFrameTimes.length > 0) {
          const measurementAvgFrameTime = Utils.roundFloat(Utils.getAverageValue(this.measurementAvgFrameTimes), 2);
          const measurementMaxFrameTime = Utils.roundFloat(Utils.getMaxValue(this.measurementMaxFrameTimes), 2);
          const measurementFrameRate = Utils.roundFloat(Utils.getAverageValue(this.measurementMaxFrameRates), 0);

          innerHTML += `<br />
          <span style="font-size: 10px;">\ncustom measurement:</span>
          <span id="custom-frame-rate">${measurementFrameRate}</span> Hz → <span id="custom-frame-time">${Utils.roundFloat(
            measurementAvgFrameTime,
            2
          )}</span> ms
          (↑ <span id="custom-max-frame-time">${measurementMaxFrameTime}</span>)`;
        }

        Performance.debugContainer.innerHTML = innerHTML;

        this.previousFrameTimeCounterStamp = frameTimeStamp;
      }
    }
  }

  protected hidePerformanceInfo = () => {
    if (Performance.debugContainer) {
      Performance.debugContainer.style.display = 'none';
    }
  };
}

export default Performance;
