import {
  LobbyControlWrapper,
  LobbyInputFormWrapper,
  ParticipantSettingsContainer,
  VideoPreviewWrapper,
} from "components/wrappers";
import { useDeviceStateContext, useBrowser } from "hooks";

import { FC, useState } from "react";
import { ToastContainer } from "react-toastify";

const Lobby: FC = () => {
  const browser = useBrowser();
  const {
    setActiveMediaErrorModal: setAciveMediaErrorModal,
    audioAccess,
    videoAccess,
  } = useDeviceStateContext();

  const [isSettingsModalVisible, setIsSettingsModalVisible] =
    useState<boolean>(false);

  // For Safari we need to refresh the page to get media access prompt again
  const handleSafariReload = () => {
    if (browser === "Apple Safari") {
      window.location.reload();
      return;
    }
  };

  return (
    <>
      <ToastContainer
        closeOnClick={false}
        toastClassName={() =>
          "flex justify-center overflow-hidden my-1.5 mx-auto rounded-md max-w-[393px]"
        }
      />
      <div className="flex flex-col lg:flex-row">
        <div className="relative lg:w-3/5 max-w-[740px]">
          <VideoPreviewWrapper />
          <div className="absolute transform -translate-x-1/2 bottom-5 left-1/2">
            <LobbyControlWrapper
              handleSettingClick={() => {
                if (!audioAccess && !videoAccess) {
                  handleSafariReload();
                  setAciveMediaErrorModal("microphone");
                  return;
                }
                setIsSettingsModalVisible(true);
              }}
            />
          </div>

          <ParticipantSettingsContainer
            isSettingsModalVisible={isSettingsModalVisible}
            handleSettingsModalClose={() => setIsSettingsModalVisible(false)}
          />
        </div>

        <LobbyInputFormWrapper isLoading={false} />
      </div>
    </>
  );
};

export default Lobby;
