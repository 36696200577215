import { init, ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import './index.css';
import './polyfills.js';
import App from './App';
import { InfoPageLayout } from 'components/layout';
import { ErrorIcon } from 'components/icons';
import ErrorBoundary from 'components/wrappers/ErrorBoundary/ErrorBoundary';
import { createRoot } from 'react-dom/client';
import React from 'react';

const IS_SENTRY_ENABLED = Boolean(process.env.REACT_APP_SENTRY_PROJECT);

if (IS_SENTRY_ENABLED) {
  init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    release: process.env.REACT_APP_RELEASE,
  });
}
const ErrorBoundaryComponent = IS_SENTRY_ENABLED ? SentryErrorBoundary : ErrorBoundary;

async function initReact() {
  if (process.env.REACT_APP_SANDBOX_VERCEL_BYPASS_TOKEN) {
    // If this is a vercel deployment, we need a service worker to append a authentication header to all requests
    // that are going to the protected sandbox deployment
    try {
      if ('serviceWorker' in navigator) {
        // need to get origin from location because we have custom <base/> tag in index.html
        const origin = window.location.origin;
        const registration = await navigator.serviceWorker.register(`${origin}/service-worker.js`);
        await navigator.serviceWorker.ready;
        if (window.navigator.serviceWorker.controller === null) {
          // Get service worker running after hard refresh (ctrl+f5)
          window.location.reload();
        }
        console.log('SW registered: ', registration);
      }
    } catch (e) {
      console.error('SW registration failed: ', e);
    }
  }

  const container = document.getElementById('root');

  if (!container) {
    throw new Error('No root container found');
  }
  const root = createRoot(container);

  root.render(
    <ErrorBoundaryComponent
      fallback={<InfoPageLayout icon={<ErrorIcon className="w-[4.6rem] h-[4.6rem]" />} headLine="Something went wrong" />}
    >
      <App />
    </ErrorBoundaryComponent>
  );
}

initReact();
