var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import urljoin from 'url-join';
export function gapiPost(path, body) {
    return __awaiter(this, void 0, void 0, function* () {
        const gapiBase = process.env.REACT_APP_BACKEND_ENDPOINT;
        if (!gapiBase) {
            return { ok: false, statusText: 'Form submit disabled, missing endpoint', body: null };
        }
        const url = urljoin(gapiBase, 'gapi', path, '/');
        try {
            const response = yield fetch(url, {
                method: 'POST',
                headers: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });
            if (!response.ok) {
                const res = {
                    ok: false,
                    statusText: response.statusText,
                    body: null,
                };
                const json = yield response.json();
                if (json)
                    res.body = json;
                return res;
            }
            const json = yield response.json();
            return { ok: true, body: json };
        }
        catch (error) {
            const res = {
                ok: false,
                body: null,
            };
            res.statusText = error instanceof Error && error.message ? error.message : 'Network error';
            return res;
        }
    });
}
