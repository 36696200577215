import { useContext } from "react";
import { RoomContext } from "components/contexts/RoomContext";

export default function useRoomContext() {
  const context = useContext(RoomContext);
  if (!context) {
    throw new Error("useRoomContext must be used within a RoomContextProvider");
  }
  return context;
}
