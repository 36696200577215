import { applyRotatedPerspectiveM4ToVector3 } from '@g360/vt-utils';

import type { SideKey } from '../../types/internal';

class Tile {
  static readonly sideKeys: SideKey[] = ['f', 'b', 'u', 'd', 'l', 'r'];
  static readonly faceGeometry = {
    f: [-1, 1, -1, 1, 1, -1, 1, -1, -1, -1, -1, -1],
    b: [1, 1, 1, -1, 1, 1, -1, -1, 1, 1, -1, 1],
    u: [-1, 1, 1, 1, 1, 1, 1, 1, -1, -1, 1, -1],
    d: [-1, -1, -1, 1, -1, -1, 1, -1, 1, -1, -1, 1],
    l: [-1, 1, 1, -1, 1, -1, -1, -1, -1, -1, -1, 1],
    r: [1, 1, -1, 1, 1, 1, 1, -1, 1, 1, -1, -1],
  };
  static readonly genericTextureCoords = new Float32Array([0, 0, 1, 0, 1, 1, 0, 1]);
  static readonly levelSizes = [512, 1536, 3072];
  private static readonly gridSizes = [1, 1, 3, 6];

  sideKey: SideKey;
  textureObject: WebGLTexture | null = null;
  geometry: Float32Array;
  path: string;
  level: number;
  parent: Tile | null;
  children: Tile[] | null;
  textureCoords: Float32Array = Tile.genericTextureCoords;

  loaded = false;
  loading = false;
  childrenLoaded = false;
  childrenLoadedCount = 0;
  disabled = true;
  preview = false;

  diff = 0;
  loadTexture?: () => void;

  constructor(
    sideKey: SideKey,
    geometry: number[],
    path: string,
    level: number,
    parent: Tile | null,
    children?: Tile[] | null,
    textureCoords?: Float32Array
  ) {
    this.sideKey = sideKey;
    this.level = level;
    this.path = path;
    this.geometry = new Float32Array(geometry);
    this.parent = parent;

    if (textureCoords) {
      this.textureCoords = textureCoords;
    }

    if (children !== undefined) {
      this.children = children;
    } else {
      this.children = this.getChildTiles(sideKey, geometry, level + 1, 1, 1);
    }
  }

  static getX(
    sideKey: SideKey,
    tileSize: number,
    coord: number,
    origin: number,
    col: number,
    addSize: boolean
  ): number {
    const offset = addSize ? tileSize : 0;

    if (sideKey === 'f' || sideKey === 'u' || sideKey === 'd') {
      return origin + tileSize * col + offset;
    }

    if (sideKey === 'b') {
      return origin - tileSize * col - offset;
    }

    return coord;
  }

  static getY(
    sideKey: SideKey,
    tileSize: number,
    coord: number,
    origin: number,
    col: number,
    addSize: boolean
  ): number {
    if (sideKey === 'u' || sideKey === 'd') {
      return coord;
    }

    const offset = addSize ? tileSize : 0;
    return origin - tileSize * col - offset;
  }

  static getZ(
    sideKey: SideKey,
    tileSize: number,
    coord: number,
    origin: number,
    row: number,
    col: number,
    addHorizontalSize: boolean,
    addVerticalSize: boolean
  ): number {
    const hOffset = addHorizontalSize ? tileSize : 0;
    if (sideKey === 'r') {
      return origin + tileSize * row + hOffset;
    }
    if (sideKey === 'l') {
      return origin - tileSize * row - hOffset;
    }

    const vOffset = addVerticalSize ? tileSize : 0;
    if (sideKey === 'u') {
      return origin - tileSize * col - vOffset;
    }
    if (sideKey === 'd') {
      return origin + tileSize * col + vOffset;
    }

    return coord;
  }

  /** Takes a point from 3d geometry and converts it to clip space coords [-1, 1]
   * Tile.rotatedPerspective must be updated before calling this
   */
  static getClipSpaceCoords(rotatedPerspective: number[], point: Float32Array): { x: number; y: number; z: number } {
    const pointPerspective = applyRotatedPerspectiveM4ToVector3(rotatedPerspective, point);

    return {
      x: pointPerspective[0] * pointPerspective[3],
      y: pointPerspective[1] * pointPerspective[3],
      z: pointPerspective[2] * pointPerspective[3],
    };
  }

  /** Check if components for provided point are visible inside the clip space coords [-1, 1].
   * If component is not visible return value is -1 or 1, if it is visible: 0
   */
  static isPointVisible(rotatedPerspective: number[], point: Float32Array): number[] {
    const clipSpace = Tile.getClipSpaceCoords(rotatedPerspective, point);

    const result = [0, 0, 0];

    if (clipSpace.x < -1) {
      result[0] = -1;
    }
    if (clipSpace.x > 1) {
      result[0] = 1;
    }
    if (clipSpace.y < -1) {
      result[1] = -1;
    }
    if (clipSpace.y > 1) {
      result[1] = 1;
    }
    if (clipSpace.z < -1 || clipSpace.z > 1) {
      result[2] = 1;
    }

    return result;
  }

  /** Check if tile is visible on the screen (at least one point) */
  isVisible(rotatedPerspective: number[]): boolean {
    const topLeft = Tile.isPointVisible(rotatedPerspective, this.geometry.slice(0, 3));
    const topRight = Tile.isPointVisible(rotatedPerspective, this.geometry.slice(3, 6));
    const bottomRight = Tile.isPointVisible(rotatedPerspective, this.geometry.slice(6, 9));
    const bottomLeft = Tile.isPointVisible(rotatedPerspective, this.geometry.slice(9, 12));

    const testX = topLeft[0] + topRight[0] + bottomRight[0] + bottomLeft[0];
    if (testX === -4 || testX === 4) {
      return false;
    }

    const testY = topLeft[1] + topRight[1] + bottomRight[1] + bottomLeft[1];
    if (testY === -4 || testY === 4) {
      return false;
    }

    const testZ = topLeft[2] + topRight[2] + bottomRight[2] + bottomLeft[2];

    return testZ !== 4;
  }

  /** Calculate the center point in screen space coords for a tile and set is as a square euclidean distance from
   * the center of the screen space
   */
  setDiff(rotatedPerspective: number[]): void {
    const topLeft = Tile.getClipSpaceCoords(rotatedPerspective, this.geometry.slice(0, 3));
    const topRight = Tile.getClipSpaceCoords(rotatedPerspective, this.geometry.slice(3, 6));
    const bottomLeft = Tile.getClipSpaceCoords(rotatedPerspective, this.geometry.slice(9, 12));

    const midpointX = (topLeft.x + topRight.x) / 2;
    const midpointY = (topLeft.y + bottomLeft.y) / 2;
    this.diff = midpointX ** 2 + midpointY ** 2;
  }

  setTextureObject(textureObject: WebGLTexture | null): Tile {
    this.textureObject = textureObject;
    return this;
  }

  // TODO(uzars): need a better solution for this
  setAsPreview(): Tile {
    this.loaded = true;
    this.loading = true;
    this.disabled = false;
    this.preview = true;

    return this;
  }

  /** Change disabled status for a tile and all children tiles */
  disableAll(status = true, tile_: Tile | null = null): void {
    const tile: Tile = tile_ || this;

    tile.disabled = status;

    if (tile.children) {
      tile.children.forEach((childTile) => {
        childTile.disableAll(status, childTile);
      });
    }
  }

  /** Check if all child tiles are loaded */
  checkChildrenLoaded(): boolean {
    if (!this.children) return false;

    this.childrenLoaded = true;

    for (let i = 0; i < this.children.length; i += 1) {
      if (!this.children[i].loaded) {
        this.childrenLoaded = false;
        break;
      }
    }

    return this.childrenLoaded;
  }

  resetTextureCoords(): void {
    this.textureCoords = Tile.genericTextureCoords;
  }

  private getChildTiles(
    sideKey: SideKey,
    faceGeometry: number[],
    level: number,
    offsetCol: number,
    offsetRow: number
  ): Tile[] {
    const gridSize = Tile.gridSizes[level];
    const tileSize = 2 / gridSize;
    const childGridSize = gridSize >= 6 ? 2 : 3;
    const tiles: Tile[] = [];

    for (let col = 0; col < childGridSize; col += 1) {
      for (let row = 0; row < childGridSize; row += 1) {
        const geometry = [
          Tile.getX(sideKey, tileSize, faceGeometry[0], faceGeometry[0], col, false),
          Tile.getY(sideKey, tileSize, faceGeometry[1], faceGeometry[1], row, false),
          Tile.getZ(sideKey, tileSize, faceGeometry[2], faceGeometry[2], col, row, false, false),

          Tile.getX(sideKey, tileSize, faceGeometry[3], faceGeometry[0], col, true),
          Tile.getY(sideKey, tileSize, faceGeometry[4], faceGeometry[1], row, false),
          Tile.getZ(sideKey, tileSize, faceGeometry[5], faceGeometry[2], col, row, true, false),

          Tile.getX(sideKey, tileSize, faceGeometry[6], faceGeometry[0], col, true),
          Tile.getY(sideKey, tileSize, faceGeometry[7], faceGeometry[1], row, true),
          Tile.getZ(sideKey, tileSize, faceGeometry[8], faceGeometry[2], col, row, true, true),

          Tile.getX(sideKey, tileSize, faceGeometry[9], faceGeometry[0], col, false),
          Tile.getY(sideKey, tileSize, faceGeometry[10], faceGeometry[1], row, true),
          Tile.getZ(sideKey, tileSize, faceGeometry[11], faceGeometry[2], col, row, false, true),
        ];

        const gridCol = col + offsetCol + (offsetCol - 1);
        const gridRow = row + offsetRow + (offsetRow - 1);

        const tile = new Tile(
          sideKey,
          geometry,
          `/${sideKey}/l${level}/${gridRow}/l${level}_${sideKey}_${gridRow}_${gridCol}.jpg`,
          level,
          this,
          level < 3 ? this.getChildTiles(sideKey, geometry, level + 1, gridCol, gridRow) : null
        );

        tiles.push(tile);
      }
    }

    return tiles;
  }
}

export default Tile;
