import type { Milliseconds, Radian, ScenePos, Subscription } from '@g360/vt-types';

import type { ThrottledFunction } from '../../misc/throttle';
import throttle from '../../misc/throttle';
import type { AnalyticsEventDetailsSceneView, DragEventType } from '..';
import type Analytics from '../Analytics';

/**
 * A helper class that handles drag events in the VT. Drag events are only triggered if yaw or pitch has changed.
 *
 * The events are throttled to one every `500 ms`.
 *
 * After `dragEnd` event, the next `dragStart` event will be generated immediately, without throttling.
 */
export default class AnalyticsSceneDragHelper {
  /** Determines how often we generate drag analytics events. */
  private static readonly DRAG_TIMEOUT_DELAY: Milliseconds = 500;

  /** An instance of the main `Analytics` class. */
  private analytics: Analytics;

  /** Store the view from `scene.interaction.start` pubsub event to use in `dragStart.VT.Scene` analytics event. */
  private lastDragStartView: ScenePos<Radian> = { yaw: 0, pitch: 0, fov: 0 };

  /** A list of external pubsub event subscriptions. */
  private subscriptions: Subscription[] = [];

  /**
   * Pushes a drag event to the analytics. A throttled version of {@link pushDragEvent}.
   * Limits how often we generate drag analytics events to one every `500 ms`.
   * @param dragEventType - `DragEventType`: the type of the drag event. Possible values: `dragStart`, `drag`, `dragEnd`.
   * @param view - `ScenePos<Radian>`: the current scene view.
   * @param view.pitch - `Radian`: the scene pitch.
   * @param view.yaw - `Radian`: the scene yaw.
   * @param view.fov - `Radian`: the scene fov.
   * @returns `void`
   */
  private pushDragEventThrottled: ThrottledFunction<(dragEventType: DragEventType, view: ScenePos<Radian>) => void>;

  /**
   * @param analytics - `Analytics`: an instance of the main Analytics class.
   */
  constructor(analytics: Analytics) {
    this.analytics = analytics;

    this.onSceneDragStart = this.onSceneDragStart.bind(this);
    this.onSceneDragUpdate = this.onSceneDragUpdate.bind(this);
    this.onSceneDragEnd = this.onSceneDragEnd.bind(this);
    this.onAnalyticsDeactivated = this.onAnalyticsDeactivated.bind(this);
    this.pushDragEvent = this.pushDragEvent.bind(this);

    this.pushDragEventThrottled = throttle(this.pushDragEvent, AnalyticsSceneDragHelper.DRAG_TIMEOUT_DELAY);

    analytics.pubSub.subscribe('analytics.deactivated', this.onAnalyticsDeactivated);
  }

  /**
   * Adds an event subscription to the list of subscriptions.
   * @param subscription - `Subscription`: an event subscription to be added to the list of subscriptions.
   * @returns `void`
   */
  public addSubscription(subscription: Subscription): void {
    this.subscriptions.push(subscription);
  }

  /**
   * Unsubscribes from all event subscriptions and clears the list of subscriptions.
   * @returns `void`
   */
  public unsubscribeAll(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

  /**
   * An event that is triggered when a user starts interacting with the scene.
   * It is triggered even if there was no actual movement.
   *
   * It is also triggered on some other actions, like clicking a hotspot, but we don't do anything special here.
   * The `lastDragStartView` will get overridden by actual drag start.
   * @param view - `ScenePos<Radian>`: the current scene view.
   * @param view.pitch - `Radian`: the scene pitch.
   * @param view.yaw - `Radian`: the scene yaw.
   * @param view.fov - `Radian`: the scene fov.
   * @returns `Promise<void>`
   */
  public async onSceneDragStart(view: ScenePos<Radian>): Promise<void> {
    if (!this.analytics.isActive) return;

    this.lastDragStartView = view;
  }

  /**
   * An event that is triggered continuously while a user is interacting with the scene.
   * It is triggered only when there was an actual movement.
   * @param view - `ScenePos<Radian>`: the current scene view.
   * @param view.pitch - `Radian`: the scene pitch.
   * @param view.yaw - `Radian`: the scene yaw.
   * @param view.fov - `Radian`: the scene fov.
   * @returns `Promise<void>`
   */
  public async onSceneDragUpdate(view: ScenePos<Radian>): Promise<void> {
    if (!this.analytics.isActive) return;

    if (!this.pushDragEventThrottled.isOnCooldown()) {
      this.pushDragEventThrottled('dragStart', this.lastDragStartView);
      return;
    }

    this.pushDragEventThrottled('drag', view);
  }

  /**
   * An event that is triggered when a user stops interacting with the scene.
   * It is triggered even if there was no actual movement.
   * @param view - `ScenePos<Radian>`: the current scene view.
   * @param view.pitch - `Radian`: the scene pitch.
   * @param view.yaw - `Radian`: the scene yaw.
   * @param view.fov - `Radian`: the scene fov.
   * @returns `Promise<void>`
   */
  public async onSceneDragEnd(view: ScenePos<Radian>): Promise<void> {
    if (!this.analytics.isActive) return;
    if (!this.pushDragEventThrottled.isOnCooldown()) return;

    this.pushDragEventThrottled('dragEnd', view);
  }

  /**
   * Pushes a drag event to the analytics. Don't call directly, use {@link pushDragEventThrottled} instead.
   * @param dragEventType - `DragEventType`: the type of the drag event. Possible values: `dragStart`, `drag`, `dragEnd`.
   * @param view - `ScenePos<Radian>`: the current scene view.
   * @param view.pitch - `Radian`: the scene pitch.
   * @param view.yaw - `Radian`: the scene yaw.
   * @param view.fov - `Radian`: the scene fov.
   * @returns `void`
   */
  private pushDragEvent(dragEventType: DragEventType, view: ScenePos<Radian>): void {
    if (!this.analytics.isActive) return;

    if (dragEventType === 'dragEnd') this.pushDragEventThrottled.cancel();

    this.analytics.push<AnalyticsEventDetailsSceneView>(dragEventType, 'VT', 'Scene', {
      scene_id: this.analytics.getCurrentSceneId(),
      view: [view.yaw, view.pitch, view.fov ?? 0],
    });
  }

  /**
   * An event that is triggered when analytics is deactivated.
   * @returns `Promise<void>`
   */
  private async onAnalyticsDeactivated(): Promise<void> {
    this.pushDragEventThrottled.cancel();
  }
}
