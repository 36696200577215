export function vec3CrossProduct(A: number[], B: number[]) {
  const crossAB = [0, 0, 0];
  crossAB[0] = A[1] * B[2] - A[2] * B[1];
  crossAB[1] = A[2] * B[0] - A[0] * B[2];
  crossAB[2] = A[0] * B[1] - A[1] * B[0];
  return crossAB;
}

export function normalizeVec3(v: number[]) {
  const length = Math.sqrt(v[0] ** 2 + v[1] ** 2 + v[2] ** 2);
  return [v[0] / length, v[1] / length, v[2] / length];
}

export function getVec3(pt1: number[], pt2: number[]): number[] {
  return [pt2[0] - pt1[0], pt2[1] - pt1[1], pt2[2] - pt1[2]];
}

export function getVec3Dir(vec3: number[], len): number[] {
  return [vec3[0] / len, vec3[1] / len, vec3[2] / len];
}

export function getVec3Sum(vec3A: number[], vec3B: number[]): number[] {
  return [vec3A[0] + vec3B[0], vec3A[1] + vec3B[1], vec3A[2] + vec3B[2]];
}

export function getVec3Difference(vec3A: number[], vec3B: number[]): number[] {
  return [vec3A[0] - vec3B[0], vec3A[1] - vec3B[1], vec3A[2] - vec3B[2]];
}

export function getVec3ScalarMultiply(vec3: number[], n): number[] {
  return [vec3[0] * n, vec3[1] * n, vec3[2] * n];
}

export function getVec3Dot(vector1: number[], vector2: number[]): number {
  let result = 0;
  for (let i = 0; i < 3; i += 1) {
    result += vector1[i] * vector2[i];
  }
  return result;
}

export function getVec3Multiply(vec3a: number[], vec3b: number[]): number[] {
  return [vec3a[0] * vec3b[0], vec3a[1] * vec3b[1], vec3a[2] * vec3b[2]];
}

export function getVec3Length(vec3: number[]): number {
  return Math.sqrt(vec3[0] ** 2 + vec3[1] ** 2 + vec3[2] ** 2);
}
