/* eslint-disable class-methods-use-this */
import type {
  AssetConfig,
  Degree,
  Features,
  GuidedViewingTourConfigStreamEvent,
  LsfMode,
  NavigationMode,
  ProjectDataConfig,
  ProjectUiConfig,
  SceneConfig,
  Theme,
  ThemeConfig,
  TourConfig,
  TourConfigStructureType,
  TourVariantJson,
  UnitsConfig,
  UnitsShort,
  WatermarkConfig,
} from '@g360/vt-types';
import {
  fetchWithRetries,
  getTourConfigStructureType,
  isInIframe,
  MakeReactive,
  parseTourConfig,
  ReactiveBase,
  sceneGroups2SubScenes,
  verStrToArray,
} from '@g360/vt-utils/';
import urlJoin from 'url-join';

import type Engine from '../../Engine';
import ContrastChecker from '../ContrastChecker';
import DEFAULTS from '../Utils/tourConfigServiceDefaults';

class TourConfigServiceBase extends ReactiveBase {
  tourConfig: TourConfig;
  tourVariant: TourVariantJson | null = null;
  assetConfig: AssetConfig;

  engine: Engine | null = null;

  watermark?: WatermarkConfig | null;
  accent?: string;

  /** Default: `{}` */
  projectDataConfig: ProjectDataConfig = {};
  /** Default: `'#ffc600'` */
  defaultAccent: string = DEFAULTS.defaultAccent;
  configVersion: number[] = DEFAULTS.configVersion;
  /** Default: `'buildings'` */
  configStructureType: TourConfigStructureType = DEFAULTS.configStructureType;
  /** Default: `'auto'` */
  themeConfig: ThemeConfig = DEFAULTS.themeConfig;
  /** Default:`'dark'` */
  theme: Theme = DEFAULTS.theme;
  /** Full path to the branded logo image, if this is falsy, then the default logo is used.
   *
   * Default: `null`
   */
  welcomeImage: string | null = DEFAULTS.welcomeImage;
  /** Full path to the default logo image.
   *
   * Default: `null`
   */
  defaultWelcomeImage: string | null = DEFAULTS.defaultWelcomeImage;
  /** If set to false then branded and default welcome logo is disabled.
   *
   * Default: `false`
   */
  welcomeImageEnabled: boolean = DEFAULTS.welcomeImageEnabled;
  /** Default: `false` */
  tourWelcomeScreen: boolean = DEFAULTS.tourWelcomeScreen;
  /** Default: `true` */
  showRoomArea: boolean = DEFAULTS.showRoomArea;
  /** Default: `true` */
  showTotalAreas: boolean = DEFAULTS.showTotalAreas;
  /** Default: `'metric'` */
  units: UnitsConfig = DEFAULTS.units;
  /** Default: `true` */
  unitSwitch: boolean = DEFAULTS.unitSwitch;
  /** Represent ground levels from number 0 or number 1, by default ground floors are 0.
   * Shifts floor number up in UI if set to 1, keeps negative indices the same.
   *
   * Default: `0`
   */
  floorIndexing: 0 | 1 = DEFAULTS.floorIndexing;
  /** Project url, used for share link.
   *
   * Default: `null`
   */
  projectUrl: string | null = DEFAULTS.projectUrl;
  /** Default: `12` */
  timestampFormat: 12 | 24 = DEFAULTS.timestampFormat;
  /** Default: `'UTC'` */
  timestampTimezone: string = DEFAULTS.timestampTimezone;
  /** Default: `'off'` */
  lsfRenderingMode: LsfMode = DEFAULTS.lsfRenderingMode;
  /** Default: `'full'` */
  navigationMode: NavigationMode = DEFAULTS.navigationMode;
  /** Default: `false` */
  disabledControlsTop: boolean = DEFAULTS.disabledControlsTop;
  /** Default: `''` */
  region: string = DEFAULTS.region;
  /** Default: `false` */
  gatedTourEnabled: boolean = DEFAULTS.gatedTourEnabled;
  /** Defaults:
   *
   * `cookieConsent`: `true`
   *
   * `cookiePopup`: `true`
   *
   * `giraffe360Branding`: `true`
   *
   * `clientBranding`: `true`
   *
   * `welcomeScreen`: `true`
   *
   * `hotspotClickableLinks`: `true`
   *
   * `infoSection`: `true`
   *
   * `fullscreen`: `true`
   *
   * `contactSection`: `true`
   *
   * `propertyInfo`: `true`
   *
   * `linkSharing`: `true`
   *
   * `defaultUnits`: `'floorplan-based'`
   *
   * `isMinimapOpenByDefault`: `true`
   *
   * `timestamp`: `true`
   *
   * `gatedTour`: `true`
   */
  features: Features = DEFAULTS.features;
  /** Scenes that are hidden from the tour.
   * @todo: This is just a temporary solution, we need to change how we handle tourConfig structure.
   * This just allows to hide alt scenes and make it reactive for the editor.
   */
  scenesHidden: Record<string, boolean> = {};

  private contrastChecker = new ContrastChecker('#231720');

  constructor(
    tourConfig: TourConfig,
    assetConfig: AssetConfig,
    options?: {
      tourVariant?: TourVariantJson;
      requestedLsfMode?: LsfMode;
    }
  ) {
    super();

    const { tourVariant, requestedLsfMode = 'off' } = options ?? {};
    this.tourVariant = tourVariant ?? null;
    const gatedTour = tourVariant?.gatedTour ?? DEFAULTS.gatedTourEnabled;

    this.assetConfig = assetConfig;

    this.configVersion = verStrToArray(tourConfig.version || '0');
    this.configStructureType = getTourConfigStructureType(tourConfig, this.configVersion);
    this.region = tourConfig.region || DEFAULTS.region;

    const scenesHidden: Record<string, boolean> = {};
    Object.entries(tourConfig.scenes).forEach(([sceneKey, scene]) => {
      if (scene.hidden) scenesHidden[sceneKey] = true;
    });
    this.setScenesHidden(scenesHidden);

    this.tourConfig = sceneGroups2SubScenes(parseTourConfig(tourConfig, this.configStructureType));
    this.gatedTourEnabled = gatedTour;

    this.loadUiConfig(this.tourConfig.ui ?? {});
    this.switchLsfMode(requestedLsfMode);
    this.loadDataConfig(this.tourConfig.data ?? DEFAULTS.projectDataConfig);
  }

  public get scenes() {
    return this.tourConfig.scenes;
  }

  /** Default: `-90` */
  public get minPitch() {
    return this.tourConfig.minPitch ?? DEFAULTS.minPitch;
  }

  public set minPitch(value: Degree) {
    this.tourConfig.minPitch = value;
  }

  /** Default: `'m'` */
  public get unitsShort(): UnitsShort {
    return this.units === 'metric' ? 'm' : 'ft';
  }

  public setEngine(engine: Engine) {
    this.engine = engine;
  }

  public setProjectUrl(publicUrl: string) {
    this.projectUrl = publicUrl;
  }

  public loadUiConfig(uiConfig: ProjectUiConfig) {
    this.units = uiConfig.units || DEFAULTS.units;
    this.unitSwitch = Boolean(uiConfig.unitSwitch ?? DEFAULTS.unitSwitch);
    this.floorIndexing = uiConfig.floorIndexing ?? DEFAULTS.floorIndexing;
    this.accent = uiConfig.accent;
    this.themeConfig = uiConfig.theme || DEFAULTS.themeConfig;
    this.welcomeImage = uiConfig.welcomeImage || DEFAULTS.welcomeImage;
    this.welcomeImageEnabled = uiConfig.welcomeImageEnabled ?? DEFAULTS.welcomeImageEnabled;
    this.watermark = uiConfig.watermark;
    this.timestampFormat = uiConfig.timestampFormat || DEFAULTS.timestampFormat;
    this.navigationMode = uiConfig.navigationMode || DEFAULTS.navigationMode;
    this.timestampTimezone = uiConfig.timestampTimezone || DEFAULTS.timestampTimezone;
    this.tourWelcomeScreen = Boolean(uiConfig.welcomeScreen);
    this.showRoomArea = uiConfig.showRoomArea ?? DEFAULTS.showRoomArea;
    this.showTotalAreas = uiConfig.showTotalAreas ?? DEFAULTS.showTotalAreas;
    this.disabledControlsTop = uiConfig.disabledControlsTop || DEFAULTS.disabledControlsTop;
  }

  public loadDataConfig(dataConfig: ProjectDataConfig) {
    this.projectDataConfig = dataConfig;
  }

  /** Call this after every new object construction. */
  public async loadFeatures(): Promise<void> {
    let featureSource = 'default';
    let featureSubset1 = '';
    let featureRegion = '';

    switch (this.lsfRenderingMode) {
      case 'standard':
        featureSource = 'lsf';
        featureSubset1 = 'standard';
        if (this.region === 'US') featureRegion = this.region;
        break;

      case 'idealista':
        featureSource = 'lsf';
        featureSubset1 = 'idealista';
        break;

      case 'off':
      default:
        featureSource = 'default';
    }

    const embedded = isInIframe() ? 'embedded' : '';

    const url = urlJoin(
      this.assetConfig.assetPath,
      'features',
      featureSource,
      featureSubset1,
      featureRegion,
      embedded,
      'features.json'
    );

    const response = await fetchWithRetries(url);

    if (!response.ok) {
      // eslint-disable-next-line no-console
      console.error(
        `featureSource=${featureSource} featureRegion=${featureRegion} featureSubset1=${featureSubset1} "embedded=${embedded}"`
      );
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const features = await response.json();

    // Ex.: idealista mode forces units to metric
    if (features.defaultUnits !== 'floorplan-based') this.changeUnits(features.defaultUnits);

    this.features = { ...DEFAULTS.features, ...features };
  }

  /**
   * @todo: Is this really necessary? We should remove this.
   * changing tour configs if they have not changed. Changed values won't be reset
   */

  public reload(rawTourConfig: TourConfig) {
    this.configVersion = verStrToArray(rawTourConfig.version || '0');
    this.configStructureType = getTourConfigStructureType(rawTourConfig, this.configVersion);
    this.tourConfig = sceneGroups2SubScenes(parseTourConfig(rawTourConfig, this.configStructureType));
    this.reInitialize(() => {
      this.loadUiConfig(this.tourConfig.ui || {});
    });
  }

  public getVersion(type?: 'major' | 'minor' | 'patch'): number {
    switch (type) {
      case 'major':
        return this.configVersion[0];
      case 'minor':
        return this.configVersion[1];
      case 'patch':
        return this.configVersion[2];
      default:
        return this.configVersion[0];
    }
  }

  /**
   * Change the units of measurement.
   * @param units - The new units of measurement in shorthand type (m|ft) or as a config (metric|imperial).
   */
  public changeUnits = (units: UnitsShort | UnitsConfig) => {
    this.units = units === 'm' || units === 'metric' ? 'metric' : 'imperial';
  };

  /** Gets sorted scene key list, using .sort() which is ascending by comparing sequences of UTF-16 code unit values */
  public getSortedSceneKeyList(): string[] {
    return Object.keys(this.tourConfig.scenes).sort();
  }

  /**
   * Gets first valid scene key, first checks the one defined in tour.json/firstScene.
   * If that fails then first key from {@link getSortedSceneKeyList}
   */
  public getFirstSceneKey(): string {
    const sceneKeys = this.getSortedSceneKeyList();

    if (sceneKeys.indexOf(this.tourConfig.firstScene) !== -1) {
      return this.tourConfig.firstScene;
    }

    return sceneKeys[0];
  }

  /** Returns the location of the scene.
   * @param sceneKey - `string`: The scene key to search for.
   * @returns `[mainSceneKey, sceneKey]` - `[string, string]`: if scene is a sub scene.
   * @returns `[sceneKey, sceneKey]` - `[string, string]`: if scene is a main scene of a scene group.
   * @returns `sceneKey` - `string`: if scene is not found.
   * @returns `sceneKey` - `string`: if sceneGroups are not defined.
   */
  public getNestedSceneLocation(sceneKey: string): string | [string, string] {
    const groupIdx = this.tourConfig.sceneGroups?.findIndex((group) => group.includes(sceneKey)) ?? -1;

    if (groupIdx === -1) return sceneKey;

    return [this.tourConfig.sceneGroups![groupIdx][0], sceneKey];
  }

  /** Gets scene config by scene key, or subScene by providing main scene key and subScene key */
  public getSceneConfigByKey(sceneKey: string | [string, string]): SceneConfig {
    const firstSceneKey = this.getFirstSceneKey();

    if (Array.isArray(sceneKey)) {
      const mainScene = this.tourConfig.scenes[sceneKey[0]];

      if (!mainScene) {
        return this.getSceneConfigByKey(firstSceneKey);
      }

      // In case trying to get main scene of subScene group
      if (sceneKey[0] === sceneKey[1]) return mainScene;

      return mainScene.subScenes?.[sceneKey[1]] ?? this.getSceneConfigByKey(firstSceneKey);
    }

    let sceneConfig = this.tourConfig.scenes[sceneKey];

    // If invalid, then return first valid scene
    if (!sceneConfig) {
      if (sceneKey === firstSceneKey) throw new Error('First scene not found');
      sceneConfig = this.getSceneConfigByKey(firstSceneKey);
    }

    return sceneConfig;
  }

  /**
   * Finds closest scene config for given x,y in floor/building.
   */
  public getSceneConfigByPosition(
    x: number,
    y: number,
    building: string | undefined,
    floor: string | undefined
  ): SceneConfig | null {
    let closestSC: SceneConfig | null = null;
    let closestDistance = Infinity;

    Object.keys(this.tourConfig.scenes).forEach((sceneKey) => {
      const scene = this.tourConfig.scenes[sceneKey];

      if (scene.floor === floor && scene.building === building) {
        const distance = Math.sqrt((scene.camera[0] - x) ** 2 + (scene.camera[1] - y) ** 2);

        if (distance < closestDistance) {
          closestSC = scene;
          closestDistance = distance;
        }
      }
    });
    return closestSC;
  }

  /** Gets the scene config based on tour.json/firstScene setting or fall-back to first valid scene */
  public getInitialSceneConfig(): SceneConfig {
    return this.getSceneConfigByKey(this.getFirstSceneKey());
  }

  /** Get projectId and companyId, in dev mode these may be undefined */
  public getTourMetaData() {
    return {
      projectId: this.tourConfig.projectId,
      companyId: this.tourConfig.companyId,
    };
  }

  // We check if the tour is mls compatible and if the link has requested an lsf rendering
  public switchLsfMode(requestedLsfMode: LsfMode) {
    if (requestedLsfMode === 'off') {
      this.lsfRenderingMode = 'off';
      return;
    }

    this.lsfRenderingMode = requestedLsfMode;
    this.watermark = undefined;

    if (this.welcomeImage) {
      this.welcomeImageEnabled = false;
    }

    if (requestedLsfMode === 'idealista') {
      this.welcomeImageEnabled = false;
    }
  }

  public loadNewSceneGroups(sceneGroups: TourConfig['sceneGroups']) {
    this.tourConfig.sceneGroups = sceneGroups;
    this.tourConfig = sceneGroups2SubScenes(this.tourConfig, { newSceneGroupOrder: true });
  }

  public setScenesHidden(scenesHidden: Record<string, boolean>) {
    this.scenesHidden = scenesHidden;
  }

  protected init() {
    this.initGuidedViewing();

    this.watch(() => {
      this.theme = this.getAutoTheme();
      const logoType = this.theme === 'light' ? 'dark' : 'light';
      this.defaultWelcomeImage = urlJoin(this.assetConfig.assetPath, `/images/welcome/default-${logoType}.png`);
    });
  }

  protected initGuidedViewing() {
    // Handle guided viewing events
    let remoteSyncReady = false;

    const remoteSyncReadyHandler = () => {
      if (remoteSyncReady || !this.engine) return;
      remoteSyncReady = true;

      // Receive
      this.engine.subscribe('tour.config.remoteUpdate', (payload) => {
        this.changeUnits(payload.units);
      });

      // Transmit
      this.watch(() => {
        const event: GuidedViewingTourConfigStreamEvent = {
          units: this.units,
          source: 'tour-config',
        };
        this.engine?.emitGuidedViewingTourConfigEvent(event);
      });
    };

    this.engine?.subscribe('remoteSyncReady', remoteSyncReadyHandler);

    if (this.engine?.isInGuidedViewing()) {
      remoteSyncReadyHandler();
    }
  }

  private getAutoTheme(color?: string): Theme {
    if (this.themeConfig === 'auto') {
      // Set auto theme based on configured accent color
      const contrastRatio = this.contrastChecker.getContrastRatio(color ?? this.accent ?? this.defaultAccent);

      if (contrastRatio !== undefined) return contrastRatio <= 4.5 ? 'light' : 'dark';
    }

    return this.themeConfig === 'light' ? 'light' : 'dark';
  }
}

// eslint-disable-next-line import/prefer-default-export
export class TourConfigService extends MakeReactive(TourConfigServiceBase, [
  'accent',
  'showRoomArea',
  'theme',
  'themeConfig',
  'tourConfig',
  'tourVariant',
  'units',
  'watermark',
  'welcomeImage',
  'defaultWelcomeImage',
  'welcomeImageEnabled',
  'tourWelcomeScreen',
  'projectDataConfig',
  'lsfRenderingMode',
  'projectUrl',
  'timestampFormat',
  'timestampTimezone',
  'navigationMode',
  'floorIndexing',
  'minPitch',
  'showTotalAreas',
  'features',
  'configStructureType',
  'gatedTourEnabled',
  'scenesHidden',
]) {}
