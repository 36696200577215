import { useState } from 'react';
const useNavigationSize = () => {
    const [size, setSquareSize] = useState(0);
    return {
        squareSize: { width: size, height: size },
        navRadius: size / 2,
        setSquareSize,
    };
};
export default useNavigationSize;
