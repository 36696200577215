(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@g360/vt-utils"));
	else if(typeof define === 'function' && define.amd)
		define(["@g360/vt-utils"], factory);
	else if(typeof exports === 'object')
		exports["vt-engine"] = factory(require("@g360/vt-utils"));
	else
		root["vt-engine"] = factory(root["@g360/vt-utils"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__4374__) => {
return 