import { RoundButton, Spinner } from "components/elements";
import {
  CameraIcon,
  CameraOffIcon,
  CogIcon,
  MicIcon,
  MicOffIcon,
} from "components/icons";
import {
  useDeviceStateContext,
  useMixpanelContext,
  useRoomContext,
} from "hooks";
import { FC } from "react";

interface LobbyControlWrapperProps {
  handleSettingClick: () => void;
}

const LobbyControlWrapper: FC<LobbyControlWrapperProps> = ({
  handleSettingClick,
}) => {
  const {
    isAudioOn,
    audioAccess,
    isVideoOn,
    videoAccess,
    handleAudioToggle,
    handleVideoToggle,
  } = useDeviceStateContext();

  const { Mixpanel, MixpanelEvents } = useMixpanelContext();
  const { isAcquiringLocalVideoTrack, isAcquiringLocalAudioTrack } =
    useRoomContext();

  const renderVideoToggleIcon = () => {
    if (isAcquiringLocalVideoTrack) {
      return <Spinner />;
    }

    if (isVideoOn) {
      return <CameraIcon className="w-full h-full fill-giraffe-white" />;
    }

    return <CameraOffIcon className="w-full h-full fill-red-500" />;
  };

  const renderAudioToggleIcon = () => {
    if (isAcquiringLocalAudioTrack) {
      return <Spinner />;
    }
    if (isAudioOn) {
      return <MicIcon className="w-full h-full fill-giraffe-white" />;
    }

    return <MicOffIcon className="w-full h-full fill-red-500" />;
  };

  const renderSettingToggleIcon = () => {
    if (!audioAccess && !videoAccess) {
      return <CogIcon className="w-full h-full fill-red-500" />;
    }

    return <CogIcon className="w-full h-full fill-giraffe-white" />;
  };

  return (
    <div className="flex items-center justify-between w-36">
      <RoundButton
        type="button"
        isDisabled={isAcquiringLocalVideoTrack}
        handleClick={() => {
          handleVideoToggle();
          if (isVideoOn) {
            Mixpanel.track(MixpanelEvents.Button.Click("Camera off"));
          } else {
            Mixpanel.track(MixpanelEvents.Button.Click("Camera on"));
          }
        }}
      >
        {renderVideoToggleIcon()}
      </RoundButton>
      <RoundButton
        type="button"
        isDisabled={isAcquiringLocalAudioTrack}
        handleClick={() => {
          if (isAudioOn) {
            Mixpanel.track(MixpanelEvents.Button.Click("Microphone off"));
          } else {
            Mixpanel.track(MixpanelEvents.Button.Click("Microphone on"));
          }
          handleAudioToggle();
        }}
      >
        {renderAudioToggleIcon()}
      </RoundButton>
      <RoundButton
        type="button"
        handleClick={() => {
          handleSettingClick();
          Mixpanel.track(MixpanelEvents.Button.Click("Settings"));
        }}
      >
        {renderSettingToggleIcon()}
      </RoundButton>
    </div>
  );
};

export default LobbyControlWrapper;
