import { SVGProps } from "react";

const WarningIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
      // ...(shadow && { filter: "drop-shadow(rgba(0, 0, 0, 0.4) 0px 0px 5px)" }),
    }}
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.2489 2.62774L21.9721 19.1994C21.9992 19.2457 22.0003 19.2715 22 19.2851C21.9995 19.3052 21.9932 19.3368 21.9714 19.3734C21.9495 19.4101 21.9182 19.4413 21.8817 19.4626C21.8499 19.4812 21.8016 19.5 21.7232 19.5H2.27682C2.19839 19.5 2.15009 19.4812 2.11828 19.4626C2.08182 19.4413 2.05045 19.4101 2.02859 19.3734C2.00675 19.3368 2.00045 19.3052 2.00003 19.2851C1.99974 19.2715 2.00079 19.2457 2.0279 19.1994L11.7511 2.62774C11.779 2.58016 11.809 2.55481 11.8419 2.53699C11.8804 2.51609 11.9352 2.5 12 2.5C12.0648 2.5 12.1196 2.51609 12.1581 2.53699C12.191 2.55481 12.221 2.58016 12.2489 2.62774ZM10.0261 1.61563C10.8989 0.128125 13.1011 0.128124 13.9739 1.61563L23.6971 18.1873C24.5632 19.6634 23.4688 21.5 21.7232 21.5H2.27682C0.531206 21.5 -0.563164 19.6634 0.302903 18.1873L10.0261 1.61563ZM12 7.5C11.4477 7.5 11 7.94772 11 8.5V12.5C11 13.0523 11.4477 13.5 12 13.5C12.5523 13.5 13 13.0523 13 12.5V8.5C13 7.94772 12.5523 7.5 12 7.5ZM12 17.5C12.5523 17.5 13 17.0523 13 16.5C13 15.9477 12.5523 15.5 12 15.5C11.4477 15.5 11 15.9477 11 16.5C11 17.0523 11.4477 17.5 12 17.5Z"
      fill="white"
    />
  </svg>
);

export default WarningIcon;
