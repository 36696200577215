/* eslint-disable consistent-return,no-continue,no-cond-assign */

import type { DataJsonType } from '@g360/vt-types';
import cloneDeep from 'lodash/cloneDeep';

import { HotSpotGraph, HotSpotNode } from './HotSpotGraph';
import type { HotSpot, HotSpotGraphV1, HotSpotGraphV2, LinkedHotSpots, Pano, Panos, Position } from './types';

const removeTargetHotSpot = (targetHotSpots: any[], panoBlacklist: string[]): void => {
  targetHotSpots.forEach((connectedHs, index) => {
    if (panoBlacklist.includes(connectedHs.target)) {
      targetHotSpots.splice(index, 1);
    }
  });
};

const filterBlacklist = (
  originalHotSpotGeneratorJson: HotSpotGraphV2,
  panoBlacklist: string[] = []
): HotSpotGraphV2 => {
  const hotSpotJson = cloneDeep(originalHotSpotGeneratorJson);
  if (!panoBlacklist) {
    return hotSpotJson;
  }

  panoBlacklist.forEach((blacklistedPano) => {
    if (blacklistedPano in hotSpotJson.scenes) {
      delete hotSpotJson.scenes[blacklistedPano];
    }
  });

  // Delete hotspots to blacklisted panos
  Object.values(hotSpotJson.scenes).forEach((pano) => {
    removeTargetHotSpot(pano.connected_wall_hotspots, panoBlacklist);
    removeTargetHotSpot(pano.door_hotspots, panoBlacklist);
    removeTargetHotSpot(pano.exact_hotspots, panoBlacklist);
    removeTargetHotSpot(pano.outside_hotspots, panoBlacklist);
    removeTargetHotSpot(pano.stair_hotspots, panoBlacklist);
  });

  return hotSpotJson;
};

const addExactHotSpots = (graph_: HotSpotGraph, scenes: Panos) => {
  Object.entries(scenes).forEach(([panoName, panoData]) => {
    panoData.exact_hotspots.forEach((connectedHsArray) => {
      connectedHsArray.forEach((connectedHs) => {
        graph_.addConnection(panoName, connectedHs.target, 'exact', connectedHs);
      });
    });
  });
};

const addDoorHotSpots = (graph_: HotSpotGraph, scenes: Panos): void => {
  Object.entries(scenes).forEach(([panoName, panoData]) => {
    panoData.door_hotspots.forEach((connectedHsArray) => {
      connectedHsArray.forEach((connectedHs) => {
        graph_.addConnection(panoName, connectedHs.target, 'door', connectedHs);
      });
    });
  });
};

const addStairHotSpots = (graph_: HotSpotGraph, hotSpotGeneratorJson: HotSpotGraphV2): void => {
  Object.entries(hotSpotGeneratorJson.scenes).forEach(([panoName, panoData]) => {
    panoData.stair_hotspots.forEach((connectedHsArray) => {
      connectedHsArray.forEach((connectedHs) => {
        graph_.addConnection(panoName, connectedHs.target, 'stair', connectedHs);
      });
    });
  });
};

const addOutsideHotSpots = (graph_: HotSpotGraph, hotSpotGeneratorJson: HotSpotGraphV2): void => {
  Object.entries(hotSpotGeneratorJson.scenes).forEach(([panoName, panoData]) => {
    panoData.outside_hotspots.forEach((connectedHsArray) => {
      connectedHsArray.forEach((connectedHs) => {
        graph_.addConnection(panoName, connectedHs.target, 'outside', connectedHs);
      });
    });
  });
};

const addConnectedWallHotSpots = (graph_: HotSpotGraph, scenes: Panos): void => {
  // This function adds connections between panos that are connected by a wall
  // This adds a bi-directional connection between the two hotspots
  // Connected wall hotspots are added only when the source pano doesn't have any other connections

  Object.entries(scenes).forEach(([panoName, panoData]) => {
    panoData.connected_wall_hotspots.forEach((connectedHsArray) => {
      connectedHsArray.forEach((connectedHs) => {
        const sourceVertexConnections = graph_.getConnections(panoName);
        if (sourceVertexConnections.length > 0) {
          return; // Skip if the pano already has connections
        }

        // Add the connection from source -> target
        graph_.addConnection(panoName, connectedHs.target, 'connected_wall', connectedHs);

        // Retrieve the inverse target (if exists)
        const targetPanoData = scenes[connectedHs.target];
        const wallHs = targetPanoData?.connected_wall_hotspots?.flat() ?? [];
        const inverseTarget = wallHs.find((hotSpot) => hotSpot.target === panoName);
        if (!inverseTarget) {
          console.error('Something is wrong with the inverse target search');
          return;
        }

        // Add the connection from target -> source
        graph_.addConnection(connectedHs.target, panoName, 'connected_wall', inverseTarget);
      });
    });
  });
};

const createDistanceHotSpot = (targetName: string, pos: Position): HotSpot => ({
  hidden: true,
  obstructed: true,
  pos,
  target: targetName,
  type: 'normal',
});

/**
 * This function is used to ensure that all parts of the graph are connected.
 * It does this by adding "distance hotspots" between the closest nodes o
 * f different connected components until there is only one connected component left.
 *
 * Doesn't connect different floors/buildings. That would give weird results.
 */
const addDistanceHotSpots = (graph: HotSpotGraph, hotSpotGeneratorJson: HotSpotGraphV2): void => {
  let connectedComponents = graph.getStronglyConnectedComponents();

  if (connectedComponents.length < 2) return;

  // Ideally all connectedComponents should be connected, but since we are
  // skipping different floors/buildings,
  // we might have multiple connected components left.
  // This prevents infinite loop.
  let runs = 0;

  while (connectedComponents.length > 1 && runs < 10) {
    runs += 1;
    let minDistance = Number.POSITIVE_INFINITY;
    let bestHotSpot1: HotSpotNode | null = null;
    let bestHotSpot2: HotSpotNode | null = null;

    for (let i = 0; i < connectedComponents.length - 1; i += 1) {
      const cluster1 = connectedComponents[i];
      for (let j = i + 1; j < connectedComponents.length; j += 1) {
        const cluster2 = connectedComponents[j];

        for (let k = 0; k < cluster1.length; k += 1) {
          const hotSpot1 = cluster1[k];
          for (let l = 0; l < cluster2.length; l += 1) {
            const hotSpot2 = cluster2[l];
            const hotSpot1Pos = hotSpot1.position;
            const hotSpot2Pos = hotSpot2.position;

            const distance = Math.hypot(hotSpot2Pos[0] - hotSpot1Pos[0], hotSpot2Pos[1] - hotSpot1Pos[1]);

            // console.info(`Distance between ${hs1.name} and ${hs2.name}: ${distance}`);

            if (distance < minDistance) {
              minDistance = distance;
              bestHotSpot1 = hotSpot1;
              bestHotSpot2 = hotSpot2;
            }
          }
        }
      }
    }

    if (!bestHotSpot1 || !bestHotSpot2) return;
    const scene1 = hotSpotGeneratorJson.scenes[bestHotSpot1.name];
    const scene2 = hotSpotGeneratorJson.scenes[bestHotSpot2.name];

    if (scene1?.floor !== scene2?.floor) continue; // Don't connect different floors
    if (scene1?.building !== scene2?.building) continue; // Don't connect different buildings

    // console.info(`addDistanceHotSpots::${bestHs1.name} <-> ${bestHs2.name}`);
    const distanceHotSpot1 = createDistanceHotSpot(bestHotSpot2.name, [
      bestHotSpot2.position[0],
      bestHotSpot2.position[1],
      0,
    ]);
    graph.addConnection(bestHotSpot1.name, bestHotSpot2.name, 'normal', distanceHotSpot1);

    const distanceHotSpot2 = createDistanceHotSpot(bestHotSpot1.name, [
      bestHotSpot1.position[0],
      bestHotSpot1.position[1],
      0,
    ]);
    graph.addConnection(bestHotSpot2.name, bestHotSpot1.name, 'normal', distanceHotSpot2);

    connectedComponents = graph.getStronglyConnectedComponents();
  }
};

const v1ToV2 = (hotSpotGeneratorJson: HotSpotGraphV1): HotSpotGraphV2 => {
  const newHotSpotGeneratorJson: HotSpotGraphV2 = {
    scenes: {},
    software_version: hotSpotGeneratorJson.software_version,
    version: '2.0',
  };

  Object.keys(hotSpotGeneratorJson.floors).forEach((floor) => {
    const scenes = hotSpotGeneratorJson.floors[floor];
    Object.entries(scenes).forEach(([sceneName, sceneInfo]) => {
      newHotSpotGeneratorJson.scenes[sceneName] = {
        ...sceneInfo,
        connected_wall_hotspots: sceneInfo.connected_wall_hotspots.map((item) => [item]), // V1 had a sensible "array of hotspots", V2 has "array of array of hotspots" where the 2nd level arrays are length=1 (might be some reason behind that, dunno)
        door_hotspots: sceneInfo.door_hotspots.map((item) => [item]),
        exact_hotspots: sceneInfo.exact_hotspots.map((item) => [item]),
        outside_hotspots: sceneInfo.outside_hotspots.map((item) => [item]),
        stair_hotspots: sceneInfo.stair_hotspots.map((item) => [item]),
        floor: parseInt(floor, 10),
        building: 0, // no building info in v1
      } as any as Pano;
    });
  });

  return newHotSpotGeneratorJson;
};

type OutsideConnection = {
  fromSceneKey: string;
  toSceneKey: string;
  toBuilding: number;
  hotSpot: HotSpot; // original hotspot to be reused
};

/**
 * Find connections between inside and outside panos.
 * So that we can later connect the building even if original connecting pano is blacklisted.
 * From specific outside pano to a specific building/pano inside.
 * @todo -- maybe later get reverse connections too? (from outside to inside)
 */
const findOutsideConnections = (hotSpotGeneratorJson: HotSpotGraphV2) => {
  const connections: OutsideConnection[] = [];
  Object.keys(hotSpotGeneratorJson.scenes).forEach((sceneKey) => {
    const scene = hotSpotGeneratorJson.scenes[sceneKey];
    const outside = scene.is_outside;
    if (outside) {
      const hotSpots = [
        ...scene.exact_hotspots,
        ...scene.door_hotspots,
        ...scene.connected_wall_hotspots,
        ...scene.stair_hotspots,
      ];
      Object.values(hotSpots).forEach((hotSpotArrayOfOne) => {
        const hotSpot = hotSpotArrayOfOne[0]; // data is wierd
        const targetSceneKey = hotSpot.target;
        const targetScene = hotSpotGeneratorJson.scenes[targetSceneKey];
        const targetBuilding = targetScene.building;
        connections.push({
          fromSceneKey: sceneKey,
          toSceneKey: targetSceneKey,
          toBuilding: targetBuilding,
          hotSpot,
        });
      });
    }
  });
  return connections;
};

/**
 * Check if after blacklisting and regeneration, the outside connections are still there:
 * Is there still a connection from original pano to the original building (even if different pano)
 * if not, then it's a "missing connection"
 */
const findMissingOutsideConnections = (
  originalOutsideConnections: OutsideConnection[],
  finalGraph: HotSpotGraph,
  hotSpotGeneratorJson: HotSpotGraphV2
) => {
  let numMissingHard = 0;
  let numMissingSoft = 0;
  const missingConnections: OutsideConnection[] = [];
  originalOutsideConnections.forEach((connection) => {
    const { fromSceneKey, toSceneKey, toBuilding } = connection;

    const connectionExists = finalGraph.getConnection(fromSceneKey, toSceneKey);
    if (!connectionExists) {
      numMissingSoft += 1;
      const altConnections = finalGraph.getConnections(fromSceneKey);
      let connectionExistsInBuilding = false;
      // original connection is missing, but can we still go from the pano to the building?
      Object.values(altConnections).forEach((altConnection) => {
        const toAltSceneKey = altConnection.toNode.name;
        const scene = hotSpotGeneratorJson.scenes[toAltSceneKey];
        if (scene.is_outside) return;
        if (scene.building === toBuilding) {
          connectionExistsInBuilding = true;
        }
      });
      if (!connectionExistsInBuilding) {
        numMissingHard += 1;
        missingConnections.push(connection);
      }
    }
  });
  console.log('checkOutsideConnections', { numMissingHard, numMissingSoft });
  return missingConnections;
};

/**
 * Restore missing outside connections.
 * Find an existing (non-blacklisted and hidden) in a building that has original connection to
 * and find nearest pano in the building.
 * Use that pano as the new connection, keeping old hotspt type and data.
 */
const restoreMissingOutsideConnections = (
  missingConnections: OutsideConnection[],
  originalGraph: HotSpotGraph,
  finalGraph: HotSpotGraph,
  hotSpotGeneratorJson: HotSpotGraphV2,
  panoBlacklist: string[]
) => {
  Object.values(missingConnections).forEach((connection) => {
    const { fromSceneKey, toSceneKey, toBuilding } = connection;
    const nearestExistingPano = originalGraph.findNearestNodeNotInBlacklistInGivenBuilding(
      toSceneKey,
      panoBlacklist,
      toBuilding,
      hotSpotGeneratorJson
    );

    if (!nearestExistingPano) return;
    const originalHotSpot = connection.hotSpot;
    finalGraph.addConnection(fromSceneKey, nearestExistingPano.name, originalHotSpot.type, originalHotSpot);
  });
};

const generateHotSpotGraph = (hotSpotGeneratorJson: HotSpotGraphV2, panoBlacklist: string[] = []): HotSpotGraph => {
  const filteredHotSpotGeneratorJson = filterBlacklist(hotSpotGeneratorJson, panoBlacklist);

  const floorGraphs: HotSpotGraph[] = [];
  const allFloors = Object.values(filteredHotSpotGeneratorJson.scenes)
    .map((x) => x.floor)
    .filter((value, index, self) => self.indexOf(value) === index);

  for (let i = 0; i < allFloors.length; i += 1) {
    const floorId = allFloors[i];

    // scenes in current floor
    const scenes: Panos = Object.fromEntries(
      Object.entries(filteredHotSpotGeneratorJson.scenes).filter((s) => s[1].floor === floorId)
    );

    const floorGraph: HotSpotGraph = new HotSpotGraph();
    Object.entries(scenes).forEach(([sceneName, sceneInfo]) => {
      // Skip outside panos for now
      // We want to connect inside panos first
      if (sceneInfo.is_outside) {
        return null;
      }
      floorGraph.addNode(sceneName, [sceneInfo.position_m[0] * 100, sceneInfo.position_m[1] * 100]); // convert to cm
    });

    // Add edges
    addExactHotSpots(floorGraph, scenes);
    addDoorHotSpots(floorGraph, scenes);
    addConnectedWallHotSpots(floorGraph, scenes);
    addDistanceHotSpots(floorGraph, hotSpotGeneratorJson);

    // Add floor graph to tour graph list
    floorGraphs.push(floorGraph);
  }

  // Create the final graph
  const allScenes = filteredHotSpotGeneratorJson.scenes;
  const finalGraph = new HotSpotGraph();
  Object.entries(allScenes).forEach(([sceneName, sceneInfo]) => {
    if (sceneInfo.position_m) {
      finalGraph.addNode(sceneName, [sceneInfo.position_m[0] * 100, sceneInfo.position_m[1] * 100]); // convert to cm
    }
  });

  // Add connections from each individual floor in the final graph
  floorGraphs.forEach((floorG) => {
    const edges = floorG.connections;
    edges.forEach((edge) => {
      const sourceName = edge.fromNode.name;
      const targetName = edge.toNode.name;
      let color: string;

      switch (edge.type) {
        case 'exact':
          color = 'green';
          break;
        case 'door':
          color = 'brown';
          break;
        case 'normal': // also "stairs"
          color = 'teal';
          break;
        default:
          color = 'gray';
          break;
      }

      finalGraph.addConnection(sourceName, targetName, edge.type, edge.hotSpot, color);
    });
  });

  // Connect multiple levels
  addStairHotSpots(finalGraph, filteredHotSpotGeneratorJson);

  // Add connections for outside hotspots
  addOutsideHotSpots(finalGraph, filteredHotSpotGeneratorJson);

  return finalGraph;
};

type MakeHotSpotsReturnType = {
  hotSpots: LinkedHotSpots;
  graph: HotSpotGraph;
};
export const makeHotSpots = (
  inputHotSpotGeneratorJson: HotSpotGraphV1 | HotSpotGraphV2,
  dataJson?: DataJsonType,
  panoBlacklist: string[] = []
): MakeHotSpotsReturnType => {
  let hotSpotGeneratorJson: HotSpotGraphV2;
  const version = inputHotSpotGeneratorJson.version;
  if (version === undefined) {
    console.log({ inputHotSpotGeneratorJson });
    throw new Error('Version not found');
  }
  if (version.charAt(0) === '1') {
    hotSpotGeneratorJson = v1ToV2(inputHotSpotGeneratorJson as HotSpotGraphV1);
  } else {
    hotSpotGeneratorJson = inputHotSpotGeneratorJson as HotSpotGraphV2;
  }

  const originalOutsideConnections = findOutsideConnections(hotSpotGeneratorJson);

  const graphWhitelisted = generateHotSpotGraph(hotSpotGeneratorJson, panoBlacklist);

  // check if outside connections are broken due to blacklisting and try to restore them
  // @todo -- find a project with HS that leads outside and fix that direction too
  //          currently only OUTSIDE -> INSIDE hotspots are fixed
  //
  // @todo -- should add additional data for opposite connection
  //          and restore that too when looking for missing connections
  const missingConnections = findMissingOutsideConnections(
    originalOutsideConnections,
    graphWhitelisted,
    hotSpotGeneratorJson
  );
  if (missingConnections.length > 0) {
    const graphNonFiltered = generateHotSpotGraph(hotSpotGeneratorJson);
    restoreMissingOutsideConnections(
      missingConnections,
      graphNonFiltered,
      graphWhitelisted,
      hotSpotGeneratorJson,
      panoBlacklist
    );
  }

  const hotSpots = graphWhitelisted.exportLinkedHotSpots(dataJson);

  return { hotSpots, graph: graphWhitelisted };
};

export default makeHotSpots;
