import { isNumber } from '../../common/utils';
function isValidString(str) {
    return Boolean(str && str !== '');
}
function isValidAddress(projectAddress) {
    if (!projectAddress)
        return false;
    if (typeof projectAddress === 'string')
        return isValidString(projectAddress);
    // Just check if any of the object address fields are valid string
    const { address, city, state, country, postcode, address_optional: addressOptional } = projectAddress;
    const displayAddress = [address, addressOptional, city, state, country, postcode].filter((v) => !!v).join('');
    return isValidString(displayAddress);
}
export const isValidContactData = (projectDataConfig, lsfRenderingMode = 'off') => {
    var _a, _b, _c;
    if (lsfRenderingMode !== 'off' || !projectDataConfig)
        return false;
    if ((_a = projectDataConfig.contactFormFields) === null || _a === void 0 ? void 0 : _a.length)
        return true;
    return isValidString((_b = projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.contact) === null || _b === void 0 ? void 0 : _b.email) || isValidString((_c = projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.contact) === null || _c === void 0 ? void 0 : _c.phone);
};
function isValidRoomCounts(roomCounts) {
    return Boolean((roomCounts === null || roomCounts === void 0 ? void 0 : roomCounts.length) && roomCounts.some((room) => room.count > 0));
}
export const isValidProjectInfo = (projectDataConfig, lsfRenderingMode = 'off') => {
    if (!projectDataConfig)
        return false;
    const { price, totalArea, roomCounts, address, floorCount } = projectDataConfig;
    const isPrice = isNumber(price === null || price === void 0 ? void 0 : price.value);
    const isAddress = isValidAddress(address);
    const isFloorCount = isNumber(floorCount);
    const isTotalArea = isNumber(totalArea);
    const isRoomCounts = isValidRoomCounts(roomCounts);
    if (lsfRenderingMode !== 'off') {
        return Boolean(isTotalArea || isRoomCounts || isFloorCount);
    }
    return Boolean(isPrice || isTotalArea || isAddress || isRoomCounts || isFloorCount);
};
