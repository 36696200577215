import { SVGProps } from "react";

const MicIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 30 41"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <path
      d="M18.995 20.997c1.328 0 2.4-1.071 2.4-2.399v-4.799a2.397 2.397 0 0 0-3.319-2.217 2.392 2.392 0 0 0-1.481 2.218v4.798c0 1.328 1.073 2.4 2.4 2.4v-.001Zm4.727-2.399a.8.8 0 0 0-.784.68 4.01 4.01 0 0 1-3.943 3.319 4.012 4.012 0 0 1-3.943-3.32.792.792 0 0 0-.784-.679.798.798 0 0 0-.773.572.814.814 0 0 0-.027.34 5.596 5.596 0 0 0 4.727 4.622v1.664c0 .44.36.8.8.8.44 0 .8-.36.8-.8v-1.664a5.588 5.588 0 0 0 4.727-4.622c.08-.48-.312-.912-.8-.912Z"
      style={{
        // fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-35.896 -30.4) scale(2.66667)"
    />
  </svg>
);

export default MicIcon;
