import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useState } from 'react';
const WelcomeImage = forwardRef(({ className, src }, ref) => {
    const [loaded, setLoaded] = useState(false);
    if (!src)
        return _jsx(_Fragment, {});
    return (_jsx("img", { ref: ref, className: `h-full w-full object-contain transition-opacity duration-500 ${loaded ? 'opacity-100' : 'opacity-0'} ${className}`, src: src, alt: "company-logo", onLoad: () => {
            setLoaded(true);
        } }));
});
WelcomeImage.displayName = 'WelcomeImage';
export default WelcomeImage;
