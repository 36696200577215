var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useLayoutEffect } from 'react';
const isEqualDOMRect = (prevSize, currentSize) => prevSize.width === currentSize.width &&
    prevSize.height === currentSize.height &&
    prevSize.top === currentSize.top &&
    prevSize.left === currentSize.left;
/** All of this because overlayscrollbars-react is using custom ref object */
const getElement = (refObject) => {
    if (!(refObject === null || refObject === void 0 ? void 0 : refObject.current))
        return null;
    if (refObject.current instanceof HTMLElement) {
        return refObject.current;
    }
    if (refObject.current.getElement) {
        return refObject.current.getElement();
    }
    return null;
};
/**
 * Observe element resize with a fallback to window.resize and orientation-change if ResizeObserver is not available.
 * Optionally wait for layout to settle before triggering a callback
 *
 * @param onResize Callback to be triggered on resize
 * @param refObject RefObject to be observed
 * @param waitStableLayout Wait for size and position to settle before triggering callback, using RAF to loop
 * @param stableFrames Frame count in which element size and position needs to be stable before triggering a callback
 */
function useResizeObserver(onResize, refObject, waitStableLayout = false, stableFrames = 1) {
    const layoutSettle = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        const element = getElement(refObject);
        if (!element || typeof window === 'undefined')
            return;
        let prevElementRect = element.getBoundingClientRect();
        let matchedFrames = 0;
        let framesSpent = 0;
        while (framesSpent < 30) {
            // eslint-disable-next-line no-await-in-loop
            yield new Promise((resolve) => window.requestAnimationFrame(resolve));
            framesSpent += 1;
            const newElementRect = element.getBoundingClientRect();
            if (isEqualDOMRect(prevElementRect, newElementRect)) {
                matchedFrames += 1;
            }
            else {
                matchedFrames = 0;
                prevElementRect = newElementRect;
            }
            if (matchedFrames >= (stableFrames !== null && stableFrames !== void 0 ? stableFrames : 2))
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [refObject, stableFrames]);
    const handleResizeEvent = useCallback(() => {
        if (waitStableLayout) {
            layoutSettle().then(onResize);
        }
        else {
            onResize();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [waitStableLayout, onResize]);
    useLayoutEffect(() => {
        var _a;
        const element = getElement(refObject);
        let resizeObserver;
        if (typeof window === 'undefined')
            return () => undefined;
        if ((window === null || window === void 0 ? void 0 : window.ResizeObserver) && element) {
            resizeObserver = new ResizeObserver(handleResizeEvent);
            resizeObserver.observe(element);
        }
        else {
            window.addEventListener('resize', handleResizeEvent, true);
            if ((_a = window === null || window === void 0 ? void 0 : window.screen) === null || _a === void 0 ? void 0 : _a.orientation) {
                window.screen.orientation.addEventListener('change', handleResizeEvent, true);
            }
            else {
                window === null || window === void 0 ? void 0 : window.addEventListener('orientationchange', handleResizeEvent, true);
            }
        }
        handleResizeEvent();
        return () => {
            var _a, _b;
            resizeObserver === null || resizeObserver === void 0 ? void 0 : resizeObserver.disconnect();
            if (typeof window === 'undefined')
                return;
            window === null || window === void 0 ? void 0 : window.removeEventListener('resize', handleResizeEvent, true);
            window === null || window === void 0 ? void 0 : window.removeEventListener('orientationchange', handleResizeEvent, true);
            (_b = (_a = window === null || window === void 0 ? void 0 : window.screen) === null || _a === void 0 ? void 0 : _a.orientation) === null || _b === void 0 ? void 0 : _b.removeEventListener('change', handleResizeEvent, true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refObject, waitStableLayout]);
}
export default useResizeObserver;
