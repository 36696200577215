import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Transition } from '@headlessui/react';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { useLayoutEffect, useRef } from 'react';
import useGlobalContext from '../../../contexts/useGlobalContext';
import useServices from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
import useResizeObserver from '../../../hooks/useResizeObserver';
import CanvasMiniMap from '../../canvasMiniMap/CanvasMiniMap';
import isToolbarVisible from '../utils/isToolbarVisible';
import ErrorBoundary from './ErrorBoundary';
/** This is set based on css styling */
const MAX_SIZE = 440;
/** This is set based on css styling */
const MIN_SIZE = 160;
const MiniMapCircle = ({ enabledNavigation, isSentryEnabled }) => {
    const { engine, squareSize, setSquareSize } = useGlobalContext();
    const { layoutService } = useServices();
    const containerRef = useRef(null);
    const canvasRef = useRef(engine.getCanvasElement());
    const prevCanvasRect = useRef({ width: 0, height: 0 });
    const { isNavigationOpen } = useReactive(layoutService, ['isNavigationOpen']);
    const handleSizeChange = () => {
        var _a;
        const containerRect = (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        const canvasRect = engine.getCanvasBoundingClientRect();
        prevCanvasRect.current = canvasRect;
        if (!containerRect || !canvasRect)
            return;
        // Container bounding rect relative to the canvas, this is needed in case canvas is not full screen in lib mode
        const relativeRect = {
            top: containerRect.top - canvasRect.top,
            left: containerRect.left - canvasRect.left,
            bottom: containerRect.bottom - canvasRect.top,
            right: containerRect.right - canvasRect.left,
        };
        // Bottom padding includes the bottom controls size and top padding is the same as left padding
        const verticalPadding = canvasRect.height - relativeRect.bottom + relativeRect.left;
        // Right and left paddings are the same
        const horizontalPadding = relativeRect.left * 2;
        const freeVerticalSpace = canvasRect.height - verticalPadding;
        const freeHorizontalSpace = canvasRect.width - horizontalPadding;
        // Just use the smaller of the two
        const freeSpace = Math.min(freeVerticalSpace, freeHorizontalSpace);
        const isLandscapeLayout = canvasRect.width > canvasRect.height;
        // Minimap in landscape mode should never go over 30% of the viewport width, but still is constrained by the max size
        // This will cause a jump in size when switching between landscape and portrait, but we decided to keep it like this
        const maxMiniMapSize = isLandscapeLayout ? Math.min(MAX_SIZE, canvasRect.width * 0.3) : MAX_SIZE;
        // The final size of the minimap is the smallest of the maximum size and the available space, but not smaller than MIN_SIZE
        const containedSize = Math.max(Math.min(maxMiniMapSize, freeSpace), MIN_SIZE);
        setSquareSize(containedSize);
    };
    useResizeObserver(handleSizeChange, canvasRef, isNavigationOpen, 1);
    useLayoutEffect(() => {
        handleSizeChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNavigationOpen]);
    const ErrorBoundaryComponent = isSentryEnabled ? SentryErrorBoundary : ErrorBoundary;
    return (_jsx(_Fragment, { children: _jsx("div", { ref: containerRef, className: `absolute max-h-[27.5rem] min-h-[10rem] min-w-[10rem] max-w-[27.5rem] select-none ${!isToolbarVisible(enabledNavigation) ? 'bottom-0' : 'smvh:mb-6 bottom-10 mb-4'}`, style: { width: `${squareSize.width}px`, height: `${squareSize.width}px` }, children: _jsx(Transition, { show: isNavigationOpen, className: "h-full w-full overflow-hidden rounded-full", enter: "transition-all duration-[600ms] ease-out", enterFrom: "-translate-x-1/3 opacity-0", enterTo: "opacity-100", leave: "transition-all duration-[600ms] ease-out", leaveFrom: "opacity-100", leaveTo: "-translate-x-1/3 opacity-0", children: _jsx(ErrorBoundaryComponent, { fallback: _jsx(_Fragment, {}), children: enabledNavigation.miniMap && _jsx(CanvasMiniMap, { parentContainer: containerRef.current }) }) }) }) }));
};
export default MiniMapCircle;
