import parsePhoneNumber, { AsYouType, getCountryCallingCode } from 'libphonenumber-js/core';
/** This is a wrapper class for libphonenumber-js for easier metadata handling and clearer separation */
class LibPhoneNumber {
    constructor(metadata) {
        /**
         * Json metadata for the libphonenumber-js
         * Origin source is google/libphonenumber metadata compiled to json by libphonenumber-js
         * Data is used for phone number parsing, formatting and validation
         * @important we need to keep this up to date, otherwise validation may fail for some phone numbers when it shouldn't
         */
        this.metadata = null;
        this.metadata = metadata;
    }
    getCountryCallingCode(countryCode) {
        if (!this.metadata)
            return null;
        return getCountryCallingCode(countryCode, this.metadata);
    }
    /** Use libphonenumber-js to validate the phone number, this should be used as final validation, not as you type parsing */
    validatePhoneNumber(phoneNumber) {
        var _a;
        if (!this.metadata)
            return null;
        // libphonenumber-js/parsePhoneNumber returns undefined if the number is invalid,
        // so to keep things simple return a partial mocked-up object instead
        return (parsePhoneNumber(phoneNumber, this.metadata) || {
            countryCallingCode: (_a = phoneNumber.split(' ')[0]) === null || _a === void 0 ? void 0 : _a.replace('+', ''),
            nationalNumber: phoneNumber.split(' ')[1],
            number: phoneNumber.replace(' ', ''),
            isValid: () => false,
        });
    }
    /** Use libphonenumber-js to parse the phone number as you type */
    formatPhoneNumber(phoneNumber) {
        var _a, _b, _c;
        if (!this.metadata)
            return {
                formattedNumber: phoneNumber,
                nationalNumber: null,
                callingCode: null,
                countryCode: null,
            };
        const formatter = new AsYouType(undefined, this.metadata);
        let formattedNumber = formatter.input(phoneNumber);
        if (formattedNumber.trim() === '')
            formattedNumber = '+';
        const phoneNumberObject = formatter.getNumber();
        return {
            formattedNumber,
            nationalNumber: (_a = phoneNumberObject === null || phoneNumberObject === void 0 ? void 0 : phoneNumberObject.nationalNumber) !== null && _a !== void 0 ? _a : null,
            callingCode: (_b = formatter.getCallingCode()) !== null && _b !== void 0 ? _b : null,
            countryCode: (_c = formatter.getCountry()) !== null && _c !== void 0 ? _c : null,
        };
    }
}
export default LibPhoneNumber;
