import { RoomNotification, NotificationDialog } from "components/elements";
import { BellIcon } from "components/icons";
import {
  useAppStateContext,
  useDebouncedEffect,
  useMixpanelContext,
  useRoomContext,
  useSound,
} from "hooks";
import uniqueId from "lodash/uniqueId";
import { useCallback, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sound from "sound/Bass_High_Lite.wav";
import { participantIdentityToName } from "utils";

const TOAST_DELAY = 500;
const toastUniqId = () => uniqueId("toast-");

const useToasts = (
  inControlParticipant: string,
  needApprovalUserIdentities: string[],
  approveParticipant: (identity: string) => void,
  declineParticipant: (identity: string) => void
) => {
  const { isHost } = useAppStateContext();
  const { Mixpanel, MixpanelEvents } = useMixpanelContext();
  const { localParticipant } = useRoomContext();
  const isFirstRender = useRef<boolean>(true);

  const audio = useSound(sound, {
    volume: 0.3,
    playbackRate: 1.5,
  });

  const displayControlMessage = useCallback(() => {
    if (!inControlParticipant) return;
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    let message = "You are in control";
    if (inControlParticipant !== localParticipant?.identity) {
      message = isHost ? "Guest is in control" : "Host is in control";
    }

    const id = toastUniqId();
    toast(
      <RoomNotification
        width="sm"
        msg={message}
        icon={<BellIcon className="w-6 h-6 fill-[#FFFFFF]" />}
        handelClose={() => {
          toast.dismiss(id);
        }}
      />,
      {
        toastId: id,
        closeButton: false,
        progress: undefined,
        position: toast.POSITION.TOP_CENTER,
      }
    );
  }, [inControlParticipant, isHost, localParticipant]);

  useDebouncedEffect(
    () => displayControlMessage(),
    [inControlParticipant],
    TOAST_DELAY
  );

  useEffect(() => {
    if (!isHost) return;
    for (let i = 0; i < needApprovalUserIdentities.length; i++) {
      const identity = needApprovalUserIdentities[i];
      if (!identity) return;

      audio.play().catch((err) => console.log(err));

      toast(
        <NotificationDialog
          btnTitle="Admit"
          secondaryBtnTitle="Deny"
          title="wants to join the session"
          msg={participantIdentityToName(identity)}
          handleClick={() => {
            approveParticipant(identity);
            Mixpanel.track(MixpanelEvents.Button.Primary.Click("Admit"));
            toast.dismiss(identity);
          }}
          handleClose={() => {
            declineParticipant(identity);
            Mixpanel.track(MixpanelEvents.Button.Secondary.Click("Deny"));
            toast.dismiss(identity);
          }}
        />,
        {
          autoClose: false,
          draggable: false,
          toastId: identity,
          closeButton: false,
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  }, [
    audio,
    isHost,
    needApprovalUserIdentities,
    declineParticipant,
    approveParticipant,
  ]);
};

export default useToasts;
