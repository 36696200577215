import { captureException as sentryCaptureException, captureMessage as sentryCaptureMessage } from '@sentry/browser';

export enum LogLevel {
  /** JSDoc */
  Fatal = 'fatal',
  /** JSDoc */
  Error = 'error',
  /** JSDoc */
  Warning = 'warning',
  /** JSDoc */
  Log = 'log',
  /** JSDoc */
  Info = 'info',
  /** JSDoc */
  Debug = 'debug',
}

export const captureException = sentryCaptureException;

export const captureMessage = sentryCaptureMessage;
