import { useContext } from "react";
import { AppStateContext } from "components/contexts/AppStateContext";

export default function useAppStateContext() {
  const context = useContext(AppStateContext);
  if (!context) {
    throw new Error(
      "useAppStateContext must be used within a AppStateContextProvider"
    );
  }
  return context;
}
