import type { TextureParameters } from '../../../types/internal';
import setTextureParameters from './setTextureParameters';

/**
 * Set texture from TexImageSource.
 *
 * Uses provided texture parameters or falls back to default values before setting the texture.
 *
 * @param gl - `WebGLRenderingContext`: An interface to the OpenGL ES 2.0 graphics rendering context for the drawing
 * surface of an HTML `<canvas>` element.
 *
 * @param texture - `WebGLTexture | null`: The texture to bind and load the data into.
 *
 * @param imageSource - `TexImageSource`: One of the supported image sources -
 * ImageBitmap | ImageData | HTMLImageElement | HTMLCanvasElement | HTMLVideoElement | OffscreenCanvas
 *
 * @param restParameters - `TextureParameters` - `Optional`: an object with the rest of parameters.
 *
 * @param restParameters.minFilter - `GLenum` - `Optional`: Texture minification filter.
 *
 * Possible values: `gl.LINEAR`, `gl.NEAREST`, `gl.NEAREST_MIPMAP_NEAREST`, `gl.LINEAR_MIPMAP_NEAREST`,
 * `gl.NEAREST_MIPMAP_LINEAR`, `gl.LINEAR_MIPMAP_LINEAR`.
 *
 * Default value: `gl.LINEAR`.
 *
 * @param restParameters.magFilter - `GLenum` - `Optional`: Texture magnification filter.
 *
 * Possible values: `gl.LINEAR`, `gl.NEAREST`.
 *
 * Default value: `gl.LINEAR`.
 *
 * @param restParameters.wrapSFilter - `GLenum` - `Optional`: Wrapping function for texture coordinate `s`.
 *
 * Possible values: `gl.REPEAT`, `gl.CLAMP_TO_EDGE`, `gl.MIRRORED_REPEAT`.
 *
 * Default value: `gl.CLAMP_TO_EDGE`.
 *
 * @param restParameters.wrapTFilter - `GLenum` - `Optional`: Wrapping function for texture coordinate `t`.
 *
 * Possible values: `gl.REPEAT`, `gl.CLAMP_TO_EDGE`, `gl.MIRRORED_REPEAT`.
 *
 * Default value: `gl.CLAMP_TO_EDGE`.
 *
 * @param restParameters.useAlphaChannel - `boolean` - `Optional`: Whether to use the alpha channel. Sets the texture
 * format to `gl.RGB` if `true` and to `gl.RGBA` if `false`.
 *
 * Default value is `false`.
 *
 * @returns `void`
 */
function setTextureFromImage(
  gl: WebGLRenderingContext,
  texture: WebGLTexture | null,
  imageSource: TexImageSource,
  restParameters?: TextureParameters
): void {
  const format = restParameters?.useAlphaChannel ? gl.RGBA : gl.RGB;

  gl.bindTexture(gl.TEXTURE_2D, texture);
  // Set texture parameters if provided, will reset to default if not provided
  setTextureParameters(gl, restParameters);

  gl.texImage2D(gl.TEXTURE_2D, 0, format, format, gl.UNSIGNED_BYTE, imageSource);
}

export default setTextureFromImage;
