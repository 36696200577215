import { useRef, useEffect } from "react";

const useSound = (
  src: string,
  { volume = 1, playbackRate = 1 }
): HTMLAudioElement => {
  const audio = useRef(new Audio(src));

  useEffect(() => {
    audio.current.volume = volume;
  }, [volume]);

  useEffect(() => {
    audio.current.playbackRate = playbackRate;
  }, [playbackRate]);

  return audio.current;
};

export default useSound;
