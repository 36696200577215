import { jsx as _jsx } from "react/jsx-runtime";
import TooltipButton from '../../../common/TooltipButton';
import useGlobalContext from '../../../contexts/useGlobalContext';
import { useTranslation } from '../../../contexts/useLocalizationContext';
import useServices from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
import MeasureIcon from '../icons/MeasureIcon';
const MeasureModeButton = ({ tooltipSide = 'bottom' }) => {
    var _a;
    const { layoutService, tourConfigService, navigationService } = useServices();
    const { analytics } = useGlobalContext();
    const t = useTranslation();
    const { tourConfig } = useReactive(tourConfigService, ['tourConfig']);
    const { activeScene } = useReactive(navigationService, ['activeScene']);
    const currentScene = tourConfig.scenes[(_a = activeScene === null || activeScene === void 0 ? void 0 : activeScene.sceneKey) !== null && _a !== void 0 ? _a : 'unknown'];
    const measureModeUnavailable = (currentScene === null || currentScene === void 0 ? void 0 : currentScene.measureToolEnabled) !== true;
    return (_jsx(TooltipButton, { placement: tooltipSide, tooltipText: measureModeUnavailable ? t('measure-tool.missing-data-scene') : '', onClick: () => {
            layoutService.setMeasureMode(true);
            if (activeScene)
                analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'MSR', 'Open', { scene_id: activeScene.sceneKey });
        }, disabled: measureModeUnavailable, className: `btn-theme-primary relative h-10 w-10 p-0 ${measureModeUnavailable ? 'hover:bg-opacity-theme-low cursor-not-allowed opacity-60' : ''}`, "data-testid": "measure-btn", children: _jsx(MeasureIcon, { className: "h-8 w-8" }) }));
};
export default MeasureModeButton;
