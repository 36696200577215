import { CloseIcon } from "components/icons";
import { FC } from "react";
import { classNamesUtil } from "utils/classNamesUtil";

interface NotificationProps {
  msg?: string;
  title?: string;
  btn?: JSX.Element;
  actions?: JSX.Element;
  icon: JSX.Element;
  width?: "sm" | "md" | "lg";
  handelClose?: () => void;
}

const RoomNotification: FC<NotificationProps> = ({
  msg,
  icon,
  width = "md",
  actions,
  handelClose,
}) => {
  return (
    <div
      className={classNamesUtil(
        width === "sm" ? "w-[239px]" : "",
        width === "md" ? "w-[294px]" : "",
        width === "lg" ? "w-[393px]" : "",
        "flex items-center px-[35px] py-4 bg-minimap-background rounded-md mx-auto justify-between relative"
      )}
    >
      <div className="flex items-center justify-center">{icon}</div>
      <div
        className={classNamesUtil(
          actions ? "w-full flex items-center justify-between pl-[12px]" : ""
        )}
      >
        {msg && (
          <p
            className={classNamesUtil(
              width === "sm" ? "max-w-[229px]" : "",
              width === "md" ? "max-w-[284px]" : "",
              width === "lg" ? "max-w-[383px]" : "",
              "text-base text-white font-primary truncate tracking-[0.01em]"
            )}
          >
            {msg}
          </p>
        )}
        {actions && <div className="flex items-center">{actions}</div>}
      </div>
      {handelClose && (
        <div className="absolute top-0 right-2">
          <button
            className="flex justify-between w-full mt-2.5"
            onClick={handelClose}
          >
            <CloseIcon className="w-3 h-3 transition-all hover:fill-white fill-gray-800" />
          </button>
        </div>
      )}
    </div>
  );
};

export default RoomNotification;
