import { FC } from "react";
import { classNamesUtil } from "utils/classNamesUtil";

interface RoundButtonProps {
  isBorder?: boolean;
  isDisabled?: boolean;
  buttonRef?: any;
  btnSize?: "sm" | "md" | "lg";
  type?: "button" | "submit" | "reset";
  bgColor?: "black" | "gray" | "white";

  children: JSX.Element | JSX.Element[];
  handleClick: () => void;
}

const RoundButton: FC<RoundButtonProps> = ({
  children,
  isDisabled,
  buttonRef,
  btnSize = "md",
  type = "button",
  bgColor = "gray",
  handleClick,
}) => {
  return (
    <button
      type={type}
      ref={buttonRef}
      onClick={handleClick}
      disabled={isDisabled}
      className={classNamesUtil(
        btnSize === "md" ? "w-9 h-9 p-2" : "",
        btnSize === "sm" ? "w-8 h-8 p-[0.43rem]" : "",
        bgColor === "gray" ? "bg-giraffe-gray-super-dark" : "",
        bgColor === "black" ? "bg-[#231F20]" : "",
        isDisabled ? "cursor-not-allowed" : "cursor-pointer",
        "flex items-center justify-center text-center rounded-full cursor-pointer border-2 border-transparent"
      )}
    >
      {children}
    </button>
  );
};

export default RoundButton;
