import { jsx as _jsx } from "react/jsx-runtime";
import IdealistaLayout from './IdealistaLayout';
import MeasureModeLayout from './MeasureModeLayout';
import StandardLayout from './StandardLayout';
/** Layout switch based on the current lsf mode, or measure mode */
const DynamicLayout = ({ isMeasureMode, lsfRenderingMode, enabledNavigation, enabledUtils, isSentryEnabled, }) => {
    if (isMeasureMode) {
        return _jsx(MeasureModeLayout, {});
    }
    if (lsfRenderingMode === 'idealista') {
        return (_jsx(IdealistaLayout, { enabledNavigation: enabledNavigation, enabledUtils: enabledUtils, isSentryEnabled: isSentryEnabled }));
    }
    return (_jsx(StandardLayout, { enabledNavigation: enabledNavigation, enabledUtils: enabledUtils, isSentryEnabled: isSentryEnabled }));
};
export default DynamicLayout;
