import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useGlobalContext from '../../../contexts/useGlobalContext';
import { useTranslation } from '../../../contexts/useLocalizationContext';
import useServices from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
import CloseIcon from '../../../icons/CloseIcon';
const MeasureModeCloseButton = () => {
    const { layoutService, navigationService } = useServices();
    const t = useTranslation();
    const { analytics } = useGlobalContext();
    const { activeScene } = useReactive(navigationService, ['activeScene']);
    return (_jsx("div", { className: "flex justify-center", children: _jsxs("button", { className: `bg-theme-primary bg-opacity-theme-text-bg font-primary text-theme-contrast pointer-events-auto flex h-10 w-20 min-w-[14rem] items-center
        rounded-md px-4 text-left text-lg font-bold hover:bg-opacity-100`, type: "button", onClick: () => {
                layoutService.setMeasureMode(false);
                if (activeScene)
                    analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'MSR', 'Close', { scene_id: activeScene.sceneKey });
            }, children: [t('measure-tool.measure-mode'), _jsx("span", { className: "ml-auto", children: _jsx(CloseIcon, {}) })] }) }));
};
export default MeasureModeCloseButton;
