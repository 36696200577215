import loadBasicVertexIndices from '../loadBasicVertexIndices';

/**
 * Sets the program to be used as a part of the current rendering state and loads basic index vertices to a buffer.
 * @param gl - `WebGLRenderingContext`: An interface to the OpenGL ES 2.0 graphics rendering context for the drawing surface of an HTML `<canvas>` element.
 * @param program - `WebGLProgram | null`: The program to load.
 * @param withBasicIndices - `boolean`: A flag indicating whether to load basic index vertices to a buffer.
 * @param basicIndexBuffer - `WebGLBuffer | null` - `Optional`: A buffer to load basic index vertices into. If `null` or `undefined`, a new buffer will be created.
 * @returns `WebGLBuffer | null`: `null` if `withBasicIndices` is `false`; a new buffer if `withBasicIndices` is `true` and `basicIndexBuffer` is not provided or is `null`; the `basicIndexBuffer` parameter if `withBasicIndices` is `true` and `basicIndexBuffer` is provided and not `null`.
 */
function loadShaders(
  gl: WebGLRenderingContext,
  program: WebGLProgram | null,
  withBasicIndices = true,
  basicIndexBuffer?: WebGLBuffer | null
): WebGLBuffer | null {
  if (!program) {
    // eslint-disable-next-line no-console
    console.warn('Failed to load shaders!');
    throw new Error('SHADER_ERROR');
  }

  gl.useProgram(program);

  if (withBasicIndices) return loadBasicVertexIndices(gl, basicIndexBuffer);
  return null;
}

export default loadShaders;
