import { useEffect } from "react";

const useDebouncedEffect = (
  effect: () => void,
  deps: unknown[],
  delay: number
) => {
  useEffect(() => {
    if (!delay && delay !== 0) {
      return;
    }

    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay]);
};

export default useDebouncedEffect;
