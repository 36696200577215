// eslint-disable-next-line class-methods-use-this

import type { FPMesh } from '@g360/vt-types';
import { isPointInPolygon } from '@g360/vt-utils';

export function isMeshOnMovedFloor(mesh: { shape2d: [number, number][] }, floorPolygon: [number, number][]): boolean {
  for (let i = 0; i < mesh.shape2d.length; i += 1) {
    if (isPointInPolygon(mesh.shape2d[i], floorPolygon)) {
      return true;
    }
  }
  return false;
}

export function tryCutawayWall(towardsCamera: boolean, mesh_: FPMesh) {
  // Don't draw top walls (if lowered)
  if (towardsCamera && mesh_.isTopWall) {
    mesh_.skipSolidRendering = true;
  }

  // Don't draw wall caps on top walls (if lowered)
  if (towardsCamera && mesh_.isWallCap && mesh_.isTopWall) {
    mesh_.skipSolidRendering = true;
    mesh_.skipFlatRendering = true;
  }

  // Don't draw wall caps on bottom wall in flat renderer (if not lowered)
  if (!towardsCamera && mesh_.isWallCap && mesh_.isBottomWall) {
    mesh_.skipSolidRendering = false;
    mesh_.skipFlatRendering = true;
  }

  // Don't draw wall caps on bottom walls (if lowered) ..
  if (towardsCamera && mesh_.isWallCap && mesh_.isBottomWall) {
    mesh_.skipSolidRendering = true; // ..textured shader only, still draw in flat shader)
  }

  // Don't draw side caps on top walls (if lowered)
  if (towardsCamera && mesh_.isSideCap) {
    mesh_.skipSolidRendering = true;
    mesh_.skipFlatRendering = true;
  }

  mesh_.isWallLowered = towardsCamera;
}
