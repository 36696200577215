import type { AutoPlaySceneFrames } from '@g360/vt-types';

/** Take in autoplay scene frames and scale the timeline by the speed value
 * Smaller speed means longer timeline
 * @param sceneFrames - the AutoPlaySceneFrames frames to scale
 * @param speed - the speed value to apply to the scene frames
 * @returns scaled AutoPlaySceneFrames frames
 */
export default function scaleScenesFrames(sceneFrames: AutoPlaySceneFrames[], speed: number) {
  if (speed === 1) return sceneFrames;

  return sceneFrames.map((sceneFrame) => ({
    ...sceneFrame,
    frames: sceneFrame.frames.map((frame) => ({
      ...frame,
      // NOTE: division is used because we need to scale the playback speed
      // and not the duration of the autoplay
      duration: frame.duration / speed,
    })),
  }));
}
