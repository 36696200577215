import { useEffect, useState } from "react";

type BrowserType =
  | "Opera"
  | "unknown"
  | "Apple Safari"
  | "Microsoft Edge"
  | "Mozilla Firefox"
  | "Samsung Internet"
  | "Google Chrome or Chromium"
  | "Microsoft Internet Explorer";

const useBrowser = () => {
  const [browser, setBrowser] = useState<BrowserType>("unknown");

  useEffect(() => {
    const uAgent = navigator.userAgent;
    let sBrowser: BrowserType;

    if (uAgent.indexOf("Firefox") > -1) {
      sBrowser = "Mozilla Firefox";
    } else if (uAgent.indexOf("SamsungBrowser") > -1) {
      sBrowser = "Samsung Internet";
    } else if (uAgent.indexOf("Opera") > -1 || uAgent.indexOf("OPR") > -1) {
      sBrowser = "Opera";
    } else if (uAgent.indexOf("Trident") > -1) {
      sBrowser = "Microsoft Internet Explorer";
    } else if (uAgent.indexOf("Edge") > -1) {
      sBrowser = "Microsoft Edge";
    } else if (uAgent.indexOf("Chrome") > -1) {
      sBrowser = "Google Chrome or Chromium";
    } else if (uAgent.indexOf("Safari") > -1) {
      sBrowser = "Apple Safari";
    } else {
      sBrowser = "unknown";
    }

    setBrowser(sBrowser);
  }, []);

  return browser;
};

export default useBrowser;
