import React from 'react';
export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: undefined };
    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }
    render() {
        const { hasError, error } = this.state;
        const { fallback, children } = this.props;
        if (hasError) {
            return typeof fallback === 'function' ? fallback(error) : fallback;
        }
        return children;
    }
}
