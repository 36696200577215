/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
import EventEmitter from '../EventEmitter';

export type Constructor<T, A extends any[]> = {
  reactiveProps?: string[];
  new (...args: A): T;
};

const getDefaultAccessors = (init: any) => {
  let value = init;
  return {
    set(newVal: any) {
      const changed = value !== newVal;
      value = newVal;
      return changed;
    },
    get() {
      return value;
    },
    reset() {
      value = init;
    },
  };
};

// eslint-disable-next-line @typescript-eslint/ban-types
export function MakeReactive<T extends Record<any, any>, A extends any[], Key extends string & keyof T>(
  MainClass: Constructor<T, A>,
  keys: Array<Key>
) {
  const props: Key[] = keys;
  const watchers = Symbol('watchers');
  const initializing = Symbol('initializing');
  const emitter = Symbol('emitter');
  class ExtendedClass extends (MainClass as any) {
    private [emitter] = new EventEmitter();
    // internal states that is used for registering reactive variables while watching them
    private [watchers]: Key[] | null = null;
    private [initializing] = false;
    private reset = () => {};

    constructor(...args: A) {
      super(...args);
      const clearCBs = props.map((prop) => this.makePropReactive(prop));
      this.reset = () => {
        clearCBs.forEach((cb) => cb());
      };
      // call init method after making the object reactive
      if (typeof this.init === 'function') {
        this.init();
      }
    }

    makePropReactive(prop: string) {
      if (typeof prop !== 'string') throw new Error('prop must be a string');
      const descriptor = Object.getOwnPropertyDescriptor(this, prop);
      let dirty = false;

      let accessor = getDefaultAccessors((this as any)[prop]);
      if (descriptor && (descriptor.set || descriptor.get)) {
        accessor = {
          set() {
            return false;
          },
          get() {
            return undefined;
          },
          reset() {},
        };
        // if the property has set function use that one to set value
        if (descriptor && descriptor.set) {
          const setFn = descriptor && descriptor.set;
          const getFn = descriptor.get || (() => undefined);
          accessor.set = (newVal: any) => {
            const old = getFn.call(this);
            setFn.call(this, newVal);
            const val = getFn.call(this);
            if (old !== val) {
              return true;
            }
            return false;
          };
        }
        if (descriptor && descriptor.get) {
          const getFn = descriptor.get;
          accessor.get = () => getFn.call(this);
        }
      }

      Object.defineProperty(this, prop, {
        get() {
          if (this[watchers] && !this[watchers].includes(prop)) {
            this[watchers].push(prop);
          }
          return accessor.get();
        },
        set(newValue: any) {
          if (this[initializing] && dirty) return;

          const prev = accessor.get();
          if (accessor.set(newValue)) {
            // don't set dirty while initializing
            if (!this[initializing]) {
              dirty = true;
            }
            this[emitter].emit(prop, accessor.get(), prev);
          }
        },
      });
      // reset to initial value
      return () => {
        dirty = false;
        accessor.reset();
      };
    }

    onChange(eventType: Key, listener: (val: T[Key], prev: T[Key]) => void) {
      const sub = this[emitter].subscribe(eventType, listener);
      return () => sub.unsubscribe();
    }

    reInitialize(cb: () => void) {
      this[initializing] = true;
      cb();
      this[initializing] = false;
    }

    /**
     * run watcher in correct context
     */
    runWatcher(cb) {
      const currentWatchers = this[watchers];
      this[watchers] = [];
      cb();
      const listeners = this[watchers];
      this[watchers] = currentWatchers;
      return listeners;
    }

    /**
     * call the callback function automatically if dependencies changed
     */
    watch(cb: () => void, state = { stopped: false }) {
      // run the callback that cause all reactive variable are registered in the watches array
      if (!state.stopped) {
        const listeners = this.runWatcher(cb);
        this[emitter].once(listeners, () => {
          this.watch(cb, state);
        });
      }

      return () => {
        state.stopped = true;
      };
    }
  }
  type ExtendedObject = T &
    ReactiveBase & {
      onChange<K extends Key & keyof T>(eventType: K, listener: (value: T[K], prev: T[K]) => void): () => void;
    };
  return ExtendedClass as any as Constructor<ExtendedObject, A>;
}

export class ReactiveBase {
  /**
   * Note: the callback **should not be async function**. if you need to do async operation you can use onChange method.
   * watch all reactive variables that are used in the passed callback and reruns the callback if something changed.
   * it looks like magic ;) but it is not. this is the same as vue.js watch method
   * or SolidJS createEffect function.
   * the way it works is that it runs the callback immediately and register all reactive variables that are used in the callback.
   */
  watch(cb: () => void): () => void {
    throw new Error('watch method should be implemented with the reactive subclass');
  }

  /**
   * reset all props to initial value
   */
  reset() {
    throw new Error('reset method should be implemented with the reactive subclass');
  }

  /**
   * the reactive features are not available in the constructor. if you need reactive features while object is generating
   * you can put them in init() method that is called automatically after setting up reactive object.
   */
  protected init() {}

  /**
   * change initial values of properties without calling event listeners. if a property has been changed after first initializing then it wont be updated here,
   * Actually the reactive util tracks changes and considers a property as dirty if we changed it outside the constructor method
   * you can pass forceUpdateAll if you want to disable skipping dirty state updating
   * @example
   * this.reInitialize(()=>{
   *   // value only is changed if this.$prop has not been changed after initializing
   *   this.$prop = 'new_val'
   * })
   *
   */
  protected reInitialize(cb: () => void) {
    throw new Error('initialize method should be implemented with the reactive subclass');
  }
}
