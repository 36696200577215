const participantIdentityToName = (participantIdentity?: string): string => {
  if (!participantIdentity) {
    return "";
  }
  // get all characters before "-"
  const identity = participantIdentity.substr(
    0,
    participantIdentity.indexOf("-")
  );
  const formattedIdentity = identity.replace("_", " ");
  return formattedIdentity;
};

const nameToParticipantIdentity = (name?: string): string => {
  if (!name) return "";
  let hash = (Math.random() + 1).toString(36).substring(7);

  const formattedStr = name.trim().replace(" ", "_");
  return `${formattedStr}-${hash}`;
};

export { participantIdentityToName, nameToParticipantIdentity };
