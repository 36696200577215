import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import noop from 'lodash/noop';
import { useState } from 'react';
import SpinnerIcon from '../icons/SpinnerIcon';
const composeButtonStyle = (variant, state, loading = false) => {
    const s = {
        primary: {
            bg: {
                normal: 'bg-modal-dark',
                disabled: 'bg-modal-gray-light',
            },
            hover: 'hover:bg-[#4D5662]',
            text: {
                normal: 'text-white',
                disabled: 'text-[#767A82]',
            },
        },
        secondary: {
            bg: {
                normal: 'border-2 border-modal-dark',
                disabled: 'border-2 border-modal-gray-light',
            },
            hover: 'hover:border-[#4D5662]',
            text: {
                normal: 'text-modal-dark',
                disabled: 'text-[#767A82]',
            },
        },
    };
    return `${s[variant].bg[state]} ${s[variant].text[state]} ${state === 'normal' && !loading ? s[variant].hover : ''}`;
};
const TextButton = ({ className = '', variant = 'primary', disabled = false, loading, tooltip, children, onClick, testId, }) => {
    const [showToolTip, setShowTooltip] = useState(false);
    const state = disabled ? 'disabled' : 'normal';
    const twButtonStyle = composeButtonStyle(variant, state, loading);
    return (_jsxs("button", { className: `font-primary pointer-events-auto relative flex h-10 min-w-[8.75rem]
        items-center justify-center rounded-lg px-2 text-center
        text-base font-bold disabled:cursor-not-allowed ${twButtonStyle} ${className}`, type: "button", onClick: disabled || loading ? noop : onClick, onMouseEnter: () => setShowTooltip(true), onMouseLeave: () => setShowTooltip(false), onTouchStart: () => setShowTooltip(true), onTouchEnd: () => setShowTooltip(false), "data-testid": testId, children: [loading ? _jsx(SpinnerIcon, { className: "h-6 w-6", isDarkSpinner: true }) : children, showToolTip && tooltip && (_jsx("div", { className: "bg-modal-gray-dark font-primary absolute bottom-full mb-2 whitespace-nowrap rounded-md\n            px-[0.62rem] pb-[0.31rem] pt-[0.25rem] text-xs font-bold text-white", children: tooltip }))] }));
};
export default TextButton;
