/* eslint-disable @typescript-eslint/no-empty-function */

import type { AutoplayDebugData, Graph, Panos } from '@g360/vt-types';

import { debugDrawFloorPlan, debugVisualizeGraph } from './debugUtils';
import { createPathSmart } from './graphUtils';

const debugDraw = (debugData: AutoplayDebugData, panos: Panos, engine: any) => {
  // ?debugDrawFloorPlanAndCamera=true&debugAnimateCurrentSubGraph=true&debugAnimateWholeGraph=false
  const params = new URLSearchParams(window.location.search);
  const debugDrawFloorPlanAndCamera = params.get('debugDrawFloorPlanAndCamera') === 'true';
  const debugAnimateCurrentSubGraph = params.get('debugAnimateCurrentSubGraph') === 'true';
  const debugAnimateWholeGraph = params.get('debugAnimateWholeGraph') === 'true';
  const firstSceneYaw = debugData.firstSceneYaw;
  const { dataJson, dataPanos, path, debugPointsOfInterest, wholeGraph, subGraphs, firstSceneKey } = debugData;

  // -----------------------------------floor plan, camera, points-of-interest ---------------------------------------
  if (debugDrawFloorPlanAndCamera) {
    let currentSceneKey = firstSceneKey;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.runAtTransition = (sceneKeyFrom: string, sceneKeyTo: string) => {
      // Engine::Renderer hack: will be called on transition
      currentSceneKey = sceneKeyTo;
    };

    let lastDrawCacheKey = '';
    const draw = (forceDraw = false) => {
      let fov = engine.getFov();
      const yaw = engine.getYaw() - Math.PI / 2; // half-a-radian is needed cos the floorplan axis is rotated 90deg

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore -- there definitely is such method, but i can't compile Engine unless Utils compiles first
      let actualCameraPos: number[] | undefined = engine.geActualCameraPos();
      // in Engine.ts:
      //      geActualCameraPos(inverse = true): number[] {
      //        if (inverse)return this.actualCameraPos.map((pos) => Math.round(-pos));
      //        return this.actualCameraPos;
      //    }

      if (!actualCameraPos) {
        console.error('there was no engine.geActualCameraPos() !');
        actualCameraPos = [0, 0];
      }

      if (forceDraw) {
        lastDrawCacheKey = '--';
        fov += 0.00001;
      }
      const cacheKey = `${currentSceneKey}__${fov}_${yaw}_${actualCameraPos[0]}_${actualCameraPos[1]}`;
      if (cacheKey === lastDrawCacheKey) return;
      debugDrawFloorPlan(
        dataJson,
        dataPanos,
        path,
        debugPointsOfInterest,
        currentSceneKey,
        firstSceneYaw,
        fov,
        yaw,
        actualCameraPos
      );
      lastDrawCacheKey = cacheKey;
    };
    draw(true);

    setInterval(() => {
      draw();
    }, 16); // poor man's requestAnimationFrame
  }

  // ----------------------------------------------current sub-graph path---------------------------------------------

  if (debugAnimateCurrentSubGraph) {
    let currentAnimatedSubGraphId = '--';
    const debugAnim = (sceneKeyTo: string) => {
      const subGraphId = panos[sceneKeyTo].subGraphId as string;
      if (currentAnimatedSubGraphId === subGraphId) return; // already animating this subGraph
      currentAnimatedSubGraphId = subGraphId;
      const firstNode = sceneKeyTo;
      const subGraph = subGraphs.get(subGraphId) as Graph;
      const pathInSubGraph = createPathSmart(subGraph, firstNode, panos);
      const x = 1000;
      const y = 70;
      const scale = 2;
      debugVisualizeGraph(`current-sub-anim`, panos, subGraph, subGraphId, scale, y, x, '', pathInSubGraph); // prettier-ignore
      debugVisualizeGraph(`current-sub-static`, panos, subGraph, subGraphId, scale, y, x); // prettier-ignore
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.runAtTransition2 = (sceneKeyFrom: string, sceneKeyTo: string) => {
      // Engine::Renderer hack: will be called on transition
      debugAnim(sceneKeyTo);
    };
    debugAnim(firstSceneKey);
  }

  // ----------------------------------------------whole graph and path---------------------------------------------
  if (debugAnimateWholeGraph) {
    debugVisualizeGraph('whole-stat', panos, wholeGraph);
    debugVisualizeGraph('whole-anim', panos, wholeGraph, undefined, 5, 0, 0, '', path); // prettier-ignore
  }
};

export default debugDraw;
