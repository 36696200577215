import { useCallback, useEffect, useState } from "react";
import { isMobile } from "func/isMobile";
import { useDebouncedEffect, useDeviceStateContext } from "hooks";
import {
  LocalParticipant,
  LocalVideoTrack,
  LocalAudioTrack,
} from "twilio-video";

const useLocalTrackToggles = (
  localParticipant: LocalParticipant | null,
  videoTrack: LocalVideoTrack | undefined,
  audioTrack: LocalAudioTrack | undefined,
  getLocalVideoTrack: () => Promise<undefined | LocalVideoTrack>,
  removeLocalVideoTrack: () => void,
  getLocalAudioTrack: () => Promise<undefined | LocalAudioTrack>,
  removeLocalAudioTrack: () => void
) => {
  const [isPublishingVideo, setIsPublishingVideo] = useState(false);
  const [isPublishingAudio, setIsPublishingAudio] = useState(false);

  const {
    isVideoOn,
    isAudioOn,
    videoAccess,
    selectedVideoInput,
    audioAccess,
    selectedAudioInput,
    performAudioCheck,
    performVideoCheck,
    setVideoAccess,
    setAudioAccess,
  } = useDeviceStateContext();

  const toggleVideoTrack = useCallback(
    (enabled: boolean) => {
      if (isPublishingVideo) return;
      if (!enabled && videoTrack) {
        const localTrackPublication =
          localParticipant?.unpublishTrack(videoTrack);
        // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
        localParticipant?.emit("trackUnpublished", localTrackPublication);
        removeLocalVideoTrack();
      } else if (enabled) {
        setIsPublishingVideo(true);
        getLocalVideoTrack()
          .then((track: LocalVideoTrack | undefined) => {
            if (track) {
              localParticipant?.publishTrack(track);
            }
          })
          .catch((err) => {
            console.error("Track publish error", err);
            setVideoAccess(false);
          })
          .finally(() => {
            setIsPublishingVideo(false);
          });
      }
    },
    [
      videoTrack,
      localParticipant,
      removeLocalVideoTrack,
      getLocalVideoTrack,
      isPublishingVideo,
    ]
  );

  const toggleAudioTrack = useCallback(
    (enabled: boolean) => {
      if (isPublishingAudio) return;
      if (!enabled && audioTrack) {
        const localTrackPublication =
          localParticipant?.unpublishTrack(audioTrack);
        // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
        localParticipant?.emit("trackUnpublished", localTrackPublication);
        removeLocalAudioTrack();
      } else if (enabled) {
        setIsPublishingAudio(true);
        getLocalAudioTrack()
          .then((track: LocalAudioTrack | undefined) => {
            if (track) {
              localParticipant?.publishTrack(track);
            }
          })
          .catch((err) => {
            console.error("Track publish error", err);
            setAudioAccess(false);
          })
          .finally(() => {
            setIsPublishingAudio(false);
          });
      }
    },
    [
      audioTrack,
      removeLocalAudioTrack,
      getLocalAudioTrack,
      isPublishingAudio,
      localParticipant,
    ]
  );

  const toggleAudioEnabled = useCallback(
    (enabled: boolean) => {
      if (audioTrack) {
        if (enabled) {
          audioTrack.enable();
        } else {
          audioTrack.disable();
        }
      }
    },
    [audioTrack]
  );

  useDebouncedEffect(() => toggleVideoTrack(isVideoOn), [isVideoOn], 200);
  useEffect(
    () => toggleAudioEnabled(isAudioOn),
    [isAudioOn, toggleAudioEnabled]
  );

  useEffect(() => {
    if (!audioAccess) {
      toggleAudioTrack(false);
    }
  }, [audioAccess]);

  useEffect(() => {
    if (selectedAudioInput) {
      toggleAudioTrack(true);
    }
  }, [selectedAudioInput]);

  useEffect(() => {
    if (!videoAccess) {
      toggleVideoTrack(false);
    }
  }, [videoAccess]);

  useEffect(() => {
    if (selectedVideoInput) {
      toggleVideoTrack(true);
    }
  }, [selectedVideoInput]);

  useEffect(() => {
    // Bug on MacOS. Need to perform cleanup otherwise AirPods get stuck in the killed track and do not work.
    const disableTracks = () => {
      toggleVideoTrack(false);
      toggleAudioTrack(false);
    };

    // On mobile browsers if app is backgrounded than the video stream is not available any more
    // so need to unpublish so that there is no black screen.
    let videoForegroundTimeout: ReturnType<typeof setTimeout>;
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        // The app has been backgrounded. So, stop and unpublish your LocalVideoTrack.
        toggleVideoTrack(false);
      } else {
        // The app has been foregrounded, So, create and publish a new LocalVideoTrack.
        videoForegroundTimeout = setTimeout(() => toggleVideoTrack(true), 500);
      }
    };

    window.addEventListener("beforeunload", disableTracks);
    if (isMobile()) {
      // Add a listener to disconnect from the room when a mobile user closes their browser
      window.addEventListener("pagehide", disableTracks);
      document.addEventListener("visibilitychange", handleVisibilityChange);
    }

    return () => {
      window.removeEventListener("beforeunload", disableTracks);
      if (isMobile()) {
        window.removeEventListener("pagehide", disableTracks);
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      }
      clearTimeout(videoForegroundTimeout);
    };
  }, [toggleVideoTrack, toggleAudioTrack]);

  useEffect(() => {
    if (audioTrack) {
      // Handle cases when the user blocks audio during the meeting - need to recheck the access.
      audioTrack.on("stopped", performAudioCheck);
    }
  }, [audioTrack, performAudioCheck]);

  useEffect(() => {
    if (videoTrack) {
      // Handle cases when the user blocks video during the meeting - need to recheck the access.
      videoTrack.on("stopped", performVideoCheck);
    }
  }, [videoTrack, performVideoCheck]);
};

export default useLocalTrackToggles;
