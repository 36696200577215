import { SVGProps } from "react";

const PeopleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 100 54"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
    }}
    {...props}
  >
    <path
      d="M67.666 35.845C70.453 34.127 74.135 33 79 33c14.716 0 18.61 10.306 19.637 16.121.377 2.138-1.368 3.879-3.538 3.879H63"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#000",
        strokeWidth: 2,
      }}
    />
    <circle
      cx={79}
      cy={18}
      r={11}
      style={{
        fill: "none",
        stroke: "#000",
        strokeWidth: 2,
        strokeLinejoin: "miter",
      }}
    />
    <path
      d="M32.336 35.845C29.549 34.127 25.866 33 21.001 33 6.286 33 2.391 43.306 1.365 49.121.988 51.259 2.732 53 4.902 53H37"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#000",
        strokeWidth: 2,
      }}
    />
    <circle
      r={11}
      style={{
        fill: "none",
        stroke: "#000",
        strokeWidth: 2,
        strokeLinejoin: "miter",
      }}
      transform="matrix(-1 0 0 1 21 18)"
    />
    <path
      d="M68.922 53H31.078c-2.239 0-4.011-1.842-3.65-4.052C28.621 41.639 33.118 27 50 27c16.882 0 21.379 14.639 22.572 21.948.361 2.21-1.41 4.052-3.65 4.052Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#000",
        strokeWidth: 2,
      }}
    />
    <circle
      cx={50}
      cy={12}
      r={11}
      style={{
        fill: "none",
        stroke: "#000",
        strokeWidth: 2,
        strokeLinejoin: "miter",
      }}
    />
  </svg>
);

export default PeopleIcon;
