import type { SceneConfig } from '@g360/vt-types';

import type { WallInfo } from '../../types/internal';
import { transitionSettings } from '../Globals';
import RendererDebug from '../RendererDebug';
import Utils from './index';
import { getCameraWallCrossPoint } from './Utils';

export function areScenesConnected(scene1: SceneConfig, scene2: SceneConfig): boolean {
  return (scene1.geometry && scene2.geometry && scene1.building === scene2.building && scene1.level === scene2.level) === true; // prettier-ignore
}

export function getTransitionDirection(
  yaw: number,
  previousSceneConfig: SceneConfig,
  nextSceneConfig: SceneConfig
): number {
  let direction = 0;
  const v1 = previousSceneConfig.camera.slice(0, 2);
  const v2 = nextSceneConfig.camera.slice(0, 2);
  const angle = Utils.getYawAngleFromV1ToV2(v1, v2);

  if (!Number.isNaN(angle)) {
    direction = Math.abs(angle - yaw + Math.PI / 2) % (Math.PI * 2);
    // make symmetrical on 0-180 axis (when imagining as circle)
    direction = direction > Math.PI ? Math.PI * 2 - direction : direction;
  }

  return direction;
}

export function cutOutWallIfEncountered(sceneConfig: SceneConfig, prevSceneConfig: SceneConfig): WallInfo {
  const result: WallInfo = {
    nearestWall: null, // 3D point where first wall is encountered
    farthestWall: null, // .. and the last wall
    wallVector: null,
  };
  const wallskipPerceivableDistance = RendererDebug.getDebugParam('wallskipPerceivableDistance', transitionSettings.wallskipPerceivableDistance); // prettier-ignore
  const geom = sceneConfig.geometry?.flat(1);
  if (!geom) return result;

  result.nearestWall = getCameraWallCrossPoint(geom, prevSceneConfig, sceneConfig, false); // first wall when moving to next Pano
  if (!result.nearestWall) return result;

  result.farthestWall = getCameraWallCrossPoint(geom, sceneConfig, prevSceneConfig, false); // last wall when moving to next Pano
  if (!result.farthestWall) return result;

  const wallThickness = Utils.getVec2Length(Utils.getVec2(result.farthestWall, result.nearestWall));
  if (wallThickness < transitionSettings.wallskipMinWidth) return result;

  const wallVector = Utils.getVec2(result.nearestWall, result.farthestWall); // a.k.a. skipvector - this portion of the path will be ignored
  const multiplyFactor = 1 - wallskipPerceivableDistance / wallThickness;
  if (multiplyFactor) {
    wallVector[0] *= multiplyFactor; // reduce the wall vector to give some space between skipped rooms
    wallVector[1] *= multiplyFactor;
    wallVector[2] = 0;
  }
  result.wallVector = wallVector;

  return result;
}
