import { jsx as _jsx } from "react/jsx-runtime";
const Label = ({ className = '', size, children, selectable = true, testId }) => {
    const twText = {
        thin: 'text-sm font-medium text-modal-gray-dark',
        thick: 'text-sm font-bold text-modal-gray-dark',
        small: 'text-xs font-bold text-modal-gray-label',
        medium: 'text-base font-bold text-modal-dark',
        normal: 'text-lg font-bold text-modal-dark',
        large: 'text-2xl font-bold text-modal-dark',
    }[size || 'normal'];
    const twSelectable = selectable ? 'select-text' : '';
    return (_jsx("div", { className: `font-primary ${twText} ${twSelectable} ${className}`, "data-testid": testId, children: children }));
};
export default Label;
