import { FC } from "react";
import { getInitials } from "../../../utils";
import { PrimaryButton } from "components/elements";

interface NotificationDialogProps {
  msg: string;
  title: string;
  btnTitle: string;
  secondaryBtnTitle: string;
  handleClick: () => void;
  handleClose: () => void;
}

const NotificationDialog: FC<NotificationDialogProps> = ({
  msg,
  title,
  btnTitle,
  secondaryBtnTitle,
  handleClick,
  handleClose,
}) => {
  return (
    <div className="flex justify-between w-full px-2.5 py-4 bg-minimap-background rounded-md">
      <div className="flex justify-start items-center w-[110px] mx-3">
        <p className="text-2xl font-bold font-primary text-center text-giraffe-gray-dark uppercase h-[70px] w-[70px] flex items-center justify-center bg-minimap-background-dark bg-opacity-20 rounded-full">
          {getInitials(msg)}
        </p>
      </div>

      <div className="w-full h-full">
        <div>
          <p className="text-base text-white max-w-[180px] truncate font-bold">
            {msg}
          </p>
          <p className="text-white text-sm">{title}</p>
        </div>
        <div className="flex w-full mt-2.5">
          <div className="w-[94px]">
            <PrimaryButton
              text={secondaryBtnTitle}
              size="small"
              bgColor="red-inverse"
              handleButtonClick={handleClose}
            />
          </div>
          <div className="w-[94px] ml-2.5">
            <PrimaryButton
              text={btnTitle}
              size="small"
              bgColor="green"
              handleButtonClick={handleClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationDialog;
