import { RoomNotification } from "components/elements";
import { ErrorIcon } from "components/icons";
import config from "config";
import { useDebouncedEffect, useInternetSpeed } from "hooks";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

const useConnectionIssues = (delay: number) => {
  const isSlowInternet = useInternetSpeed(
    config.internetSpeedImage.url,
    config.internetSpeedImage.size,
    delay
  );
  const [networkLevels, setNetworkLevels] = useState<number | null>(null);
  const [tempNetworkLevels, setTempNetworkLevels] = useState<number | null>(
    null
  );

  // If for 1000ms the networkLevels that we receive from twilio
  // is les than 3, then show a toast message of bad internet connection
  useDebouncedEffect(
    () => setNetworkLevels(tempNetworkLevels ? tempNetworkLevels : 5),
    [tempNetworkLevels],
    1000
  );

  const isNetworkIssue = useCallback(() => {
    if ((networkLevels !== null && networkLevels <= 3) || isSlowInternet) {
      return true;
    }

    return false;
  }, [isSlowInternet, networkLevels]);

  useEffect(() => {
    // To prevent the duplicate toast message providing custom id
    // if tost already exists with same id, then it will not show again
    const customId = "customId";

    if (isNetworkIssue()) {
      toast(
        <RoomNotification
          icon={<ErrorIcon className="w-6 h-6 fill-[#FFFFFF]" />}
          msg="Poor internet connection"
          handelClose={() => {
            toast.dismiss(customId);
          }}
        />,
        {
          autoClose: 10000,
          draggable: false,
          toastId: customId,
          progress: undefined,
          closeButton: false,
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }

    if (!isNetworkIssue()) {
      toast.dismiss(customId);
    }
  }, [isNetworkIssue]);

  return { isSlowInternet, setTempNetworkLevels };
};

export default useConnectionIssues;
