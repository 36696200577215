import { SVGProps } from "react";

const DenyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 100 64"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    {...props}
  >
    <path
      d="M44.649 4.549 19.42 54.286C17.396 58.277 20.296 63 24.771 63h50.458c4.475 0 7.375-4.723 5.351-8.714L55.351 4.549c-2.222-4.381-8.48-4.381-10.702 0Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#000",
        strokeWidth: 2,
      }}
    />
    <circle
      cx={50}
      cy={49.5}
      r={3.5}
      style={{
        fill: "none",
        stroke: "#000",
        strokeWidth: 2,
      }}
    />
    <path
      d="M53 25a3.001 3.001 0 0 0-6 0v13a3.001 3.001 0 0 0 6 0V25Z"
      style={{
        fill: "none",
        stroke: "#000",
        strokeWidth: 2,
      }}
    />
  </svg>
);

export default DenyIcon;
