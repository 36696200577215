import type { Size } from '@g360/vt-types';

import { MAX_FOV_RAD } from '../../../Globals';
import { getMinFov, horizontalToVerticalFovRad, verticalToHorizontalFovRad } from '../../fovUtils/fovUtils';
import Utils from '../../Utils';

export default function clampFov(targetHorizontalFov: number, canvasSize: Size): number {
  const minHorizontalFov = getMinFov(canvasSize.width);

  const verticalFov = horizontalToVerticalFovRad(targetHorizontalFov, canvasSize);

  // Limit vertical fov
  if (verticalFov > MAX_FOV_RAD) {
    return Math.min(MAX_FOV_RAD, verticalToHorizontalFovRad(MAX_FOV_RAD, canvasSize));
  }

  return Utils.boundToRange(targetHorizontalFov, [minHorizontalFov, MAX_FOV_RAD]);
}
