/* eslint-disable no-continue */
import type { FPPoint2D, FPPoint3D, FPTriangle } from '@g360/vt-types';

import { calculateTriangleNormal, isTriangleDegenerate, nudgeVertex } from '../utils';
import BVHRaycaster from './BVH';
import { PixelHolder } from './PixelHolder';
import {
  calculateBarycentricCoordinates,
  colorUVPointOnImage,
  findMinMax,
  isPointInOrOnTriangle,
  tryColorPointOnImageFromNeighbors,
} from './utils';

export function iterateTriangleSurfaceByUVMain(
  triangle: FPTriangle,
  textureSize: number,
  mode: 'skipFew' | 'fillSkipped' | 'all',
  pixelHolder: PixelHolder,
  raycaster: BVHRaycaster,
  hemisphereSamples: FPPoint3D[],
  maxDistance: number,
  triangleId: number // for debug,  @todo -- rem
) {
  const { vertices, uvs } = triangle;

  // Check if the triangle is degenerate and try to fix it
  // happens super rarely
  let attempts = 0;
  while (isTriangleDegenerate(vertices[0], vertices[1], vertices[2]) && attempts < 3) {
    vertices[attempts] = nudgeVertex(vertices[attempts]) as FPPoint3D;
    attempts += 1;
  }

  const normal = calculateTriangleNormal(vertices[0], vertices[1], vertices[2]) as FPPoint3D;

  const pixelCoords = uvs.map(([u, v]) => [Math.round(u * textureSize), Math.round(v * textureSize)]);
  const { minX, minY, maxX, maxY } = findMinMax(pixelCoords);

  for (let x = minX; x <= maxX; x += 1) {
    for (let y = minY; y <= maxY; y += 1) {
      const u = x / textureSize;
      const v = y / textureSize;
      const uv: FPPoint2D = [u, v];

      // fast debug
      // if(uv[0] > 0.5 || uv[1] > 0.5) continue; // only bake AO for the first half of the texture

      if (isPointInOrOnTriangle(uv, uvs[0], uvs[1], uvs[2], textureSize)) {
        const [b1, b2, b3] = calculateBarycentricCoordinates([u, v], uvs[0], uvs[1], uvs[2]);
        const position: FPPoint3D = [
          b1 * vertices[0][0] + b2 * vertices[1][0] + b3 * vertices[2][0],
          b1 * vertices[0][1] + b2 * vertices[1][1] + b3 * vertices[2][1],
          b1 * vertices[0][2] + b2 * vertices[1][2] + b3 * vertices[2][2],
        ];
        const skip = mode !== 'all' && (x + y) % 2 !== 0; // checkerboard

        if (mode === 'skipFew' || mode === 'all') {
          // calculate AO (or write "skipped" color)
          colorUVPointOnImage(
            position,
            uv,
            normal,
            skip,
            textureSize,
            pixelHolder,
            raycaster,
            hemisphereSamples,
            maxDistance,
            triangleId
          );
        } else if (mode === 'fillSkipped' && skip) {
          // try to fill skipped pixels, by not calculating AO, but analyzing neighbors
          tryColorPointOnImageFromNeighbors(x, y, uvs, textureSize, pixelHolder);
        }
      }
    }
  }
}

function lerp2D(a: FPPoint2D, b: FPPoint2D, t: number): FPPoint2D {
  return [a[0] + (b[0] - a[0]) * t, a[1] + (b[1] - a[1]) * t];
}

export function iterateTriangleEdgesByUV(
  triangle: FPTriangle,
  textureSize: number,
  callback: (position: FPPoint3D, uv: FPPoint2D, triangleId: number, meshId: number) => void,
  triangleId: number,
  meshId: number
) {
  const { vertices, uvs } = triangle;

  // each edge of the triangle
  for (let i = 0; i < 3; i += 1) {
    const start = uvs[i];
    const end = uvs[(i + 1) % 3];

    // edge length in texture space
    const dx = (end[0] - start[0]) * textureSize;
    const dy = (end[1] - start[1]) * textureSize;
    const distance = Math.sqrt(dx * dx + dy * dy);
    const numSteps = Math.ceil(distance);

    for (let step = 0; step <= numSteps; step += 1) {
      const t = step / numSteps;
      const uv = lerp2D(start, end, t);
      if (Number.isNaN(uv[0]) || Number.isNaN(uv[1])) continue; // most likely 0 length edge

      const pixelX = Math.ceil(uv[0] * textureSize); // ceil mimics tex sampling by WebGL the closest, minimizes texture bleeding
      const pixelY = Math.ceil(uv[1] * textureSize);
      const pixelUV: FPPoint2D = [pixelX / textureSize, pixelY / textureSize];

      const [b1, b2, b3] = calculateBarycentricCoordinates(pixelUV, uvs[0], uvs[1], uvs[2]);
      const position: FPPoint3D = [
        b1 * vertices[0][0] + b2 * vertices[1][0] + b3 * vertices[2][0],
        b1 * vertices[0][1] + b2 * vertices[1][1] + b3 * vertices[2][1],
        b1 * vertices[0][2] + b2 * vertices[1][2] + b3 * vertices[2][2],
      ];

      callback(position, pixelUV, triangleId, meshId);
    }
  }
}
