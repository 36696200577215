import { Tooltip } from "components/elements";
import { CopyIcon } from "components/icons";
import { isMobile } from "func/isMobile";
import { useCopyToClipboard, useTimeout } from "hooks";
import { FC, useState } from "react";
import { classNamesUtil } from "utils/classNamesUtil";
import removeQueryFromString from "utils/removeQueryFromString";

interface CopyInviteLinkProps {
  isSmallScreen?: boolean;
  title?: "large" | "small";
  onCopyInvite?: () => void;
}

const CopyInviteLink: FC<CopyInviteLinkProps> = ({
  isSmallScreen = false,
  title = "large",
  onCopyInvite,
}) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const { isClipboardSupported, copy } = useCopyToClipboard();

  const copyLink = async () => {
    const formattedLink = removeQueryFromString(window.location.href);

    if (!formattedLink) return;
    const isSaved = await copy(formattedLink);
    if (isSaved) {
      setIsCopied(true);
    }
  };

  useTimeout(() => setIsCopied(false), 3000, [isCopied]);

  return (
    <div>
      {!isSmallScreen && title === "large" && (
        <p className="text-sm font-bold leading-5 tracking-[1%] mb-1">
          Copy Invite Link and share it with your Guest
        </p>
      )}
      <div className="inline-flex relative justify-between items-end w-full">
        <div className="w-[90%]">
          {title === "small" && (
            <p className="text-giraffe-gray-lightest mt-2.5 text-xs">
              Copy invite link
            </p>
          )}
          <div className="flex relative items-center">
            <p className="text-sm text-giraffe-dark truncate">
              {removeQueryFromString(window.location.href)}
            </p>
          </div>
        </div>
        <Tooltip
          tooltipPosition="bottom"
          tooltipOffset="left"
          theme="light"
          tooltipText={
            isCopied ? "Link copied to clipboard" : "Copy link to clipboard"
          }
        >
          <>
            {isClipboardSupported && (
              <button
                className="flex items-center justify-center w-7 h-7 p-1.5 bg-giraffe-gray-light hover:bg-giraffe-gray-medium rounded-md"
                onClick={() => {
                  onCopyInvite && onCopyInvite();
                  copyLink();
                }}
              >
                <CopyIcon className="w-4 h-4 fill-giraffe-dark" />
              </button>
            )}
            {isMobile() && (
              <span
                className={classNamesUtil(
                  "mt-1 w-[85px] absolute text-sm px-1.5 z-50 rounded-md py-0.5 transition-all duration-300 ease-in-out transform pointer-events-none bg-giraffe-dark top-full right-0 text-white",
                  isCopied ? "opacity-100" : "opacity-0"
                )}
              >
                Link copied
              </span>
            )}
          </>
        </Tooltip>
      </div>
    </div>
  );
};

export default CopyInviteLink;
