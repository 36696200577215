import { useCallback, useEffect } from 'react';
// type AnyEvent = MouseEvent | TouchEvent;
function useOnClickOutside(rootRef, ref, handler, lock) {
    const handleClick = useCallback((event) => handler(event), [handler]);
    useEffect(() => {
        var _a, _b;
        // TODO: fix any
        const listener = (event) => {
            const el = ref === null || ref === void 0 ? void 0 : ref.current;
            // Do nothing if clicking ref's element or descendent elements
            if (!el || el.contains(event.target) || lock) {
                return;
            }
            handleClick(event);
        };
        // check if the device support touch events
        if ('ontouchstart' in document.documentElement) {
            (_a = rootRef === null || rootRef === void 0 ? void 0 : rootRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener(`touchstart`, listener);
            return () => { var _a; return (_a = rootRef === null || rootRef === void 0 ? void 0 : rootRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener(`touchstart`, listener); };
        }
        (_b = rootRef === null || rootRef === void 0 ? void 0 : rootRef.current) === null || _b === void 0 ? void 0 : _b.addEventListener(`mousedown`, listener);
        return () => { var _a; return (_a = rootRef === null || rootRef === void 0 ? void 0 : rootRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener(`mousedown`, listener); };
        // Reload only if ref or handleClick changes
    }, [rootRef, ref, handleClick, lock]);
}
export default useOnClickOutside;
