import type { TextureParameters } from '../../../types/internal';

/**
 * Sets texture parameters if provided. Otherwise, sets the default values.
 * @param gl - `WebGLRenderingContext`: An interface to the OpenGL ES 2.0 graphics rendering context for the drawing surface of an HTML `<canvas>` element.
 * @param textureParameters - `TextureParameters` - `Optional`: an object with the texture parameters.
 * @param textureParameters.minFilter - `GLenum` - `Optional`: Texture minification filter.
 *
 * Possible values: `gl.LINEAR`, `gl.NEAREST`, `gl.NEAREST_MIPMAP_NEAREST`, `gl.LINEAR_MIPMAP_NEAREST`, `gl.NEAREST_MIPMAP_LINEAR`, `gl.LINEAR_MIPMAP_LINEAR`.
 *
 * Default value: `gl.LINEAR`.
 *
 * @param textureParameters.magFilter - `GLenum` - `Optional`: Texture magnification filter.
 *
 * Possible values: `gl.LINEAR`, `gl.NEAREST`.
 *
 * Default value: `gl.LINEAR`.
 *
 * @param textureParameters.wrapSFilter - `GLenum` - `Optional`: Wrapping function for texture coordinate `s`.
 *
 * Possible values: `gl.REPEAT`, `gl.CLAMP_TO_EDGE`, `gl.MIRRORED_REPEAT`.
 *
 * Default value: `gl.CLAMP_TO_EDGE`.
 *
 * @param textureParameters.wrapTFilter - `GLenum` - `Optional`: Wrapping function for texture coordinate `t`.
 *
 * Possible values: `gl.REPEAT`, `gl.CLAMP_TO_EDGE`, `gl.MIRRORED_REPEAT`.
 *
 * Default value: `gl.CLAMP_TO_EDGE`.
 *
 * @param textureParameters.useAlphaChannel - `boolean` - `Optional`: unused.
 * @returns `void`
 */
function setTextureParameters(gl: WebGLRenderingContext, textureParameters?: TextureParameters): void {
  if (!textureParameters) {
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);

    return;
  }

  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, textureParameters.minFilter ?? gl.LINEAR);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, textureParameters.magFilter ?? gl.LINEAR);

  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, textureParameters.wrapSFilter ?? gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, textureParameters.wrapTFilter ?? gl.CLAMP_TO_EDGE);
}

export default setTextureParameters;
