import { normalizeVec3, vec3CrossProduct } from '@g360/vt-utils';

import {
  destroyProgram,
  disableVertexAttributes,
  enableVertexAttributes,
  initShaders,
  loadShaders,
} from '../../../common/webglUtils';
import type MeasureToolProgram from '../MeasureToolProgram';
import fragmentShaderSource from './measureLine.fs.glsl';
import vertexShaderSource from './measureLine.vs.glsl';

/** How many floating point numbers are needed to form the vertices
 * for one measure line
 */
const FLOATS_PER_LINE = 36;

/** Active measurement line filled in part length (mm) */
const SEG_CONTENT_LEN = 65;
/** Active measurement line spacing part length (mm) */
const SEG_SPACING_LEN = 30;
/** Draws the measurement lines */
class MeasureLineProgram {
  private program: WebGLProgram | null = null;

  private gl: WebGLRenderingContext;
  private canvas: HTMLCanvasElement;
  private mainProgram: MeasureToolProgram;

  private vertexBuffer: WebGLBuffer | null;
  private point1Buffer: WebGLBuffer | null = null;
  private point2Buffer: WebGLBuffer | null = null;
  private activeBuffer: WebGLBuffer | null = null;

  private vertexAttribute = 0;
  private point1Attribute = 0;
  private point2Attribute = 0;
  private activeAttribute = 0;

  private projectionMatUniform: WebGLUniformLocation | null = null;

  private lineRadiusUniform: WebGLUniformLocation | null = null;
  private segmentContentLenUniform: WebGLUniformLocation | null = null;
  private segmentSpacingLenUniform: WebGLUniformLocation | null = null;

  private vertexAttributes: number[] = []; // list vertex attributes to be enabled before and disabled after a draw in order to not mess up state of other programs

  private abortController = window.AbortController ? new AbortController() : null;

  private vertices = new Float32Array(FLOATS_PER_LINE * 2);
  private point1Data = new Float32Array(FLOATS_PER_LINE * 2);
  private point2Data = new Float32Array(FLOATS_PER_LINE * 2);
  private activeData = new Float32Array(FLOATS_PER_LINE * 2);

  constructor(webGLContext: WebGLRenderingContext, canvas: HTMLCanvasElement, mainProgram: MeasureToolProgram) {
    this.gl = webGLContext;
    this.canvas = canvas;
    this.mainProgram = mainProgram;

    this.vertexBuffer = this.gl.createBuffer();
    this.point1Buffer = this.gl.createBuffer();
    this.point2Buffer = this.gl.createBuffer();
    this.activeBuffer = this.gl.createBuffer();
  }

  init(): void {
    this.program = initShaders(this.gl, vertexShaderSource, fragmentShaderSource);

    if (this.program) {
      this.vertexAttribute = this.gl.getAttribLocation(this.program, 'a_position');
      this.point1Attribute = this.gl.getAttribLocation(this.program, 'a_p1');
      this.point2Attribute = this.gl.getAttribLocation(this.program, 'a_p2');
      this.activeAttribute = this.gl.getAttribLocation(this.program, 'a_active');

      this.lineRadiusUniform = this.gl.getUniformLocation(this.program, 'u_line_radius');
      this.segmentContentLenUniform = this.gl.getUniformLocation(this.program, 'u_seg_content_length');
      this.segmentSpacingLenUniform = this.gl.getUniformLocation(this.program, 'u_seg_spacing_length');

      this.projectionMatUniform = this.gl.getUniformLocation(this.program, 'u_proj_mat');

      this.vertexAttributes = [this.vertexAttribute, this.point1Attribute, this.point2Attribute, this.activeAttribute];
    }
  }

  destroy(): void {
    this.abortPending();
    destroyProgram(this.gl, this.program);
  }

  abortPending(): void {
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = new AbortController();
    }
  }

  draw(): void {
    loadShaders(this.gl, this.program);
    enableVertexAttributes(this.gl, this.vertexAttributes);

    this.gl.enable(this.gl.CULL_FACE);
    this.gl.cullFace(this.gl.FRONT);

    this.gl.uniformMatrix4fv(this.projectionMatUniform, false, this.mainProgram.projectionMatrix);

    const FOV_COEF = this.mainProgram.isHandheld ? 10 : 3;
    const MAX_RADIUS = this.mainProgram.isHandheld ? 15 : 7;
    const MEASURE_LINE_RADIUS = Math.min(FOV_COEF * this.mainProgram.fov, MAX_RADIUS);

    this.gl.uniform1f(this.lineRadiusUniform, MEASURE_LINE_RADIUS);

    this.gl.uniform1f(this.segmentContentLenUniform, SEG_CONTENT_LEN);
    this.gl.uniform1f(this.segmentSpacingLenUniform, SEG_SPACING_LEN);

    const neededVertLength = this.mainProgram.measurements.length * FLOATS_PER_LINE * 2;
    if (this.vertices.length !== neededVertLength) this.vertices = new Float32Array(neededVertLength);
    if (this.point1Data.length !== neededVertLength) this.point1Data = new Float32Array(neededVertLength);
    if (this.point2Data.length !== neededVertLength) this.point2Data = new Float32Array(neededVertLength);
    if (this.activeData.length !== neededVertLength) this.activeData = new Float32Array(neededVertLength);

    for (let i = 0; i < this.mainProgram.measurements.length; i += 1) {
      const vertexOffset = i * FLOATS_PER_LINE * 2;

      const measurement = this.mainProgram.measurements[i];
      const p1Data = measurement.start.snapPosition ?? measurement.start;
      let p2Data = measurement.end?.snapPosition ?? measurement.end;

      const measurementActive = this.mainProgram.activeMeasurements.includes(measurement);
      if (!p2Data) {
        p2Data = this.mainProgram.cursorCoords?.snapPosition ?? this.mainProgram.cursorCoords;
      }

      if (!p1Data || !p2Data) {
        for (let j = 0; j < FLOATS_PER_LINE; j += 1) {
          // Clear the vertices in case they had values before
          this.vertices[vertexOffset + j] = 0;
          // Also clear the vertices required for the chromium issue mentioned below
          this.vertices[vertexOffset + j * 2] = 0;
        }
        // eslint-disable-next-line no-continue
        continue;
      }

      // Normalized direction of the measurement line
      const lineDirection = normalizeVec3([
        p2Data.cartX - p1Data.cartX,
        p2Data.cartY - p1Data.cartY,
        p2Data.cartZ - p1Data.cartZ,
      ]);

      // Vector perpendicular to the measurement line and camera direction to the first point
      // We use this vector to expand the line to the sides, facing the camera
      const p1Perpendicular = normalizeVec3(
        vec3CrossProduct([p1Data.cartX, p1Data.cartY, p1Data.cartZ], lineDirection)
      );

      const v1X = p1Data.cartX + p1Perpendicular[0] * MEASURE_LINE_RADIUS;
      const v1Y = p1Data.cartY + p1Perpendicular[1] * MEASURE_LINE_RADIUS;
      const v1Z = p1Data.cartZ + p1Perpendicular[2] * MEASURE_LINE_RADIUS;
      const v2X = p1Data.cartX - p1Perpendicular[0] * MEASURE_LINE_RADIUS;
      const v2Y = p1Data.cartY - p1Perpendicular[1] * MEASURE_LINE_RADIUS;
      const v2Z = p1Data.cartZ - p1Perpendicular[2] * MEASURE_LINE_RADIUS;
      const v3X = p2Data.cartX + p1Perpendicular[0] * MEASURE_LINE_RADIUS;
      const v3Y = p2Data.cartY + p1Perpendicular[1] * MEASURE_LINE_RADIUS;
      const v3Z = p2Data.cartZ + p1Perpendicular[2] * MEASURE_LINE_RADIUS;
      const v4X = p2Data.cartX - p1Perpendicular[0] * MEASURE_LINE_RADIUS;
      const v4Y = p2Data.cartY - p1Perpendicular[1] * MEASURE_LINE_RADIUS;
      const v4Z = p2Data.cartZ - p1Perpendicular[2] * MEASURE_LINE_RADIUS;

      // Each measure line is 4 triangles
      // But due to some weird chromium bug (v113-v118) we need to duplicate them to 8 triangles,
      // Otherwise some triangles just don't get rendered, this seems like it has something to do with the buffer size
      // But it's not clear why it happens, and it's not happening in v119 or v112
      // This issue also affected the "line active" buffer, so instead of just floats its now a vec3
      for (let c = 0; c < 2; c += 1) {
        const innerOffset = c * FLOATS_PER_LINE;
        this.vertices[vertexOffset + innerOffset + 0] = v1X;
        this.vertices[vertexOffset + innerOffset + 1] = v1Y;
        this.vertices[vertexOffset + innerOffset + 2] = v1Z;
        this.vertices[vertexOffset + innerOffset + 3] = p1Data.cartX;
        this.vertices[vertexOffset + innerOffset + 4] = p1Data.cartY;
        this.vertices[vertexOffset + innerOffset + 5] = p1Data.cartZ;
        this.vertices[vertexOffset + innerOffset + 6] = v3X;
        this.vertices[vertexOffset + innerOffset + 7] = v3Y;
        this.vertices[vertexOffset + innerOffset + 8] = v3Z;

        this.vertices[vertexOffset + innerOffset + 9] = v3X;
        this.vertices[vertexOffset + innerOffset + 10] = v3Y;
        this.vertices[vertexOffset + innerOffset + 11] = v3Z;
        this.vertices[vertexOffset + innerOffset + 12] = p1Data.cartX;
        this.vertices[vertexOffset + innerOffset + 13] = p1Data.cartY;
        this.vertices[vertexOffset + innerOffset + 14] = p1Data.cartZ;
        this.vertices[vertexOffset + innerOffset + 15] = p2Data.cartX;
        this.vertices[vertexOffset + innerOffset + 16] = p2Data.cartY;
        this.vertices[vertexOffset + innerOffset + 17] = p2Data.cartZ;

        this.vertices[vertexOffset + innerOffset + 18] = p2Data.cartX;
        this.vertices[vertexOffset + innerOffset + 19] = p2Data.cartY;
        this.vertices[vertexOffset + innerOffset + 20] = p2Data.cartZ;
        this.vertices[vertexOffset + innerOffset + 21] = p1Data.cartX;
        this.vertices[vertexOffset + innerOffset + 22] = p1Data.cartY;
        this.vertices[vertexOffset + innerOffset + 23] = p1Data.cartZ;
        this.vertices[vertexOffset + innerOffset + 24] = v2X;
        this.vertices[vertexOffset + innerOffset + 25] = v2Y;
        this.vertices[vertexOffset + innerOffset + 26] = v2Z;

        this.vertices[vertexOffset + innerOffset + 27] = p2Data.cartX;
        this.vertices[vertexOffset + innerOffset + 28] = p2Data.cartY;
        this.vertices[vertexOffset + innerOffset + 29] = p2Data.cartZ;
        this.vertices[vertexOffset + innerOffset + 30] = v2X;
        this.vertices[vertexOffset + innerOffset + 31] = v2Y;
        this.vertices[vertexOffset + innerOffset + 32] = v2Z;
        this.vertices[vertexOffset + innerOffset + 33] = v4X;
        this.vertices[vertexOffset + innerOffset + 34] = v4Y;
        this.vertices[vertexOffset + innerOffset + 35] = v4Z;
        for (let j = 0; j < FLOATS_PER_LINE / 3; j += 1) {
          const active = measurementActive ? 1 : 0;
          this.activeData[i * FLOATS_PER_LINE * 2 + innerOffset + j * 3] = active;
          this.activeData[i * FLOATS_PER_LINE * 2 + innerOffset + j * 3 + 1] = active;
          this.activeData[i * FLOATS_PER_LINE * 2 + innerOffset + j * 3 + 2] = active;

          this.point1Data[i * FLOATS_PER_LINE * 2 + innerOffset + j * 3] = p1Data.cartX;
          this.point1Data[i * FLOATS_PER_LINE * 2 + innerOffset + j * 3 + 1] = p1Data.cartY;
          this.point1Data[i * FLOATS_PER_LINE * 2 + innerOffset + j * 3 + 2] = p1Data.cartZ;

          this.point2Data[i * FLOATS_PER_LINE * 2 + innerOffset + j * 3] = p2Data.cartX;
          this.point2Data[i * FLOATS_PER_LINE * 2 + innerOffset + j * 3 + 1] = p2Data.cartY;
          this.point2Data[i * FLOATS_PER_LINE * 2 + innerOffset + j * 3 + 2] = p2Data.cartZ;
        }
      }
    }

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.vertices, this.gl.DYNAMIC_DRAW);
    this.gl.vertexAttribPointer(this.vertexAttribute, 3, this.gl.FLOAT, false, 0, 0);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.point1Buffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.point1Data, this.gl.DYNAMIC_DRAW);
    this.gl.vertexAttribPointer(this.point1Attribute, 3, this.gl.FLOAT, false, 0, 0);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.point2Buffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.point2Data, this.gl.DYNAMIC_DRAW);
    this.gl.vertexAttribPointer(this.point2Attribute, 3, this.gl.FLOAT, false, 0, 0);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.activeBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.activeData, this.gl.DYNAMIC_DRAW);
    this.gl.vertexAttribPointer(this.activeAttribute, 3, this.gl.FLOAT, false, 0, 0);

    this.gl.drawArrays(this.gl.TRIANGLES, 0, this.vertices.length / 3);

    this.gl.disable(this.gl.CULL_FACE);
    disableVertexAttributes(this.gl, this.vertexAttributes);
  }
}

export default MeasureLineProgram;
