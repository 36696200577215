import { FC, useEffect, useRef } from "react";
import { VideoPreview } from "components/elements";
import {
  useAudioLevelIndicator,
  useIsTrackEnabled,
  useRoomContext,
} from "hooks";

const VideoPreviewWrapper: FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null!);
  const { localVideoTrack, localAudioTrack } = useRoomContext();
  const isVideoEnabled = useIsTrackEnabled(localVideoTrack);
  const { AudioBorderDivRef } = useAudioLevelIndicator(
    localAudioTrack,
    Boolean(localAudioTrack)
  );

  useEffect(() => {
    if (!localVideoTrack) return;

    const el = videoRef.current;
    el.muted = true;

    // Need to call this at the end of the call stack, not sure why - in iPhone the next re-renders restroy the stream.
    setTimeout(() => localVideoTrack.attach(el));
    return () => {
      localVideoTrack.detach(el);

      // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
      // See: https://github.com/twilio/twilio-video.js/issues/1528
      el.srcObject = null;
    };
  }, [localVideoTrack]);

  return (
    <div className="relative w-full h-full">
      <VideoPreview videoRef={videoRef} isVideoEnabled={isVideoEnabled} />
      <div
        ref={AudioBorderDivRef}
        className="absolute top-0 left-0 w-full h-full"
      >
        <div className="w-full h-full border-[1px] border-[A0A6AE] rounded-md overflow-hidden">
          <div className="w-full h-full border-[4px] border-white">
            <div className="w-full h-full border-[1px] border-black" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPreviewWrapper;
