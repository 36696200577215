import { FC } from "react";

interface MediaDeviceButtonProps {
  title?: string;
  icon?: JSX.Element;
  handleClick: () => void;
}

const MediaDeviceButton: FC<MediaDeviceButtonProps> = ({
  icon,
  title,
  handleClick,
}) => {
  return (
    <button
      className="w-full px-4 py-2 text-sm font-semibold leading-5 text-left cursor-pointer hover:bg-giraffe-gray-light font-primary"
      onClick={handleClick}
      type="button"
    >
      <span className="flex">
        {icon && <span className="mr-2">{icon}</span>}
        {title && title}
      </span>
    </button>
  );
};

export default MediaDeviceButton;
