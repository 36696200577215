import fscreen from 'fscreen';
import { useCallback, useEffect, useMemo, useState } from 'react';
const useFullScreen = (analytics) => {
    const [isFullscreen, setFullscreen] = useState(false);
    const enabledFullscreen = useMemo(() => fscreen.fullscreenEnabled, []);
    const toggleFullscreen = useCallback(() => {
        if (fscreen.fullscreenElement === null) {
            fscreen.requestFullscreen(document.documentElement);
        }
        else {
            fscreen.exitFullscreen();
        }
    }, []);
    const handler = useCallback(() => {
        if (fscreen.fullscreenElement === null) {
            setFullscreen(false);
            analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'FS', 'Close');
        }
        else {
            setFullscreen(true);
            analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'FS', 'Open');
        }
    }, [analytics]);
    useEffect(() => {
        if (!fscreen.fullscreenEnabled)
            return () => undefined;
        fscreen.addEventListener('fullscreenchange', handler, false);
        return () => {
            fscreen.removeEventListener('fullscreenchange', handler, false);
        };
    }, [handler]);
    return { isFullscreen, enabledFullscreen, toggleFullscreen };
};
export default useFullScreen;
