import FontFaceObserver from 'fontfaceobserver';
import { useEffect } from 'react';
const useFontFaces = (fonts, onFontsLoaded) => {
    useEffect(() => {
        const observerList = fonts.map(({ family, weight, style }) => {
            const observer = new FontFaceObserver(family, { weight, style });
            return { promise: observer.load(), family, weight, style };
        });
        const promiseList = observerList.map((font) => {
            font.promise.catch(() => {
                // eslint-disable-next-line no-console
                console.error(`Failed to load font ${font.family}, ${font.weight}, ${font.style}`);
            });
            return font.promise;
        });
        // NOTE: This will cause a 3 second delay if one of the fonts is 404
        Promise.all(promiseList)
            .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
        })
            .finally(() => {
            onFontsLoaded === null || onFontsLoaded === void 0 ? void 0 : onFontsLoaded();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
export default useFontFaces;
