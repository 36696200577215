import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Transition } from '@headlessui/react';
import { forwardRef, useState } from 'react';
import ButtonRow from './ButtonRow';
/** Collapsible menu component
 *
 * Horizontal menu that collapses to the left or right when toggled
 * Collapsing is animated and is controlled by the isOpen prop
 * Collapsing direction is controlled by the direction prop
 * Collapsing can be disabled by setting isCollapsible to false
 *  - in this case the menu button is hidden and all the children are visible
 *
 * Component only handles the animation state, everything else is handled by the parent component
 *  even the button that toggles the menu
 *
 * @prop button - The button that toggles the menu
 * @prop children - The content of the menu
 * @prop direction - The direction the menu should collapse to
 * @prop isOpen - Whether the menu is open or closed
 * @prop isCollapsible - Whether the menu should be collapsible or not
 */
const MenuCollapse = forwardRef(({ button, children, direction, isOpen, isCollapsible = true }, ref) => {
    const [isAnimating, setIsAnimating] = useState(false);
    const twTranslateDirection = direction === 'left' ? '-translate-x-1/3' : 'translate-x-1/3';
    let buttonLeft = null;
    let buttonRight = null;
    if (direction === 'left') {
        buttonLeft = button;
    }
    else {
        buttonRight = button;
    }
    const twOverflow = isAnimating ? 'overflow-hidden' : '';
    const twMargin = isCollapsible && isOpen ? 'space-x-3 smvh:space-x-4' : '';
    return (_jsxs("div", { className: `flex ${twMargin}`, ref: ref, children: [isCollapsible && buttonLeft, _jsx("div", { className: `${twOverflow}`, children: isCollapsible ? (_jsx(Transition, { as: "div", className: "smvh:max-w-none smvh:space-x-4 flex max-w-[236px] space-x-3", show: !isCollapsible || isOpen, enter: "transition-all duration-[600ms] ease-out", enterFrom: `${twTranslateDirection} opacity-0`, enterTo: "opacity-100", leave: "transition-all duration-[600ms] ease-out", leaveFrom: "opacity-100", leaveTo: `${twTranslateDirection} opacity-0`, beforeEnter: () => setIsAnimating(true), afterEnter: () => setIsAnimating(false), beforeLeave: () => setIsAnimating(true), afterLeave: () => setIsAnimating(false), children: children })) : (_jsx(ButtonRow, { children: children })) }), isCollapsible && buttonRight] }));
});
MenuCollapse.displayName = 'MenuCollapse';
export default MenuCollapse;
