(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@sentry/browser"));
	else if(typeof define === 'function' && define.amd)
		define(["@sentry/browser"], factory);
	else if(typeof exports === 'object')
		exports["vt-utils"] = factory(require("@sentry/browser"));
	else
		root["vt-utils"] = factory(root["@sentry/browser"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__7356__) => {
return 