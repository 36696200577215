import type { Pixel, Pos } from '@g360/vt-types';

// import isMobile from 'is-mobile';
import Utils from '../../common/Utils/Utils';
import GestureController from '../GestureController';
import type { ExtDragEvent } from '../GestureController/types';

export default class MeasureToolController extends GestureController {
  // distance from edges that we should start spinning the scene
  private static readonly EDGE_DISTANCE = 40;

  /** Allow engine to lock camera pan on specific measure events */
  panDisabled = false;
  /** Prevent interactions while measure files being loaded */
  loading = false;

  private isSpinning = false;
  private currentPos: Pos<Pixel> = { x: 0, y: 0 };

  protected onDrag(current: ExtDragEvent) {
    if (this.loading) return;

    this.currentPos = Utils.xyToPos(current.xy);
    switch (current.type) {
      case 'start': {
        break;
      }
      case 'drag': {
        const spin = this.getSpinDirection(this.currentPos);
        if (!spin) {
          // Should stop spinning
          this.stopSpinning();
          if (!this.panDisabled) {
            // If not dragging a point, just pan the camera around
            this.moveCamera(this.getFinalScenePos(current.deltaPos));
          }
        } else if (!this.isSpinning && this.panDisabled) {
          // If should spin, but not spinning yet, start spinning
          this.spinCamera(spin);
        }
        break;
      }
      case 'end': {
        this.stopSpinning();
        this.moveCameraToTargetPos(current);
        break;
      }
      default:
        console.warn(`type is not supported`);
    }
  }

  private getSpinDirection(pos: Pos<Pixel>): 'left' | 'right' | 'up' | 'down' | null {
    if (pos.x < MeasureToolController.EDGE_DISTANCE) {
      return 'left';
    }
    if (pos.x > this.boundingRect.width - MeasureToolController.EDGE_DISTANCE) {
      return 'right';
    }
    if (pos.y < MeasureToolController.EDGE_DISTANCE) {
      return 'up';
    }
    if (pos.y > this.boundingRect.height - MeasureToolController.EDGE_DISTANCE) {
      return 'down';
    }
    return null;
  }

  private async spinCamera(direction: 'up' | 'down' | 'left' | 'right', progress?: () => void) {
    const params: Record<typeof direction, ['pitch' | 'yaw', -1 | 1, number]> = {
      up: ['pitch', 1, 1000],
      down: ['pitch', -1, 1000],
      left: ['yaw', -1, 7000],
      right: ['yaw', 1, 7000],
    };
    const [param, multiple, duration] = params[direction];

    const patch = { [param]: this[param] + multiple * Utils.toRad(360) };

    this.isSpinning = true;
    await this.changeCamera({ ...this.getEngineState(), ...patch, duration, animate: true }, progress);
    this.isSpinning = false;
  }

  private stopSpinning() {
    if (this.isSpinning) {
      this.moveCamera({});
    }
  }
}
