import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useTranslation } from '../../../contexts/useLocalizationContext';
import CheckIcon from '../../../icons/CheckIcon';
const EmailConsentCheckBox = memo(({ className = '', labelClassName = '', checkBoxClassName = '', iconClassName = '', disabled, checked, onChange, }) => {
    const t = useTranslation();
    const twDisabled = useMemo(() => (disabled ? 'cursor-not-allowed opacity-30' : 'cursor-pointer'), [disabled]);
    const twChecked = useMemo(() => checked
        ? 'bg-modal-dark group-hover:bg-modal-gray-dark'
        : 'bg-white text-primary group-hover:bg-modal-gray-light border border-modal-gray-light', [checked]);
    const twIconChecked = useMemo(() => (checked ? '' : 'invisible'), [checked]);
    return (_jsxs("label", { className: `group flex items-center leading-none ${twDisabled} ${className}`, htmlFor: "email-consent-checkbox", children: [_jsxs("span", { className: `flex h-5 w-5 shrink-0 items-center justify-center rounded-md ${checkBoxClassName} ${twChecked}`, children: [_jsx("input", { id: "email-consent-checkbox", className: "hidden", type: "checkbox", disabled: disabled, checked: checked, "aria-checked": checked, onChange: onChange, "data-testid": "email-consent-checkbox" }), _jsx(CheckIcon, { className: `h-2 w-2 stroke-white ${iconClassName} ${twIconChecked}` })] }), _jsxs("span", { className: `font-primary text-modal-gray-dark ml-[0.625rem] text-sm font-medium leading-5 tracking-[0.0075rem] ${labelClassName}`, children: [t('gated-tour.email-consent'), _jsx("a", { className: "pointer-events-auto cursor-pointer underline underline-offset-2", href: `https://giraffe360.com/cookie-policy-vt-${t('locale')}/`, target: "_blank", rel: "noreferrer", children: t('gated-tour.email-consent-link') }), t('gated-tour.email-consent-tail')] })] }));
});
EmailConsentCheckBox.displayName = 'EmailConsentCheckBox';
export default EmailConsentCheckBox;
