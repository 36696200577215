import { SVGProps } from "react";

const CameraIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 38 27"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <path
      d="M23.889 17.75v-2.917c0-.458-.35-.833-.778-.833h-9.333c-.428 0-.778.375-.778.833v8.334c0 .458.35.833.778.833h9.333c.428 0 .778-.375.778-.833V20.25l1.781 1.908c.49.525 1.33.15 1.33-.591v-5.142c0-.742-.84-1.117-1.33-.592l-1.781 1.917Z"
      style={{
        fillRule: "nonzero",
      }}
      transform="translate(-34.667 -37.333) scale(2.66667)"
    />
  </svg>
);

export default CameraIcon;
