/* eslint-disable no-continue */

import type { AoSettings, FPMesh, FPTriangle } from '@g360/vt-types';

import { endTimer, startTimer } from '../utils';
import BVHRaycaster from './BVH';
import { iterateTriangleSurfaceByUVMain } from './iterators';
import { PixelHolder } from './PixelHolder';
import {
  calculateAreaStatistics,
  generateCosineWeightedHemisphereSamples,
  makeTriangles,
  printAreaStatistics,
} from './utils';

export function createAOTextureDataForMeshes(allMeshes: FPMesh[], settings: AoSettings, workerNum = 1, workerId = 0) {
  const { textureSize, numSamples, maxDistance, verbose, blurRadius, blurEdgeThreshold, postProcess, onlyMeshes } =
    settings;

  const meshes: FPMesh[] = allMeshes.filter(
    (mesh) =>
      mesh.texCoords.length > 0 && // only meshes with texCoords
      !mesh.isSideCap &&
      !mesh.isWallCap && // these have texCoords but should not have AO baked (they are rendered only with SOLID program) @todo -- should remove them from UV generation
      // !mesh.isCeiling &&  -- ceiling should not have UVs and should not have its tex baked, but it should affect AO of the room
      true
  );

  // if(verbose) console.log('bakeAOTextureForMeshes::good meshes n=', meshes.length);
  if (meshes.length === 0) throw new Error('No meshes to bake AO for');

  // @todo -- skip meshes that are never drawn with SOLID program (inner caps or smnt)
  const pixelHolder = new PixelHolder(textureSize, textureSize);
  const triangles: FPTriangle[] = makeTriangles(meshes);
  const hemisphereSamples = generateCosineWeightedHemisphereSamples(numSamples);
  const raycaster = new BVHRaycaster(triangles, maxDistance);

  if (verbose) printAreaStatistics(calculateAreaStatistics(meshes));

  const skipMeshes: number[] = [];
  // don't bake ceiling texture (but it's there to affect AO of other meshes)
  for (let i = 0; i < meshes.length; i += 1) {
    if (meshes[i].isCeiling) {
      skipMeshes.push(meshes[i].unqId);
    }
  }

  const calc = (mode) => {
    for (let i = 0; i < triangles.length; i += 1) {
      if (onlyMeshes.length > 0 && !onlyMeshes.includes(triangles[i].meshId)) continue;
      if (skipMeshes.includes(triangles[i].meshId)) continue;
      if (triangles[i].meshId % workerNum !== workerId) continue; // distribute work between workers, very non-scientific, but good enough
      iterateTriangleSurfaceByUVMain(
        triangles[i],
        textureSize,
        mode,
        pixelHolder,
        raycaster,
        hemisphereSamples,
        maxDistance,
        i
      );
      if (verbose && i % 50 === 0) console.log(`AO(worker${workerId}) Progress: ${Math.round((i / triangles.length) * 100)}%`); // prettier-ignore
    }
  };

  //
  //
  // #1 calculate only every 2nd pixel on each triangle
  //
  //
  calc('skipFew');

  //
  //
  // #2 try filling skipped pixels by checking if their neighbors are identical
  //
  //
  calc('fillSkipped');

  //
  //
  // #3 calculate every un-filled pixel that was skipped but not filled in previous step
  //
  //
  calc('all');

  //
  //
  // #4 blur the UV "islands"
  //
  //
  const timer3 = startTimer(`AO(worker${workerId}) baking blur`);
  if (postProcess) pixelHolder.blur(blurRadius, blurEdgeThreshold);
  if (verbose) endTimer(timer3);
  if (verbose) console.log(`AO(worker${workerId}`);

  return pixelHolder.pixels;
}
