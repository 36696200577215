const syncWithRAF = () => {
  let queuedCallback: ((timeStamp: number) => void) | null;

  return (callback) => {
    if (!queuedCallback && typeof window !== 'undefined') {
      requestAnimationFrame((timeStamp: number) => {
        const cb = queuedCallback;
        queuedCallback = null;
        if (cb) {
          cb(timeStamp);
        }
      });
    }

    queuedCallback = callback;
  };
};

export default syncWithRAF;
