import { BroadcastChannel } from "broadcast-channel";
import { useEffect, useMemo, useState } from "react";

const MESSAGE = "I am not alone";

const useIsTabOpen = () => {
  const channel = useMemo(() => new BroadcastChannel("guidedViewing"), []);
  const [state, setSate] = useState<boolean>(false);

  // Receive message from new tab
  useEffect(() => {
    channel.postMessage(MESSAGE);

    channel.onmessage = (msg) => {
      if (msg === MESSAGE) {
        setSate(true);
      }
    };

    return () => {
      channel.close();
    };
  }, [channel]);

  return [state] as const;
};

export default useIsTabOpen;
