import { SVGProps } from "react";

const MicOffIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 14 16"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <path
      d="m15.434 16.566 12 12a.802.802 0 0 0 1.132-1.132l-12-12a.802.802 0 0 0-1.132 1.132Z"
      transform="translate(-15.2 -14.4)"
    />
    <path
      d="M23.913 23.044a2.398 2.398 0 0 1-1.918.953v.001a2.397 2.397 0 0 1-2.4-2.4v-2.872l4.318 4.318Zm.482-1.78-4.779-4.78a2.39 2.39 0 0 1 1.46-1.902 2.397 2.397 0 0 1 3.319 2.217v4.465Zm.653 2.915a4.01 4.01 0 0 1-3.053 1.418 4.012 4.012 0 0 1-3.943-3.32.792.792 0 0 0-.784-.679.798.798 0 0 0-.773.572.814.814 0 0 0-.027.34 5.596 5.596 0 0 0 4.727 4.622v1.664c0 .44.36.8.8.8.44 0 .8-.36.8-.8v-1.664a5.588 5.588 0 0 0 3.388-1.818l-1.135-1.135Zm2.031-.232-1.24-1.24c.041-.141.074-.284.099-.429a.804.804 0 0 1 .784-.68c.488 0 .88.432.8.912-.082.5-.232.983-.443 1.437Z"
      transform="translate(-15.2 -14.4)"
    />
  </svg>
);

export default MicOffIcon;
