import InfoIcon from '../icons/InfoIcon';

const InfoButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button
      type="button"
      className={`flex relative justify-center items-center p-2 w-10 min-w-0 h-10 rounded-lg pointer-events-auto
        bg-minimap-background bg-opacity-70 fill-white stroke-white`}
      onClick={onClick}
    >
      <InfoIcon className="w-6 h-6" />
    </button>
  );
};

export default InfoButton;