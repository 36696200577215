import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Transition } from '@headlessui/react';
import { useCookieConsentContext } from '../../contexts/useCookieConsentContext';
import { useTranslation } from '../../contexts/useLocalizationContext';
import useServices from '../../contexts/useServices';
import useReactive from '../../hooks/useReactive';
import Disclaimer from './components/Disclaimer';
import TextButton from './components/TextButton';
const CookiePopup = () => {
    const { showCookiePopup, handleSubmitPreferences, setShowCookiePopup, setShowCookieModal } = useCookieConsentContext();
    const t = useTranslation();
    // This component is not based on the same theme colors as the rest of the ui, so it was easier to just
    // get the theme from the tour config service and adjust the colors, same for the TextButton component
    const { tourConfigService } = useServices();
    const { theme } = useReactive(tourConfigService, ['theme']);
    const twDisclaimerFontColor = theme === 'light' ? 'text-modal-gray-dark' : 'text-theme-primary';
    /** Handles the accept all cookies. Sets necessary, performance and preference cookies to true */
    const handleAcceptAll = () => {
        handleSubmitPreferences({ isNecessary: true, isPerformance: true, isPreferences: true });
    };
    /** Handles cookie customization - opens cookie modal and closes the popup */
    const handleCustomize = () => {
        setShowCookiePopup(false);
        setShowCookieModal(true);
    };
    return (_jsx(Transition, { appear: true, as: "div", show: showCookiePopup, className: "z-cookie-popup absolute left-1/2 top-[4.5rem] min-w-[18rem] max-w-[42.5rem] -translate-x-1/2 opacity-0 sm:top-[5.5rem]", enter: "transition duration-700 ease-out delay-1000", enterTo: "opacity-100 translate-y-0", leave: "transition duration-700 ease-out", leaveFrom: "opacity-100 translate-y-0", leaveTo: "opacity-0 translate-y-4", children: _jsxs("div", { className: "bg-theme-primary bg-opacity-theme-text-bg flex flex-col justify-items-center gap-6 rounded-md px-8 py-6 sm:flex-row", children: [_jsx(Disclaimer, { className: `font-secondary min-w-[14rem] text-sm font-normal ${twDisclaimerFontColor}` }), _jsx("div", { className: "flex flex-col items-center justify-center", children: _jsxs("div", { className: "w-[8rem]", children: [_jsx(TextButton, { className: "mb-2", variant: "primary", fullWidth: true, theme: theme, onClick: handleAcceptAll, children: t('cookie-consent.button-all') }), _jsx(TextButton, { fullWidth: true, variant: "secondary", theme: theme, onClick: handleCustomize, children: t('cookie-consent.button-customize') })] }) })] }) }));
};
export default CookiePopup;
