import type { BuildingConfig, FloorConfig, LayerConfig, TourConfig, TourConfigStructureType } from '@g360/vt-types';
import cloneDeep from 'lodash/cloneDeep';

import { linearScale } from '../math';

/**
 * Convert version string to array, separates by '.' and takes first 3 values as [major, minor, patch]
 */
export function verStrToArray(version: string): number[] {
  const versionArrayTemplate = [0, 0, 0];
  if (version === '') return versionArrayTemplate;

  const versionArray = version.split('.').map((n) => parseInt(n, 10));

  versionArrayTemplate.splice(0, versionArray.length, ...versionArray);
  versionArrayTemplate.length = 3;
  return versionArrayTemplate;
}

export function parseTourConfig(config: TourConfig, configStructureType: TourConfigStructureType): TourConfig {
  const tempConfig = cloneDeep(config);

  Object.keys(tempConfig.scenes).forEach((sceneKey) => {
    const sceneConfig = tempConfig.scenes[sceneKey];

    // Set scene key so it is easier to use SceneConfig objects
    tempConfig.scenes[sceneKey] = {
      ...sceneConfig,
      sceneKey,
    };

    // Set default building
    if (!sceneConfig.building) {
      tempConfig.scenes[sceneKey].building = '1';
    }

    if (config.size) {
      // Calculate position ratios based on floorplan size
      tempConfig.scenes[sceneKey].posRatio = {
        x: linearScale(sceneConfig.camera[0], [0, config.size[0]], [0, 1]),
        y: linearScale(sceneConfig.camera[1], [0, config.size[1]], [0, 1]),
      };
    }

    if (configStructureType === 'buildings') {
      if (!sceneConfig.floor || !sceneConfig.building) {
        // If no floor or building provided, this means that this scene is from outside
        tempConfig.scenes[sceneKey].outside = true;
      } else if (config.buildings) {
        const building: BuildingConfig = config.buildings[sceneConfig.building];
        const floor: FloorConfig = building?.floors[sceneConfig.floor];

        // Generalized value for floors, layers, levels
        tempConfig.scenes[sceneKey].level = sceneConfig.floor;

        if (floor) {
          // Reference geometry and imageUrl to each scene
          tempConfig.scenes[sceneKey].geometry = floor.geometry;
          tempConfig.scenes[sceneKey].imageUrl = floor.imageUrl;
          // Reference building size and center position
          tempConfig.scenes[sceneKey].buildingSize = building.size;
          tempConfig.scenes[sceneKey].buildingCenter = building.center;
        }
      }
    } else if (!sceneConfig.layer) {
      // If no layer provided, this means that this scene is from outside
      tempConfig.scenes[sceneKey].outside = true;
    } else if (config.layers) {
      const layer: LayerConfig = config.layers[sceneConfig.layer];

      if (layer) {
        // Reference geometry and imageUrl to each scene
        tempConfig.scenes[sceneKey].geometry = layer.geometry;
        tempConfig.scenes[sceneKey].imageUrl = layer.imageUrl;
      }

      // Generalized value for floors, layers, levels
      tempConfig.scenes[sceneKey].level = layer.level || sceneConfig.layer;
    }
  });

  return tempConfig;
}

export function getTourConfigStructureType(
  tourConfig: TourConfig,
  tourConfigVersion?: number[]
): TourConfigStructureType {
  // eslint-disable-next-line no-prototype-builtins
  if (tourConfig.hasOwnProperty('buildings')) return 'buildings';
  // eslint-disable-next-line no-prototype-builtins
  if (tourConfig.hasOwnProperty('layers')) return 'layers';
  if (!tourConfigVersion) return 'buildings';
  if (tourConfigVersion[0] <= 2) return 'layers';
  // configVersion[0] >= 3
  return 'buildings';
}
