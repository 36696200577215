/** Try to get project url from different sources with priority:
 * 1. index.html og:url meta tag
 * 2. window.location.href
 * 3. empty string when nothing else is available (should not happen)
 */

export function readProjectUrl() {
  if (typeof window === 'undefined') {
    return '';
  }

  const metaTag = document.querySelector('meta[property="og:url"]');
  const content = metaTag?.getAttribute('content');

  if (content) {
    return content;
  }

  if (window?.location) {
    const url = new URL(window.location.href);
    return url.origin + url.pathname;
  }

  return '';
}
