import { hexToRGB } from '@g360/vt-utils';

export const lerpColor = (color1: number[], color2: number[], t: number): number[] => [
  color1[0] + (color2[0] - color1[0]) * t,
  color1[1] + (color2[1] - color1[1]) * t,
  color1[2] + (color2[2] - color1[2]) * t,
];

/**
 * Convert hex color to RGB with values in range [0, 1]
 */
export const hexToRGB01 = (hexColor: string): number[] => hexToRGB(hexColor)?.map((val) => val / 255) ?? [1, 0.5, 0];
