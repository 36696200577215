export function matchHexColor(hexColor: string): RegExpExecArray | null {
  return /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
}

export function hexToRGB(hexColor: string): number[] | null {
  const match = matchHexColor(hexColor);

  if (match) {
    return [parseInt(match[1], 16), parseInt(match[2], 16), parseInt(match[3], 16)];
  }

  return null;
}

export function srgbToLinearRGB(srgbColor: number[]): number[] {
  const linearRGB: number[] = [];

  srgbColor.forEach((val, index) => {
    const value = val / 255;

    // https://entropymine.com/imageworsener/srgbformula/
    if (value <= 0.04045) {
      linearRGB[index] = value / 12.92;
    } else {
      linearRGB[index] = ((value + 0.055) / 1.055) ** 2.4;
    }
  });

  return linearRGB;
}

export function getLuminance(srgbColor: number[]): number {
  const RGB = srgbToLinearRGB(srgbColor);
  return 0.2126 * RGB[0] + 0.7152 * RGB[1] + 0.0722 * RGB[2];
}

export function getContrastRatioLuminances(l1: number, l2: number): number {
  return (Math.max(l1, l2) + 0.05) / (Math.min(l1, l2) + 0.05);
}

/** Providing base color, overlay colors in hex string and overlay opacity - return blended color rgb */
export function blendColors(rawBase: string, rawOverlay: string, opacity: number): [number, number, number] {
  let base = rawBase;
  let overlay = rawOverlay;

  if (base.startsWith('#')) {
    base = base.slice(1);
  }

  if (overlay.startsWith('#')) {
    overlay = overlay.slice(1);
  }

  const rBase = parseInt(base.slice(0, 2), 16);
  const gBase = parseInt(base.slice(2, 4), 16);
  const bBase = parseInt(base.slice(4, 6), 16);

  const rOverlay = parseInt(overlay.slice(0, 2), 16);
  const gOverlay = parseInt(overlay.slice(2, 4), 16);
  const bOverlay = parseInt(overlay.slice(4, 6), 16);

  const rFinal = Math.round((1 - opacity) * rBase + opacity * rOverlay);
  const gFinal = Math.round((1 - opacity) * gBase + opacity * gOverlay);
  const bFinal = Math.round((1 - opacity) * bBase + opacity * bOverlay);

  return [rFinal, gFinal, bFinal];
}
