import { OutlineButton, PrimaryButton } from "components/elements";
import {
  AudioSettingWrapper,
  CopyInviteLink,
  VideoSettingWrapper,
} from "components/wrappers";
import { SettingTabType } from "components/wrappers/ParticipantSettingsContainer/ParticipantSettingsContainer";
import {
  useAppStateContext,
  useDeviceStateContext,
  useMixpanelContext,
} from "hooks";
import { FC, useEffect } from "react";

interface ParticipantSettingWrapperDesktopProps {
  isSettingsModalVisible: boolean;
  settingTab: SettingTabType;
  handleDone: () => void;
  handleCancel: () => void;
  handelTabChange: (tab: SettingTabType) => void;
}

const ParticipantSettingWrapperDesktop: FC<
  ParticipantSettingWrapperDesktopProps
> = ({
  settingTab,
  isSettingsModalVisible,
  handleDone,
  handleCancel,
  handelTabChange,
}) => {
  const { isHost } = useAppStateContext();
  const {
    audioInputDevices,
    videoInputDevices,
    audioOutputDevices,
    selectedVideoInput,
    selectedAudioInput,
    selectedAudioOutput,
    setSelectedAudioInput,
    setSelectedAudioOutput,
    setSelectedVideoInput,
  } = useDeviceStateContext();
  const { Mixpanel, MixpanelEvents } = useMixpanelContext();

  useEffect(() => {
    let listener: (event: KeyboardEvent) => void;

    if (isSettingsModalVisible) {
      listener = (event: KeyboardEvent) => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
          event.preventDefault();
          handleDone();
        }
      };
      document.addEventListener("keydown", listener);
    }
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [handleDone, isSettingsModalVisible]);

  return (
    <div className="mt-6">
      <div className="flex">
        <div className="flex flex-col items-center w-[200px] sm:w-56 p-2.5 sm:border-gray-300 sm:border-y">
          {isHost && (
            <div className="w-full sm:w-48 h-[45px]">
              <PrimaryButton
                textPosition="left"
                text="Copy Invite Link"
                isInActive={settingTab !== "copy invite link"}
                handleButtonClick={() => {
                  handelTabChange("copy invite link");
                  Mixpanel.track(
                    MixpanelEvents.Section.Open("Copy Invite Link")
                  );
                }}
              />
            </div>
          )}
          <div className="w-full sm:w-48 h-[45px]">
            <PrimaryButton
              textPosition="left"
              text="Audio Settings"
              isInActive={settingTab !== "audio"}
              handleButtonClick={() => {
                handelTabChange("audio");
                Mixpanel.track(MixpanelEvents.Section.Open("Audio Settings"));
              }}
            />
          </div>
          <div className="w-full sm:w-48 h-[45px]">
            <PrimaryButton
              textPosition="left"
              text="Camera Settings"
              isInActive={settingTab !== "video"}
              handleButtonClick={() => {
                handelTabChange("video");
                Mixpanel.track(MixpanelEvents.Section.Open("Camera Settings"));
              }}
            />
          </div>
        </div>
        <form
          className="w-[calc(100%-200px)] py-[30px] px-[40px] sm:border-gray-300 sm:border-l sm:p-3 md:p-10 sm:border-y"
          onSubmit={(e) => {
            e.preventDefault();
            handleDone();
          }}
        >
          {(() => {
            switch (settingTab) {
              case "copy invite link":
                return (
                  <CopyInviteLink
                    onCopyInvite={() => {
                      Mixpanel.track(MixpanelEvents.Button.Click("Copy"));
                    }}
                  />
                );

              case "audio":
                return (
                  <AudioSettingWrapper
                    setSelectedAudioInput={setSelectedAudioInput}
                    setSelectedAudioOutput={setSelectedAudioOutput}
                    audioInputDevices={audioInputDevices}
                    audioOutputDevices={audioOutputDevices}
                    selectedAudioInput={selectedAudioInput}
                    selectedAudioOutput={selectedAudioOutput}
                  />
                );

              case "video":
                return (
                  <VideoSettingWrapper
                    setSelectedVideoInput={setSelectedVideoInput}
                    videoInputDevices={videoInputDevices}
                    selectedVideoInput={selectedVideoInput}
                  />
                );

              default:
                return null;
            }
          })()}
        </form>
      </div>
      <div className="flex justify-end px-6 py-5">
        <div className="w-36">
          <OutlineButton
            text="Cancel"
            handleButtonClick={() => {
              Mixpanel.track(MixpanelEvents.Button.Click("Cancel"));
              handleCancel();
            }}
          />
        </div>
        <div className="ml-5 w-36">
          <PrimaryButton
            text="Done"
            type="submit"
            handleButtonClick={() => {
              Mixpanel.track(MixpanelEvents.Button.Click("Done"));
              handleDone();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ParticipantSettingWrapperDesktop;
