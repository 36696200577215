import { useCallback, useEffect, useRef, useState } from 'react';
import useGlobalContext from '../../../contexts/useGlobalContext';
import useServices from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
import useResizeObserver from '../../../hooks/useResizeObserver';
import getEnabledFeatureCount from '../utils/getEnabledFeatureCount';
/** Hook for handling Idealista layout resize.
 * Open close states are handled by components: MiniMapMenu, UtilsMenu.
 * Which is easier, but may be handled differently in the future when there are
 *  more layouts with this behavior.
 *
 * @important This only works if ResizeObserver is supported, otherwise the layout
 * will not be updated correctly. All modern browsers support it.
 * However, if there is a need to support older browsers, we could try a polyfill.
 *
 * @todo This needs to support the new hot-spot arrows, but the arrow disabling
 *  is not implemented yet (?) and arrow positioning and size may need some
 *  adjustments on smaller screens
 */
const useIdealistaLayout = (enabledNavigation, enabledUtils, minMapMenuRef, utilsMenuRef) => {
    const { engine } = useGlobalContext();
    const { layoutService } = useServices();
    const { isNavigationOpen, isUtilityMenuCollapsible } = useReactive(layoutService, [
        'isNavigationOpen',
        'isUtilityMenuCollapsible',
    ]);
    const [hideUtils, setHideUtils] = useState(false);
    const [hideNav, setHideNav] = useState(false);
    const canvasRef = useRef(engine.getCanvasElement());
    const isNavOpenRef = useRef(isNavigationOpen);
    const isUtilsCollapsibleRef = useRef(isUtilityMenuCollapsible);
    const enabledNavigationRef = useRef(enabledNavigation);
    const enabledUtilsRef = useRef(enabledUtils);
    /** Last canvas size when utils menu was hidden */
    const utilsLastHideBreakPoint = useRef(0);
    const setLayoutState = useCallback((state) => {
        layoutService.setUtilityMenuCollapsible(state.utilsCollapse);
        // When menus are hidden, also set them to closed state
        // Makes it easier to handle the layout when menus are shown again
        if (state.hideUtils)
            layoutService.toggleUtilityMenu(false);
        if (state.hideNav)
            layoutService.toggleNavigation(false);
        setHideUtils(state.hideUtils);
        setHideNav(state.hideNav);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    const handleResize = useCallback((source) => {
        var _a;
        /** Intermediary state which needs to be prepared for an update */
        const state = {
            hideUtils: false,
            hideNav: false,
            utilsCollapse: false,
        };
        /** Same break point as tailwind smvh: */
        const twSmvhBreakPoint = 640;
        /** Same as the compact outer padding in LayoutPadding container */
        const outerPad = 16;
        /** Same as tailwind space-x-3 smvh:space-x-4 */
        const btnSpacing = { small: 12, large: 14 };
        /** Button size for the utility menu button */
        const btnSize = 40;
        const canvasWidth = canvasRef.current.clientWidth;
        const canvasHeight = canvasRef.current.clientHeight;
        /** If element not fond, consider it as far left of the screen (0) */
        const miniMapMenuBounding = ((_a = minMapMenuRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect()) || new DOMRect();
        /** Extra space for collision detection, so there is some space between before overlapping.
         * This is same as css horizontal gap between buttons: space-x-3 smvh:space-x-4 */
        const extraSpace = canvasWidth > twSmvhBreakPoint ? btnSpacing.small : btnSpacing.large;
        /** There is a minimum size for mini-map we can show before it gets useless / out of bounds.
         * Below this breakpoint we should hide scene navigation (min-map, mini-map menu) */
        const isSpaceForMiniMap = canvasWidth >= layoutService.minSafeSize.width && canvasHeight >= layoutService.minSafeSize.height;
        /** Count of how many utils buttons are enabled, if only one, there is no
         * need for collapsible state. If no buttons are enabled, utility menu
         * should be hidden
         */
        const enabledUtilCount = getEnabledFeatureCount(enabledUtilsRef.current);
        /** Count how many navigation element/buttons are enabled, if none,
         * navigation should be hidden and utility menu shown */
        const enabledNavCount = getEnabledFeatureCount(enabledNavigationRef.current);
        /** Utility menu should be collapsible if width is less than 640 (tailwind smvh: break point) */
        const shouldUtilsMenuCollapse = enabledUtilCount > 1 && canvasWidth < twSmvhBreakPoint;
        // Update intermediate state from the current state
        state.utilsCollapse = isUtilsCollapsibleRef.current;
        // When navigation is closed or menu sizes changed, need to reset the break point
        if (!isNavOpenRef.current || source === 'navigation' || source === 'utils') {
            utilsLastHideBreakPoint.current = 0;
        }
        /** Canvas size when min map menu is overlapping with collapsed utils menu.
         * This assumes the menu button is with specific size and padding */
        const hideBreakPoint = miniMapMenuBounding.right + extraSpace + btnSize + outerPad;
        // If there is no space for mini-map (below minSafeSize): hide navigation,
        // show utility as collapsible (if necessary), nothing else so return early
        if (!isSpaceForMiniMap || enabledNavCount === 0) {
            return setLayoutState(Object.assign(Object.assign({}, state), { hideNav: true, hideUtils: false, utilsCollapse: shouldUtilsMenuCollapse }));
        }
        // There is no utility buttons enabled, so hide the utility menu and exit early
        if (enabledUtilCount === 0) {
            return setLayoutState(Object.assign(Object.assign({}, state), { hideUtils: true }));
        }
        // If there is not enough space for the utility menu button, hide it. In
        // this we check if there is potential collision with the utils menu
        // button only and not the whole menu, because both menus can't be open at
        // the same time. If there is a collision, hide the utility menu and exit
        if (canvasWidth < hideBreakPoint) {
            utilsLastHideBreakPoint.current = hideBreakPoint;
            return setLayoutState(Object.assign(Object.assign({}, state), { hideUtils: true }));
        }
        // If not overlapping and not over the break point keep the utility menu
        // hidden. Otherwise this would cause oscillation between hidden and visible state
        if (canvasWidth < utilsLastHideBreakPoint.current) {
            return setLayoutState(Object.assign(Object.assign({}, state), { hideUtils: true }));
        }
        // Utility menu is visible, so reset the break point
        utilsLastHideBreakPoint.current = 0;
        return setLayoutState(Object.assign(Object.assign({}, state), { utilsCollapse: shouldUtilsMenuCollapse }));
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    // Need to react to canvas and menu size changes
    useResizeObserver(() => handleResize('canvas'), canvasRef, true, 3);
    useResizeObserver(() => handleResize('navigation'), minMapMenuRef, true, 3);
    useResizeObserver(() => handleResize('utils'), utilsMenuRef, true, 3);
    useEffect(() => {
        enabledNavigationRef.current = enabledNavigation;
        enabledUtilsRef.current = enabledUtils;
        handleResize('props');
        // enabledNavigation as dependency because mini map menu size can change
        // based on the enabled navigation controls
        // enabledUtils can change after the fullscreen api is initialized
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enabledNavigation, enabledUtils]);
    return { hideUtils, hideNav };
};
export default useIdealistaLayout;
