var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const Textarea = (_a) => {
    var { className, error, testId } = _a, rest = __rest(_a, ["className", "error", "testId"]);
    const twBorderColor = error ? 'border-modal-red' : 'border-modal-gray-light focus:border-modal-gray-dark';
    return (_jsxs("div", { className: "relative flex w-full flex-col", children: [_jsx("textarea", Object.assign({ className: `
        font-primary text-modal-dark no-appearance peer min-h-[9.375rem] resize-none rounded border px-6 pb-2
        pt-6 text-base font-normal outline-none placeholder:font-bold ${twBorderColor} ${className}` }, rest, { placeholder: " ", id: "floating-label-input", "data-testid": testId })), _jsx("label", { htmlFor: "floating-label-input", className: `
          peer-focus:text-sx font-primary text-modal-gray-label pointer-events-none absolute left-6 top-2 flex h-4
          transform items-center text-xs font-bold duration-300
          peer-placeholder-shown:top-4 peer-placeholder-shown:h-6 peer-placeholder-shown:text-base peer-focus:top-2
          peer-focus:h-4 peer-focus:text-xs
        `, children: rest.placeholder }), error && (_jsx("label", { htmlFor: "floating-label-input", className: "font-primary text-modal-red items-center pl-6 pt-1 text-xs font-bold", children: error }))] }));
};
export default Textarea;
