/* eslint-disable no-continue,@typescript-eslint/no-non-null-assertion,no-restricted-syntax */
// @todo -- REM console.logs + debug params

import type { Costs, Graph, Pano, Panos } from '@g360/vt-types';

import { haveCommonElement } from './dataUtils';

/**
 * Delete identical nodes that are next to each other in the path
 * @param path
 */
export const clearNeighboringDuplicates = (path: string[]) => {
  const newFinalPath: string[] = [];
  let lastElement = '';
  path.forEach((element) => {
    if (element !== lastElement) {
      newFinalPath.push(element);
    }
    lastElement = element;
  });
  return newFinalPath;
};

/**
 * Find the shortest path between 2 nodes in a graph. Using node count not actual path length.
 * Dijkstra's algorithm
 * @param graph
 * @param start
 * @param end
 */
export const findPath = (graph: Graph, start: string, end: string): string[] | undefined => {
  const visited: Set<string> = new Set();
  const queue: { node: string; path: string[] }[] = [{ node: start, path: [start] }];
  while (queue.length > 0) {
    const currentPath = queue.shift()!;
    const currentNode = currentPath.node;

    if (visited.has(currentNode)) {
      continue;
    }

    if (currentNode === end) {
      return currentPath.path;
    }

    visited.add(currentNode);

    const neighbors = graph.get(currentNode) || [];
    neighbors.forEach((neighbor) => {
      if (!visited.has(neighbor)) {
        queue.push({ node: neighbor, path: [...currentPath.path, neighbor] });
      }
    });
  }

  return undefined;
};

/**
 * Same as findPath(), but with dynamic end function
 * @param graph
 * @param start
 * @param end
 */
export const findPathWithEndFunction = (
  graph: Graph,
  start: string,
  end: (node: string) => boolean
): string[] | undefined => {
  const visited: Set<string> = new Set();
  const queue: { node: string; path: string[] }[] = [{ node: start, path: [start] }];

  while (queue.length > 0) {
    const currentPath = queue.shift()!;
    const currentNode = currentPath.node;

    if (visited.has(currentNode)) {
      continue;
    }

    if (end(currentNode)) {
      return currentPath.path;
    }

    visited.add(currentNode);

    const neighbors = graph.get(currentNode) || [];
    neighbors.forEach((neighbor) => {
      if (!visited.has(neighbor)) {
        queue.push({ node: neighbor, path: [...currentPath.path, neighbor] });
      }
    });
  }

  return undefined;
};

/**
 * Create a graph of all the panos and their destinations.
 * Using hotspot data from tour.json - currently old/manual ones, later new/automatic hotspots,
 * but the data is the same: "where you can go from a pano"
 */
export const createWholeGraph = (panos: Panos): Graph => {
  const graph = new Map<string, string[]>();
  Object.keys(panos).forEach((sceneKey) => {
    const destinations: string[] = [];
    const hotSpots = panos[sceneKey].hotSpots;
    if (!hotSpots) {
      console.log('createWholeGraph::no hotspots for sceneKey:', sceneKey, { panos }); //  @todo -- remove explanation
      return;
    }
    for (let i = 0; i < hotSpots.length; i += 1) {
      destinations.push(hotSpots[i].target);
    }
    graph.set(sceneKey, destinations);
  });

  return graph;
};

export const createSubGraph = (graph: Graph, panos: Panos, subGraphId: string): Graph => {
  const subGraph = new Map<string, string[]>();

  graph.forEach((destinations, sceneKey) => {
    if (!sceneKey || !panos[sceneKey]?.subGraphId) return;
    if (panos[sceneKey].subGraphId !== subGraphId) return;
    subGraph.set(
      sceneKey,
      destinations.filter((destination) => panos[destination]?.subGraphId === subGraphId)
    );
  });

  return subGraph;
};

/**
 * Recursively spread a subgraph from a scene to all its destinations.
 * @param sceneKey
 * @param subGraphId
 * @param graph
 * @param panos
 * @param subGraphs_
 */
const spreadSubGraph = (
  sceneKey: string,
  subGraphId: number,
  graph: Graph,
  panos: Panos,
  subGraphs_: Record<string, string>
) => {
  subGraphs_[sceneKey] = subGraphId.toString(); // put this scene in this subgraph

  const destinations = graph.get(sceneKey);
  if (!destinations) return;

  if (panos[sceneKey].outside) return; // outside scenes are not part of any subgraph (but their own)

  const building = panos[sceneKey].building;
  const floor = panos[sceneKey].floor;

  for (let i = 0; i < destinations.length; i += 1) {
    if (!subGraphs_[destinations[i]]) {
      const destinationPano = panos[destinations[i]];
      if (!destinationPano) continue;

      // if  this destination scene is not in any subgraph yet, try to add it to this subgraph
      const destBuilding = destinationPano.building;
      const destFloor = destinationPano.floor;
      if (building === destBuilding && floor === destFloor) {
        spreadSubGraph(destinations[i], subGraphId, graph, panos, subGraphs_);
      }
    }
  }
};

/**
 * Create sub-graphs of panos that are in the same building and floor.
 * Puts data back into panos_
 * @param panos_
 * @param graph
 */
export const createSubGraphAndAddToPanos = (panos_: Panos, graph: Graph): void => {
  let subGraphId = 1;
  const subGraphs: Record<string, string> = {}; // sceneKey => subGraphId

  Object.keys(panos_).forEach((sceneKey) => {
    panos_[sceneKey].subGraphId = '0';
  });

  Array.from(graph.keys()).forEach((sceneKey) => {
    if (!subGraphs[sceneKey]) {
      // this scene is not in any subgraph yet
      spreadSubGraph(sceneKey, subGraphId, graph, panos_, subGraphs);
      subGraphId += 1;
    }
  });

  Object.keys(subGraphs).forEach((sceneKey) => {
    panos_[sceneKey].subGraphId = subGraphs[sceneKey];
  });
};

/**
 * Distance between 2 panos: straight line, in 2D
 * @param key1
 * @param key2
 * @param panos
 */
export const getPanoGeometricDistance = (key1: string, key2: string, panos: Panos): number => {
  const pano1 = panos[key1];
  const pano2 = panos[key2];

  if (!pano1 || !pano2) {
    // console.error(`getPanoDistance::No pano for key1 ${key1} or key2 ${key2},  @todo -- investigate`);
    // most likely we deleted the panos, for not having building/floor info
    return Infinity;
  }
  const dx = pano1.camera[0] - pano2.camera[0];
  const dy = pano1.camera[1] - pano2.camera[1];
  return Math.sqrt(dx * dx + dy * dy);
};

const getLowestCostNode = (costs: Costs, processed: Set<string>): string | undefined => {
  let lowestCost = Infinity;
  let lowestCostNode: string | undefined;

  // Loop over each node in the costs
  costs.forEach((cost, node) => {
    // If it's the lowest cost so far and hasn't been processed yet...
    if (cost < lowestCost && !processed.has(node)) {
      // ... update the lowest cost and set the lowest cost node.
      lowestCost = cost;
      lowestCostNode = node;
    }
  });

  return lowestCostNode;
};

const pushIntoGraph = (key1: string, key2: string, graph_: Graph) => {
  const destinations = graph_.get(key2) || [];
  if (destinations.indexOf(key1) === -1) {
    destinations.push(key1);
  }
  graph_.set(key2, destinations);
};

const getPanoPathLength = (key1: string, key2: string, graph: Graph) => {
  const path = findPath(graph, key1, key2);
  if (!path) {
    console.error(`getPanoPathLength::Dijkstra:No path from ${key1} to ${key2}`, { graph });
    throw new Error(`getPanoPathLength::Dijkstra:No path from ${key1} to ${key2}`);
    // return Infinity;
  }
  return path.length;
};

/**
 * Create a minimum spanning tree of the graph.
 * @param graph
 * @param panos
 * @param graphLength
 * @param startNode
 * @param geometricDistance
 */
export const createGraphMST = (
  graph: Graph,
  panos: Panos,
  graphLength: number,
  startNode: string,
  geometricDistance = true
): Graph => {
  const parents: Map<string, string> = new Map();
  const costs: Map<string, number> = new Map();
  const processed: Set<string> = new Set();

  graph.forEach((_, node) => {
    costs.set(node, Infinity);
  });
  costs.set(startNode, 0);

  let node = getLowestCostNode(costs, processed);

  const getDistance = (key1: string, key2: string) => {
    if (geometricDistance) return getPanoGeometricDistance(key1, key2, panos);
    return getPanoPathLength(key1, key2, graph);
  };

  while (node) {
    const cost = costs.get(node) || 0;
    const children = graph.get(node) || [];
    for (let i = 0; i < children.length; i += 1) {
      const child = children[i];
      const childNodeConnectionCount = graph.get(child)?.length || 0;
      const newCost = cost + childNodeConnectionCount + getDistance(node, child) / graphLength;

      if (!costs.has(child) || costs.get(child)! > newCost) {
        costs.set(child, newCost);
        parents.set(child, node);
      }
    }

    processed.add(node);
    node = getLowestCostNode(costs, processed);
  }

  // plain map to our format: map with arrays
  const finalGraph = new Map<string, string[]>();
  parents.forEach((sceneKey1, sceneKey2) => {
    pushIntoGraph(sceneKey1, sceneKey2, finalGraph);
    pushIntoGraph(sceneKey2, sceneKey1, finalGraph);
  });

  return finalGraph;
};

const cloneGraph = (graph: Graph): Graph => {
  const newGraph = new Map<string, string[]>();
  graph.forEach((destinations, scene) => {
    newGraph.set(scene, JSON.parse(JSON.stringify(destinations)));
  });
  return newGraph;
};

const getRoomForScene = (sceneKey: string, panos: Panos) => {
  const pano = panos[sceneKey];
  return pano.roomIds[0] ?? '--';
};

export const graphHasMultiRoomLoop = (graph: Graph, panos: Panos): boolean => {
  const visited = new Set<string>();
  const recursionStack = new Set<string>();

  function dfs(node: string, path: string[]): boolean {
    if (recursionStack.has(node)) {
      // We've found a loop, now check if it spans multiple rooms
      const loopStart = path.indexOf(node);
      const loopPath = path.slice(loopStart);
      const rooms = new Set(loopPath.map((n) => getRoomForScene(n, panos)));
      return rooms.size > 1;
    }

    if (visited.has(node)) {
      return false;
    }

    visited.add(node);
    recursionStack.add(node);
    path.push(node);

    for (const neighbor of graph.get(node) || []) {
      if (dfs(neighbor, path)) {
        return true;
      }
    }

    recursionStack.delete(node);
    path.pop();
    return false;
  }

  for (const node of graph.keys()) {
    if (!visited.has(node) && dfs(node, [])) {
      return true;
    }
  }

  return false;
};

/**
 * Restore loops that are in the same room in the MST graph (where all loops were removed).
 * @param mstGraph
 * @param fullGraph
 * @param panos
 */
export const restoreRoomLoops = (mstGraph: Graph, fullGraph: Graph, panos: Panos): Graph => {
  let optimizedGraph = cloneGraph(mstGraph);

  fullGraph.forEach((destinations, sceneKeyFrom) => {
    destinations.forEach((sceneKeyTo) => {
      const inSameRoom = haveCommonElement(panos[sceneKeyFrom].roomIds, panos[sceneKeyTo].roomIds);
      if (!inSameRoom) return;

      const maybeGraph = cloneGraph(optimizedGraph);

      pushIntoGraph(sceneKeyFrom, sceneKeyTo, maybeGraph);
      pushIntoGraph(sceneKeyTo, sceneKeyFrom, maybeGraph);

      // Because of this project gramex/60842ad3241b42dbb5db590294aa64a8
      // we can't just willy-nilly restore loops in rooms,
      // sometimes the rooms are very cheeky and restoring a loop
      // in a room actually creates a multi-room loop and that
      // messes up final path finding.
      // A  -x-   B
      // |        |
      // C   -    D
      // A & B are one room, C & D are 2 separate rooms.
      // This function would like to restore the loop between A & B, that was deleted by MST function,
      // since it qualifies as a loop contained in a single room.
      // But this creates loop that spans multiple rooms.
      if (!graphHasMultiRoomLoop(maybeGraph, panos)) {
        optimizedGraph = maybeGraph;
      }
    });
  });
  return optimizedGraph;
};

/**
 * Condensed graph: each subGraph is a node in this graph
 * @param panos
 * @param firstSceneKey
 */
export const createSuperGraph = (panos: Panos, firstSceneKey: string): Graph => {
  const superGraph = new Map<string, string[]>();
  Object.keys(panos).forEach((sceneKey) => {
    const hotSpots = panos[sceneKey].hotSpots;
    const subGraphIdFrom = panos[sceneKey].subGraphId;
    if (!hotSpots) {
      console.log('createSuperGraph::no hotspots for sceneKey:', sceneKey); //  @todo -- remove explanation
      return;
    }
    if (!subGraphIdFrom) return;
    for (let i = 0; i < hotSpots.length; i += 1) {
      const toSceneKey = hotSpots[i].target;
      if (!toSceneKey || !panos[toSceneKey]) continue;
      const subGraphIdTo = panos[toSceneKey].subGraphId;
      if (subGraphIdTo && subGraphIdFrom !== subGraphIdTo) {
        pushIntoGraph(subGraphIdFrom, subGraphIdTo, superGraph);
        pushIntoGraph(subGraphIdTo, subGraphIdFrom, superGraph);
      }
    }
  });

  if (superGraph.size === 0) return superGraph; // no links at all, no tuning-up is needed

  // check if there are subGraphs that are not a part of the superGraph
  const unrepresentedSubGraphIds: string[] = [];
  Object.keys(panos).forEach((sceneKey) => {
    const pano = panos[sceneKey];
    const subGraphId = pano.subGraphId as string;
    if (!superGraph.get(subGraphId) && !unrepresentedSubGraphIds.includes(subGraphId)) {
      unrepresentedSubGraphIds.push(subGraphId);
    }
  });

  if (unrepresentedSubGraphIds.length === 0) return superGraph; // all subGraphs are represented in the superGraph
  // console.error('All panos are not linked. Some subGraphs are not represented in the superGraph. Not great, not terrible. Will try to fix.', { unrepresentedSubGraphIds }); // prettier-ignore

  const firstSubGraphId = panos[firstSceneKey].subGraphId;
  if (!firstSubGraphId) throw new Error('createSuperGraph::firstSubGraphId not found, what???');

  // it would be best to link the orphans to the first subGraph (starting pano),
  // but if the starting pano is itself in an orphaned subGraph,
  // then link to the first subGraph that is in the superGraph - not guaranteed to be the best one
  let linkId: string; // subGraph ID to link to the unrepresented subGraphs
  if (superGraph.get(firstSubGraphId)) {
    linkId = firstSubGraphId;
  } else {
    const [rndFirstSubGraph] = superGraph.keys();
    linkId = rndFirstSubGraph;
  }

  for (let i = 0; i < unrepresentedSubGraphIds.length; i += 1) {
    const existingDestinations = superGraph.get(linkId) || [];
    existingDestinations.push(unrepresentedSubGraphIds[i] as string);
    superGraph.set(linkId, existingDestinations); // add TO unrepresented
    superGraph.set(unrepresentedSubGraphIds[i] as string, [linkId]); // also add from unrepresented BACK
  }

  return superGraph;
};

const getPathGeomLength = (graph: Map<string, string[]>, nodeA: string, nodeB: string, panos: Panos) => {
  const path = findPath(graph, nodeA, nodeB) as string[];
  let sumPathLength = 0;
  for (let i = 0; i < path.length - 1; i += 1) {
    sumPathLength += getPanoGeometricDistance(path[i], path[i + 1], panos);
  }
  return sumPathLength;
};

const getPathLength = (graph: Map<string, string[]>, nodeA: string, nodeB: string): number | undefined => {
  const path = findPath(graph, nodeA, nodeB) as string[];
  if (!path) return undefined;
  return path.length - 1;
};

/**
 *
 * @param graph
 * @param totalGraphLength
 * @param currentNode
 * @param worthwhileNodes
 * @param panos -- if provided then assumed the graph contains panos as nodes (otherwise - nodes are subGraphIds)
 */
const getBestNextNode = (
  graph: Graph,
  totalGraphLength: number,
  currentNode: string,
  worthwhileNodes: Set<string>,
  panos: Panos | undefined
): string | undefined => {
  let minCost = Infinity;
  let bestNode = '';
  worthwhileNodes.forEach((node) => {
    const pathGeometricLength = panos
      ? getPathGeomLength(graph, currentNode, node, panos)
      : getPathLength(graph, currentNode, node);
    if (!pathGeometricLength) return; // no path found
    const destinations = graph.get(node) || [];
    const cost = destinations.length + pathGeometricLength / totalGraphLength;
    if (cost < minCost) {
      minCost = cost;
      bestNode = node;
    }
  });

  return bestNode || undefined;
};

// all dead-end nodes + ones not visited while visiting dead-ends (in room loops there might be nodes that are never visited)
const creteWorthwhileNodes = (graph: Graph, unvisited: Set<string>, startNode: string, debug = false) => {
  const seenWorthwhile = new Set<string>();
  const worthwhile = new Set<string>();
  graph.forEach((destinations, sceneKey) => {
    if (destinations.length === 1) {
      worthwhile.add(sceneKey);
    }
  });

  if (debug) console.log('createPathSmart::worthwhile:', Array.from(worthwhile));

  worthwhile.forEach((node) => {
    let worthwhilePath = findPath(graph, startNode, node);
    if (!worthwhilePath) {
      worthwhilePath = findPath(graph, node, startNode);
      if (worthwhilePath) {
        worthwhilePath.reverse();
      }

      worthwhilePath = [startNode, node]; // no path ? just make it up!
    }
    worthwhilePath.forEach((worthwhilePathNode) => {
      seenWorthwhile.add(worthwhilePathNode);
    });
  });

  if (debug) console.log('createPathSmart::seenWorthwhile:', { seenWorthwhile });

  // find all nodes that are not in the paths while visiting worthwhile nodes
  unvisited.forEach((node) => {
    if (!seenWorthwhile.has(node)) {
      worthwhile.add(node);
      if (debug) console.log('createPathSmart::worthwhile added:', node);
    }
  });
  return worthwhile;
};

/**
 *
 * @param graph
 * @param start -- sceneKey
 * @param panos
 * @param allHaveOutsidePositions -- if true, then all panos have outside positions, if false, then can't use positions and must assume they all are equidistant
 * @param totalGraphLength -- sum of all paths in graph
 */
export const createSmartPathForOutsideOnly = (
  graph: Graph,
  start: string,
  panos: Panos,
  allHaveOutsidePositions: boolean,
  totalGraphLength: number
): string[] => {
  // for neighboring panoramas so no path searching, just check hotspots
  const getDistance = (keyA: string, keyB: string) => {
    if (!allHaveOutsidePositions) return 1; // assume all are equidistant
    return getPanoGeometricDistance(keyA, keyB, panos);
  };

  const path: string[] = [];
  const allNodes = new Set(graph.keys());
  const visited = new Set<string>();
  function dfs(current: string) {
    path.push(current);
    visited.add(current);

    if (visited.size === allNodes.size) {
      // All nodes have been visited at least once
      return true;
    }

    const neighbors = graph.get(current) || [];
    let bestNode = '';
    let bestScore = Infinity;

    for (let i = 0; i < neighbors.length; i += 1) {
      const neighbor = neighbors[i];
      const score = ((graph.get(neighbor)?.length ?? 1) + getDistance(current, neighbor)) / totalGraphLength; // prefer closer nodes with fewer neighbors (distance matters only if neighbors are equal)
      if (score < bestScore && !visited.has(neighbor)) {
        bestScore = score;
        bestNode = neighbor;
      }
    }

    if (bestNode && dfs(bestNode)) {
      return true;
    }

    // Explore other neighbors
    for (let i = 0; i < neighbors.length; i += 1) {
      const neighbor = neighbors[i];
      if (dfs(neighbor)) {
        return true;
      }
    }

    // Backtrack
    path.pop();
    visited.delete(current);
    return false;
  }

  dfs(start);
  return path;
};

/**
 *
 * @param ogGraph
 * @param startNode
 * @param panos -- if provided then assumed the graph contains panos as nodes (otherwise - nodes are subGraphIds)
 * @param debug
 */
export const createPathSmart = (
  ogGraph: Graph,
  startNode: string,
  panos: Panos | undefined,
  debug = false
): string[] => {
  const graph = cloneGraph(ogGraph);
  if (debug) console.log('createPathSmart::ogGraph', ogGraph);
  const path = [startNode];
  let currentNode = startNode;
  const visited = new Set<string>();
  visited.add(startNode);
  const unvisited = new Set<string>(graph.keys());
  unvisited.delete(startNode);
  if (debug) console.log('createPathSmart::path push', startNode);

  let totalGraphLength = 0;
  graph.forEach((destinations) => {
    totalGraphLength += destinations.length / 2; // each destination is counted twice
  });
  if (debug) console.log({ totalGraphLength });

  const worthwhileNodes = creteWorthwhileNodes(graph, unvisited, startNode, debug);
  worthwhileNodes.delete(startNode);
  if (debug) console.log('createPathSmart', { worthwhileNodes });

  while (worthwhileNodes.size > 0) {
    const bestNextNode = getBestNextNode(graph, totalGraphLength, currentNode, worthwhileNodes, panos);
    if (!bestNextNode) {
      path.push(...Array.from(worthwhileNodes));
      if (debug) console.log('all the worthwhile nodes are unreachable from this node, so we just add them to path, and ... just fly there I guess'); // prettier-ignore
      break;
      // throw new Error('createPathSmart::pathToWorthwhileNode not found, this is bad! A');
    }
    const pathToWorthwhileNode = findPath(graph, currentNode, bestNextNode);
    if (!pathToWorthwhileNode) {
      throw new Error('createPathSmart::pathToWorthwhileNode not found, this is bad! B');
    }
    pathToWorthwhileNode.shift(); // remove first node, it is already in the path
    path.push(...pathToWorthwhileNode);
    currentNode = bestNextNode;
    worthwhileNodes.delete(bestNextNode);
    if (debug) console.log('createPathSmart::getBestNextNode', { bestNextNode }, 'added;  path:', [...pathToWorthwhileNode]); // prettier-ignore
  }

  return path;
};

/**
 * Get a pano by its subGraphId, just a random one, not guaranteed to be first in graph
 * @param panos
 * @param subGraphId
 */
export const getAPanoBySubGraphId = (panos: Panos, subGraphId: string): Pano | null => {
  const entry = Object.entries(panos).find(([, pano]) => pano.subGraphId === subGraphId);
  return entry ? entry[1] : null;
};

/**
 * Delete nodes that have the same building as the previous node.
 * Outside counts as separate building.
 * @param path in subGraph ids
 * @param panos
 */
export const clearNeighboringBuildingDuplicatesFromSubGraphPath = (path: string[], panos: Panos) => {
  const newFinalPath: string[] = [];
  let lastBuildingAndOutside = '';
  path.forEach((element) => {
    const pano = getAPanoBySubGraphId(panos, element);
    if (!pano) return;
    const buildingAndOutside = `${pano.building}+${pano.outside ? 'outside' : ''}`;
    if (lastBuildingAndOutside !== buildingAndOutside) {
      newFinalPath.push(element);
      lastBuildingAndOutside = buildingAndOutside;
    }
  });
  return newFinalPath;
};

/**
 * Inter building path contains only building subGraphs (except if starting pano is outside, then that subGraph too)
 */
export const createInterBuildingPath = (superGraph: Graph, panos: Panos, startPanoSceneKey: string): string[] => {
  // We need find out in what of the subGraphs that are left the start pano is
  const ogStartPano = panos[startPanoSceneKey];
  const startPanoBuilding = ogStartPano.building;
  const startPanoOutside = ogStartPano.outside;
  let startSubGraphId = ogStartPano.subGraphId; // if outside, this will do, if inside, we will overwrite it

  Array.from(superGraph.keys()).forEach((subGraphId) => {
    const pano = getAPanoBySubGraphId(panos, subGraphId);
    if (pano && !pano.outside && !startPanoOutside && pano.building === startPanoBuilding) {
      startSubGraphId = subGraphId; // for inside panos
    }
  });

  if (!startSubGraphId) {
    console.error('createInterBuildingPath::startSubGraphId not found');
    return [];
  }

  // path is subGraphIds
  let path = createPathSmart(superGraph, startSubGraphId, undefined);

  const first = path[0];
  path.shift();

  // remove path nodes that are outside (don't touch first node, it can be outside)
  path = path.filter((subGraphId) => {
    const pano = getAPanoBySubGraphId(panos, subGraphId);
    if (!pano) return false;
    return !pano.outside;
  });

  const fullPath = [first, ...path];

  // remove nodes that are in the same building as the previous one
  const cleanedPath = clearNeighboringBuildingDuplicatesFromSubGraphPath(fullPath, panos);

  return clearNeighboringDuplicates(cleanedPath);
};

/**
 * Creates final subGraphs: create MST version and then restore loops [in the same room]
 * return all subGraphs subGraphId => subGraph
 */
export const optimizeSubGraphs = (panos: Panos, wholeGraph: Graph): Map<string, Graph> => {
  const subGraphs = new Map<string, Graph>();

  const subGraphIds = Array.from(new Set(Object.values(panos).map((p) => p.subGraphId)));

  for (let i = 0; i < subGraphIds.length; i += 1) {
    const subGraphId = subGraphIds[i];
    if (!subGraphId) continue;

    let sumDistances = 0;
    let startNode = '';

    wholeGraph.forEach((destinations, sceneKey) => {
      const nodeIsInThisSubGraph = panos[sceneKey].subGraphId === subGraphId;
      if (!nodeIsInThisSubGraph) return;
      destinations.forEach((destinationSceneKey) => {
        const distance = getPanoGeometricDistance(sceneKey, destinationSceneKey, panos);
        sumDistances += distance / 2; // since we are counting both ways: a->b and b->a
      });

      if (startNode === '') startNode = sceneKey; // first node alphabetically, maybe real start node would be best, but this is ok
    });

    const subGraphPrime = createSubGraph(wholeGraph, panos, subGraphId);
    if (subGraphPrime.size === 1) {
      subGraphs.set(subGraphId, subGraphPrime); // for lone graphs use the original graph (optimizing looses the only node)
      continue;
    }
    const mstSubGraph = createGraphMST(subGraphPrime, panos, sumDistances, startNode);
    const subGraph = restoreRoomLoops(mstSubGraph, subGraphPrime, panos); // MST eliminates loops, but we can put back loops that are in the same room
    subGraphs.set(subGraphId, subGraph);
  }

  return subGraphs;
};

/**
 * Return a list of all floors in order they must be visited:
 * from start floor upstairs, then all basement floors (in reverse order)
 * @param building
 * @param panos
 * @param startFloor
 */
export const getAllFloorsForBuildingSorted = (building: string, panos: Panos, startFloor: string): string[] => {
  const floors: string[] = [];

  Object.keys(panos).forEach((sceneKey) => {
    const pano = panos[sceneKey];
    if (!pano.outside && pano.floor && pano.building === building && !floors.includes(pano.floor)) {
      floors.push(pano.floor);
    }
  });

  // floors are strings containing numbers (including half floors and negative ones)
  floors.sort((a, b) => {
    const numA = parseFloat(a);
    const numB = parseFloat(b);
    if (numA > numB) return 1;
    if (numA < numB) return -1;
    return 0;
  });

  if (!floors.includes(startFloor)) {
    console.error({ floors, startFloor });
    throw new Error(`getAllFloorsForBuildingSorted::Floor ${startFloor} does not exist in the provided list`);
  }

  const startFloorNum = parseFloat(startFloor);
  const positiveFloors = floors.filter((floor) => parseFloat(floor) >= startFloorNum);
  const negativeFloors = floors.filter((floor) => parseFloat(floor) < startFloorNum).reverse();

  return [...positiveFloors, ...negativeFloors];
};

/**
 * Find the shortest path between 2 nodes in a graph.
 * Start node is rnd node from subGraphA
 * Finish node is first encountered pano from subGraphB
 * @param wholeGraph
 * @param subGraphIdA
 * @param subGraphIdB
 * @param panos
 */
export const findPanoPathBetweenSubGraphs = (
  subGraphIdA: string,
  subGraphIdB: string,
  wholeGraph: Graph,
  panos: Panos
) => {
  const startNode = getAPanoBySubGraphId(panos, subGraphIdA);
  if (!startNode) return;
  // eslint-disable-next-line consistent-return
  return findPathWithEndFunction(
    wholeGraph,
    startNode?.sceneKey,
    (sceneKey: string) => panos[sceneKey]?.subGraphId === subGraphIdB
  );
};

/**
 * Find the shortest path between a specific pano and first pano from given subGraph
 * @param panoSceneKey
 * @param subGraphId
 * @param wholeGraph
 * @param panos
 */
export const findPanoPathBetweenPanoAndSubGraph = (
  panoSceneKey: string,
  subGraphId: string,
  wholeGraph: Graph,
  panos: Panos
) =>
  findPathWithEndFunction(wholeGraph, panoSceneKey, (sceneKey: string) => panos[sceneKey]?.subGraphId === subGraphId);

export const findAllSubGraphIdsForBuildingAndFloor = (
  building: string,
  floor: string,
  outside: boolean,
  panos: Panos
): string[] => {
  const unqSubGraphs: string[] = [];
  Object.keys(panos).forEach((sceneKey) => {
    const pano = panos[sceneKey];
    if (
      pano.building === building &&
      pano.floor === floor &&
      !!pano.outside === outside &&
      pano.subGraphId &&
      !unqSubGraphs.includes(pano.subGraphId)
    ) {
      unqSubGraphs.push(pano.subGraphId);
    }
  });
  return unqSubGraphs;
};

export const getBuildingInfoForSubGraph = (
  lastSubGraphId: string | undefined,
  interBuildingSubGraphId: string,
  wholeGraph: Graph,
  panos: Panos,
  tourStartScene: string
) => {
  let startFloor: string | undefined;
  let building: string | undefined;

  if (!lastSubGraphId) {
    // this is the first node in the subGraph, then use starting pano
    const firstPano = panos[tourStartScene];
    startFloor = firstPano.floor;
    building = firstPano.building;
  } else {
    // we need to find starting pano in this subGraph
    // to do that: find path from the last subgraph to this subGraph and then use first node in this subgraph
    const path = findPanoPathBetweenSubGraphs(lastSubGraphId, interBuildingSubGraphId, wholeGraph, panos);
    if (path) {
      const firstPanoInThisSubGraph = panos[path[path.length - 1]];
      startFloor = firstPanoInThisSubGraph.floor;
      building = firstPanoInThisSubGraph.building;
    } else {
      // console.error('getBuildingInfoForSubGraph::no path found! this should not be happening!',{lastSubGraphId, interBuildingSubGraphId, wholeGraph, panos});
      const pano = getAPanoBySubGraphId(panos, interBuildingSubGraphId);
      if (pano) {
        startFloor = pano.floor;
        building = pano.building;
      } else {
        throw new Error(
          'getBuildingInfoForSubGraph::no path found and no pano by sub graph id ?! this should not be happening!'
        );
      }
    }
  }

  return { startFloor, building };
};

// @todo -- since this is only for some edge cases, maybe not use pathfinding, just use pano distance
export const sortSubGraphsByDistanceFromPano = (
  subGraphIds: string[],
  panoSceneKey: string,
  wholeGraph: Graph,
  panos: Panos
) => {
  if (subGraphIds.length < 2) return subGraphIds;

  const newSubGraphIds: string[] = [];
  const distances: Record<string, number> = {};

  subGraphIds.forEach((subGraphId) => {
    const path = findPanoPathBetweenPanoAndSubGraph(panoSceneKey, subGraphId, wholeGraph, panos);
    distances[subGraphId] = path?.length || Infinity;
    newSubGraphIds.push(subGraphId);
  });

  return newSubGraphIds.sort((a, b) => distances[a] - distances[b]);
};

export const removeOneWayConnections = (graph: Graph): Graph => {
  const newGraph = new Map<string, string[]>(graph);

  newGraph.forEach((edges, node) => {
    const mutualConnections = edges.filter((edge) => newGraph.get(edge)?.includes(node));
    newGraph.set(node, mutualConnections);
  });
  return newGraph;
};

export const makeOneWayConnectionsToTwoWay = (graph: Graph): Graph => {
  const newGraph = new Map<string, string[]>(graph);

  newGraph.forEach((edges, node) => {
    edges.forEach((edge) => {
      if (!newGraph.get(edge)?.includes(node)) {
        newGraph.set(edge, [...(newGraph.get(edge) || []), node]);
      }
    });
  });
  return newGraph;
};

export const countGraphConnections = (graph: Graph) =>
  Array.from(graph.values()).reduce((sum, destinations) => sum + destinations.length, 0);

export const getUnvisitedOutsidePanos = (panos: Panos, finalPath: string[]) =>
  Object.keys(panos).filter((sceneKey) => !finalPath.includes(sceneKey) && panos[sceneKey].outside);
