import type { TourConfig } from '@g360/vt-types';

import objectToQueryString from '../misc/objToQueryString';
import { fetchWithRetries } from './fetchWithRetries';

class ResponseError extends Error {
  name = 'ResponseError';
  response?: Response;

  constructor(response?: Response) {
    super(response?.statusText || response?.status?.toString() || 'No status');

    this.response = response;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ResponseError);
    }
  }
}

function loadTourJson(
  jsonPath: string,
  signatureData: Record<string, string>,
  abortSignal?: AbortSignal
): Promise<TourConfig> {
  const signatureQuery = objectToQueryString(signatureData);
  return new Promise((resolve, reject) => {
    const signedJsonPath = signatureQuery.length > 0 ? `${jsonPath}?${signatureQuery}` : jsonPath;

    fetchWithRetries(signedJsonPath, { credentials: 'include', signal: abortSignal })
      .then((res) => {
        if (!res.ok) {
          throw new ResponseError(res);
        }

        return res.json();
      })
      .then((tourConfig) => resolve(tourConfig))
      .catch((error) => {
        if (error?.stack?.indexOf('SyntaxError') !== -1) {
          // Json failed to parse - broken or redirected to an HTML response
          reject(new Error('Parse Error'));
          return;
        }

        if (error?.name === 'ResponseError') {
          const { status } = error.response;

          let errorMessage = 'Unknown Error';
          if (status === 403) errorMessage = 'Access Denied';
          if (status === 404) errorMessage = '404 Not Found';

          reject(new Error(errorMessage));
          return;
        }

        // Possibly user is offline or CORS error
        reject(new Error('Network Error'));
      });
  });
}

export default loadTourJson;
