import { RemoteParticipant } from "twilio-video";

const isParticipantsInArray = (
  obj: RemoteParticipant,
  list: RemoteParticipant[]
) => {
  for (let i = 0; i < list.length; i++) {
    if (list[i]?.identity === obj.identity) {
      return true;
    }
  }

  return false;
};

export default isParticipantsInArray;
