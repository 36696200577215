import type { AssetConfig, Subscription } from '@g360/vt-types';
import { getTextScaleForVideoEditor } from '@g360/vt-utils';
import opentype from 'opentype.js';
import urljoin from 'url-join';

import {
  destroyProgram,
  disableVertexAttributes,
  enableVertexAttributes,
  initShaders,
  loadShaders,
} from '../../../common/webglUtils';
import fragmentShaderSource from './text.fs.glsl';
import vertexShaderSource from './text.vs.glsl';
import type { BoundingBox } from './utils';
import { getTextData } from './utils';

type TextProgramEvents = 'fonts.loaded' | 'render';

type TextParams2D = {
  text: string;
  font: string;
  fontSize: number;
  fontColor: [number, number, number, number];
  clipSpaceOffset: [number, number];
};

const defaultFont = 'GilroyMedium';

class TextProgram {
  fontData: { [key: string]: opentype.Font } | null = null;

  protected canvas: HTMLCanvasElement;
  protected boundingBox: BoundingBox | null = null;
  protected textParams: TextParams2D = {
    text: '',
    font: defaultFont,
    fontSize: 72,
    fontColor: [0, 0, 0, 1],
    clipSpaceOffset: [0.0, 0.0],
  };
  protected fonts: { [key: string]: string } = {
    [defaultFont]: 'fonts/gilroy/Gilroy-Medium.woff2',
  };

  private program: WebGLProgram | null = null;

  private gl: WebGLRenderingContext;

  private assetConfig: AssetConfig;

  private indexData: Uint16Array = new Uint16Array(0);
  private vertexData: Float32Array = new Float32Array(0);
  private vertexAttribute = 0;
  private vertexBuffer: WebGLBuffer | null;
  private textureBuffer: WebGLBuffer | null;
  private scaleUniform: WebGLUniformLocation | null = null;
  private offsetUniform: WebGLUniformLocation | null = null;
  private colorUniform: WebGLUniformLocation | null = null;

  private vertexAttributes: number[] = []; // list vertex attributes to be enabled before and disabled after a draw in order to not mess up state of other programs

  private listeners: { [key: string]: ((payload) => void)[] } = {};

  constructor(webGLContext: WebGLRenderingContext, canvas: HTMLCanvasElement, assetConfig: AssetConfig) {
    this.gl = webGLContext;
    this.canvas = canvas;

    this.vertexBuffer = this.gl.createBuffer();
    this.textureBuffer = this.gl.createBuffer();

    this.assetConfig = assetConfig;

    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  init(): void {
    this.program = initShaders(this.gl, vertexShaderSource, fragmentShaderSource);

    if (!this.program) {
      throw new Error('PROGRAM_NOT_INITIALIZED');
    }

    this.scaleUniform = this.gl.getUniformLocation(this.program, 'u_scale');
    this.offsetUniform = this.gl.getUniformLocation(this.program, 'u_offset');
    this.colorUniform = this.gl.getUniformLocation(this.program, 'u_color');
    this.vertexAttribute = this.gl.getAttribLocation(this.program, 'a_position');

    this.vertexAttributes = [this.vertexAttribute];

    this.loadFonts();
    window.addEventListener('resize', this.handleWindowResize);
  }

  update(textParams: TextParams2D): void {
    if (!this.fontData) {
      this.subscribeOnce('fonts.loaded', () => this.update(textParams));
      return;
    }

    this.textParams = textParams;

    const textScale = getTextScaleForVideoEditor(this.canvas.getBoundingClientRect());
    const { vertexData, indexData, boundingBox } = this.getTextData(textScale);

    this.vertexData = vertexData;
    this.indexData = indexData;
    this.boundingBox = boundingBox;
  }

  hide() {
    this.update({ ...this.textParams, text: '' });
  }

  draw(): void {
    if (!this.program || !this.boundingBox) return;

    loadShaders(this.gl, this.program);
    enableVertexAttributes(this.gl, this.vertexAttributes);

    this.gl.uniform2fv(this.scaleUniform, [2.0 / this.canvas.clientWidth, -2.0 / this.canvas.clientHeight]);

    const color = this.textParams.fontColor ?? [0, 0, 0, 1];

    this.gl.uniform2fv(this.offsetUniform, this.textParams.clipSpaceOffset);
    this.gl.uniform4fv(this.colorUniform, color);

    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.textureBuffer);
    this.gl.bufferData(this.gl.ELEMENT_ARRAY_BUFFER, this.indexData, this.gl.STATIC_DRAW);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.vertexData, this.gl.STATIC_DRAW);
    this.gl.vertexAttribPointer(this.vertexAttribute, 2, this.gl.FLOAT, true, 8, 0);

    this.gl.drawElements(this.gl.TRIANGLES, this.indexData.length, this.gl.UNSIGNED_SHORT, 0);

    disableVertexAttributes(this.gl, this.vertexAttributes);

    this.emit('render');
  }

  destroy(): void {
    destroyProgram(this.gl, this.program);
  }

  getFontPath(fontName: string) {
    if (!this.fonts[fontName]) {
      throw new Error(`Font ${fontName} not found!`);
    }

    return urljoin(this.assetConfig.assetPath, this.fonts[fontName]);
  }

  handleWindowResize(): void {
    this.update(this.textParams);
  }

  subscribe(event: TextProgramEvents, fn: (payload) => void): Subscription {
    if (event !== undefined) {
      this.listeners[event] = this.listeners[event] || [];

      if (!this.listeners[event].filter((c) => c !== fn).length) {
        this.listeners[event].push(fn);
      }
    }

    return {
      unsubscribe: () => {
        this.unsubscribe(event, fn);
      },
    };
  }

  subscribeOnce(event: TextProgramEvents, fn: (payload) => void): Subscription {
    const onceWrapper = (payload) => {
      this.unsubscribe(event, onceWrapper);
      fn(payload);
    };

    this.subscribe(event, onceWrapper);

    return {
      unsubscribe: () => {
        this.unsubscribe(event, onceWrapper);
      },
    };
  }

  unsubscribe(event: TextProgramEvents, fn: (payload) => void): void {
    if (this.listeners[event]) {
      this.listeners[event] = this.listeners[event].filter((c) => c !== fn);
    }
  }

  protected loadFonts() {
    return Promise.all(
      Object.keys(this.fonts).map((fontName) =>
        fetch(this.getFontPath(fontName))
          .then((res) => res.arrayBuffer())
          .then((data) => {
            const font = opentype.parse(data);
            if (!font) {
              console.warn(`Font ${fontName} could not be loaded!`);
              throw new Error('FONT_LOAD_ERROR');
            }
            return { name: fontName, font };
          })
      )
    )
      .then((loadedFonts) => {
        this.fontData = loadedFonts.reduce((acc, { name, font }) => {
          // eslint-disable-next-line no-param-reassign
          acc[name] = font;
          return acc;
        }, {});
        this.emit('fonts.loaded');
      })
      .catch((error) => {
        console.warn(`Error loading fonts: ${error}`);
        throw new Error('FONT_LOAD_ERROR');
      });
  }

  protected getTextMaxWidth() {
    return this.canvas.clientWidth;
  }

  protected getTextData(fontScale: number) {
    if (!this.fontData) {
      return {
        vertexData: new Float32Array(0),
        indexData: new Uint16Array(0),
        boundingBox: null,
      };
    }

    const { vertexData, indices, boundingBox } = getTextData(
      this.textParams.text,
      this.fontData[this.textParams.font],
      this.textParams.fontSize * fontScale,
      this.getTextMaxWidth()
    );

    return {
      vertexData,
      indexData: indices,
      boundingBox,
    };
  }

  private destroyEventEmitter(): void {
    this.listeners = {};
  }

  private emit(event: TextProgramEvents, payload?): void {
    if (this.listeners[event] && this.listeners[event].length) {
      this.listeners[event].forEach((listener) => listener(payload));
    }
  }
}

export default TextProgram;
