import setTextureParameters from './setTextureParameters';

/**
 * Creates a new texture. Minification and magnification filters are set to `gl.LINEAR`, wrapping functions are set to `gl.CLAMP_TO_EDGE`. Resets texture parameters to default values after creating the texture.
 * @param gl - `WebGLRenderingContext`: An interface to the OpenGL ES 2.0 graphics rendering context for the drawing surface of an HTML `<canvas>` element.
 * @param placeholderColor - `number[]`: A RGBA array with placeholder to fill the the texture. Values must be in the `[0, 255]` range. Default value is `[0, 255, 0, 0]`.
 * @param useAlphaChannel - `boolean` - `Optional`: Whether to use the alpha channel. Sets the texture format to `gl.RGB` if `true` and to `gl.RGBA` if `false`. Default value is `false`.
 * @returns `WebGLTexture | null`: The created texture.
 */
function createTexture(
  gl: WebGLRenderingContext,
  placeholderColor: number[] = [0, 255, 0, 0],
  useAlphaChannel = false
): WebGLTexture | null {
  const texture = gl.createTexture();

  gl.bindTexture(gl.TEXTURE_2D, texture);
  setTextureParameters(gl);

  const colorFormat: GLenum = useAlphaChannel ? gl.RGBA : gl.RGB;
  // fill with 1px placeholder color
  gl.texImage2D(gl.TEXTURE_2D, 0, colorFormat, 1, 1, 0, colorFormat, gl.UNSIGNED_BYTE, new Uint8Array(placeholderColor)); // prettier-ignore

  return texture;
}

export default createTexture;
