import type {
  AutoPlaySceneFrames,
  DataJsonRoomType,
  DataJsonType,
  Panos,
  PointOfInterest,
  Scenes,
} from '@g360/vt-types';

import { offsetPoint, rotatePoint } from './geometryUtils';

const DEBUG = false;

export const makePanos = (scenes_: Scenes): Panos => {
  Object.keys(scenes_).forEach((sceneKey) => {
    const scene = scenes_[sceneKey];
    scene.sceneKey = sceneKey;
  });
  return scenes_ as Panos;
};
/**
 * Add roomIds from Data JSON to panos
 * Pano can have multiple rooms (merged, most likely)
 *
 * @param panos_
 * @param dataJson
 */
export const addRoomIdsToPanos = (panos_: Panos, dataJson: DataJsonType | null): void => {
  if (!dataJson) return;

  const addRoomToPano = (room: DataJsonRoomType, roomId: string) => {
    let idNamePanoId = room.id_name;
    const mergedRoomsPanoIds = room.merged_rooms;

    if (idNamePanoId) {
      idNamePanoId = idNamePanoId.replace(/-SUB\d$/g, ''); // pano-SUB1 -> pano
      const pano = panos_[idNamePanoId];
      if (pano) {
        pano.roomIds.push(roomId);
      }
    }

    if (mergedRoomsPanoIds) {
      // get room siblings for disabled panos / merged rooms
      let notAdded: string[] = [...mergedRoomsPanoIds]; // all pano IDs that are not found in tour pano list
      const added: string[] = [];
      for (let i = 0; i < mergedRoomsPanoIds.length; i += 1) {
        const pano = panos_[mergedRoomsPanoIds[i]];
        if (pano) {
          pano.roomIds.push(roomId);
          notAdded = notAdded.filter((v) => v !== mergedRoomsPanoIds[i]);
          added.push(mergedRoomsPanoIds[i]);
        }
      }

      // check not-added panos and get their room ids
      if (added.length > 0 && notAdded.length > 0) {
        const addedPanoId = added[0]; // pano we have in tour list, we will add its unlisted siblings
        notAdded.forEach((notAddedPanoId) => {
          Object.keys(dataJson.rooms).forEach((notAddedRoomId) => {
            const notAddedRoom = dataJson.rooms[notAddedRoomId];
            if (notAddedRoom.id_name === notAddedPanoId) {
              const pano = panos_[addedPanoId];
              if (pano) {
                pano.roomIds.push(notAddedRoomId);
              }
            }
          });
        });
      }
    }
  };

  Object.keys(panos_).forEach((sceneKey) => {
    const pano = panos_[sceneKey];
    pano.roomIds = [];
  });

  Object.keys(dataJson.rooms).forEach((roomId) => {
    const room = dataJson.rooms[roomId];
    addRoomToPano(room, roomId);
    const parentRoom = room.parent_room_id;
    if (parentRoom) {
      addRoomToPano(room, roomId);
    }
  });

  if (DEBUG) {
    // ---------------------
    // debug statistics
    // ---------------------
    let nonMatched = 0;
    Object.keys(dataJson.rooms).forEach((roomId) => {
      let matched = false;
      Object.keys(panos_).forEach((sceneKey) => {
        const pano = panos_[sceneKey];
        if (pano.roomIds.includes(roomId)) {
          matched = true;
        }
      });

      if (!matched) {
        console.log('addRoomIdsToPanos::room ID not belonging to pano:', {
          roomId,
          room: dataJson.rooms[roomId],
          portals: dataJson.rooms[roomId].portals,
        });
        nonMatched += 1;
      }
    });

    if (nonMatched > 0) {
      console.log(
        `%caddRoomIdsToPanos::nonMatched=${nonMatched} maybe do some geometric matching if this is a big problem?`,
        'color: #f00'
      );
    }
  }
};

/**
 * match portals in dataJson
 * data.json should have the data, but it doesn't
 *
 * Different approach than in backend, but this works better
 */
export const superMatchPortals = (dataJson_: DataJsonType | null) => {
  if (!dataJson_) return;
  let numMatched = 0;

  const distanceBetweenPoints = (point1: number[], point2: number[]): number => {
    const dx = point2[0] - point1[0];
    const dy = point2[1] - point1[1];
    return Math.sqrt(dx * dx + dy * dy);
  };

  const linesMatch = (line1: [number[], number[]], line2: [number[], number[]]) => {
    /* "A→B" is 1 portal, B→D is another portal
        A       C

        B       D
        They can be upside down relative to one another, so we need to check both configurations
     */
    const distAC = distanceBetweenPoints(line1[0], line2[0]);
    const distAD = distanceBetweenPoints(line1[0], line2[1]);
    const distBC = distanceBetweenPoints(line1[1], line2[0]);
    const distBD = distanceBetweenPoints(line1[1], line2[1]);
    const config1 = distAC + distBD;
    const config2 = distAD + distBC;
    const dist = Math.min(config1, config2);
    return dist < 0.8; // in meters
  };

  Object.keys(dataJson_.portals).forEach((portalIdA) => {
    const portalA = dataJson_.portals[portalIdA];
    if (portalA.type !== 'door') return;

    const roomA = dataJson_.rooms[portalA.room_id];
    const floorA = roomA.floor.toString();
    const buildingA = roomA.building.toString();
    let roomRotationA = roomA.rotation_rad;
    let roomOffsetA = roomA.position_m;
    if (!roomOffsetA && roomA.parent_room_id) {
      const parentRoom = dataJson_.rooms[roomA.parent_room_id];
      roomOffsetA = parentRoom.position_m;
      roomRotationA = parentRoom.rotation_rad;
    }
    const p1A = offsetPoint(rotatePoint(roomRotationA, portalA.bot_left_3d_m), roomOffsetA);
    const p2A = offsetPoint(rotatePoint(roomRotationA, portalA.bot_right_3d_m), roomOffsetA);

    Object.keys(dataJson_.portals).forEach((portalIdB) => {
      if (portalIdA === portalIdB) return;
      const portalB = dataJson_.portals[portalIdB];
      // @todo -- get rooms and check if they are on same floor/building
      if (portalB.type !== 'door') return;

      const roomB = dataJson_.rooms[portalB.room_id];
      const floorB = roomB.floor.toString();
      const buildingB = roomB.building.toString();

      if (floorA !== floorB || buildingA !== buildingB) return;

      let roomRotationB = roomB.rotation_rad;
      let roomOffsetB = roomB.position_m;
      if (!roomOffsetB && roomB.parent_room_id) {
        const parentRoom = dataJson_.rooms[roomB.parent_room_id];
        roomOffsetB = parentRoom.position_m;
        roomRotationB = parentRoom.rotation_rad;
      }
      const p1B = offsetPoint(rotatePoint(roomRotationB, portalB.bot_left_3d_m), roomOffsetB);
      const p2B = offsetPoint(rotatePoint(roomRotationB, portalB.bot_right_3d_m), roomOffsetB);

      const matchDistance = linesMatch([p1A, p2A], [p1B, p2B]);
      if (matchDistance) {
        dataJson_.portals[portalIdB].matched = true;
        dataJson_.portals[portalIdA].matched = true;
        dataJson_.portals[portalIdB].debug2 = numMatched.toString();
        dataJson_.portals[portalIdA].debug2 = numMatched.toString();
        dataJson_.portals[portalIdB].matched_id = portalIdA;
        dataJson_.portals[portalIdA].matched_id = portalIdB;
        numMatched += 1;
      }
    });
  });
};

/**
 * Find portals (doors & windows) in Data JSON and add their ids to panos corresponding to room that portal is in
 *
 * @param panos
 * @param dataJson
 */
export const addPortalsToPanos = (panos: Panos, dataJson: DataJsonType | null): void => {
  if (!dataJson) return;
  // let numPortals = 0;

  Object.keys(panos).forEach((sceneKey) => {
    const pano = panos[sceneKey];
    pano.portals = [];
  });

  Object.entries(dataJson.rooms).forEach(([roomId, room]) => {
    const portals = room.portals;
    if (!portals) return;

    Object.keys(panos).forEach((sceneKey) => {
      const pano = panos[sceneKey];
      if (!pano.roomIds.includes(roomId)) return;
      panos[sceneKey].portals.push(...portals);
      // numPortals += pano.portals.length;
    });
  });

  // console.log('addPortalsToPanos::', { numPortals });
};

/**
 * Remove all entrances from interest points if previous or next pano in path is outside/different building,
 * assuming we are using said entrances
 *
 * @param panos
 * @param interestPoints
 * @param path
 * @param pathId
 */
export const filterOutUsedEntrances = (
  panos: Panos,
  interestPoints: PointOfInterest[],
  path: string[],
  pathId: number
) => {
  const thisPano = panos[path[pathId]];
  const nextPano = panos[path[(pathId + 1) % path.length]];
  const prevPano = panos[path[(pathId + path.length - 1) % path.length]];
  const isArriving = thisPano.building !== prevPano.building || prevPano.outside;
  const isLeaving = thisPano.building !== nextPano.building || nextPano.outside;
  const removeEntrances = isArriving || isLeaving;
  if (!removeEntrances) return interestPoints;
  // console.log("filterOutUsedEntrances::", thisPano.sceneKey, { isArriving, isLeaving, removeEntrances });
  return interestPoints.filter((point) => point.type !== 'entrance');
};

export const haveCommonElement = <T>(array1: T[], array2: T[]): boolean => {
  const set1 = new Set(array1);
  return array2.some((element) => set1.has(element));
};

export const calculateDuration = (scenesFrames: AutoPlaySceneFrames[]) => {
  const totalDuration = scenesFrames.reduce(
    (acc, sft) => acc + sft.frames.reduce((innerAcc, frame) => innerAcc + frame.duration, 0),
    0
  );
  const min = Math.floor(totalDuration / 60000);
  const sec = Math.floor((totalDuration - min * 60000) / 1000);
  const totalSeconds = totalDuration / 1000;
  return { min, sec, totalSeconds, totalMilliseconds: totalDuration };
};
