import type { MediaPermissionsError } from "mic-check";
import { MediaPermissionsErrorType, requestMediaPermissions } from "mic-check";
import { useCallback, useEffect, useState } from "react";

const useDevicesMediaAccess = () => {
  const [audioAccess, setAudioAccess] = useState<boolean | undefined>();
  const [videoAccess, setVideoAccess] = useState<boolean | undefined>();

  const handleMediaPermissionError = (err: MediaPermissionsError) => {
    const { type, name, message } = err as MediaPermissionsError;

    if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
      console.error(
        `Browser does not have permission to access camera or microphone ${name}: ${message}`
      );
    } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
      console.error(
        `User denied permission to access camera or microphone ${name}: ${message}`
      );
    } else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
      console.error(
        `Camera or microphone is in use by another application ${name}: ${message}`
      );
    } else {
      console.error(
        `Unknown error occurred with camera or microphone ${name}: ${message}`
      );
    }
  };

  const getMediaPermissions = async (media: {
    audio?: boolean | undefined;
    video?: boolean | undefined;
  }) => {
    try {
      const success = await requestMediaPermissions(media);
      return { success, error: null };
    } catch (error) {
      return { success: false, error };
    }
  };

  const performAudioCheck = useCallback(async () => {
    const { success, error } = await getMediaPermissions({ audio: true });
    setAudioAccess(success);

    if (error) {
      handleMediaPermissionError(error as MediaPermissionsError);
    }

    return success;
  }, []);

  const performVideoCheck = useCallback(async () => {
    const { success, error } = await getMediaPermissions({ video: true });
    setVideoAccess(success);

    if (error) {
      handleMediaPermissionError(error as MediaPermissionsError);
    }

    return success;
  }, []);

  useEffect(() => {
    const runPerformAudioCheck = async () => {
      await performAudioCheck();
    };
    const runPerformVideoCheck = async () => {
      await performVideoCheck();
    };

    runPerformAudioCheck();
    runPerformVideoCheck();
  }, []);

  return {
    audioAccess,
    videoAccess,
    performAudioCheck,
    performVideoCheck,
    setAudioAccess,
    setVideoAccess,
  };
};

export default useDevicesMediaAccess;
