(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@g360/vt-engine"), require("@g360/vt-utils"), require("react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["@g360/vt-engine", "@g360/vt-utils", "react", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["vt-skin"] = factory(require("@g360/vt-engine"), require("@g360/vt-utils"), require("react"), require("react-dom"));
	else
		root["vt-skin"] = factory(root["@g360/vt-engine"], root["@g360/vt-utils"], root["react"], root["react-dom"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__7320__, __WEBPACK_EXTERNAL_MODULE__4374__, __WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__7111__) {
return 