import type { Point } from './utils';
import { cross, distance, lerp } from './utils';

// bezier discretization
const MAX_BEZIER_STEPS = 10;
const BEZIER_STEP_SIZE = 3.0;
// this is for inside checks - doesn't have to be particularly
// small because glyphs have finite resolution
const EPSILON = 1e-6;

// class for converting path commands into point data
export default class Polygon {
  points: Point[] = [];
  children: Polygon[] = [];
  area = 0.0;

  moveTo(p: Point) {
    this.points.push(p);
  }

  lineTo(p: Point) {
    this.points.push(p);
  }

  close() {
    let cur = this.points[this.points.length - 1];
    this.points.forEach((next) => {
      this.area += 0.5 * cross(cur, next);
      cur = next;
    });
  }

  conicTo(p: Point, p1: Point) {
    const p0 = this.points[this.points.length - 1];
    const dist = distance(p0, p1) + distance(p1, p);
    const steps = Math.max(2, Math.min(MAX_BEZIER_STEPS, dist / BEZIER_STEP_SIZE));
    for (let i = 1; i <= steps; i += 1) {
      const t = i / steps;
      this.points.push(lerp(lerp(p0, p1, t), lerp(p1, p, t), t));
    }
  }

  cubicTo(p: Point, p1: Point, p2: Point) {
    const p0 = this.points[this.points.length - 1];
    const dist = distance(p0, p1) + distance(p1, p2) + distance(p2, p);
    const steps = Math.max(2, Math.min(MAX_BEZIER_STEPS, dist / BEZIER_STEP_SIZE));
    for (let i = 1; i <= steps; i += 1) {
      const t = i / steps;
      const a = lerp(lerp(p0, p1, t), lerp(p1, p2, t), t);
      const b = lerp(lerp(p1, p2, t), lerp(p2, p, t), t);
      this.points.push(lerp(a, b, t));
    }
  }

  inside(p) {
    let count = 0;
    let cur = this.points[this.points.length - 1];
    this.points.forEach((next) => {
      const p0 = cur.y < next.y ? cur : next;
      const p1 = cur.y < next.y ? next : cur;
      if (p0.y < p.y + EPSILON && p1.y > p.y + EPSILON) {
        if ((p1.x - p0.x) * (p.y - p0.y) > (p.x - p0.x) * (p1.y - p0.y)) {
          count += 1;
        }
      }
      cur = next;
    });
    return count % 2 !== 0;
  }
}
