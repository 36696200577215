import config from "config";

const loginAsHost = async (payload: { username: string; password: string }) => {
  const loginData = await fetch(
    `${config.baseUrl}/gapi/dashboard/auth/login/`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );

  const data = await loginData.json();
  return data;
};

export default loginAsHost;
