import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useGlobalContext from '../../../contexts/useGlobalContext';
import { useTranslation } from '../../../contexts/useLocalizationContext';
import useServices from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
const UnitIcon = ({ children, active }) => {
    const twActive = active ? 'bg-modal-dark text-white' : '';
    return (_jsx("div", { className: `flex h-6 w-[1.875rem] items-center justify-center rounded-xl font-bold ${twActive}`, children: children }));
};
const UnitSwitch = ({ className }) => {
    const { tourConfigService } = useServices();
    const t = useTranslation();
    const { units } = useReactive(tourConfigService, ['units']);
    const { analytics } = useGlobalContext();
    const handleChange = () => {
        tourConfigService.units = units === 'metric' ? 'imperial' : 'metric';
        analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'INFO', 'Unit switch', { units: tourConfigService.units });
    };
    return (_jsxs("button", { className: `bg-modal-gray font-primary text-modal-dark hover:bg-modal-gray-hover flex h-7 w-16
        items-center justify-center rounded-2xl text-sm ${className}`, type: "button", onClick: handleChange, children: [_jsx(UnitIcon, { active: units === 'metric', children: t('units.m') }), _jsx(UnitIcon, { active: units !== 'metric', children: t('units.ft') })] }));
};
export default UnitSwitch;
