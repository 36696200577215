const iOSSafari = (userAgent) => /iP(ad|od|hone)/i.test(userAgent) && /WebKit/i.test(userAgent) && !/(CriOS|FxiOS|OPiOS|mercury)/i.test(userAgent);
const isIOSSafari = typeof window !== 'undefined' ? iOSSafari(navigator.userAgent) : false;
const isSafari = typeof window !== 'undefined' &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (/constructor/i.test(window.HTMLElement) ||
        (function (p) {
            return p.toString() === '[object SafariRemoteNotification]';
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
        })(!(window === null || window === void 0 ? void 0 : window.safari) || (typeof safari !== 'undefined' && safari.pushNotification)));
// Safari can't zoom in on images that are scaled down in HTML (the result is blurry)
// so the original image is scaled up in HTML to its original size and then here the scale is fixed
// and the result looks normal
const knownIsSafari = isSafari || isIOSSafari;
// this still is needed for new SVG minimaps
export default function getFloorplanImageZoomInFactor() {
    if (knownIsSafari) {
        return 3; // "3" works fine even at 580% minimap zoom  |  "2" would be sufficient for SVG's
    }
    return 1;
}
