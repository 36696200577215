import fscreen from 'fscreen';
import { useEffect, useState } from 'react';

type ReturnType = {
  isFullscreen: boolean;
  enabledFullscreen: boolean;
  toggleFullscreen: () => void;
};

const useFullScreen = (): ReturnType => {
  const [enabledFullscreen, setEnabledFullscreen] = useState(false);
  const [isFullscreen, setFullscreen] = useState(false);

  const toggleFullscreen = () => {
    if (fscreen.fullscreenElement === null) {
      fscreen.requestFullscreen(document.documentElement);
    } else {
      fscreen.exitFullscreen();
    }
  };

  const handler = () => {
    if (fscreen.fullscreenElement === null) {
      setFullscreen(false);
    } else {
      setFullscreen(true);
    }
  };

  useEffect(() => {
    setEnabledFullscreen(fscreen.fullscreenEnabled);

    fscreen.addEventListener('fullscreenchange', handler, false);

    return () => {
      fscreen.removeEventListener('fullscreenchange', handler, false);
    };
  }, []);

  return {
    isFullscreen,
    enabledFullscreen,
    toggleFullscreen,
  };
};

export default useFullScreen;
