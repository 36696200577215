export async function fetchWithRetries(
  url: string,
  options: RequestInit = {},
  maxRetryCount = 3,
  delay = 500,
  attempt = 0
): Promise<Response> {
  try {
    const res = await fetch(url, options);

    if (!res.ok) throw new Error(res.statusText);

    return res;
  } catch (error) {
    // Propagate the error if we have reached the maximum number of retries
    if (attempt >= maxRetryCount) throw error;

    // Wait for the delay before retrying
    await new Promise((resolve) => setTimeout(resolve, delay));

    // Add exponential backoff using recursion
    return fetchWithRetries(url, options, maxRetryCount, delay * 2, attempt + 1);
  }
}
