import { Spinner } from "components/elements";
import { FC } from "react";
import { classNamesUtil } from "utils/classNamesUtil";

interface PrimaryButtonProps {
  text: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  isInActive?: boolean;
  bgColor?: "red" | "dark" | "green" | "red-inverse" | "white";
  type?: "button" | "submit";
  textPosition?: "left" | "right" | "center";
  size?: "small" | "medium";
  handleButtonClick: () => void;
}

const PrimaryButton: FC<PrimaryButtonProps> = ({
  text,
  isDisabled,
  type = "button",
  bgColor = "dark",
  isLoading = false,
  isInActive = false,
  textPosition = "center",
  size = "medium",
  handleButtonClick,
}) => {
  return (
    <button
      type={type}
      disabled={isDisabled}
      onClick={handleButtonClick}
      className={classNamesUtil(
        !isDisabled ? "shadow" : "",
        !isInActive && bgColor === "red"
          ? "bg-giraffe-red hover:bg-giraffe-red-hover"
          : "",
        !isInActive && bgColor === "dark"
          ? "text-white bg-giraffe-dark hover:bg-[#485363]"
          : "",
        !isInActive && bgColor === "red-inverse"
          ? "bg-giraffe-gray-super-dark hover:bg-giraffe-red text-giraffe-red hover:text-white"
          : "",
        !isInActive && bgColor === "green"
          ? "bg-giraffe-green hover:bg-giraffe-green-hover "
          : "",
        !isInActive && bgColor === "white"
          ? "bg-white hover:bg-giraffe-gray-light text-giraffe-dark"
          : "",
        !isDisabled && isInActive
          ? "bg-giraffe-gray-light hover:bg-giraffe-gray-medium text-black"
          : "",
        isDisabled ? "bg-gray-300 text-gray-400 cursor-not-allowed" : "",
        size === "small" ? "h-[30px]" : "h-10",
        "w-full text-sm font-bold rounded-md font-primary transition-all duration-150 ease-in-out inline-block text-center"
      )}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <span
          className={classNamesUtil(
            textPosition === "left" ? "text-left" : "",
            textPosition === "right" ? "text-right" : "",
            textPosition === "center" ? "text-center" : "",
            "w-full font-bold text-sm tracking-wide font-primary px-5"
          )}
        >
          {text}
        </span>
      )}
    </button>
  );
};

export default PrimaryButton;
