import { SVGProps } from "react";

const TabIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 84 57"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
    }}
    {...props}
  >
    <g transform="translate(-8.877 -2.877)">
      <path
        d="M88 11c0-2.208-1.792-4-4-4H32c-2.208 0-4 1.792-4 4v36c0 2.208 1.792 4 4 4h52c2.208 0 4-1.792 4-4V11Z"
        style={{
          fill: "#fff",
          stroke: "#1a222e",
          strokeWidth: 2,
        }}
      />
      <path
        d="M28 16h60"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#1a222e",
          strokeWidth: 2,
          strokeLinecap: "butt",
        }}
      />
      <path
        d="M73 15c0-2.208-1.792-4-4-4H17c-2.208 0-4 1.792-4 4v36c0 2.208 1.792 4 4 4h52c2.208 0 4-1.792 4-4V15Z"
        style={{
          fill: "#fff",
          stroke: "#1a222e",
          strokeWidth: 2,
        }}
      />
      <path
        d="M13 20h60"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#1a222e",
          strokeWidth: 2,
          strokeLinecap: "butt",
        }}
      />
      <circle
        cx={17.5}
        cy={15.5}
        r={1.5}
        style={{
          fill: "#1a222e",
        }}
      />
      <circle
        cx={22.5}
        cy={15.5}
        r={1.5}
        style={{
          fill: "#1a222e",
        }}
      />
      <circle
        cx={27.5}
        cy={15.5}
        r={1.5}
        style={{
          fill: "#1a222e",
        }}
      />
    </g>
  </svg>
);

export default TabIcon;
