import type { Radian, ScenePos } from '@g360/vt-types';

import { toDeg } from '../../Angle';

export default function cameraToPixel(
  point: ScenePos<Radian>,
  scenePos: Required<ScenePos<Radian>>,
  size: { width: number; height: number },
  allowBehindCamera = false
) {
  const { width, height } = size;
  const { pitch, yaw, fov } = scenePos;

  const hotSpotPitch = point.pitch;
  const hotSpotYaw = point.yaw;
  const hotSpotPitchSin = Math.sin(hotSpotPitch);
  const hotSpotPitchCos = Math.cos(hotSpotPitch);
  const currentPitchSin = Math.sin(pitch);
  const currentPitchCos = Math.cos(pitch);
  const yawDiff: Radian = yaw - hotSpotYaw;
  const yawCos = Math.cos(yawDiff);

  let z: number;

  if (allowBehindCamera) {
    // new approach: keep z in front of the camera even if it should be behind
    // not mathematically correct, but it is fine when outside the screen, way better than "null"
    z = Math.max(0.001, hotSpotPitchSin * currentPitchSin + hotSpotPitchCos * yawCos * currentPitchCos);
  } else {
    // standard approach: return null if the point is behind the camera
    z = hotSpotPitchSin * currentPitchSin + hotSpotPitchCos * yawCos * currentPitchCos;
    if (z <= 0) return null;
  }

  const yawSin = Math.sin(yawDiff);
  const hFovTan = Math.tan(toDeg(fov) * (Math.PI / 360));

  const x = ((-width / hFovTan) * yawSin * hotSpotPitchCos) / z / 2 + width / 2;
  const y =
    ((-width / hFovTan) * (hotSpotPitchSin * currentPitchCos - hotSpotPitchCos * yawCos * currentPitchSin)) / z / 2 +
    height / 2;

  return { x, y };
}
