import { OutlineButton, PrimaryButton } from "components/elements";
import { Accordion } from "components/layout";
import {
  AudioSettingWrapper,
  CopyInviteLink,
  VideoSettingWrapper,
} from "components/wrappers";
import { SettingTabType } from "components/wrappers/ParticipantSettingsContainer/ParticipantSettingsContainer";
import {
  useAppStateContext,
  useDeviceStateContext,
  useMixpanelContext,
} from "hooks";
import { FC } from "react";

interface ParticipantSettingWrapperProps {
  settingTab: SettingTabType;
  handleDone: () => void;
  handleCancel: () => void;
  handelTabChange: (tab: SettingTabType) => void;
}

const ParticipantSettingWrapperMobile: FC<ParticipantSettingWrapperProps> = ({
  settingTab,
  handleDone,
  handleCancel,
  handelTabChange,
}) => {
  const { isHost } = useAppStateContext();
  const {
    audioInputDevices,
    videoInputDevices,
    audioOutputDevices,
    selectedVideoInput,
    selectedAudioInput,
    selectedAudioOutput,
    setSelectedAudioInput,
    setSelectedAudioOutput,
    setSelectedVideoInput,
  } = useDeviceStateContext();
  const { Mixpanel, MixpanelEvents } = useMixpanelContext();

  const getMinHeight = () => {
    if (selectedAudioInput && selectedAudioOutput) {
      return 350;
    }

    return 220;
  };

  const renderActionButtons = () => {
    return (
      <div className="flex justify-center py-5 px-6 w-full h-20">
        <div className="w-40">
          <OutlineButton
            text="Cancel"
            handleButtonClick={() => {
              Mixpanel.track(MixpanelEvents.Button.Click("Cancel"));
              handleCancel();
            }}
          />
        </div>
        <div className="ml-5 w-40">
          <PrimaryButton
            text="Done"
            type="submit"
            handleButtonClick={() => {
              Mixpanel.track(MixpanelEvents.Button.Click("Done"));
              handleDone();
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="p-2.5 mt-6">
      {isHost && (
        <div className="mb-2.5">
          <PrimaryButton
            textPosition="left"
            text="Copy Invite Link"
            isInActive={settingTab !== "copy invite link"}
            handleButtonClick={() => {
              if (settingTab !== "copy invite link") {
                handelTabChange("copy invite link");
                Mixpanel.track(MixpanelEvents.Section.Open("Copy Invite Link"));
                return;
              }
              handelTabChange("none");
            }}
          />
          <Accordion isOpened={settingTab === "copy invite link"}>
            <div className="mt-4 md:mt-0">
              <CopyInviteLink
                isSmallScreen
                onCopyInvite={() => {
                  Mixpanel.track(MixpanelEvents.Button.Click("Copy"));
                }}
              />
            </div>
          </Accordion>
        </div>
      )}

      <div className="mb-2.5">
        <PrimaryButton
          textPosition="left"
          text="Audio Settings"
          isInActive={settingTab !== "audio"}
          handleButtonClick={() => {
            if (settingTab !== "audio") {
              handelTabChange("audio");
              Mixpanel.track(MixpanelEvents.Section.Open("Audio Settings"));
              return;
            }
            handelTabChange("none");
          }}
        />
        <Accordion minHeight={getMinHeight()} isOpened={settingTab === "audio"}>
          <AudioSettingWrapper
            setSelectedAudioInput={setSelectedAudioInput}
            setSelectedAudioOutput={setSelectedAudioOutput}
            audioInputDevices={audioInputDevices}
            audioOutputDevices={audioOutputDevices}
            selectedAudioInput={selectedAudioInput}
            selectedAudioOutput={selectedAudioOutput}
          />
          <div>{renderActionButtons()}</div>
        </Accordion>
      </div>

      <div className="mb-2.5">
        <PrimaryButton
          textPosition="left"
          text="Camera Settings"
          isInActive={settingTab !== "video"}
          handleButtonClick={() => {
            if (settingTab !== "video") {
              handelTabChange("video");
              Mixpanel.track(MixpanelEvents.Section.Open("Camera Settings"));
              return;
            }
            handelTabChange("none");
          }}
        />
        <Accordion minHeight={200} isOpened={settingTab === "video"}>
          <VideoSettingWrapper
            setSelectedVideoInput={setSelectedVideoInput}
            videoInputDevices={videoInputDevices}
            selectedVideoInput={selectedVideoInput}
          />
          {renderActionButtons()}
        </Accordion>
      </div>
    </div>
  );
};

export default ParticipantSettingWrapperMobile;
