import { jsx as _jsx } from "react/jsx-runtime";
import useServices from '../../../contexts/useServices';
import UtilsIcon from '../icons/UtilsIcon';
const UtilsMenuButton = ({ onToggle }) => {
    const { layoutService } = useServices();
    return (_jsx("button", { type: "button", className: "btn-theme-primary disabled:hover:bg-opacity-theme-low h-10 w-10 min-w-[2.5rem]\n        p-0 disabled:cursor-not-allowed", onClick: () => {
            layoutService.toggleUtilityMenu();
            if (onToggle)
                onToggle(layoutService.isUtilityMenuOpen);
        }, "data-testid": "utility-menu-btn", children: _jsx(UtilsIcon, { className: "h-8 w-8" }) }));
};
export default UtilsMenuButton;
