/* eslint-disable no-restricted-syntax,no-continue,class-methods-use-this */
import type { AoSettings, FPMesh, FPPoint2D, FPPoint3D, FPTriangle } from '@g360/vt-types';

import { iterateTriangleEdgesByUV } from './iterators';
import { PixelHolder } from './PixelHolder';
import { makeTriangles } from './utils';

interface Pixel3DInfo {
  uv: [number, number];
  position3D: [number, number, number];
  triangleId: number;
  meshId: number;
}

class SpatialGrid {
  private grid: Map<string, Pixel3DInfo[]> = new Map();
  private cellSize: number;

  constructor(cellSize: number) {
    this.cellSize = cellSize;
  }

  getCellKey(x: number, y: number, z: number): string {
    const cellX = Math.floor(x / this.cellSize);
    const cellY = Math.floor(y / this.cellSize);
    const cellZ = Math.floor(z / this.cellSize);
    return `${cellX},${cellY},${cellZ}`;
  }

  addPixel(pixelInfo: Pixel3DInfo) {
    const [x, y, z] = pixelInfo.position3D;
    const key = this.getCellKey(x, y, z);
    if (!this.grid.has(key)) {
      this.grid.set(key, []);
    }
    this.grid.get(key)!.push(pixelInfo);
  }

  findNearestNeighbor(
    position: [number, number, number],
    currentTriangleId: number,
    currentMeshId: number
  ): Pixel3DInfo | undefined {
    const [x, y, z] = position;
    const centerKey = this.getCellKey(x, y, z);
    const [centerX, centerY, centerZ] = centerKey.split(',').map(Number);

    let nearestNeighbor: Pixel3DInfo | undefined;
    let minDistance = Infinity;

    // Check the current cell and its 26 neighboring cells
    for (let dx = -1; dx <= 1; dx += 1) {
      for (let dy = -1; dy <= 1; dy += 1) {
        for (let dz = -1; dz <= 1; dz += 1) {
          const key = `${centerX + dx},${centerY + dy},${centerZ + dz}`;
          const cellPixels = this.grid.get(key) || [];

          for (const pixel of cellPixels) {
            if (pixel.triangleId === currentTriangleId) continue;
            if (pixel.meshId === currentMeshId) continue;

            const distance = this.distance3D(position, pixel.position3D);
            if (distance < minDistance) {
              minDistance = distance;
              nearestNeighbor = pixel;
            }
          }
        }
      }
    }

    return nearestNeighbor;
  }

  private distance3D(a: [number, number, number], b: [number, number, number]): number {
    return /* Math.sqrt */ (a[0] - b[0]) ** 2 + (a[1] - b[1]) ** 2 + (a[2] - b[2]) ** 2;
  }
}

class PixelMapper {
  private pixelMap: Map<number, Pixel3DInfo> = new Map();
  private spatialGrid: SpatialGrid;
  private width: number;
  private height: number;

  constructor(width: number, height: number, cellSize: number) {
    this.width = width;
    this.height = height;
    this.spatialGrid = new SpatialGrid(cellSize);
  }

  getIndex(u: number, v: number): number {
    return Math.floor(v * this.width) * this.width + Math.floor(u * this.width);
  }

  addPixel(u: number, v: number, x: number, y: number, z: number, triangleId: number, meshId: number) {
    const index = this.getIndex(u, v);
    const pixelInfo: Pixel3DInfo = {
      uv: [u, v],
      position3D: [x, y, z],
      triangleId,
      meshId,
    };
    this.pixelMap.set(index, pixelInfo);
    this.spatialGrid.addPixel(pixelInfo);
  }

  getPixelInfo(u: number, v: number): Pixel3DInfo | undefined {
    return this.pixelMap.get(this.getIndex(u, v));
  }

  findNearestNeighbor(u: number, v: number): Pixel3DInfo | undefined {
    const currentPixel = this.getPixelInfo(u, v);
    if (!currentPixel) return undefined;

    return this.spatialGrid.findNearestNeighbor(currentPixel.position3D, currentPixel.triangleId, currentPixel.meshId);
  }

  blurEdgePixels(pixelHolder: PixelHolder) {
    const blurredTexture = new Uint16Array(pixelHolder.pixels.length);
    blurredTexture.set(pixelHolder.pixels);

    for (const [index, pixelInfo] of this.pixelMap) {
      const neighbor = this.findNearestNeighbor(pixelInfo.uv[0], pixelInfo.uv[1]);

      if (neighbor) {
        const neighborIndex = this.getIndex(neighbor.uv[0], neighbor.uv[1]);
        let a = pixelHolder.pixels[index];
        let b = pixelHolder.pixels[neighborIndex];
        if (a > 255 && b > 255) continue; // both are padding, skip
        if (a > 255) a = b;
        if (b > 255) b = a;

        const blurredValue = Math.round((a + b) / 2);
        blurredTexture[index] = Math.max(0, Math.min(255, blurredValue));
        blurredTexture[neighborIndex] = Math.max(0, Math.min(255, blurredValue));
        // console.log('write pixel', { blurredValue, a, b});
      }
    }

    pixelHolder.pixels.set(blurredTexture);
  }
}

export function blurInterMeshEdges(pixelHolder: PixelHolder, meshes: FPMesh[], settings: AoSettings) {
  const { textureSize, onlyMeshes } = settings;
  const cellSize = 1.0; // Adjust based on your 3D space scale
  const mapper = new PixelMapper(pixelHolder.width, pixelHolder.height, cellSize);
  const triangles: FPTriangle[] = makeTriangles(meshes);

  // populate edge pixels of all triangles in the mesh
  // @todo -- could optimize by doing it only for edges that are mesh edges
  const callback = (position: FPPoint3D, uv: FPPoint2D, triangleId: number, meshId: number) => {
    mapper.addPixel(uv[0], uv[1], position[0], position[1], position[2], triangleId, meshId);
  };

  //
  // for (let y = 0; y < textureSize; y += 1) {
  //   for (let x = 0; x < textureSize; x += 1) {
  //     const color = pixelHolder.pixels[y * textureSize + x];
  //     if (color === 125) {
  //       pixelHolder.setPixel(x, y, 124);
  //     }
  //   }
  // }

  const skipMeshes: number[] = [];
  // don't bake ceiling texture (but it's there to affect AO of other meshes)
  for (let i = 0; i < meshes.length; i += 1) {
    if (meshes[i].isCeiling) {
      skipMeshes.push(meshes[i].unqId);
    }
  }
  for (let i = 0; i < triangles.length; i += 1) {
    if (onlyMeshes.length > 0 && !onlyMeshes.includes(triangles[i].meshId)) continue;
    if (skipMeshes.includes(triangles[i].meshId)) continue;
    iterateTriangleEdgesByUV(triangles[i], textureSize, callback, i, triangles[i].meshId);
  }

  mapper.blurEdgePixels(pixelHolder);

  // for (let y = 0; y < textureSize; y += 1) {
  //   for (let x = 0; x < textureSize; x += 1) {
  //     const color = pixelHolder.pixels[y * textureSize + x];
  //     if (color !== 125) {
  //       pixelHolder.setPixel(x, y, 254);
  //     }
  //   }
  // }
}
