import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Listbox, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import useGlobalContext from '../../../contexts/useGlobalContext';
import useServices from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
import BuildingIcon from '../icons/BuildingIcon';
import OutsideIcon from '../icons/OutsideIcon';
import Scrollbars from './Scrollbars';
const ButtonContent = ({ label, singleBuilding, disabledHoverShadow }) => {
    const pathClassName = disabledHoverShadow ? 'group-hover:filter-none' : '';
    const twTextHoverShadow = disabledHoverShadow ? 'group-hover:text-shadow-none' : '';
    if (label === 'outside') {
        return _jsx(OutsideIcon, { className: "h-8 w-8", pathClassName: pathClassName });
    }
    if (singleBuilding) {
        return _jsx(BuildingIcon, { className: "h-8 w-8 filter-none", pathClassName: pathClassName });
    }
    return (_jsxs("div", { className: `text-shadow-dark flex items-end justify-center ${twTextHoverShadow}`, children: [_jsx("span", { className: "ml-1 min-w-[0.75rem] pb-[1px] text-center", children: label }), _jsx(BuildingIcon, { className: "h-8 w-8", pathClassName: pathClassName })] }));
};
const BuildingSwitch = () => {
    const { gatedTourService, navigationService, layoutService } = useServices();
    const { changeBuilding, panoMatchBuilding, structure } = navigationService;
    const { activeScene, buildingKey, transitionInProgress } = useReactive(navigationService, [
        'activeScene',
        'buildingKey',
        'transitionInProgress',
    ]);
    const { isRemoteControlled } = useReactive(layoutService, ['isRemoteControlled']);
    const singleBuilding = structure.buildingsOrder.length === 1;
    const { analytics } = useGlobalContext();
    return (_jsxs(Listbox, { as: "div", disabled: isRemoteControlled || transitionInProgress, className: "relative", value: buildingKey, onChange: (nextBuildingKey) => {
            if (activeScene) {
                if (nextBuildingKey === 'outside') {
                    analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'BMENU', 'Outdoors', {
                        scene_id: activeScene.sceneKey,
                    });
                }
                else {
                    analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'BMENU', 'Building', {
                        scene_id: activeScene.sceneKey,
                        building: nextBuildingKey,
                    });
                }
            }
            if (!gatedTourService.checkVerification())
                return;
            if (!panoMatchBuilding(nextBuildingKey)) {
                // panoMatchBuildingChange couldn't find a pano in the next building
                // then no pano switching will occur and we must explicitly switch UI to that building,
                changeBuilding(nextBuildingKey);
            }
        }, children: [_jsx(Listbox.Button, { as: "button", className: "btn-theme-primary disabled:hover:bg-opacity-theme-low group pointer-events-auto h-10 w-[4.5rem] min-w-0 truncate\n          disabled:cursor-not-allowed", "data-testid": "building-switch-btn", children: _jsx(ButtonContent, { label: buildingKey, singleBuilding: singleBuilding }) }), _jsx("div", { className: "absolute bottom-full h-[20rem] w-full overflow-hidden", children: _jsx(Transition, { as: Fragment, children: _jsx(Listbox.Options, { as: "div", className: "absolute bottom-0 w-full pb-2 outline-none", children: _jsxs(Scrollbars, { className: "max-h-[15.25rem]", children: [structure.buildingsOrder.map((buildingId) => (_jsx(Listbox.Option, { as: Fragment, value: buildingId, children: _jsx("button", { type: "button", className: "btn-theme-secondary group h-10 min-h-[2.5rem] w-full", "data-testid": "building-btn", children: _jsx(ButtonContent, { label: buildingId, singleBuilding: singleBuilding, disabledHoverShadow: true }) }) }, `building-${buildingId}`))), structure.outsideScenes && (_jsx(Listbox.Option, { as: Fragment, value: "outside", children: _jsx("button", { type: "button", className: "btn-theme-secondary group h-10 min-h-[2.5rem] w-full", "data-testid": "outside-btn", children: _jsx(ButtonContent, { label: "outside", disabledHoverShadow: true }) }) }, "outside"))] }) }) }) })] }));
};
export default BuildingSwitch;
