import { getBlockMarkup } from './block';
import { getListMarkup, isList } from './list';
/**
 * Functions copied from original library with minor refactoring.
 * https://github.com/jpuri/draftjs-to-html/commit/c02452e3ce6719f84f38d39f8f9b0a9e32bf871f
 */
/**
 * Coverts DraftJS content state to HTML string.
 * @param editorContent - `RawDraftContentState`: DraftJS content state.
 * @param hashtagConfig  - `HashtagConfig` - `Optional`: Hashtag configuration.
 * @param directional - `boolean` - `Optional`: Directional text.
 * @param customEntityTransform - `(...args: unknown[]) => unknown`: Custom entity transform.
 * @returns `string`: HTML string.
 */
export default function draftJsToHtml(editorContent, hashtagConfig, isDirectional, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
customEntityTransform) {
    const html = [];
    if (!editorContent)
        return '';
    const { blocks } = editorContent;
    const entityMap = editorContent.entityMap;
    if (!blocks || blocks.length === 0)
        return '';
    let listBlocks = [];
    blocks.forEach((block) => {
        if (isList(block.type)) {
            listBlocks.push(block);
            return;
        }
        if (listBlocks.length > 0) {
            const listHtml = getListMarkup(listBlocks, entityMap, hashtagConfig, undefined, customEntityTransform);
            html.push(listHtml);
            listBlocks = [];
        }
        const blockHtml = getBlockMarkup(block, entityMap, hashtagConfig, isDirectional, customEntityTransform);
        html.push(blockHtml);
    });
    if (listBlocks.length > 0) {
        const listHtml = getListMarkup(listBlocks, entityMap, hashtagConfig, isDirectional, customEntityTransform);
        html.push(listHtml);
        listBlocks = [];
    }
    return html.join('');
}
