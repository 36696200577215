import {
  destroyProgram,
  disableVertexAttributes,
  enableVertexAttributes,
  initShaders,
  loadShaders,
} from '../../../common/webglUtils';
import type MeasureToolProgram from '../MeasureToolProgram';
import fragmentShaderSource from './measureDebug.fs.glsl';
import vertexShaderSource from './measureDebug.vs.glsl';

class MeasureDebugProgram {
  // Debug params
  // Listed as static to persist between measure mode toggles
  static overlayOpacity = 0;
  static overlayMode = 2;
  static overlayHoverRadius = 0;

  private program: WebGLProgram | null = null;

  private gl: WebGLRenderingContext;
  private canvas: HTMLCanvasElement;
  private mainProgram: MeasureToolProgram;

  private vertexBuffer: WebGLBuffer | null;

  private vertexAttribute = 0;

  private depthMapUniform: WebGLUniformLocation | null = null;
  private normalMapUniform: WebGLUniformLocation | null = null;

  private aspectRatioUniformLocation: WebGLUniformLocation | null = null;
  private yawUniformLocation: WebGLUniformLocation | null = null;
  private pitchUniformLocation: WebGLUniformLocation | null = null;
  private fovUniformLocation: WebGLUniformLocation | null = null;
  private alphaUniformLocation: WebGLUniformLocation | null = null;

  private cartesianCursorUniform: WebGLUniformLocation | null = null;

  private sphereRadiusUniform: WebGLUniformLocation | null = null;
  private sphereHasRadiusUniform: WebGLUniformLocation | null = null;
  private normalsFullSelectedUniform: WebGLUniformLocation | null = null;
  private normalsAbsSelectedUniform: WebGLUniformLocation | null = null;

  private vertexAttributes: number[] = []; // list vertex attributes to be enabled before and disabled after a draw in order to not mess up state of other programs

  private vertices = new Float32Array([-1, 1, 1, 1, 1, -1, -1, 1, 1, -1, -1, -1]);

  constructor(webGLContext: WebGLRenderingContext, canvas: HTMLCanvasElement, mainProgram: MeasureToolProgram) {
    this.gl = webGLContext;
    this.canvas = canvas;
    this.mainProgram = mainProgram;

    this.vertexBuffer = this.gl.createBuffer();
  }

  init(): void {
    this.program = initShaders(this.gl, vertexShaderSource, fragmentShaderSource);

    if (this.program) {
      this.vertexAttribute = this.gl.getAttribLocation(this.program, 'a_texCoord');

      this.depthMapUniform = this.gl.getUniformLocation(this.program, 'u_tex_depth_map');
      this.normalMapUniform = this.gl.getUniformLocation(this.program, 'u_tex_normal_map');

      this.aspectRatioUniformLocation = this.gl.getUniformLocation(this.program, 'u_aspectRatio');
      this.yawUniformLocation = this.gl.getUniformLocation(this.program, 'u_yaw');
      this.pitchUniformLocation = this.gl.getUniformLocation(this.program, 'u_pitch');
      this.fovUniformLocation = this.gl.getUniformLocation(this.program, 'u_fov');
      this.alphaUniformLocation = this.gl.getUniformLocation(this.program, 'u_alpha');

      this.cartesianCursorUniform = this.gl.getUniformLocation(this.program, 'u_cart_cursor');

      this.sphereRadiusUniform = this.gl.getUniformLocation(this.program, 'u_hover_sphere_radius');
      this.sphereHasRadiusUniform = this.gl.getUniformLocation(this.program, 'u_bool_sphere_has_radius');
      this.normalsFullSelectedUniform = this.gl.getUniformLocation(this.program, 'u_bool_normals_full_selected');
      this.normalsAbsSelectedUniform = this.gl.getUniformLocation(this.program, 'u_bool_normals_abs_selected');

      this.vertexAttributes = [this.vertexAttribute];
    }
  }

  destroy(): void {
    destroyProgram(this.gl, this.program);
  }

  draw(): void {
    if (MeasureDebugProgram.overlayOpacity === 0) return;

    loadShaders(this.gl, this.program);
    enableVertexAttributes(this.gl, this.vertexAttributes);

    this.gl.enable(this.gl.CULL_FACE);
    this.gl.cullFace(this.gl.FRONT);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.vertices, this.gl.DYNAMIC_DRAW);
    this.gl.vertexAttribPointer(this.vertexAttribute, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.activeTexture(this.gl.TEXTURE1);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.mainProgram.depthMapTextureObject);
    this.gl.uniform1i(this.depthMapUniform, 1);

    this.gl.activeTexture(this.gl.TEXTURE2);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.mainProgram.normalMapTextureObject);
    this.gl.uniform1i(this.normalMapUniform, 2);

    this.gl.uniform1f(this.aspectRatioUniformLocation, this.gl.drawingBufferWidth / this.gl.drawingBufferHeight);
    this.gl.uniform1f(this.yawUniformLocation, this.mainProgram.yaw + this.mainProgram.yawOffset);
    this.gl.uniform1f(this.pitchUniformLocation, this.mainProgram.pitch);
    this.gl.uniform1f(this.fovUniformLocation, this.mainProgram.fov);

    this.gl.uniform1f(this.alphaUniformLocation, MeasureDebugProgram.overlayOpacity);

    const cursorCoords = this.mainProgram.cursorCoords?.snapPosition ?? this.mainProgram.cursorCoords;

    const cartCursor = cursorCoords ? [cursorCoords.cartX, cursorCoords.cartY, cursorCoords.cartZ] : [0, 0, 0];

    this.gl.uniform3fv(this.cartesianCursorUniform, [cartCursor[0], cartCursor[2], -cartCursor[1]]);
    this.gl.uniform1f(this.sphereRadiusUniform, MeasureDebugProgram.overlayHoverRadius);
    this.gl.uniform1f(this.sphereHasRadiusUniform, MeasureDebugProgram.overlayHoverRadius > 0 ? 1 : 0);
    this.gl.uniform1f(this.normalsAbsSelectedUniform, MeasureDebugProgram.overlayMode === 1 ? 1 : 0);
    this.gl.uniform1f(this.normalsFullSelectedUniform, MeasureDebugProgram.overlayMode === 2 ? 1 : 0);

    this.gl.drawArrays(this.gl.TRIANGLES, 0, 6);

    this.gl.disable(this.gl.CULL_FACE);
    disableVertexAttributes(this.gl, this.vertexAttributes);
  }
}

export default MeasureDebugProgram;
