import type { Pixel, Radian, Size } from '@g360/vt-types';
import { identityM3, m3toM4, perspectiveM4, rotateM3X, rotateM3Y, rotatePerspective } from '@g360/vt-utils';

/**
 * Get perspective matrix. If rotation angles are provided, the matrix will be rotated with `rotateX` -\> `rotateY` transformation.
 * @param fov - `Radian`: Field of view.
 * @param size - `Size<Pixel>`: The size of the drawing buffer.
 * @param zNear - `number`: The distance to the near clipping plane.
 * @param zFar - `number`: The distance to the far clipping plane.
 * @param rotationX - `Radian` - `Optional`: An angles for `rotateX` transformation.
 * @param rotationY - `Radian` - `Optional`: An angles for `rotateY` transformation.
 * @returns `number[]`: The created geometry perspective matrix. Rotation applied if `rotationX` or `rotationY` were provided.
 */
function getPerspectiveMatrix(
  fov: Radian,
  size: Size<Pixel>,
  zNear: number,
  zFar: number,
  rotationX?: Radian,
  rotationY?: Radian
): number[] {
  let geometryPerspectiveMatrix = perspectiveM4(fov, size, zNear, zFar);

  if (rotationX !== undefined || rotationY !== undefined) {
    let rotationMatrix = identityM3();

    if (rotationX !== undefined) rotationMatrix = rotateM3X(rotationMatrix, rotationX);
    if (rotationY !== undefined) rotationMatrix = rotateM3Y(rotationMatrix, rotationY);

    rotationMatrix = m3toM4(rotationMatrix);
    geometryPerspectiveMatrix = rotatePerspective(geometryPerspectiveMatrix, rotationMatrix);
  }

  return geometryPerspectiveMatrix;
}

export default getPerspectiveMatrix;
