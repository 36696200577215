import React, { ReactNode } from "react";

type Props = {
  fallback: ReactNode | ((error?: Error) => ReactNode);
  children: ReactNode;
};

export default class ErrorBoundary extends React.Component<
  Props,
  { hasError?: boolean; error?: Error }
> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  render() {
    const { hasError, error } = this.state;
    const { fallback, children } = this.props;
    if (hasError) {
      return typeof fallback === "function" ? fallback(error) : fallback;
    }

    return children;
  }
}
