/* eslint-disable no-useless-constructor */

import type { DataJsonType, Degree } from '@g360/vt-types';

import { visuallyFilterHotSpots } from './filter';
import { visuallyFixHotSpots } from './fix';
import type { HotSpot, HotSpotGraphV2, HotSpotType, LinkedHotSpots, Position } from './types';

export class HotSpotNode {
  constructor(public name: string, public position: Position) {}
}

export class HotSpotConnection {
  constructor(
    public fromNode: HotSpotNode,
    public toNode: HotSpotNode,
    public type: HotSpotType,
    public hotSpot: HotSpot,
    public debugData?: string
  ) {}
}

// directed HotSpot graph
export class HotSpotGraph {
  nodes: Map<string, HotSpotNode>;
  connections: HotSpotConnection[];

  constructor() {
    this.nodes = new Map<string, HotSpotNode>();
    this.connections = [];
  }

  addNode(name: string, position: Position): void {
    if (this.nodes.has(name)) {
      throw new Error(`Node with name "${name}" already exists.`);
    }
    const node = new HotSpotNode(name, position);
    this.nodes.set(name, node);
  }

  addConnection(
    fromNodeName: string,
    toNodeName: string,
    connectionType: HotSpotType,
    hotSpot: HotSpot,
    debugData?: string
  ): void {
    const fromNode = this.nodes.get(fromNodeName);
    const toNode = this.nodes.get(toNodeName);

    if (!fromNode || !toNode) {
      return;
    }

    const existingConnection = this.connections.find(
      (conn) => conn.fromNode.name === fromNodeName && conn.toNode.name === toNodeName
    );
    if (existingConnection) {
      return;
    }

    const connection = new HotSpotConnection(fromNode, toNode, connectionType, hotSpot, debugData);

    this.connections.push(connection);
  }

  getConnections(fromNodeName: string): HotSpotConnection[] {
    return this.connections.filter((conn) => conn.fromNode.name === fromNodeName);
  }

  getConnection(fromNodeName: string, toNodeName: string): HotSpotConnection | undefined {
    return this.connections.find((conn) => conn.fromNode.name === fromNodeName && conn.toNode.name === toNodeName);
  }

  /**
   * Strongly Connected Component = subgraph, where all nodes are reachable from each other
   */
  getStronglyConnectedComponents(): HotSpotNode[][] {
    let index = 0;
    const stack: HotSpotNode[] = [];
    const onStack: Map<string, boolean> = new Map<string, boolean>();
    const indices: Map<string, number> = new Map<string, number>();
    const lowLinks: Map<string, number> = new Map<string, number>();
    const sccs: HotSpotNode[][] = [];

    const strongConnect = (node: HotSpotNode): void => {
      indices.set(node.name, index);
      lowLinks.set(node.name, index);
      index += 1;
      stack.push(node);
      onStack.set(node.name, true);

      // Consider successors of node
      this.getConnections(node.name).forEach((conn) => {
        const toNode = conn.toNode;
        if (!indices.has(toNode.name)) {
          // Successor has not yet been visited; recurse on it
          strongConnect(toNode);
          lowLinks.set(node.name, Math.min(lowLinks.get(node.name)!, lowLinks.get(toNode.name)!));
        } else if (onStack.get(toNode.name)) {
          // Successor is in the stack and hence in the current SCC
          lowLinks.set(node.name, Math.min(lowLinks.get(node.name)!, indices.get(toNode.name)!));
        }
      });

      // If node is a root node, pop the stack and generate an SCC
      if (lowLinks.get(node.name) === indices.get(node.name)) {
        const scc: HotSpotNode[] = [];
        let w: HotSpotNode;
        do {
          w = stack.pop()!;
          onStack.set(w.name, false);
          scc.push(w);
        } while (w !== node);
        sccs.push(scc);
      }
    };

    this.nodes.forEach((node, name) => {
      if (!indices.has(name)) {
        strongConnect(node);
      }
    });

    return sccs;
  }

  exportLinkedHotSpots(
    dataJson?: DataJsonType,
    filterHotSpotsByDistance = 0.01,
    filterHotSpotsByAngle: Degree = 5
  ): LinkedHotSpots {
    const scenes: Record<string, { hotSpots: HotSpot[] }> = {};

    // Initialize scenes with empty hotSpots arrays
    this.nodes.forEach((node) => {
      scenes[node.name] = { hotSpots: [] };
    });

    // Populate hotSpots arrays with hotspots from connections
    this.connections.forEach((connection) => {
      const hotSpotData: HotSpot = {
        hidden: connection.hotSpot.hidden,
        obstructed: connection.hotSpot.obstructed,
        pos: connection.hotSpot.pos,
        target: connection.toNode.name,
        type: connection.hotSpot.type,
      };
      scenes[connection.fromNode.name].hotSpots.push(hotSpotData);
    });

    // Sort the hotSpots arrays by target name within each scene
    Object.keys(scenes).forEach((key) => {
      scenes[key].hotSpots.sort((a, b) => a.target.localeCompare(b.target));
    });

    const filteredScenes = visuallyFilterHotSpots(
      this,
      scenes,
      filterHotSpotsByDistance,
      filterHotSpotsByAngle,
      dataJson
    );
    const fixedScenes = visuallyFixHotSpots(this, filteredScenes);

    return { scenes: fixedScenes };
  }

  /**
   * changes graph
   */
  leaveOnlyWhitelistedNodesAndNeighbors(whitelist: string[]): void {
    // Step 1: Initialize the set with nodes that are directly in the whitelist
    const relevantNodesSet = new Set<HotSpotNode>();
    whitelist.forEach((name) => {
      if (this.nodes.has(name)) {
        relevantNodesSet.add(this.nodes.get(name)!);
      }
    });

    // Step 2: Add nodes that are connected to the nodes in the whitelist
    this.connections.forEach((conn) => {
      if (relevantNodesSet.has(conn.fromNode) || relevantNodesSet.has(conn.toNode)) {
        relevantNodesSet.add(conn.fromNode);
        relevantNodesSet.add(conn.toNode);
      }
    });

    // Step 3: Update the nodes map
    this.nodes = new Map<string, HotSpotNode>();
    relevantNodesSet.forEach((node) => {
      this.nodes.set(node.name, node);
    });

    // Step 4: Filter the connections
    this.connections = this.connections.filter(
      (conn) => relevantNodesSet.has(conn.fromNode) && relevantNodesSet.has(conn.toNode)
    );
  }

  removeBlacklistedNodes(blacklist: string[]): void {
    const nodesToRemove = new Set<HotSpotNode>();
    blacklist.forEach((name) => {
      if (this.nodes.has(name)) {
        nodesToRemove.add(this.nodes.get(name)!);
      }
    });
    this.connections = this.connections.filter(
      (conn) => !nodesToRemove.has(conn.fromNode) && !nodesToRemove.has(conn.toNode)
    );
    this.nodes = new Map<string, HotSpotNode>();
    this.connections.forEach((conn) => {
      this.nodes.set(conn.fromNode.name, conn.fromNode);
      this.nodes.set(conn.toNode.name, conn.toNode);
    });
  }

  findNearestNodeNotInBlacklistInGivenBuilding(
    fromNodeName: string,
    blacklist: string[],
    buildingId: number,
    hotSpotGeneratorJson: HotSpotGraphV2
  ): HotSpotNode | undefined {
    const fromNode = this.nodes.get(fromNodeName);
    if (!fromNode) return undefined;

    const visited = new Set<string>();
    const queue: HotSpotNode[] = [fromNode];

    while (queue.length > 0) {
      const currentNode = queue.shift()!;
      const scene = hotSpotGeneratorJson.scenes[currentNode.name];
      if (!scene.is_outside && scene.building === buildingId && !blacklist.includes(currentNode.name)) {
        return currentNode;
      }

      visited.add(currentNode.name);

      this.getConnections(currentNode.name).forEach((connection) => {
        const neighborNode = connection.toNode;
        if (!visited.has(neighborNode.name)) {
          queue.push(neighborNode);
        }
      });
    }

    return undefined;
  }
}
