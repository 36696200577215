import { Input, OutlineButton, PrimaryButton } from "components/elements";
import { loginAsHost } from "api";
import { useAppStateContext } from "hooks";
import { Modal } from "components/layout";
import useFocus from "hooks/useFocus";
import { FC, useEffect, useState } from "react";
import config from "config";

type LoginStateType = {
  loading: boolean;
  success: boolean;
  error: string | null;
};

const HostWrapper: FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [htmlElRef, setFocus] = useFocus<HTMLInputElement>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const [loginState, setLoginState] = useState<LoginStateType>({
    success: false,
    loading: false,
    error: null,
  });

  const { onLoginAsHost } = useAppStateContext();

  useEffect(() => {
    if (isModalVisible && loginState.success) {
      setIsModalVisible(false);
    }
  }, [loginState.success, isModalVisible]);

  useEffect(() => {
    if (isModalVisible) {
      setFocus();
    }
  }, [isModalVisible, setFocus]);

  const handleLoginAsHost = async (username: string, password: string) => {
    setLoginState({ error: null, loading: true, success: false });

    const loginData = await loginAsHost({
      username,
      password,
    });

    if (!loginData.authorized) {
      setLoginState({
        error: "Wrong email and/or password.",
        loading: false,
        success: false,
      });
    }

    if (loginData.authorized) {
      onLoginAsHost(loginData.authorized);
      setLoginState({ loading: false, success: true, error: null });
      return;
    }

    if (loginData.non_field_errors) {
      setLoginState({
        success: false,
        loading: false,
        error: "You have entered an invalid email.",
      });
      return;
    }

    setLoginState({
      success: false,
      loading: false,
      error: "Something went wrong.",
    });
  };

  return (
    <>
      <div className="flex flex-wrap items-start justify-start w-full">
        <span className="mr-1 font-primary text-xs text-giraffe-dark whitespace-nowrap">
          If you are the host, please
        </span>
        <button
          className="inline-block mr-1 text-xs font-bold border-b-2 font-primary text-giraffe-dark"
          onClick={() => setIsModalVisible((state) => !state)}
        >
          log in
        </button>
        <span className="font-primary text-giraffe-dark text-xs whitespace-nowrap">
          to start this session
        </span>
      </div>
      <Modal
        modalWidth="large"
        modalTitle="Log in as Giraffe360 user"
        isModalVisible={isModalVisible}
        handleCancelClick={() => setIsModalVisible(false)}
      >
        <form
          className="mb-4 min-h-[10rem] px-6 mt-9 lg:max-w-[510px]"
          onSubmit={(e) => {
            e.preventDefault();
            handleLoginAsHost(email, password);
          }}
        >
          <div className="mb-5">
            <Input
              ref={htmlElRef}
              inputKind="email"
              inputHeight="small"
              inputValue={email}
              inputPlaceholder="Email"
              handleInputChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <Input
            inputKind="password"
            inputHeight="small"
            inputValue={password}
            inputPlaceholder="Password"
            handleInputChange={(e) => setPassword(e.target.value)}
          />
          <div className="mt-[24px] mb-[37px] relative">
            <a
              href={`${config.dashboardUrl}/reset-password/`}
              target="_blank"
              rel="noreferrer"
              className="inline text-xs font-primary text-giraffe-gray-lightest underline"
            >
              Forgot password
            </a>
            <div className="absolute left-0 flex items-center justify-start w-full my-1 top-5 h-7">
              {loginState.error && (
                <p className="text-xs text-giraffe-red font-primary">
                  {loginState.error}
                </p>
              )}
            </div>
          </div>

          <div className="flex items-center justify-center w-full mt-12 sm:justify-end">
            <div className="w-36">
              <OutlineButton
                text="Cancel"
                handleButtonClick={() => setIsModalVisible(false)}
              />
            </div>
            <div className="ml-5 w-36">
              <PrimaryButton
                text="Log in"
                type="submit"
                isLoading={loginState.loading}
                isDisabled={email.length === 0 || password.length === 0}
                handleButtonClick={() => handleLoginAsHost(email, password)}
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default HostWrapper;
