import { MediaDeviceButton } from "components/elements";
import { Dropdown } from "components/layout";
import { FC, useState } from "react";
import getDeviceName from "utils/getDeviceName";
import { useMixpanelContext } from "hooks";

interface VideoSettingWrapperProps {
  videoInputDevices: MediaDeviceInfo[];
  selectedVideoInput: MediaDeviceInfo | null | undefined;
  setSelectedVideoInput: (input: MediaDeviceInfo) => void;
}

const VideoSettingWrapper: FC<VideoSettingWrapperProps> = ({
  videoInputDevices,
  selectedVideoInput,
  setSelectedVideoInput,
}) => {
  const { Mixpanel, MixpanelEvents } = useMixpanelContext();
  const [isCameraDropdown, setIsCameraDropdown] = useState<boolean>(false);

  const displayVideoInputName = () => {
    if (selectedVideoInput) {
      return getDeviceName(selectedVideoInput).name;
    }

    return "Selected Camera";
  };

  return (
    <>
      {videoInputDevices.length >= 1 && (
        <div className="flex flex-col justify-between w-full h-10 mb-14 md:items-center md:flex-row">
          <div className="flex items-center justify-start md:justify-center mb-2.5 md:mb-0 pl-5 mt-5 md:mt-0">
            <p className="text-sm font-primary leading-5 tracking-[1%]">
              Camera
            </p>
          </div>
          <Dropdown
            bgColor="white"
            buttonSize="full"
            dropDownWidth="full"
            isDropdownArrow={true}
            customNames="w-full md:w-80"
            isDropdownOpen={isCameraDropdown}
            handleDropdownClose={() => {
              setIsCameraDropdown(false);
            }}
            handleDropdownButtonClick={() => {
              Mixpanel.track(MixpanelEvents.Dropdown.Open("Camera"));
              setIsCameraDropdown((state) => !state);
            }}
            bntTitle={displayVideoInputName()}
          >
            <div className="w-full py-2">
              {videoInputDevices.map((videoInput) => {
                return (
                  <MediaDeviceButton
                    key={videoInput.deviceId}
                    title={getDeviceName(videoInput).name}
                    handleClick={() => {
                      Mixpanel.track(
                        MixpanelEvents.Dropdown.ItemClick(
                          getDeviceName(videoInput).name || "Unknown"
                        )
                      );
                      setIsCameraDropdown(false);
                      setSelectedVideoInput(videoInput);
                    }}
                  />
                );
              })}
            </div>
          </Dropdown>
        </div>
      )}
    </>
  );
};

export default VideoSettingWrapper;
