/**
 * @description - This function parses the search string of a URL and returns an object with the key value pairs
 *
 * @param searchString - The search string to parse
 * @returns {key: value} pairs of the search string as an object
 *
 * @example
 * const url = 'https://www.example.com/?name=John&age=30';
 * const search = url.substring(url.indexOf('?'));
 * const data = getUrlVariables(search);
 * console.log(data); // { name: 'John', age: '30' }
 *
 */
const getUrlVariables = (searchString: string) => {
  const data = {};
  const query = searchString.substring(1);
  const vars = query.split('&');

  if (typeof window === 'undefined') return data;

  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');
    data[pair[0]] = decodeURIComponent(pair[1]);
  }
  return data;
};
export default getUrlVariables;
