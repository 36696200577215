import { Participant } from "components/elements";
import { FC } from "react";
import { LocalParticipant, RemoteParticipant } from "twilio-video";

interface LocalParticipantWrapperProps {
  isHost: boolean;
  localParticipant: LocalParticipant | null;
  remoteParticipants: RemoteParticipant[];
  roomHostIdentity: string;
  inControlParticipant: string;
  isControlButtonMargin: boolean;
  handleClickControlSwitch: (id: string) => void;
}

const LocalParticipantWrapper: FC<LocalParticipantWrapperProps> = ({
  localParticipant,
  isHost,
  remoteParticipants,
  roomHostIdentity,
  inControlParticipant,
  isControlButtonMargin,
  handleClickControlSwitch,
}) => {
  const renderParticipant = () => {
    if (localParticipant) {
      return (
        <Participant
          isHost={isHost}
          participant={localParticipant}
          roomHostIdentity={roomHostIdentity}
          isInControl={inControlParticipant === localParticipant.identity}
          isControlButtonMargin={isControlButtonMargin}
          isControlBtnDisplayed={remoteParticipants.length >= 1}
          handleClickControlSwitch={handleClickControlSwitch}
        />
      );
    }

    return null;
  };

  return <div className="relative">{renderParticipant()}</div>;
};

export default LocalParticipantWrapper;
