import type { EasingTypes } from '@g360/vt-types';

import SimpleAnimator from '../common/SimpleAnimator';
import type { AnimationTypes } from '../types/internal';

/**
 * Mixin that is part of Engine. Watches over Engine animations.
 */
abstract class Animator {
  public averageFrameTime = 1 / 60; // Used to determine refresh rate of the monitor. Since there is no way JS will just tell us the details of the monitor
  public averageRefreshRate = 60;
  public speedScale = 1; // if animation speed is defined in seconds assuming 60FPS, then it needs to multiplied by this

  private instances: Map<string, SimpleAnimator> = new Map(); // only 1 animation of per type active at a time
  private lastTickTime = 0;

  public startAnimation(
    type: AnimationTypes,
    tickCallback: (progress: number) => void,
    finishedCallback: () => void,
    easing: EasingTypes = 'linear',
    duration = 1000, // milliseconds
    fixedTimeStep?: number // undefined = use real time, otherwise: e.g. 1/60 for 60fps   use fixed time step only for video generation not for normal, browser use
  ): void {
    this.stopAnimation(type);
    this.instances.set(type, new SimpleAnimator(duration, easing, tickCallback, finishedCallback, fixedTimeStep));
  }

  public tickAnimations = () => {
    this.tickAll();
    this.checkFrameTimes();
  };

  protected stopAnimation(type: AnimationTypes): void {
    this.instances.get(type)?.stop();
  }

  private tickAll() {
    const keysIterator = this.instances.keys();
    let key = keysIterator.next();

    while (!key.done) {
      const animator = this.instances.get(key.value);
      let remove = true;
      if (animator) {
        animator.tick();
        remove = animator.finished;
      }
      if (remove) {
        this.instances.delete(key.value);
      }
      key = keysIterator.next();
    }
  }

  private checkFrameTimes() {
    const delta = (performance.now() - this.lastTickTime) / 1000;
    if (delta < 1 / 30) {
      // ignore large gaps in time
      const weight = 30; // bigger this value, less impact each frame time will have on avg value
      this.averageFrameTime += delta * (1 / weight);
      this.averageFrameTime /= (weight + 1) / weight;
      this.averageRefreshRate = 1 / this.averageFrameTime;
      this.speedScale = this.averageRefreshRate / 60;
    }
    this.lastTickTime = performance.now();
  }
}

export default Animator;
