import { getViewingInfo, getRoomAccessData, endMeeting, me, stats } from "api";
import { createContext, FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { nameToParticipantIdentity, keysToCamelCase } from "utils";
import { useMixpanelContext } from "hooks";
import {
  mapToSignData,
  signTourAndGetKeys,
  VirtualTourSignData,
  ErrorLogger,
} from "@g360/vt-utils";
import type { AssetConfig } from "@g360/vt-types";

type AppStateContextType = {
  isHost: boolean;
  roomName: string | undefined;
  token: string | null;
  jsonPath: string | null;
  assetConfig: AssetConfig | null;
  error: string | null;
  userName: string;
  userTwilioIdentity: string;
  handleJoinRoom: (identity: string) => Promise<void>;
  handleEndRoom: (deleteLink: boolean) => Promise<string>;
  onLoginAsHost: (authorized: boolean) => Promise<void>;
  debug: boolean;
};

interface AppStateContextProps {
  children: JSX.Element;
}

const AppStateContext = createContext<AppStateContextType>({
  isHost: false,
  roomName: undefined,
  token: null,
  jsonPath: null,
  assetConfig: null,
  error: null,
  userName: "",
  userTwilioIdentity: "",
  handleJoinRoom: () => new Promise<void>((resolve) => resolve()),
  handleEndRoom: () => new Promise<string>((resolve) => resolve("")),
  onLoginAsHost: () => new Promise<void>((resolve) => resolve()),
  debug: false,
});

const AppStateContextProvider: FC<AppStateContextProps> = ({ children }) => {
  const { id: roomName } = useParams();
  const [isHost, setIsHost] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [assetConfig, setAssetConfig] = useState<AssetConfig | null>(null);
  const [jsonPath, setJsonPath] = useState<string | null>(null);
  const [userName, setUserName] = useState<string>("");
  const [userTwilioIdentity, setUserTwilioIdentity] = useState<string>("");
  const [debug] = useState(false);

  const { Mixpanel } = useMixpanelContext();

  const signTour = async (vtSignData: VirtualTourSignData) => {
    const { mode, signBody } = mapToSignData(vtSignData);
    const signUrl = process.env.REACT_APP_SIGNING_ENDPOINT;
    if (!signUrl) throw new Error("sign url is not valid");
    if (mode === "public") {
      return {};
    }
    return await signTourAndGetKeys(signUrl, mode, signBody);
  };

  const handleJoinRoom = useCallback(
    async (name: string) => {
      // roomName is url param
      if (!roomName) return;
      const twilioIdentity = nameToParticipantIdentity(name);

      const data = await getRoomAccessData({
        roomId: roomName,
        participantIdentity: twilioIdentity,
      });

      if (!data) {
        setError("Empty response");
        return;
      }

      if (data.errorMessage) {
        // Error message from backend used in LobbyContainer
        setError(data.errorMessage);
        return;
      }

      try {
        const signatureData = await signTour(
          keysToCamelCase(data) as VirtualTourSignData
        );
        setAssetConfig({
          basePath: data.base_href,
          assetPath: process.env.REACT_APP_IC_STATIC_ASSET_URL ?? "/",
          signatureData,
        });
      } catch (err: any) {
        ErrorLogger.captureMessage(err?.message || "Error in signing");
        setError(`Error in singing tour: (${err?.message ?? "Unknown error"})`);
        return;
      }

      setError(null);
      setToken(data.token);
      setIsHost(data.is_host);
      setJsonPath(data.json_path);
      setUserName(name);
      setUserTwilioIdentity(twilioIdentity);
    },
    [roomName]
  );

  const handleEndRoom = useCallback(
    async (deleteLink: boolean) => {
      let redirectLink = `/${roomName}?name=${userName}&rejoin=true`;

      if (deleteLink && roomName) {
        const endMeetingData = await endMeeting(roomName);
        if (endMeetingData.success) {
          redirectLink = "/leave";
        }
      }

      return redirectLink;
    },
    [roomName, userName]
  );

  const handleMixpanelDataInit = async () => {
    try {
      const user = await me();
      const userStats = await stats();
      if (user && userStats) {
        Mixpanel.opt_in_tracking();
        Mixpanel.identify(user.id);
        Mixpanel.register({
          ...userStats,
          id: user.id,
          namespace: user.company.namespace,
          role: user.role,
          user_created: user.created,
          company_created: user.company.created,
          is_hijacked: user.is_hijacked,
        });
      }
    } catch (err) {
      console.error(err);
      ErrorLogger.captureException(err);
    }
  };

  const handleMixpanelPageClose = () => {
    Mixpanel.track("Page closed");
  };

  const onLoginAsHost = async (authorized: boolean) => {
    if (authorized && roomName) {
      const viewingData = await getViewingInfo(roomName);
      setIsHost(viewingData.isHost);
      if (viewingData.error) {
        setError(viewingData.error);
      }

      if (viewingData.isHost) {
        handleMixpanelDataInit();
      }
    }
  };

  useEffect(() => {
    if (!roomName) return;

    const checkIfIsHostOrError = async () => {
      const data = await getViewingInfo(roomName);
      setIsHost(data.isHost);

      if (data.error) {
        setError(data.error);
      }

      if (data.isHost) {
        handleMixpanelDataInit();
      }
    };
    checkIfIsHostOrError();

    // Add a listener to send Mixpanel event when the user closes the tab
    window.addEventListener("beforeunload", handleMixpanelPageClose);

    return () => {
      window.removeEventListener("beforeunload", handleMixpanelPageClose);
    };
  }, [roomName]);

  return (
    <AppStateContext.Provider
      value={{
        isHost,
        roomName,
        token,
        jsonPath,
        assetConfig,
        error,
        userName,
        userTwilioIdentity,
        handleJoinRoom,
        onLoginAsHost,
        handleEndRoom,
        debug,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};

export { AppStateContextProvider, AppStateContext };
