import type { Milliseconds, Subscription } from '@g360/vt-types';

import type { DebouncedFunction } from '../../misc';
import throttle from '../../misc/throttle';
import type { AnalyticsEventDetailsMiniMap, DragEventType } from '..';
import type Analytics from '../Analytics';

/**
 * A helper class that handles drag events in the minimap. The events are generated only if there was an actual movement.
 * The events are throttled to one every `500 ms`.
 * After `dragEnd` event, the next `dragStart` event will be generated immediately, without throttling.
 */
export default class AnalyticsMiniMapDragHelper {
  /** Determines how often we generate drag analytics events. */
  private static readonly DRAG_TIMEOUT_DELAY: Milliseconds = 500;

  /** An instance of the main `Analytics` class. */
  private analytics: Analytics;

  /** Store the details from drag start to use in `dragStart.VT.Scene` analytics event. */
  private lastDragStartDetails: AnalyticsEventDetailsMiniMap = {
    scene_id: '',
    building: '1',
    floor: '0',
    position: [0, 0],
    zoom: 0,
  };

  /** A list of external pubsub event subscriptions. */
  private subscriptions: Subscription[] = [];

  /**
   * Pushes a drag event to the analytics. A throttled version of {@link pushDragEvent}.
   * Limits how often we generate drag analytics events to one every `500 ms`.
   * @param dragEventType - `DragEventType`: the type of the drag event. Possible values: `dragStart`, `drag`, `dragEnd`.
   * @param miniMapDetails - `AnalyticsEventDetailsMiniMap`: the details of the drag event.
   * @param miniMapDetails.scene_id - `string`: the current scene id.
   * @param miniMapDetails.building - `string`: the current building key.
   * @param miniMapDetails.floor - `string`: the current floor key.
   * @param miniMapDetails.position - `[Centimeter, Centimeter]`: the current position of the minimap center.
   * @param miniMapDetails.zoom - `number`: the current zoom level (scale) of the minimap.
   * @returns `void`
   */
  private pushDragEventThrottled: DebouncedFunction<
    (dragEventType: DragEventType, miniMapDetails: AnalyticsEventDetailsMiniMap) => void
  >;

  /**
   * @param analytics - `Analytics`: an instance of the main Analytics class.
   */
  constructor(analytics: Analytics) {
    this.analytics = analytics;

    this.onMiniMapDragStart = this.onMiniMapDragStart.bind(this);
    this.onMiniMapDragUpdate = this.onMiniMapDragUpdate.bind(this);
    this.onMiniMapDragEnd = this.onMiniMapDragEnd.bind(this);
    this.pushDragEvent = this.pushDragEvent.bind(this);
    this.onAnalyticsDeactivated = this.onAnalyticsDeactivated.bind(this);

    this.pushDragEventThrottled = throttle(this.pushDragEvent, AnalyticsMiniMapDragHelper.DRAG_TIMEOUT_DELAY);

    analytics.pubSub.subscribe('analytics.deactivated', this.onAnalyticsDeactivated);
  }

  /**
   * Adds an event subscription to the list of subscriptions.
   * @param subscription - `Subscription`: an event subscription to be added to the list of subscriptions.
   * @returns `void`
   */
  public addSubscription(subscription: Subscription): void {
    this.subscriptions.push(subscription);
  }

  /**
   * Unsubscribes from all event subscriptions and clears the list of subscriptions.
   * @returns `void`
   */
  public unsubscribeAll(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

  /**
   * An event that is triggered when an user starts interacting with the minimap. For example, on click.
   * It is triggered even if there was no actual movement yet.
   * @param miniMapDetails - `AnalyticsEventDetailsMiniMap`: the details of the drag event.
   * @param miniMapDetails.scene_id - `string`: the current scene id.
   * @param miniMapDetails.building - `string`: the current building key.
   * @param miniMapDetails.floor - `string`: the current floor key.
   * @param miniMapDetails.position - `[Centimeter, Centimeter]`: the current position of the minimap center.
   * @param miniMapDetails.zoom - `number`: the current zoom level (scale) of the minimap.
   * @returns `Promise<void>`
   */
  public async onMiniMapDragStart(miniMapDetails: AnalyticsEventDetailsMiniMap): Promise<void> {
    if (!this.analytics.isActive) return;

    this.lastDragStartDetails = miniMapDetails;
  }

  /**
   * An event that is triggered continuously while a user is interacting with the minimap.
   * It is triggered only when there was an actual movement.
   * @param miniMapDetails - `AnalyticsEventDetailsMiniMap`: the details of the drag event.
   * @param miniMapDetails.scene_id - `string`: the current scene id.
   * @param miniMapDetails.building - `string`: the current building key.
   * @param miniMapDetails.floor - `string`: the current floor key.
   * @param miniMapDetails.position - `[Centimeter, Centimeter]`: the current position of the minimap center.
   * @param miniMapDetails.zoom - `number`: the current zoom level (scale) of the minimap.
   * @returns `Promise<void>`
   */
  public async onMiniMapDragUpdate(miniMapDetails: AnalyticsEventDetailsMiniMap): Promise<void> {
    if (!this.analytics.isActive) return;

    if (!this.pushDragEventThrottled.isOnCooldown()) {
      this.pushDragEventThrottled('dragStart', this.lastDragStartDetails);
      return;
    }

    this.pushDragEventThrottled('drag', miniMapDetails);
  }

  /**
   * An event that is triggered when an user stops interacting with the minimap. For example, releasing a click.
   * It is triggered even if there was no actual movement.
   * @param miniMapDetails - `AnalyticsEventDetailsMiniMap`: the details of the drag event.
   * @param miniMapDetails.scene_id - `string`: the current scene id.
   * @param miniMapDetails.building - `string`: the current building key.
   * @param miniMapDetails.floor - `string`: the current floor key.
   * @param miniMapDetails.position - `[Centimeter, Centimeter]`: the current position of the minimap center.
   * @param miniMapDetails.zoom - `number`: the current zoom level (scale) of the minimap.
   * @returns `Promise<void>`
   */
  public async onMiniMapDragEnd(miniMapDetails: AnalyticsEventDetailsMiniMap): Promise<void> {
    if (!this.analytics.isActive) return;
    if (!this.pushDragEventThrottled.isOnCooldown()) return;

    this.pushDragEventThrottled('dragEnd', miniMapDetails);
  }

  /**
   * Pushes a drag event to the analytics. Don't call directly, use {@link pushDragEventThrottled} instead.
   * @param dragEventType - `DragEventType`: the type of the drag event. Possible values: `dragStart`, `drag`, `dragEnd`.
   * @param miniMapDetails - `AnalyticsEventDetailsMiniMap`: the details of the drag event.
   * @param miniMapDetails.scene_id - `string`: the current scene id.
   * @param miniMapDetails.building - `string`: the current building key.
   * @param miniMapDetails.floor - `string`: the current floor key.
   * @param miniMapDetails.position - `[Centimeter, Centimeter]`: the current position of the minimap center.
   * @param miniMapDetails.zoom - `number`: the current zoom level (scale) of the minimap.
   * @returns `void`
   */
  private pushDragEvent(dragEventType: DragEventType, miniMapDetails: AnalyticsEventDetailsMiniMap): void {
    if (!this.analytics.isActive) return;

    if (dragEventType === 'dragEnd') this.pushDragEventThrottled.cancel();

    this.analytics.push<AnalyticsEventDetailsMiniMap>(dragEventType, 'MAP', 'Floor plan', miniMapDetails);
  }

  /**
   * An event that is triggered when analytics is deactivated.
   * @returns `Promise<void>`
   */
  private async onAnalyticsDeactivated(): Promise<void> {
    this.pushDragEventThrottled.cancel();
  }
}
