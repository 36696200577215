/** Get project camera, project number and pano number from sceneKey */
export function getNumbersFromSceneKey(sceneKey: string) {
  const parseResult = (result: string[] | null) => (result ? parseInt(result[1], 10) : null);

  const camera = parseResult(/CAM(\d+)/.exec(sceneKey));
  const project = parseResult(/PR(\d+)/.exec(sceneKey));
  const pano = parseResult(/PAN(\d+)/.exec(sceneKey));

  return { camera, project, pano };
}

/**
 * Sort scene keys based on camera, project and pano numbers, mutating the array. Ignores camera version.
 */
export default function sortSceneKeysByNumbers(sceneKeys: string[], direction: 'asc' | 'desc' = 'asc') {
  return sceneKeys.sort((keyA, keyB) => {
    const aNumbers = getNumbersFromSceneKey(keyA);
    const bNumbers = getNumbersFromSceneKey(keyB);

    const compare = (a: number, b: number) => (direction === 'asc' ? a - b : b - a);

    const cameraDiff = compare(aNumbers.camera ?? 0, bNumbers.camera ?? 0);
    const projectDiff = compare(aNumbers.project ?? 0, bNumbers.project ?? 0);
    const panoDiff = compare(aNumbers.pano ?? 0, bNumbers.pano ?? 0);

    return cameraDiff || projectDiff || panoDiff;
  });
}
