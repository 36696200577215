import { RefObject, useCallback, useEffect } from "react";

// type AnyEvent = MouseEvent | TouchEvent;

function useOnClickOutside<T extends HTMLElement>(
  ref: RefObject<T>,
  handler: (event: React.ChangeEvent<T>) => void
): void {
  const handleClick = useCallback(
    (event: React.ChangeEvent<T>) => {
      return handler(event);
    },
    [handler]
  );

  useEffect(() => {
    // TODO: fix any
    const listener = (event: any) => {
      const el = ref?.current;

      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains(event.target)) {
        return;
      }

      handleClick(event);
    };

    document.addEventListener(`mousedown`, listener);
    document.addEventListener(`touchstart`, listener);

    return () => {
      document.removeEventListener(`mousedown`, listener);
      document.removeEventListener(`touchstart`, listener);
    };

    // Reload only if ref or handleClick changes
  }, [ref, handleClick]);
}

export default useOnClickOutside;
