import { CloseIcon } from "components/icons";
import { useDelayUnmount, useOnClickOutside } from "hooks";
import { FC, useRef } from "react";
import { classNamesUtil } from "utils/classNamesUtil";

interface ModalProps {
  modalTitle?: JSX.Element | string;
  modalWidth?:
    | "xl"
    | "2xl"
    | "3xl"
    | "4xl"
    | "5xl"
    | "6xl"
    | "7xl"
    | "large"
    | "medium";
  isCloseX?: boolean;
  minHeight?: "500" | "auto";
  isModalVisible: boolean;
  maxHeight?: "none" | "md";
  overflow?: "auto" | "hidden";
  topPosition?: "2" | "10" | "20";
  children: JSX.Element | JSX.Element[];
  handleCancelClick: () => void;
}

const Modal: FC<ModalProps> = ({
  children,
  minHeight,
  modalTitle,
  isModalVisible,
  isCloseX = true,
  overflow = "auto",
  topPosition = "20",
  maxHeight = "none",
  modalWidth = "medium",
  handleCancelClick,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  useOnClickOutside<HTMLDivElement>(modalRef, () => {
    handleCancelClick();
  });

  const shouldRender = useDelayUnmount(isModalVisible, 800);

  return (
    <>
      {isModalVisible && (
        <div
          className={classNamesUtil(
            shouldRender ? "opacity-100" : "opacity-0",
            "fixed top-0 left-0 flex items-center justify-center w-full h-full transition-all ease-in-out duration-300 z-10"
          )}
        >
          <div className="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"></div>
          <div
            className={classNamesUtil(
              overflow === "hidden" ? "overflow-hidden" : "overflow-y-auto",
              topPosition === "2" ? "top-[2%] bottom-[2%]" : "",
              topPosition === "10"
                ? "h-screen sm:h-auto top-0 sm:top-[10%]"
                : "",
              topPosition === "20"
                ? "h-screen sm:h-auto top-0 sm:top-[20%]"
                : "",
              modalWidth === "xl" ? "md:max-w-xl" : "",
              modalWidth === "2xl" ? "md:max-w-2xl" : "",
              modalWidth === "3xl" ? " md:max-w-3xl" : "",
              modalWidth === "4xl" ? "md:max-w-4xl" : "",
              modalWidth === "5xl" ? "md:max-w-5xl" : "",
              modalWidth === "6xl" ? "md:max-w-6xl" : "",
              modalWidth === "7xl" ? "md:max-w-7xl" : "",
              modalWidth === "large" ? "md:max-w-lg" : "",
              modalWidth === "medium" ? "md:max-w-md" : "",
              "absolute w-full z-50 sm:w-11/12 mx-auto bg-white rounded shadow-lg modal-container"
            )}
            ref={modalRef}
          >
            <div
              className={classNamesUtil(
                minHeight === "auto" ? "min-h-auto" : "",
                minHeight === "500" ? "min-h-[500px]" : "",
                maxHeight === "md" ? "max-h-[50rem] overflow-scroll" : "",
                "w-full text-left"
              )}
            >
              {/* <!-- Title, Close button --> */}
              {modalTitle || isCloseX ? (
                <div
                  className={classNamesUtil(
                    "flex items-center justify-between px-[30px] pt-6"
                  )}
                >
                  <div>
                    {modalTitle && <h1 className="text-2xl">{modalTitle}</h1>}
                  </div>
                  {isCloseX && (
                    <div
                      className="z-50 cursor-pointer modal-close"
                      onClick={() => {
                        handleCancelClick();
                      }}
                    >
                      <CloseIcon className="w-4 h-4 hover:fill-gray-800 fill-gray-700" />
                    </div>
                  )}
                </div>
              ) : null}
              {/* <!--Body--> */}
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
