import { SVGProps } from "react";

const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 38 38"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <path
      d="m12.293.293-12 12a1 1 0 0 0 1.414 1.414l12-12A1 1 0 0 0 12.293.293Z"
      transform="scale(2.66667)"
    />
    <path
      d="m12.293.293-12 12a1 1 0 0 0 1.414 1.414l12-12A1 1 0 0 0 12.293.293Z"
      transform="matrix(0 -2.66667 2.66667 0 0 37.333)"
    />
  </svg>
);

export default CloseIcon;
