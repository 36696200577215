import { SVGProps } from "react";

const NoAccessIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 44 62"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
    }}
    {...props}
  >
    <path
      d="M71 34c0-3.311-2.689-6-6-6H35c-3.311 0-6 2.689-6 6v21c0 3.311 2.689 6 6 6h30c3.311 0 6-2.689 6-6V34Z"
      style={{
        fill: "none",
        stroke: "#000",
        strokeWidth: 2,
      }}
      transform="translate(-28)"
    />
    <path
      d="M52 41a2 2 0 0 0-4 0v7a2 2 0 0 0 4 0v-7Z"
      style={{
        fill: "none",
        stroke: "#000",
        strokeWidth: 2,
      }}
      transform="translate(-28)"
    />
    <path
      d="M35 16c0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15v12H35V16Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#000",
        strokeWidth: 2,
      }}
      transform="translate(-28)"
    />
    <path
      d="M40 16c0-5.523 4.477-10 10-10s10 4.477 10 10v12H40V16Z"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#000",
        strokeWidth: 2,
      }}
      transform="translate(-28)"
    />
  </svg>
);

export default NoAccessIcon;
