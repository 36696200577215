import type { Milliseconds, Radian, ScenePos, Subscription } from '@g360/vt-types';

import type { AnalyticsEventDetailsInertia } from '..';
import type Analytics from '../Analytics';

/**
 * A helper class that handles inertia animation events in the VT after dragging.
 */
export default class AnalyticsSceneInertiaHelper {
  /** An instance of the main `Analytics` class. */
  private analytics: Analytics;

  /** Stores the view from `scene.anim.start` pubsub event. */
  private inertiaStartView: ScenePos<Radian> = { yaw: 0, pitch: 0, fov: 0 };
  /** Stores the target animation duration from `scene.anim.start` pubsub event.
   Animation can be cancelled before it's done by user. */
  private targetDuration: Milliseconds = 0;
  /** Stores the timestamp when `scene.anim.start` pubsub event happened. */
  private inertiaStartTimestamp: Milliseconds = 0;

  /** A list of external pubsub event subscriptions. */
  private subscriptions: Subscription[] = [];

  /**
   * @param analytics - `Analytics`: an instance of the main Analytics class.
   */
  constructor(analytics: Analytics) {
    this.analytics = analytics;

    this.onSceneInertiaStart = this.onSceneInertiaStart.bind(this);
    this.onSceneInertiaEnd = this.onSceneInertiaEnd.bind(this);
    this.onAnalyticsDeactivated = this.onAnalyticsDeactivated.bind(this);

    analytics.pubSub.subscribe('analytics.deactivated', this.onAnalyticsDeactivated);
  }

  /**
   * Adds an event subscription to the list of subscriptions.
   * @param subscription - `Subscription`: an event subscription to be added to the list of subscriptions.
   * @returns `void`
   */
  public addSubscription(subscription: Subscription): void {
    this.subscriptions.push(subscription);
  }

  /**
   * Unsubscribes from all event subscriptions and clears the list of subscriptions.
   * @returns `void`
   */
  public unsubscribeAll(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

  /**
   * An event that is triggered when inertia animation starts after user ends dragging the scene manually.
   * @param startView - `ScenePos<Radian>`: the scene view when inertia animation started.
   * @param startView.pitch - `Radian`: the scene pitch when inertia animation started.
   * @param startView.yaw - `Radian`: the scene yaw when inertia animation started.
   * @param startView.fov - `Radian`: the scene fov when inertia animation started.
   * @param _targetView - `ScenePos<Radian>`: the target scene view. Unused here because it also a parameter in `onSceneInertiaEnd`.
   * @param _targetView.pitch - `Radian`: the target scene pitch. Unused.
   * @param _targetView.yaw - `Radian`: the target scene yaw. Unused.
   * @param _targetView.fov - `Radian`: the target scene fov. Unused.
   * @param targetDuration - `Milliseconds`: the target inertia animation duration.
   * @returns `Promise<void>`
   */
  public async onSceneInertiaStart(
    startView: ScenePos<Radian>,
    _targetView: ScenePos<Radian>,
    targetDuration: Milliseconds
  ): Promise<void> {
    if (!this.analytics.isActive) return;

    this.inertiaStartView = startView;
    this.targetDuration = targetDuration;
    this.inertiaStartTimestamp = Date.now();
  }

  /**
   * An event that is triggered when inertia animation ends.
   * It can happen naturally, or the user can cancel it by starting dragging again.
   * @param actualEndView - `ScenePos<Radian>`: the scene view when inertia animation ended.
   * @param actualEndView.pitch - `Radian`: the scene pitch when inertia animation ended.
   * @param actualEndView.yaw - `Radian`: the scene yaw when inertia animation ended.
   * @param actualEndView.fov - `Radian`: the scene fov when inertia animation ended.
   * @param targetView - `ScenePos<Radian>`: the target scene view.
   * @param targetView.pitch - `Radian`: the target scene pitch. Unused.
   * @param targetView.yaw - `Radian`: the target scene yaw. Unused.
   * @param targetView.fov - `Radian`: the target scene fov. Unused.
   * @returns `Promise<void>`
   */
  public async onSceneInertiaEnd(actualEndView: ScenePos<Radian>, targetView: ScenePos<Radian>): Promise<void> {
    if (!this.analytics.isActive) return;

    this.analytics.push<AnalyticsEventDetailsInertia>('inertia', 'VT', 'Scene', {
      scene_id: this.analytics.getCurrentSceneId(),
      target_view: [targetView.yaw, targetView.pitch, targetView.fov ?? 0],
      target_duration: this.targetDuration,
      start: {
        view: [this.inertiaStartView.yaw, this.inertiaStartView.pitch, this.inertiaStartView.fov ?? 0],
        timestamp: this.inertiaStartTimestamp,
      },
      end: {
        view: [actualEndView.yaw, actualEndView.pitch, actualEndView.fov ?? 0],
        timestamp: Date.now(),
      },
    });
  }

  /**
   * An event that is triggered when analytics is deactivated.
   * @returns `Promise<void>`
   */
  private async onAnalyticsDeactivated(): Promise<void> {
    this.inertiaStartView = { yaw: 0, pitch: 0, fov: 0 };
    this.targetDuration = 0;
    this.inertiaStartTimestamp = 0;
  }
}
