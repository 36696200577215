import type { Milliseconds, Pixel, Size } from '@g360/vt-types';

import type { Analytics, DebouncedFunction, ThrottledFunction } from '../..';
import debounce from '../../misc/debounce';
import throttle from '../../misc/throttle';
import type { AnalyticsEventDetailsResize, ResizeEventType } from '..';

/**
 * A helper class that handles window resize events. The events are generated only if there was an actual movement.
 *
 * The events are throttled to one every `500 ms`.
 *
 * After `resizeEnd` event, the next `resizeStart` event will be generated immediately, without throttling.
 *
 * We don't have a unique trigger for each analytics event, so we have to keep the track of the flow of interactions here.
 *
 * Because of that, we can't immediately detect the `resizeEnd` event.
 * Instead we have to wait certain time since the last resize event.
 * And if there was no new resize updates, generates the `resizeEnd` event, and it will have the same details as the last `resize` event.
 */
export default class AnalyticsResizeHelper {
  /** Determines how often we generate resize analytics events. */
  private static readonly RESIZE_TIMEOUT_DELAY: Milliseconds = 500;

  /** An instance of the main `Analytics` class. */
  private analytics: Analytics;

  /** `onresize` event is triggered when opening the tour for some reason. Need to skip it. */
  private isFirstResizeEventSkipped = false;
  private lastResizeEndCanvasSize: Size<Pixel> = { width: 0, height: 0 };

  /**
   * Pushes a resize event to the analytics. A throttled version of {@link pushResizeEvent}.
   * Limits how often we generate resize analytics events to one every `500 ms`.
   * @param resizeEventType - `ResizeEventType`: the type of resize event. Possible values: `resizeStart`, `resize`, `resizeEnd`.
   * @param canvasSize - `Size<Pixel>`: the size of the renderer canvas.
   * @returns `void`
   */
  private pushResizeEventThrottled: ThrottledFunction<
    (resizeEventType: ResizeEventType, canvasSize: Size<Pixel>) => void
  >;

  /**
   * Pushes a `resizeEnd` event to the analytics. A debounced version of {@link pushResizeEndEvent}.
   * Will generate it in `500ms` after the last `resize` event.
   * @returns `void`
   */
  private pushResizeEndEventDebounced: DebouncedFunction<() => void>;

  /**
   * @param analytics - `Analytics`: an instance of the main Analytics class.
   */
  constructor(analytics: Analytics) {
    this.analytics = analytics;

    this.onResize = this.onResize.bind(this);
    this.onAnalyticsDeactivated = this.onAnalyticsDeactivated.bind(this);
    this.pushResizeEvent = this.pushResizeEvent.bind(this);
    this.pushResizeEndEvent = this.pushResizeEndEvent.bind(this);

    window.addEventListener('resize', this.onResize);

    this.pushResizeEventThrottled = throttle(this.pushResizeEvent, AnalyticsResizeHelper.RESIZE_TIMEOUT_DELAY);
    this.pushResizeEndEventDebounced = debounce(this.pushResizeEndEvent, AnalyticsResizeHelper.RESIZE_TIMEOUT_DELAY);

    analytics.pubSub.subscribe('analytics.deactivated', this.onAnalyticsDeactivated);
  }

  /**
   * Pushes a resize event to the analytics.  Don't call directly, use {@link pushResizeEventThrottled} instead.
   * @param resizeEventType - `ResizeEventType`: the type of resize event. Possible values: `resizeStart`, `resize`, `resizeEnd`.
   * @param canvasSize - `Size<Pixel>`: the size of the renderer canvas.
   * @returns `void`
   */
  private pushResizeEvent(resizeEventType: ResizeEventType, canvasSize: Size<Pixel>): void {
    if (!this.analytics.isActive) return;

    this.pushResizeEndEventDebounced();

    this.analytics.push<AnalyticsEventDetailsResize>(resizeEventType, 'VT', 'Scene', {
      scene_id: this.analytics.getCurrentSceneId(),
      size: [canvasSize.width, canvasSize.height],
    });
  }

  /**
   * Pushes a resize end event to the analytics. Don't call directly, use {@link pushResizeEndEventDebounced} instead.
   * @returns `void`
   */
  private pushResizeEndEvent(): void {
    if (!this.analytics.isActive) return;

    this.lastResizeEndCanvasSize = this.analytics.getCanvasSize();

    this.analytics.push<AnalyticsEventDetailsResize>('resizeEnd', 'VT', 'Scene', {
      scene_id: this.analytics.getCurrentSceneId(),
      size: [this.lastResizeEndCanvasSize.width, this.lastResizeEndCanvasSize.height],
    });
  }

  /**
   * An event that is triggered when the window is resized.
   * @returns `Promise<void>`
   */
  private async onResize(): Promise<void> {
    this.pushResizeEndEventDebounced.cancel();

    if (!this.isFirstResizeEventSkipped) {
      this.isFirstResizeEventSkipped = true;
      return;
    }

    if (!this.analytics.isActive) return;

    this.pushResizeEndEventDebounced();

    if (!this.pushResizeEventThrottled.isOnCooldown()) {
      this.pushResizeEventThrottled('resizeStart', this.lastResizeEndCanvasSize);
      return;
    }

    this.pushResizeEventThrottled('resize', this.analytics.getCanvasSize());
  }

  /**
   * An event that is triggered when analytics is deactivated.
   * @returns `Promise<void>`
   */
  private async onAnalyticsDeactivated(): Promise<void> {
    this.pushResizeEventThrottled.cancel();
    this.pushResizeEndEventDebounced.cancel();
  }
}
