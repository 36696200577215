import * as attributeUtils from './attributeUtils';
import * as loadBasicVertexIndices from './loadBasicVertexIndices';
import * as programShaderUtils from './programShaderUtils';
import * as textureUtils from './textureUtils';

export * from './attributeUtils';
export * from './loadBasicVertexIndices';
export * from './programShaderUtils';
export * from './textureUtils';

export default {
  ...attributeUtils,
  ...programShaderUtils,
  ...textureUtils,
  ...loadBasicVertexIndices,
};
