import type { Degree } from '@g360/vt-types';

import type { SetCubeMapTexturesParameters } from '../../types/internal';

function rotateImage(canvas: HTMLCanvasElement, image: HTMLImageElement, angle: Degree): ArrayBufferLike {
  // eslint-disable-next-line no-param-reassign
  canvas.width = image.width;
  // eslint-disable-next-line no-param-reassign
  canvas.height = image.height;

  const canvasContext = canvas.getContext('2d', { willReadFrequently: true });

  if (!canvasContext) return new ArrayBuffer(0);

  canvasContext.translate(canvas.width / 2, canvas.height / 2);
  canvasContext.rotate((angle * Math.PI) / 180);
  canvasContext.drawImage(image, -image.width / 2, -image.width / 2);
  canvasContext.restore();

  const imageData = canvasContext.getImageData(0, 0, image.width, image.height);
  return imageData.data.buffer;
}

/**
 * Creates cube map textures.
 * @param gl `WebGLRenderingContext`: An interface to the OpenGL ES 2.0 graphics rendering context for the drawing surface of an HTML `<canvas>` element.
 * @param canvas `HTMLCanvasElement`: A canvas element to draw on.
 * @param texture `WebGLTexture | null`: A cube map texture to set the cubemap textures to. Will hold images for all 6 sides of the cube.
 * @param restParameters `SetCubeMapTexturesParameters`: an object with the rest of the parameters.
 * @param restParameters.cubeMapSides `CubeMapSides`: An object containing source images and rotations of every side of the cube.
 * @param restParameters.useAlphaChannel `boolean` - `Optional`: Whether to use the alpha channel. Sets the texture format to `gl.RGB` if `true` and to `gl.RGBA` if `false`.
 *
 * Default value is `false`.
 *
 * @returns `WebGLTexture | null`: The created texture.
 */
function setCubeMapTextures(
  gl: WebGLRenderingContext,
  canvas: HTMLCanvasElement,
  texture: WebGLTexture | null,
  restParameters: SetCubeMapTexturesParameters
): WebGLTexture | null {
  const cubeMapSides = restParameters.cubeMapSides;
  const format: GLenum = restParameters.useAlphaChannel ? gl.RGBA : gl.RGB;

  if (!texture) {
    // eslint-disable-next-line no-console
    console.error('No texture provided to setCubeMapTextures');
    return null;
  }

  // Unbind any existing textures
  gl.bindTexture(gl.TEXTURE_2D, null);
  gl.bindTexture(gl.TEXTURE_CUBE_MAP, null);

  // Bind the cube map texture
  gl.bindTexture(gl.TEXTURE_CUBE_MAP, texture);

  const sides = [
    { face: gl.TEXTURE_CUBE_MAP_POSITIVE_X, side: cubeMapSides.left },
    { face: gl.TEXTURE_CUBE_MAP_NEGATIVE_X, side: cubeMapSides.right },
    { face: gl.TEXTURE_CUBE_MAP_POSITIVE_Y, side: cubeMapSides.up },
    { face: gl.TEXTURE_CUBE_MAP_NEGATIVE_Y, side: cubeMapSides.down },
    { face: gl.TEXTURE_CUBE_MAP_POSITIVE_Z, side: cubeMapSides.back },
    { face: gl.TEXTURE_CUBE_MAP_NEGATIVE_Z, side: cubeMapSides.front },
  ];

  sides.forEach(({ face, side }) => {
    const arrayBuffer = rotateImage(canvas, side.source as HTMLImageElement, side.rotationAngle);
    const dataTypedArray = new Uint8Array(arrayBuffer);

    gl.texImage2D(
      face,
      0,
      format,
      side.source.width ?? 0,
      side.source.height ?? 0,
      0,
      format,
      gl.UNSIGNED_BYTE,
      dataTypedArray
    );
  });

  gl.texParameteri(gl.TEXTURE_CUBE_MAP, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
  gl.texParameteri(gl.TEXTURE_CUBE_MAP, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
  gl.texParameteri(gl.TEXTURE_CUBE_MAP, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_CUBE_MAP, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);

  // Unbind the cube map texture
  gl.bindTexture(gl.TEXTURE_CUBE_MAP, null);

  return texture;
}

export default setCubeMapTextures;
