import asIcon from './asIcon';

type Props = { className?: string };

const InfoIcon = ({ className }: Props) => (
  <svg
    className={className}
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 11L12 16" strokeWidth="2" strokeLinecap="round" />
    <circle cx="12" cy="12" r="10" strokeWidth="2" />
    <circle cx="12" cy="7" r="1" fill="white" stroke="none" />
  </svg>
);

export default asIcon(InfoIcon);
