import { HandIcon } from "components/icons";
import { InfoPageLayout } from "components/layout";
import { FC } from "react";

const LeaveVideoChatPage: FC = () => {
  return (
    <InfoPageLayout
      icon={
        <HandIcon className="w-[4.6rem] h-[4.6rem] fill-giraffe-dark stroke-giraffe-dark" />
      }
      headLine="Guided viewing session has ended. Thank you for participating."
    />
  );
};

export default LeaveVideoChatPage;
