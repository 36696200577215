import type { Degree, Radian, Size } from '@g360/vt-types';

import { MAX_FOV_DEG, MAX_FOV_RAD } from '../../Globals';
import * as Angle from '../Angle';

export function getViewAngle(aFovRad: Radian, aspectRatio: number): number {
  return 2 * Math.atan(Math.tan(aFovRad / 2) * aspectRatio);
}

export function horizontalToVerticalFovDeg(hFovDeg: Degree, canvasSize: Size): number {
  return Angle.toDeg(getViewAngle(Angle.toRad(hFovDeg), canvasSize.height / canvasSize.width));
}

export function verticalToHorizontalFovDeg(vFovDeg: Degree, canvasSize: Size): number {
  return Angle.toDeg(getViewAngle(Angle.toRad(vFovDeg), canvasSize.width / canvasSize.height));
}

export function horizontalToVerticalFovRad(hFovRad: Radian, canvasSize: Size): number {
  return getViewAngle(hFovRad, canvasSize.height / canvasSize.width);
}

export function verticalToHorizontalFovRad(vFovRad: Radian, canvasSize: Size): number {
  return getViewAngle(vFovRad, canvasSize.width / canvasSize.height);
}

export function getMinFov(canvasWidth: number): number {
  return Angle.toRad(Math.min(MAX_FOV_DEG, canvasWidth / ((3072 / 90) * 0.9)));
}

export function getFovBoundary(canvasSize: Size): [number, number] {
  let maxHorizontalFov = MAX_FOV_RAD;

  const verticalFovMax = horizontalToVerticalFovRad(MAX_FOV_RAD, canvasSize);

  // Limit vertical fov
  if (verticalFovMax > MAX_FOV_RAD) {
    maxHorizontalFov = Math.min(MAX_FOV_RAD, verticalToHorizontalFovRad(MAX_FOV_RAD, canvasSize));
  }

  return [getMinFov(canvasSize.width), maxHorizontalFov];
}
