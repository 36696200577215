import { useDevices, useDevicesMediaAccess } from "hooks";
import noop from "lodash/noop";
import { createContext, FC, useCallback, useEffect, useState } from "react";

type MediaDeviceType = MediaDeviceInfo | undefined | null;
type ActiveMediaErrorModalType = "microphone" | "camera" | null;

type DeviceStateContextType = {
  isAudioOn: boolean;
  isVideoOn: boolean;
  audioAccess: boolean | undefined;
  videoAccess: boolean | undefined;
  audioInputDevices: MediaDeviceInfo[];
  audioOutputDevices: MediaDeviceInfo[];
  videoInputDevices: MediaDeviceInfo[];
  selectedVideoInput: MediaDeviceType | undefined;
  selectedAudioInput: MediaDeviceType | undefined;
  selectedAudioOutput: MediaDeviceType | undefined;
  setSelectedAudioInput: (device: MediaDeviceType) => void;
  setSelectedVideoInput: (device: MediaDeviceType) => void;
  setSelectedAudioOutput: (device: MediaDeviceType) => void;
  activeMediaErrorModal: ActiveMediaErrorModalType;
  setActiveMediaErrorModal: (modalType: ActiveMediaErrorModalType) => void;
  handleAudioToggle: () => void;
  handleVideoToggle: () => void;
  setAudioAccess: (access: boolean) => void;
  setVideoAccess: (access: boolean) => void;
  performAudioCheck: () => Promise<boolean>;
  performVideoCheck: () => Promise<boolean>;
};

interface DeviceStateContextProps {
  children: JSX.Element;
}

const DeviceStateContext = createContext<DeviceStateContextType>({
  isAudioOn: false,
  isVideoOn: false,
  audioAccess: undefined,
  videoAccess: undefined,
  audioInputDevices: [],
  audioOutputDevices: [],
  videoInputDevices: [],
  selectedVideoInput: undefined,
  selectedAudioInput: undefined,
  selectedAudioOutput: undefined,
  setSelectedAudioInput: noop,
  setSelectedVideoInput: noop,
  setSelectedAudioOutput: noop,
  activeMediaErrorModal: null,
  setActiveMediaErrorModal: noop,
  handleAudioToggle: noop,
  handleVideoToggle: noop,
  setAudioAccess: noop,
  setVideoAccess: noop,
  performAudioCheck: () => new Promise((resolve) => resolve(false)),
  performVideoCheck: () => new Promise((resolve) => resolve(false)),
});

const DeviceStateContextProvider: FC<DeviceStateContextProps> = ({
  children,
}) => {
  // Manage video and audio enabling.
  const [isAudioOn, setIsAudioOn] = useState<boolean>(false);
  const [isVideoOn, setIsVideoOn] = useState<boolean>(false);

  const {
    audioAccess,
    videoAccess,
    performAudioCheck,
    performVideoCheck,
    setVideoAccess,
    setAudioAccess,
  } = useDevicesMediaAccess();
  const { audioInputDevices, videoInputDevices, audioOutputDevices } =
    useDevices(videoAccess, audioAccess);

  const [selectedVideoInput, setSelectedVideoInput] =
    useState<MediaDeviceType>();
  const [selectedAudioInput, setSelectedAudioInput] =
    useState<MediaDeviceType>();
  const [selectedAudioOutput, setSelectedAudioOutput] =
    useState<MediaDeviceType>();

  useEffect(() => {
    setSelectedAudioInput(audioInputDevices[0]);
    setSelectedVideoInput(videoInputDevices[0]);
    setSelectedAudioOutput(audioOutputDevices[0]);
  }, [audioInputDevices, videoInputDevices, audioOutputDevices]);

  const [activeMediaErrorModal, setActiveMediaErrorModal] =
    useState<ActiveMediaErrorModalType>(null);

  const handleAudioToggle = useCallback(() => {
    if (!audioAccess) {
      performAudioCheck().then((success) => {
        if (!success) setActiveMediaErrorModal("microphone");
      });
      return;
    }
    setIsAudioOn((state) => !state);
  }, [audioAccess, performAudioCheck, setActiveMediaErrorModal]);

  const handleVideoToggle = useCallback(() => {
    if (!videoAccess) {
      performVideoCheck().then((success) => {
        if (!success) setActiveMediaErrorModal("camera");
      });
      return;
    }
    setIsVideoOn((state) => !state);
  }, [videoAccess, performVideoCheck, setActiveMediaErrorModal]);

  useEffect(() => {
    setIsAudioOn(!!audioAccess);
    setIsVideoOn(!!videoAccess);
  }, [audioAccess, videoAccess]);

  return (
    <DeviceStateContext.Provider
      value={{
        isAudioOn,
        isVideoOn,
        audioAccess,
        videoAccess,
        audioInputDevices,
        videoInputDevices,
        audioOutputDevices,
        selectedVideoInput,
        selectedAudioInput,
        selectedAudioOutput,
        setSelectedAudioInput,
        setSelectedVideoInput,
        setSelectedAudioOutput,
        activeMediaErrorModal,
        setActiveMediaErrorModal,
        handleAudioToggle,
        handleVideoToggle,
        performAudioCheck,
        performVideoCheck,
        setVideoAccess,
        setAudioAccess,
      }}
    >
      {children}
    </DeviceStateContext.Provider>
  );
};

export { DeviceStateContextProvider, DeviceStateContext };
