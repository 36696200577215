import { SVGProps } from "react";

const NotSupportedBrowserIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 107 71"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
    }}
    {...props}
  >
    <g transform="translate(3.123 3.123)">
      <path
        d="M99 7c0-3.311-2.689-6-6-6H7C3.689 1 1 3.689 1 7v50c0 3.311 2.689 6 6 6h86c3.311 0 6-2.689 6-6V7Z"
        style={{
          fill: "none",
          stroke: "#000",
          strokeWidth: 2,
        }}
      />
      <path
        d="M2 13h97M9 7h12M50 32v9"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#000",
          strokeWidth: 2,
        }}
      />
      <circle
        cx={50}
        cy={45.5}
        r={1.5}
        style={{
          stroke: "#000",
          strokeWidth: 1,
          strokeLinecap: "butt",
          strokeLinejoin: "round",
          strokeMiterlimit: 2,
        }}
      />
      <path
        d="M11.633 4.733 2.342 23.317C.347 27.306 3.248 32 7.708 32h18.584c4.46 0 7.361-4.694 5.366-8.683L22.367 4.733c-2.212-4.422-8.522-4.422-10.734 0Z"
        style={{
          fill: "none",
          fillRule: "nonzero",
          stroke: "#000",
          strokeWidth: 2,
          strokeLinecap: "butt",
        }}
        transform="translate(32.942 21.336)"
      />
    </g>
  </svg>
);

export default NotSupportedBrowserIcon;
