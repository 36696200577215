import asIcon from './asIcon';

type Props = {
  className?: string;
  isOpen: boolean;
};

const FullScreenIcon = ({ className, isOpen }: Props) =>
  isOpen ? (
    <svg
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 8H6C7.10457 8 8 7.10457 8 6V3" strokeWidth="2" strokeLinecap="round" />
      <path d="M21 16L18 16C16.8954 16 16 16.8954 16 18L16 21" strokeWidth="2" strokeLinecap="round" />
      <path d="M8 21L8 18C8 16.8954 7.10457 16 6 16L3 16" strokeWidth="2" strokeLinecap="round" />
      <path d="M16 3L16 6C16 7.10457 16.8954 8 18 8L21 8" strokeWidth="2" strokeLinecap="round" />
    </svg>
  ) : (
    <svg
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 8V5C3 3.89543 3.89543 3 5 3H8" strokeWidth="2" strokeLinecap="round" />
      <path d="M21 16L21 19C21 20.1046 20.1046 21 19 21L16 21" strokeWidth="2" strokeLinecap="round" />
      <path d="M8 21L5 21C3.89543 21 3 20.1046 3 19L3 16" strokeWidth="2" strokeLinecap="round" />
      <path d="M16 3L19 3C20.1046 3 21 3.89543 21 5L21 8" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );

export default asIcon(FullScreenIcon);
