/**
 * Enables vertex attributes.
 * @param gl - `WebGLRenderingContext`: An interface to the OpenGL ES 2.0 graphics rendering context for the drawing surface of an HTML `<canvas>` element.
 * @param vertexAttributes - `GLuint[]`: An array of vertex attribute locations to enable.
 * @returns `void`
 */
function enableVertexAttributes(gl: WebGLRenderingContext, vertexAttributes: GLuint[]): void {
  for (let i = 0; i < vertexAttributes.length; i += 1) {
    gl.enableVertexAttribArray(vertexAttributes[i]);
  }
}

export default enableVertexAttributes;
