import { SVGProps } from "react";

const CogIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 42 42"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <path
      d="M19.8 14.625V12a.8.8 0 1 0-1.6 0v2.625a.8.8 0 1 0 1.6 0ZM19.8 26v-2.625a.8.8 0 1 0-1.6 0V26a.8.8 0 1 0 1.6 0ZM12 19.8h2.625a.8.8 0 1 0 0-1.6H12a.8.8 0 1 0 0 1.6ZM23.375 19.8H26a.8.8 0 1 0 0-1.6h-2.625a.8.8 0 1 0 0 1.6ZM13.184 14.316l1.75 1.75a.802.802 0 0 0 1.132-1.132l-1.75-1.75a.802.802 0 0 0-1.132 1.132ZM23.684 13.184l-1.75 1.75a.802.802 0 0 0 1.132 1.132l1.75-1.75a.802.802 0 0 0-1.132-1.132ZM14.316 24.816l1.75-1.75a.802.802 0 0 0-1.132-1.132l-1.75 1.75a.802.802 0 0 0 1.132 1.132ZM21.934 23.066l1.75 1.75a.802.802 0 0 0 1.132-1.132l-1.75-1.75a.802.802 0 0 0-1.132 1.132Z"
      transform="translate(-29.867 -29.867) scale(2.66667)"
    />
    <path
      d="M19 13.825A5.178 5.178 0 0 0 13.825 19 5.178 5.178 0 0 0 19 24.175 5.178 5.178 0 0 0 24.175 19 5.178 5.178 0 0 0 19 13.825Zm0 1.6A3.577 3.577 0 0 1 22.575 19 3.577 3.577 0 0 1 19 22.575 3.577 3.577 0 0 1 15.425 19 3.577 3.577 0 0 1 19 15.425Z"
      transform="translate(-29.867 -29.867) scale(2.66667)"
    />
  </svg>
);

export default CogIcon;
