import { SVGProps } from "react";

const CameraOffIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 32 27"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <path
      d="m12 13 12 12"
      style={{
        fill: "none",
        fillRule: "nonzero",
      }}
      transform="matrix(2 0 0 2 -22.5 -24.5)"
    />
    <path
      d="m11.47 13.53 12 12a.749.749 0 1 0 1.06-1.06l-12-12a.749.749 0 1 0-1.06 1.06Z"
      transform="matrix(2 0 0 2 -22.5 -24.5)"
    />
    <path
      d="M13.869 14h-.091c-.428 0-.778.375-.778.833v8.334c0 .458.35.833.778.833h9.333a.747.747 0 0 0 .531-.226L13.869 14Zm10.02 7.758V20.25l1.781 1.908c.49.525 1.33.15 1.33-.591v-5.142c0-.742-.84-1.117-1.33-.592l-1.781 1.917v-2.917c0-.458-.35-.833-.778-.833h-6.98l7.758 7.758Z"
      transform="matrix(2 0 0 2 -22.5 -24.5)"
    />
  </svg>
);

export default CameraOffIcon;
