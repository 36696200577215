export function base64Encode(str: string) {
  if (typeof window === 'undefined') return str;

  try {
    return btoa(str).replace(/[+]/g, '-').replace(/[/]/g, '_').replace(/[=]/g, '');
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }

  console.warn('Failed to encode base64 string');
  return str;
}

export function base64Decode(str: string) {
  if (typeof window === 'undefined') return str;

  try {
    return atob(str.replace(/[-]/g, '+').replace(/[_]/g, '/'));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }

  console.warn('Failed to decode base64 string');
  return str;
}
