import type { Degree, Radian, Size } from '@g360/vt-types';

import { horizontalToVerticalFovDeg } from '../../fovUtils/fovUtils';
import Utils from '../../Utils';

export default function getMinMaxPitch(
  targetFov: Radian,
  canvasSize: Size,
  minPitchConfig?: Degree
): { minPitch: Degree; maxPitch: Degree } {
  const minPitchLimit = minPitchConfig ?? -55;

  const vFovDeg = horizontalToVerticalFovDeg(Utils.toDeg(targetFov), canvasSize);
  const pitchRange = 90 - minPitchLimit;

  let minPitch = minPitchLimit + vFovDeg / 2;
  let maxPitch = 90 - vFovDeg / 2;

  if (pitchRange < vFovDeg) {
    // Lock pitch to average of min and max pitch when both can be seen at once
    minPitch = (minPitch + maxPitch) / 2;
    maxPitch = minPitch;
  }

  if (Number.isNaN(minPitch)) {
    minPitch = -90;
  }

  if (Number.isNaN(maxPitch)) {
    maxPitch = 90;
  }

  return { minPitch, maxPitch };
}
