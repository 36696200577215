/* eslint-disable no-continue */
import type { ClipSpace, HotSpot3DConfig, HotSpot3DType, Pixel, Pos, TourConfig } from '@g360/vt-types';
import { linearScale } from '@g360/vt-utils/';

import { MAX_FOV_RAD } from '../../common/Globals';
import RendererDebug from '../../common/RendererDebug';
import { cameraToPixel, getRenderedFov, uncapitalize } from '../../common/Utils';
import { Utils } from '../../index';
import type Animator from '../../mixins/Animator';
import type { HotSpot3DSprite, HotSpotEditAction } from '../../types/internal';
import HotSpot3D from './HotSpot3D';
import type HotSpotProgram3D from './HotSpotProgram3D';

class HotSpot3DLogic {
  public hotSpots: HotSpot3D[] = []; // all pano positions as the new hotspots
  public arrowCandidates: HotSpot3D[] = []; // subset of hotSpots that can get their arrow drawn ("normal" hotspots)
  public arrows: HotSpot3DSprite[] = []; // @todo -- maybe rename to "...Sprites" ?
  public nonDirectHotSpots: HotSpot3DSprite[] = [];

  private outside = false;
  private currentPanoKey = '';
  private tourConfig?: TourConfig;
  private hotSpotData: { [key: string]: HotSpot3DConfig[] } = {};
  private readonly hotSpotProgram3D: HotSpotProgram3D;
  private arrowWidth = 0;
  private arrowRadius = 0;
  private animator?: Animator;

  constructor(hotSpotProgram3D: HotSpotProgram3D) {
    this.hotSpotProgram3D = hotSpotProgram3D;
    console.log('HotSpot3DLogic++');
  }

  init(tourConfig: TourConfig, animator: Animator) {
    this.tourConfig = tourConfig;
    this.animator = animator;
    this.extractHotSpotsFromTourJson(this.tourConfig);
  }

  extractHotSpotsFromTourJson(json: TourConfig): void {
    Object.keys(this.tourConfig?.scenes || []).forEach((sceneKeyFrom) => {
      const scene = json?.scenes[sceneKeyFrom];

      if (!scene?.hotSpots) return;

      Object.values(scene.hotSpots).forEach((hotSpot) => {
        const hotSpot3DConfig = hotSpot as HotSpot3DConfig;
        const type = (uncapitalize(hotSpot3DConfig.type) as HotSpot3DType) || 'normal';
        let noArrow = false; // different default, depending on type

        if (hotSpot3DConfig.noArrow === undefined && type === 'normal') {
          noArrow = false;
        } else if (
          hotSpot3DConfig.noArrow === undefined &&
          ['doors', 'stairsUp', 'stairsDown', 'info'].includes(type)
        ) {
          noArrow = true;
        }

        // legacy "visible" to new "hidden" and "obstructed"  @todo -- remove later, when only the new standard JSON is pushed
        if (hotSpot3DConfig.visible === false && !scene.outside) {
          hotSpot3DConfig.obstructed = true;
          console.log('legacy stuff: "obstructed" to "true" for', scene, { hotSpot3DConfig });
        }

        if (hotSpot3DConfig.visible === false && scene.outside) {
          hotSpot3DConfig.hidden = true;
          console.log('legacy stuff: "hidden" to "true" for', scene, { hotSpot3DConfig });
        }

        if (!this.hotSpotData[sceneKeyFrom]) {
          this.hotSpotData[sceneKeyFrom] = [];
        }

        this.hotSpotData[sceneKeyFrom].push({
          visible: hotSpot3DConfig.visible || undefined,
          hidden: hotSpot3DConfig.hidden || false,
          obstructed: hotSpot3DConfig.obstructed || false,
          noArrow,
          target: hotSpot3DConfig.target || '',
          type,
          content: hotSpot3DConfig.content,
          pos: hotSpot3DConfig.pos || null,
          id: hotSpot3DConfig.id,
        });
      });
    });

    this.createHotSpots();
  }

  setCurrentScene(sceneKey: string): void {
    this.currentPanoKey = sceneKey;
    this.outside = this.tourConfig?.scenes[sceneKey].outside === true;
  }

  // create direct hotspots for current scene
  createHotSpots(): void {
    this.hotSpots = [];
    this.arrowCandidates = [];

    const maxHotSpotDistance = 800; // cm from camera where to hide normal HS (but still show their arrows)
    const cam = this.tourConfig?.scenes[this.currentPanoKey]?.camera;

    if (this.hotSpotData[this.currentPanoKey]) {
      Object.values(this.hotSpotData[this.currentPanoKey]).forEach((hotSpotData) => {
        if (this.tourConfig) {
          const hotSpot3D = new HotSpot3D(this.hotSpotProgram3D, hotSpotData);
          // @todo -- remove next line when there are only 2d HotsSpots   (only the new ones are being drawn anyway, but the old ones just pollute the lists)
          if (['normal', 'doors', 'stairsUp', 'stairsDown', 'info'].includes(hotSpot3D.type)) {
            // TS is not good enough to check if the type is correct HotSpot3DType :|     must rewrite the values
            if (cam) {
              hotSpot3D.distanceToCamera = Utils.getVec2Length(Utils.getVec2(hotSpot3D.position, [cam[0], cam[1]]));
            }
            hotSpot3D.farAway = false;
            this.hotSpots.push(hotSpot3D);
            if (hotSpot3D.type === 'normal') {
              hotSpot3D.farAway = hotSpot3D.distanceToCamera > maxHotSpotDistance;
              this.arrowCandidates.push(hotSpot3D);
            }
          }
        }
      });

      // sort by distance to camera, nearest first
      this.hotSpots.sort((a, b) => a.distanceToCamera - b.distanceToCamera);
    }

    this.checkOverlaps(true);
  }

  calculateArrows(): void {
    if (!this.tourConfig) return;

    this.arrows = [];
    // @todo -- remove getDebugParam and just hard code the consts once development is done
    const arrowScale = RendererDebug.getDebugParamFloat('arrowScale', 0.055);
    const arrowScaleOutside = RendererDebug.getDebugParamFloat('arrowScaleOutside', 0.07);
    const circleWidening = 1.8;
    const circleYOffset = -0.045;
    const penalizeSideHs = 0;
    const distanceToBottomToFade = 9;
    const numArrows = RendererDebug.getDebugParamFloat('numArrows', 3); // max num
    const fadeInSpeed = RendererDebug.getDebugParamFloat('fadeInSpeed', 7);
    const fadeOutSpeed = RendererDebug.getDebugParamFloat('fadeOutSpeed', 5);
    const slotDelaySpeed = 1 / RendererDebug.getDebugParamFloat('slotDelayTime', 1); // seconds
    const proximityFadeDistance = RendererDebug.getDebugParamFloat('proximityFadeDistance', 1); // start | in arrow diameters
    const proximityFadeDistance2 = RendererDebug.getDebugParamFloat('proximityFadeDistance2', 0.6); // finish | in arrow diameters | will be multiplied by approx size of a HS
    const proximityFadeThreshold = RendererDebug.getDebugParamFloat('proximityFadeThreshold', 0); // %     fade entirely when below this value
    const useScreenPosForInside = RendererDebug.getDebugParamFloat('useScreenPosForInside', 1) > 0;
    const useScreenPosForOutside = RendererDebug.getDebugParamFloat('useScreenPosForOutside', 0) > 0;
    const additionalRotation = RendererDebug.getDebugParamFloat('additionalRotation', 1) > 0;
    const minAngleCoef = RendererDebug.getDebugParamFloat('minAngleCoef', 1.1);

    const rect = this.hotSpotProgram3D.boundingRect;
    const zoom = MAX_FOV_RAD / this.hotSpotProgram3D.fov;
    const scale = this.outside ? arrowScaleOutside : arrowScale;
    let diameter = 0.19;
    const pixelRatio = Math.round(window.devicePixelRatio);

    this.arrowWidth = this.hotSpotProgram3D.actualArrowSpriteSize * 2.1;
    this.arrowRadius = ((this.arrowWidth / 2) * rect.width) / 2;

    if (rect.width < 400 && pixelRatio > 1) {
      diameter *= 0.9;
    }
    if (rect.width < 470 && pixelRatio === 1) {
      diameter *= 0.9;
    }

    const diameterPx = (diameter * circleWidening * rect.width) / this.hotSpotProgram3D.screenAspectRatio;
    const arrowSizePx = this.hotSpotProgram3D.actualArrowSpriteSize * rect.width;
    this.hotSpotProgram3D.actualArrowSpriteSizePx = arrowSizePx;
    const numArrowsCanBeDrawnOnCircle = diameterPx / arrowSizePx;
    const minAngleBetween = (180 / numArrowsCanBeDrawnOnCircle) * 0.6 * minAngleCoef; //  value  at the top of circle (at sides will multiplied to give bigger angle, but visually it will look same)

    // update arrow candidates
    for (let i = 0; i < this.arrowCandidates.length; i += 1) {
      // other types don't get to have an arrow pointing at them
      const pos = this.arrowCandidates[i].positionPx; // Would it be visible if drawn on screen

      if (pos && this.tourConfig.scenes[this.currentPanoKey]) {
        const c1 = this.tourConfig.scenes[this.currentPanoKey].camera;
        const c2 = this.arrowCandidates[i].position;

        // angle to camera forward
        const dx = c1[0] - c2[0];
        const dy = c1[1] - c2[1];
        let angle = Math.abs(((-(Math.PI / 2) - Math.atan2(dy, dx) + this.hotSpotProgram3D.yaw) % Math.PI) * 2);
        angle = angle > Math.PI ? Math.PI * 2 - angle : angle;
        this.arrowCandidates[i].drawingDeterminantAngle = angle;

        // floor distance
        const dist = Utils.getVec2Length(Utils.getVec2(c1, c2));

        this.arrowCandidates[i].drawingDeterminantDist = dist + Math.abs(angle) * penalizeSideHs;
      }

      // find the closest hotspot to each arrow
      // ignoring obstructed HSs
      const arrowPosPx = this.arrowCandidates[i].arrowPosPx;
      let closestDist = Number.POSITIVE_INFINITY;
      let closestHs: HotSpot3D | null = null;
      let closestDirection: number[] | null = null;
      if (arrowPosPx) {
        for (let j = 0; j < this.arrowCandidates.length; j += 1) {
          const hotSpotPosPx2 = this.arrowCandidates[j].positionPx;
          if (hotSpotPosPx2 && !this.arrowCandidates[j].obstructed) {
            const dist = Utils.getVec2Length(
              Utils.getVec2([arrowPosPx.x, arrowPosPx.y], [hotSpotPosPx2.x, hotSpotPosPx2.y])
            );
            if (dist < closestDist) {
              closestDist = dist;
              closestHs = this.arrowCandidates[j];
              closestDirection = Utils.getVec2Normalized(
                Utils.getVec2([arrowPosPx.x, arrowPosPx.y], [hotSpotPosPx2.x, hotSpotPosPx2.y])
              );
            }
          }
        }
      }

      if (closestHs !== null) {
        this.arrowCandidates[i].closestHsToArrow = closestHs;
        this.arrowCandidates[i].closestHsToArrowDistance = closestDist;
        this.arrowCandidates[i].closestHsToArrowDirection = closestDirection;
      }
    }

    this.arrowCandidates.sort((a, b) => Math.abs(a.drawingDeterminantAngle) - Math.abs(b.drawingDeterminantAngle));
    this.arrowCandidates.sort((a, b) => a.drawingDeterminantDist - b.drawingDeterminantDist);

    const anglesUsed: number[] = [];
    let numDrawn = 0;
    for (let i = 0; i < this.arrowCandidates.length; i += 1) {
      this.arrowCandidates[i].invisibleHotSpot = false;

      const hotSpotPosPx = this.arrowCandidates[i].positionPx;
      if (hotSpotPosPx) {
        let rotation = Number.POSITIVE_INFINITY;
        let diffXHsToCenterPx = Number.POSITIVE_INFINITY;
        let diffYHsToCenterPx = Number.POSITIVE_INFINITY;
        let cCenterXPx = Number.POSITIVE_INFINITY;
        let cCenterYPx = Number.POSITIVE_INFINITY;
        let distFromHsToCenterPx = Number.POSITIVE_INFINITY;
        let distFromArrowToCenterPx = Number.POSITIVE_INFINITY;
        let arrowXClip = Number.POSITIVE_INFINITY;
        let arrowYClip = Number.POSITIVE_INFINITY;
        let arrowRotation = Number.POSITIVE_INFINITY;
        let arrowPx = [0, 0];

        // angle::floor position
        if ((!this.outside && !useScreenPosForInside) || (this.outside && !useScreenPosForOutside)) {
          const dx = this.arrowCandidates[i].position[0] - this.tourConfig.scenes[this.currentPanoKey].camera[0];
          const dy = this.arrowCandidates[i].position[1] - this.tourConfig.scenes[this.currentPanoKey].camera[1];
          const angleBonus = this.hotSpotProgram3D.yaw;

          rotation = (Math.PI / 2 - Math.atan2(dy, dx) + angleBonus) % (Math.PI * 2);
          rotation = rotation > 0 ? rotation - Math.PI * 2 : rotation;

          // This angle is in the 3D space and without any zoom-in it looks correct when given to the arrows,
          // but it must be reduced when zoomed in.
          rotation += Math.PI; // shift so that "0" is middle
          rotation *= zoom;
          rotation -= Math.PI;
          arrowRotation = rotation;

          // arrow clipspace positions
          const rr = rotation - Math.PI / 2;
          arrowXClip = (Math.cos(rr) * diameter * circleWidening) / this.hotSpotProgram3D.screenAspectRatio;
          arrowYClip = Math.sin(rr) * diameter - 1 - circleYOffset;

          // arrow pixel positions
          const arrowXPx = linearScale(arrowXClip, [-1, 1], [0, rect.width], false);
          const arrowYPx = linearScale(arrowYClip, [-1, 1], [rect.height, 0], false);
          arrowPx = [arrowXPx, arrowYPx];
        }

        // angle::screen position
        if ((!this.outside && useScreenPosForInside) || (this.outside && useScreenPosForOutside)) {
          // calculate angle from bottom center of the screen to the hotspot
          cCenterXPx = rect.width / 2;
          cCenterYPx = rect.height - circleYOffset * rect.height; // circle offset is in clipspace, need to convert it to pixels
          diffXHsToCenterPx = hotSpotPosPx.x - cCenterXPx;
          diffYHsToCenterPx = hotSpotPosPx.y - cCenterYPx;
          rotation = (Math.PI / 2 - Math.atan2(diffYHsToCenterPx, diffXHsToCenterPx)) % (Math.PI * 2);
          rotation = rotation > 0 ? rotation - Math.PI * 2 : rotation;

          // arrow clipspace positions
          const rr = rotation - Math.PI / 2;
          arrowXClip = (Math.cos(rr) * diameter * circleWidening) / this.hotSpotProgram3D.screenAspectRatio;
          arrowYClip = Math.sin(rr) * diameter - 1 - circleYOffset;

          // arrow pixel positions
          const arrowXPx = linearScale(arrowXClip, [-1, 1], [0, rect.width], false);
          const arrowYPx = linearScale(arrowYClip, [-1, 1], [rect.height, 0], false);
          arrowPx = [arrowXPx, arrowYPx];

          distFromHsToCenterPx = Utils.getVec2Length([diffXHsToCenterPx, diffYHsToCenterPx]);
          distFromArrowToCenterPx = Utils.getVec2Length(Utils.getVec2(arrowPx, [cCenterXPx, cCenterYPx]));

          // additional rotation: rotate arrow so that it points to the HS (otherwise it points from the circle origin to the HS - near the extremes, they visually differ)
          if (additionalRotation) {
            const dx = hotSpotPosPx.x - arrowXPx;
            const dy = hotSpotPosPx.y - arrowYPx;
            arrowRotation = (Math.PI / 2 - Math.atan2(dy, dx)) % (Math.PI * 2);
            arrowRotation = arrowRotation > 0 ? arrowRotation - Math.PI * 2 : arrowRotation;

            const angleFromCenter = Math.abs(rotation + Math.PI); // 0° is up and 90° is near the bottom of the screen (in RAD ofc)
            const percentageFromCenter = angleFromCenter / (Math.PI / 2);

            // the closer to the side the more use the additional rotation, the closer to the center the more use the rotation from the circle origin
            arrowRotation = rotation * (1 - percentageFromCenter) + arrowRotation * percentageFromCenter;
          } else {
            arrowRotation = rotation;
          }
        }

        this.arrowCandidates[i].drawingDeterminantAngle = rotation;

        // don't draw another arrow with similar angle
        let angleFree = true;
        for (let j = 0; j < anglesUsed.length; j += 1) {
          const d = Math.abs(anglesUsed[j] - rotation);
          const angleFromCenter = Math.abs(rotation + Math.PI); // 0° is up and 90° is near the bottom of the screen (in RAD ofc)
          const strongerAtSides = linearScale(angleFromCenter, [0, Math.PI / 2], [1, 2]);
          const minAngleBetweenDynamic = minAngleBetween * strongerAtSides;
          if (d < (minAngleBetweenDynamic * Math.PI) / 180) {
            angleFree = false;
            break;
          }
        }

        // filter aut obviously invisible arrows (under the horizon) by angle
        if (angleFree) {
          const maxSideAngle = 1.91; // ~110°
          // angle on the big circle , not too good with different aspect resolutions (arrow ico sizes)
          const angleFromMid = Math.abs(rotation + Math.PI);
          if (angleFromMid > maxSideAngle) {
            angleFree = false;
          }
        }

        // don't draw arrows too near to the bottom of the screen, counting pixels
        if (angleFree) {
          const clipY = arrowYClip + 0.02 + (window.devicePixelRatio > 1 ? 0.01 : 0);
          const py = rect.height - ((clipY + 1) / 2) * rect.height;
          const distanceToBottom = rect.height - py - this.arrowRadius;
          if (py > 0 && distanceToBottom < distanceToBottomToFade) {
            angleFree = false;
          }
          // if (this.arrowCandidates[i].target.includes('23'))
          //   console.log('distanceToBottom=', distanceToBottom, 'r', this.arrowRadius, 'arrowYClip', arrowYClip);
        }

        // non-retina ekrāniem atkarībā no ekrāna y izmēra mainās arī distanceToBottomToFade
        // tur kaut kādu lerpiņu vajag, pārējiem izskatās norm

        // Proximity fade.
        // Fade if too close to hotspot (only if using screen angles math and if hotspot is visible)
        this.arrowCandidates[i].drawArrowFadeProximityTTL = 100;

        let distanceToHs = this.arrowCandidates[i].closestHsToArrowDistance;
        const directionToHs = this.arrowCandidates[i].closestHsToArrowDirection;
        if (distanceToHs && directionToHs) {
          const startFade = proximityFadeDistance * this.hotSpotProgram3D.actualArrowSpriteSizePx;
          const finishFade = proximityFadeDistance2 * this.hotSpotProgram3D.actualArrowSpriteSizePx;

          // directionToHs[0] is x component of direction vector
          // modDistanceToHs makes sure the distance to hotspot is modified smaller when the arrow is coming from sides, so that fade comes sooner
          const modDistanceToHs = linearScale(Math.abs(directionToHs[0]), [0, 1], [1, 0.75]);
          distanceToHs *= modDistanceToHs;

          if (distanceToHs < startFade) {
            const d = linearScale(distanceToHs, [startFade, finishFade], [100, 0]);
            const p = d < proximityFadeThreshold ? 0 : d;
            this.arrowCandidates[i].drawArrowFadeProximityTTL = p;
            if (d > 1) {
              this.arrowCandidates[i].drawArrowSlotDelayTTL = 100;
            }
          }
        }

        // fade if inside the circle (only for screen angles)
        let f = this.arrowCandidates[i].drawArrowFadeCenterTTL;
        if (distFromHsToCenterPx < distFromArrowToCenterPx) {
          // f -= fadeOutSpeed; // timed fade
          f = 0; // insta fade
        } else {
          f = 100;
          // f += fadeInSpeed;
        }

        this.arrowCandidates[i].drawArrowFadeCenterTTL = Utils.clamp(f, 0, 100);
        this.arrowCandidates[i].drawArrowSlotDelayTTL -= slotDelaySpeed * (this.animator?.speedScale ?? 1);
        if (this.arrowCandidates[i].drawArrowSlotDelayTTL > 0 && this.arrowCandidates[i].drawArrowSlotDelayTTL < 99) {
          this.hotSpotProgram3D.emit('render'); // need to re-render to continue to count this TTL (ony if in 0..99 range)
        }

        if (angleFree && numDrawn < numArrows) {
          // first n arrows draw full strength opacity
          this.arrowCandidates[i].drawArrowFadeOutTTL = 100;
          this.arrowCandidates[i].drawArrowFadeInTTL += fadeInSpeed * (this.animator?.speedScale ?? 1);

          // don't include arrows faded out by proximity to their HS's or because they are inside the circle
          // but mark ange as used if this arrow has proximity delay going
          if (
            this.arrowCandidates[i].drawArrowSlotDelayTTL > 0 ||
            (this.arrowCandidates[i].drawArrowFadeProximityTTL > 0 &&
              this.arrowCandidates[i].drawArrowFadeCenterTTL > 0)
          ) {
            anglesUsed.push(rotation);
            numDrawn += 1;
          }
        } else {
          this.arrowCandidates[i].drawArrowFadeInTTL = 0;
        }

        this.arrowCandidates[i].drawArrowFadeOutTTL -= fadeOutSpeed * (this.animator?.speedScale ?? 1);

        let fade = Utils.clamp(
          this.arrowCandidates[i].drawArrowFadeInTTL > 0
            ? this.arrowCandidates[i].drawArrowFadeInTTL
            : this.arrowCandidates[i].drawArrowFadeOutTTL,
          0,
          100
        );
        fade = Math.min(fade, this.arrowCandidates[i].drawArrowFadeCenterTTL); // force center fade on

        if (fade < 100 && fade > 0) {
          // this fading anim will need to be recalculated (and rendered) for next few frames until it fades completely
          this.hotSpotProgram3D.emit('render');
        }

        fade = Math.min(fade, this.arrowCandidates[i].drawArrowFadeProximityTTL); // force proximity fade on  |  this fade does not need to be re-rendered, as it changed depending on the distance
        let alpha = fade / 100;

        if (alpha > 0.05) {
          alpha = this.arrowCandidates[i].hover ? 1 : alpha; // force full opacity for arrow icons on hover (only if not already too faded)
        }

        const iconAsset = this.arrowCandidates[i].hover
          ? this.hotSpotProgram3D.iconsAssets.arrowHover
          : this.hotSpotProgram3D.iconsAssets.arrow;

        this.arrowCandidates[i].arrow = null;
        if (iconAsset.texture) {
          const arrow = {
            x: arrowXClip,
            y: arrowYClip,
            scale,
            alpha,
            rotation: arrowRotation,
            texture: iconAsset.texture,
          };

          this.arrows.push(arrow); // for drawing
          this.arrowCandidates[i].arrow = arrow; // for further comparison
          this.arrowCandidates[i].arrowVisible = alpha > 0.05;
        }
      }
    }
  }

  getHotSpotPixelAndClipSpacePos(
    c1: number[],
    c2: number[],
    rect: DOMRect
  ): { pixelPos: Pos<Pixel> | null; clipSpacePos: Pos<ClipSpace> | null; distanceFromCamOnFloor: number } {
    // this math works best if hotspots are below camera (lets pretend it is for a moment)
    let aboveCamera = false;
    const cc2 = [...c2];
    if (cc2[2] < 0) {
      aboveCamera = true;
      cc2[2] *= -1;
    }

    cc2[2] = Math.abs(cc2[2]) < 0.01 ? 0.01 : cc2[2]; // this trigonometry doesn't like 0

    // @note -- using hotspot as camera position not actual [debug] camera, so no cam movement supported yet, apart from pano switching
    const posRelativeToCamXY = [c1[0] - cc2[0], c1[1] - cc2[1]];
    const hpYaw = Utils.getYawAngleFromV1ToV2(posRelativeToCamXY, [0, 0]) + Math.PI / 2; // + 90° cos camera is turned sideways 90°

    const distanceFromCamOnFloor = Utils.getTriangleHypotenuse(posRelativeToCamXY[0], posRelativeToCamXY[1]);
    let hpPitch = Math.atan(distanceFromCamOnFloor / cc2[2]); // dist on the floor / camera height
    hpPitch -= Math.PI / 2; // + 90°,otherwise 0 is to the nadir, we need 0 on the horizon
    hpPitch = aboveCamera ? -hpPitch : hpPitch; // flip back for higher-up hotspots

    const pixelPos = cameraToPixel(
      { pitch: hpPitch, yaw: hpYaw },
      {
        pitch: this.hotSpotProgram3D.pitch,
        yaw: this.hotSpotProgram3D.yaw,
        fov: getRenderedFov(this.hotSpotProgram3D.fov, rect),
      },
      rect
    );

    let clipSpacePos;
    if (pixelPos) {
      clipSpacePos = {
        x: linearScale(pixelPos.x, [0, rect.width], [-1, 1], false),
        y: linearScale(pixelPos.y, [0, rect.height], [1, -1], false),
      };
    }

    return { pixelPos, clipSpacePos, distanceFromCamOnFloor };
  }

  calculateHotSpotScreenPositions(): void {
    if (this.tourConfig) {
      const rect = this.hotSpotProgram3D.boundingRect;
      for (let i = 0; i < this.hotSpots.length; i += 1) {
        this.hotSpots[i].positionPx = null;
        this.hotSpots[i].clipSpacePos = null;

        const c1 = this.tourConfig.scenes[this.currentPanoKey].camera;
        const c2 = this.hotSpots[i].position;
        const { pixelPos, clipSpacePos } = this.getHotSpotPixelAndClipSpacePos(c1, c2, rect);

        if (pixelPos) {
          this.hotSpots[i].positionPx = pixelPos;
          this.hotSpots[i].clipSpacePos = clipSpacePos;

          // get N points in the circle around the hotspot on the floor, and calculate their screen positions
          if (this.hotSpots[i].type === 'normal') {
            this.hotSpots[i].clipSpacePosAround = [];
            const posArray = this.hotSpots[i].clipSpacePosAround;
            const numPoints = 10; // good enough
            const radius = this.hotSpots[i].proximityScale * this.hotSpotProgram3D.hotSpotScaleModifier * 27;
            const angleStep = (2 * Math.PI) / numPoints;
            for (let k = 0; k < numPoints; k += 1) {
              const angle = k * angleStep;
              const x = c2[0] + radius * Math.cos(angle);
              const y = c2[1] + radius * Math.sin(angle);
              const z = c2[2];
              const { clipSpacePos: pos } = this.getHotSpotPixelAndClipSpacePos(c1, [x, y, z], rect);
              if (pos && posArray) {
                posArray.push(pos);
              }
            }
          }
        }

        const arrow = this.hotSpots[i].arrow;
        if (arrow) {
          const w = this.arrowWidth;
          this.hotSpots[i].arrowClipSpaceSize = { width: w, height: w * this.hotSpotProgram3D.screenAspectRatio };

          // caclulate arrow position even if it won't be drawn
          this.hotSpots[i].arrowClipSpacePos = {
            x: arrow.x,
            y: arrow.y + 0.02,
          };

          this.hotSpots[i].arrowPosPx = {
            x: linearScale(arrow.x, [-1, 1], [0, rect.width]),
            y: linearScale(arrow.y + 0.02, [1, -1], [0, rect.height]),
          };
        }
      }

      // @todo -- get rid of debugparams
      // @todo -- this math could be done when creating HS for this pano and stored in "spriteScale" or smtn not recalculated every frame
      const scaleA = RendererDebug.getDebugParamFloat('spriteScaleA', 0.075);
      const scaleB = RendererDebug.getDebugParamFloat('spriteScaleB', 0.045);
      const rescaleDistA = RendererDebug.getDebugParamFloat('spriteRescaleDistA', 1) * 100; // debugparam is in meters, but we use cm
      const rescaleDistB = RendererDebug.getDebugParamFloat('spriteRescaleDistB', 8) * 100;

      this.nonDirectHotSpots = [];
      for (let i = 0; i < this.hotSpots.length; i += 1) {
        const hotSpot3D = this.hotSpots[i];
        const clipspacePos = hotSpot3D.clipSpacePos;
        if (clipspacePos && hotSpot3D.positionPx && hotSpot3D.type !== 'normal') {
          let scale = 1;
          if (hotSpot3D.distanceToCamera < rescaleDistA) {
            scale = scaleA;
          } else if (hotSpot3D.distanceToCamera > rescaleDistB) {
            scale = scaleB;
          } else {
            scale = linearScale(hotSpot3D.distanceToCamera, [rescaleDistA, rescaleDistB], [scaleA, scaleB]);
          }

          const w = this.hotSpotProgram3D.actualArrowSpriteSize * 2 * scale * 20;
          this.hotSpots[i].clipSpaceSize = { width: w, height: w * this.hotSpotProgram3D.screenAspectRatio };

          const alpha = this.hotSpots[i].obstructedByHsTTL / 100;

          if (alpha > 0) {
            const texture = this.hotSpotProgram3D.getTextureForHotSpot(i);
            if (texture) {
              this.nonDirectHotSpots.push({
                x: clipspacePos.x,
                y: clipspacePos.y,
                scale,
                alpha,
                rotation: Math.PI,
                texture,
              });

              // after giving correct coords to sprite draw, move them for better mouse hovering performance
              // move the hover zone towards screen center
              const offX = clipspacePos.x / 40;
              const offY = clipspacePos.y / 40;
              clipspacePos.x -= offX;
              clipspacePos.y -= offY;
            }
          }
        }
      }
    }

    this.checkOverlaps();
  }

  checkOverlaps(startup = false): void {
    if (startup) {
      for (let i = 0; i < this.hotSpots.length; i += 1) {
        this.hotSpots[i].obstructedByHs = false;
        this.hotSpots[i].obstructedByHsTTL = 100;
      }
      return;
    }

    const rect = this.hotSpotProgram3D.boundingRect;
    const screenSize = { width: rect.width, height: rect.height };

    for (let i = 0; i < this.hotSpots.length; i += 1) {
      this.hotSpots[i].obstructedByHs = false;
    }

    for (let i = 0; i < this.hotSpots.length; i += 1) {
      if (this.hotSpots[i].type === 'normal' || !this.hotSpots[i].clipSpacePos) continue;

      for (let j = 0; j < i; j += 1) {
        // look at all previous hotspots (that are closer to camera than this [i])
        if (this.hotSpots[j].type === 'normal') continue;

        const radiusI = ((this.hotSpots[i].clipSpaceSize.width / 2) * screenSize.width) / 2;
        const pxI = (((this.hotSpots[i].clipSpacePos?.x || -2) + 1) / 2) * screenSize.width;
        const pyI = screenSize.height - ((this.hotSpots[i].clipSpacePos?.y || -2 + 1) / 2) * screenSize.height;

        const radiusJ = ((this.hotSpots[j].clipSpaceSize.width / 2) * screenSize.width) / 2;
        const pxJ = (((this.hotSpots[j].clipSpacePos?.x || -2) + 1) / 2) * screenSize.width;
        const pyJ = screenSize.height - ((this.hotSpots[j].clipSpacePos?.y || -2 + 1) / 2) * screenSize.height;

        const distance = Math.sqrt((pxI - pxJ) ** 2 + (pyI - pyJ) ** 2);
        if (distance < radiusI + radiusJ) {
          this.hotSpots[i].obstructedByHs = true;
        }
      }
    }

    const animSpeed = RendererDebug.getDebugParamFloat('hsObstructionFadeSpeed', 4);
    let animating = false;
    for (let i = 0; i < this.hotSpots.length; i += 1) {
      if (this.hotSpots[i].obstructedByHs && this.hotSpots[i].obstructedByHsTTL > 0) {
        this.hotSpots[i].obstructedByHsTTL -= animSpeed * (this.animator?.speedScale ?? 1);
        animating = true;
      }
      if (!this.hotSpots[i].obstructedByHs && this.hotSpots[i].obstructedByHsTTL < 100) {
        this.hotSpots[i].obstructedByHsTTL += animSpeed * (this.animator?.speedScale ?? 1);
        animating = true;
      }
    }
    if (animating) {
      this.hotSpotProgram3D.emit('render');
    }
  }

  handleHotSpotAction(action: HotSpotEditAction, nextSpotConfig: HotSpot3DConfig, targetSceneKey: string) {
    if (!this.hotSpotData[targetSceneKey]) this.hotSpotData[targetSceneKey] = [];

    switch (action) {
      case 'add': {
        this.hotSpotData[targetSceneKey].push(nextSpotConfig);
        break;
      }

      case 'update': {
        const configIndex = this.hotSpotData[targetSceneKey].findIndex(
          (hotSpotConfig) => hotSpotConfig.id === nextSpotConfig.id
        );

        if (configIndex >= 0) {
          this.hotSpotData[targetSceneKey][configIndex] = nextSpotConfig;
        }

        break;
      }

      case 'delete': {
        this.hotSpotData[targetSceneKey] = this.hotSpotData[targetSceneKey].filter(
          (hotSpotConfig) => hotSpotConfig.id !== nextSpotConfig.id
        );

        break;
      }

      default:
        // eslint-disable-next-line no-console
        console.warn('Unknown HotSpotEditAction:', action);
        break;
    }

    this.createHotSpots();
    this.hotSpotProgram3D.emit('render');
  }
}

export default HotSpot3DLogic;
