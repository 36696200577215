import { SVGProps } from "react";

const ControllerOffIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 38 37"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <path
      d="M16.566 14.434a.802.802 0 0 0-1.132 1.132l1.252 1.252a2.966 2.966 0 0 0-.986 1.929l-.7 4.92v.166c0 .31.129.607.359.825.23.219.541.342.866.342.336 0 .644-.133.875-.333l2.079-2h3.356l4.899 4.899a.802.802 0 0 0 1.132-1.132l-12-12Zm2.636 4.899-.702-.702v.036h-1.4v.666h1.4v1.334h.7v-1.334h.002Zm9.439 5.325a.792.792 0 0 1-.053.048L19.881 16h5.269c1.652 0 3.003 1.207 3.15 2.747l.7 4.92v.166c0 .31-.129.607-.359.825Zm-3.491-7.325a.537.537 0 0 0-.371.147.487.487 0 0 0 0 .707.536.536 0 0 0 .371.146.536.536 0 0 0 .371-.146.487.487 0 0 0 0-.707.537.537 0 0 0-.371-.147ZM23.925 18.5a.54.54 0 0 0-.371.146.487.487 0 0 0 0 .708.54.54 0 0 0 .742 0 .487.487 0 0 0 0-.708.54.54 0 0 0-.371-.146Zm2.45 0a.54.54 0 0 0-.371.146.487.487 0 0 0 0 .708.54.54 0 0 0 .742 0 .487.487 0 0 0 0-.708.54.54 0 0 0-.371-.146Zm-1.225 1.167a.536.536 0 0 0-.371.146.487.487 0 0 0 0 .707.537.537 0 0 0 .742 0 .487.487 0 0 0 0-.707.536.536 0 0 0-.371-.146Z"
      transform="translate(-40 -37.867) scale(2.66667)"
    />
  </svg>
);

export default ControllerOffIcon;
