var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getCookiePreferences, getOrCreateSessionId, MakeReactive, ReactiveBase } from '@g360/vt-utils';
import * as Sentry from '@sentry/react';
import { genericGatedTourErrorMessage, readVerificationTokenFromCookies, requestEmailApi, verifyCodeApi, verifyTokenApi, } from '../modules/gatedTour/utils/gatedTourUtils';
import VerificationFailedError from '../modules/gatedTour/utils/VerificationFailedError';
const INITIAL_RESEND_CODE_COOLDOWN = 10000;
const DEFAULT_RESEND_CODE_COOLDOWN = 60000;
const requestEmailSentryMessagePrefix = 'GatedTourService -> requestEmail -> ';
const verifyCodeSentryMessagePrefix = 'GatedTourService -> verifyCode -> ';
const verifyTokenSentryMessagePrefix = 'GatedTourService -> verifyToken -> ';
class GatedTourServiceBase extends ReactiveBase {
    constructor() {
        super();
        /** Indicates if verification is needed or not. */
        this.isVerificationNeeded = false;
        /** An email address to which a verification code will be sent. */
        this.email = '';
        /** A name and a surname. */
        this.fullName = '';
        /** A phone number with +, country calling code and national number. */
        this.fullPhoneNumber = '';
        /** a country calling code. */
        this.callingCode = '';
        /** Indicates if email consent has been given or not. */
        this.isEmailConsentAccepted = false;
        /** A verification code to verify the gated tour. */
        this.verificationCode = '';
        /** A verification token to read/store in the cookies to automatically verify a tour if it was verified before already. */
        this.verificationToken = '';
        /** Indicates if the gated tour has been explicitly verified or not. */
        this.isVerified = false;
        /** An email address used in the last email request. */
        this.requestEmailCooldownEmail = '';
        /** Current cooldown for requesting an email with a verification code. */
        this.requestEmailCooldown = 0;
        /** Indicates if verification token has been read from cookies or not. */
        this.isVerificationTokenCookieRead = false;
        /** The last starting point of the cooldown. */
        this.requestEmailCooldownMax = 0;
        /** A timestamp of when an email was last requested. */
        this.requestEmailCooldownTimestamp = 0;
        this.onTransitionBlocked = this.onTransitionBlocked.bind(this);
    }
    /**
     * Sets the engine instance. Passes `isVerificationNeeded` and `onTransitionBlockedInEngine` callback to the engine.
     * @param engine - `Engine`: The engine instance.
     * @returns `void`
     */
    setEngine(engine) {
        this.engine = engine;
        engine.setIsVerificationNeeded(this.isVerificationNeeded);
    }
    /**
     * Sets the tour config service instance.
     * @param tourConfigService - `TourConfigService`: The tour config service instance.
     * @returns `void`
     */
    setTourConfigService(tourConfigService) {
        this.tourConfigService = tourConfigService;
    }
    /**
     * Sets the layout service instance.
     * @param layoutService - `LayoutService`: The layout service instance.
     * @returns `void`
     */
    setLayoutService(layoutService) {
        this.layoutService = layoutService;
    }
    init() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            this.watch(() => {
                var _a;
                (_a = this.engine) === null || _a === void 0 ? void 0 : _a.setIsVerificationNeeded(this.isVerificationNeeded);
            });
            (_a = this.engine) === null || _a === void 0 ? void 0 : _a.subscribe('scene.transition.gated', this.onTransitionBlocked);
            if (this.tourConfigService && this.layoutService) {
                this.isVerificationNeeded =
                    this.layoutService.appContext === 'standalone' &&
                        this.tourConfigService.gatedTourEnabled &&
                        this.tourConfigService.features.gatedTour;
            }
            yield this.readVerificationTokenOnStartup();
        });
    }
    /**
     * Checks if the gated tour is verified. If not verified and verification is needed, it opens the modal.
     * @returns `boolean`: `true` if the verification is successful (or not needed) and the transition should be allowed, `false` otherwise.
     */
    checkVerification() {
        if (!this.isVerificationNeeded || !this.layoutService)
            return true;
        this.layoutService.isGatedTourModalOpen = true;
        return false;
    }
    /**
     * Reads the verification token from the cookies if not yet done and not verified.
     *
     * It is called in the gated tour modal once performance cookies are allowed.
     * @returns `void`
     */
    readVerificationToken() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isVerified || this.isVerificationTokenCookieRead)
                return;
            this.isVerificationTokenCookieRead = true;
            const newVerificationToken = readVerificationTokenFromCookies();
            this.verificationToken = newVerificationToken;
            try {
                yield this.verifyToken();
            }
            catch (error) {
                // Don't show any errors to users, it can fail silently, and the user will have to go through the verification process normally
            }
        });
    }
    /**
     * If verification is needed, requests an email with a verification code by calling the backend API endpoint.
     * @returns `Promise<boolean>`: A promise that resolves if the email was requested successfully,if the verification isn't needed or if already verified.
     * @throws `Error`: If the email request failed due to incorrect/insufficient data.
     */
    requestEmail() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.isVerificationNeeded)
                return;
            if (this.isVerified)
                return;
            if (!this.tourConfigService) {
                Sentry.captureMessage(`${requestEmailSentryMessagePrefix}No TourConfigService`);
                throw new Error(genericGatedTourErrorMessage);
            }
            const sessionId = getOrCreateSessionId();
            const projectId = this.tourConfigService.tourConfig.projectId;
            if (!projectId) {
                Sentry.captureMessage(`${requestEmailSentryMessagePrefix}Project ID is required`);
                throw new Error(genericGatedTourErrorMessage);
            }
            if (!this.email) {
                Sentry.captureMessage(`${requestEmailSentryMessagePrefix}Email is required`);
                throw new Error(genericGatedTourErrorMessage);
            }
            if (!this.fullName) {
                Sentry.captureMessage(`${requestEmailSentryMessagePrefix}Contact name is required`);
                throw new Error(genericGatedTourErrorMessage);
            }
            if (!this.fullPhoneNumber) {
                Sentry.captureMessage(`${requestEmailSentryMessagePrefix}Phone number is required`);
                throw new Error(genericGatedTourErrorMessage);
            }
            if (!sessionId) {
                Sentry.captureMessage(`${requestEmailSentryMessagePrefix}Session ID is required`);
                throw new Error(genericGatedTourErrorMessage);
            }
            yield requestEmailApi({
                project_id: projectId,
                email: this.email,
                contact_name: this.fullName,
                phone_number: this.fullPhoneNumber,
                session_id: sessionId,
            });
            this.handleRequestEmailCooldown();
        });
    }
    /**
     * If verification is needed, initiates the verification process of the verification code by calling the backend API endpoint.
     * @returns `Promise<void>`: A promise that resolves when the verification was successful,
     * if the verification was not needed or if already verified.
     * @throws `Error`: If the verification failed due to incorrect/insufficient data.
     */
    verifyCode() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.isVerificationNeeded)
                return;
            if (this.isVerified)
                return;
            if (!this.tourConfigService) {
                Sentry.captureMessage('Gated Tour -> Request Email -> No TourConfigService');
                throw new Error(genericGatedTourErrorMessage);
            }
            if (!this.tourConfigService.gatedTourEnabled || !this.tourConfigService.features.gatedTour) {
                return;
            }
            const projectId = this.tourConfigService.tourConfig.projectId;
            if (!projectId) {
                Sentry.captureMessage(`${verifyCodeSentryMessagePrefix}Project ID is required`);
                throw new Error(genericGatedTourErrorMessage);
            }
            if (!this.email) {
                Sentry.captureMessage(`${verifyCodeSentryMessagePrefix}Email is required`);
                throw new Error(genericGatedTourErrorMessage);
            }
            if (!this.verificationCode) {
                Sentry.captureMessage(`${verifyCodeSentryMessagePrefix}Verification code is required`);
                throw new Error(genericGatedTourErrorMessage);
            }
            if (this.verificationCode.length !== 5)
                throw new VerificationFailedError();
            const newVerificationToken = yield verifyCodeApi({
                project_id: projectId,
                email: this.email,
                verification_code: Number(this.verificationCode),
            });
            this.isVerified = true;
            this.verificationToken = newVerificationToken;
        });
    }
    /**
     * If not verified, initiates the verification process of the verification token and calls the backend API endpoint.
     * @returns `Promise<void>`: A promise that resolves when the verification  was successful,
     * if the verification is not needed, if already verified.
     * @throws `Error`: If the verification failed due to incorrect/insufficient data.
     */
    verifyToken() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.isVerificationNeeded)
                return;
            if (this.isVerified)
                return;
            if (!this.verificationToken) {
                throw new Error(genericGatedTourErrorMessage);
            }
            if (!this.tourConfigService) {
                Sentry.captureMessage(`${verifyTokenSentryMessagePrefix}No TourConfigService`);
                throw new Error(genericGatedTourErrorMessage);
            }
            const projectId = this.tourConfigService.tourConfig.projectId;
            if (!projectId) {
                Sentry.captureMessage(`${verifyTokenSentryMessagePrefix}Project ID is required`);
                throw new Error(genericGatedTourErrorMessage);
            }
            yield verifyTokenApi({ project_id: projectId, verification_token: this.verificationToken });
            this.isVerified = true;
        });
    }
    readVerificationTokenOnStartup() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.tourConfigService)
                return;
            if (this.isVerificationTokenCookieRead)
                return;
            const currentPreferences = getCookiePreferences('vt_consentid');
            if (!currentPreferences || !currentPreferences.isPerformance) {
                if (this.isVerificationNeeded)
                    this.resetInitialView();
                return;
            }
            this.isVerificationTokenCookieRead = true;
            const newVerificationToken = readVerificationTokenFromCookies();
            this.verificationToken = newVerificationToken;
            try {
                yield this.verifyToken();
            }
            catch (error) {
                // Don't show any errors to users, it can fail silently, and the user will have to go through the verification process normally
            }
            if (!this.isVerified && this.isVerificationNeeded)
                this.resetInitialView();
        });
    }
    resetInitialView() {
        var _a;
        const { tourConfigService } = this;
        if (!tourConfigService || !tourConfigService.tourConfig)
            return;
        (_a = this.engine) === null || _a === void 0 ? void 0 : _a.resetInitialSceneConfig();
    }
    // For resend code 60 second cooldown timer
    // it uses `Date.now()` instead of just ticking down a variable every second with setInterval
    // because browsers when minimized/tab switched can suspend tabs and severely throttle timers
    /**
     * Initiates a cooldown timer for the resend code feature.
     *
     * The first email request has a cooldown of `10` seconds, while the subsequent ones have a cooldown of `60` seconds.
     *
     * If email changes, the cooldown is reset.
     *
     * If email changes again to one that was previously used, it still counts as a first request, so cooldown is `10` seconds.
     *
     * It uses `Date.now()` instead of just ticking down a variable every second using `setInterval`
     * because browsers when minimized/tab switched can suspend tabs and severely throttle timers.
     *
     * @returns `void`
     */
    handleRequestEmailCooldown() {
        this.requestEmailCooldownMax =
            this.requestEmailCooldownEmail === this.email ? DEFAULT_RESEND_CODE_COOLDOWN : INITIAL_RESEND_CODE_COOLDOWN;
        this.requestEmailCooldownEmail = this.email;
        this.requestEmailCooldownTimestamp = Date.now();
        this.requestEmailCooldown = this.requestEmailCooldownMax / 1000;
        if (this.requestEmailCooldownInterval)
            return;
        this.requestEmailCooldownInterval = setInterval(() => {
            const now = Date.now();
            const timestampDelta = now - this.requestEmailCooldownTimestamp;
            this.requestEmailCooldown = Math.ceil((this.requestEmailCooldownMax - timestampDelta) / 1000);
            if (this.requestEmailCooldown < 0) {
                clearInterval(this.requestEmailCooldownInterval);
                this.requestEmailCooldownInterval = undefined;
            }
        }, 1000);
    }
    /**
     * Callback for when the transition is blocked.
     *
     * Opens the gated tour modal if the verification is needed.
     * @returns `void`
     */
    onTransitionBlocked() {
        var _a;
        if (!this.isVerificationNeeded || !this.layoutService) {
            (_a = this.engine) === null || _a === void 0 ? void 0 : _a.setIsVerificationNeeded(this.isVerificationNeeded);
            return;
        }
        this.layoutService.isGatedTourModalOpen = true;
    }
}
export default class GatedTourService extends MakeReactive(GatedTourServiceBase, [
    'email',
    'fullName',
    'fullPhoneNumber',
    'callingCode',
    'isEmailConsentAccepted',
    'verificationCode',
    'verificationToken',
    'isVerificationNeeded',
    'requestEmailCooldownEmail',
    'requestEmailCooldown',
    'isVerified',
]) {
}
