import { SVGProps } from "react";

const SuccessIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <circle
      cx="12"
      cy="12"
      r="11"
      stroke="white"
      fill="black"
      stroke-width="2"
    />
    <path
      d="M7 10L11 16L17 8"
      stroke="white"
      fill="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default SuccessIcon;
