import type { Radian } from '@g360/vt-types';
import { identityM3, m3toM4, rotateM3X, rotateM3Z } from '@g360/vt-utils';

/**
 * Get rotation matrix. Applies `rotateZ` -\> `rotateX` transformation.
 * @param rotationX - `Radian`: Angle for `rotateX` transformation.
 * @param rotationZ - `Radian`: Angle for `rotateZ` transformation.
 * @returns `number[]`: The created rotation matrix.
 */
function getRotationMatrixZX(rotationZ: Radian, rotationX: Radian): number[] {
  let modelRotationMatrix = identityM3();

  modelRotationMatrix = rotateM3Z(modelRotationMatrix, rotationZ);
  modelRotationMatrix = rotateM3X(modelRotationMatrix, rotationX);
  modelRotationMatrix = m3toM4(modelRotationMatrix);

  return modelRotationMatrix;
}

export default getRotationMatrixZX;
