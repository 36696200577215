import { loadTourJson } from '@g360/vt-utils';

import ContrastChecker from './common/ContrastChecker';
import Globals from './common/Globals';
import { TourConfigService } from './common/services/TourConfigService';
import Utils from './common/Utils';
import AutoplayController from './controllers/AutoplayController';
import EditorController from './controllers/EditorController';
import GestureController from './controllers/GestureController';
import Engine from './Engine';

// @ts-expect-error start player in development mode
if (__STANDALONE__) {
  /* eslint-disable no-console */

  console.log('Auto-starting player for development...');

  const rootElem = document.getElementById('root');

  if (rootElem) {
    loadTourJson('./tour.json', {}).then((jsonObject) => {
      if (jsonObject) {
        const canvas = document.createElement('canvas');

        canvas.style.height = '100%';
        canvas.style.width = '100%';
        canvas.style.position = 'absolute';
        canvas.style.top = '0px';
        canvas.style.left = '0px';
        canvas.style.zIndex = '100';
        canvas.style.backgroundColor = 'rgba(255, 0, 0, 0.1)';
        rootElem.appendChild(canvas);

        const engineProps = {
          canvas,
          tourConfig: jsonObject,
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const engine = new Engine(engineProps);
        // console.log(engine);
      }
    });
  } else {
    console.error('Root element not found, check you template!');
  }
}

export { AutoplayController, ContrastChecker, EditorController, GestureController, Globals, TourConfigService, Utils };
export default Engine;
