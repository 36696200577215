import { useEffect, useState } from 'react';
const useTourEventSource = (engine) => {
    const [tourEventSource, setTourEventSource] = useState(engine.getTourEventSource());
    useEffect(() => {
        const subscription = engine.subscribe('tour.eventSource.change', setTourEventSource);
        setTourEventSource(engine.getTourEventSource());
        return () => subscription.unsubscribe();
    }, [engine]);
    return tourEventSource;
};
export default useTourEventSource;
