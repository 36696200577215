import { useOnClickOutside } from "hooks";
import { FC, useRef } from "react";
import { classNamesUtil } from "utils/classNamesUtil";

interface DropdownProps {
  bntTitle?: string;
  icon?: JSX.Element;
  customNames?: string;
  isDropdownOpen: boolean;
  isDropdownArrow?: boolean;
  dropDownWidth?: "default" | "lg" | "full";
  bgColor?: "white" | "black";
  buttonStyle?: "square";
  children: JSX.Element | JSX.Element[];
  buttonSize?: "sm" | "md" | "lg" | "full" | "default";
  handleDropdownClose: () => void;
  handleDropdownButtonClick: () => void;
}

const Dropdown: FC<DropdownProps> = ({
  icon,
  bntTitle,
  children,
  customNames,
  isDropdownOpen,
  bgColor = "white",
  isDropdownArrow = true,
  buttonSize = "default",
  dropDownWidth = "default",
  handleDropdownClose,
  handleDropdownButtonClick,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside<HTMLDivElement>(dropdownRef, () => {
    handleDropdownClose();
  });

  return (
    <div
      className={classNamesUtil(
        customNames ? customNames : "",
        "flex items-center justify-center w-full"
      )}
      ref={dropdownRef}
    >
      <div
        className={classNamesUtil(
          buttonSize === "full" ? "w-full" : "",
          "inline-block text-left"
        )}
      >
        <span
          className={classNamesUtil(
            buttonSize === "full" ? "w-full" : "",
            "rounded-md shadow-sm"
          )}
        >
          <button
            className={classNamesUtil(
              buttonSize === "default" ? "h-10 w-10" : "",
              buttonSize === "full" ? "w-full h-10 px-3" : "",
              bgColor === "white"
                ? "bg-white border-gray-300 text-gray-700"
                : "",
              bgColor === "black"
                ? "bg-gray-700 border-gray-700 text-white"
                : "",
              "flex justify-center items-center text-sm leading-5 transition duration-150 ease-in-out border rounded-md cursor-pointer focus:outline-none active:bg-gray-50 truncate group"
            )}
            type="button"
            onClick={() => {
              handleDropdownButtonClick();
            }}
          >
            <span className="w-full ml-2 font-bold text-left truncate">
              {bntTitle}
            </span>

            {icon && icon}
            {isDropdownArrow && (
              <svg
                className={`${
                  isDropdownOpen ? "-rotate-180" : ""
                } text-lg transition duration-300 ease-in-out w-5 h-5 ml-2 -mr-1 text-giraffe-gray-lightest group-hover:text-giraffe-dark`}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            )}
          </button>
        </span>
        <div
          className={`${
            isDropdownOpen
              ? "opacity-100 scale-100 visible translate-x-0"
              : "invisible opacity-0"
          } transition-all w-full duration-300 -translate-y-2 transform-gpu`}
        >
          <div
            className={classNamesUtil(
              dropDownWidth === "full" ? "w-full" : "",
              dropDownWidth === "default" ? "w-56" : "",
              dropDownWidth === "lg" ? "w-[20.1rem]" : "",
              "absolute right-0 mt-2  origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
            )}
          >
            {/* Any content to hide and show at the touch of a button */}
            <div>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
