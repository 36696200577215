import { RoundButton, Tooltip } from "components/elements";
import { ArrowsIcon } from "components/icons";
import {
  useAppStateContext,
  useDeviceStateContext,
  useRoomContext,
} from "hooks";
import {
  LocalParticipantWrapper,
  ParticipantRoomActionsWrapper,
  RemoteParticipantsWrapper,
} from "components/wrappers";
import { useWindowDimensions } from "hooks";
import { FC, useState } from "react";
import { RemoteParticipant } from "twilio-video";
import { classNamesUtil } from "utils/classNamesUtil";

const MIN_HEIGHT = 680;

interface ParticipantGridWrapperProps {
  isMinimized: boolean;
  roomHostIdentity: string;
  inControlParticipant: string;
  remoteParticipants: RemoteParticipant[];
  toggleMinimize: () => void;
  handleSettingModal: () => void;
  handleEndSessionModal: () => void;
  handleClickControlSwitch: (identity?: string | undefined) => void;
}

const ParticipantGridWrapper: FC<ParticipantGridWrapperProps> = ({
  isMinimized,
  roomHostIdentity,
  remoteParticipants,
  inControlParticipant,
  toggleMinimize,
  handleSettingModal,
  handleEndSessionModal,
  handleClickControlSwitch,
}) => {
  const { isHost } = useAppStateContext();
  const {
    isVideoOn,
    isAudioOn,
    audioAccess,
    videoAccess,
    handleAudioToggle,
    handleVideoToggle,
    selectedAudioOutput,
  } = useDeviceStateContext();
  const { localParticipant } = useRoomContext();

  const { height } = useWindowDimensions();
  const [isLocalParticipantVisible, setIsLocalParticipantVisible] =
    useState<boolean>(false);

  const isVisibleLocalParticipant = () => {
    if (
      height < MIN_HEIGHT &&
      remoteParticipants.length === 0 &&
      !isLocalParticipantVisible
    ) {
      return true;
    }

    if (height < MIN_HEIGHT && isLocalParticipantVisible) {
      return true;
    }

    return false;
  };

  const isVisibleRemoteParticipants = () => {
    if (
      height < MIN_HEIGHT &&
      remoteParticipants.length === 0 &&
      !isLocalParticipantVisible
    ) {
      return false;
    }

    if (height < MIN_HEIGHT && !isLocalParticipantVisible) {
      return true;
    }

    return false;
  };

  const isControlButtonMargin = () => {
    if (height < MIN_HEIGHT && remoteParticipants.length !== 0) {
      return true;
    }

    return false;
  };

  return (
    <div className="mx-1.5 my-1.5 relative">
      <div
        className={classNamesUtil(
          isControlButtonMargin() ? "block" : "hidden",
          "absolute z-50 top-2 right-2"
        )}
      >
        <Tooltip
          tooltipOffset="left"
          tooltipPosition="bottom"
          tooltipText="Switch"
        >
          <RoundButton
            btnSize="sm"
            handleClick={() => setIsLocalParticipantVisible((state) => !state)}
          >
            <ArrowsIcon className="w-3.5 h-3.5 fill-white" />
          </RoundButton>
        </Tooltip>
      </div>
      <div
        className={classNamesUtil(
          isVisibleLocalParticipant()
            ? "h-0 overflow-hidden"
            : "max-h-[500rem]",
          height >= MIN_HEIGHT ? "mb-0.5" : "",
          "relative transition-all ease-in-out duration-150"
        )}
      >
        {/* Remote participants */}
        <RemoteParticipantsWrapper
          isHost={isHost}
          roomHostIdentity={roomHostIdentity}
          remoteParticipants={remoteParticipants}
          inControlParticipant={inControlParticipant}
          isControlButtonMargin={isControlButtonMargin()}
          handleClickControlSwitch={handleClickControlSwitch}
          selectedAudioOutput={selectedAudioOutput}
        />
      </div>

      <div>
        <div
          className={classNamesUtil(
            isVisibleRemoteParticipants()
              ? "h-0 overflow-hidden"
              : "max-h-[500rem]",
            "relative transition-all ease-in-out duration-150"
          )}
        >
          {/* Local participants */}
          <LocalParticipantWrapper
            localParticipant={localParticipant}
            isHost={isHost}
            roomHostIdentity={roomHostIdentity}
            remoteParticipants={remoteParticipants}
            inControlParticipant={inControlParticipant}
            isControlButtonMargin={isControlButtonMargin()}
            handleClickControlSwitch={handleClickControlSwitch}
          />
        </div>
        {/* Local participants settings */}
        <ParticipantRoomActionsWrapper
          isHost={isHost}
          isVideoOn={isVideoOn}
          isAudioOn={isAudioOn}
          isMinimized={isMinimized}
          audioAccess={audioAccess}
          videoAccess={videoAccess}
          toggleMinimize={toggleMinimize}
          handleVideoToggle={handleVideoToggle}
          handleAudioToggle={handleAudioToggle}
          handleSettingModal={handleSettingModal}
          handleEndSessionModal={handleEndSessionModal}
        />
      </div>
    </div>
  );
};

export default ParticipantGridWrapper;
