const isStringArrayEqual = (arr1: string[], arr2: string[]) => {
  return (
    arr1.length === arr2.length &&
    arr1.every(function (element, index) {
      return element === arr2[index];
    })
  );
};

export default isStringArrayEqual;
