import { Spinner } from "components/elements";
import { FC } from "react";
import { classNamesUtil } from "utils/classNamesUtil";

interface LinkButtonProps {
  text: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  type?: "button" | "submit";
  handleButtonClick: () => void;
}

const LinkButton: FC<LinkButtonProps> = ({
  text,
  isLoading,
  type = "button",
  isDisabled = false,
  handleButtonClick,
}) => {
  return (
    <button
      type={type}
      disabled={isDisabled}
      onClick={handleButtonClick}
      className={classNamesUtil(
        !isDisabled ? "text-black  border-giraffe-dark" : "",
        isDisabled ? "border-gray-300 text-gray-400 cursor-not-allowed" : "",
        "border-b-2 text-sm font-medium text-center font-primary transition-all duration-500 ease-in-out flex justify-center items-center"
      )}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <span className="w-full text-base font-semibold tracking-wide font-primary">
          {text}
        </span>
      )}
    </button>
  );
};

export default LinkButton;
