import type { Milliseconds } from '@g360/vt-types';

import type { Analytics, DebouncedFunction, ThrottledFunction } from '../..';
import debounce from '../../misc/debounce';
import throttle from '../../misc/throttle';
import type { AnalyticsEventDetailsMiniMap, ZoomEventType } from '..';

/**
 * A helper class that handles zoom events in the minimap. The events are generated only if there was an actual movement.
 *
 * The events are throttled to one every `500 ms`.
 *
 * After `zoomEnd` event, the next `zoomStart` event will be generated immediately, without throttling.
 *
 * We don't have a unique trigger for each analytics event, so we have to keep the track of the flow of interactions here.
 *
 * Because of that, we can't immediately detect the `zoomEnd` event.
 * Instead we have to wait certain time since the last zoom event.
 * And if there was no new zoom updates, generates the `zoomEnd` event, and it will have the same details as the last `zoom` event.
 */
export default class AnalyticsMiniMapZoomHelper {
  /** Determines how often we generate zoom analytics events. */
  private static readonly ZOOM_TIMEOUT_DELAY: Milliseconds = 500;

  /** An instance of the main `Analytics` class. */
  private analytics: Analytics;

  /** The minimap detail during the last onWheel event. */
  private lastWheelMiniMapDetails: AnalyticsEventDetailsMiniMap = {
    scene_id: '',
    building: '1',
    floor: '0',
    position: [0, 0],
    zoom: 0,
  };

  /**
   * Pushes a zoom event to the analytics. A throttled version of {@link pushZoomEvent}.
   * Limits how often we generate zoom analytics events to one every `500 ms`.
   * @param zoomEventType - `ZoomEventType`: the type of zoom event. Possible values: `zoomStart`, `zoom`, `zoomEnd`.
   * @param miniMapDetails - `AnalyticsEventDetailsMiniMap`: the details of the zoom event.
   * @param miniMapDetails.scene_id - `string`: the current scene id.
   * @param miniMapDetails.building - `string`: the current building key.
   * @param miniMapDetails.floor - `string`: the current floor key.
   * @param miniMapDetails.position - `[Centimeter, Centimeter]`: the current position of the minimap center.
   * @param miniMapDetails.zoom - `number`: the current zoom level (scale) of the minimap.
   * @returns `void`
   */
  private pushZoomEventThrottled: ThrottledFunction<
    (zoomEventType: ZoomEventType, miniMapDetails: AnalyticsEventDetailsMiniMap) => void
  >;
  /**
   * Pushes a `zoomEnd` event to the analytics. A debounced version of {@link pushZoomEndEvent}.
   * Will generate it in `500ms` after the last `zoom` event.
   * @param miniMapDetails - `AnalyticsEventDetailsMiniMap`: the details of the zoom event.
   * @param miniMapDetails.scene_id - `string`: the current scene id.
   * @param miniMapDetails.building - `string`: the current building key.
   * @param miniMapDetails.floor - `string`: the current floor key.
   * @param miniMapDetails.position - `[Centimeter, Centimeter]`: the current position of the minimap center.
   * @param miniMapDetails.zoom - `number`: the current zoom level (scale) of the minimap.
   * @returns `void`
   */
  private pushZoomEndEventDebounced: DebouncedFunction<(miniMapDetails: AnalyticsEventDetailsMiniMap) => void>;

  /**
   * @param analytics - `Analytics`: an instance of the main Analytics class.
   */
  constructor(analytics: Analytics) {
    this.analytics = analytics;

    this.onWheel = this.onWheel.bind(this);
    this.onZoomUpdate = this.onZoomUpdate.bind(this);
    this.onAnalyticsDeactivated = this.onAnalyticsDeactivated.bind(this);
    this.pushZoomEvent = this.pushZoomEvent.bind(this);
    this.pushZoomEndEvent = this.pushZoomEndEvent.bind(this);

    this.pushZoomEventThrottled = throttle(this.pushZoomEvent, AnalyticsMiniMapZoomHelper.ZOOM_TIMEOUT_DELAY);
    this.pushZoomEndEventDebounced = debounce(this.pushZoomEndEvent, AnalyticsMiniMapZoomHelper.ZOOM_TIMEOUT_DELAY);

    analytics.pubSub.subscribe('analytics.deactivated', this.onAnalyticsDeactivated);
  }

  /**
   * An event that is triggered on wheel scroll or touchpad scroll.
   * It is triggered even if there was no actual zoom yet.
   * @param miniMapDetails - `AnalyticsEventDetailsMiniMap`: the details of the zoom event.
   * @param miniMapDetails.scene_id - `string`: the current scene id.
   * @param miniMapDetails.building - `string`: the current building key.
   * @param miniMapDetails.floor - `string`: the current floor key.
   * @param miniMapDetails.position - `[Centimeter, Centimeter]`: the current position of the minimap center.
   * @param miniMapDetails.zoom - `number`: the current zoom level (scale) of the minimap.
   * @returns `Promise<void>`
   */
  public async onWheel(miniMapZoomDetails: AnalyticsEventDetailsMiniMap): Promise<void> {
    if (!this.analytics.isActive) return;

    this.lastWheelMiniMapDetails = miniMapZoomDetails;
  }

  /**
   * An event that is triggered when minimap zoom updates.
   * It happens multiple times after a wheel scroll or touchpad scroll, because zooming is eased.
   * Triggered only when there was actual zoom.
   * @param miniMapDetails - `AnalyticsEventDetailsMiniMap`: the details of the zoom event.
   * @param miniMapDetails.scene_id - `string`: the current scene id.
   * @param miniMapDetails.building - `string`: the current building key.
   * @param miniMapDetails.floor - `string`: the current floor key.
   * @param miniMapDetails.position - `[Centimeter, Centimeter]`: the current position of the minimap center.
   * @param miniMapDetails.zoom - `number`: the current zoom level (scale) of the minimap.
   * @returns `Promise<void>`
   */
  public async onZoomUpdate(miniMapDetails: AnalyticsEventDetailsMiniMap): Promise<void> {
    this.pushZoomEndEventDebounced.cancel();

    if (!this.analytics.isActive) return;

    this.pushZoomEndEventDebounced(miniMapDetails);

    if (!this.pushZoomEventThrottled.isOnCooldown()) {
      this.pushZoomEventThrottled('zoomStart', this.lastWheelMiniMapDetails);
      return;
    }

    this.pushZoomEventThrottled('zoom', miniMapDetails);
  }

  /**
   * Pushes a zoom event to the analytics.  Don't call directly, use {@link pushZoomEventThrottled} instead.
   * @param zoomEventType - `ZoomEventType`: the type of zoom event. Possible values: `zoomStart`, `zoom`, `zoomEnd`.
   * @param miniMapDetails - `AnalyticsEventDetailsMiniMap`: the details of the zoom event.
   * @param miniMapDetails.scene_id - `string`: the current scene id.
   * @param miniMapDetails.building - `string`: the current building key.
   * @param miniMapDetails.floor - `string`: the current floor key.
   * @param miniMapDetails.position - `[Centimeter, Centimeter]`: the current position of the minimap center.
   * @param miniMapDetails.zoom - `number`: the current zoom level (scale) of the minimap.
   * @returns `void`
   */
  private pushZoomEvent(zoomEventType: ZoomEventType, miniMapDetails: AnalyticsEventDetailsMiniMap): void {
    if (!this.analytics.isActive) return;

    this.pushZoomEndEventDebounced(miniMapDetails);

    this.analytics.push<AnalyticsEventDetailsMiniMap>(zoomEventType, 'MAP', 'Floor plan', miniMapDetails);
  }

  /**
   * Pushes a zoom end event to the analytics. Don't call directly, use {@link pushZoomEndEventDebounced} instead.
   * @param miniMapDetails - `AnalyticsEventDetailsMiniMap`: the details of the zoom event.
   * @param miniMapDetails.scene_id - `string`: the current scene id.
   * @param miniMapDetails.building - `string`: the current building key.
   * @param miniMapDetails.floor - `string`: the current floor key.
   * @param miniMapDetails.position - `[Centimeter, Centimeter]`: the current position of the minimap center.
   * @param miniMapDetails.zoom - `number`: the current zoom level (scale) of the minimap.
   * @returns `void`
   */
  private pushZoomEndEvent(miniMapDetails: AnalyticsEventDetailsMiniMap): void {
    if (!this.analytics.isActive) return;

    this.analytics.push<AnalyticsEventDetailsMiniMap>('zoomEnd', 'MAP', 'Floor plan', miniMapDetails);
  }

  /**
   * An event that is triggered when analytics is deactivated.
   * @returns `Promise<void>`
   */
  private async onAnalyticsDeactivated(): Promise<void> {
    this.pushZoomEventThrottled.cancel();
    this.pushZoomEndEventDebounced.cancel();
  }
}
