import type { Degree, Radian } from '@g360/vt-types';

const DEG_RAD = Math.PI / 180;
const RAD_DEG = 180 / Math.PI;

/** @deprecated - moved to vt-utils/math - refactor
 * @todo - refactor later to use the math function from in vt-utils/math
 */
export function toRad(n: Degree): Radian {
  return n * DEG_RAD;
}

/** @deprecated - moved to vt-utils/math - refactor
 * @todo - refactor later to use the math function from in vt-utils/math
 */
export function toDeg(n: Radian): Degree {
  return n * RAD_DEG;
}

/**
 * Calculates the angular difference between two angles using formula: δ=(T−C+540°)mod360°−180°
 */
export function calculateAngularDifference(fromBearing: number, toBearing: number): number {
  const fullCircle: number = Math.PI * 2;
  const halfCircle: number = fullCircle / 2;

  // Normalize angles to be within [0, 360]
  const fromNormal: number = ((fromBearing % fullCircle) + fullCircle) % fullCircle;
  const toNormal: number = ((toBearing % fullCircle) + fullCircle) % fullCircle;

  // Calculate the angular difference
  const angularDifference: number = ((fromNormal - toNormal + toRad(540)) % fullCircle) - halfCircle;

  return angularDifference;
}

/** Helper function for type safe angle subtraction */
export function subtractAngle<T extends number>(angle: T, offset: T): T {
  return (angle - offset) as T;
}
