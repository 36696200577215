import { SVGProps } from "react";

const ErrorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 36 32"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <path d="m18.373 3.192 14.585 24.857c.041.07.042.108.042.129a.278.278 0 0 1-.043.132.39.39 0 0 1-.134.134.455.455 0 0 1-.238.056H3.415a.455.455 0 0 1-.238-.056.39.39 0 0 1-.134-.134.278.278 0 0 1-.043-.132c0-.021.001-.059.042-.129L17.627 3.192a.342.342 0 0 1 .136-.137A.509.509 0 0 1 18 3c.097 0 .179.024.237.055a.342.342 0 0 1 .136.137Zm-3.334-1.519c1.309-2.231 4.613-2.231 5.922 0l14.585 24.858c1.299 2.214-.343 4.969-2.961 4.969H3.415c-2.618 0-4.26-2.755-2.961-4.969L15.039 1.673ZM18 10.5a1.5 1.5 0 0 0-1.5 1.5v6a1.5 1.5 0 0 0 3 0v-6a1.5 1.5 0 0 0-1.5-1.5Zm0 15a1.5 1.5 0 1 0-.001-3.001A1.5 1.5 0 0 0 18 25.5Z" />
  </svg>
);

export default ErrorIcon;
