/* eslint-disable no-continue,prefer-const */

import type {
  AutoPlaySceneFrames,
  DataJsonType,
  DebugPointOfInterest,
  Degree,
  EasingTypes,
  HotSpot3DConfig,
  Pano,
  PanoKeyFrame,
  Panos,
  Point3D,
  PointOfInterest,
  Radian,
  ScenePos,
  Scenes,
} from '@g360/vt-types';

import { toDeg, toDegRound, toRad } from '../math';
import { filterOutUsedEntrances } from './dataUtils';
import {
  angleDifference,
  directionalizeAngle,
  getInterestPoints,
  getLookAtAngle,
  getPanoExitAngle,
  getPitchAndYawToLookAtP2FromP1,
  sortPointsOfInterestByAngle,
  tryGetPathEndDirection,
} from './geometryUtils';

// @todo -- later -- rem debug logging entirely
const DEBUG = false;
const log = (...args: any[]) => {
  if (DEBUG) {
    console.log(...args);
  }
};

type PanoType = 'TransitionPano' | 'ViewingPano';

const stairsTypes = [
  'hotspot-up', // 2D (old) hotspots
  'hotspot-down', // 2D
  'stairsUp', // 3D (new) hotspots
  'stairsDown', // 3D
];
const roomTypesHallways = [5, 25, 26, 27, 37];

const slowSpeed = 44; // degrees per second
const fastSpeed = 65; // degrees per second
const waitTime = 1; // seconds

/**
 * Is the current path element just upstairs/downstairs from last one ?
 *
 * @param path
 * @param pathIndex
 * @param panos
 */
const justUsedStairs = (path: string[], pathIndex: number, panos: Panos) => {
  const panoNow = panos[path[pathIndex]];
  const panoLast = panos[path[(pathIndex + path.length - 1) % path.length]];
  const sceneLast = panos[panoLast.sceneKey];
  const hotSpotFromLastToNow = sceneLast.hotSpots?.find((hotSpot) => hotSpot.target === panoNow.sceneKey);
  return stairsTypes.includes(hotSpotFromLastToNow?.type || '');
};

const getLastUsedStairsAsAnInterestPoint = (
  path: string[],
  pathIndex: number,
  panos: Panos
): PointOfInterest | undefined => {
  const panoNow = panos[path[pathIndex]];
  const panoLast = panos[path[(pathIndex + path.length - 1) % path.length]];
  const sceneNow = panos[panoNow.sceneKey];
  const hotSpotFromNowToLast = sceneNow.hotSpots?.find((hotSpot) => hotSpot.target === panoLast.sceneKey);

  if (!hotSpotFromNowToLast) return undefined;

  const cameraPosFrom = [panoNow.camera[0], panoNow.camera[1], panoNow.camera[2]] as Point3D; // convert to meters
  return {
    scenePos: getPitchAndYawToLookAtP2FromP1(cameraPosFrom, (hotSpotFromNowToLast as HotSpot3DConfig).pos),
    type: 'stairs',
  } as PointOfInterest;
};

/**
 *
 * @param projectStartYaw
 * @param path -- array of panos to visit
 * @param panos
 * @param dataJson
 */
export const generatePanoKeyFrames = (
  projectStartYaw: Radian,
  path: string[],
  panos: Panos,
  dataJson: DataJsonType | null
): { panoKeyFrames: PanoKeyFrame[]; debugPointsOfInterest: DebugPointOfInterest[] } => {
  const visitedPanos = new Set<string>();
  const keyFrames: PanoKeyFrame[] = [];
  const debugPointsOfInterest: DebugPointOfInterest[] = [];
  let entryAngle = projectStartYaw; // entry angle for 1st pano is the exit angle of last pano
  log('generatePanoKeyFrames::starting at ', toDegRound(entryAngle), { panos });

  const allInterestPoints = new Map<string, PointOfInterest[]>();
  Object.keys(panos).forEach((sceneKey) => {
    const interestPoints = dataJson ? getInterestPoints(sceneKey, panos, dataJson) : []; // no real interest points for outside panos (or projects with no data.json)
    allInterestPoints.set(sceneKey, interestPoints);
  });

  const isPanoInHallway = (p: Pano) => {
    if (p.type !== undefined) {
      return roomTypesHallways.includes(p.type);
    }
    if (dataJson) {
      return p.roomIds.some((roomId) => roomTypesHallways.includes(dataJson.rooms[roomId].type));
    }
    return false;
  };

  // path is array of panos to visit
  for (let i = 0; i < path.length; i += 1) {
    const pano = panos[path[i]];
    const sceneKey = pano.sceneKey;
    const isHallway = isPanoInHallway(pano);
    const isVisited = visitedPanos.has(sceneKey);
    let interestPoints = allInterestPoints.get(sceneKey) || [];
    let exitAngle = dataJson
      ? getPanoExitAngle(path, i, panos, dataJson, projectStartYaw)
      : { yaw: projectStartYaw, pitch: 0 };

    const nextPano = panos[path[(i + 1) % path.length]];
    if (pano.outside && nextPano.sceneKey !== pano.sceneKey) {
      // outside panos have no exit angle, we must look at the hotspot to the next pano
      const hotSpotToNext = pano.hotSpots?.find((hotSpot) => hotSpot.target === nextPano.sceneKey);

      if (hotSpotToNext && hotSpotToNext.pos.length === 3) {
        // new hotspots are 3D, we can use them to calculate the angle
        const cameraPosFrom = [pano.camera[0], pano.camera[1], pano.camera[2]] as Point3D;
        exitAngle = getPitchAndYawToLookAtP2FromP1(cameraPosFrom, hotSpotToNext.pos);
      } else if (hotSpotToNext && hotSpotToNext.pos.length === 2) {
        // Old hotspots are 2D, we can use its yaw + offset
        const offset = pano.camera[3]; // in deg
        const yaw = hotSpotToNext.pos[1]
        exitAngle = { yaw: toRad(-offset + yaw), pitch: 0 };
      } else if (DEBUG) console.error('no hotspot to next pano', pano.sceneKey, '->', nextPano.sceneKey, { pano }, { hostSpotConfig: pano.hotSpots }); // prettier-ignore
    }

    // assuming the exit angle is closest to the staring (entrance) angle
    exitAngle.yaw = directionalizeAngle(entryAngle, exitAngle.yaw, false);

    // hallways with interest points are not transition panos (boring hallways are)
    let type: PanoType = (isHallway && interestPoints.length === 0) || isVisited ? 'TransitionPano' : 'ViewingPano';

    if (justUsedStairs(path, i, panos)) {
      if (isVisited) {
        // don't look at interest points this still is a TransitionPano,
        // because it is already visited, but we need to take a quick look at the stairs we just took
        interestPoints = [];
      }

      // if we just went upstairs or climbed downstairs, we need to take a look back
      const stairsInterestPoint = getLastUsedStairsAsAnInterestPoint(path, i, panos);
      if (stairsInterestPoint) {
        interestPoints.push(stairsInterestPoint);
        type = 'ViewingPano';
      }
    }

    // only for exit keyframes
    let needToTurnInShortestDir = true;

    // arriving in this pano
    if (type === 'ViewingPano') {
      // for viewing panos make sure to turn in the longest direction when leaving the pano, but still this doe
      exitAngle.yaw = directionalizeAngle(entryAngle, exitAngle.yaw, true);

      // don't look at entrances we are using to leave/enter this building
      interestPoints = filterOutUsedEntrances(panos, interestPoints, path, i);

      if (interestPoints.length === 0) {
        // if no interest points,
        // look at spot 80° to the side of largest arc
        const lookAtAngle = getLookAtAngle(entryAngle, exitAngle.yaw);
        const boringInterestPoint = { scenePos: { yaw: lookAtAngle, pitch: 0 }, type: 'nothing' } as PointOfInterest;
        interestPoints.push(boringInterestPoint);
      }

      sortPointsOfInterestByAngle(interestPoints, exitAngle.yaw);

      // calculate how much of the room we will see (not counting peripheral vision)
      let angleSeen = 0;
      let lastAngle = entryAngle;
      for (let j = 0; j < interestPoints.length; j += 1) {
        const angle = angleDifference(lastAngle, interestPoints[j].scenePos.yaw);
        angleSeen += angle;
        lastAngle = interestPoints[j].scenePos.yaw;
      }
      const angle = angleDifference(lastAngle, exitAngle.yaw);
      angleSeen += angle;
      const percentageSeen = Math.abs(angleSeen / (Math.PI * 2)) * 100; // 100 = 100% = full circle
      const percentageSeenNeeded = 70;
      if (percentageSeen < percentageSeenNeeded) {
        needToTurnInShortestDir = false;
      }

      interestPoints.forEach((point) => {
        keyFrames.push({
          actionType: 'turnSlow',
          sceneKey,
          targetAngle: point.scenePos,
          needToTurnInShortestDir: true,
        } as PanoKeyFrame);

        if (point.type !== 'nothing' && point.type !== 'stairs') {
          // don't wait when looking at nothing or at stairs
          keyFrames.push({
            actionType: 'Wait',
            sceneKey,
            targetAngle: point.scenePos,
            needToTurnInShortestDir: true,
          } as PanoKeyFrame);
        }

        // --- debug ---
        debugPointsOfInterest.push({
          sceneKey,
          yaw: point.scenePos.yaw - Math.PI / 2, // floorplan has 90° rotation on x axis or smtn
          type: point.type,
        });
      });
    }

    // slow for viewing panos, fast for visited ones or hallways
    let actionType = type === 'ViewingPano' ? 'turnSlow' : 'turnFast';

    keyFrames.push({
      actionType,
      sceneKey,
      targetAngle: exitAngle,
      needToTurnInShortestDir,
    } as PanoKeyFrame);

    entryAngle = exitAngle.yaw;
    visitedPanos.add(sceneKey);
  }

  log({ keyFrames });
  return { panoKeyFrames: keyFrames, debugPointsOfInterest };
};

export const createAutoPlayFramesFromPanoKeyFrames = (
  panoKeyFrames: PanoKeyFrame[],
  projectStartYaw: Radian,
  defaultPitch: Degree
): AutoPlaySceneFrames[] => {
  let debugText = '';
  const autoplayData: AutoPlaySceneFrames[] = [];

  for (let i = 0; i < panoKeyFrames.length; i += 1) {
    const panoKeyFrame = panoKeyFrames[i];

    const lastPanoKeyFrame = panoKeyFrames[(i + panoKeyFrames.length - 1) % panoKeyFrames.length];
    const nextPanoKeyFrame = panoKeyFrames[(i + 1) % panoKeyFrames.length];
    const exitKeyframe = panoKeyFrame.sceneKey !== nextPanoKeyFrame.sceneKey;
    const fov = 120;
    const entryYaw = i === 0 ? projectStartYaw : lastPanoKeyFrame.targetAngle.yaw;
    let targetYaw = panoKeyFrame.targetAngle.yaw;

    // engine animator uses literal linear tweening, not angular,
    // it can't turn to the shortest direction which we were assuming in the keyframe generator above
    // so we need to give explicit angles for it to turn in right direction:
    // angles must not be rounded to 0..360° and negative angles can be used
    let angleDistanceLinear = targetYaw - entryYaw;

    // eliminate full circles
    let shrank = false;
    let shrankDetails = '';
    while (angleDistanceLinear > Math.PI * 2) {
      shrankDetails += ` | ${toDegRound(angleDistanceLinear)} --> ${toDegRound(angleDistanceLinear - Math.PI * 2)}`;
      angleDistanceLinear -= Math.PI * 2;
      targetYaw -= Math.PI * 2;
      shrank = true;
    }
    while (angleDistanceLinear < -Math.PI * 2) {
      shrankDetails += ` | ${toDegRound(angleDistanceLinear)} ++> ${toDegRound(angleDistanceLinear + Math.PI * 2)}`;
      angleDistanceLinear += Math.PI * 2;
      targetYaw += Math.PI * 2;
      shrank = true;
    }

    // check and fix direction
    let isTurnInLongerDirection = angleDistanceLinear < -Math.PI || angleDistanceLinear > Math.PI;
    const needTurnInLongerDirection = !panoKeyFrame.needToTurnInShortestDir;

    let fixedJustNow = false;
    let rightDirection = isTurnInLongerDirection === needTurnInLongerDirection;
    if (!rightDirection) {
      // swap the direction, go to the same angle, but in the other direction
      targetYaw = directionalizeAngle(entryYaw, targetYaw, needTurnInLongerDirection);
      angleDistanceLinear = targetYaw - entryYaw;
      // angleDistanceLinear %= (Math.PI*2);
      isTurnInLongerDirection = angleDistanceLinear < -Math.PI || angleDistanceLinear > Math.PI;
      rightDirection = true;
      fixedJustNow = true;
    }

    panoKeyFrame.targetAngle.yaw = targetYaw; // update the keyframe, so that next frame uses fixed angle

    const distance = Math.abs(angleDistanceLinear);

    // ----------------debug----------------
    if (DEBUG) {
      let frameDebug = '';
      if (exitKeyframe) {
        frameDebug = `is:${isTurnInLongerDirection ? 'longer' : 'shortest'} need:${
          needTurnInLongerDirection ? 'longer' : 'shortest'
        } ${fixedJustNow ? 'fixedJustNow' : ''}`;
      } else {
        frameDebug = `look ${isTurnInLongerDirection ? 'longer' : 'shortest'} ${fixedJustNow ? 'fixedJustNow' : ''}`;
      }

      debugText += ` ${i} ${panoKeyFrame.sceneKey} ${exitKeyframe ? 'exit' : 'look'} ${toDegRound(
        entryYaw
      )} -> ${toDegRound(targetYaw)} = ${toDegRound(angleDistanceLinear)} ${frameDebug} ${
        shrank ? `shrank${shrankDetails}` : ''
      } \n`;
    }
    // ------------end-debug----------------

    let duration: number;
    switch (panoKeyFrame.actionType) {
      case 'turnSlow':
        duration = toDeg(distance) / slowSpeed;
        break;
      case 'turnFast':
        duration = toDeg(distance) / fastSpeed;
        break;
      case 'Wait':
        duration = waitTime;
        break;
      default:
        throw new Error(`unknown action type ${panoKeyFrame.actionType}`);
    }

    duration = Math.round(duration * 1000); // rounded milliseconds

    const easing = 'easeOutQuad' as EasingTypes;
    // overwriting pitch: to look a bit down, works fine until new hotspots might require to look directly at them
    const pitch = defaultPitch; // panoKeyFrame.targetAngle.pitch;
    const yaw = toDeg(targetYaw); // autoplay uses degrees 😔

    autoplayData.push({
      sceneKey: panoKeyFrame.sceneKey,
      frames: [
        {
          duration,
          easing,
          position: { fov, pitch, yaw },
        },
      ],
    });
  }

  // put in a cheeky 0 sec frame to fix rotations:
  // a) head-down-pitch  (5 deg or smtn)
  // b) full circles yaw neutralization
  //    99% of the time at the end of a loop autoplay will be few full circles off from start yaw angle
  //    and if left alone this will result in last animation doing circles to get to start position
  const lastFrame = autoplayData[autoplayData.length - 1];
  autoplayData.unshift({
    sceneKey: lastFrame.sceneKey,
    frames: [
      {
        duration: 0,
        easing: 'linear',
        position: { ...lastFrame.frames[0].position, yaw: toDeg(projectStartYaw) },
      },
    ],
  });

  // grouping: merge frames of the same scene (if the scenes are back to back in autoplayData)
  const groupedAutoplayData: AutoPlaySceneFrames[] = [];
  let lastSceneKey = '';
  for (let i = 0; i < autoplayData.length; i += 1) {
    const frame = autoplayData[i];
    if (frame.sceneKey === lastSceneKey) {
      groupedAutoplayData[groupedAutoplayData.length - 1].frames.push(frame.frames[0]);
    } else {
      groupedAutoplayData.push(frame);
    }
    lastSceneKey = frame.sceneKey;
  }

  debugText += `+ cheeky frame to return to start ${toDegRound(projectStartYaw)}\n`;
  log(debugText);

  return groupedAutoplayData;
};

/**
 * special case for 1 pano, when there is no data.json
 * just slowly do a 360° rotation
 * then instantly reset
 */
export const createAutoPlayForSinglePano = (scenes: Scenes, defaultPitch: Degree): AutoPlaySceneFrames[] => {
  const scene = Object.values(scenes)[0];
  const startPos = tryGetPathEndDirection([scene.sceneKey], 0, scenes as Panos);
  const startYaw = startPos?.yaw || 0;
  const duration = (360 / slowSpeed) * 1000;

  return [
    {
      sceneKey: scene.sceneKey,
      frames: [
        {
          duration: 0,
          easing: 'linear',
          position: { fov: 120, pitch: defaultPitch, yaw: toDeg(startYaw) } as ScenePos<Degree>,
        },
        {
          duration,
          easing: 'linear',
          position: { fov: 120, pitch: defaultPitch, yaw: toDeg(startYaw) + 360 } as ScenePos<Degree>,
        },
      ],
    },
  ];
};

/**
 * add a frame to the start of each scene, that corresponds to the scene transition that takes place at that time
 * @param scenesFrames
 * @param transitionDuration
 */
export const addTransitionsKeyframes = (scenesFrames: AutoPlaySceneFrames[], transitionDuration: number) => {
  let lastSceneKey = scenesFrames[0].sceneKey;
  for (let i = 0; i < scenesFrames.length; i += 1) {
    const frame = scenesFrames[i];
    if (frame.sceneKey !== lastSceneKey) {
      // this is pretty useless check - since all ScenesFrames are already grouped by sceneKey and next ScenesFrames always have different sceneKey
      lastSceneKey = frame.sceneKey;
      const previousScene = scenesFrames[(i + scenesFrames.length - 1) % scenesFrames.length];
      const previousSceneLastFrame = previousScene.frames[previousScene.frames.length - 1];
      frame.frames.unshift({
        duration: transitionDuration,
        easing: 'linear',
        position: previousSceneLastFrame.position, // just a formality, transition frames currently won't move the camera
        transition: true,
      });
    }
  }

  return scenesFrames;
};

/*
room_names: {
    '0': 'Balcony',
    '1': 'Bathroom',
    '2': 'Bedroom',
    '3': 'Dining Room',
    '4': 'Garage',
    '5': 'Hallway',               <-- hallway (not to look around, just move along (except if there are some interesting doors to look at))
    '6': 'Kitchen',
    '7': 'Laundry Room',
    '8': 'Living Room',
    '9': 'Office',
    '10': 'Other',
    '11': 'Outside',
    '12': 'Room',
    '13': 'Kitchen / Living Area',
    '14': 'Sunroom',
    '15': 'WC',
    '16': 'Terrace',
    '17': 'Attic',
    '18': 'Basement',
    '19': 'Billiard Room',
    '20': 'Conservatory',
    '21': 'Cupboard',
    '22': 'Dining Area',
    '23': 'Entry',
    '24': 'Gym',
    '25': 'Hallway (Down)',       <-- is this hallway ?
    '26': 'Hallway (Up)',         <-- is this hallway ?
    '27': 'Landing',              <-- maybe this also is hallway ?
    '28': 'Living Area',
    '29': 'Living Area / Kitchen',
    '30': 'Meeting Room',
    '31': 'Office (Building)',
    '32': 'Orangery',
    '33': 'Pantry',
    '34': 'Rooftop',
    '35': 'Sauna',
    '36': 'Shed',
    '37': 'Stairs',               <-- this def is a hallway, and we should not be looking around, right ?
    '38': 'Storage Room',
    '39': 'Study',
    '40': 'Utility Room',
    '41': 'Wardrobe',
    '-1': 'Unknown',
  },
 */
