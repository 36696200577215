import { FC } from "react";
import { classNamesUtil } from "utils/classNamesUtil";

interface TooltipProps {
  isOpacity?: boolean;
  tooltipText: string;
  children: JSX.Element;
  tooltipPosition?: "top" | "bottom" | "left" | "right";
  tooltipOffset?: "center" | "left" | "right";
  theme?: "light" | "dark";
}

const Tooltip: FC<TooltipProps> = ({
  children,
  tooltipText,
  isOpacity = true,
  tooltipPosition = "top",
  tooltipOffset = "center",
  theme = "dark",
}) => {
  return (
    <div
      className={classNamesUtil(
        isOpacity ? "opacity-100" : "opacity-0",
        "relative group"
      )}
    >
      {children}
      <div
        className={classNamesUtil(
          tooltipOffset === "center" ? "-translate-x-1/2 left-1/2" : "",
          tooltipOffset === "left" ? "right-0" : "",
          tooltipOffset === "right" ? "left-0" : "",
          tooltipPosition === "top" ? "-top-8" : "",
          tooltipPosition === "bottom" ? "-bottom-8" : "",
          theme === "dark"
            ? "bg-giraffe-gray-darkest"
            : "bg-giraffe-gray-medium",
          "absolute px-3 rounded-md py-1.5 transition-all duration-300 ease-in-out transform opacity-0 pointer-events-none group-hover:better-hover:opacity-100"
        )}
      >
        <p
          className={classNamesUtil(
            "z-50 text-xs font-bold whitespace-nowrap font-primary",
            theme === "dark" ? "text-white" : "text-giraffe-dark"
          )}
        >
          {tooltipText}
        </p>
      </div>
    </div>
  );
};

export default Tooltip;
