import type { BlurMaskData, Pos, Ratio } from '@g360/vt-types';
import { pitchToY, yawToX } from '@g360/vt-utils';
import * as StackBlur from 'stackblur-canvas';

type MaskPath = Pos<Ratio>[];

// holds 1 equirect image and it's blur variants
class Blurrer {
  public static readonly intensityDiffFactor = 10000 / 4096; // RapidAI's intensity is blur radius when blurring 10k x 5k image, we are blurring 2048 x 1024 image
  // But needs to be 4096, because we upscale the image to 4096 x 2048 when rendering?

  public readonly blurredImage: HTMLImageElement;
  public lastBlurIntensity = -1; // accepted values 0..255, anything goes for the local blur, but for RapidAI it's 0..255 cos it's embedded in pngs color channels

  private readonly originalImg: HTMLImageElement; // input image to blur

  private width = 0;
  private height = 0;
  private maskPaths: MaskPath[] = [];
  private lastMaskDataJson = ''; // used to compare if mask has changed
  private busy = false;

  constructor(originalImg: HTMLImageElement) {
    this.originalImg = originalImg;
    this.blurredImage = new Image();
  }

  // input: points in camera space pitch/yaw
  // output: points in equirect space x/y (curved lines represented as multiple points)
  public setBlurMasks(intensity: number, masks: BlurMaskData[]): void {
    if (this.busy || !masks) return;
    const intensityChanged = this.updateBlurIntensity(intensity);
    this.busy = true;
    const maskJson = JSON.stringify(masks); // could look only at og points + radii
    if (!intensityChanged && this.lastMaskDataJson === maskJson) {
      // console.log(`Engine::setBlurMasks::Mask has not changed, skipping; intensity: ${intensity}`);
      this.busy = false;
      return;
    }
    // console.log(`Engine::setBlurMasks n=`, masks.length, masks);
    this.lastMaskDataJson = maskJson;
    this.maskPaths = [];

    for (let i = 0; i < masks.length; i += 1) {
      let splitMask = false; // if mask is crossing date change line, then 2 blurs must be made
      const mask: MaskPath = [];
      const maskAlt: MaskPath = [];
      const points = masks[i].maskPointsDerived;
      const maxYaw = points.reduce((max, p) => (p.yaw > max ? p.yaw : max), -Infinity);
      const maxX = yawToX(maxYaw);

      for (let j = 0; j < points.length; j += 1) {
        let x = yawToX(points[j].yaw);
        const y = pitchToY(points[j].pitch);

        // if the mask is crossing date change line on the right hand side, shift everything to the left
        // because we can handle the crossing on the left hand side (hopefully the blurs won't be as big to require 2 crossings)
        if (maxX > 1) {
          x -= 1;
        }

        if (x < 0) {
          splitMask = true;
        }
        mask.push({ x, y });
        maskAlt.push({ x: x + 1, y });
      }

      this.maskPaths.push(mask);
      if (splitMask) {
        this.maskPaths.push(maskAlt);
      }
    }
  }

  public makeComboBlur(highQuality: boolean): Promise<ImageData> {
    return this.combo(false, highQuality ? 4096 : 1024) as Promise<ImageData>;
  }

  public makeComboMask(width: number): Promise<string> {
    return this.combo(true, width) as Promise<string>;
  }

  public makeComboMaskBlob(width: number): Promise<Blob> {
    return this.combo(true, width, true) as Promise<Blob>;
  }

  public updateBlurIntensity(intensity: number, force = false): boolean {
    if (!force && this.lastBlurIntensity === intensity) return false;
    this.lastBlurIntensity = intensity;

    const ogWidth = this.originalImg.width;
    const ogHeight = this.originalImg.height;
    this.width = ogWidth;
    this.height = ogHeight;

    const actualIntensity = intensity / Blurrer.intensityDiffFactor;

    const blurCanvas = document.createElement('canvas');
    const blurCtx = blurCanvas.getContext('2d', { willReadFrequently: true });
    if (!blurCtx) return false;

    blurCanvas.width = this.width + 100; // add 50px on each side for looping image
    blurCanvas.height = this.height;

    // draw slices of original on both sides
    blurCtx.drawImage(this.originalImg, -ogWidth + 50, 0, ogWidth, ogHeight);
    blurCtx.drawImage(this.originalImg, ogWidth + 50, 0, ogWidth, ogHeight);
    blurCtx.drawImage(this.originalImg, 50, 0, ogWidth, ogHeight);
    StackBlur.canvasRGB(blurCanvas, 0, 0, ogWidth + 100, ogHeight, actualIntensity);

    const imageData = blurCtx.getImageData(50, 0, ogWidth, ogHeight);

    blurCanvas.width = this.width;
    blurCanvas.height = this.height;
    blurCtx.putImageData(imageData, 0, 0);
    this.blurredImage.src = blurCanvas.toDataURL('image/jpg');

    return true;
  }

  private combo(maskMode: boolean, width?: number, asBlob?: boolean): Promise<ImageData | string | Blob> {
    return new Promise((resolve) => {
      const cCanvas = document.createElement('canvas');
      const cCtx = cCanvas.getContext('2d')!;
      cCtx.lineWidth = 1;
      let resizeFactor = 1;

      if (width) {
        cCanvas.width = width;
        cCanvas.height = width / 2; // equirect images are always 2:1
        resizeFactor = width / this.width;
      } else {
        cCanvas.width = this.width;
        cCanvas.height = this.height;
      }

      if (maskMode) {
        cCtx.beginPath();
        cCtx.rect(0, 0, cCanvas.width, cCanvas.height);
        cCtx.fillStyle = 'black'; // black background for mask
        cCtx.fill();
      }

      for (let i = 0; i < this.maskPaths.length; i += 1) {
        // clipping
        cCtx.save();
        cCtx.beginPath();
        const updMask = this.maskPaths[i];
        for (let j = 0; j < updMask.length; j += 1) {
          const maskPoint = updMask[j];
          cCtx.lineTo(maskPoint.x * this.width * resizeFactor, maskPoint.y * this.height * resizeFactor);
        }

        cCtx.closePath();
        cCtx.clip();

        // mask or blur
        if (maskMode) {
          cCtx.fillStyle = '#808080'; // grey-128
          cCtx.fill();
        } else {
          cCtx.drawImage(this.blurredImage, 0, 0, this.width, this.height, 0, 0, cCanvas.width, cCanvas.height);
        }
        cCtx.restore();
      }

      if (maskMode) {
        const imgData = cCtx.getImageData(0, 0, cCanvas.width, cCanvas.height);
        const data = imgData.data;
        for (let i = 0; i < data.length; i += 4) {
          const clampedColor = Math.round(data[i] / 128) * 128; // mask is always grey-128, convert this to the closest color: black or grey-128
          data[i] = clampedColor === 0 ? 0 : this.lastBlurIntensity;
          data[i + 1] = clampedColor === 0 ? 0 : this.lastBlurIntensity;
          data[i + 2] = clampedColor === 0 ? 0 : this.lastBlurIntensity;
        }
        cCtx.putImageData(imgData, 0, 0);

        if (asBlob) {
          cCanvas.toBlob((blob) => {
            if (blob) {
              resolve(blob);
            }
          }, 'image/png');
          return;
        }

        const pngUrl = cCanvas.toDataURL('image/png');
        resolve(pngUrl);
      } else {
        const imgData = cCtx.getImageData(0, 0, cCanvas.width, cCanvas.height);
        resolve(imgData);
        this.busy = false;
      }
    });
  }
}

export default Blurrer;
