import debounce from 'lodash/debounce';
/**
 * Minimap renderer has no idea about Guided Viewing, it just renders the minimap according to the input:
 * direct user input or forced input from Guided Viewing through this class.
 */
class GuidedViewingManager {
    constructor(engine, miniMapRenderer) {
        this.source = 'DOMEvents';
        this.lastMinimapState = { scale: -9999, position: { x: -9999, y: -9999 } };
        this.engine = engine;
        this.miniMapRenderer = miniMapRenderer;
        if (!engine.isInGuidedViewing()) {
            this.on = false;
            return;
        }
        this.on = true;
        this.miniMapRenderer.onRendered = this.onMinimapRendered.bind(this);
        this.minimapStateChangeDebounced = debounce((state) => {
            this.sendMinimapState(state);
        }, GuidedViewingManager.debounceTime);
        this.subSource = engine.subscribe('tour.eventSource.change', this.setTourEventSource.bind(this));
        this.subUpdate = engine.subscribe('minimap.layout.remoteUpdate', this.updateLayout.bind(this));
        this.source = engine.getTourEventSource();
    }
    destroy() {
        var _a, _b;
        if (!this.on)
            return;
        (_a = this.subSource) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        (_b = this.subUpdate) === null || _b === void 0 ? void 0 : _b.unsubscribe();
    }
    onMinimapRendered(newScale, newCentered) {
        if (newScale === this.lastMinimapState.scale &&
            newCentered.x === this.lastMinimapState.position.x &&
            newCentered.y === this.lastMinimapState.position.y)
            return;
        this.lastMinimapState.scale = newScale;
        this.lastMinimapState.position.x = newCentered.x;
        this.lastMinimapState.position.y = newCentered.y;
        if (this.source === 'RemoteHost')
            return;
        if (this.minimapStateChangeDebounced) {
            this.minimapStateChangeDebounced(this.lastMinimapState);
        }
    }
    setTourEventSource(source) {
        this.source = source;
    }
    updateLayout(remoteState) {
        if (remoteState.scale && remoteState.position) {
            const t = GuidedViewingManager.receiverAnimationTime;
            this.miniMapRenderer.forceSetState(remoteState.scale, remoteState.position, t);
        }
    }
    sendMinimapState(state) {
        this.engine.emitGuidedViewingMinimapEvent({
            source: 'minimap-layout',
            scale: state.scale,
            position: state.position,
        });
    }
}
GuidedViewingManager.debounceTime = 300;
GuidedViewingManager.receiverAnimationTime = 400;
export default GuidedViewingManager;
