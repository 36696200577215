import type { FPIntersection, FPRay } from '@g360/vt-types';

const edge1 = new Float32Array(3);
const edge2 = new Float32Array(3);
const h = new Float32Array(3);
const q = new Float32Array(3);
const s = new Float32Array(3);

/**
 * Same as rayTriangleIntersection, but without mem allocation and inlined math function
 * MO stands for micro-optimized
 * Since this functions is top 2 most called functions in the AO baker, it's worth optimizing
 *
 * receives reusable object result_ to avoid mem allocation
 * returns Intersection or null
 */
export default function rayTriangleIntersectionMO(
  ray: FPRay,
  v0: number[],
  v1: number[],
  v2: number[],
  result_: FPIntersection
): FPIntersection | null {
  // Inline getVec3Difference for edge1 = v1 - v0
  edge1[0] = v1[0] - v0[0];
  edge1[1] = v1[1] - v0[1];
  edge1[2] = v1[2] - v0[2];

  // Inline getVec3Difference for edge2 = v2 - v0
  edge2[0] = v2[0] - v0[0];
  edge2[1] = v2[1] - v0[1];
  edge2[2] = v2[2] - v0[2];

  // Inline vec3CrossProduct for h = cross(ray.direction, edge2)
  h[0] = ray.direction[1] * edge2[2] - ray.direction[2] * edge2[1];
  h[1] = ray.direction[2] * edge2[0] - ray.direction[0] * edge2[2];
  h[2] = ray.direction[0] * edge2[1] - ray.direction[1] * edge2[0];

  // Inline getVec3Dot for a = dot(edge1, h)
  const a = edge1[0] * h[0] + edge1[1] * h[1] + edge1[2] * h[2];

  if (Math.abs(a) < 1e-6) return null;

  const f = 1.0 / a;

  // Inline getVec3Difference for s = ray.origin - v0
  s[0] = ray.origin[0] - v0[0];
  s[1] = ray.origin[1] - v0[1];
  s[2] = ray.origin[2] - v0[2];

  // Inline getVec3Dot for u = f * dot(s, h)
  const u = f * (s[0] * h[0] + s[1] * h[1] + s[2] * h[2]);

  if (u < 0.0 || u > 1.0) return null;

  // Inline vec3CrossProduct for q = cross(s, edge1)
  q[0] = s[1] * edge1[2] - s[2] * edge1[1];
  q[1] = s[2] * edge1[0] - s[0] * edge1[2];
  q[2] = s[0] * edge1[1] - s[1] * edge1[0];

  // Inline getVec3Dot for v = f * dot(ray.direction, q)
  const v = f * (ray.direction[0] * q[0] + ray.direction[1] * q[1] + ray.direction[2] * q[2]);

  if (v < 0.0 || u + v > 1.0) return null;

  // Inline getVec3Dot for t = f * dot(edge2, q)
  const t = f * (edge2[0] * q[0] + edge2[1] * q[1] + edge2[2] * q[2]);

  if (t > 1e-6) {
    result_.point[0] = ray.origin[0] + t * ray.direction[0];
    result_.point[1] = ray.origin[1] + t * ray.direction[1];
    result_.point[2] = ray.origin[2] + t * ray.direction[2];
    result_.distance = t;
    return result_;
  }

  return null;
}
