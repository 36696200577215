import { RoundButton, Tooltip } from "components/elements";
import {
  ArrowRightIcon,
  CameraIcon,
  CameraOffIcon,
  CogIcon,
  MicIcon,
  MicOffIcon,
  PhoneOffIcon,
} from "components/icons";
import { FC } from "react";
import { classNamesUtil } from "utils/classNamesUtil";
import { useMixpanelContext } from "hooks";

interface ParticipantRoomActionsWrapperProps {
  isHost: boolean;
  isAudioOn: boolean;
  isVideoOn: boolean;
  isMinimized: boolean;
  audioAccess: boolean | undefined;
  videoAccess: boolean | undefined;
  toggleMinimize: () => void;
  handleAudioToggle: () => void;
  handleVideoToggle: () => void;
  handleSettingModal: () => void;
  handleEndSessionModal: () => void;
}

const ParticipantRoomActionsWrapper: FC<ParticipantRoomActionsWrapperProps> = ({
  isHost,
  isAudioOn,
  isVideoOn,
  isMinimized,
  audioAccess,
  videoAccess,
  toggleMinimize,
  handleAudioToggle,
  handleVideoToggle,
  handleSettingModal,
  handleEndSessionModal,
}) => {
  const { Mixpanel, MixpanelEvents } = useMixpanelContext();

  const renderCameraIcon = () => {
    if (!videoAccess) {
      return <CameraOffIcon className="w-3.5 h-3.5 fill-giraffe-red" />;
    }

    if (!isVideoOn) {
      return <CameraOffIcon className="w-3.5 h-3.5 fill-giraffe-red" />;
    }

    return <CameraIcon className="w-3.5 h-3.5 fill-giraffe-white m-[1px]" />;
  };

  const renderMicIcon = () => {
    if (!audioAccess) {
      return <MicOffIcon className="w-3.5 h-3.5 fill-giraffe-red" />;
    }

    if (!isAudioOn) {
      return <MicOffIcon className="w-3.5 h-3.5 fill-giraffe-red" />;
    }

    return <MicIcon className="w-3.5 h-3.5 fill-giraffe-white" />;
  };

  return (
    <>
      <div className="mt-0.5 mx-1 border-b border-giraffe-dark"></div>
      <div
        className={classNamesUtil(
          "transition-all w-full ease-in-out duration-300 flex justify-between relative opacity-100 mt-1.5 mb-0.5"
        )}
      >
        <div
          className={classNamesUtil(
            isMinimized ? "mt-[1.4rem] absolute" : "mt-0",
            "transition-all duration-300 ease-in-out transform-gpu "
          )}
        >
          <Tooltip
            tooltipText={isMinimized ? "Show panel" : "Hide panel"}
            tooltipPosition={!isMinimized ? "top" : "bottom"}
          >
            <RoundButton
              btnSize="sm"
              bgColor="black"
              handleClick={() => {
                const mixpanelMessage = isMinimized
                  ? "Show panel"
                  : "Hide panel";
                Mixpanel.track(MixpanelEvents.Button.Click(mixpanelMessage));
                toggleMinimize();
              }}
            >
              <ArrowRightIcon
                className={classNamesUtil(
                  isMinimized
                    ? "rotate-90 mt-[0.2rem]"
                    : "-rotate-90 mb-[0.2rem]",
                  "w-3.5 h-3.5 fill-giraffe-white transition-all duration-300 ease-in-out transform-gpu"
                )}
              />
            </RoundButton>
          </Tooltip>
        </div>

        <div
          className={classNamesUtil(
            "transition-all duration-300 ease-in-out transform-gpu"
          )}
        >
          <Tooltip
            tooltipText={
              isAudioOn ? "Turn off microphone" : "Turn on microphone"
            }
          >
            <RoundButton
              btnSize="sm"
              bgColor="black"
              handleClick={() => {
                const mixpanelMessage = isAudioOn
                  ? "Microphone off"
                  : "Microphone on";
                Mixpanel.track(MixpanelEvents.Button.Click(mixpanelMessage));
                handleAudioToggle();
              }}
            >
              {renderMicIcon()}
            </RoundButton>
          </Tooltip>
        </div>

        <div className="">
          <Tooltip
            tooltipText={isVideoOn ? "Turn off camera" : "Turn on camera"}
          >
            <RoundButton
              btnSize="sm"
              bgColor="black"
              handleClick={() => {
                const mixpanelMessage = isVideoOn ? "Camera off" : "Camera on";
                Mixpanel.track(MixpanelEvents.Button.Click(mixpanelMessage));
                handleVideoToggle();
              }}
            >
              {renderCameraIcon()}
            </RoundButton>
          </Tooltip>
        </div>

        <div className="">
          <Tooltip tooltipText="Settings" isOpacity={!isMinimized}>
            <RoundButton
              btnSize="sm"
              bgColor="black"
              handleClick={() => {
                Mixpanel.track(MixpanelEvents.Button.Click("Settings"));
                handleSettingModal();
              }}
            >
              {audioAccess || videoAccess ? (
                <CogIcon className="w-3.5 h-3.5 fill-giraffe-white" />
              ) : (
                <CogIcon className="w-3.5 h-3.5 fill-giraffe-red" />
              )}
            </RoundButton>
          </Tooltip>
        </div>
        <div className="">
          <Tooltip
            isOpacity={!isMinimized}
            tooltipOffset="left"
            tooltipText={isHost ? "End Guided Viewing" : "Leave Guided Viewing"}
          >
            <RoundButton
              btnSize="sm"
              bgColor="black"
              handleClick={() => {
                Mixpanel.track(MixpanelEvents.Button.Click("End meeting"));
                handleEndSessionModal();
              }}
            >
              <PhoneOffIcon className="w-3.5 h-3.5 fill-giraffe-red" />
            </RoundButton>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default ParticipantRoomActionsWrapper;
