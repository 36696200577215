import { useEffect, useMemo, useState } from 'react';
/**
 * Constructs a state object containing the properties specified in the keys array from a reactive object.
 * @param reactive - `T`: The current reactive object.
 * @param keys  - `Key[]`: The keys to include in the state object.
 * @returns `Pick<T, Key>`: The state object containing the properties specified in the keys array.
 */
function constructState(reactive, keys) {
    const stateObject = {};
    if (!reactive)
        return stateObject;
    for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        stateObject[key] = reactive[key];
    }
    return stateObject;
}
export default function useReactive(reactive, keys) {
    const [currentReactive, setCurrentReactive] = useState(reactive);
    // This assumes that the keys will not change during the component lifecycle
    // But if we where to allow the keys to change, we would need to actually compare current keys with the new keys
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const currentKeys = useMemo(() => keys, []);
    const [state, setState] = useState(() => constructState(reactive, keys));
    useEffect(() => {
        if (!reactive || !currentKeys.length)
            return () => undefined;
        // If `reactive` has changed, we need to subscribe new listeners
        // `onChange` returns a cleanup function that we can use to unsubscribe later
        const cleaners = currentKeys.map((key) => reactive.onChange(key, (newValue) => {
            setState((previousState) => (Object.assign(Object.assign({}, previousState), { [key]: newValue })));
        }));
        if (currentReactive !== reactive) {
            // If `reactive` changes, we need to update the state to match the new instance
            setCurrentReactive(reactive);
            setState(constructState(reactive, currentKeys));
        }
        // Unsubscribe all listeners when the component unmounts
        return () => cleaners.forEach((clean) => clean());
    }, [currentReactive, currentKeys, reactive]);
    return state;
}
