import { FC, useEffect, useState } from "react";
import { classNamesUtil } from "utils/classNamesUtil";

const POINTER_POSITION_CORRECTION = 17;

interface PointerProps {
  x: number | null;
  y: number | null;
  clearPercentCoords: () => void;
}

const Pointer: FC<PointerProps> = ({ x, y, clearPercentCoords }) => {
  const [isAnimation, setIsAnimation] = useState<boolean>(false);

  useEffect(() => {
    if (x !== null && y !== null) {
      setIsAnimation(true);
    }
  }, [x, y]);

  return (
    <>
      <span
        className={classNamesUtil(
          "absolute z-50 w-9 h-9 rounded-full transform-gpu radial-gradient-outer",
          isAnimation ? "animate-ripple-outer" : "opacity-0"
        )}
        style={{
          top: y ? y - POINTER_POSITION_CORRECTION : 0,
          left: x ? x - POINTER_POSITION_CORRECTION : 0,
        }}
      ></span>
      <span
        className={classNamesUtil(
          "absolute z-50 w-9 h-9 rounded-full transform-gpu radial-gradient-inner",
          isAnimation ? "animate-ripple-inner" : "opacity-0"
        )}
        onAnimationEnd={() => {
          setIsAnimation(false);
          clearPercentCoords();
        }}
        style={{
          top: y ? y - POINTER_POSITION_CORRECTION : 0,
          left: x ? x - POINTER_POSITION_CORRECTION : 0,
        }}
      ></span>
    </>
  );
};

export default Pointer;
