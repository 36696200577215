import { ConnectToApiRoomResponse } from "types/api.response.types";
import { getCookieByName } from "func/getCookieByName";
import config from "config";

type getRoomAccessDataArgs = {
  participantIdentity: string;
  roomId: string | undefined;
  userId?: string | undefined;
};

/**
 * Get data from backend that allows to access room
 */
const getRoomAccessData = async ({
  roomId,
  participantIdentity,
}: getRoomAccessDataArgs) => {
  if (!roomId) return;

  const cookie = getCookieByName("csrftoken");

  const res = await fetch(
    `${config.baseUrl}/gapi/guided-viewing/${roomId}/connect/`,
    {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        link: roomId,
        identity: participantIdentity,
      }),
      headers: {
        "Content-Type": "application/json",
        ...(cookie && { "X-CSRFToken": cookie }),
      },
    }
  );

  const data: ConnectToApiRoomResponse = await res.json();

  return data;
};

export default getRoomAccessData;
