import config from "config";
import { getCookieByName } from "func/getCookieByName";

type ResType = {
  detail?: string;
  success?: boolean;
};

const endMeeting = async (roomName: string) => {
  const cookie = getCookieByName("csrftoken");
  const res = await fetch(
    `${config.baseUrl}/gapi/guided-viewing/${roomName}/complete/`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        ...(cookie && { "X-CSRFToken": cookie }),
      },
    }
  );
  const data: ResType = await res.json();
  return data;
};

export default endMeeting;
