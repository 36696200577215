import earcut from 'earcut';
import type opentype from 'opentype.js';

import Polygon from './polygon';

export type Point = { x: number; y: number };

export type BoundingBox = {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
};

export const distance = (p1: Point, p2: Point) => {
  const dx = p1.x - p2.x;
  const dy = p1.y - p2.y;
  return Math.sqrt(dx * dx + dy * dy);
};

export const lerp = (p1: Point, p2: Point, t: number) => ({
  x: (1 - t) * p1.x + t * p2.x,
  y: (1 - t) * p1.y + t * p2.y,
});

export const cross = (p1: Point, p2: Point) => p1.x * p2.y - p1.y * p2.x;

const getWordWidth = (fontData: opentype.Font, wordToProcess: string, advanceWidth = false) =>
  fontData.stringToGlyphs(wordToProcess).reduce((acc, glyph) => {
    if (advanceWidth) {
      return acc + (glyph.advanceWidth ?? 0); // includes spaces
    }

    return acc + (glyph?.xMax ?? 0) - (glyph?.xMin ?? 0);
  }, 0);

export const splitTextIntoLines = (text: string, fontData: opentype.Font, maxWidth: number, fontSize: number) => {
  const unitsPerEm = fontData.unitsPerEm;

  const textLines: string[] = [];

  const paragraphs = text.split('\n');
  paragraphs.forEach((paragraph) => {
    let currentLineText = '';
    let currentWidth = 0;

    const words = paragraph.split(' ');
    words.forEach((word) => {
      // add space if not last word
      const wordToProcess = `${word} `;

      const wordWidth = getWordWidth(fontData, wordToProcess, true);
      const wordWidthInPixels = (wordWidth * fontSize) / unitsPerEm;

      if (currentWidth + wordWidthInPixels > maxWidth) {
        // New Line
        textLines.push(currentLineText.trim());

        currentWidth = wordWidthInPixels;
        currentLineText = wordToProcess;
      } else {
        // Continue current line
        currentLineText += wordToProcess;
        currentWidth += wordWidthInPixels;
      }
    });

    if (currentLineText.length > 0) {
      if (currentLineText.slice(-1) === ' ') {
        currentLineText = currentLineText.slice(0, -1);
      }
      textLines.push(currentLineText.trim());
    }
  });

  let widestLineWidth = 0;
  const lineDataWithWidth = textLines.map((lineText) => {
    const wordWidth = (getWordWidth(fontData, lineText, true) * fontSize) / unitsPerEm;

    widestLineWidth = Math.max(widestLineWidth, wordWidth);

    return {
      width: wordWidth,
      text: lineText,
    };
  });

  const lines = lineDataWithWidth.map((line) => ({
    text: line.text,
    xOffset: (widestLineWidth - line.width) / 2,
  }));

  return lines;
};

export const mergeBoundingBoxes = (boundingBoxes: BoundingBox[]) => {
  if (boundingBoxes.length === 0) {
    return { x1: 0, y1: 0, x2: 0, y2: 0 };
  }

  let minX = boundingBoxes[0].x1;
  let minY = boundingBoxes[0].y1;
  let maxX = boundingBoxes[0].x2;
  let maxY = boundingBoxes[0].y2;

  boundingBoxes.forEach((box) => {
    if (box.x1 < minX) minX = box.x1;
    if (box.y1 < minY) minY = box.y1;
    if (box.x2 > maxX) maxX = box.x2;
    if (box.y2 > maxY) maxY = box.y2;
  });

  return {
    x1: minX,
    y1: minY,
    x2: maxX,
    y2: maxY,
  };
};

type PathCommand = {
  type: string;
  x: number;
  y: number;
  x1: number;
  x2: number;
  y1: number;
  y2: number;
};

export const getTextData = (inputText: string, fontData: opentype.Font, fontSize: number, maxWidth: number) => {
  const lines = splitTextIntoLines(inputText, fontData, maxWidth, fontSize);

  const lineSize = fontSize * 1.5;
  let yOffset = -Math.round((lines.length - 1) * lineSize);

  const polys: Polygon[] = [];
  const boundingBoxes: BoundingBox[] = [];

  lines.forEach(({ text, xOffset }, indx: number) => {
    const path = fontData.getPath(text, xOffset, yOffset, fontSize);

    const boundingBox = path.getBoundingBox();

    // - do not calculate height from font data (can be different for different letters), but set line height calculated from font size
    // - do not need full line size for last line, otherwise a uneeded padding will be added at the top (lines are reversed due to clipspace y axis)
    boundingBox.y2 = boundingBox.y1 + (lines.length - 1 === indx ? fontSize : lineSize);

    boundingBoxes.push(boundingBox);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    path.commands.forEach(({ type, x, y, x1, y1, x2, y2 }: PathCommand) => {
      switch (type) {
        case 'M':
          polys.push(new Polygon());
          polys[polys.length - 1].moveTo({ x, y });
          break;
        case 'L':
          polys[polys.length - 1].moveTo({ x, y });
          break;
        case 'C':
          polys[polys.length - 1].cubicTo({ x, y }, { x: x1, y: y1 }, { x: x2, y: y2 });
          break;
        case 'Q':
          polys[polys.length - 1].conicTo({ x, y }, { x: x1, y: y1 });
          break;
        case 'Z':
          polys[polys.length - 1].close();
          break;
        default:
          break;
      }
    });
    yOffset += lineSize;
  });

  // sort contours by descending area
  polys.sort((a, b) => Math.abs(b.area) - Math.abs(a.area));
  // classify contours to find holes and their 'parents'
  const root: Polygon[] = [];
  for (let i = 0; i < polys.length; i += 1) {
    let parent: Polygon | null = null;
    for (let j = i - 1; j >= 0; j -= 1) {
      // a contour is a hole if it is inside its parent and has different winding
      if (polys[j].inside(polys[i].points[0]) && polys[i].area * polys[j].area < 0) {
        parent = polys[j];
        break;
      }
    }
    if (parent) {
      parent.children.push(polys[i]);
    } else {
      root.push(polys[i]);
    }
  }

  const totalPoints = polys.reduce((sum, p) => sum + p.points.length, 0);
  const vertexData = new Float32Array(totalPoints * 2);
  let vertexCount = 0;
  const indices: number[] = [];

  const process = (poly: Polygon) => {
    // construct input for earcut
    const coords: number[] = [];
    const holes: number[] = [];
    poly.points.forEach(({ x, y }) => coords.push(x, y));
    poly.children.forEach((child) => {
      // children's children are new, separate shapes
      child.children.forEach(process);

      holes.push(coords.length / 2);
      child.points.forEach(({ x, y }) => coords.push(x, y));
    });

    // add vertex data
    vertexData.set(coords, vertexCount * 2);
    // add index data
    earcut(coords, holes).forEach((i: number) => indices.push(i + vertexCount));
    vertexCount += coords.length / 2;
  };

  root.forEach(process);

  return {
    vertexData,
    indices: new Uint16Array(indices),
    boundingBox: mergeBoundingBoxes(boundingBoxes),
  };
};
