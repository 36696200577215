import type { AnyScalar, ClipSpace, Pixel, Pos, Size } from '@g360/vt-types';

export const always: (res: any) => (...args: any) => any =
  (res = undefined) =>
  () =>
    res;

export const noAction = always(undefined);

export const sleep = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

function isPointInsideShape<TPos extends Pos<AnyScalar>>(point: TPos, shape: TPos[]): boolean {
  let inside = false;
  const x = point.x;
  const y = point.y;
  // eslint-disable-next-line no-plusplus
  for (let i = 0, j = shape.length - 1; i < shape.length; j = i++) {
    const xi = shape[i].x;
    const yi = shape[i].y;
    const xj = shape[j].x;
    const yj = shape[j].y;

    const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) {
      inside = !inside;
    }
  }

  return inside;
}

export function isThis3DHotSpotHovered(
  mouseClipSpace: Pos<ClipSpace>,
  clipSpacePosAround: Pos<ClipSpace>[] | null
): boolean {
  if (clipSpacePosAround) {
    if (isPointInsideShape<Pos<ClipSpace>>(mouseClipSpace, clipSpacePosAround)) {
      return true;
    }
  }
  return false;
}

export function isThisSpriteHotSpotHovered(
  clipSpacePos: Pos<ClipSpace> | null,
  clipSpaceSize: Size | null,
  mousePixels: Pos<Pixel>,
  screenSize: Size
): boolean {
  if (clipSpacePos && clipSpaceSize) {
    const radius = ((clipSpaceSize.width / 2) * screenSize.width) / 2;
    const px = ((clipSpacePos.x + 1) / 2) * screenSize.width;
    const py = screenSize.height - ((clipSpacePos.y + 1) / 2) * screenSize.height;
    const distance = (mousePixels.x - px) ** 2 + (mousePixels.y - py) ** 2;
    if (distance < radius ** 2) {
      return true;
    }
  }

  return false;
}
