/**
 * Error to throw when the verification of the gated tour fails.
 */
class VerificationFailedError extends Error {
    /** @param message - `string`: The error message. Default is 'Verification failed'. */
    constructor(message) {
        super(message !== null && message !== void 0 ? message : 'Verification failed');
        this.name = 'VerificationFailedError';
    }
}
export default VerificationFailedError;
