import { jsx as _jsx } from "react/jsx-runtime";
import useServices from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
const GradientBottom = () => {
    const { tourConfigService } = useServices();
    const { theme } = useReactive(tourConfigService, ['theme']);
    const twGradient = theme === 'light' ? 'bottom-gradient-light' : 'bottom-gradient-dark';
    return _jsx("div", { className: `absolute bottom-0 -z-0 h-[10rem] w-full ${twGradient}` });
};
export default GradientBottom;
