import { useDeviceStateContext } from "hooks";
import { useEffect, useState } from "react";
import {
  createLocalAudioTrack,
  createLocalVideoTrack,
  CreateLocalTrackOptions,
  LocalAudioTrack,
  LocalDataTrack,
  LocalVideoTrack,
} from "twilio-video";

const useLocalTracks = () => {
  // Thus this hook needs to be called under the DeviceStatusContextProvider
  const { selectedAudioInput, selectedVideoInput, audioAccess, videoAccess } =
    useDeviceStateContext();

  const [localVideoTrack, setLocalVideoTrack] = useState<LocalVideoTrack>();
  const [isAcquiringLocalVideoTrack, setIsAcquiringLocalVideoTrack] =
    useState(false);
  const [localAudioTrack, setLocalAudioTrack] = useState<LocalAudioTrack>();
  const [isAcquiringLocalAudioTrack, setIsAcquiringLocalAudioTrack] =
    useState(false);
  const [localDataTrack] = useState<LocalDataTrack>(() => new LocalDataTrack());

  const getLocalAudioTrack = async () => {
    if (!selectedAudioInput || isAcquiringLocalAudioTrack || !audioAccess)
      return Promise.resolve(undefined);
    setIsAcquiringLocalAudioTrack(true);

    const options: CreateLocalTrackOptions = {};
    if (selectedAudioInput) {
      options.deviceId = { exact: selectedAudioInput.deviceId };
    }

    if (localAudioTrack) {
      return localAudioTrack
        .restart(options)
        .then(() => {
          setIsAcquiringLocalAudioTrack(false);
          return localAudioTrack;
        })
        .finally(() => setIsAcquiringLocalAudioTrack(false));
    }

    return createLocalAudioTrack(options)
      .then((newTrack) => {
        setLocalAudioTrack(newTrack);
        setIsAcquiringLocalAudioTrack(false);
        return newTrack;
      })
      .finally(() => setIsAcquiringLocalAudioTrack(false));
  };

  const getLocalVideoTrack = async () => {
    if (!selectedVideoInput || isAcquiringLocalVideoTrack || !videoAccess)
      return Promise.resolve(undefined);
    setIsAcquiringLocalVideoTrack(true);

    const options: CreateLocalTrackOptions = {
      name: `camera-${Date.now()}`,
    };
    if (selectedVideoInput) {
      options.deviceId = { exact: selectedVideoInput.deviceId };
    }

    if (localVideoTrack) {
      return localVideoTrack
        .restart(options)
        .then(() => {
          setIsAcquiringLocalVideoTrack(false);
          return localVideoTrack;
        })
        .finally(() => setIsAcquiringLocalVideoTrack(false));
    }

    return createLocalVideoTrack(options)
      .then((newTrack) => {
        setLocalVideoTrack(newTrack);
        setIsAcquiringLocalVideoTrack(false);
        return newTrack;
      })
      .finally(() => setIsAcquiringLocalVideoTrack(false));
  };

  const removeLocalAudioTrack = () => {
    if (localAudioTrack) {
      localAudioTrack.stop();
      setLocalAudioTrack(undefined);
    }
  };

  const removeLocalVideoTrack = () => {
    if (localVideoTrack) {
      localVideoTrack.stop();
      setLocalVideoTrack(undefined);
    }
  };

  const localTracks = [localAudioTrack, localVideoTrack, localDataTrack].filter(
    (track) => track !== undefined
  ) as (LocalAudioTrack | LocalVideoTrack | LocalDataTrack)[];

  return {
    localTracks,
    localVideoTrack,
    isAcquiringLocalVideoTrack,
    getLocalVideoTrack,
    removeLocalVideoTrack,
    localAudioTrack,
    isAcquiringLocalAudioTrack,
    getLocalAudioTrack,
    removeLocalAudioTrack,
    localDataTrack,
  };
};

export default useLocalTracks;
