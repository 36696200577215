export const getDistanceBetweenTouches = (touches) => {
    const touch1 = touches[0];
    const touch2 = touches[1];
    return Math.sqrt(Math.pow((touch2.clientX - touch1.clientX), 2) + Math.pow((touch2.clientY - touch1.clientY), 2));
};
const noResult = { size: null, center: null };
export const getFloorPropertiesFromTour = (structure, buildingKey) => {
    const building = structure.buildings[buildingKey];
    if (!building)
        return noResult;
    return {
        size: building.size,
        center: building.center,
    };
};
