/* eslint-disable @typescript-eslint/no-unused-vars,@typescript-eslint/ban-ts-comment,arrow-body-style,no-continue */

import type { DataJsonType, DebugPointOfInterest, Graph, Panos } from '@g360/vt-types';

import { angleDifference, getPanoExitAngle, offsetPoint, rotatePoint, tryGetRoomInfo } from './geometryUtils';
import { getAPanoBySubGraphId, getPanoGeometricDistance } from './graphUtils';

export const getRndColor = (inputNum: number, saturation = 0.5, lightness = 0.6) => {
  type RGB = {
    r: number;
    g: number;
    b: number;
  };

  const numberToSeed = (num: number): number => {
    const x = Math.sin(num ** 2) * Math.tan(num) * 10000;
    return x - Math.floor(x);
  };

  const hslToRgb = (h: number, s: number, l: number): RGB => {
    const a = s * Math.min(l, 1 - l);
    const f = (n: number, k = (n + h / 30) % 12) => l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return { r: Math.round(f(0) * 255), g: Math.round(f(8) * 255), b: Math.round(f(4) * 255) };
  };

  const rgbToHex = (rgb: RGB): string => {
    const toHex = (value: number): string => value.toString(16).padStart(2, '0');
    return `#${toHex(rgb.r)}${toHex(rgb.g)}${toHex(rgb.b)}`;
  };

  const getColorFromNumber = (num: number): string => {
    const seed = numberToSeed(num);
    const hue = seed * 360;
    const rgbColor = hslToRgb(hue, saturation, lightness);
    return rgbToHex(rgbColor);
  };

  return getColorFromNumber(inputNum);
};

export const debugVisualizeGraph = (
  unqI: string,
  panos: Panos,
  graph: Graph,
  subGraphId: string | undefined = undefined,
  canvasScale = 5,
  top = 0,
  left = 0,
  labelText = '',
  path: string[] | undefined = undefined
) => {
  const id = `debugVisualize${unqI}`;

  if (typeof window === 'undefined') return;

  // @ts-ignore
  if (!window.debugVisualizeGraphIntervals) {
    // @ts-ignore
    window.debugVisualizeGraphIntervals = [];
  }

  // @ts-ignore
  const lastInterval = window.debugVisualizeGraphIntervals[id];
  if (lastInterval) {
    clearInterval(lastInterval); // stop animation
  }

  let canvas = document.getElementById(id) as HTMLCanvasElement;
  if (!canvas) {
    canvas = document.createElement('canvas');
    canvas.id = id;
    canvas.width = 100 * canvasScale;
    canvas.height = 100 * canvasScale;
    canvas.style.position = 'absolute';
    canvas.style.pointerEvents = 'none';
    canvas.style.top = `${top}px`;
    canvas.style.left = `${left}px`;
    canvas.style.zIndex = '40';
    document.body.appendChild(canvas);
  }

  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

  // Find bounding box
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;
  const padding = 100;

  Object.keys(panos).forEach((sceneKey) => {
    const pano = panos[sceneKey];
    if (subGraphId && pano.subGraphId !== subGraphId) return;
    minX = Math.min(minX, pano.camera[0] - padding);
    minY = Math.min(minY, pano.camera[1] - padding);
    maxX = Math.max(maxX, pano.camera[0] + padding);
    maxY = Math.max(maxY, pano.camera[1] + padding);
  });

  const boundingBoxWidth = maxX - minX;
  const boundingBoxHeight = maxY - minY;

  // Calculate scale
  const canvasSize = 100 * canvasScale;
  const scaleX = canvasSize / boundingBoxWidth;
  const scaleY = canvasSize / boundingBoxHeight;
  const scale = Math.min(scaleX, scaleY); // Use the smaller scale to ensure all points fit

  // scale from property coords to canvas coords
  function sx(x: number) {
    return (x - minX) * scale;
  }

  function sy(y: number) {
    return (y - minY) * scale;
  }

  function drawPoint(x: number, y: number, color = 'blue', radius = 5) {
    ctx.beginPath();
    ctx.arc(sx(x), sy(y), radius, 0, 2 * Math.PI);
    ctx.fillStyle = color;
    ctx.strokeStyle = color;
    ctx.fill();
    ctx.stroke();
  }

  function drawLine(x1: number, y1: number, x2: number, y2: number, color = 'red') {
    ctx.beginPath();
    ctx.moveTo(sx(x1), sy(y1));
    ctx.lineTo(sx(x2), sy(y2));
    ctx.strokeStyle = color;
    ctx.stroke();
  }

  function drawText(x: number, y: number, text: string, color = 'red', size = 18) {
    ctx.font = `${size}px serif`;
    ctx.fillStyle = color;
    ctx.fillText(text, x, y);
  }

  let x1 = 0;
  let y1 = 0;
  graph.forEach((destinations, sceneKey) => {
    const pano = panos[sceneKey];
    x1 = pano.camera[0];
    y1 = pano.camera[1];

    const color = pano.outside ? '#656565' : getRndColor(parseInt(pano.subGraphId || '-1', 10));

    destinations.forEach((destSceneKey) => {
      const destPano = panos[destSceneKey];
      if (!destPano) return;
      const x2 = destPano.camera[0];
      const y2 = destPano.camera[1];
      drawLine(x1, y1, x2, y2, color);
    });

    drawPoint(x1, y1, color);
    if (!path) {
      const parts = sceneKey.split('-');
      const text = parts[parts.length - 1].replace('PAN', 'P');
      drawText(sx(x1 - 110), sy(y1 + 10), text, '#8200b6', 15);
    }
  });

  // if (labelText) {
  //   drawText(s(x1), s(y1), labelText);
  // }

  if (path) {
    const drawMovingDotAlongPath = (
      pathX: string[],
      panosXX: Panos,
      speed: number // Speed in units per millisecond
    ) => {
      const pathXX: string[] = [...pathX];
      for (let i = 0; i < 50; i += 1) {
        // duplicate last node to make the dot stay on the last node
        pathXX.push(pathXX[pathXX.length - 1]);
      }

      const drawDotsOnVisitedNodes = (index: number, color: string) => {
        for (let i = 0; i <= index; i += 1) {
          const node = panosXX[pathXX[i]];
          drawPoint(node.camera[0] - 2 * scale, node.camera[1] - 2 * scale, color, 8);
        }
      };

      const drawDotBetweenPoints = (
        // eslint-disable-next-line @typescript-eslint/no-shadow
        x1: number,
        // eslint-disable-next-line @typescript-eslint/no-shadow
        y1: number,
        x2: number,
        y2: number,
        startTime: number,
        endTime: number,
        nextIndex: number
      ) => {
        const currentTime = Date.now();
        if (currentTime > endTime) {
          // Move to the next segment of the path
          if (nextIndex < pathXX.length) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            animatePathSegment(nextIndex);
          }
          return;
        }

        if (typeof window === 'undefined') return;

        const t = (currentTime - startTime) / (endTime - startTime);
        const x = x1 + t * (x2 - x1);
        const y = y1 + t * (y2 - y1);

        drawPoint(x, y, '#e712ff', 4);
        drawDotsOnVisitedNodes(nextIndex - 1, 'rgb(255,255,255)');

        window.requestAnimationFrame(() => {
          ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
          drawDotBetweenPoints(x1, y1, x2, y2, startTime, endTime, nextIndex);
          drawLine(x1, y1, x2, y2, 'pink');
        });
      };

      const animatePathSegment = (index: number) => {
        const nodeKey = pathXX[index];
        const nextNodeKey = pathXX[index + 1];

        const pano = panosXX[nodeKey];
        const nextPano = panosXX[nextNodeKey];

        if (!pano || !nextPano) {
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-shadow
        const [x1, y1] = pano.camera;
        const [x2, y2] = nextPano.camera;

        // eslint-disable-next-line no-restricted-properties
        const distance = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
        const travelTime = distance / speed;

        const startTime = Date.now();
        const endTime = startTime + travelTime * 1000;

        drawDotBetweenPoints(x1, y1, x2, y2, startTime, endTime, index + 1);
      };

      animatePathSegment(0);
    };

    // get path length by adding all distances between path nodes

    let totalDistance = 0;
    for (let i = 0; i < path.length - 1; i += 1) {
      totalDistance += getPanoGeometricDistance(path[i], path[i + 1], panos);
    }

    const speed = 500;
    const pause = 1000; // between restarts
    const travelTime = (totalDistance / speed) * 1000 + pause;
    const interval = setInterval(() => {
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      drawMovingDotAlongPath(path, panos, speed);
    }, travelTime);
    drawMovingDotAlongPath(path, panos, speed);

    // @ts-ignore
    window.debugVisualizeGraphIntervals[id] = interval;

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  }
};

export const debugPrintSubGraphFromArray = (superGraph: string[], panos: Panos, simple = false) => {
  Array.from(superGraph.values()).forEach((subGraphId) => {
    const pano = getAPanoBySubGraphId(panos, subGraphId);
    if (!pano) return;
    if (simple) {
      console.log(`#${subGraphId}`);
    } else {
      console.log(`#${subGraphId}`, pano.outside ? 'outside' : `${pano.building}/${pano.floor}`);
    }
  });
};

export const debugPrintPanoPath = (path: string[] | undefined, panos: Panos) => {
  if (!path) return;
  path.forEach((sceneKey) => {
    const pano = panos[sceneKey];
    if (!pano) return;
    console.log(sceneKey, pano.outside ? 'outside' : `${pano.building}/${pano.floor}`, `#${pano.subGraphId}`);
  });
};

type Line = {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  color: string;
  width: number;
  fx?: number[];
};

type Circle = {
  x: number;
  y: number;
  r: number;
  color: string;
  radius: number;
};
type Cone = {
  x: number;
  y: number;
  direction: number; // camera yaw in radians
  fov: number; // camera fov in radians
};
type Text = {
  x: number;
  y: number;
  text: string;
  color: string;
};
const drawPrimitives = (lines: Line[], circles: Circle[], texts: Text[], cone?: Cone) => {
  const unqI = '00';
  const canvasScale = 6;
  const top = 0;
  const left = 400;

  const id = `debugFloorPlan${unqI}`;
  let canvas = document.getElementById(id) as HTMLCanvasElement;
  if (!canvas) {
    canvas = document.createElement('canvas');
    canvas.id = id;
    canvas.width = 100 * canvasScale;
    canvas.height = 100 * canvasScale;
    canvas.style.position = 'absolute';
    canvas.style.pointerEvents = 'none';
    canvas.style.top = `${top}px`;
    canvas.style.left = `${left}px`;
    canvas.style.zIndex = '40';
    document.body.appendChild(canvas);
  }

  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

  // Find bounding box
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;
  const padding = 1;

  lines.forEach((line) => {
    minX = Math.min(minX, line.x1 - padding);
    minY = Math.min(minY, line.y1 - padding);
    minX = Math.min(minX, line.x1 - padding);
    minY = Math.min(minY, line.y1 - padding);

    maxX = Math.max(maxX, line.x2 + padding);
    maxY = Math.max(maxY, line.y2 + padding);
    maxX = Math.max(maxX, line.x2 + padding);
    maxY = Math.max(maxY, line.y2 + padding);
  });

  const boundingBoxWidth = maxX - minX;
  const boundingBoxHeight = maxY - minY;

  // Calculate scale
  const canvasSize = 100 * canvasScale;
  const scaleX = canvasSize / boundingBoxWidth;
  const scaleY = canvasSize / boundingBoxHeight;
  const scale = Math.min(scaleX, scaleY); // Use the smaller scale to ensure all points fit

  // scale from property coords to canvas coords
  function sx(x: number) {
    return (x - minX) * scale;
  }

  function sy(y: number) {
    return (y - minY) * scale;
  }

  function drawLine(line: Line) {
    const { x1, y1, x2, y2, color, width, fx } = line;
    ctx.beginPath();
    ctx.moveTo(sx(x1), sy(y1));
    ctx.lineTo(sx(x2), sy(y2));
    ctx.strokeStyle = color;
    ctx.lineWidth = width;
    ctx.setLineDash(fx || []);
    ctx.stroke();
  }

  function drawCircle(circle: Circle) {
    const { x, y, color, radius } = circle;
    ctx.beginPath();
    ctx.arc(sx(x), sy(y), radius, 0, 2 * Math.PI);
    ctx.fillStyle = color;
    ctx.strokeStyle = color;
    ctx.fill();
    ctx.stroke();
  }

  function drawText(text: Text) {
    const { x, y, text: textStr, color } = text;
    const fontSize = 3 * canvasScale;
    ctx.font = `${fontSize}px sans-serif`;
    ctx.fillStyle = color;
    ctx.fillText(textStr, sx(x) - 1 * canvasScale, sy(y) - 1.5 * canvasScale);
  }

  // draw cone first
  if (cone) {
    const point = { x: cone.x, y: cone.y };
    const directionAngle = cone.direction;
    const fov = cone.fov;
    const coneLength = 6;
    const numberOfRays = fov * 20;
    const angleBetweenRays = fov / (numberOfRays - 1);

    for (let i = 0; i < numberOfRays; i += 1) {
      const rayAngle = directionAngle - fov / 2 + i * angleBetweenRays;
      const end = {
        x: point.x + coneLength * Math.cos(rayAngle),
        y: point.y + coneLength * Math.sin(rayAngle),
      };

      try {
        const gradient = ctx.createLinearGradient(sx(point.x), sy(point.y), sx(end.x), sy(end.y));

        gradient.addColorStop(0, 'rgba(255,0,0,0.39)'); // Red at the origin
        gradient.addColorStop(1, 'rgba(255, 0, 0, 0)'); // Transparent at the end

        ctx.beginPath();
        ctx.moveTo(sx(point.x), sy(point.y));
        ctx.lineTo(sx(end.x), sy(end.y));
        ctx.strokeStyle = gradient;
        ctx.lineWidth = 1;
        ctx.stroke();
      } catch (e) {
        // gradient has negative number, just ignore
      }
    }
  }

  lines.forEach((line) => {
    drawLine(line);
  });

  circles.forEach((circle) => {
    drawCircle(circle);
  });

  texts.forEach((text) => {
    drawText(text);
  });
};

function movePointAtAngle(x: number, y: number, angle: number, dist: number): [number, number] {
  const x2 = x + dist * Math.cos(angle);
  const y2 = y + dist * Math.sin(angle);
  return [x2, y2];
}

let buildingCacheKey = '-';
let cachedLinesForBuilding: Line[] = [];
let cacheCirclesForBuilding: Circle[] = [];
let cacheTextsForBuilding: Text[] = [];
let pathCacheKey = '-';
let cachedPathLines: Line[] = [];
let cachedPathTexts: Text[] = [];
let hotSpotsCacheKey = '-';
let cachedHotSpotLines: Line[] = [];

export const debugDrawFloorPlan = (
  dataJson: DataJsonType | null,
  panos: Panos,
  path: string[],
  debugPointsOfInterest: DebugPointOfInterest[],
  currentSceneKey: string,
  projectStartAngle: number,
  fov?: number,
  yaw?: number,
  actualCameraPos?: number[]
) => {
  if (dataJson === null) return;

  const currentPano = panos[currentSceneKey];
  const currentBuilding = currentPano.building;
  const currentFloor = currentPano.floor;
  // const currentOutside = currentPano.outside;
  // if (currentOutside) return;

  const lines: Line[] = [];
  const circles: Circle[] = [];
  const texts: Text[] = [];

  // ---------------------------------------------------------------
  // recreate lines/circles specific for each sceneKey
  // contours for current building/floor, highlighting current room
  // ---------------------------------------------------------------
  // const sceneKeyCacheKey = `${currentBuilding}_${currentFloor}_${currentOutside}`;
  const sceneKeyCacheKey = currentSceneKey; // if highlighting current room, then need to redraw on each pano change
  if (sceneKeyCacheKey !== buildingCacheKey) {
    buildingCacheKey = sceneKeyCacheKey;
    cachedLinesForBuilding = [];
    cacheCirclesForBuilding = [];
    cacheTextsForBuilding = [];

    // contours
    Object.keys(dataJson.contours).forEach((contourId) => {
      const contour = dataJson.contours[contourId];
      const room = dataJson.rooms[contour.room_id];
      const panoIdsInRoom = room.id_name ? [room.id_name] : room.merged_rooms || [];
      const floor = room.floor.toString();
      const building = room.building.toString();

      if (currentBuilding !== building || currentFloor !== floor) return;

      const { roomRotation, roomOffset, failed } = tryGetRoomInfo(dataJson, contour.room_id);
      if (failed) {
        // no room rotation/offset data for this contour
        return;
      }

      // make lines from room corners
      for (let i = 0; i < contour.corners.length; i += 1) {
        const currentPanoInThisRoom = panoIdsInRoom.includes(currentSceneKey);
        const corner = contour.corners[i];
        const nextCorner = contour.corners[(i + 1) % contour.corners.length];
        const c = rotatePoint(roomRotation, corner?.position_m || corner); // in old data.json (we don't support) corner is "number[]" and sometimes it's "{position_m: number[]}"
        const cNext = rotatePoint(roomRotation, nextCorner?.position_m || corner);
        try {
          cachedLinesForBuilding.push({
            x1: c[0] + roomOffset[0],
            y1: c[1] + roomOffset[1],
            x2: cNext[0] + roomOffset[0],
            y2: cNext[1] + roomOffset[1],
            color: currentPanoInThisRoom ? 'red' : 'grey',
            width: 1,
          } as Line);
        } catch (e) {
          console.log("don't care", e);
        }
      }
    });

    // portals for current building/floor, highlighting current room
    Object.keys(dataJson.portals).forEach((portalId) => {
      const portal = dataJson.portals[portalId];
      const room = dataJson.rooms[portal.room_id];
      const panoIdsInRoom = room.id_name ? [room.id_name] : room.merged_rooms || [];
      const floor = room.floor.toString();
      const building = room.building.toString();

      if (currentBuilding !== building || currentFloor !== floor) return;

      const { roomRotation, roomOffset } = tryGetRoomInfo(dataJson, portal.room_id);
      const currentPanoInThisRoom = panoIdsInRoom.includes(currentSceneKey);
      let color: string;
      if (portal.type === 'door') {
        color = currentPanoInThisRoom ? '#27b900' : '#2f4900';

        if (!portal.matched) {
          // @todo -- maybe mid point of the door?
          const p = offsetPoint(rotatePoint(roomRotation, portal.bot_left_3d_m), roomOffset);
          cacheTextsForBuilding.push({
            x: p[0],
            y: p[1],
            text: (portal.matched ? portal.matched_id : '--') + (portal.open ? 'O' : ''),
            color: portal.matched ? color : '#27d7ff',
          });
        }
      } else if (portal.type === 'window') {
        color = currentPanoInThisRoom ? '#0000e0' : '#000f59';
      } else if (portal.type === 'niche') {
        color = currentPanoInThisRoom ? '#ff9a27' : '#8a3e00';
      } else if (portal.type === 'open_space') {
        color = currentPanoInThisRoom ? '#84eaff' : '#7aacb6';
      } else {
        console.log('debugDrawFloorPlan::unknown portal type:', portal.type);
        color = '#f34eff';
      }

      const c = rotatePoint(roomRotation, portal.bot_left_3d_m);
      const cNext = rotatePoint(roomRotation, portal.bot_right_3d_m);
      cachedLinesForBuilding.push({
        x1: c[0] + roomOffset[0],
        y1: c[1] + roomOffset[1],
        x2: cNext[0] + roomOffset[0],
        y2: cNext[1] + roomOffset[1],
        color,
        width: 4,
      } as Line);

      // -----
      // if (portal.debug) {
      //   color = '#ffce00';
      //
      //   const p = offsetPoint(rotatePoint(roomRotation, portal.bot_left_3d_m), roomOffset);
      //   cacheTextsForBuilding.push({
      //     x: p[0],
      //     y: p[1],
      //     text: portal.debug,
      //     color,
      //   });
      // }

      // if (portal.debug2) {
      //   color = '#99ff00';
      //
      //   const p = offsetPoint(rotatePoint(roomRotation, portal.bot_left_3d_m), roomOffset);
      //   cacheTextsForBuilding.push({
      //     x: p[0],
      //     y: p[1],
      //     text: portal.debug2,
      //     color,
      //   });
      // }
    });

    // panos and points of interest
    Object.keys(panos).forEach((sceneKey) => {
      const pano = panos[sceneKey];
      const floor = pano.floor?.toString() || '-';
      const building = pano.building?.toString();

      if (pano.outside) return;
      if (currentBuilding !== building || currentFloor !== floor) return;
      const color = currentSceneKey === sceneKey ? 'red' : 'grey';
      const originX = pano.camera[0] / 100; // data.json uses meters, tour.json uses centimeters
      const originY = pano.camera[1] / 100;

      cacheCirclesForBuilding.push({
        x: originX,
        y: originY,
        radius: 3,
        color,
      } as Circle);

      // draw points of interest:
      // doors or just look-around
      const interestingPoints = debugPointsOfInterest.filter((poi) => poi.sceneKey === sceneKey);
      interestingPoints.forEach((poi) => {
        let fx = [2, 2]; // "nothing", boring look-around
        let width = 2;
        if (poi.type === 'entrance') {
          fx = [5, 5];
          width = 4;
        } else if (poi.type === 'doors') {
          fx = [2, 2];
          width = 4;
        } else if (poi.type === 'unknown') {
          fx = [2, 2, 5];
        }

        const [x, y] = movePointAtAngle(originX, originY, poi.yaw, 2);
        cachedLinesForBuilding.push({
          x1: originX,
          y1: originY,
          x2: x,
          y2: y,
          color: 'rgba(193,65,234,0.44)',
          fx,
          width,
        });
      });
    });

    // exit directions from pano, if different from moving straight to the next pano
    for (let i = 0; i < path.length; i += 1) {
      const pano = panos[path[i]];
      const floor = pano.floor?.toString() || '-';
      const building = pano.building?.toString();

      if (pano.outside) continue;
      if (currentBuilding !== building || currentFloor !== floor) continue;
      const originX = pano.camera[0] / 100; // data.json uses meters, tour.json uses centimeters
      const originY = pano.camera[1] / 100;

      const exitAnglePortal = getPanoExitAngle(path, i, panos, dataJson, projectStartAngle);
      const exitAngleBasic = getPanoExitAngle(path, i, panos, dataJson, projectStartAngle, true);

      if (angleDifference(exitAngleBasic.yaw, exitAnglePortal.yaw) > 0.1) {
        const angle = exitAnglePortal.yaw - Math.PI / 2; // half-a-radian is needed cos the floorplan axis is rotated 90deg
        const [x, y] = movePointAtAngle(originX, originY, angle, 1.2);
        cachedLinesForBuilding.push({
          x1: originX,
          y1: originY,
          x2: x,
          y2: y,
          color: '#e20afa',
          // color: 'rgb(0,0,0)',
          fx: [],
          width: 1,
        });
      }
    }
  }
  // use cached lines/circles
  lines.push(...cachedLinesForBuilding);
  circles.push(...cacheCirclesForBuilding);
  texts.push(...cacheTextsForBuilding);

  // ---------------------------------------------------------------
  // path
  // ---------------------------------------------------------------
  const currentPathCacheKey = path.join('') + buildingCacheKey;
  if (currentPathCacheKey !== pathCacheKey) {
    pathCacheKey = currentPathCacheKey;
    cachedPathLines = [];
    cachedPathTexts = [];

    // path lines
    for (let i = 0; i < path.length; i += 1) {
      const pathPano = panos[path[i]];
      const pathPanoNext = panos[path[(i + 1) % path.length]];
      if (pathPano.outside || pathPanoNext.outside) continue; // @todo -- draw line to outside? or at least mark it somehow
      const pathFloor = pathPano.floor?.toString() || '-';
      const nextPathFloor = pathPanoNext.floor?.toString() || '-';
      const pathBuilding = pathPano.building?.toString();
      const nextPathBuilding = pathPanoNext.building?.toString();
      if (pathBuilding !== currentBuilding || pathFloor !== currentFloor) continue;
      if (nextPathBuilding !== currentBuilding || nextPathFloor !== currentFloor) continue;
      cachedPathLines.push({
        x1: pathPano.camera[0] / 100,
        y1: pathPano.camera[1] / 100,
        x2: pathPanoNext.camera[0] / 100,
        y2: pathPanoNext.camera[1] / 100,
        color: 'rgba(193,65,234,0.44)',
        width: 2,
      } as Line);
    }

    const panoTexts = new Map<string, string[]>();

    // path node texts
    for (let i = 0; i < path.length; i += 1) {
      const pathPano = panos[path[i]];
      if (pathPano.outside) continue;

      const pathFloor = pathPano.floor?.toString() || '-';
      const pathBuilding = pathPano.building?.toString();
      if (pathBuilding !== currentBuilding || pathFloor !== currentFloor) continue;

      const pathNextPano = panos[path[(i + 1) % path.length]];
      const pathPrevPano = panos[path[(i + 1) % path.length]];
      const pathNextFloor = pathNextPano.floor?.toString() || '-';
      const pathNextBuilding = pathNextPano.building?.toString();
      const pathPrevFloor = pathPrevPano.floor?.toString() || '-';
      const pathPrevBuilding = pathPrevPano.building?.toString();
      const pathPrevOutside = pathPrevPano.outside;
      const pathNextOutside = pathNextPano.outside;

      let textsForPano: string[] = [];
      if (panoTexts.has(path[i])) {
        textsForPano = panoTexts.get(path[i]) || [];
      }

      if (i === 0) {
        textsForPano.push('◎'); // start pano
      }

      if (pathPrevOutside) {
        textsForPano.push('⇢'); // enter from outside
      } else if (pathBuilding === pathPrevBuilding && pathFloor !== pathPrevFloor) {
        textsForPano.push(parseFloat(pathFloor) > parseFloat(pathNextFloor) ? '⇡' : '⇣'); // came from another floor
      }

      if (pathNextOutside) {
        textsForPano.push('→'); // will go outside
      } else if (pathBuilding === pathNextBuilding && pathFloor !== pathNextFloor) {
        textsForPano.push(parseFloat(pathFloor) > parseFloat(pathNextFloor) ? '↓' : '↑'); // will go to another floor
      }

      panoTexts.set(path[i], textsForPano);
    }

    panoTexts.forEach((textsForPano, sceneKey) => {
      const unqTexts = textsForPano.filter((value, index, array) => array.indexOf(value) === index);
      const text = unqTexts.join('');
      const pano = panos[sceneKey];
      cachedPathTexts.push({
        x: pano.camera[0] / 100,
        y: pano.camera[1] / 100,
        text,
        color: 'rgb(172,51,211)',
      });
    });
  }

  lines.push(...cachedPathLines);
  texts.push(...cachedPathTexts);

  // ---------------------------------------------------------------
  // hotspots (just lines from pano to all hotspots from there)
  // ---------------------------------------------------------------
  const nobodyNeedsToSeeThis = true; // <--------------------- disabled
  const currentHotSpotsCacheKey = currentPano.sceneKey;
  if (!nobodyNeedsToSeeThis && currentHotSpotsCacheKey !== hotSpotsCacheKey) {
    hotSpotsCacheKey = currentHotSpotsCacheKey;
    cachedHotSpotLines = [];
    Object.keys(panos).forEach((sceneKey) => {
      const scene = panos[sceneKey];

      if (currentPano.sceneKey !== sceneKey) return;
      // if (scene.outside) return;
      // if (scene.building !== currentBuilding || scene.floor !== currentFloor) return;

      const pano = panos[sceneKey];
      const hotSpots = scene.hotSpots || [];
      for (let i = 0; i < hotSpots.length; i += 1) {
        const hotSpot = hotSpots[i];
        if (hotSpot.disabled) continue;

        cachedHotSpotLines.push({
          x1: pano.camera[0] / 100,
          y1: pano.camera[1] / 100,
          x2: hotSpot.pos[0] / 100,
          y2: hotSpot.pos[1] / 100,
          color: 'rgba(0,0,0,0.62)',
          width: 2,
          fx: [2, 2],
        } as Line);
      }
    });
  }
  lines.push(...cachedHotSpotLines);

  // ---------------------------------------------------------------
  // camera stuff
  // ---------------------------------------------------------------
  const cameraPos = actualCameraPos || currentPano.camera; // @todo -- maybe just pass the cameraPos in?
  const cone =
    fov && yaw
      ? ({
          x: cameraPos[0] / 100,
          y: cameraPos[1] / 100,
          direction: yaw,
          fov,
        } as Cone)
      : undefined;

  // ---------------------------------------------------------------
  // drawing
  // ---------------------------------------------------------------
  drawPrimitives(lines, circles, texts, cone);
};

/**
 * Create a CSV string in "from,to\n" format
 * for use in https://hub.graphistry.com/pivot/upload-file visualizations
 */
export const createGraphistryCSVFromGraph = (data: Map<string, string[]>): string => {
  const csvLines: string[] = [];
  csvLines.push('Source,Target');
  const dataEntries = Array.from(data.entries());
  for (let i = 0; i < dataEntries.length; i += 1) {
    const from = dataEntries[i][0];
    const tos = dataEntries[i][1];
    for (let j = 0; j < tos.length; j += 1) {
      const to = tos[j];
      csvLines.push(`${from},${to}`);
    }
  }
  return csvLines.join('\n');
};

const deleteProperties = (obj: any, properties: string[]) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  const keys = Object.keys(obj);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    if (properties.includes(key)) {
      // eslint-disable-next-line no-param-reassign
      delete obj[key];
    } else if (typeof obj[key] === 'object') {
      deleteProperties(obj[key], properties);
    }
  }
  return obj;
};

/**
 * for tour config and data json
 */
export const deleteUnwantedProperties = (obj: any) => {
  return deleteProperties(obj, [
    'geometry',
    'timestamp',
    'imageUrl',
    'area',
    'buildingSize',
    'buildingCenter',
    'suggested_data',
    'corners',
    'walls',
    'objects',
    'contours',
    'measurements',
    'ui',
    'flatten',
    'measureToolEnabled',
  ]);
};
