import {
  AppStateContextProvider,
  DeviceStateContextProvider,
  RoomContextProvider,
  MixpanelProvider,
} from "components/contexts";
import { TabIcon } from "components/icons";
import { InfoPageLayout } from "components/layout";
import { VideoChatPageContent } from "components/module";
import { useConnectionIssues } from "hooks";
import useIsTabOpen from "hooks/useIsTabOpen";
import { FC } from "react";
import { ConnectOptions } from "twilio-video";
import config from "config";

const VideoChatPage: FC = () => {
  useConnectionIssues(3000);
  const [isSecondTab] = useIsTabOpen();

  const TwilioOptions: ConnectOptions = {
    dominantSpeaker: true,
    // VP8 simulcast enables the media server in a Small Group or Group Room
    // to adapt your encoded video quality for each RemoteParticipant based on
    // their individual bandwidth constraints. This has no utility if you are
    // using Peer-to-Peer Rooms, so you can comment this line.
    preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
    bandwidthProfile: {
      video: {
        maxSubscriptionBitrate: 2500000, // 2.5 Mbps
      },
    },
    networkQuality: {
      local: 3, // LocalParticipant's Network Quality verbosity [1 - 3]
      remote: 3, // RemoteParticipants' Network Quality verbosity [0 - 3]
    },
  };

  if (isSecondTab) {
    return (
      <InfoPageLayout
        icon={<TabIcon className="w-[4.6rem] h-[4.6rem]" />}
        headLine="Guided Viewing is open in another tab"
        paragraph="If you refresh this page, the guided viewing will open here. All the progress in the other tab will be lost."
      />
    );
  }

  return (
    <MixpanelProvider token={config.mixpanelToken}>
      <AppStateContextProvider>
        <DeviceStateContextProvider>
          <RoomContextProvider options={TwilioOptions}>
            <VideoChatPageContent />
          </RoomContextProvider>
        </DeviceStateContextProvider>
      </AppStateContextProvider>
    </MixpanelProvider>
  );
};

export default VideoChatPage;
