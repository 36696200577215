import { jsx as _jsx } from "react/jsx-runtime";
import { getCookiePreferences, setCookiePreferences } from '@g360/vt-utils';
import noop from 'lodash/noop';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import useReactive from '../hooks/useReactive';
import useServices from './useServices';
/**
 * This context is used to store the cookie popup/modal state and the initial preferences.
 * It also provides callback to update the popup/modal state and the preferences.
 */
export const CookieConsentContext = createContext({
    consentNecessary: false,
    showCookiePopup: false,
    showCookieModal: false,
    showCookiePreferencesLink: true,
    setShowCookiePopup: noop,
    setShowCookieModal: noop,
    initialPreferences: null,
    handleSubmitPreferences: noop,
    isPerformanceCookieAllowed: true,
    isPreferenceCookieAllowed: true,
});
/**
 * @prop consentNecessary - Specifies whether the consent is necessary or not. Determines whether the consent popup is shown or not.
 * @prop onPreferencesConfirmed - Callback that is called when the user confirms the preferences.
 * @returns The wrapper around the children that require the CookieConsentContext.
 */
export const CookieConsentContextProvider = ({ children, consentNecessary, onPreferencesConfirmed, }) => {
    const [isPerformanceCookieAllowed, setIsPerformanceCookiesAllowed] = useState(true);
    const [isPreferenceCookieAllowed, setIsPreferenceCookiesAllowed] = useState(true);
    const [showCookiePopup, setShowCookiePopup] = useState(false);
    const [showCookieModal, setShowCookieModal] = useState(false);
    const [showCookiePreferencesLink, setShowCookiePreferencesLink] = useState(false);
    const [initialPreferences, setInitialPreferences] = useState(null);
    const cookiePopupAlreadyShown = useRef(false);
    const { tourConfigService } = useServices();
    const { features } = useReactive(tourConfigService, ['features']);
    const handleSubmitPreferences = useCallback((preferences) => {
        var _a;
        setInitialPreferences(preferences);
        setShowCookiePopup(false);
        setCookiePreferences('vt_consentid', preferences, {
            domain: (_a = process.env.REACT_APP_COOKIE_DOMAIN) !== null && _a !== void 0 ? _a : '.giraffe360.com',
        });
        setIsPerformanceCookiesAllowed(preferences.isPerformance);
        setIsPreferenceCookiesAllowed(preferences.isPreferences);
        onPreferencesConfirmed === null || onPreferencesConfirmed === void 0 ? void 0 : onPreferencesConfirmed(preferences);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (!features.cookiePopup) {
            setShowCookiePopup(false);
        }
    }, [features, setShowCookieModal, setShowCookiePopup]);
    useEffect(() => {
        if (!features.cookieConsent) {
            setShowCookieModal(false);
            setShowCookiePopup(false);
        }
        setShowCookiePreferencesLink(features.cookieConsent);
    }, [features, setShowCookieModal, setShowCookiePopup, setShowCookiePreferencesLink]);
    useEffect(() => {
        if (!consentNecessary)
            return;
        const currentPreferences = getCookiePreferences('vt_consentid');
        if (currentPreferences) {
            setInitialPreferences(currentPreferences);
            setIsPerformanceCookiesAllowed(currentPreferences.isPerformance);
            setIsPreferenceCookiesAllowed(currentPreferences.isPreferences);
            onPreferencesConfirmed === null || onPreferencesConfirmed === void 0 ? void 0 : onPreferencesConfirmed(currentPreferences);
            return;
        }
        if (!features.cookieConsent || !features.cookiePopup)
            return;
        if (cookiePopupAlreadyShown.current)
            return;
        setShowCookiePopup(true);
        cookiePopupAlreadyShown.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consentNecessary, features]);
    const context = useMemo(() => ({
        consentNecessary,
        showCookiePopup,
        setShowCookiePopup,
        showCookieModal,
        setShowCookieModal,
        showCookiePreferencesLink,
        initialPreferences,
        handleSubmitPreferences,
        isPerformanceCookieAllowed,
        isPreferenceCookieAllowed,
    }), [
        consentNecessary,
        initialPreferences,
        handleSubmitPreferences,
        showCookiePopup,
        showCookieModal,
        showCookiePreferencesLink,
        isPerformanceCookieAllowed,
        isPreferenceCookieAllowed,
    ]);
    return _jsx(CookieConsentContext.Provider, { value: context, children: children });
};
/**
 *   @returns consentNecessary - Specifies whether the consent is necessary or not. Determines whether the consent modal is shown or not.
 *   @returns showCookiePopup - Specifies whether the cookie popup is shown or not.
 *   @returns setShowCookiePopup - Callback to update the showCookiePopup state.
 *   @returns showCookiePreferencesLink - Specifies whether the cookie preference link is shown or not in the info modal.
 *   @returns setShowCookiePreferencesLink - Callback to update the showCookiePreferencesLink state.
 *   @returns showCookieModal - Specifies whether the cookie modal is shown or not.
 *   @returns setShowCookieModal - Callback to update the showCookieModal state.
 *   @returns initialPreferences - The initial preferences that are set by the user.
 *   @returns handleSubmitPreferences - Callback that is called when the user confirms the preferences.
 *
 *   @throws Will throw an error if the context is used outside of the CookieConsentContextProvider.
 *
 *   @example
 *   const { showCookiePopup, setShowCookiePopup, initialPreferences, handleSubmitPreferences } = useCookieConsentContext();
 *   const handleConsent = () => {
 *      handleSubmitPreferences({ isNecessary: true, isPerformance: true, isPreferences: true });
 *   };
 */
export function useCookieConsentContext() {
    const context = useContext(CookieConsentContext);
    if (!context) {
        throw new Error('useCookieConsentContext must be used within a CookieConsentContextProvider');
    }
    return context;
}
