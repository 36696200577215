import { noop } from "lodash/fp";
import { createContext } from "react";
import debounce from "lodash/debounce";
import mixpanel, { Dict } from "mixpanel-browser";
import { useLocation } from "react-router-dom";

type MixpanelActions = {
  identify: (id: string) => void;
  alias: (id: string) => void;
  track: (name: string, props?: Dict) => void;
  debouncedTrack: (name: string, props?: Dict) => void;
  register: (props: Dict) => void;
  reset: () => void;
  people: {
    set: (props: Dict) => void;
  };
  opt_in_tracking: () => void;
  opt_out_tracking: () => void;
};

interface IMixpanelContext {
  actions: MixpanelActions;
}

interface MixpanelProviderProps {
  children: any;
  token: string;
}

const MixpanelEvents = {
  Button: {
    Primary: {
      Click: (name: string) => `Button Primary Click: ${name}`,
    },
    Secondary: {
      Click: (name: string) => `Button Secondary Click: ${name}`,
    },
    Click: (name: string) => `Button Click: ${name}`,
  },
  Input: {
    Click: (name: string) => `Input Field Click: ${name}`,
    Submit: (name: string) => `Input Field Submit: ${name}`,
  },
  Checkbox: {
    Check: (name: string) => `Checkbox Check: ${name}`,
    Uncheck: (name: string) => `Checkbox Uncheck: ${name}`,
  },
  Dropdown: {
    ItemClick: (name: string) => `Dropdown Item Click: ${name}`,
    Open: (name: string) => `Dropdown Open: ${name}`,
  },
  Section: {
    Open: (name: string) => `Section Open: ${name}`,
  },
};

const MixpanelContext = createContext<IMixpanelContext>({
  actions: {
    identify: noop,
    alias: noop,
    track: noop,
    debouncedTrack: noop,
    register: noop,
    reset: noop,
    people: {
      set: noop,
    },
    opt_in_tracking: noop,
    opt_out_tracking: noop,
  },
});

const MixpanelProvider = ({ children, token }: MixpanelProviderProps) => {
  const { pathname: route } = useLocation();

  const actions = {
    identify: (id: string) => {
      mixpanel.identify(id);
    },
    alias: (id: string) => {
      mixpanel.alias(id);
    },
    track: (name: string, props?: Dict) => {
      try {
        if (
          mixpanel.get_property("id") &&
          !mixpanel.get_property("is_hijacked")
        ) {
          mixpanel.track(name, { ...props, route });
        }
      } catch (e) {
        console.error("Mixpanel track error:", e);
      }
    },
    debouncedTrack: debounce((name: string, props?: Dict) => {
      actions.track(name, props);
    }, 300),
    register: (props: Dict) => {
      mixpanel.register(props);
    },
    reset: () => {
      mixpanel.reset();
    },
    people: {
      set: (props: Dict) => {
        mixpanel.people.set(props);
      },
    },
    opt_in_tracking: () => {
      mixpanel.opt_in_tracking();
    },
    opt_out_tracking: () => {
      mixpanel.opt_out_tracking();
    },
  };

  mixpanel.init(token, {
    debug: !(process.env.NODE_ENV === "production"),
    opt_out_tracking_by_default: true,
  });

  return (
    <MixpanelContext.Provider
      value={{
        actions,
      }}
    >
      {children}
    </MixpanelContext.Provider>
  );
};

export { MixpanelProvider, MixpanelContext, MixpanelEvents };
