import type { SignData, SignDataPasswordProtected, SignDataSing, SignDataTokenProtected, SingBodyCommon } from './type';

// eslint-disable-next-line import/prefer-default-export
export const mapToSignData = (data: VirtualTourSignData): SignData => {
  const commonBody: SingBodyCommon = {
    url: data.baseHref,
    entrypoint: data.entrypoint,
  };

  if (data.password) {
    const signing: SignDataPasswordProtected = {
      mode: 'password',
      signBody: {
        ...commonBody,
        password: data.password,
      },
    };
    return signing;
  }

  if (!data.published) {
    const signing: SignDataTokenProtected = {
      mode: 'token',
      signBody: {
        ...commonBody,
        token: data.previewToken,
      },
    };
    return signing;
  }

  const signing: SignDataSing = {
    mode: 'sign',
    signBody: commonBody,
  };
  return signing;
};

export type VirtualTourSignData = {
  entrypoint: string;
  password: string;
  published: boolean;
  baseHref: string;
  jsonPath: string;
  previewToken: string;
};
