import { SVGProps } from "react";

const BellIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 34 36"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <path d="M13.5 3a3 3 0 1 1 6 0v.75c0 .142-.009.283-.029.42A10.509 10.509 0 0 1 27 14.25v11.437l2.282 2.567c.859.967.173 2.496-1.122 2.496H4.84c-1.292 0-1.981-1.529-1.119-2.496L6 25.688V14.25c0-4.768 3.176-8.8 7.53-10.08l-.027-.223-.003-.197V3ZM9 14.25a7.5 7.5 0 0 1 7.5-7.5 7.5 7.5 0 0 1 7.5 7.5v13.5H9v-13.5Z" />
    <path
      d="M9.151 4.2a1.5 1.5 0 1 0-1.798-2.4C5.627 3.093 4.18 4.98 3.16 7.017 2.144 9.053 1.502 11.345 1.502 13.5a1.5 1.5 0 1 0 3 0c0-1.594.483-3.428 1.342-5.142a13.137 13.137 0 0 1 1.951-2.899A9.609 9.609 0 0 1 9.151 4.2ZM26.255 2.907A1.5 1.5 0 0 1 28.651 1.8c1.726 1.293 3.173 3.18 4.193 5.217 1.016 2.036 1.658 4.328 1.658 6.483a1.5 1.5 0 1 1-3 0c0-1.594-.483-3.428-1.342-5.142-.855-1.713-2.033-3.201-3.307-4.158a1.502 1.502 0 0 1-.598-1.293ZM21.002 33a3 3 0 1 1-6 0h6Z"
      style={{
        fillRule: "nonzero",
      }}
      transform="translate(-1.502)"
    />
  </svg>
);

export default BellIcon;
