import { SVGProps } from "react";

const CopyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 30 33"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <path
      d="M13.533 18.333v4a2.8 2.8 0 0 0 2.8 2.8h2a2.8 2.8 0 0 0 2.8-2.8v-4a2.8 2.8 0 0 0-2.8-2.8h-2a2.8 2.8 0 0 0-2.8 2.8Zm1.6 0a1.2 1.2 0 0 1 1.2-1.2h2a1.2 1.2 0 0 1 1.2 1.2v4a1.2 1.2 0 0 1-1.2 1.2h-2a1.2 1.2 0 0 1-1.2-1.2v-4Z"
      transform="translate(-36.088 -34.311) scale(2.66667)"
    />
    <path
      d="M20.334 22.467h1.333a2.8 2.8 0 0 0 2.8-2.8v-4a2.8 2.8 0 0 0-2.8-2.8h-2a2.8 2.8 0 0 0-2.8 2.8v.666h1.6v-.666a1.2 1.2 0 0 1 1.2-1.2h2a1.2 1.2 0 0 1 1.2 1.2v4a1.2 1.2 0 0 1-1.2 1.2h-1.333v1.6Z"
      transform="translate(-36.088 -34.311) scale(2.66667)"
    />
  </svg>
);

export default CopyIcon;
