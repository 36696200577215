/* eslint-disable no-param-reassign */
import type { FPIntersection, FPPoint3D, FPRay, FPTriangle } from '@g360/vt-types';

import rayTriangleIntersectionMO from './utilsMO';

class AABB {
  min: FPPoint3D;
  max: FPPoint3D;

  constructor(min: FPPoint3D, max: FPPoint3D) {
    this.min = min;
    this.max = max;
  }

  static fromTriangle(triangle: FPTriangle): AABB {
    const [v0, v1, v2] = triangle.vertices;
    const min: FPPoint3D = [
      Math.min(v0[0], v1[0], v2[0]),
      Math.min(v0[1], v1[1], v2[1]),
      Math.min(v0[2], v1[2], v2[2]),
    ];
    const max: FPPoint3D = [
      Math.max(v0[0], v1[0], v2[0]),
      Math.max(v0[1], v1[1], v2[1]),
      Math.max(v0[2], v1[2], v2[2]),
    ];
    return new AABB(min, max);
  }

  intersect(ray: FPRay, tMin: number, tMax: number): boolean {
    for (let i = 0; i < 3; i += 1) {
      const invD = ray.directionInv[i];

      let t0 = (this.min[i] - ray.origin[i]) * invD;
      let t1 = (this.max[i] - ray.origin[i]) * invD;

      if (invD < 0) {
        const temp = t0;
        t0 = t1;
        t1 = temp;
      }

      const xtMin = t0 > tMin ? t0 : tMin;
      const xtMax = t1 < tMax ? t1 : tMax;

      if (xtMax <= xtMin) return false;
    }
    return true;
  }

  /**
   * Same as intersect but with unrolled loop and inlined operations
   * MO stands for micro-optimized
   * Since this functions is top 2 most called functions in the AO baker, it's worth optimizing
   */
  intersectMO(ray: FPRay, tMin: number, tMax: number): boolean {
    // Unroll the loop for i = 0, 1, 2 and inline the operations
    // Precompute invD and t0, t1 for all i at once
    const invD0 = ray.directionInv[0];
    const invD1 = ray.directionInv[1];
    const invD2 = ray.directionInv[2];

    // i = 0
    let t0 = (this.min[0] - ray.origin[0]) * invD0;
    let t1 = (this.max[0] - ray.origin[0]) * invD0;
    if (invD0 < 0) {
      const temp = t0;
      t0 = t1;
      t1 = temp;
    }
    // eslint-disable-next-line no-param-reassign
    tMin = t0 > tMin ? t0 : tMin;
    // eslint-disable-next-line no-param-reassign
    tMax = t1 < tMax ? t1 : tMax;
    if (tMax <= tMin) return false;

    // i = 1
    t0 = (this.min[1] - ray.origin[1]) * invD1;
    t1 = (this.max[1] - ray.origin[1]) * invD1;
    if (invD1 < 0) {
      const temp = t0;
      t0 = t1;
      t1 = temp;
    }
    // eslint-disable-next-line no-param-reassign
    tMin = t0 > tMin ? t0 : tMin;
    // eslint-disable-next-line no-param-reassign
    tMax = t1 < tMax ? t1 : tMax;
    if (tMax <= tMin) return false;

    // i = 2
    t0 = (this.min[2] - ray.origin[2]) * invD2;
    t1 = (this.max[2] - ray.origin[2]) * invD2;
    if (invD2 < 0) {
      const temp = t0;
      t0 = t1;
      t1 = temp;
    }
    // eslint-disable-next-line no-param-reassign
    tMin = t0 > tMin ? t0 : tMin;
    // eslint-disable-next-line no-param-reassign
    tMax = t1 < tMax ? t1 : tMax;
    return tMax > tMin;
  }

  expand(delta: number): AABB {
    return new AABB(
      [this.min[0] - delta, this.min[1] - delta, this.min[2] - delta],
      [this.max[0] + delta, this.max[1] + delta, this.max[2] + delta]
    );
  }
}

class BVHNode {
  bbox: AABB;
  left: BVHNode | null = null;
  right: BVHNode | null = null;
  triangles: FPTriangle[] | null = null;

  constructor(triangles: FPTriangle[], start: number, end: number, depth = 0) {
    const EPSILON = 1e-6;
    const MAX_TRIANGLES_PER_LEAF = 1;
    const MAX_DEPTH = 100;
    this.bbox = triangles
      .slice(start, end + 1)
      .reduce((acc, tri) => this.unionAABB(acc, AABB.fromTriangle(tri)), AABB.fromTriangle(triangles[start]))
      .expand(EPSILON);

    if (end - start + 1 <= MAX_TRIANGLES_PER_LEAF || depth >= MAX_DEPTH) {
      this.triangles = triangles.slice(start, end + 1);
    } else {
      const axis = depth % 3;
      const mid = Math.floor((start + end) / 2);

      triangles.slice(start, end + 1).sort((a, b) => {
        const centroidA = this.getCentroid(a);
        const centroidB = this.getCentroid(b);
        return centroidA[axis] - centroidB[axis];
      });

      this.left = new BVHNode(triangles, start, mid, depth + 1);
      this.right = new BVHNode(triangles, mid + 1, end, depth + 1);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private getCentroid(triangle: FPTriangle): FPPoint3D {
    const [v0, v1, v2] = triangle.vertices;
    return [(v0[0] + v1[0] + v2[0]) / 3, (v0[1] + v1[1] + v2[1]) / 3, (v0[2] + v1[2] + v2[2]) / 3];
  }

  // eslint-disable-next-line class-methods-use-this
  private unionAABB(a: AABB, b: AABB): AABB {
    const min: FPPoint3D = [Math.min(a.min[0], b.min[0]), Math.min(a.min[1], b.min[1]), Math.min(a.min[2], b.min[2])];
    const max: FPPoint3D = [Math.max(a.max[0], b.max[0]), Math.max(a.max[1], b.max[1]), Math.max(a.max[2], b.max[2])];
    return new AABB(min, max);
  }
}

// "global" vars for micro-optimized ray-triangle intersection to avoid creating new objects
const intersection: FPIntersection = {
  distance: 0,
  point: [0, 0, 0],
};

function traverseBVH(node: BVHNode, ray: FPRay, maxDistance: number): number | null {
  if (!node.bbox.intersectMO(ray, 0, maxDistance)) {
    return null;
  }

  if (node.triangles) {
    let closestDistance = maxDistance;
    let hasIntersection = false;

    for (let i = 0; i < node.triangles.length; i += 1) {
      const triangle = node.triangles[i];

      // Calculate dot product of ray direction and triangle normal
      const dotProduct =
        ray.direction[0] * triangle.normal[0] +
        ray.direction[1] * triangle.normal[2] +
        ray.direction[2] * triangle.normal[1];

      // Skip if ray is coming from behind the triangle
      // eslint-disable-next-line no-continue
      if (dotProduct >= 0) continue;

      const intersectionRes = rayTriangleIntersectionMO(
        ray,
        triangle.vertices[0],
        triangle.vertices[1],
        triangle.vertices[2],
        intersection
      );
      if (intersectionRes && intersectionRes.distance < closestDistance) {
        closestDistance = intersectionRes.distance;
        hasIntersection = true;
      }
    }

    return hasIntersection ? closestDistance : null;
  }

  const leftDistance = node.left ? traverseBVH(node.left, ray, maxDistance) : null;
  const rightDistance = node.right ? traverseBVH(node.right, ray, maxDistance) : null;

  if (leftDistance !== null && rightDistance !== null) {
    if (leftDistance < rightDistance) {
      return leftDistance;
    }
    return rightDistance;
  }

  if (leftDistance !== null) {
    return leftDistance;
  }

  if (rightDistance !== null) {
    return rightDistance;
  }

  return null;
}

export default class BVHRaycaster {
  private readonly root: BVHNode;
  private readonly maxDistance: number;

  constructor(triangles: FPTriangle[], maxDistance: number) {
    this.maxDistance = maxDistance;
    this.root = new BVHNode(triangles, 0, triangles.length - 1);
  }

  rayIntersectsGeometry(ray: FPRay): number | null {
    return traverseBVH(this.root, ray, this.maxDistance);
  }
}
