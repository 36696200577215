import type { Milliseconds, Radian, ScenePos, Subscription } from '@g360/vt-types';

import type { Analytics, ThrottledFunction } from '../..';
import throttle from '../../misc/throttle';
import type { AnalyticsEventDetailsSceneView, ZoomEventType } from '..';

/**
 * A helper class that handles zoom events in the VT. Zoom events are only triggered if fov has changed.
 * The events are throttled to one every `500 ms`.
 *
 * After `dragEnd` event, the next `dragStart` event will be generated immediately, without throttling.
 */
export default class AnalyticsSceneZoomHelper {
  /** Determines how often we generate zoom analytics events. */
  private static readonly ZOOM_TIMEOUT_DELAY: Milliseconds = 500;

  /** An instance of the main `Analytics` class. */
  private analytics: Analytics;

  /** Store the view from `scene.zoom.start` pubsub event to use in `zoomStart.VT.Scene` analytics event. */
  private lastZoomStartView: ScenePos<Radian> = { yaw: 0, pitch: 0, fov: 0 };

  /**
   * Pushes a zoom event to the analytics. A throttled version of {@link pushZoomEvent}.
   * Limits how often we generate zoom analytics events to one every `500 ms`.
   * @param resizeEventType - `ZoomEventType`: the type of zoom event. Possible values: `zoomStart`, `zoom`, `zoomEnd`.
   * @param view - `ScenePos<Radian>`: the current scene view.
   * @param view.pitch - `Radian`: the scene pitch.
   * @param view.yaw - `Radian`: the scene yaw.
   * @param view.fov - `Radian`: the scene fov.
   * @returns `void`
   */
  private pushZoomEventThrottled: ThrottledFunction<(zoomEventType: ZoomEventType, view: ScenePos<Radian>) => void>;

  private subscriptions: Subscription[] = [];

  /**
   * @param analytics - `Analytics`: an instance of the main Analytics class.
   */
  constructor(analytics: Analytics) {
    this.analytics = analytics;

    this.onSceneZoomStart = this.onSceneZoomStart.bind(this);
    this.onSceneZoomUpdate = this.onSceneZoomUpdate.bind(this);
    this.onSceneZoomEnd = this.onSceneZoomEnd.bind(this);
    this.onAnalyticsDeactivated = this.onAnalyticsDeactivated.bind(this);
    this.pushZoomEvent = this.pushZoomEvent.bind(this);

    this.pushZoomEventThrottled = throttle(this.pushZoomEvent, AnalyticsSceneZoomHelper.ZOOM_TIMEOUT_DELAY);

    analytics.pubSub.subscribe('analytics.deactivated', this.onAnalyticsDeactivated);
  }

  /**
   * Adds an event subscription to the list of subscriptions.
   * @param subscription - `Subscription`: an event subscription to be added to the list of subscriptions.
   * @returns `void`
   */
  public addSubscription(subscription: Subscription): void {
    this.subscriptions.push(subscription);
  }

  /**
   * Unsubscribes from all event subscriptions and clears the list of subscriptions.
   * @returns `void`
   */
  public unsubscribeAll(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

  /**
   * An event that is triggered when a user starts zooming the scene.
   * It is triggered only when there was an actual zoom.
   * @param view - `ScenePos<Radian>`: the current scene view.
   * @param view.pitch - `Radian`: the scene pitch.
   * @param view.yaw - `Radian`: the scene yaw.
   * @param view.fov - `Radian`: the scene fov.
   * @returns `Promise<void>`
   */
  public async onSceneZoomStart(view: ScenePos<Radian>): Promise<void> {
    if (!this.analytics.isActive) return;

    this.lastZoomStartView = view;
  }

  /**
   * An event that is triggered continuously while a user is zooming the scene.
   * It is triggered only when there was an actual zoom.
   * @param view - `ScenePos<Radian>`: the current scene view.
   * @param view.pitch - `Radian`: the scene pitch.
   * @param view.yaw - `Radian`: the scene yaw.
   * @param view.fov - `Radian`: the scene fov.
   * @returns `Promise<void>`
   */
  public async onSceneZoomUpdate(view: ScenePos<Radian>): Promise<void> {
    if (!this.analytics.isActive) return;

    if (!this.pushZoomEventThrottled.isOnCooldown()) {
      this.pushZoomEventThrottled('zoomStart', this.lastZoomStartView);
      return;
    }

    this.pushZoomEventThrottled('zoom', view);
  }

  /**
   * An event that is triggered when a user stops zooming with the scene.
   * It is triggered only if there was an actual zoom.
   * @param view - `ScenePos<Radian>`: the current scene view.
   * @param view.pitch - `Radian`: the scene pitch.
   * @param view.yaw - `Radian`: the scene yaw.
   * @param view.fov - `Radian`: the scene fov.
   * @returns `Promise<void>`
   */
  public async onSceneZoomEnd(view: ScenePos<Radian>): Promise<void> {
    if (!this.analytics.isActive) return;
    if (!this.pushZoomEventThrottled.isOnCooldown()) return;

    this.pushZoomEventThrottled('zoomEnd', view);
  }

  /**
   * Pushes a zoom event to the analytics. Don't call directly, use {@link pushZoomEventThrottled} instead.
   * @param zoomEventType - `ZoomEventType`: the type of zoom event. Possible values: `zoomStart`, `zoom`, `zoomEnd`.
   * @param view - `ScenePos<Radian>`: the current scene view.
   * @param view.pitch - `Radian`: the scene pitch.
   * @param view.yaw - `Radian`: the scene yaw.
   * @param view.fov - `Radian`: the scene fov.
   * @returns `void`
   */
  private pushZoomEvent(zoomEventType: ZoomEventType, view: ScenePos<Radian>): void {
    if (!this.analytics.isActive) return;

    if (zoomEventType === 'zoomEnd') this.pushZoomEventThrottled.cancel();

    this.analytics.push<AnalyticsEventDetailsSceneView>(zoomEventType, 'VT', 'Scene', {
      scene_id: this.analytics.getCurrentSceneId(),
      view: [view.yaw, view.pitch, view.fov ?? 0],
    });
  }

  /**
   * An event that is triggered when analytics is deactivated.
   * @returns `Promise<void>`
   */
  private async onAnalyticsDeactivated(): Promise<void> {
    this.pushZoomEventThrottled.cancel();
  }
}
