export const drawFloorPlanImage = ({ halfCanvasSize, ctx, img, angle, userScale, pixelScale, floorPlanOffset, opacity, }) => {
    const scale = userScale / pixelScale;
    const offsetX = floorPlanOffset.x * pixelScale;
    const offsetY = floorPlanOffset.y * pixelScale;
    const x = halfCanvasSize - (img.width / 2) * scale;
    const y = halfCanvasSize - (img.height / 2) * scale;
    const imgOffsetX = (img.width - offsetX - img.width / 2) * scale;
    const imgOffsetY = (img.height - offsetY - img.height / 2) * scale;
    ctx.save();
    // eslint-disable-next-line no-param-reassign
    ctx.globalAlpha = opacity;
    ctx.translate(x + (img.width * scale) / 2, y + (img.height * scale) / 2);
    ctx.rotate(-angle);
    ctx.translate(-x - (img.width * scale) / 2, -y - (img.height * scale) / 2);
    ctx.drawImage(img, x + imgOffsetX, y + imgOffsetY, img.width * scale, img.height * scale);
    ctx.restore();
};
/**
 * Translate point from floorPlan centimeters to canvas pixel coordinates
 *
 * @param halfCanvasSizeDpr -- half canvas size / device pixel ratio
 * @param pos
 * @param yaw
 * @param scale
 * @param floorPlanCenteredAt
 */
export const fpPixels2CanvasPixels = (halfCanvasSizeDpr, pos, yaw, scale, floorPlanCenteredAt) => {
    const translatedX = (pos.x - floorPlanCenteredAt.x) * scale;
    const translatedY = (pos.y - floorPlanCenteredAt.y) * scale;
    const sin = Math.sin(-yaw);
    const cos = Math.cos(-yaw);
    const x = translatedX * cos - translatedY * sin + halfCanvasSizeDpr;
    const y = translatedX * sin + translatedY * cos + halfCanvasSizeDpr;
    return { x, y };
};
export const drawImage = (ctx, image, position, offset = { x: 0, y: 0 }) => {
    const icoWidth = image.width;
    const icoHeight = image.height;
    const topLeftX = position.x - icoWidth / 2 + offset.x;
    const topLeftY = position.y - icoHeight / 2 + offset.y;
    ctx.drawImage(image, topLeftX, topLeftY, icoWidth, icoHeight);
};
