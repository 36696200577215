/* eslint-disable no-restricted-syntax,no-continue */
import type { FPMesh, SceneConfig } from '@g360/vt-types';

import { disableVertexAttributes, enableVertexAttributes, loadShaders } from '../../../common/webglUtils';
import type FloorPlan3DProgram from '../FloorPlan3DProgram';
import FPWebGLProgram from '../FPWebGLProgram';
import type ModelNavigation from '../ModelNavigation';
import fragmentShader from './flat.fs.glsl';
import vertexShader from './flat.vs.glsl';

/**
 * Program for rendering unlit untextured single color geometry
 */
class FlatProgram extends FPWebGLProgram {
  sceneConfig?: SceneConfig;

  private readonly navigation: ModelNavigation;

  constructor(
    gl: WebGL2RenderingContext,
    canvas: HTMLCanvasElement,
    parentProgram: FloorPlan3DProgram,
    navigation: ModelNavigation
  ) {
    super(gl, canvas, parentProgram);
    this.navigation = navigation;
  }

  init(): void {
    super.init(vertexShader, fragmentShader);
    this.vertexAttributes = [this.positionLocation];
    this.gl.vertexAttribPointer(this.positionLocation, 3, this.gl.FLOAT, false, 0, 0);
  }

  loadGeometry(meshes: FPMesh[]): void {
    this.meshes = meshes;

    for (let m = 0; m < meshes.length; m += 1) {
      const mesh = meshes[m];

      if (mesh.dataNum === 0) continue; // skip bad meshes
      if (mesh.isCeiling) continue;
      if (!mesh.isWallCap && !mesh.isSideCap) continue;

      this.goodMeshIds.push(m);
    }
  }

  draw(): void {
    if (!this.gl) return;

    loadShaders(this.gl, this.program);
    enableVertexAttributes(this.gl, this.vertexAttributes);

    this.gl.uniformMatrix4fv(this.matrixWorldPosLocation, false, this.parentProgram.matrixWorldFloat32Array);
    this.gl.uniformMatrix4fv(this.matrixViewPosLocation, false, this.parentProgram.matrixViewFloat32Array);
    this.gl.uniformMatrix4fv(this.matrixProjectionPosLocation, false, this.parentProgram.matrixProjectionFloat32Array);

    this.numTrisDrawn = 0;

    // Draw each mesh separately
    for (const meshId of this.goodMeshIds) {
      const mesh = this.meshes[meshId];

      if (this.parentProgram.skipMeshes.includes(mesh.unqId)) continue;
      if (this.parentProgram.onlyMeshes.length && !this.parentProgram.onlyMeshes.includes(mesh.unqId)) continue;

      if (mesh.unfocusedRoom) continue; // skip drawing in non-focused room (it's transparent)
      if (mesh.skipFlatRendering) continue; // meshes that are pre-calculated to be skipped here

      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.parentProgram.positionBuffer);
      this.gl.vertexAttribPointer(this.positionLocation, 3, this.gl.FLOAT, false, 0, mesh.dataOffsetPositions);

      this.gl.drawArrays(this.gl.TRIANGLES, 0, mesh.dataNum);
      this.numTrisDrawn += mesh.dataNum / 3;
    }

    disableVertexAttributes(this.gl, this.vertexAttributes);
  }
}

export default FlatProgram;
