import { hexToRGB } from '@g360/vt-utils';
/**
 * Inject accent color variables for tailwind, use hex values for colors
 * Is using default color if no branded color is provided
 *  */
export default function setCssAccentColorVariable(defaultHexColor, brandedHexColor) {
    let hexColor = brandedHexColor || defaultHexColor;
    // Soft error handling falls back to the #ffc600 value... should never happen if data is correct
    if (hexColor[0] !== '#') {
        // eslint-disable-next-line no-console
        console.error('Accent color must be a hex value');
        hexColor = '#ffc600';
    }
    const rgbColor = hexToRGB(hexColor);
    if (rgbColor) {
        // Accent color is set to giraffe default if no branded color is provided
        document.documentElement.style.setProperty('--vt-color-accent', `${rgbColor[0]}, ${rgbColor[1]}, ${rgbColor[2]}`);
    }
    else {
        // eslint-disable-next-line no-console
        console.error('Failed to convert accent color to RGB');
    }
    // Pin color accent is set to dark if no branded color is provided
    let pinColorAccent = hexToRGB('#1A222E');
    if (brandedHexColor) {
        pinColorAccent = hexToRGB(brandedHexColor);
    }
    if (pinColorAccent) {
        document.documentElement.style.setProperty('--vt-color-pin-accent', `${pinColorAccent[0]}, ${pinColorAccent[1]}, ${pinColorAccent[2]}`);
    }
}
