/**
 * @param n - number to destructure. This is sum of subset of geometric sequence 1, 2, 4, 8, 16, ...
 * @returns array of numbers that sum up to n
 *
 * @example
 * destructureGeometricSequenceSum(7) // [1, 2, 4]
 */
const destructureGeometricSequenceSum = (n: number) => {
  const checkNumbers = [16, 8, 4, 2, 1];
  let currentSum = n;

  const containsNumbers: number[] = [];

  for (let i = 0; i < checkNumbers.length; i += 1) {
    if (currentSum >= checkNumbers[i]) {
      containsNumbers.push(checkNumbers[i]);
      currentSum -= checkNumbers[i];
    }
  }

  return containsNumbers;
};

export default destructureGeometricSequenceSum;
