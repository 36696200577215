import { SVGProps } from "react";

const ControllerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 38 24"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
      // ...(shadow && { filter: "drop-shadow(rgba(0, 0, 0, 0.4) 0px 0px 5px)" }),
    }}
    {...props}
  >
    <path
      d="m19.179 20.667-2.079 2c-.231.2-.539.333-.875.333-.325 0-.636-.123-.866-.342a1.136 1.136 0 0 1-.359-.825v-.166l.7-4.92c.147-1.54 1.498-2.747 3.15-2.747h6.3c1.652 0 3.003 1.207 3.15 2.747l.7 4.92v.166c0 .31-.129.607-.359.825a1.254 1.254 0 0 1-.866.342c-.336 0-.644-.133-.875-.333l-2.079-2h-5.642Zm-.679-5.334v1.334h-1.4v.666h1.4v1.334h.7v-1.334h1.4v-.666h-1.4v-1.334h-.7Zm6.65 0a.537.537 0 0 0-.371.147.485.485 0 0 0 0 .707.536.536 0 0 0 .371.146.536.536 0 0 0 .371-.146.485.485 0 0 0 0-.707.537.537 0 0 0-.371-.147ZM23.925 16.5a.54.54 0 0 0-.371.146.487.487 0 0 0 0 .708.54.54 0 0 0 .742 0 .487.487 0 0 0 0-.708.54.54 0 0 0-.371-.146Zm2.45 0a.54.54 0 0 0-.371.146.487.487 0 0 0 0 .708.54.54 0 0 0 .742 0 .487.487 0 0 0 0-.708.54.54 0 0 0-.371-.146Zm-1.225 1.167a.536.536 0 0 0-.371.146.485.485 0 0 0 0 .707.537.537 0 0 0 .742 0 .485.485 0 0 0 0-.707.536.536 0 0 0-.371-.146Z"
      style={{
        fillRule: "nonzero",
      }}
      transform="translate(-40 -37.333) scale(2.66667)"
    />
  </svg>
);

export default ControllerIcon;
