import UAParser from 'ua-parser-js';

function parseVersionString(versionString?: string) {
  if (versionString) {
    return parseInt(versionString.split('.')[0], 10);
  }

  return null;
}

function parseNameString(nameString?: string) {
  if (nameString) {
    return nameString.toLowerCase();
  }

  return null;
}

/** Abstraction layer for ua-parser-js package - abstract class with static only members */
export default abstract class BrowserInfo {
  // NOTE(uzars): currently targeting specific browser and os combinations are not supported,
  // but we could add this functionality later if needed, also targeting specific versions instead of only min version.
  private static supportList = [
    { browser: { name: 'chrome', minVersion: 61 } },
    { browser: { name: 'firefox', minVersion: 54 } },
    { browser: { name: 'edge', minVersion: 80 } },
    { browser: { name: 'opera', minVersion: 48 } },
    { browser: { name: 'safari', minVersion: 11 } },
    { os: { name: 'ios', minVersion: 11 } },
    { os: { name: 'android', minVersion: 5 } },
  ];

  private static info =
    typeof window !== 'undefined'
      ? new UAParser().getResult()
      : {
          device: { type: 'desktop' },
          browser: { name: 'chrome', version: '80' },
          os: { name: 'windows', version: '10' },
        };

  public static getResult() {
    if (typeof window === 'undefined') {
      return false;
    }

    return BrowserInfo.info;
  }

  public static isMobile() {
    // Check size, sometimes ua is wrong and detects tablets as mobile
    // return false on server side
    if (typeof window === 'undefined') {
      return false;
    }

    if (window.screen.width > 1024 || window.screen.height > 1024) {
      return false;
    }

    return BrowserInfo.info.device.type === 'mobile';
  }

  public static isEmbedded() {
    if (typeof window === 'undefined') {
      return false;
    }

    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  public static getBrowserName() {
    if (typeof window === 'undefined') {
      return null;
    }

    return parseNameString(BrowserInfo.info.browser.name);
  }

  public static getBrowserMajorVersion() {
    if (typeof window === 'undefined') {
      return null;
    }

    return parseVersionString(BrowserInfo.info.browser.version);
  }

  public static getOSName() {
    if (typeof window === 'undefined') {
      return null;
    }

    return parseNameString(BrowserInfo.info.os.name);
  }

  public static getOSMajorVersion() {
    if (typeof window === 'undefined') {
      return null;
    }

    return parseVersionString(BrowserInfo.info.os.version);
  }

  public static isSupported() {
    if (typeof window === 'undefined') {
      return true;
    }

    const browserName = BrowserInfo.getBrowserName();
    const osName = BrowserInfo.getOSName();
    const browserVersion = BrowserInfo.getBrowserMajorVersion();
    const osVersion = BrowserInfo.getOSMajorVersion();

    for (let i = 0; i < BrowserInfo.supportList.length; i += 1) {
      const listEntry = BrowserInfo.supportList[i];

      if (listEntry.browser && browserVersion && browserName) {
        if (browserName === listEntry.browser.name && browserVersion < listEntry.browser.minVersion) {
          return false;
        }
      }

      if (listEntry.os && osVersion && osName) {
        if (osName === listEntry.os.name && osVersion < listEntry.os.minVersion) {
          return false;
        }
      }
    }

    return true;
  }
}
