import { Input, PrimaryButton } from "components/elements";
import { HostWrapper, CopyInviteLink } from "components/wrappers";
import {
  useAppStateContext,
  useCopyToClipboard,
  useRoomContext,
  useQuery,
  useMixpanelContext,
} from "hooks";
import useFocus from "hooks/useFocus";
import { FC, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

type InputType = {
  identity: string;
};

interface LobbyInputFormWrapperProps {
  isLoading: boolean;
}

const LobbyInputFormWrapper: FC<LobbyInputFormWrapperProps> = ({
  isLoading,
}) => {
  const { isHost, handleJoinRoom } = useAppStateContext();
  const { Mixpanel, MixpanelEvents } = useMixpanelContext();
  const { isAcquiringLocalVideoTrack } = useRoomContext();

  const query = useQuery();
  const [htmlElRef, setFocus] = useFocus<HTMLInputElement>();
  const { isClipboardSupported } = useCopyToClipboard();
  const [isRejoining, setIsRejoining] = useState<boolean>(false);
  const { control, reset, setValue, handleSubmit } = useForm<InputType>({
    defaultValues: {
      identity: "",
    },
  });

  const onSubmit: SubmitHandler<InputType> = async (data) => {
    const { identity } = data;

    if (!identity || identity.trim().length === 0) return;
    handleJoinRoom(data.identity);
    reset();
  };

  useEffect(() => {
    setFocus();
  }, [setFocus]);

  useEffect(() => {
    const name = query.get("name");
    if (name) {
      setValue("identity", name, { shouldDirty: true });
    }
  }, [query, setValue]);

  useEffect(() => {
    const rejoin = query.get("rejoin");
    setIsRejoining(rejoin === "true");
  }, [query]);

  const getJoinBottomText = () => {
    let action = isHost ? "Start" : "Join";
    if (isRejoining) {
      action = `Re${action.toLowerCase()}`;
    }
    return action;
  };

  return (
    <div className="flex w-full items-center justify-center lg:ml-[85px] lg:w-2/5 lg:max-w-[520px]">
      <div className="w-full lg:p-2">
        <h2 className="mb-4 mt-12 lg:mt-0">
          {isRejoining
            ? "You left guided viewing session. Thank you for participating."
            : "Guided viewing"}
        </h2>
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <form
                onSubmit={(...args) => {
                  handleSubmit(onSubmit)(...args);
                  Mixpanel.track(MixpanelEvents.Input.Submit("Full name"));
                }}
                className="h-12 w-full"
              >
                <Input
                  ref={htmlElRef}
                  inputKind="text"
                  inputHeight="small"
                  inputValue={value}
                  inputPlaceholder="Full name"
                  isError={error ? true : false}
                  handleInputChange={(e) => onChange(e.target.value)}
                  onClick={() => {
                    // This will not trigger on auto-focus which is necessary.
                    Mixpanel.track(MixpanelEvents.Input.Click("Full name"));
                  }}
                />
                <label className="pt-2.5 ">
                  {error && (
                    <span className="pl-5 pt-1 text-xs font-bold text-giraffe-red">
                      Please fill in your full name
                    </span>
                  )}
                </label>
              </form>
            </>
          )}
          control={control}
          rules={{
            required: true,
            validate: (value) => {
              if (value.trim() === "") {
                return "Please validate string";
              }
              return true;
            },
          }}
          name="identity"
        />

        {isHost && isClipboardSupported && (
          <div className="mb-3 mt-5">
            <CopyInviteLink
              title="small"
              onCopyInvite={() => {
                Mixpanel.track(MixpanelEvents.Button.Click("Copy"));
              }}
            />
          </div>
        )}
        <div className="mt-10 w-[140px]">
          <PrimaryButton
            isLoading={isLoading}
            text={getJoinBottomText()}
            isDisabled={isAcquiringLocalVideoTrack}
            handleButtonClick={() => {
              handleSubmit(onSubmit)();
              Mixpanel.track(
                MixpanelEvents.Button.Primary.Click(getJoinBottomText())
              );
            }}
          />
        </div>
        {!isHost && (
          <div className="mt-16">
            <HostWrapper />
          </div>
        )}
        <div className="mb-10 lg:mb-0" />
      </div>
    </div>
  );
};

export default LobbyInputFormWrapper;
