import Engine, { TourConfigService } from "@g360/vt-engine";
import type { AssetConfig, TourConfig } from "@g360/vt-types";
import type EngineType from "@g360/vt-engine";
import { RefObject, useEffect, useRef, useState } from "react";
import useAppStateContext from "./useAppStateContext";
import { loadTourJson } from "@g360/vt-utils";

type UseEngineReturnType = {
  engine: EngineType | null;
  tourConfig: TourConfig | null;
  canvasRef: RefObject<HTMLCanvasElement>;
};

export async function loadEngine(
  config: TourConfig,
  canvasElement: string | HTMLCanvasElement,
  assetConfig: AssetConfig,
  blurMode?: boolean,
  colorCorrectionMode?: boolean
): Promise<EngineType> {
  const canvas =
    typeof canvasElement === "string"
      ? document.getElementById(canvasElement)
      : canvasElement;

  if (!(canvas instanceof HTMLCanvasElement)) {
    throw new Error(`Invalid canvas element`);
  }

  try {
    const tourConfigService = new TourConfigService(config, assetConfig);
    await tourConfigService.loadFeatures();
    const engineProps = {
      canvas,
      tourConfigService,
      assetConfig,
      engineConfig: {
        renderHotSpots: !blurMode,
        blurMode: !!blurMode,
        colorCorrectionMode: !!colorCorrectionMode,
        forceBlendTransition: !!blurMode,
        recordingMode: false,
      },
    };
    return new Engine(engineProps);
  } catch (err) {
    throw new Error(`Engine loading failed: ${err}`);
  }
}

const useEngine = (): UseEngineReturnType => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [engine, setEngine] = useState<EngineType | null>(null);
  const [tourConfig, setTourConfig] = useState<TourConfig | null>(null);
  const { assetConfig, jsonPath } = useAppStateContext();

  useEffect(() => {
    if (!assetConfig || !jsonPath) return;

    if (canvasRef?.current !== null) {
      canvasRef.current.width =
        canvasRef.current?.clientWidth * window.devicePixelRatio;
      canvasRef.current.height =
        canvasRef.current?.clientHeight * window.devicePixelRatio;
    }

    const loadConfig = async (jsonPath: string, assetConfig: AssetConfig) => {
      try {
        const tourConfig = await loadTourJson(
          jsonPath,
          assetConfig.signatureData
        );
        setTourConfig(tourConfig);
      } catch (err) {
        throw new Error(`Failed to load ${jsonPath}`);
      }
    };

    loadConfig(jsonPath, assetConfig);
  }, [assetConfig, jsonPath]);

  useEffect(() => {
    if (tourConfig !== null && assetConfig !== null) {
      const run = async () => {
        if (canvasRef.current) {
          const newEngine = await loadEngine(
            tourConfig,
            canvasRef.current,
            assetConfig
          );
          setEngine(newEngine);
        }
      };
      run();
    }
  }, [tourConfig, assetConfig]);

  return {
    engine,
    canvasRef,
    tourConfig,
  };
};

export default useEngine;
