import { PrimaryButton } from "components/elements";
import { InfoPageLayout } from "components/layout";
import { useQuery } from "hooks";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DenyIcon from "../icons/DenyIcon";

const DeclinedPage: FC = () => {
  const query = useQuery();
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <InfoPageLayout
      icon={
        <DenyIcon className="h-[4.6rem] w-[4.6rem] fill-giraffe-dark stroke-giraffe-dark" />
      }
      headLine="The host has denied you access to the guided viewing session"
      btn={
        <PrimaryButton
          text="Return to lobby"
          handleButtonClick={() => {
            navigate(`/${id}?name=${query.get("name")}`, {
              replace: true,
            });
          }}
        />
      }
    />
  );
};

export default DeclinedPage;
