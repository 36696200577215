import { getBlockInnerMarkup, getBlockStyle, getBlockTag } from './block';
/**
 * Functions copied from original library.
 * https://github.com/jpuri/draftjs-to-html/commit/c02452e3ce6719f84f38d39f8f9b0a9e32bf871f
 */
/**
 * Checks if a block is of type list.
 * @param blockType - `string`: block type.
 * @returns `boolean`: `true` if block is of type list. `false` otherwise.
 */
export function isList(blockType) {
    return blockType === 'unordered-list-item' || blockType === 'ordered-list-item';
}
/**
 * Functions copied from original library with minor refactoring.
 * https://github.com/jpuri/draftjs-to-html/commit/c02452e3ce6719f84f38d39f8f9b0a9e32bf871f
 */
/**
 * Gets HTML markup for a list block.
 * @param listBlocks - `RawDraftContentBlock[]`: List blocks.
 * @param entityMap - `EntityMap`: Entity map.
 * @param hashtagConfig - `HashtagConfig` - `Optional`: Hashtag configuration.
 * @param isDirectional - `boolean` - `Optional`: Whether the block list is directional.
 * @param customEntityTransform - `(...args: any[]) => any`: Custom entity transform function.
 * @returns `string`: HTML markup string.
 */
export function getListMarkup(listBlocks, entityMap, hashtagConfig, isDirectional, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
customEntityTransform) {
    const listHtml = [];
    let nestedListBlock = [];
    let previousBlock;
    listBlocks.forEach((block) => {
        let isBlockNested = false;
        if (!previousBlock) {
            listHtml.push(`<${getBlockTag(block.type)}>\n`);
        }
        else if (previousBlock.type !== block.type) {
            listHtml.push(`</${getBlockTag(previousBlock.type)}>\n`);
            listHtml.push(`<${getBlockTag(block.type)}>\n`);
        }
        else if (previousBlock.depth === block.depth) {
            if ((nestedListBlock === null || nestedListBlock === void 0 ? void 0 : nestedListBlock.length) > 0) {
                listHtml.push(getListMarkup(nestedListBlock, entityMap, hashtagConfig, isDirectional, customEntityTransform));
                nestedListBlock = [];
            }
        }
        else {
            isBlockNested = true;
            nestedListBlock.push(block);
        }
        if (isBlockNested)
            return;
        listHtml.push('<li');
        const blockStyle = getBlockStyle(block.data);
        if (blockStyle)
            listHtml.push(` style="${blockStyle}"`);
        if (isDirectional)
            listHtml.push(' dir = "auto"');
        listHtml.push('>');
        listHtml.push(getBlockInnerMarkup(block, entityMap, hashtagConfig, customEntityTransform));
        listHtml.push('</li>\n');
        previousBlock = block;
    });
    if (nestedListBlock.length > 0) {
        listHtml.push(getListMarkup(nestedListBlock, entityMap, hashtagConfig, isDirectional, customEntityTransform));
    }
    if (previousBlock)
        listHtml.push(`</${getBlockTag(previousBlock.type)}>\n`);
    return listHtml.join('');
}
