import type { AnyScalar, Degree, Pos, Radian } from '@g360/vt-types';

// x,y are normalized equirect coords, yaw/pitch are spherical scene coords in radians
// yaw:      -PI .. PI     ->  0 .. 1
// pitch:   PI/2 .. -PI/2  ->  0 .. 1
export function pitchToY(pitch: number): number {
  return -pitch / Math.PI + 0.5;
}

export function yawToX(yaw: number): number {
  return ((yaw / Math.PI / 2) % 1) + 0.5;
}

export function yToPitch(y: number): number {
  return -(y - 0.5) * Math.PI;
}

export function xToYaw(x: number): number {
  return (x - 0.5) * (Math.PI * 2);
}

export function lerp(v0: number, v1: number, t: number): number {
  return v0 * (1 - t) + v1 * t;
}

export function lerpPos<TPos extends Pos<AnyScalar>>(v0: TPos, v1: TPos, t: number): TPos {
  return {
    x: lerp(v0.x, v1.x, t),
    y: lerp(v0.y, v1.y, t),
  } as TPos;
}

export function linearScale(num: number, from: [number, number], to = [0, 1], _boundToRange = true): number {
  const fromRange = from[1] - from[0];
  if (fromRange === 0) console.error('Division by zero in linearScale');

  const toRange = to[1] - to[0];
  // In case if to range is 0, the formula would result in returning to[1] anyway, can skip it and return manually
  if (toRange === 0) return to[1];

  const result = (toRange / fromRange) * (num - from[1]) + to[1];

  if (_boundToRange) {
    const min = Math.min(to[0], to[1]);
    const max = Math.max(to[0], to[1]);

    if (result < min) return min;
    if (result > max) return max;
  }

  return result;
}

export function toRad(n: Degree): Radian {
  return n * (Math.PI / 180);
}

export function toDeg(n: Radian): Degree {
  return n * (180 / Math.PI);
}

export const toDegRound = (n: Radian): Degree => Math.round(toDeg(n));
