import { CameraOffIcon } from "components/icons";
import { FC } from "react";
import { classNamesUtil } from "utils/classNamesUtil";

interface VideoPreviewProps {
  isVideoEnabled: boolean;
  videoRef: React.RefObject<HTMLVideoElement>;
}

const VideoPreview: FC<VideoPreviewProps> = ({ videoRef, isVideoEnabled }) => {
  return (
    <>
      <div
        className={classNamesUtil(
          "transition-all duration-300 ease-in-out rounded-md pt-[83%] lg:pt-[83.25%] xl:pt-[59.25%] relative w-[calc(100%-3px)] h-[calc(100%-3px)] m-[1px]",
          isVideoEnabled ? "opacity-100" : "opacity-0"
        )}
      >
        <video
          muted
          autoPlay
          playsInline
          ref={videoRef}
          id="videoElement-lobby"
          style={{
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            maxWidth: "740px",
            objectFit: "cover",
            borderRadius: "6px",
            position: "absolute",
            transform: "rotateY(180deg)",
          }}
        ></video>
      </div>
      <div
        className={classNamesUtil(
          "absolute rounded-md bg-giraffe-dark flex items-center justify-center w-[calc(100%-3px)] h-[calc(100%-3px)] pt-5 p-16 lg:p-20 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 transition-all duration-300 ease-in-out",
          isVideoEnabled ? "opacity-0" : "opacity-100"
        )}
      >
        <CameraOffIcon className="w-16 h-16 fill-[#313a48]" />
      </div>
    </>
  );
};

export default VideoPreview;
