import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Listbox, Transition } from '@headlessui/react';
import { Fragment, useMemo } from 'react';
import useServices from '../../contexts/useServices';
import useReactive from '../../hooks/useReactive';
import Scrollbars from '../layout/components/Scrollbars';
const PanoSwitcher = ({ className }) => {
    var _a;
    const { gatedTourService, navigationService, tourConfigService } = useServices();
    const { activeScene, activeSubScene, transitionInProgress } = useReactive(navigationService, [
        'activeScene',
        'activeSubScene',
        'transitionInProgress',
    ]);
    const { scenesHidden } = useReactive(tourConfigService, ['scenesHidden']);
    const sceneGroup = useMemo(() => {
        if (!(activeScene === null || activeScene === void 0 ? void 0 : activeScene.subScenes))
            return [];
        const list = [activeScene.sceneKey];
        Object.keys(activeScene.subScenes).forEach((sceneKey) => {
            if (scenesHidden[sceneKey])
                return;
            list.push(sceneKey);
        });
        return list;
    }, [activeScene, scenesHidden]);
    const activeSceneKey = (_a = activeSubScene === null || activeSubScene === void 0 ? void 0 : activeSubScene.sceneKey) !== null && _a !== void 0 ? _a : activeScene === null || activeScene === void 0 ? void 0 : activeScene.sceneKey;
    if (!activeScene)
        return null;
    if (sceneGroup.length <= 1)
        return null;
    if (sceneGroup.length <= 3) {
        return (_jsx("div", { className: `bg-theme-primary bg-opacity-theme-low flex rounded-lg p-0.5 ${className !== null && className !== void 0 ? className : ''}`, children: sceneGroup.map((sceneKey, index) => (_jsx("button", { type: "button", className: `h-9 w-9 rounded-md ${activeSceneKey === sceneKey ? 'btn-theme-active' : 'btn-theme-primary bg-opacity-0'} ${transitionInProgress ? 'cursor-not-allowed' : 'cursor-pointer'}`, onClick: () => {
                    if (!gatedTourService.checkVerification())
                        return;
                    navigationService.switchSubScene(activeScene.sceneKey, sceneKey);
                }, "data-testid": "alt-pano-btn", children: index + 1 }, sceneKey))) }));
    }
    return (_jsxs(Listbox, { as: "div", disabled: transitionInProgress, className: className, value: activeSceneKey, onChange: (nextSceneKey) => {
            if (!gatedTourService.checkVerification())
                return;
            navigationService.switchSubScene(activeScene.sceneKey, nextSceneKey);
        }, children: [_jsx(Listbox.Button, { as: "button", className: "btn-theme-primary disabled:hover:bg-opacity-theme-low group pointer-events-auto h-10 w-16 min-w-0 disabled:cursor-not-allowed", "data-testid": "pano-switch-btn", children: sceneGroup.findIndex((sceneKey) => sceneKey === activeSceneKey) + 1 }), _jsx(Transition, { as: Fragment, children: _jsx(Listbox.Options, { className: "mt-2.5 flex w-16 flex-col rounded-lg", children: _jsx(Scrollbars, { className: "max-h-[15.25rem]", children: sceneGroup.map((sceneKey, index) => (_jsx(Listbox.Option, { value: sceneKey, className: `group h-10 min-h-[2.5rem] w-full cursor-pointer ${activeSceneKey === sceneKey ? 'btn-theme-active' : 'btn-theme-secondary'}`, "data-testid": "alt-pano-btn", children: index + 1 }, sceneKey))) }) }) })] }));
};
export default PanoSwitcher;
