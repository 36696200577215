import { useRef, useEffect } from "react";

const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef<() => void | null>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id: ReturnType<typeof setInterval>;
    if (!savedCallback) return;

    const tick = () => {
      //@ts-ignore
      savedCallback.current();
    };
    if (delay !== null) {
      id = setInterval(tick, delay);
    }

    return () => clearInterval(id);
  }, [delay]);
};

export default useInterval;
