function validator(phoneNumberService, t) {
    return {
        name: (value, required) => {
            if (required && !value)
                return t('contact.error-name');
            // Skip validation if field is not required and empty
            if (!value)
                return null;
            // Regex is for unicode letters, spaces and hyphens
            if (!/^[\p{L}\s-]*$/u.test(value)) {
                return t('contact.error-name-letters-only');
            }
            return null;
        },
        email: (value, required) => {
            if (required && !value)
                return t('contact.error-email-mandatory');
            // Skip validation if field is not required and empty
            if (!value)
                return null;
            // Same regex as in dashboard
            if (!/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)) {
                return t('contact.error-email-invalid');
            }
            return null;
        },
        phone: (value, required) => {
            const phoneNumber = phoneNumberService.validatePhoneNumber(value);
            // This means the parser metadata is missing, so we skip validation
            if (!phoneNumber)
                return null;
            const { nationalNumber } = phoneNumber;
            if (required && (!nationalNumber || (nationalNumber === null || nationalNumber === void 0 ? void 0 : nationalNumber.trim()) === ''))
                return t('contact.error-phone-mandatory');
            // Skip further validation if field is not required and empty
            if (!nationalNumber)
                return null;
            if (!phoneNumber.isValid())
                return t('contact.error-phone-invalid');
            return null;
        },
        message: (value, required) => {
            if (required && !value)
                return t('contact.error-message');
            return null;
        },
    };
}
export default validator;
