import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import useLocalizationContext from '../../../contexts/useLocalizationContext';
import useServices from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
import LogoIcon from '../../branding/icons/LogoIcon';
import formatTimestamp from '../utils/formatTimestamp';
const TimeStampLogo = () => {
    const { layoutService, tourConfigService, navigationService } = useServices();
    const { localeString } = useLocalizationContext();
    const { isNavigationOpen } = useReactive(layoutService, ['isNavigationOpen']);
    const { activeScene } = useReactive(navigationService, ['activeScene']);
    const { timestampFormat, timestampTimezone, tourConfig, features } = useReactive(tourConfigService, [
        'timestampFormat',
        'timestampTimezone',
        'tourConfig',
        'features',
    ]);
    const twLogoVisibility = isNavigationOpen ? 'hidden sm:block' : 'block';
    const timestamp = useMemo(() => {
        var _a;
        if (!activeScene)
            return null;
        const activeTimeStamp = (_a = tourConfig.scenes[activeScene.sceneKey]) === null || _a === void 0 ? void 0 : _a.timestamp;
        if (!activeTimeStamp)
            return null;
        return formatTimestamp(activeTimeStamp, { timestampFormat, locales: localeString, timestampTimezone });
    }, [tourConfig, activeScene, timestampFormat, timestampTimezone, localeString]);
    return (_jsxs(_Fragment, { children: [features.giraffe360Branding && (_jsx(LogoIcon, { className: `ml-auto w-[5.813rem] flex-shrink-0 self-center ${twLogoVisibility}` })), features.timestamp && timestamp && (_jsx("p", { className: `font-primary text-theme-primary mt-2 text-right text-base leading-[1.125rem] ${twLogoVisibility}`, children: timestamp }))] }));
};
export default TimeStampLogo;
