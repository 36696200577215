/**
 * Compiles a shader from a given shader source.
 * @param gl - `WebGLRenderingContext`: An interface to the OpenGL ES 2.0 graphics rendering context for the drawing surface of an HTML `<canvas>` element.
 * @param shaderType - `'VERTEX_SHADER'  |  'FRAGMENT_SHADER'`: The type of shader to compile.
 * @param shaderSource - `string`: The shader source code.
 * @returns `WebGLShader | null`: The compiled shader or `null` if the shader failed to compile.
 */
function compileShader(
  gl: WebGLRenderingContext,
  shaderType: 'VERTEX_SHADER' | 'FRAGMENT_SHADER',
  shaderSource: string
): WebGLShader | null {
  const shader = gl.createShader(gl[shaderType]);

  if (!shader) {
    console.warn('Failed to create shader!');
    return null;
  }

  gl.shaderSource(shader, shaderSource);
  gl.compileShader(shader);

  if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    console.warn('Failed to compile shader:', gl.getShaderInfoLog(shader));
    return null;
  }

  return shader;
}

export default compileShader;
