import type { AutoPlaySceneFrames, Milliseconds } from '@g360/vt-types';

/**
 * Calculate the usable length of the autoplay -- whole panos than can fit in the maxDuration
 */
export const calculateUsableAutoplayLength = (
  scenesFrames: AutoPlaySceneFrames[],
  maxDuration: Milliseconds
): Milliseconds => {
  let usableDuration = 0;
  let totalDuration = 0;
  for (let i = 0; i < scenesFrames.length; i += 1) {
    const sft = scenesFrames[i];
    totalDuration += sft.frames.reduce((acc, frame) => acc + frame.duration, 0);
    if (totalDuration > maxDuration) {
      break;
    }
    usableDuration = totalDuration;
  }
  return usableDuration;
};
