import { FC, useEffect, useState } from "react";
import { RoomNotification, PrimaryButton } from "components/elements";
import { WarningIcon, SuccessIcon } from "components/icons";

interface NotificationProps {
  audioRef: React.MutableRefObject<HTMLAudioElement | undefined>;
  onDismiss: () => void;
  onRetryClick: () => void;
}

const NoAudioNotification: FC<NotificationProps> = ({
  audioRef,
  onDismiss,
  onRetryClick,
}) => {
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    if (success) {
      setTimeout(() => {
        onDismiss();
      }, 2000);
    }
  }, [success, onDismiss]);

  if (success) {
    return (
      <RoomNotification
        width="sm"
        msg="Audio is enabled"
        icon={<SuccessIcon className="w-6 h-6 fill-[#FFFFFF]" />}
        handelClose={onDismiss}
      />
    );
  }

  return (
    <RoomNotification
      width="md"
      msg="No audio"
      actions={
        <PrimaryButton
          text="Retry"
          handleButtonClick={() => {
            onRetryClick();
            if (audioRef.current) {
              audioRef.current
                .play()
                .then(() => {
                  setSuccess(true);
                })
                .catch(() => {
                  setSuccess(false);
                });
            }
          }}
          bgColor="white"
          size="small"
        />
      }
      icon={<WarningIcon className="w-6 h-6 fill-[#FFFFFF]" />}
      handelClose={onDismiss}
    />
  );
};

export default NoAudioNotification;
