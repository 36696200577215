import { MeStatsResponseType } from "types/api.response.types";
import config from "config";

const stats = async (): Promise<MeStatsResponseType> => {
  const res = await fetch(`${config.baseUrl}/gapi/dashboard/me/stats/`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });

  return await res.json();
};

export default stats;
