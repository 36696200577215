import type { DataJsonType, Degree } from '@g360/vt-types';

import { offsetPoint, rotatePoint } from '../autoplay/geometryUtils';
import type { HotSpotGraph } from './HotSpotGraph';
import type { HotSpot, Position, Scenes } from './types';

const calculateDistance = (pos1: Position, pos2: Position): number => {
  let distance = 0;
  const n = pos1.length;
  for (let i = 0; i < n; i += 1) {
    distance += (pos1[i] - pos2[i]) ** 2;
  }
  return Math.sqrt(distance);
};

export const calculateDistance2 = (pos1: Position, pos2: Position): number => {
  let distance = 0;
  for (let i = 0; i < 2; i += 1) {
    distance += (pos1[i] - pos2[i]) ** 2;
  }
  return Math.sqrt(distance);
};

const calculateAngularDistance = (basePos: Position, pos1: Position, pos2: Position): Degree => {
  const vector1 = [pos1[0] - basePos[0], pos1[1] - basePos[1]];
  const vector2 = [pos2[0] - basePos[0], pos2[1] - basePos[1]];
  const dotProduct = vector1[0] * vector2[0] + vector1[1] * vector2[1];
  const magnitude1 = Math.sqrt(vector1[0] ** 2 + vector1[1] ** 2);
  const magnitude2 = Math.sqrt(vector2[0] ** 2 + vector2[1] ** 2);
  const cosAngle = dotProduct / (magnitude1 * magnitude2);
  return Math.acos(cosAngle) * (180 / Math.PI); // to degrees
};

/**
 * filter out hotspots that are in the exact same position on screen - e.g. door hotspot for multiple panos in the same room.
 */
const filterOutOverlappingHotSpots = (graph: HotSpotGraph, scenes: Scenes, distance: number) => {
  let numTotal = 0;
  let numLeft = 0;

  Object.keys(scenes).forEach((sceneKey) => {
    const scenePos = graph.nodes.get(sceneKey)!.position;
    const scene = scenes[sceneKey];
    const unqHotSpots: HotSpot[] = [];
    numTotal += scene.hotSpots.length;
    scene.hotSpots.forEach((hotSpot) => {
      // find all hotspots that are close to this one (this includes self) by HotSpot position (not pano position)
      const closeHotSpots = scene.hotSpots.filter((hs) => {
        const dist = calculateDistance(hs.pos, hotSpot.pos);
        return dist < distance;
      });

      // if there are multiple hotSpots in same location
      if (closeHotSpots.length > 1) {
        // find the closest target pano to the current pano - leave only the hotspot targeting closest pano
        const closestHotSpotByPanoDistance = closeHotSpots.reduce((prev, curr) => {
          const prevDist = calculateDistance(scenePos, graph.nodes.get(prev.target)!.position);
          const currDist = calculateDistance(scenePos, graph.nodes.get(curr.target)!.position);
          return prevDist < currDist ? prev : curr;
        });
        if (!unqHotSpots.includes(closestHotSpotByPanoDistance)) {
          unqHotSpots.push(closestHotSpotByPanoDistance);
        }
      } else if (!unqHotSpots.includes(hotSpot)) {
        unqHotSpots.push(hotSpot);
      }
    });

    scene.hotSpots = unqHotSpots;
    numLeft += scene.hotSpots.length;
  });

  const numFiltered = numTotal - numLeft;
  console.log(numFiltered, 'hotspots filtered out @ distance', distance);
};

/**
 * Filter out direct hotspots that are very close to each other by angle on the floor
 */
const filterOutAngularlyAmbiguousHotSpots = (graph: HotSpotGraph, scenes: Scenes, minAngle: Degree) => {
  const getObstructedNormalHotSpots = (hotSpots: HotSpot[]) =>
    hotSpots.filter((h) => h.type === 'normal' && h.obstructed);
  let numTotal = 0;
  let numLeft = 0;

  // panos
  Object.keys(scenes).forEach((sceneKey) => {
    const scenePos = graph.nodes.get(sceneKey)!.position;
    const scene = scenes[sceneKey];
    const closeHotSpots: HotSpot[] = [];
    numTotal += scene.hotSpots.length;

    // [obstructed normal] hotspots in pano
    getObstructedNormalHotSpots(scene.hotSpots).forEach((hotSpot) => {
      // find all hotspots that are close to this one (this includes self) by HotSpot position (not pano position)
      const closeHss = getObstructedNormalHotSpots(scene.hotSpots).filter((iteratedHotSpot) => {
        if (iteratedHotSpot.target === hotSpot.target) return false; // skip self
        const angle = calculateAngularDistance(scenePos, iteratedHotSpot.pos, hotSpot.pos);
        if (angle < minAngle) console.log(angle, '<', minAngle, sceneKey, ':', iteratedHotSpot.target, '->', hotSpot.target, scenePos, iteratedHotSpot.pos, hotSpot.pos); // prettier-ignore
        return angle < minAngle;
      });
      closeHotSpots.push(...closeHss);
    });

    // if there are multiple hotSpots withing that angle
    if (closeHotSpots.length > 1) {
      // find the closest target pano to the current pano - leave only the hotspot targeting closest pano
      const closestHotSpotByPanoDistance = closeHotSpots.reduce((prev, curr) => {
        const prevDist = calculateDistance(scenePos, graph.nodes.get(prev.target)!.position);
        const currDist = calculateDistance(scenePos, graph.nodes.get(curr.target)!.position);
        return prevDist < currDist ? prev : curr;
      });
      // remove all but the closest hotspot
      const removable = closeHotSpots.filter((h) => h !== closestHotSpotByPanoDistance);
      scene.hotSpots = scene.hotSpots.filter((h) => !removable.includes(h));
    }

    numLeft += scene.hotSpots.length;
  });

  const numFiltered = numTotal - numLeft;
  console.log(numFiltered, 'hotspots filtered out @ angle', minAngle);
};

/**
 * Get all rooms that are pointed to from the scene, set of roomIds from data.json
 */
const getAllRoomsPointedToFromScene = (
  scene: { hotSpots: HotSpot[] },
  roomHasPano: Map<string, string[]>
): Set<string> => {
  const rooms = new Set<string>();
  scene.hotSpots.forEach((hotSpot) => {
    const roomId = Array.from(roomHasPano.entries()).find(([, panos]) => panos.includes(hotSpot.target))?.[0];
    if (roomId) {
      rooms.add(roomId);
    }
  });
  return rooms;
};

const getHotSpotsGroupedByRoom = (
  scene: { hotSpots: HotSpot[] },
  roomHasPano: Map<string, string[]>
): Record<string, HotSpot[]> => {
  const allRoomsPointedToFromScene = getAllRoomsPointedToFromScene(scene, roomHasPano);
  const hotSpotsGroupedByRoom: Record<string, HotSpot[]> = {};
  allRoomsPointedToFromScene.forEach((roomId) => {
    hotSpotsGroupedByRoom[roomId] = scene.hotSpots.filter((hotSpot) =>
      roomHasPano.get(roomId)?.includes(hotSpot.target)
    );
  });
  return hotSpotsGroupedByRoom;
};

/**
 * Returns a list of hotspots that are not the best choice to point to the room
 */
const filterBestHotSpotFromRoomToRoom = (
  fromRoomId: string,
  fromSceneKey: string,
  toRoomId: string,
  toHotSpots: HotSpot[],
  graph: HotSpotGraph,
  dataJson: DataJsonType
): HotSpot[] => {
  // find all doors from and to the room
  const doorsFromRoom: string[] = [];
  const doorsToRoom: string[] = [];
  const doorsBetweenRooms: string[] = [];
  // console.log("filterBestHotSpotFromRoomToRoom", {fromSceneKey});
  Object.entries(dataJson.rooms).forEach(([roomId, room]) => {
    if ((roomId === fromRoomId || room.parent_room_id === fromRoomId) && room.portals) {
      doorsFromRoom.push(...room.portals);
    }
    if ((roomId === toRoomId || room.parent_room_id === toRoomId) && room.portals) {
      doorsToRoom.push(...room.portals);
    }
  });

  // find all doors that are in both rooms (using MATCHED data)
  Object.entries(doorsFromRoom).forEach(([, portalId]) => {
    const portal = dataJson.portals[portalId];
    const matchedPortal = portal.matched_portal_id;
    if (matchedPortal && doorsToRoom.includes(matchedPortal)) {
      doorsBetweenRooms.push(portalId);
    }
  });
  if (doorsBetweenRooms.length === 0) return [];
  // console.log({doorsBetweenRooms});

  const roomRotationFrom = dataJson.rooms[fromRoomId].rotation_rad;
  const roomOffsetFrom = dataJson.rooms[fromRoomId].position_m;

  // find the closest doors between rooms (from HS -> doors -> any HS)
  const posFrom = graph.nodes.get(fromSceneKey)!.position;
  let bestDist = Infinity;
  let bestHotSpot: HotSpot | null = null;

  Object.entries(doorsBetweenRooms).forEach(([, portalId]) => {
    const portal = dataJson.portals[portalId];

    const p1 = offsetPoint(rotatePoint(roomRotationFrom, portal.bot_left_3d_m), roomOffsetFrom);
    const p2 = offsetPoint(rotatePoint(roomRotationFrom, portal.bot_right_3d_m), roomOffsetFrom);
    const portalMidPointGlobal = [((p1[0] + p2[0]) / 2) * 100, ((p1[1] + p2[1]) / 2) * 100] as Position; // meters to cm,
    //     const p1XXX = offsetPoint(rotatePoint(roomRotationFrom, portal.pretty.handle_m), roomOffsetFrom);
    //     const portalMidPointGlobalXXX = [p1XXX[0]* 100, p1XXX[1] * 100] as Position; // meters to cm,
    //     const p1YYY = offsetPoint(rotatePoint(roomRotationFrom, portal.pretty.hinge_m), roomOffsetFrom);
    //     const portalMidPointGlobalYYY = [p1YYY[0]* 100, p1YYY[1] * 100] as Position; // meters to cm,
    // console.log({portalMidPointGlobal,portalMidPointGlobalXXX,portalMidPointGlobalYYY});

    // console.log({posFrom,portalId});
    // console.log({doorMidPos:portalMidPointGlobal});
    const distA = calculateDistance2(posFrom, portalMidPointGlobal); // from starting HS to the door
    // console.log("distance to doors", distA);

    Object.entries(toHotSpots).forEach(([, hotSpot]) => {
      const distB = calculateDistance2(portalMidPointGlobal, hotSpot.pos); // from doors to target HS
      // console.log("distance from doors to candidate HS", distB, hotSpot.target, hotSpot.pos);
      const dist = distA + distB;
      if (dist < bestDist) {
        bestDist = dist;
        bestHotSpot = hotSpot;
      }
    });
  });

  // console.log("bestHotSpot",bestHotSpot);

  const hotpotsToRemove = toHotSpots.filter((iteratedHotSpot) => iteratedHotSpot !== bestHotSpot);
  // console.log({hotpotsToRemove});
  return hotpotsToRemove;
};

/**
 * Filter out multiple hotspots to the same merged room, leaving HS that is closest to the doors
 */
const filterOutMergedRoomHotSpots = (graph: HotSpotGraph, scenes: Scenes, dataJson: DataJsonType) => {
  const roomHasPano = new Map<string, string[]>(); // roomId => panoId[]
  const panosSeen = new Set<string>();

  // find all panos that are in merged rooms
  Object.entries(dataJson.rooms).forEach(([roomId, room]) => {
    if (room.merged_rooms) {
      room.merged_rooms.forEach((panoId) => {
        if (!roomHasPano.has(roomId)) {
          roomHasPano.set(roomId, []);
        }
        roomHasPano.get(roomId)!.push(panoId);
        panosSeen.add(panoId);
      });
    }
  });

  // find all other panos that has own room
  Object.entries(dataJson.rooms).forEach(([roomId, room]) => {
    if (!room.id_name) return;
    const panoId = room.id_name;
    if (panosSeen.has(panoId)) return;
    roomHasPano.set(roomId, [panoId]);
    panosSeen.add(panoId);
  });

  let numRemoved = 0;
  Object.keys(scenes).forEach((sceneKey) => {
    const scene = scenes[sceneKey];
    const fromRoomId = Array.from(roomHasPano.entries()).find(([, panos]) => panos.includes(sceneKey))?.[0];
    if (!fromRoomId) return;
    // group hotspots by room where they point to
    const hotSpotsGroupedByRoom = getHotSpotsGroupedByRoom(scene, roomHasPano);
    Object.entries(hotSpotsGroupedByRoom)
      .filter((x) => x[1].length > 1) // look at rooms with multiple hotspots in them
      .forEach(([roomId, hotSpots]) => {
        const hotSpotsToRemove = filterBestHotSpotFromRoomToRoom(
          fromRoomId,
          sceneKey,
          roomId,
          hotSpots,
          graph,
          dataJson
        );
        numRemoved += hotSpotsToRemove.length;
        scene.hotSpots = scene.hotSpots.filter((hs) => !hotSpotsToRemove.includes(hs));
      });
  });

  console.log(numRemoved, 'hotspots filtered out because they pointed to the same [merged] room');
};

export const visuallyFilterHotSpots = (
  graph: HotSpotGraph,
  scenes: Scenes,
  filterHsByDistance: number,
  filterHsByAngle: Degree,
  dataJson?: DataJsonType
) => {
  if (dataJson) {
    filterOutMergedRoomHotSpots(graph, scenes, dataJson);
  }
  filterOutOverlappingHotSpots(graph, scenes, filterHsByDistance);
  filterOutAngularlyAmbiguousHotSpots(graph, scenes, filterHsByAngle);

  return scenes;
};
