class FadeAnimator {
    constructor() {
        this.comboFadeOutTTL = 0;
        this.pauseBeforeFloorPlanFadeInTTL = 0;
        this.floorPlanFadeInTTL = 0;
        this.pauseBeforeActivePinFadeInTTL = 0;
        this.activePinFadeInTTL = 0;
        this.pauseBeforePinsFadeInTTL = 0;
        this.pinsFadeInTTL = 0;
        /** parent is setting this to true when at least one the new FP image are loaded */
        this._fpImageLoaded = false;
        this._animating = false;
    }
    /** parent sets this true, when onFadeOutCompletedNewFPImage is completed (not just called) */
    set fpImageLoaded(value) {
        this._fpImageLoaded = value;
    }
    /** a.k.a. is animator running ? */
    get animating() {
        return this._animating;
    }
    /**
     * @param initial -- is this first time since the Minimap load ?
     */
    startAnimation(initial) {
        this._animating = true;
        this._fpImageLoaded = false;
        this.comboFadeOutTTL = FadeAnimator.comboFadeOutTime;
        this.pauseBeforeFloorPlanFadeInTTL = FadeAnimator.pauseBeforeFloorPlanFadeInTime;
        this.floorPlanFadeInTTL = FadeAnimator.floorPlanFadeInTime;
        this.pauseBeforeActivePinFadeInTTL = FadeAnimator.activePinFadeInDelay;
        this.activePinFadeInTTL = FadeAnimator.activePinFadeInTime;
        this.pauseBeforePinsFadeInTTL = FadeAnimator.pinFadeInDelay;
        this.pinsFadeInTTL = FadeAnimator.pinFadeInTime;
        if (initial) {
            // skip fadeOut portion
            this.comboFadeOutTTL = 1;
            // wait a little for initial fade in
            this.pauseBeforeFloorPlanFadeInTTL = FadeAnimator.initialPauseBeforeFloorPlanFadeIn;
        }
    }
    /**
     * Since this function is called on every frame (and has multiple returns),
     * we are reusing the same object to avoid creating new objects on every frame. (minimize GC)
     *
     * This functions animates minimap by being called on every frame, for roughly 1 second.
     * Each segment has its TTL counter, when that runs out the next segment gets activated.
     * At one time only one segment is active and changes opacities of floorPlan, activePin and pins.
     *
     *
     * @param deltaTime -- time since last frame
     * @param changes_ -- reusable object to store changes
     *
     * @returns -- should parent re-render everything
     */
    doAnimations(deltaTime, changes_) {
        if (!this._animating)
            return false;
        changes_.floorPlanOpacity = 1;
        changes_.activePinOpacity = 1;
        changes_.pinsOpacity = 1;
        if (this.comboFadeOutTTL > 0) {
            this.comboFadeOutTTL -= deltaTime;
            const opacity = Math.max(this.comboFadeOutTTL / FadeAnimator.comboFadeOutTime, 0);
            changes_.floorPlanOpacity = opacity;
            changes_.activePinOpacity = opacity;
            changes_.pinsOpacity = opacity;
            return true;
        }
        // fade-out completed
        // try calling callbacks now - nothing is visible, can safely change data
        this.tryCallingCallbacks();
        if (this.pauseBeforeFloorPlanFadeInTTL > 0) {
            this.pauseBeforeFloorPlanFadeInTTL -= deltaTime;
            changes_.floorPlanOpacity = 0;
            changes_.activePinOpacity = 0;
            changes_.pinsOpacity = 0;
            return true;
        }
        // Pause between fade-out and fade-in is completed,
        // but before we start fading-in, we must be sure that both callbacks were called
        // and second callback finished its work
        if (!this._fpImageLoaded) {
            changes_.floorPlanOpacity = 0;
            changes_.activePinOpacity = 0;
            changes_.pinsOpacity = 0;
            return false;
        }
        if (this.floorPlanFadeInTTL > 0) {
            this.floorPlanFadeInTTL -= deltaTime;
            changes_.floorPlanOpacity = Math.min(1 - this.floorPlanFadeInTTL / FadeAnimator.floorPlanFadeInTime, 1);
            changes_.activePinOpacity = 0;
            changes_.pinsOpacity = 0;
            return true;
        }
        if (this.pauseBeforeActivePinFadeInTTL > 0) {
            this.pauseBeforeActivePinFadeInTTL -= deltaTime;
            changes_.activePinOpacity = 0;
            changes_.pinsOpacity = 0;
            return true;
        }
        if (this.activePinFadeInTTL > 0) {
            this.activePinFadeInTTL -= deltaTime;
            changes_.activePinOpacity = Math.min(1 - this.activePinFadeInTTL / FadeAnimator.pinFadeInTime, 1);
            changes_.pinsOpacity = 0;
            return true;
        }
        if (this.pauseBeforePinsFadeInTTL > 0) {
            this.pauseBeforePinsFadeInTTL -= deltaTime;
            changes_.pinsOpacity = 0;
            return true;
        }
        if (this.pinsFadeInTTL > 0) {
            this.pinsFadeInTTL -= deltaTime;
            changes_.pinsOpacity = Math.min(1 - this.pinsFadeInTTL / FadeAnimator.pinFadeInTime, 1);
            return true;
        }
        this._animating = false;
        return true;
    }
    tryCallingCallbacks() {
        if (this.onFadeOutCompletedNewRenderers) {
            this.onFadeOutCompletedNewRenderers();
            this.onFadeOutCompletedNewRenderers = undefined;
        }
        if (this.onFadeOutCompletedNewFPImage) {
            this.onFadeOutCompletedNewFPImage();
            this.onFadeOutCompletedNewFPImage = undefined;
        }
    }
}
FadeAnimator.initialPauseBeforeFloorPlanFadeIn = 500;
FadeAnimator.comboFadeOutTime = 500;
FadeAnimator.pauseBeforeFloorPlanFadeInTime = 1;
FadeAnimator.floorPlanFadeInTime = 250;
FadeAnimator.activePinFadeInTime = 150;
FadeAnimator.activePinFadeInDelay = 1;
FadeAnimator.pinFadeInTime = 250;
FadeAnimator.pinFadeInDelay = 1;
export default FadeAnimator;
