var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { forwardRef } from 'react';
const Scrollbars = forwardRef((_a, ref) => {
    var { children, className = '' } = _a, rest = __rest(_a, ["children", "className"]);
    return (_jsx(OverlayScrollbarsComponent, Object.assign({ ref: ref, className: `scrollbars-theme ${className}`, options: {
            scrollbars: { theme: 'os-theme-custom' },
        } }, rest, { children: children })));
});
Scrollbars.displayName = 'ScrollbarsTheme';
export default Scrollbars;
