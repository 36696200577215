var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ErrorLogger, fetchWithRetries } from '@g360/vt-utils';
import { createContext, useContext } from 'react';
import urlJoin from 'url-join';
import bindUseContext from './bindUseContext';
// @ts-expect-error ignore default value
const LocalizationContext = createContext();
export default bindUseContext(LocalizationContext, 'LocalizationContext');
export { LocalizationContext };
let lastUsedLang = '';
const availableLanguages = ['en', 'de', 'es', 'et', 'fi', 'fr', 'hr', 'it', 'lv', 'nl', 'pl', 'pt', 'ro', 'sk', 'sv'];
const defaultLanguage = 'en';
export const getLocalizationLanguage = () => {
    if (typeof window === 'undefined')
        return defaultLanguage;
    const htmlLang = document.documentElement.getAttribute('lang');
    const urlParams = new URLSearchParams(window.location.search);
    const forceLanguage = urlParams.get('debugLanguage');
    const lang = forceLanguage || htmlLang;
    return availableLanguages.includes(lang) ? lang : defaultLanguage;
};
export const getLocaleString = (lang) => {
    const language = lang !== null && lang !== void 0 ? lang : getLocalizationLanguage();
    if (Intl === null || Intl === void 0 ? void 0 : Intl.getCanonicalLocales) {
        return Intl.getCanonicalLocales(language)[0];
    }
    return language;
};
export const fetchTranslations = (lang, assetConfig, abortController) => __awaiter(void 0, void 0, void 0, function* () {
    const shortCode = lang !== null && lang !== void 0 ? lang : getLocalizationLanguage();
    lastUsedLang = shortCode;
    const namespace = 'skin'; // currently there only one namespace and it's hardcoded
    const url = urlJoin(assetConfig.assetPath, 'locales', shortCode, `${namespace}.json`);
    // TODO: add caching, better error handling
    const response = yield fetchWithRetries(url, { signal: abortController.signal });
    if (!response.ok) {
        // eslint-disable-next-line no-console
        console.error(`lang=${shortCode} status=${response.status}`);
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const json = yield response.json();
    const translationMap = new Map();
    translationMap.set('locale', shortCode);
    function traverse(obj, currentPath = '') {
        Object.keys(obj).forEach((key) => {
            if (typeof obj[key] === 'object') {
                traverse(obj[key], currentPath ? `${currentPath}.${key}` : key);
            }
            else {
                translationMap.set(`${currentPath}.${key}`, obj[key]);
            }
        });
    }
    traverse(json);
    return translationMap;
});
export function curriedTranslate(translations) {
    return (key, replacements) => {
        if (!translations)
            return key;
        if (!translations.has(key)) {
            // eslint-disable-next-line no-console
            console.error(`key=${key}, lang=${lastUsedLang}`);
            ErrorLogger.captureMessage(`Translations missing key!`);
        }
        let value = translations.get(key) || key;
        if (replacements) {
            Object.entries(replacements).forEach(([replaceKey, replaceValue]) => {
                const placeholder = `{{${replaceKey}}}`;
                value = value.replace(new RegExp(placeholder, 'g'), String(replaceValue));
            });
        }
        return value;
    };
}
export function useTranslation() {
    const ctx = useContext(LocalizationContext);
    const translate = curriedTranslate(ctx.translations);
    return translate;
}
