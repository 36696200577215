import { NoAccessIcon } from "components/icons";
import { InfoPageLayout } from "components/layout";
import { FC } from "react";

const GuidedViewingNotFound: FC = () => {
  return (
    <InfoPageLayout
      icon={<NoAccessIcon className="w-[4.6rem] h-[4.6rem]" />}
      headLine="Guided Viewing is not available"
    />
  );
};

export default GuidedViewingNotFound;
