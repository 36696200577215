import urljoin from 'url-join';
/** Helper class to handle phone-codes.json data and flag paths */
class PhoneCodes {
    constructor(jsonData, flagsPath) {
        /**
         * Object containing country name, dial code and url to the flag image, key is lowercase country code (like: lv, gb)
         * Used for faster access to country data by country code
         */
        this.countriesObject = null;
        /** List of country data objects, used for displaying country list in the UI, sorted by country name */
        this.countriesList = null;
        const parseCountryData = (country) => ({
            name: country.name,
            callingCode: country.dial_code.replace('+', ''),
            countryCode: country.code,
            flagUrl: urljoin(flagsPath, `${country.code.toLowerCase()}.png`),
        });
        this.countriesList = jsonData
            .map((country) => parseCountryData(country))
            .sort((a, b) => a.name.localeCompare(b.name));
        this.countriesObject = jsonData.reduce((acc_, country) => {
            acc_[country.code.toUpperCase()] = parseCountryData(country);
            return acc_;
        }, {});
    }
    getCountryData(countryCode) {
        if (!this.countriesObject)
            return null;
        return this.countriesObject[countryCode.toUpperCase()];
    }
    getCountryCodesForCallingCode(partialNumber) {
        var _a;
        if (!this.countriesObject)
            return null;
        const filteredCountries = (_a = this.countriesList) === null || _a === void 0 ? void 0 : _a.filter((country) => partialNumber.replace('+', '').startsWith(country.callingCode));
        return filteredCountries === null || filteredCountries === void 0 ? void 0 : filteredCountries.map((country) => country.countryCode);
    }
}
export default PhoneCodes;
