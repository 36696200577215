import {
  destroyProgram,
  disableVertexAttributes,
  enableVertexAttributes,
  initShaders,
  loadShaders,
} from '../../../common/webglUtils';
import type MeasureToolProgram from '../MeasureToolProgram';
import fragmentShaderSource from './measureMissingData.fs.glsl';
import vertexShaderSource from './measureMissingData.vs.glsl';

class MeasureMissingDataProgram {
  private program: WebGLProgram | null = null;

  private gl: WebGLRenderingContext;
  private canvas: HTMLCanvasElement;
  private mainProgram: MeasureToolProgram;

  private vertexBuffer: WebGLBuffer | null;
  private vertexAttribute = 0;

  private depthMapUniform: WebGLUniformLocation | null = null;

  private lineThicknessUniform: WebGLUniformLocation | null = null;
  private lineSpacingUniform: WebGLUniformLocation | null = null;

  private aspectRatioUniformLocation: WebGLUniformLocation | null = null;
  private yawUniformLocation: WebGLUniformLocation | null = null;
  private pitchUniformLocation: WebGLUniformLocation | null = null;
  private fovUniformLocation: WebGLUniformLocation | null = null;

  private vertexAttributes: number[] = []; // list vertex attributes to be enabled before and disabled after a draw in order to not mess up state of other programs

  private vertices = new Float32Array([-1, 1, 1, 1, 1, -1, -1, 1, 1, -1, -1, -1]);

  constructor(webGLContext: WebGLRenderingContext, canvas: HTMLCanvasElement, mainProgram: MeasureToolProgram) {
    this.gl = webGLContext;
    this.canvas = canvas;
    this.mainProgram = mainProgram;

    this.vertexBuffer = this.gl.createBuffer();
  }

  init(): void {
    this.program = initShaders(this.gl, vertexShaderSource, fragmentShaderSource);

    if (this.program) {
      this.vertexAttribute = this.gl.getAttribLocation(this.program, 'a_texCoord');

      this.depthMapUniform = this.gl.getUniformLocation(this.program, 'u_tex_depth_map');

      this.lineThicknessUniform = this.gl.getUniformLocation(this.program, 'u_line_thickness');
      this.lineSpacingUniform = this.gl.getUniformLocation(this.program, 'u_line_spacing');

      this.aspectRatioUniformLocation = this.gl.getUniformLocation(this.program, 'u_aspectRatio');
      this.yawUniformLocation = this.gl.getUniformLocation(this.program, 'u_yaw');
      this.pitchUniformLocation = this.gl.getUniformLocation(this.program, 'u_pitch');
      this.fovUniformLocation = this.gl.getUniformLocation(this.program, 'u_fov');

      this.vertexAttributes = [this.vertexAttribute];
    }
  }

  destroy(): void {
    destroyProgram(this.gl, this.program);
  }

  draw(): void {
    loadShaders(this.gl, this.program);
    enableVertexAttributes(this.gl, this.vertexAttributes);

    this.gl.enable(this.gl.CULL_FACE);
    this.gl.cullFace(this.gl.FRONT);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.vertices, this.gl.DYNAMIC_DRAW);
    this.gl.enableVertexAttribArray(this.vertexAttribute);
    this.gl.vertexAttribPointer(this.vertexAttribute, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.activeTexture(this.gl.TEXTURE1);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.mainProgram.depthMapTextureObject);
    this.gl.uniform1i(this.depthMapUniform, 1);

    const ratio = Math.round(window.devicePixelRatio);

    const thickness = 1 * ratio;
    const spacing = 10 * ratio;

    // must be at least a pixel
    this.gl.uniform1f(this.lineThicknessUniform, Math.max(1, thickness));
    // must be at least 10 pixels, or the lines will blend together into solid color when zoomed out
    this.gl.uniform1f(this.lineSpacingUniform, Math.max(10, spacing));

    this.gl.uniform1f(this.aspectRatioUniformLocation, this.gl.drawingBufferWidth / this.gl.drawingBufferHeight);
    this.gl.uniform1f(this.yawUniformLocation, this.mainProgram.yaw + this.mainProgram.yawOffset);
    this.gl.uniform1f(this.pitchUniformLocation, this.mainProgram.pitch);
    this.gl.uniform1f(this.fovUniformLocation, this.mainProgram.fov);

    this.gl.drawArrays(this.gl.TRIANGLES, 0, 6);

    this.gl.disable(this.gl.CULL_FACE);
    disableVertexAttributes(this.gl, this.vertexAttributes);
  }
}

export default MeasureMissingDataProgram;
