import useGlobalContext from '../contexts/useGlobalContext';
import useReactive from './useReactive';
function getThemeClassName(theme) {
    return theme === 'light' ? 'vt-theme-light' : 'vt-theme-dark';
}
const useBranding = () => {
    const { engine } = useGlobalContext();
    const tourConfigService = engine.getTourConfigService();
    const { theme } = useReactive(tourConfigService, ['theme']);
    return {
        theme,
        themeClassName: getThemeClassName(theme),
    };
};
export default useBranding;
