import type {
  AutoplayDebugData,
  AutoplayOptions,
  AutoplayResult,
  AutoPlaySceneFrames,
  DataJsonType,
  Degree,
  Milliseconds,
  Radian,
  SceneConfig,
  TourConfig,
} from '@g360/vt-types';

import { toRad } from '../math';
import { calculateUsableAutoplayLength, getTourConfigStructureType, parseTourConfig, verStrToArray } from '../misc';
import { addPortalsToPanos, addRoomIdsToPanos, calculateDuration, makePanos, superMatchPortals } from './dataUtils';
import {
  addTransitionsKeyframes,
  createAutoPlayForSinglePano,
  createAutoPlayFramesFromPanoKeyFrames,
  generatePanoKeyFrames,
} from './keyFrameGenerator';
import { generatePath, generatePathForOutsideOnly } from './pathGenerator';
import scaleScenesFrames from './scaleScenesFrames';

type InternalConfig = {
  firstSceneKey: string;
  firstSceneYaw: Radian;
  transitionDuration: Milliseconds;
  defaultPitch: Degree;
  verbose?: boolean;
};

const generateAutoplayDataInternal = (
  tourConfig: TourConfig,
  dataJson: DataJsonType | null,
  config: InternalConfig
) => {
  const { firstSceneKey, firstSceneYaw, transitionDuration, defaultPitch } = config;

  // console.log(" ↓↓↓   input for unit tests: cont input = ...   ↓↓↓");
  // const cleanedTourConfig = deleteUnwantedProperties(JSON.parse(JSON.stringify(tourConfig)));
  // const cleanedDataJson = deleteUnwantedProperties(JSON.parse(JSON.stringify(dataJson)));
  // console.log({dataJson:cleanedDataJson,tourConfig:cleanedTourConfig,firstSceneKey,firstSceneYaw,transitionDuration,defaultPitch});

  const configVersionArr = verStrToArray(tourConfig.version || '0');
  const tourConfigStructureType = getTourConfigStructureType(tourConfig, configVersionArr);
  const fixedTourConfig = parseTourConfig(tourConfig, tourConfigStructureType);
  const panos = makePanos(fixedTourConfig.scenes);

  // if there is just 1 pano, create some simple rotation autoplay (no data.json required)
  if (Object.keys(panos).length === 1) {
    const scenesFrames = createAutoPlayForSinglePano(panos, defaultPitch);
    const sceneFramesTransitions = addTransitionsKeyframes(scenesFrames, transitionDuration);
    return { scenesFrames: sceneFramesTransitions, debugData: {} as AutoplayDebugData };
  }

  // for projects with only outside panos, no data.json exists, generating special autoplay
  if (Object.values(panos).every((pano) => pano.outside)) {
    const path = generatePathForOutsideOnly(panos, firstSceneKey);
    const { panoKeyFrames } = generatePanoKeyFrames(firstSceneYaw, path, panos, null);
    const scenesFrames = createAutoPlayFramesFromPanoKeyFrames(panoKeyFrames, firstSceneYaw, defaultPitch);
    const sceneFramesTransitions = addTransitionsKeyframes(scenesFrames, transitionDuration);
    return { scenesFrames: sceneFramesTransitions, debugData: {} as AutoplayDebugData };
  }

  // for projects with multiple panos, data.json is required, no autoplay if it's missing
  if (dataJson === null) {
    console.error('missing data.json');
    return { scenesFrames: [] as AutoPlaySceneFrames[], debugData: {} as AutoplayDebugData };
  }

  addRoomIdsToPanos(panos, dataJson); // changes panos in place;
  addPortalsToPanos(panos, dataJson); // changes panos in place
  superMatchPortals(dataJson); // changes dataJson in place

  const { path, wholeGraph, subGraphs } = generatePath(panos, firstSceneKey);
  const { panoKeyFrames, debugPointsOfInterest } = generatePanoKeyFrames(firstSceneYaw, path, panos, dataJson);

  const debugData = {
    dataJson,
    dataPanos: panos,
    path,
    debugPointsOfInterest,
    wholeGraph,
    subGraphs,
    firstSceneKey,
    firstSceneYaw,
  } as AutoplayDebugData;

  const scenesFrames = createAutoPlayFramesFromPanoKeyFrames(panoKeyFrames, firstSceneYaw, defaultPitch);
  const sceneFramesTransitions = addTransitionsKeyframes(scenesFrames, transitionDuration);
  return { scenesFrames: sceneFramesTransitions, debugData } as AutoplayResult;
};

/**
 * @param tourConfig - Virtual tour config (tour.json)
 * @param rsJson - Roomsketcher data (data.json) if available or null.
 *  If not provided and all scenes are outside, autoplay will be generated only using tourConfig
 *  Else returns empty autoplay data
 * @param options - autoplay options {@link AutoplayOptions}
 * @param options.firstSceneKey - degrees, if not provided, tourConfig.firstScene will be used
 * @param options.firstSceneYaw - if not provided, first scene.view from tour config will be used or fall back to 0
 *  Angle is relative to the panorama layout - 0 is the center of the layout
 * @param options.defaultPitch - degrees, default camera look down angle, fixed for all scenes
 * @param options.defaultTransitionDuration - default transition duration in milliseconds
 * @param options.maxLength - maximum length of the autoplay in milliseconds
 * @returns - autoplay data {@link AutoplayResult}
 */
function generateAutoplayData(tourConfig: TourConfig, rsJson: DataJsonType | null, options?: AutoplayOptions | null) {
  const firstSceneKey = options?.firstSceneKey || tourConfig.firstScene;
  // Validate first scene key as it can be anything when provided from options, as we discussed - fail early
  if (!tourConfig.scenes[firstSceneKey]) {
    throw new Error(`Autoplay: option ${firstSceneKey} for firstSceneKey or tourConfig.firstScene is not valid!`);
  }

  const firstSceneData = tourConfig.scenes[firstSceneKey] as SceneConfig;

  // It is possible that scene.view in not set, so we need to fallback to 0
  let initialYaw = options?.firstSceneYaw ?? ((firstSceneData.view?.[1] ?? 0) as Degree);
  // Counter the yawOffset because this is done in the Engine normally for initial view, but autoplay doesn't do it
  // Initial view is relative to the layout, and the engine always adjusts rotation to floorplan
  initialYaw -= firstSceneData.camera[3] as Degree;

  const defaultPitch = options?.defaultPitch ?? (-5 as Degree);
  const transitionDuration = options?.defaultTransitionDuration || (1000 as Milliseconds);
  const speed = options?.speed || 1;
  const maxLength = (options?.maxLength || 10 * 60 * 1000) as Milliseconds; // default: 10 minutes

  if (options?.verbose) {
    /* eslint-disable no-console */
    if (!rsJson) {
      console.log('Autoplay: no data.json provided, generating autoplay using only tourConfig');
    }
    console.log('Autoplay: firstSceneKey:', firstSceneKey);
    // This is the yaw that is used in the engine for initial view, not the one relative to the layout
    console.log('Autoplay: firstSceneYaw (deg engine):', initialYaw);
    console.log('Autoplay: defaultPitch (deg):', defaultPitch);
    console.log('Autoplay: transitionDuration (ms):', transitionDuration);
    console.log('Autoplay: speed:', options?.speed || 'not set');
    /* eslint-enable no-console */
  }

  const autoplayResult = generateAutoplayDataInternal(tourConfig, rsJson, {
    firstSceneKey,
    firstSceneYaw: toRad(initialYaw),
    transitionDuration,
    defaultPitch,
    verbose: options?.verbose,
  });

  autoplayResult.scenesFrames = scaleScenesFrames(autoplayResult.scenesFrames, speed);

  if (options?.verbose) {
    /* eslint-disable no-console */

    console.log({ scenesFrames: autoplayResult.scenesFrames });
    const { min, sec, totalSeconds } = calculateDuration(autoplayResult.scenesFrames);
    const timeReadable = `${String(min).padStart(2, '0')}:${String(sec).padStart(2, '0')}`;
    console.log(`Autoplay: final: length of autoplay: ${timeReadable} (${totalSeconds} sec)`);

    const usableLength = calculateUsableAutoplayLength(autoplayResult.scenesFrames, maxLength);
    const uMin = Math.floor(usableLength / 60000);
    const uSec = Math.floor((usableLength - uMin * 60000) / 1000);
    const uTotalSeconds = usableLength / 1000;
    console.log(
      `Autoplay: usable length of autoplay: ${uMin}:${uSec} (${uTotalSeconds} sec), maxLength: ${maxLength / 1000} sec`
    );

    const numUniqueScenesFrames = new Set(autoplayResult.scenesFrames.map((sft) => sft.sceneKey)).size;
    const numScenes = Object.keys(tourConfig.scenes).length;
    console.log(
      `Autoplay: final: ${autoplayResult.scenesFrames.length} scenes in path, ${numUniqueScenesFrames} unique scenes, (${numScenes} scenes in tour config)`
    );

    /* eslint-enable no-console */
  }

  return autoplayResult;
}

export default generateAutoplayData;
