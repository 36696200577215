import type { Pixel, Pos, ScenePos } from '@g360/vt-types';

import FloorPlan3DProgram from '../programs/FloorPlan3DProgram';
import GestureController from './GestureController';
import type { ZoomEvent } from './GestureController/types';

export default class FloorPlan3DController extends GestureController {
  private fp3DProgram: FloorPlan3DProgram;

  constructor(fp3DProgram: FloorPlan3DProgram) {
    super();
    this.fp3DProgram = fp3DProgram;
  }

  /**
   * Overriding zoom function to change Engine.zoom instead of Engine.fov
   */
  protected onZoom({ delta, source }: ZoomEvent) {
    this.zoomFloorPlan(delta, source !== 'wheel');
  }

  /**
   * Overriding to invert dragging
   */
  protected getScenePosDelta(eventPos: Pos<Pixel>, lastPos: Pos<Pixel>): ScenePos {
    const normalDelta = super.getScenePosDelta(eventPos, lastPos);

    // invert everything + invert again for top-down view
    const invertYaw = this.fp3DProgram.topDownView ? -1 : 1;
    const yaw = -normalDelta.yaw * invertYaw;
    const pitch = -normalDelta.pitch;

    return { ...normalDelta, yaw, pitch };
  }

  /**
   * Overriding to disable hotspots.
   * They are not rendered, this prevents them from being hovered/clicked.
   */
  // eslint-disable-next-line class-methods-use-this
  protected getHotspotOnPosition(): number {
    return -1;
  }
}
