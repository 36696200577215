// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import type {
  AnyScalar,
  AssetConfig,
  Boundary,
  ClipSpace,
  Pixel,
  Pos,
  Radian,
  Ratio,
  SceneConfig,
  ScenePos,
  Size,
} from '@g360/vt-types';
import {
  ErrorLogger,
  getVec3,
  getVec3Difference,
  getVec3Dir,
  getVec3Dot,
  getVec3Length,
  getVec3ScalarMultiply,
  linearScale,
  multiplyM4AndPoint,
  normalizeVec3,
  toDeg,
  toRad,
} from '@g360/vt-utils';
import { decode } from 'fast-png';
import rayTriangleIntersection from 'ray-triangle-intersection';

import type { Image, PointData } from '../../types/internal';
import { MAX_FOV_RAD } from '../Globals';
import { getViewAngle, horizontalToVerticalFovRad, verticalToHorizontalFovRad } from './fovUtils/fovUtils';

export function roundFloat(num: number, places = 0): number {
  const temp = 10 ** places;
  return Math.round(num * temp) / temp;
}

export function polarToRect(radius: number, angleRads: number): { x: number; y: number } {
  const normalAngle = angleRads - toRad(90); // set 0deg to north (up)

  return {
    x: radius * Math.cos(normalAngle),
    y: radius * Math.sin(normalAngle),
  };
}

export function boundToRange(value: number, range: [number, number]): number {
  const min = Math.min(range[0], range[1]);
  const max = Math.max(range[0], range[1]);

  if (value < min) return min;

  if (value > max) return max;

  return value;
}

export function toClipSpace(pixelPos: Pixel, canvasSize: number): ClipSpace {
  // maps to -1, 1 clip space coords
  return (pixelPos / canvasSize) * 2 - 1;
}

// Convert from canvas x, y coords to camera pitch, yaw, canvas center = camera(0, 0)
export function canvasToCamera(mousePos: Pos<Pixel>, canvasSize: Size, hFov: number): ScenePos {
  const spx = toClipSpace(mousePos.x, canvasSize.width);
  const spy = toClipSpace(mousePos.y, canvasSize.height);
  const vFov = getViewAngle(hFov, canvasSize.height / canvasSize.width);

  return {
    yaw: Math.atan2(spx, 1 / Math.tan(hFov / 2)),
    pitch: Math.atan2(-spy, 1 / Math.tan(vFov / 2)),
  };
}

export function getRenderedFov(cameraFov: Radian, size: Size) {
  const vFovRad = horizontalToVerticalFovRad(cameraFov, size);

  // Limit vertical fov
  if (vFovRad > MAX_FOV_RAD) {
    return verticalToHorizontalFovRad(MAX_FOV_RAD, size);
  }
  return cameraFov;
}

export function getAverageValue(array: number[]): number {
  return array.reduce((a, b) => a + b) / array.length;
}

export function getMaxValue(array: number[]): number {
  return array.reduce((p, v) => Math.max(p, v));
}

function getVec2Length(vec2): number {
  return Math.sqrt(vec2[0] ** 2 + vec2[1] ** 2);
}

function getVec2(pt1: number[], pt2: number[]): number[] {
  return [pt2[0] - pt1[0], pt2[1] - pt1[1]];
}

function getVec2Normalized(vec2: number[]): number[] {
  const len = getVec2Length(vec2);
  return [vec2[0] / len, vec2[1] / len];
}

export function getVec4Sum(vec4A: number[], vec4B: number[]): number[] {
  return [vec4A[0] + vec4B[0], vec4A[1] + vec4B[1], vec4A[2] + vec4B[2], vec4A[3] + vec4B[3]];
}

function absAngle(a: number): number {
  // this yields correct counter-clock-wise numbers, like 350deg for -370
  return (360 + (a % 360)) % 360;
}

export function angleDelta(a: number, b: number): number {
  const delta = Math.abs(absAngle(a) - absAngle(b));
  const sign = absAngle(a) > absAngle(b) || delta >= 180 ? -1 : 1;
  return (180 - Math.abs(delta - 180)) * sign;
}

export function lineSegmentTriangleIntersection(
  triangle: number[],
  lineSegment: number[][],
  onlyCWTriangles = false
): null | number[] {
  const pt = [lineSegment[0][0], lineSegment[0][1], lineSegment[0][2]];
  const pt2 = [lineSegment[1][0], lineSegment[1][1], lineSegment[1][2]];
  const vec = getVec3(pt, pt2);
  const len = getVec3Length(vec);
  const dir = getVec3Dir(vec, len);

  const tri = [
    [triangle[0], triangle[1], triangle[2]],
    [triangle[3], triangle[4], triangle[5]],
    [triangle[6], triangle[7], triangle[8]],
  ];

  const ispt1 = rayTriangleIntersection([], pt, dir, tri);
  const ispt2 = onlyCWTriangles ? false : rayTriangleIntersection([], pt, dir, tri.reverse()); // check both sides of the triangle if needed
  const ispt = ispt1 || ispt2;
  if (ispt) {
    const ivec = getVec3(pt, ispt);
    const ilen = getVec3Length(ivec);
    const idir = getVec3Dir(ivec, ilen);

    let sameDirection = true;
    idir.forEach((v, i) => {
      if (roundFloat(v, 2) !== roundFloat(dir[i], 2)) {
        sameDirection = false;
      }
    });

    if (ilen <= len && sameDirection) {
      return ispt;
    }
  }

  return null;
}

export function getCameraWallCrossPointGeneric(
  geometry: number[][],
  p1: number[],
  p2: number[],
  findFarthest = true,
  onlyCWTriangles = false
): null | number[] {
  let bestPoint: null | number[] = null;

  const camPath = [
    [p1[0], p1[1], p1[2]],
    [p2[0], p2[1], p2[2]],
  ];

  let bestVectorLength = findFarthest ? 0 : 999999999;

  geometry.forEach((face) => {
    const intersection = lineSegmentTriangleIntersection(face, camPath, onlyCWTriangles);

    if (intersection) {
      const vector = { x: intersection[0] - camPath[0][0], y: intersection[1] - camPath[0][1] };
      const vectorLength = vector.x ** 2 + vector.y ** 2;

      if (findFarthest && vectorLength > bestVectorLength) {
        bestVectorLength = vectorLength;
        bestPoint = intersection;
      } else if (!findFarthest && vectorLength < bestVectorLength) {
        bestVectorLength = vectorLength;
        bestPoint = intersection;
      }
    }
  });

  return bestPoint;
}

// Scan through geometry data and find if path between two scenes is crossing any walls and return position of the
// farthest or closest point if found
export function getCameraWallCrossPoint(
  geometry: number[][],
  targetScene: SceneConfig,
  sourceScene: SceneConfig,
  findFarthest = true
): null | number[] {
  return getCameraWallCrossPointGeneric(geometry, targetScene.camera, sourceScene.camera, findFarthest);
}

export function deepFreeze<T>(obj: T): T {
  const propNames = Object.getOwnPropertyNames(obj);
  // eslint-disable-next-line
  for (const name of propNames) {
    // eslint-disable-next-line
    const value = (obj as any)[name];
    if (value && typeof value === 'object') {
      deepFreeze(value);
    }
  }
  return Object.freeze(obj);
}

export function strNumSortAsc(a: string, b: string): number {
  return parseFloat(a) - parseFloat(b);
}

export function metricToImperialStr(metric: number, decimal = 2): string {
  return (metric * 10.7639104167097).toFixed(decimal);
}

export const objectToQueryString = (obj: Record<string, string>): string =>
  Object.entries(obj)
    .reduce((acc, [key, value]) => `${acc}&${key}=${value}`, '')
    .slice(1);

export function getTriangleHypotenuse(legA: number, legB: number): number {
  return Math.sqrt(legA ** 2 + legB ** 2);
}

export function createImageElement(imageBlob: Blob): Promise<HTMLImageElement | null> {
  return new Promise((resolve) => {
    const blobUrl = URL.createObjectURL(imageBlob);
    const image = new Image();

    image.onload = () => {
      URL.revokeObjectURL(blobUrl);
      resolve(image);
    };

    image.onerror = (error) => {
      console.error(error);
      resolve(null);
    };

    image.src = blobUrl;
  });
}

async function fetchImageWithRetry(
  src: string,
  abortController: AbortController | null,
  maxRetries = 3,
  retryDelay = 1000
) {
  async function tryFetchImage(retriesLeft: number): Promise<Response> {
    try {
      const options = abortController ? { signal: abortController.signal } : {};
      const response = await fetch(src, { credentials: 'include', ...options });

      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      }

      return response;
    } catch (error) {
      if (retriesLeft === 0 || (error instanceof DOMException && error.name === 'AbortError')) {
        throw error;
      } else {
        await new Promise((resolve) => setTimeout(resolve, retryDelay));
        return tryFetchImage(retriesLeft - 1);
      }
    }
  }

  return tryFetchImage(maxRetries);
}

export async function fetchSignedMeasureImgs(
  depthMapPath: string,
  normalMapPath: string,
  edgeMapPath: string,
  assetConfig: AssetConfig,
  abortController: AbortController | null
) {
  const signatureQuery = objectToQueryString(assetConfig.signatureData);

  const depthMapUrl =
    assetConfig.basePath + (signatureQuery.length > 0 ? `${depthMapPath}?${signatureQuery}` : depthMapPath);
  const normalMapUrl =
    assetConfig.basePath + (signatureQuery.length > 0 ? `${normalMapPath}?${signatureQuery}` : normalMapPath);
  const edgeMapUrl =
    assetConfig.basePath + (signatureQuery.length > 0 ? `${edgeMapPath}?${signatureQuery}` : edgeMapPath);

  const [depthMapRes, normalMapRes, edgeMapRes] = await Promise.all([
    fetchImageWithRetry(depthMapUrl, abortController),
    fetchImageWithRetry(normalMapUrl, abortController),
    fetchImageWithRetry(edgeMapUrl, abortController),
  ]);
  if (depthMapRes.ok && normalMapRes.ok && edgeMapRes.ok) {
    const depthBlob = await depthMapRes.clone().blob();
    const depthDecoded = decode(await depthMapRes.arrayBuffer());

    // turn the normal map jpeg response into an ImageData object
    const normalsBlob = await normalMapRes.clone().blob();
    const normalsDecoded = decode(await normalMapRes.arrayBuffer());

    const edgeDecoded = decode(await edgeMapRes.arrayBuffer());

    return { depthBlob, depthDecoded, normalsBlob, normalsDecoded, edgeDecoded };
  }
  console.error(`Request error: ${depthMapRes.status}`);

  return null;
}

export async function fetchImage(
  imagePath: string,
  abortController: AbortController | null,
  bitMapOptions?: ImageBitmapOptions
): Promise<Image | undefined> {
  try {
    const res = await fetchImageWithRetry(imagePath, abortController);

    if (res.ok) {
      const blob = await res.blob();

      // @ts-expect-error this can be undefined
      if (window.createImageBitmap) {
        try {
          return await createImageBitmap(blob, bitMapOptions);
        } catch (error) {
          console.log(error);
          return await createImageElement(blob);
        }
      }

      return await createImageElement(blob);
    }

    console.error(`Request error: ${res.status}`);

    return null;
  } catch (err) {
    const isAbortError = err instanceof DOMException && err.name === 'AbortError';

    if (!isAbortError) {
      console.error('Failed to load image', imagePath);
      console.error(err);

      // @ts-expect-error this can be undefined
      if (!window.VT_IMAGE_FETCH_FAIL_REPORTED) {
        // @ts-expect-error this can be undefined
        if (!window.VT_IMAGE_FETCH_FAIL_NUM) {
          // @ts-expect-error this can be undefined
          window.VT_IMAGE_FETCH_FAIL_NUM = 0;
        }

        // @ts-expect-error this can be undefined
        window.VT_IMAGE_FETCH_FAIL_NUM += 1;

        // @ts-expect-error this can be undefined
        if (window.VT_IMAGE_FETCH_FAIL_NUM > 100) {
          // @ts-expect-error this can be undefined
          window.VT_IMAGE_FETCH_FAIL_REPORTED = true; // report only if it happens a lot in one session
          ErrorLogger.captureMessage(`Image load failed: ${err}`);
        }
      }
    }

    // In some places we need to handle aborts differently, so this is a fast hack to differentiate between aborts and other errors
    // If image load was aborted, we return undefined, otherwise we return null
    // TODO: refactor this, we should throw an error and handle it in the callers properly
    if (isAbortError) return undefined;

    return null;
  }
}

export async function fetchSignedImage(
  imagePath: string,
  assetConfig: AssetConfig,
  abortController: AbortController | null,
  bitMapOptions?: ImageBitmapOptions
): Promise<Image | undefined> {
  const signatureQuery = objectToQueryString(assetConfig.signatureData);
  const signedImagePath = signatureQuery.length > 0 ? `${imagePath}?${signatureQuery}` : imagePath;
  return fetchImage(signedImagePath, abortController, bitMapOptions);
}

// Abort friendly
export async function fetchHtmlImage(
  imagePath: string,
  assetConfig: AssetConfig,
  abortController: AbortController | null
): Promise<HTMLImageElement | boolean | null> {
  try {
    const signatureQuery = objectToQueryString(assetConfig.signatureData);
    const signedImagePath = signatureQuery.length > 0 ? `${imagePath}?${signatureQuery}` : imagePath;
    const options = abortController ? { signal: abortController.signal } : {};
    const res = await fetch(signedImagePath, { credentials: 'include', ...options });

    if (res.ok) {
      const imageBlob = await res.blob();
      if (imageBlob.type === 'image/png' || imageBlob.type === 'image/jpeg') {
        const image = document.createElement('img');
        image.src = URL.createObjectURL(imageBlob);
        return image;
      }
      return false; // 404-ish: server didn't return image
    }
    return false; // 404
  } catch (err) {
    return null; // aborted
  }
}

export function closeImage(image: Image): void {
  if (!window.createImageBitmap) return;

  if (image && image instanceof ImageBitmap) {
    image.close();
  }
}

export function getBoundary(val: number, range: [number, number]): Boundary {
  const [min, max] = range;

  if (min >= max) return 'both';

  if (val <= min) return 'min';
  if (val >= max) return 'max';

  return 'none';
}

export function rotatePoint<TPos extends Pos<AnyScalar>>(
  pos: TPos,
  angleRad: number,
  origin: TPos = { x: 0, y: 0 } as TPos
): TPos {
  const sin = Math.sin(angleRad);
  const cos = Math.cos(angleRad);

  const translated = {
    x: pos.x - origin.x,
    y: pos.y - origin.y,
  };

  const rotated = {
    x: translated.x * cos + translated.y * sin,
    y: translated.x * -sin + translated.y * cos,
  };

  return {
    x: rotated.x + origin.x,
    y: rotated.y + origin.y,
  } as TPos;
}

export const throttle = (cb, ms) => {
  let lock = false;
  return (...args) => {
    if (lock) return;
    lock = true;
    setTimeout(() => {
      lock = false;
    }, ms);
    cb(...args);
  };
};

export const xyToPos = ([x, y]: [Pixel, Pixel]): Pos<Pixel> => ({ x, y });
// input: h as an angle in [0..360] and s,l in [0..1] - output: r,g,b in [0..1]
export function hslToRgb(h: number, s: number, l: number): number[] {
  if (__DEV__) {
    const a = s * Math.min(l, 1 - l);
    const f = (n, k = (n + h / 30) % 12) => l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return [f(0), f(8), f(4)];
  }
  return [];
}

// will return NaN if v1==v2
export function getYawAngleFromV1ToV2(v1 = [0, 0], v2 = [0, 0]): number {
  const vDir = [v2[0] - v1[0], v2[1] - v1[1]];
  const len = Math.sqrt(vDir[0] * vDir[0] + vDir[1] * vDir[1]);
  const angle = Math.asin(vDir[1] / len);
  return vDir[0] > 0 ? angle : Math.PI - angle; // something something quadrants
}

export function clamp(num, min = 0, max = 1) {
  return Math.min(Math.max(num, min), max);
}

export function delay(time: number): Promise<any> {
  return new Promise((resolve) => setTimeout(resolve, time));
}

// returns weird results whn yaw is around 180° or 0° if high pitch (above 45°)
export function getSinYaw(point: number[]): number {
  const yaw = (Math.asin(point[1]) + Math.PI / 2) * (point[0] > 0 ? 1 : -1);
  return yaw < 0 ? yaw + Math.PI * 2 : yaw;
}

// very sketchy math, please don't show this to a teacher 🥲
// this fixes yaw around extremes where high pitch causes some funkiness to occur
export function getCosYawFix(ogYaw: number, point: number[]): number {
  let yaw = 0;

  let diffToExtreme: number | undefined;

  // extremes are 0/360 and 180
  // this number goes from 39° to 65° (using the angles below)  (and max pitch, where this hacky calculations are needed the most)
  // (or from 0° to 65° with neutral pitch where this calculation is not really needed)
  // 39 is near the 360/0 and 180 degree zone where we need to use only the alt calculation
  // and 64 is away from the zone, where we need to use original calculation, as this is the end of alt calculation range
  const x180a = toRad(180 - 65);
  const x180b = toRad(180 + 65);
  const x360a = toRad(360 - 65);
  const x360b = toRad(65);

  // then use it as weight when averaging ogYaw and new one
  if (ogYaw > x180a && ogYaw < x180b) {
    // around 180°
    yaw = Math.acos(point[0]) + Math.PI / 2;
    diffToExtreme = toRad(Math.abs(ogYaw - Math.PI));
  }
  if (ogYaw > x360a || ogYaw < x360b) {
    // around 360°/0°
    yaw = Math.PI * 2 - (Math.acos(point[0]) - Math.PI / 2);
    yaw = yaw > Math.PI * 2 ? yaw - Math.PI * 2 : yaw;
    diffToExtreme = toRad(ogYaw > Math.PI ? Math.PI * 2 - ogYaw : ogYaw);
  }

  if (diffToExtreme !== undefined) {
    // near the extremes, need to mix in the alt result
    const lerpForce = linearScale(diffToExtreme, [38, 65], [1, 0]);
    return lerpForce * yaw + (1 - lerpForce) * ogYaw;
  }

  // alt yaw is not calculated, using original
  return ogYaw;
}

export function getYawPitchDeltas(
  facePoint: number[],
  cameraP: number[],
  cameraForward: number[]
): { yawDelta: number; pitchDelta: number; yawCamera: number; pitchCamera: number } {
  const up = [0, 0, 1];
  let point = getVec3Difference(facePoint, cameraP); //  point relative to the camera
  point = getVec3ScalarMultiply(point, 1 / getVec3Length(point));

  let dot = getVec3Dot(point, up);
  const pitch = Math.asin(dot);

  dot = getVec3Dot(cameraForward, up);
  const pitchCamera = Math.asin(dot);

  const pitchDelta = Math.abs(pitch - pitchCamera);

  const yaw = getCosYawFix(getSinYaw(point), point);
  const yawCamera = getCosYawFix(getSinYaw(cameraForward), cameraForward);

  let yawDelta = Math.abs(angleDelta(yaw, yawCamera));
  yawDelta = yawDelta > Math.PI ? Math.PI * 2 - yawDelta : yawDelta; // never go full PI

  return { yawDelta, pitchDelta, yawCamera, pitchCamera };
}

export function uncapitalize(s: string): string {
  return (s && s[0].toLowerCase() + s.slice(1)) || '';
}

function calculateMidPoint(a: Pos<Radian>, b: Pos<Radian>): Pos<Radian> {
  const lngDiff = b.x - a.x;
  const latA = a.y;
  const latB = b.y;
  const lngA = a.x;

  const bx = Math.cos(latB) * Math.cos(lngDiff);
  const by = Math.cos(latB) * Math.sin(lngDiff);

  const latMidway = Math.atan2(
    Math.sin(latA) + Math.sin(latB),
    Math.sqrt((Math.cos(latA) + bx) * (Math.cos(latA) + bx) + by * by)
  );
  const lngMidway = lngA + Math.atan2(by, Math.cos(latA) + bx);

  return { x: lngMidway, y: latMidway };
}

// input is in radians (like on a globe)
export function haversineDistance(a: Pos<Radian>, b: Pos<Radian>): number {
  const aLat = a.y;
  const bLat = b.y;
  const aLng = a.x;
  const bLng = b.x;

  function squared(x: number) {
    return x * x;
  }

  function hav(x: number) {
    return squared(Math.sin(x / 2));
  }

  const R = 1; // pano not Earth

  const ht = hav(bLat - aLat) + Math.cos(aLat) * Math.cos(bLat) * hav(bLng - aLng);
  return 2 * R * Math.asin(Math.sqrt(ht));
}

// to global coordinate system where lat (y) is -90  .. 90  deg
//                               and lon (x) is -180 .. 180 deg
// everything is in radians of course
export function denormalizePosRatioToSphere(p: Pos<Ratio>): Pos<Radian> {
  return {
    x: linearScale(p.x, [0, 1], [-Math.PI, Math.PI]),
    y: linearScale(p.y, [0, 1], [-Math.PI / 2, Math.PI / 2]),
  };
}

// 0..1 for equirect use
export function normalizePosRatio(p: Pos<Radian>): Pos<Ratio> {
  return {
    x: linearScale(p.x, [-Math.PI, Math.PI], [0, 1]),
    y: linearScale(p.y, [-Math.PI / 2, Math.PI / 2], [0, 1]),
  };
}

// @note -- points are normalized (in range [0..1]) on both axes, even though the canvas (and the globe) is 2:1
export function getBetweenPoints(p0: Pos<Ratio>, p1: Pos<Ratio>): Pos<Ratio>[] {
  const a = denormalizePosRatioToSphere(p0);
  const b = denormalizePosRatioToSphere(p1);

  const newPoints: Pos<Ratio>[] = [];
  newPoints.push(p0);

  const baseLen = 0.1;
  const lineLen = haversineDistance(a, b);

  if (lineLen > baseLen) {
    const c = calculateMidPoint(a, b);
    newPoints.push(normalizePosRatio(c));
  }

  return newPoints;
}

export function sphericalToCartesian(theta: number, phi: number, r: number): [number, number, number] {
  const phiAdjusted = phi + Math.PI / 2;
  const x = r * Math.sin(phiAdjusted) * Math.cos(theta);
  const y = -r * Math.cos(phiAdjusted);
  const z = r * Math.sin(phiAdjusted) * Math.sin(theta);
  return [x, y, z];
}

export function euclideanDistanceVec3(x1: number, y1: number, z1: number, x2: number, y2: number, z2: number) {
  return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2 + (z2 - z1) ** 2);
}

export function euclideanDistanceVec2(x1: number, y1: number, x2: number, y2: number) {
  return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
}

export function projectCartesianToScreen(
  x: number,
  y: number,
  z: number,
  yaw: number,
  pitch: number,
  projectionMatrix: number[]
) {
  const projected = multiplyM4AndPoint(projectionMatrix, [x, y, z, 1]);
  const cameraDirection = sphericalToCartesian(yaw, pitch, 1);
  const dot = getVec3Dot(cameraDirection, [x, y, z]);
  // If the point is behind the camera, it doesn't have a valid projection
  if (dot <= 0) return null;
  const screenX = projected[0] / projected[2];
  const screenY = projected[1] / projected[2];
  if (screenX < -1 || screenX > 1 || screenY < -1 || screenY > 1) return null;
  return { x: screenX, y: screenY };
}

/**
 * Projects the line between two points in 3D space to the screen
 * Returns the screen coordinates of the two points limited to the screen boundaries
 */
export function getCappedLineProjection(
  p1: PointData,
  p2: PointData,
  yaw: number,
  pitch: number,
  projectionMatrix: number[]
): {
  screen1X: number;
  screen1Y: number;
  screen2X: number;
  screen2Y: number;
} | null {
  const point1InBounds = p1.screenX >= -1 && p1.screenX <= 1 && p1.screenY >= -1 && p1.screenY <= 1;
  const point2InBounds = p2.screenX >= -1 && p2.screenX <= 1 && p2.screenY >= -1 && p2.screenY <= 1;

  if (point1InBounds && point2InBounds) {
    return {
      screen1X: p1.screenX,
      screen1Y: p1.screenY,
      screen2X: p2.screenX,
      screen2Y: p2.screenY,
    };
  }

  let screen1X = p1.screenX;
  let screen1Y = p1.screenY;
  let screen2X = p2.screenX;
  let screen2Y = p2.screenY;

  // At least one of the points is off screen
  // We will sample multiple points between the two measure points to find one that is on screen
  // Then get the line equation of the projected line and find out where it touches the screen
  const normalizedDirection = normalizeVec3([p2.cartX - p1.cartX, p2.cartY - p1.cartY, p2.cartZ - p1.cartZ]);
  const lineLength = euclideanDistanceVec3(p1.cartX, p1.cartY, p1.cartZ, p2.cartX, p2.cartY, p2.cartZ);

  // How many points between the two measure points to check for on screen
  // Needs to be at least 2
  const checkedSegments = 10;
  let onScreenPoint: {
    screenX: number;
    screenY: number;
    cartX: number;
    cartY: number;
    cartZ: number;
  } | null = null;

  for (let i = 0; i < checkedSegments; i += 1) {
    const segmentLength = lineLength / (checkedSegments - 1);
    const currentX = p1.cartX + normalizedDirection[0] * segmentLength * i;
    const currentY = p1.cartY + normalizedDirection[1] * segmentLength * i;
    const currentZ = p1.cartZ + normalizedDirection[2] * segmentLength * i;
    const currentPoint = projectCartesianToScreen(currentX, currentY, currentZ, yaw, pitch, projectionMatrix);
    if (currentPoint !== null) {
      onScreenPoint = {
        screenX: currentPoint.x,
        screenY: currentPoint.y,
        cartX: currentX,
        cartY: currentY,
        cartZ: currentZ,
      };
      break;
    }
  }

  if (!onScreenPoint) {
    // No on screen point was found between the two measure points
    return null;
  }

  const dummyLineLength = 0.1;
  const dummyBaseX = onScreenPoint.cartX;
  const dummyBaseY = onScreenPoint.cartY;
  const dummyBaseZ = onScreenPoint.cartZ;

  // If dummy point would be over the end of the line, reverse the direction
  let direction: number;
  if (normalizedDirection[0] > 0) {
    direction = dummyBaseX + normalizedDirection[0] * dummyLineLength > p2.cartX ? -1 : 1;
  } else {
    direction = dummyBaseX + normalizedDirection[0] * dummyLineLength < p2.cartX ? -1 : 1;
  }

  const dummyX = dummyBaseX + normalizedDirection[0] * direction * dummyLineLength;
  const dummyY = dummyBaseY + normalizedDirection[1] * direction * dummyLineLength;
  const dummyZ = dummyBaseZ + normalizedDirection[2] * direction * dummyLineLength;

  const projectedDummyPoint = projectCartesianToScreen(dummyX, dummyY, dummyZ, yaw, pitch, projectionMatrix);
  if (!projectedDummyPoint) {
    // The dummy point is off screen
    // this shouldn't really happen, but if it does, we can't display the label
    return null;
  }
  // Line equation
  // y = yIntercept + slope * x
  // x = (y - yIntercept) / slope
  const slope = (projectedDummyPoint.y - onScreenPoint.screenY) / (projectedDummyPoint.x - onScreenPoint.screenX);
  const yIntercept = onScreenPoint.screenY - slope * onScreenPoint.screenX;

  /** Y when x = -1 */
  const y1 = yIntercept + slope * -1;
  /** Y when x = 1 */
  const y2 = yIntercept + slope * 1;
  /** X when y = -1 */
  const x1 = (-1 - yIntercept) / slope;
  /** X when y = 1 */
  const x2 = (1 - yIntercept) / slope;

  // Find out which sides of the screen the line touches
  const touchesLeft = y1 >= -1 && y1 <= 1;
  const touchesRight = y2 >= -1 && y2 <= 1;
  const touchesTop = x2 >= -1 && x2 <= 1;
  const touchesBottom = x1 >= -1 && x1 <= 1;

  if ((!point1InBounds && point2InBounds) || (point1InBounds && !point2InBounds)) {
    // One of the points is off screen, but not both
    const p = point1InBounds ? p1 : p2;
    let screenX = 0;
    let screenY = 0;
    if (touchesLeft && p.screenX > projectedDummyPoint.x) {
      screenX = -1;
      screenY = y1;
    } else if (touchesRight && p.screenX < projectedDummyPoint.x) {
      screenX = 1;
      screenY = y2;
    } else if (touchesTop && p.screenY < projectedDummyPoint.y) {
      screenX = x2;
      screenY = 1;
    } else if (touchesBottom && p.screenY > projectedDummyPoint.y) {
      screenX = x1;
      screenY = -1;
    } else {
      console.warn('Unhandled positioning case');
    }
    if (!point1InBounds) {
      screen1X = screenX;
      screen1Y = screenY;
    } else {
      screen2X = screenX;
      screen2Y = screenY;
    }
  } else {
    // Both points are off screen
    if (!touchesLeft && !touchesRight && !touchesTop && !touchesBottom) {
      // The line doesn't touch any of the sides
      // This means the whole line is off screen
      return null;
    }
    let xStart = 0;
    let yStart = 0;
    let xEnd = 0;
    let yEnd = 0;
    if (touchesLeft && touchesTop) {
      // left
      xStart = -1;
      yStart = y1;
      // top
      xEnd = x2;
      yEnd = 1;
    } else if (touchesLeft && touchesRight) {
      // left
      xStart = -1;
      yStart = y1;
      // right
      xEnd = 1;
      yEnd = y2;
    } else if (touchesLeft && touchesBottom) {
      // left
      xStart = -1;
      yStart = y1;
      // bottom
      xEnd = x1;
      yEnd = -1;
    } else if (touchesTop && touchesRight) {
      // top
      xStart = x2;
      yStart = 1;
      // right
      xEnd = 1;
      yEnd = y2;
    } else if (touchesTop && touchesBottom) {
      // top
      xStart = x2;
      yStart = 1;
      // bottom
      xEnd = x1;
      yEnd = -1;
    } else if (touchesRight && touchesBottom) {
      // right
      xStart = 1;
      yStart = y2;
      // bottom
      xEnd = x1;
      yEnd = -1;
    } else {
      console.warn('Unhandled positioning case');
    }
    screen1X = xStart;
    screen1Y = yStart;
    screen2X = xEnd;
    screen2Y = yEnd;
  }

  return {
    screen1X,
    screen1Y,
    screen2X,
    screen2Y,
  };
}

export function mmToMetricString(mm: number) {
  return `${(mm / 1000).toFixed(2)} m`;
}

export function mmToImperialString(mm: number) {
  const inches = mm / 25.4;
  return `${inches.toFixed(1)} in`;
}

export default {
  roundFloat,
  toRad,
  toDeg,
  polarToRect,
  boundToRange,
  getViewAngle,
  toClipSpace,
  getRenderedFov,
  canvasToCamera,
  getAverageValue,
  getMaxValue,
  getVec2,
  getVec2Length,
  getVec2Normalized,
  angleDelta,
  lineSegmentTriangleIntersection,
  getCameraWallCrossPointGeneric,
  getCameraWallCrossPoint,
  deepFreeze,
  strNumSortAsc,
  metricToImperialStr,
  getTriangleHypotenuse,
  createImageElement,
  fetchSignedMeasureImgs,
  fetchImage,
  fetchHtmlImage,
  fetchSignedImage,
  closeImage,
  getBoundary,
  rotatePoint,
  xyToPos,
  hslToRgb,
  getYawAngleFromV1ToV2,
  clamp,
  delay,
  getVec4Sum,
  getYawPitchDeltas,
  getSinYaw,
  getCosYawFix,
  objectToQueryString,
  uncapitalize,
  getBetweenPoints,
  sphericalToCartesian,
  euclideanDistanceVec2,
  euclideanDistanceVec3,
  projectCartesianToScreen,
  getCappedLineProjection,
  mmToImperialString,
  mmToMetricString,
};
