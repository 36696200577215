import { useEffect, useState } from "react";

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>; // Return success

const useCopyToClipboard = () => {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);
  const [isClipboardSupported, setIsClipboardSupported] = useState<boolean>(
    true
  );

  const copy: CopyFn = async (text) => {
    if (!isClipboardSupported) {
      console.warn("Clipboard not supported");
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      console.warn("Copy failed", error);
      setCopiedText(null);
      return false;
    }
  };

  useEffect(() => {
    if (!navigator?.clipboard) {
      console.warn("Clipboard not supported");
      setIsClipboardSupported(false);
    } else {
      setIsClipboardSupported(true);
    }
  }, []);

  return { copiedText, isClipboardSupported, copy };
};

export default useCopyToClipboard;
