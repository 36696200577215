import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import throttle from 'lodash/throttle';
import { useEffect, useRef, useState } from 'react';
import Spinner from '../../common/Spinner';
import useGlobalContext from '../../contexts/useGlobalContext';
import { useTranslation } from '../../contexts/useLocalizationContext';
import useServices from '../../contexts/useServices';
import useReactive from '../../hooks/useReactive';
import CanvasMiniMapRenderer from './CanvasMiniMapRenderer';
import GuidedViewingManager from './GuidedViewingManager';
import useTourEventSource from './hooks/useEventSource';
import getFloorPropertiesFromGeometry from './utils/getFloorPropertiesFromGeometry';
import { getFloorPropertiesFromTour } from './utils/misc';
const CanvasMiniMap = ({ parentContainer }) => {
    const canvasRef = useRef(null);
    const rendererRef = useRef();
    const eventDivRef = useRef(null);
    const guidedViewingManagerRef = useRef();
    const { engine, assetConfig, analytics } = useGlobalContext();
    const t = useTranslation();
    const { gatedTourService, navigationService, tourConfigService } = useServices();
    const { structure, levelKey, buildingKey, imageUrl, imageType, tourConfigStructureType, activeScene } = useReactive(navigationService, ['structure', 'levelKey', 'buildingKey', 'imageUrl', 'imageType', 'tourConfigStructureType', 'activeScene']);
    const { accent, features, tourConfig } = useReactive(tourConfigService, ['accent', 'features', 'tourConfig']);
    const tourEventSource = useTourEventSource(engine);
    const [loading, setLoading] = useState(true);
    const getNextActivePinLabel = (toSceneKey) => {
        const toScene = tourConfigService.tourConfig.scenes[toSceneKey];
        const units = tourConfigService.unitsShort;
        const unitsTranslated = t(`units.${units}`);
        if (!toScene)
            return { roomLabel: '', areaUnit: unitsTranslated };
        const name = toScene.name;
        const showRoomArea = tourConfigService.showRoomArea;
        const area = showRoomArea ? toScene.area : undefined;
        if (!area)
            return { roomLabel: `${name}`, areaUnit: unitsTranslated };
        const areaConverted = units === 'ft' ? area * 10.7639104167097 : area;
        // @note -- superscript ² will be added to the end of this text in svg renderer
        return {
            roomLabel: name,
            areaUnit: unitsTranslated,
            fullArea: areaConverted,
            livingAreaPerc: toScene.livingAreaPercentage,
        };
    };
    const handleSceneHover = (sceneKey) => {
        if (!engine)
            return;
        const controller = engine.getController();
        if (!controller)
            return;
        controller.handleSceneHotSpotHover(sceneKey);
        engine.emitSceneHoverEvent(sceneKey);
    };
    const createRenderer = (canvas) => {
        const ctx = canvas.getContext('2d');
        if (ctx === null)
            throw new Error('Canvas context is null');
        return new CanvasMiniMapRenderer({
            engine,
            assetPath: assetConfig.assetPath,
            canvas,
            ctx,
            analytics,
            navigationService,
            gatedTourService,
            getNextActivePinLabel,
            handleSceneHover,
            onFloorPlanImageLoaded: navigationService.setImageLoaded,
            onFloorPlanImageError: navigationService.setImageError,
            setLoading,
        });
    };
    const updateMinimapPins = () => {
        var _a;
        const pins = navigationService.getPins(buildingKey, levelKey);
        if (activeScene)
            (_a = rendererRef.current) === null || _a === void 0 ? void 0 : _a.updatePins(pins, activeScene.sceneKey);
    };
    const setFloorPlan = () => {
        var _a;
        const floorPlanSize = structure === null || structure === void 0 ? void 0 : structure.size;
        const pins = navigationService.getPins(buildingKey, levelKey);
        // @note -- if floorplan has no scenes this is the last scene visited (currently active scene)
        const startingSceneKey = engine.getActiveSceneConfig().sceneKey;
        const accentColor = features.clientBranding
            ? accent || tourConfigService.defaultAccent
            : tourConfigService.defaultAccent;
        let floorProperties;
        if (pins.length > 0) {
            floorProperties = getFloorPropertiesFromGeometry(structure, startingSceneKey);
        }
        else {
            // if no pins, that means there are no scenes in the building/floor, can't use geometry to calculate floor size
            floorProperties = getFloorPropertiesFromTour(structure, buildingKey);
        }
        const floorCenter = floorProperties.center;
        const floorSize = floorProperties.size;
        if (!floorCenter)
            throw new Error('floorCenter is undefined');
        if (!floorSize)
            throw new Error('floorSize is undefined');
        if (!floorPlanSize)
            throw new Error('floorPlanSize is undefined');
        (_a = rendererRef.current) === null || _a === void 0 ? void 0 : _a.setFloorPlan({
            floorPlanSize,
            floorCenter,
            floorSize,
            pins,
            startingSceneKey,
            accentColor,
        });
    };
    useEffect(() => {
        if (!canvasRef.current)
            return;
        rendererRef.current = createRenderer(canvasRef.current);
        guidedViewingManagerRef.current = new GuidedViewingManager(engine, rendererRef.current);
        const render = () => {
            var _a;
            (_a = rendererRef.current) === null || _a === void 0 ? void 0 : _a.render();
            requestAnimationFrame(render);
        };
        render();
        // eslint-disable-next-line consistent-return
        return () => {
            var _a;
            (_a = guidedViewingManagerRef.current) === null || _a === void 0 ? void 0 : _a.destroy();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        var _a;
        setFloorPlan();
        if (tourConfigStructureType === 'layers' && imageUrl) {
            // tour.json v2 sometimes has 2 floors in 1 image.
            // And setFloorPlanImage is not called, if transitions between buildings happen in the same picture,
            // Because nothing has changed, but renderer has discarded old image.
            // This sneakily sets same image for transitions where it is not changed.
            // If new image is given, then this will not break anything
            (_a = rendererRef.current) === null || _a === void 0 ? void 0 : _a.setFloorPlanImage(imageUrl, imageType);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [structure, levelKey, buildingKey]);
    useEffect(() => {
        updateMinimapPins();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tourConfig.sceneGroups]);
    // on accent color change regenerate some pin images
    useEffect(() => {
        var _a;
        if (!rendererRef.current)
            return;
        const accentColor = features.clientBranding
            ? accent || tourConfigService.defaultAccent
            : tourConfigService.defaultAccent;
        const currentAccentColor = rendererRef.current.getCurrentAccentColor();
        if (currentAccentColor !== accentColor) {
            (_a = rendererRef.current) === null || _a === void 0 ? void 0 : _a.forceRegenerateAccentedPinImages(accentColor);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accent, features]);
    useEffect(() => {
        var _a;
        if (imageUrl) {
            (_a = rendererRef.current) === null || _a === void 0 ? void 0 : _a.setFloorPlanImage(imageUrl, imageType);
        }
    }, [imageUrl, imageType]);
    useEffect(() => {
        const hotSpotHoverSub = engine.subscribe('hotspots.scene.hover', (sceneKey) => {
            var _a;
            (_a = rendererRef.current) === null || _a === void 0 ? void 0 : _a.onPinHoverChangedInScene(sceneKey);
        });
        const sceneTransitionStartSub = engine.subscribe('scene.transition.start', (fromScene, toScene) => {
            var _a;
            (_a = rendererRef.current) === null || _a === void 0 ? void 0 : _a.onSceneTransitionStart(fromScene, toScene);
        });
        const sceneTransitionUpdateSub = engine.subscribe('scene.transition.update', (progress) => {
            var _a;
            (_a = rendererRef.current) === null || _a === void 0 ? void 0 : _a.onSceneTransitionUpdate(progress);
        });
        const sceneTransitionEndSub = engine.subscribe('scene.transition.end', (toScene) => {
            var _a;
            (_a = rendererRef.current) === null || _a === void 0 ? void 0 : _a.onSceneTransitionEnd(toScene);
        });
        const branding1ChangeSub = engine.subscribe('branding.showRoomArea.change', () => {
            var _a;
            (_a = rendererRef.current) === null || _a === void 0 ? void 0 : _a.forceRegenerateActivePinLabel();
        });
        const branding2ChangeSub = engine.subscribe('branding.unit.change', () => {
            var _a;
            (_a = rendererRef.current) === null || _a === void 0 ? void 0 : _a.forceRegenerateActivePinLabel();
        });
        const forcedRerenderPinsSub = engine.subscribe('minimap.rerender.force', () => {
            var _a;
            (_a = rendererRef.current) === null || _a === void 0 ? void 0 : _a.forceRegenerateActivePinLabel();
        });
        return () => {
            hotSpotHoverSub.unsubscribe();
            sceneTransitionStartSub.unsubscribe();
            sceneTransitionUpdateSub.unsubscribe();
            sceneTransitionEndSub.unsubscribe();
            branding1ChangeSub.unsubscribe();
            branding2ChangeSub.unsubscribe();
            forcedRerenderPinsSub.unsubscribe();
        };
    }, [engine]);
    const doResize = () => {
        var _a, _b;
        (_a = rendererRef.current) === null || _a === void 0 ? void 0 : _a.onResize();
        (_b = rendererRef.current) === null || _b === void 0 ? void 0 : _b.forceRender();
    };
    useEffect(() => {
        // canvas parent that gets explicitly resized
        if (!parentContainer)
            return;
        const throttledResize = throttle(() => {
            doResize();
        }, 250);
        const observer = new MutationObserver(() => {
            throttledResize();
        });
        // if minimap parent element is resized
        observer.observe(parentContainer, { attributes: true, attributeFilter: ['style'] });
        // if window is resized
        window.addEventListener('resize', throttledResize);
        // eslint-disable-next-line consistent-return
        return () => {
            observer.disconnect();
            window.removeEventListener('resize', throttledResize);
        };
    }, [parentContainer]);
    useEffect(() => {
        var _a;
        (_a = rendererRef.current) === null || _a === void 0 ? void 0 : _a.forceRegenerateActivePinLabel();
    }, [activeScene === null || activeScene === void 0 ? void 0 : activeScene.name]);
    // onWheel is in this complex useEffect instead of  just an attribute to the DIV,
    // is because we need to set passive: false in order to preventDefault
    useEffect(() => {
        const divElement = eventDivRef.current;
        const handleWheel = (e) => {
            var _a;
            e.preventDefault();
            (_a = rendererRef.current) === null || _a === void 0 ? void 0 : _a.onWheel(e);
        };
        if (divElement) {
            divElement.addEventListener('wheel', handleWheel, { passive: false });
        }
        return () => {
            if (divElement) {
                divElement.removeEventListener('wheel', handleWheel);
            }
        };
    }, []);
    const isEventsDisabled = tourEventSource === 'RemoteHost';
    return (_jsx("div", { className: "new-minimap-canvas bg-theme-primary bg-opacity-theme-low font-primary text-theme-primary h-full w-full", style: {
            // Safari fix. Canvas, though rounded off by parent circle, still has invisible square corners, that block events
            clipPath: 'circle(50%)',
        }, children: _jsxs("div", { ref: eventDivRef, className: `rotate-yaw-reverse relative flex h-full w-full touch-none items-center justify-center
          overflow-hidden rounded-full outline-none ${isEventsDisabled ? 'pointer-events-none' : 'pointer-events-auto'}`, onMouseDown: (e) => { var _a; return (_a = rendererRef.current) === null || _a === void 0 ? void 0 : _a.onMouseDown(e); }, onMouseUp: () => { var _a; return (_a = rendererRef.current) === null || _a === void 0 ? void 0 : _a.onMouseUp(); }, onMouseLeave: () => { var _a; return (_a = rendererRef.current) === null || _a === void 0 ? void 0 : _a.onMouseLeave(); }, onMouseMove: (e) => { var _a; return (_a = rendererRef.current) === null || _a === void 0 ? void 0 : _a.onMouseMove(e); }, onTouchStart: (e) => { var _a; return (_a = rendererRef.current) === null || _a === void 0 ? void 0 : _a.onTouchStart(e); }, onTouchEnd: () => { var _a; return (_a = rendererRef.current) === null || _a === void 0 ? void 0 : _a.onTouchEnd(); }, onTouchMove: (e) => { var _a; return (_a = rendererRef.current) === null || _a === void 0 ? void 0 : _a.onTouchMove(e); }, role: "button", tabIndex: 0, children: [_jsx("canvas", { ref: canvasRef, width: 375, height: 375, style: { cursor: 'grab' } }), loading && (_jsx("div", { className: "delayed-show absolute h-12 w-12", children: _jsx(Spinner, {}) }))] }) }));
};
export default CanvasMiniMap;
