import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useRef } from 'react';
import useServices from '../../contexts/useServices';
import useReactive from '../../hooks/useReactive';
import PanoSwitcher from '../altPano/PanoSwitcher';
import MiniMapCircle from './components/MiniMapCircle';
import MiniMapMenu from './components/MiniMapMenu';
import UtilsMenu from './components/UtilsMenu';
import LayoutSlot from './containers/LayoutSlot';
import useIdealistaLayout from './hooks/useIdealistaLayout';
import isToolbarVisible from './utils/isToolbarVisible';
const IdealistaLayout = ({ enabledNavigation, enabledUtils, isSentryEnabled }) => {
    const { tourConfigService } = useServices();
    const { navigationMode } = useReactive(tourConfigService, ['navigationMode']);
    const miniMapMenuRef = useRef(null);
    const utilsMenuRef = useRef(null);
    // Info button/modal is disabled by default in this layout
    const idealistaEnabledUtils = useMemo(() => (Object.assign(Object.assign({}, enabledUtils), { info: false })), [enabledUtils]);
    const { hideNav, hideUtils } = useIdealistaLayout(enabledNavigation, idealistaEnabledUtils, miniMapMenuRef, utilsMenuRef);
    const showMiniMapMenu = enabledNavigation.miniMap || isToolbarVisible(enabledNavigation);
    return (_jsxs(_Fragment, { children: [_jsx(LayoutSlot, { className: "smvh:space-y-6 space-y-4", position: "bottom-left", children: !hideNav && (_jsxs(_Fragment, { children: [_jsx(MiniMapCircle, { isSentryEnabled: isSentryEnabled, enabledNavigation: enabledNavigation }), showMiniMapMenu && (_jsx(MiniMapMenu, { ref: miniMapMenuRef, enabledNavigation: enabledNavigation, listMode: navigationMode === 'list' }))] })) }), enabledUtils.altPano && (_jsx(LayoutSlot, { position: "top-middle", children: _jsx("div", { className: "flex justify-center", children: _jsx(PanoSwitcher, {}) }) })), !hideUtils && (_jsx(LayoutSlot, { position: "bottom-right", children: _jsx(UtilsMenu, { ref: utilsMenuRef, enabledUtils: idealistaEnabledUtils, measureTooltipSide: "top" }) }))] }));
};
export default IdealistaLayout;
