import { SVGProps } from "react";

const ThreeDotsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={70}
    height={20}
    xmlns="http://www.w3.org/2000/svg"
    fill="#fff"
    {...props}
  >
    <circle cx={7} cy={8} r={15}>
      <animate
        attributeName="r"
        from={15}
        to={15}
        begin="0s"
        dur="1.2s"
        values="6;3;6"
        calcMode="linear"
        repeatCount="indefinite"
      />
      <animate
        attributeName="fill-opacity"
        from={1}
        to={1}
        begin="0s"
        dur="1.2s"
        values="1;.5;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx={36} cy={8} r={9} fillOpacity={0.3}>
      <animate
        attributeName="r"
        from={9}
        to={9}
        begin="0s"
        dur="1.2s"
        values="4;8;4"
        calcMode="linear"
        repeatCount="indefinite"
      />
      <animate
        attributeName="fill-opacity"
        from={0.5}
        to={0.5}
        begin="0s"
        dur="0.8s"
        values=".5;1;.5"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx={63} cy={8} r={15}>
      <animate
        attributeName="r"
        from={15}
        to={15}
        begin="0s"
        dur="0.9s"
        values="6;3;6"
        calcMode="linear"
        repeatCount="indefinite"
      />
      <animate
        attributeName="fill-opacity"
        from={1}
        to={1}
        begin="0s"
        dur="0.8s"
        values="1;.5;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
);

export default ThreeDotsIcon;
