import { ViewingInfoResponseType } from "types/api.response.types";
import config from "config";

const getViewingInfo = async (
  id: string
): Promise<{
  isHost: boolean;
  error?: string | undefined;
}> => {
  const res = await fetch(`${config.baseUrl}/gapi/guided-viewing/${id}/`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const data: ViewingInfoResponseType = await res.json();

  if (res.ok) {
    return { isHost: data.is_host ?? false };
  }

  return {
    isHost: false,
    error: data.detail,
  };
};

export default getViewingInfo;
