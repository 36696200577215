import type { Pixel, Pos } from '@g360/vt-types';

import { MODE_SWITCH_CLICK_THRESHOLD } from '../common/Globals';
import { pixelToCamera, toRad } from '../common/Utils';
import Utils from '../common/Utils/Utils';
import GestureController from './GestureController';
import type { ExtDragEvent } from './GestureController/types';

export default class EditorController extends GestureController {
  // distance from edges that we should start spinning the scene
  private static readonly EDGE_DISTANCE = 25;
  private isSpinning = false;
  private currentPos: Pos<Pixel> = { x: 0, y: 0 };

  protected onDrag(current: ExtDragEvent) {
    this.currentPos = Utils.xyToPos(current.xy);
    const isHotSpotSelected = this.selectedHotSpot.index > -1;
    switch (current.type) {
      case 'start': {
        const hotSpotIdx = this.getHotSpotOnPosition(this.currentPos);
        this.setSelectedHotSpot(hotSpotIdx);
        break;
      }
      case 'drag': {
        const hotSpot = isHotSpotSelected ? this.hotSpots[this.selectedHotSpot.index] : null;
        if (!hotSpot || hotSpot.type !== 'hotspot-info') {
          this.moveCamera(this.getFinalScenePos(current.deltaPos));
          return;
        }

        const spin = this.getSpinDirection(this.currentPos);

        if (!spin) {
          this.stopSpinning();
        } else if (!this.isSpinning) {
          this.spinCamera(spin, () => {
            this.moveSelectedHotSpot(this.currentPos);
          });
        }

        this.moveSelectedHotSpot(this.currentPos);

        break;
      }
      case 'end': {
        this.stopSpinning();

        const initialPos = Utils.xyToPos(current.initial);
        const distance = Math.sqrt((this.currentPos.x - initialPos.x) ** 2 + (this.currentPos.y - initialPos.y) ** 2);

        if (!isHotSpotSelected) {
          // Normal click on canvas and not on hotspot - basic drag canvas event
          this.moveCameraToTargetPos(current);
          this.emptyClickCallback();
          return;
        }

        if (distance < MODE_SWITCH_CLICK_THRESHOLD) {
          // Basic click on hotspot - triggered on click, but not on hotspot dragging unless drag too small
          this.clickSelectedHotSpot();
          return;
        }

        if (isHotSpotSelected) {
          // Hotspot dragging end
          this.hotSpotMoveEnd(this.selectedHotSpot.index);
        }

        break;
      }
      default:
        console.warn(`type is not supported`);
    }
  }

  private getSpinDirection(pos: Pos<Pixel>): 'left' | 'right' | 'up' | 'down' | null {
    if (pos.x < EditorController.EDGE_DISTANCE) {
      return 'left';
    }
    if (pos.x > this.boundingRect.width - EditorController.EDGE_DISTANCE) {
      return 'right';
    }
    if (pos.y < EditorController.EDGE_DISTANCE) {
      return 'up';
    }
    if (pos.y > this.boundingRect.height - EditorController.EDGE_DISTANCE) {
      return 'down';
    }
    return null;
  }

  private async spinCamera(direction: 'up' | 'down' | 'left' | 'right', spinning?: () => void) {
    const params: Record<typeof direction, ['pitch' | 'yaw', -1 | 1, number]> = {
      up: ['pitch', 1, 1000],
      down: ['pitch', -1, 1000],
      left: ['yaw', -1, 7000],
      right: ['yaw', 1, 7000],
    };
    const [param, multiple, duration] = params[direction];

    const patch = { [param]: this[param] + multiple * toRad(360) };

    this.isSpinning = true;
    await this.changeCamera({ ...this.getEngineState(), ...patch, duration, animate: true }, spinning);
    this.isSpinning = false;
  }

  private stopSpinning() {
    if (this.isSpinning) {
      this.moveCamera({});
    }
  }

  private moveSelectedHotSpot(point: Pos<Pixel>) {
    const { pitch, yaw, fov, yawOffset } = this.getEngineState();
    const cameraPos = {
      pitch,
      yaw: yaw + yawOffset,
      fov,
    };
    const updatedPos = pixelToCamera(point, this.boundingRect, cameraPos);
    this.moveHotSpot(this.selectedHotSpot.index, updatedPos);
  }
}
