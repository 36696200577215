function withOpacity(variableName) {
    return ({ opacityValue }) => {
        if (opacityValue) {
            return `rgba(var(${variableName}), ${opacityValue})`;
        }
        return `rgb(var(${variableName}))`;
    };
}
// Just a simple way to generate z-index values and change the order without
// changing the values manually every time, this still doesn't guarantee proper
// ordering if elements using these values are not layered properly
const topZIndex = 1000;
const zIndexNames = [
    'scene-loading',
    'toast-msg',
    'gated-tour-modal',
    'measure-disclaimer-modal',
    'cookie-modal',
    'info-modal',
    'cookie-popup',
    'hs-image-preview',
    'layout-slot',
    'tutorial-anim',
];
const zIndex = zIndexNames.reduce((acc_, name, index) => {
    acc_[name] = `${topZIndex - index}`;
    return acc_;
}, {});
export const tailwindConfig = {
    future: {
        hoverOnlyWhenSupported: true,
    },
    theme: {
        fontFamily: {
            primary: ['Gilroy', 'Roboto', 'Open Sans', 'Arial', 'Helvetica', 'sans-serif'],
            secondary: ['Open Sans', 'Roboto', 'Arial', 'Helvetica', 'sans-serif'],
        },
        extend: {
            screens: {
                xxs: { raw: '(max-height: 320px)' },
                xs: { raw: '(min-width: 320px)' },
                xsvh: { raw: '(min-width: 320px) and (min-height: 320px)' },
                smvh: { raw: '(min-width: 640px) and (min-height: 640px)' },
            },
            colors: {
                modal: {
                    accent: '#FFC600',
                    'accent-dark': '#F5B100',
                    'accent-light': '#FFE899',
                    dark: '#1A222E',
                    gray: '#F3F3F3',
                    'gray-dark': '#485363',
                    'gray-light': '#D7DADD',
                    'gray-label': '#A0A6AE',
                    'gray-hover': '#E7E8E9',
                    red: '#ED4040',
                    light: '#FFFFFF',
                },
            },
            borderRadius: {
                DEFAULT: '6px',
                small: '5px',
                normal: '6px',
            },
            borderWidth: {
                DEFAULT: '1px',
            },
            textColor: {
                theme: {
                    primary: 'rgb(var(--color-text-primary))',
                    secondary: 'rgb(var(--color-text-secondary))',
                    accent: 'rgb(var(--vt-color-accent))',
                    contrast: 'rgb(var(--color-text-contrast))',
                },
            },
            backgroundColor: {
                theme: {
                    primary: withOpacity('--color-bg-primary'),
                    secondary: withOpacity('--color-bg-secondary'),
                    tertiary: withOpacity('--color-bg-tertiary'),
                    accent: withOpacity('--vt-color-accent'),
                },
            },
            borderColor: {
                theme: {
                    accent: withOpacity('--vt-color-accent'),
                },
            },
            backgroundOpacity: {
                'theme-low': 'var(--opacity-low)',
                'theme-medium': 'var(--opacity-medium)',
                'theme-normal': 'var(--opacity-normal)',
                'theme-text-bg': 'var(--opacity-text-bg)',
            },
            stroke: {
                theme: {
                    primary: withOpacity('--color-text-primary'),
                    secondary: withOpacity('--color-text-secondary'),
                    accent: withOpacity('--vt-color-accent'),
                    'pin-accent': withOpacity('--vt-color-pin-accent'),
                    spinner: withOpacity('--color-bg-primary'),
                },
            },
            fill: {
                theme: {
                    primary: withOpacity('--color-text-primary'),
                    secondary: withOpacity('--color-text-secondary'),
                    accent: withOpacity('--vt-color-accent'),
                    'pin-accent': withOpacity('--vt-color-pin-accent'),
                },
            },
            boxShadow: {
                medium: '-2px 2px 3px 0px rgba(0, 0, 0, 0.3)',
            },
            borderSize: {
                1: '1px',
            },
            transitionProperty: {
                size: 'width, height',
            },
            zIndex,
            keyframes: {
                'fade-in': {
                    '0%': { opacity: '0' },
                    '100%': { opacity: '1' },
                },
                'canvas-swing': {
                    '0%': { transform: 'scale(1.5) translate(0%)' },
                    '25%': { transform: 'scale(1.5) translate(5%)' },
                    '50%': { transform: 'scale(1.5) translate(0%)' },
                    '75%': { transform: 'scale(1.5) translate(-5%)' },
                    '100%': { transform: 'scale(1.5) translate(0%)' },
                },
                'notification-ping': {
                    '0%': {
                        transform: 'scale(1)',
                        opacity: '1',
                        backgroundColor: 'rgba(var(--vt-color-accent), 1)',
                    },
                    '30%': {
                        transform: 'scale(1.8)',
                        opacity: '1',
                        backgroundColor: 'rgba(var(--vt-color-accent), 0)',
                    },
                    '60%': {
                        opacity: '0',
                        transform: 'scale(1.8)',
                        backgroundColor: 'rgba(var(--vt-color-accent), 0)',
                    },
                    '100%': {
                        opacity: '0',
                        transform: 'scale(1.8)',
                        backgroundColor: 'rgba(var(--vt-color-accent), 0)',
                    },
                },
            },
            animation: {
                'fade-in': 'fade-in .4s ease-out .2s forwards',
                'canvas-swing': 'canvas-swing 10s linear infinite',
                'notification-ping': 'notification-ping 2s infinite ease-out',
            },
        },
    },
    variants: {
        extend: {},
    },
    plugins: [
        // eslint-disable-next-line global-require
        require('@tailwindcss/container-queries'),
        ({ addUtilities }) => {
            const newUtilities = {
                '.no-appearance': {
                    appearance: 'none',
                    '-webkit-appearance': 'none',
                    '-moz-appearance': 'none',
                },
                '.text-shadow-dark': {
                    'text-shadow': '0px 2px 3px rgba(26, 34, 46, 0.35)',
                },
                '.text-shadow-none': {
                    'text-shadow': 'none',
                },
            };
            addUtilities(newUtilities);
        },
    ],
};
export default tailwindConfig;
