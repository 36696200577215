import { FC } from "react";

interface CheckboxProps {
  name: string;
  isChecked: boolean;
  handleClick: () => void;
}

const Checkbox: FC<CheckboxProps> = ({ name, isChecked, handleClick }) => {
  return (
    <input
      name={name}
      type="checkbox"
      checked={isChecked}
      onChange={handleClick}
      className="accent-black mr-2.5 w-5 h-5 rounded-md focus:ring-giraffe-red text-black border-gray-300"
    />
  );
};

export default Checkbox;
