import { VideoRoomMonitor } from "@twilio/video-room-monitor";
import { isMobile } from "func/isMobile";
import { useAppStateContext } from "hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  connect,
  ConnectOptions,
  LocalTrack,
  LocalParticipant,
  Room,
  isSupported,
  // Logger
} from "twilio-video";

// Set log-level for twilio-video, by default it is set to 'silent'
// This setting when set is saved on browsers 'Local storage' and will be set from there if not set explicitly in code
// Logger.getLogger('twilio-video').setLevel('info');

export default function useRoom(
  localTracks: LocalTrack[],
  options: ConnectOptions
) {
  const { roomName, token, debug } = useAppStateContext();

  const [room, setRoom] = useState<Room | null>(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const [localParticipant, setLocalParticipant] =
    useState<LocalParticipant | null>(null);

  const optionsRef = useRef(options);

  useEffect(() => {
    // This allows the connect function to always access the most recent version of the options object. This allows us to
    // reliably use the connect function at any time.
    optionsRef.current = options;
  }, [options]);

  const resetRoom = useCallback(() => {
    setRoom((prevRoom) => {
      if (prevRoom) {
        prevRoom.localParticipant.dataTracks.forEach((track) => {
          track.unpublish();
        });

        prevRoom.localParticipant.audioTracks.forEach((track) => {
          track.unpublish();
        });

        prevRoom.localParticipant.videoTracks.forEach((track) => {
          track.unpublish();
        });

        prevRoom.disconnect();
      }
      return null;
    });
  }, []);

  const twilioConnect = useCallback(() => {
    if (!isSupported) {
      console.error("This browser is not supported");
      window.location.replace(
        `/${roomName}/error/?error=${1}
        )}`
      );
      return new Promise<void>((resolve) => {
        resolve();
      });
    }

    setIsConnecting(true);
    return connect(token as string, {
      ...optionsRef.current,
      tracks: localTracks,
    }).then(
      (newRoom) => {
        setRoom(newRoom);
        if (debug) {
          VideoRoomMonitor.registerVideoRoom(newRoom);
          VideoRoomMonitor.openMonitor();
        }
        const disconnect = () => newRoom.disconnect();

        // This app can add up to 16 'participantDisconnected' listeners to the room object, which can trigger
        // a warning from the EventEmitter object. Here we increase the max listeners to suppress the warning.
        newRoom.setMaxListeners(16);

        newRoom.once("disconnected", () => {
          // Reset the room only after all other `disconnected` listeners have been called.
          setTimeout(() => setRoom(null));
          window.removeEventListener("beforeunload", disconnect);

          if (isMobile()) {
            window.removeEventListener("pagehide", disconnect);
          }
        });

        // @ts-ignore
        window.twilioRoom = newRoom;

        setLocalParticipant(newRoom.localParticipant);
        setIsConnecting(false);

        // Add a listener to disconnect from the room when a user closes their browser
        window.addEventListener("beforeunload", disconnect);

        if (isMobile()) {
          // Add a listener to disconnect from the room when a mobile user closes their browser
          window.addEventListener("pagehide", disconnect);
        }
      },
      (error) => {
        console.error("Twilio", error.message, error.code);
        setIsConnecting(false);

        if (error.code === 1 || error.code === 53105) {
          resetRoom();

          window.location.replace(
            `/${roomName}/error/?error=${error.code.toString()}`
          );
        }
        setIsConnecting(false);
      }
    );
  }, [localTracks, resetRoom, roomName, token, debug]);

  return {
    room,
    isConnecting,
    localParticipant,
    twilioConnect,
  };
}
