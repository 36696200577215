import { RoundButton, Tooltip, NoAudioNotification } from "components/elements";
import {
  ControllerIcon,
  ControllerOffIcon,
  MicOffIcon,
} from "components/icons";
import {
  useMixpanelContext,
  useTrack,
  usePublications,
  useAudioLevelIndicator,
  useIsTrackEnabled,
} from "hooks";
import { FC, useEffect, useRef } from "react";
import {
  LocalAudioTrack,
  LocalParticipant,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteParticipant,
} from "twilio-video";
import { participantIdentityToName, getInitials } from "utils";
import { classNamesUtil } from "utils/classNamesUtil";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FILTER_SHADOW_SVG = "drop-shadow(rgba(0, 0, 0, 0.7) 0px 0px 6px)";

interface ParticipantProps {
  isHost: boolean;
  isRemote?: boolean;
  roomHostIdentity: string;
  isInControl: boolean;
  isControlBtnDisplayed: boolean;
  isControlButtonMargin: boolean;
  participant: LocalParticipant | RemoteParticipant;
  handleClickControlSwitch: (identity: string) => void;
  selectedAudioOutput?: MediaDeviceInfo | undefined | null;
}

const Participant: FC<ParticipantProps> = ({
  isHost,
  isRemote,
  participant,
  roomHostIdentity,
  isInControl,
  isControlBtnDisplayed,
  isControlButtonMargin,
  handleClickControlSwitch,
  selectedAudioOutput,
}) => {
  const { Mixpanel, MixpanelEvents } = useMixpanelContext();

  const videoRef = useRef<HTMLVideoElement>(null!);
  const audioRef = useRef<HTMLAudioElement>();

  const publications = usePublications(participant);
  const videoPublication = publications.find(
    (p) => !p.trackName.includes("screen") && p.kind === "video"
  );
  const videoTrack = useTrack(videoPublication) as LocalVideoTrack;
  const isVideoEnabled = useIsTrackEnabled(videoTrack);

  const audioPublication = publications.find((p) => p.kind === "audio");
  const audioTrack = useTrack(audioPublication) as
    | LocalAudioTrack
    | RemoteAudioTrack
    | undefined;

  const isAudioEnabled = useIsTrackEnabled(audioTrack);

  const { AudioBorderDivRef } = useAudioLevelIndicator(
    audioTrack,
    Boolean(audioTrack)
  );
  const shouldMuteOnFirstRender = useRef(true);

  const displayAudioNotWorkingToast = (audioElementId: string) => {
    toast(
      <NoAudioNotification
        audioRef={audioRef}
        onDismiss={() => toast.dismiss()}
        onRetryClick={() => {
          Mixpanel.track(MixpanelEvents.Button.Primary.Click("Retry Audio"));
        }}
      />,
      {
        autoClose: false,
        draggable: false,
        toastId: `audio-not-working-toast-${audioElementId}`,
        closeButton: false,
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  useEffect(() => {
    if (!videoTrack) return;

    const el = videoRef.current;
    el.muted = true;

    // Need to call this at the end of the call stack, not sure why - in iPhone the next re-renders destroy the stream.
    setTimeout(() => videoTrack.attach(el));
    return () => {
      videoTrack.detach(el);

      // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
      // See: https://github.com/twilio/twilio-video.js/issues/1528
      el.srcObject = null;
    };
  }, [videoTrack]);

  // Audio
  useEffect(() => {
    if (!audioTrack) return;

    audioRef.current = audioTrack.attach();
    audioRef.current.setAttribute("data-cy-audio-track-name", audioTrack.name);
    document.body.appendChild(audioRef.current);

    // This is to handle the delayed audio being played out on the first render
    // Twilio buffers audio that is not heard and plays it back quickly on first render
    // This include the audio that was not heard while in the lobby, which is not desired.
    let delayUnmuteTimeout: ReturnType<typeof setTimeout>;
    if (shouldMuteOnFirstRender.current) {
      audioRef.current.pause();
      shouldMuteOnFirstRender.current = false;
      delayUnmuteTimeout = setTimeout(() => {
        if (audioRef.current)
          audioRef.current.play().catch(() => {
            displayAudioNotWorkingToast(audioTrack.name);
          });
      }, 1000);
    }

    return () =>
      audioTrack.detach().forEach((el) => {
        el.remove();

        // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
        // See: https://github.com/twilio/twilio-video.js/issues/1528
        el.srcObject = null;
        clearTimeout(delayUnmuteTimeout);
      });
  }, [audioTrack]);

  useEffect(() => {
    if (
      selectedAudioOutput &&
      // @ts-ignore
      typeof audioRef.current?.setSinkId === "function"
    ) {
      // @ts-ignore
      audioRef.current?.setSinkId(selectedAudioOutput.deviceId);
    }
  }, [selectedAudioOutput]);

  return (
    <div>
      <div className="relative w-full h-full rounded mb-1.5">
        <div className="relative flex items-center justify-center ease-linear transition-all duration-75 rounded-[8px]">
          <div
            className={classNamesUtil(
              "w-full h-[180px]",
              isVideoEnabled ? "opacity-100" : "opacity-0"
            )}
          >
            <video
              style={{
                width: "100%",
                height: "100%",
                overflow: "hidden",
                maxHeight: "180px",
                objectFit: "cover",
                borderRadius: "5px",
                transform: "rotateY(180deg)",
              }}
              ref={videoRef}
              muted
              autoPlay
              playsInline
              id={`videoElement-room-${participant.identity}`}
            />
          </div>
          <div
            className={classNamesUtil(
              "absolute top-0 left-0 h-[180px] w-full flex justify-center items-center rounded bg-minimap-background",
              isVideoEnabled ? "opacity-0" : "opacity-100"
            )}
          >
            <div className="flex items-center justify-center w-16 h-16 uppercase rounded-full bg-minimap-background-dark bg-opacity-20">
              <p className="text-2xl text-giraffe-gray-dark font-bold font-primary">
                {getInitials(participantIdentityToName(participant.identity))}
              </p>
            </div>
          </div>
        </div>

        {/* Buttons for host over remote participant */}
        {isRemote && isHost && (
          <div
            className={classNamesUtil(
              isControlButtonMargin ? "right-12" : "right-2",
              " absolute top-2 z-10 transition-all duration-300 ease-in-out transform-gpu flex items-center"
            )}
          >
            <Tooltip
              tooltipOffset="left"
              tooltipPosition="bottom"
              tooltipText={
                isInControl ? "Guest is in control" : "Give control to Guest"
              }
            >
              <RoundButton
                handleClick={() => {
                  const mixpanelMessage = isInControl
                    ? "Regain control"
                    : "Give control";
                  Mixpanel.track(MixpanelEvents.Button.Click(mixpanelMessage));
                  handleClickControlSwitch(participant.identity);
                }}
                btnSize="sm"
                isDisabled={isInControl}
              >
                {isInControl ? (
                  <ControllerIcon className="w-3.5 h-3.5 fill-giraffe-white" />
                ) : (
                  <ControllerOffIcon className="w-3.5 h-3.5 fill-giraffe-red" />
                )}
              </RoundButton>
            </Tooltip>
          </div>
        )}

        {!isRemote && isHost && isControlBtnDisplayed && (
          <div
            className={classNamesUtil(
              isControlButtonMargin ? "right-12" : "right-2",
              "absolute top-2 z-10 transition-all duration-300 ease-in-out transform-gpu flex items-center"
            )}
          >
            <Tooltip
              tooltipOffset="left"
              tooltipPosition="bottom"
              tooltipText={
                isInControl ? "You are in control" : "Regain control"
              }
            >
              <RoundButton
                isDisabled={isInControl}
                handleClick={() => {
                  const mixpanelMessage = isInControl
                    ? "Give control"
                    : "Regain control";
                  Mixpanel.track(MixpanelEvents.Button.Click(mixpanelMessage));
                  handleClickControlSwitch(participant.identity);
                }}
                btnSize="sm"
              >
                {isInControl ? (
                  <ControllerIcon className="w-3.5 h-3.5 fill-giraffe-white" />
                ) : (
                  <ControllerOffIcon className="w-3.5 h-3.5 fill-giraffe-red" />
                )}
              </RoundButton>
            </Tooltip>
          </div>
        )}

        {/* Icon for remove participant when they are in control of tour */}
        {!isHost && isInControl && !isRemote && (
          <div
            className={classNamesUtil(
              isControlButtonMargin ? "right-12" : "right-2.5",
              "absolute z-50 top-2 w-7 h-7 flex items-center justify-center p-2 rounded-full"
            )}
          >
            {/* Guest have control */}
            <Tooltip
              tooltipText="You're in control"
              tooltipPosition="bottom"
              tooltipOffset="left"
            >
              <ControllerIcon
                className="w-3.5 h-3.5 fill-giraffe-white"
                filter={FILTER_SHADOW_SVG}
              />
            </Tooltip>
          </div>
        )}

        {/* Icon for remote participant when they are in control of tour */}
        {isRemote && !isAudioEnabled && (
          <div
            className={classNamesUtil(
              "absolute z-50 top-1.5 left-1 w-8 h-8  flex items-center justify-center p-2 rounded-full"
            )}
          >
            {/* Guest is muted */}
            <Tooltip
              tooltipText={
                roomHostIdentity === participant.identity
                  ? "Host is muted"
                  : "Guest is muted"
              }
              tooltipPosition="bottom"
              tooltipOffset="center"
            >
              <MicOffIcon
                className={classNamesUtil("w-full h-full fill-giraffe-white")}
                filter={FILTER_SHADOW_SVG}
              />
            </Tooltip>
          </div>
        )}

        <div
          className={classNamesUtil(
            "absolute bottom-0 border-black left-0 px-3 py-2 bg-gradient-to-b w-full from-transparent to-gray-800/40 rounded-[5px] overflow-hidden"
          )}
        >
          <div className="w-full text-end">
            <span className="w-full text-sm text-white truncate font-bold">
              {participantIdentityToName(participant.identity)}
            </span>
          </div>
        </div>

        <div
          ref={AudioBorderDivRef}
          className="absolute top-0 left-0 w-full h-full"
        >
          <div className="w-full h-full border-[2px] border-white rounded-md overflow-hidden">
            <div className="w-full h-full border-[1px] border-black rounded-md" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Participant;
