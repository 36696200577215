import FullScreenIcon from "../icons/FullScreenIcon";
import useFullScreen from "../hooks/useFullScreen";

const FullScreenButton = () => {
  // TODO: state change doesn't react when F11 is pressed
  const { isFullscreen, enabledFullscreen, toggleFullscreen } = useFullScreen();

  if (!enabledFullscreen) return null;

  return (
    <button
      type="button"
      className={`pointer-events-auto relative ml-2.5 flex h-10 w-10 min-w-0 items-center justify-center rounded-lg
        bg-minimap-background bg-opacity-70 fill-white stroke-white p-2`}
      onClick={() => {
        toggleFullscreen();
      }}
    >
      <FullScreenIcon isOpen={isFullscreen} className="h-6 w-6" />
    </button>
  );
};

export default FullScreenButton;
