/* eslint-disable no-continue */
import { pinMultiOffset } from './svg';
const iconOffsetPosCanvas = [
    [{ x: 0, y: 0 }],
    [
        { x: pinMultiOffset / 2, y: 0 },
        { x: -pinMultiOffset / 2, y: 0 },
    ],
    [
        { x: pinMultiOffset, y: 0 },
        { x: 0, y: 0 },
        { x: -pinMultiOffset, y: 0 },
    ],
];
/**
 * Get offsets for 1,2 and 3 pin groups.
 */
export const getGroupPinIconOffsets = (count) => {
    const n = Math.max(0, Math.min(count - 1, 2)); // clamp [1,2,3,4,+] to [0,1,2]
    return iconOffsetPosCanvas[n];
};
/**
 * The more zoomed in the map is, the more expensive this gets.
 * Optimization idea - only check the visible pins
 */
export const groupPins = (pins, scale, pinDiameter) => {
    const proximityThresholdSquared = Math.pow((pinDiameter / scale), 2);
    const groups = [];
    const visited = new Array(pins.length).fill(false);
    for (let i = 0; i < pins.length; i += 1) {
        if (visited[i])
            continue;
        const groupedPinIndexes = [i];
        let sumX = pins[i].posFloorPlan.x;
        let sumY = pins[i].posFloorPlan.y;
        let count = 1;
        visited[i] = true;
        for (let j = 0; j < pins.length; j += 1) {
            if (i !== j && !visited[j]) {
                const dx = pins[i].posFloorPlan.x - pins[j].posFloorPlan.x;
                const dy = pins[i].posFloorPlan.y - pins[j].posFloorPlan.y;
                const distanceSq = dx * dx + dy * dy;
                if (distanceSq <= proximityThresholdSquared) {
                    visited[j] = true;
                    sumX += pins[j].posFloorPlan.x;
                    sumY += pins[j].posFloorPlan.y;
                    count += 1;
                    groupedPinIndexes.push(j);
                }
            }
        }
        const centroid = { x: sumX / count, y: sumY / count };
        groups.push({
            pins: groupedPinIndexes.map((idx) => pins[idx]),
            posFloorPlan: centroid,
            posCanvas: { x: 0, y: 0 },
            culled: false,
            iconOffsetPosCanvas: getGroupPinIconOffsets(count),
        });
    }
    return groups;
};
/**
 * @returns the closest distance between any two pins in the group (in canvas pixels)
 * @param group
 */
export const findClosestDistanceInGroup = (group) => {
    if (group.pins.length < 2)
        return 0;
    let closestDistanceSquared = Number.MAX_SAFE_INTEGER;
    for (let i = 0; i < group.pins.length; i += 1) {
        for (let j = i + 1; j < group.pins.length; j += 1) {
            if (i === j)
                continue;
            const dx = group.pins[i].posCanvas.x - group.pins[j].posCanvas.x;
            const dy = group.pins[i].posCanvas.y - group.pins[j].posCanvas.y;
            const distanceSquared = Math.pow(dx, 2) + Math.pow(dy, 2);
            if (distanceSquared < closestDistanceSquared)
                closestDistanceSquared = distanceSquared;
        }
    }
    return Math.sqrt(closestDistanceSquared);
};
