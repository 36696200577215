import { SVGProps } from "react";

const ArrowsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 36 36"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <path d="M28.309 8.125H1.95C.874 8.125 0 9.009 0 10.099c0 1.089.874 1.974 1.95 1.974h26.12l-4.658 4.567a1.992 1.992 0 0 0-.044 2.792 1.935 1.935 0 0 0 2.758.044l8.025-7.87c.068-.058.131-.12.19-.186l.054-.053a1.986 1.986 0 0 0-.014-2.849L26.087.543a1.935 1.935 0 0 0-2.758.071c-.741.79-.71 2.04.07 2.79l4.91 4.72ZM1.83 24.372c-.067.058-.13.12-.19.187l-.053.053c-.381.374-.595.89-.592 1.427.003.538.222 1.051.608 1.421l8.301 7.969c.781.75 2.017.716 2.758-.074.74-.79.708-2.041-.073-2.79l-4.913-4.716 26.358-.023c1.077 0 1.95-.886 1.949-1.975 0-1.09-.876-1.974-1.952-1.973l-26.12.023 4.655-4.572c.772-.759.79-2.01.041-2.791a1.935 1.935 0 0 0-2.758-.042L1.83 24.372Z" />
  </svg>
);

export default ArrowsIcon;
