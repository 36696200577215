const config = {
  baseUrl: process.env.REACT_APP_BACKEND_ENDPOINT || '',
  dashboardUrl: process.env.REACT_APP_DASHBOARD_URL || '',
  internetSpeedImage: {
    url: 'https://static.giraffe360.com/apps/guided-viewing/bandwidth-test/image.jpeg',
    size: 55366, // bytes.
  },
  mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN || '',
};

export default config;
