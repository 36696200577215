import type {
  GuidedViewingStreamEvent,
  ScenePos,
  Radian,
} from "@g360/vt-types";
import { useRoomContext } from "hooks";
import { useCallback, useEffect, useState } from "react";
import { Subject } from "rxjs";

const useTourEvents = () => {
  const { twilioMessageEmitter } = useRoomContext();

  const [receivedPingCoords, setReceivedPingCoords] = useState<ScenePos | null>(null);
  const [remoteFrames$] = useState<Subject<GuidedViewingStreamEvent>>(
    () => new Subject()
  );

  // we need to keep the function reference
  const sendTourKeyFrame = useCallback(
    (data: GuidedViewingStreamEvent) =>
      twilioMessageEmitter.emit("tour.keyframe.send", data),
    [twilioMessageEmitter]
  );

  const sendPingCoords = (camera: ScenePos<Radian> | null) => {
    twilioMessageEmitter.emit("tour.pointer.send", camera);
  };

  useEffect(() => {
    const subscriptions = [
      twilioMessageEmitter.subscribe(
        "tour.keyframe.receive",
        (data: GuidedViewingStreamEvent) => {
          remoteFrames$.next(data);
        }
      ),
      twilioMessageEmitter.subscribe("tour.pointer.receive", setReceivedPingCoords),
    ];

    return () =>
      subscriptions.forEach((subscription) => subscription.unsubscribe());
  }, [twilioMessageEmitter, remoteFrames$]);

  return {
    receivedPingCoords,
    remoteFrames$,
    sendTourKeyFrame,
    sendPingCoords,
  };
};

export default useTourEvents;
