import type { StartAnimationFunction } from '../types/internal';
import type {
  ChangeCamera,
  ChangeScene,
  ClickHotSpot,
  EmitFunction,
  EmptyClickCallback,
  EngineState,
  GetEngineState,
  HideCaptionText,
  HotSpotMoveEnd,
  IController,
  IControllerOptions,
  MoveHotSpot,
  PreloadScenes,
  SceneMove,
  SelectHotSpot,
  SetCaptionText,
  UpdateHotSpot,
  Zoom,
} from './types';
import { always, noAction } from './utils';

export default abstract class BaseController implements IController {
  protected connected = false;
  protected engineState: EngineState | null = null;

  protected sceneMoveStart: SceneMove = noAction;
  protected changeCamera: ChangeCamera = noAction;
  protected sceneMoveEnd: SceneMove = noAction;
  protected changeScene: ChangeScene = noAction;
  protected selectHotSpot: SelectHotSpot = noAction;
  protected emptyClickCallback: EmptyClickCallback = noAction;
  protected clickHotSpot: ClickHotSpot = noAction;
  protected hotSpotMoveEnd: HotSpotMoveEnd = noAction;
  protected zoomPano: Zoom = noAction;
  protected zoomFloorPlan: Zoom = noAction;
  protected moveHotSpot: MoveHotSpot = noAction;
  protected setCaptionText: SetCaptionText = noAction;
  protected hideCaptionText: HideCaptionText = noAction;
  protected updateHotSpot: UpdateHotSpot = noAction;
  protected getEngineState: GetEngineState = always({});
  protected preloadScenes: PreloadScenes = noAction;
  protected startAnimation: StartAnimationFunction = noAction;
  protected emit: EmitFunction = noAction;
  protected options: IControllerOptions | null = null;

  protected get canvas() {
    return this.getEngineState().canvas;
  }
  protected get pitch() {
    return this.getEngineState().pitch;
  }
  protected get yaw() {
    return this.getEngineState().yaw;
  }
  protected get fov() {
    return this.getEngineState().fov;
  }
  protected get hotSpots() {
    return this.getEngineState().hotSpots;
  }
  protected get boundingRect() {
    return this.getEngineState().boundingRect;
  }

  initialize(options: IControllerOptions) {
    if (!options) throw new Error('Controller needs initialize options');
    this.options = options;
  }

  connect() {
    if (!this.options) throw new Error('Controller needs connect options');
    this.connected = true;
    this.setOptions(this.options);
  }

  disconnect() {
    this.connected = false;
    this.setOptions({
      sceneMoveStart: noAction,
      sceneMove: noAction,
      sceneMoveEnd: noAction,
      zoomPano: noAction,
      zoomFloorPlan: noAction,
      changeScene: noAction,
      selectHotSpot: noAction,
      clickHotSpot: noAction,
      getEngineState: always({}),
      moveHotSpot: always({}),
      updateHotSpot: always({}),
      emptyClickCallback: noAction,
      hotSpotMoveEnd: noAction,
      setCaptionText: noAction,
      hideCaptionText: noAction,
      preloadScenes: noAction,
      startAnimation: noAction,
      emit: noAction,
    });
  }

  isConnected() {
    return this.connected;
  }

  private setOptions(options: IControllerOptions) {
    this.sceneMoveStart = options.sceneMoveStart;
    this.changeCamera = options.sceneMove;
    this.sceneMoveEnd = options.sceneMoveEnd;
    this.changeScene = options.changeScene;
    this.selectHotSpot = options.selectHotSpot;
    this.clickHotSpot = options.clickHotSpot;
    this.getEngineState = options.getEngineState;
    this.moveHotSpot = options.moveHotSpot;
    this.setCaptionText = options.setCaptionText;
    this.hideCaptionText = options.hideCaptionText;
    this.zoomPano = options.zoomPano;
    this.zoomFloorPlan = options.zoomFloorPlan;
    this.updateHotSpot = options.updateHotSpot;
    this.emptyClickCallback = options.emptyClickCallback;
    this.hotSpotMoveEnd = options.hotSpotMoveEnd;
    this.preloadScenes = options.preloadScenes;
    this.startAnimation = options.startAnimation;
    this.emit = options.emit;
  }
}
