import { useEffect, useRef, useState } from "react";

type AccordionProps = {
  isOpened: boolean;
  minHeight?: number;
  children: React.ReactNode;
};
const Accordion = ({ minHeight, isOpened, children }: AccordionProps) => {
  const [height, setHeight] = useState<string>("0px");
  const contentElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setHeight(isOpened ? `${contentElement.current?.scrollHeight}px` : "0px");
  }, [isOpened]);

  return (
    <div
      ref={contentElement}
      style={{
        height: height,
        ...(minHeight &&
          isOpened &&
          // For first render contentElement.current.scrollHeight is wrong and we need to set minHeight
          contentElement.current &&
          contentElement.current.scrollHeight <= minHeight && {
            minHeight: `${minHeight}px`,
          }),
      }}
      className="overflow-hidden transition-all duration-200 transform-gpu"
    >
      {children}
    </div>
  );
};

export default Accordion;
