import { Checkbox, OutlineButton, PrimaryButton } from "components/elements";
import { Modal } from "components/layout";
import { useAppStateContext, useMixpanelContext } from "hooks";
import { FC, useState } from "react";

interface EndSessionModalProps {
  isEndSessionModalVisible: boolean;
  handelCloseModal: () => void;
  handelEndSession: (shouldDelete: boolean) => void;
}

const EndSessionModal: FC<EndSessionModalProps> = ({
  isEndSessionModalVisible,
  handelCloseModal,
  handelEndSession,
}) => {
  const { isHost } = useAppStateContext();
  const { Mixpanel, MixpanelEvents } = useMixpanelContext();
  const [shouldDeleteCheckboxChecked, setShouldDeleteCheckboxChecked] =
    useState<boolean>(false);

  const renderHostModalContent = () => {
    return (
      <div>
        <label
          className="text-base tracking-[1%] leading-6 flex items-center font-primary font-bold"
          htmlFor="delete link"
        >
          <Checkbox
            name="delete link"
            isChecked={shouldDeleteCheckboxChecked}
            handleClick={() => {
              if (shouldDeleteCheckboxChecked) {
                Mixpanel.track(MixpanelEvents.Checkbox.Uncheck("Delete link"));
              } else {
                Mixpanel.track(MixpanelEvents.Checkbox.Check("Delete link"));
              }
              setShouldDeleteCheckboxChecked((state) => !state);
            }}
          />
          Delete link
        </label>

        <p className="text-sm ml-7 text-giraffe-gray-darkest font-secondary">
          The session link will be deleted when leaving. Generate a new link for
          the next guided viewing session.
        </p>
      </div>
    );
  };

  const renderGuestModalContent = () => {
    return (
      <p className="text-sm text-giraffe-gray-darkest">
        Are you sure you want to leave this guided viewing session?
      </p>
    );
  };

  return (
    <Modal
      modalWidth="xl"
      modalTitle="Leave this session?"
      isModalVisible={isEndSessionModalVisible}
      handleCancelClick={() => {
        Mixpanel.track(MixpanelEvents.Button.Click("Close"));
        handelCloseModal();
      }}
    >
      <div className="px-6 pt-[1.8rem] pb-[2.3rem]">
        {isHost ? renderHostModalContent() : renderGuestModalContent()}
      </div>
      <div className="flex justify-end px-6">
        <div className="w-36">
          <OutlineButton
            text="Cancel"
            handleButtonClick={() => {
              Mixpanel.track(MixpanelEvents.Button.Secondary.Click("Cancel"));
              handelCloseModal();
            }}
          />
        </div>
        <div className="pb-6 ml-5 w-36">
          <PrimaryButton
            text="Leave"
            bgColor="red"
            handleButtonClick={() => {
              Mixpanel.track(MixpanelEvents.Button.Primary.Click("Leave"));
              handelEndSession(shouldDeleteCheckboxChecked);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EndSessionModal;
