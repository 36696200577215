/* eslint-disable no-restricted-syntax,no-continue */
import type { Centimeter, FPMesh, Radian, TourConfig } from '@g360/vt-types';
import { areShapesSimilar, calculateCenter2d, findNearestMesh, getOutlineFrom3DMesh } from '@g360/vt-utils';

import { isMeshOnMovedFloor, tryCutawayWall } from './utils/modelNav';

type RoomInfo = {
  center: number[];
  numberOfPanos: number;
  wallAngles: Record<number, number>; // in which direction the wall is facing inside the room for each wall | [wallId] => angle
};

/**
 * Looks after navigation in single model, rooms,walls, centering on rooms, etc.
 * Lowering walls and centering on rooms - cutaway effect
 * @todo -- maybe improve 3D to 2D conversion for floors?
 * @todo -- def improve the wall lowering algorithm (raycast from wall fragment to floor to check if it's covering the floor
 */
class ModelNavigation {
  private tourConfig: TourConfig;
  private pano2Room: Record<string, string> = {}; // pano ID => room name
  private roomInfo: Record<string, RoomInfo> = {}; // room name => room info
  private modelCenter: number[] = [0, 0, 0];
  /** if null - then centering on model center, if string - then on that room */
  private _centeringOnRoom: string | null = null;
  private orbitCenter: number[] = [0, 0, 0];
  private meshes: FPMesh[] | null = null;

  private lastWallCutawayCalculationParams: {
    yaw: number;
    topDownView: boolean;
  } | null = null;

  get centeringOnRoom(): string | null {
    return this._centeringOnRoom;
  }

  set centeringOnRoom(value: string | null) {
    this._centeringOnRoom = value;

    // all the stuff that needs to be recalculated when centering changes
    this.calculateWallFragmentIdsForMeshes();
    this.calculateWallFragmentCentersForMeshes();

    this.calculateTransparency(); // for unfocused rooms

    // recalculate cutaways using last camera params (and new centering)
    const lastCutawayParams = this.lastWallCutawayCalculationParams ?? { yaw: 0, topDownView: false };
    this.calculateCutaways(lastCutawayParams.yaw, lastCutawayParams.topDownView);
  }

  constructor(tourConfig: TourConfig) {
    this.tourConfig = tourConfig;
    this.calculatePano2Room();
  }

  init(meshes: FPMesh[]) {
    this.meshes = meshes;

    this.calculateRoomsForMeshes();
    this.calculate2DDataForMeshes();
  }

  getCenteringOnRoom(): string | null {
    return this.centeringOnRoom;
  }

  /**
   *
   * @param mesh
   * @param explicitPosition -- if omitted, the room center is used
   */
  centerOnRoomByMesh(mesh: FPMesh | null, explicitPosition: number[] | undefined = undefined) {
    const newRoom = mesh?.roomIds[0] ?? null;
    const isSameRoom = newRoom === this.centeringOnRoom;

    this.centeringOnRoom = isSameRoom ? null : newRoom;
    this.orbitCenter = this.centeringOnRoom ? this.roomInfo[this.centeringOnRoom]?.center : this.modelCenter;

    if (explicitPosition) {
      this.orbitCenter = explicitPosition;
    }
  }

  /**
   * @todo -- rem later
   */
  debugNextCentering() {
    const roomNames = Object.keys(this.roomInfo);
    if (roomNames.length === 0) {
      console.log('Navigation, no rooms to center on');
      this.orbitCenter = this.modelCenter;
      return;
    }

    if (this.centeringOnRoom === null) {
      this.centeringOnRoom = roomNames[0];
      console.log('Navigation, now centering on room', this.centeringOnRoom);
      this.orbitCenter = this.roomInfo[this.centeringOnRoom].center;
    } else {
      const roomIndex = roomNames.indexOf(this.centeringOnRoom);
      const nextRoomIndex = roomIndex + 1;
      if (nextRoomIndex >= roomNames.length) {
        this.centeringOnRoom = null;
        this.orbitCenter = this.modelCenter;
        console.log('Navigation, now centering on model center; orbit center=', this.orbitCenter);
      } else {
        this.centeringOnRoom = roomNames[nextRoomIndex];
        this.orbitCenter = this.roomInfo[this.centeringOnRoom].center;
        console.log('Navigation, now centering on room', this.centeringOnRoom, 'orbit center=', this.orbitCenter);
      }
    }
  }

  getCurrentOrbitCenter() {
    return this.orbitCenter;
  }

  setModelCenter(modelCenter: number[]) {
    this.modelCenter = modelCenter;
    this.orbitCenter = this.modelCenter;
    this.centeringOnRoom = null;
  }

  /**
   * Calculate which wall meshes should be drawn as low walls
   * by checking if point offset from wall fragment center is inside the room shape
   *
   * Calculates what meshes can be skipped by some programs for current centered room
   */
  calculateCutaways(yaw: Radian, topDownView: boolean) {
    if (!this.meshes) return;

    const roundedYaw = this.roundYawForCutaways(yaw, topDownView, this.centeringOnRoom);
    if (!this.shouldRecalculateLowWalls(roundedYaw, topDownView)) return;

    this.resetCutaway();

    // top-down view, where all walls are low walls
    if (topDownView) {
      this.cutawayAllWalls();
      return;
    }

    // regular, camera facing cutaway
    this.lowerWallByMovingFloor(roundedYaw, 150);
    this.cutawayWallsByNudgingNeighbours();

    this.calculateHiddenMeshes();
  }

  /**
   * What meshes should be transparent based on focused room (if there is one)
   */
  calculateTransparency() {
    if (!this.meshes) return;

    const centeringOnRoom = this.centeringOnRoom;

    for (const mesh of this.meshes) {
      if (!centeringOnRoom) {
        // not centering room, nothing is transparent
        mesh.unfocusedRoom = false;
      } else {
        // centering - meshes from non-centered rooms are transparent
        mesh.unfocusedRoom = !mesh.roomIds.includes(centeringOnRoom);
      }
    }
  }

  /**
   * Fix roomIds for each mesh based on panoIds
   * Room ids we get from tour.json and panoIds for meshes come from model
   *
   * @todo -- some panos are not found in tour.json
   *          (merged rooms?)
   *          a) ask for more data in model
   *          b) ignore them, since the mesh has the other pano id from merged room that is in our data
   */
  private calculateRoomsForMeshes() {
    const meshes = this.meshes;
    if (!meshes) throw new Error('Navigation::calculateRoomsForMeshes: no meshes');

    const roomNames = Object.keys(this.roomInfo);
    for (const mesh of meshes) {
      const panoIds = mesh.panoIds;
      const roomIds: string[] = [];
      for (const panoId of panoIds) {
        const roomName = this.pano2Room[panoId];
        const roomIndex = roomNames.indexOf(roomName);
        if (roomIndex === -1) {
          // console.warn('Navigation::fixRoomsForMeshes: room not found in roomInfo', {
          //   panoId,
          //   roomName,
          //   roomInfo: this.roomInfo,
          //   pano2Room:this.pano2Room,
          //   roomNames,
          // });
          continue;
        }
        if (!roomIds.includes(roomName)) {
          roomIds.push(roomName);
        }
      }
      mesh.roomIds = roomIds;
    }

    console.log('rooms fixed for meshes:', { meshes });
    return meshes;
  }

  private calculate2DDataForMeshes() {
    const meshes = this.meshes;
    if (!meshes) throw new Error('Navigation::calculate2DDataForMeshes: no meshes');

    for (let i = 0; i < meshes.length; i += 1) {
      const mesh = meshes[i];

      // calculate 2D shapes only for walls and floors
      if (mesh.isWall || mesh.isFloor) {
        const wallVertices = mesh.positions;
        mesh.shape2d = getOutlineFrom3DMesh(wallVertices);
        mesh.center2d = calculateCenter2d(mesh.shape2d);
        // console.log("calculateShape2DData::", mesh.positions,'=>',mesh.shape2d,mesh.center2d);
      }
    }
  }

  /**
   * Finds the room name for each panoId
   * There might be problems in HUGE properties with lots of rooms
   * @todo -- maybe we can use floor geometry as base for rooms ?
   *          not room names from tour.json  (since they might be same names for different rooms)
   *          and attach panos to room by position?
   */
  private calculatePano2Room() {
    const panoIds = Object.keys(this.tourConfig.scenes);
    for (const panoId of panoIds) {
      const scene = this.tourConfig.scenes[panoId];
      if (!scene) throw new Error('Navigation::calculatePano2Room: scene not found in tourConfig');

      const roomName = `${scene.name}-${scene.building}/${scene.floor}-${scene.area}`; // hopefully unq name, since there might be many duplicate "WC" rooms if identified by name only
      this.pano2Room[panoId] = roomName;

      const roomInfo = this.roomInfo[roomName] ?? {
        center: [0, 0, 0],
        numberOfPanos: 0,
        wallNormals: {},
        wallAngles: {},
      };
      roomInfo.numberOfPanos += 1;
      // sum up all pano positions to later find the center of the room
      roomInfo.center[0] += scene.camera[0]; // tour config uses z,y for a plane and z for height
      roomInfo.center[1] += scene.camera[2];
      roomInfo.center[2] += scene.camera[1]; // we are using x,z for a plane and y for height
      this.roomInfo[roomName] = roomInfo;
    }

    // @todo -- maybe use data json to get room positions ?
    //          not directly, to avoid unnecessary download,
    //          but this info could be embedded in .glb file

    // find room center by averaging all panos in the room
    const rooms = Object.keys(this.roomInfo);
    for (const roomName of rooms) {
      const roomInfo = this.roomInfo[roomName];
      roomInfo.center[0] /= roomInfo.numberOfPanos;
      roomInfo.center[1] /= roomInfo.numberOfPanos;
      roomInfo.center[2] /= roomInfo.numberOfPanos;
      this.roomInfo[roomName] = roomInfo;
    }

    // console.log('this.pano2Room', this.pano2Room);
  }

  /**
   * Calculates what meshes can be skipped by what programs
   * Trying to turn off meshes that are hidden by other meshes
   */
  private calculateHiddenMeshes() {
    if (!this.meshes) return;
    const centeringOnRoom = this.centeringOnRoom;

    if (centeringOnRoom) {
      // @todo -- hide side caps that are not visible - that are not bordering lowered wall fragments
    } else {
      for (let i = 0; i < this.meshes.length; i += 1) {
        const mesh = this.meshes[i];

        // never draw side caps, they are always hidden
        if (mesh.isSideCap) {
          mesh.skipSolidRendering = true;
          mesh.skipFlatRendering = true;
        }

        // Don't draw wall caps on top walls with solid renderer (flat renderer will render this mesh)
        if (mesh.isWallCap && mesh.isTopWall) {
          mesh.skipSolidRendering = true;
        }

        // Don't draw any wall caps on bottom wall (they are hidden inside the wall)
        if (mesh.isWallCap && mesh.isBottomWall) {
          mesh.skipSolidRendering = true;
          mesh.skipFlatRendering = true;
        }
      }
    }
  }

  private shouldRecalculateLowWalls(roundedYaw: number, topDownView: boolean): boolean {
    if (this.lastWallCutawayCalculationParams === null) {
      this.lastWallCutawayCalculationParams = { yaw: roundedYaw, topDownView };
      return true;
    }

    const yawChanged = this.lastWallCutawayCalculationParams.yaw !== roundedYaw;
    const viewChanged = this.lastWallCutawayCalculationParams.topDownView !== topDownView;
    if (yawChanged || viewChanged) {
      this.lastWallCutawayCalculationParams = { yaw: roundedYaw, topDownView };
      return true;
    }

    return false;
  }

  private lowerWallByMovingFloor(yaw: Radian, distance: Centimeter = 150, shrinkDistance: Centimeter = 20) {
    if (!this.meshes) return;

    const centeringOnRoom = this.centeringOnRoom;
    if (centeringOnRoom === null) return;

    const floorMesh = this.meshes.find((mesh) => mesh.isFloor && mesh.roomIds.includes(centeringOnRoom));
    if (!floorMesh) return;

    const camDx = Math.cos(yaw) * -distance;
    const camDy = Math.sin(yaw) * -distance;

    // move points towards room center (shrink) and then move towards camera
    const movedFloor: [number, number][] = floorMesh.shape2d.map((point) => {
      const vectorX = floorMesh.center2d[0] - point[0];
      const vectorY = floorMesh.center2d[1] - point[1];
      const shrinkMagnitude = Math.sqrt(vectorX * vectorX + vectorY * vectorY);
      const shrinkDx = (vectorX / shrinkMagnitude) * shrinkDistance;
      const shrinkDy = (vectorY / shrinkMagnitude) * shrinkDistance;
      return [point[0] + shrinkDx + camDx, point[1] + shrinkDy + camDy];
    });

    for (let i = 0; i < this.meshes.length; i += 1) {
      const mesh = this.meshes[i];
      const towardsCamera = isMeshOnMovedFloor(mesh, movedFloor);
      tryCutawayWall(towardsCamera, mesh);
    }
  }

  private calculateWallFragmentIdsForMeshes() {
    if (!this.meshes) return;
    const meshes = this.meshes;

    const centeringOnRoom = this.centeringOnRoom;
    if (centeringOnRoom === null) return;

    const floorMesh = meshes.find((mesh) => mesh.isFloor && mesh.roomIds.includes(centeringOnRoom));
    if (!floorMesh) return;

    let nextWallFragmentId = 0;
    let nextGroupId = 0;
    const fourPlusMeshes: FPMesh[] = [];
    const groupLookup = new Map<number, FPMesh[]>();

    // 4+ point shapes
    for (let i = 0; i < meshes.length; i += 1) {
      const mesh = meshes[i];
      if (!mesh.isWall || !mesh.roomIds.includes(centeringOnRoom)) {
        mesh.wallFragmentId = -1;
        continue;
      }

      if (mesh.shape2d.length >= 4) {
        mesh.wallFragmentId = nextWallFragmentId;
        nextWallFragmentId += 1;
        fourPlusMeshes.push(mesh);

        // is shape similar to existing group
        let foundGroup = false;
        const groupEntries = Array.from(groupLookup.entries());
        for (let j = 0; j < groupEntries.length; j += 1) {
          const groupMeshes = groupEntries[j][1];
          if (areShapesSimilar(mesh.shape2d, groupMeshes[0].shape2d, 1)) {
            groupMeshes.push(mesh);
            foundGroup = true;
            break;
          }
        }

        if (!foundGroup) {
          groupLookup.set(nextGroupId, [mesh]);
          nextGroupId += 1;
        }
      } else {
        mesh.wallFragmentId = -1;
      }
    }

    // 2-point shapes
    for (let i = 0; i < meshes.length; i += 1) {
      const mesh = meshes[i];
      if (!mesh.isWall || !mesh.roomIds.includes(centeringOnRoom) || mesh.shape2d.length !== 2) continue;

      const nearestFourPlusMesh = findNearestMesh(mesh.center2d, fourPlusMeshes);
      if (nearestFourPlusMesh) {
        mesh.wallFragmentId = nearestFourPlusMesh.wallFragmentId;

        // Check if this 2-point shape is similar to any existing group
        let foundGroup = false;
        const groupEntries = Array.from(groupLookup.entries());
        for (let j = 0; j < groupEntries.length; j += 1) {
          const groupMeshes = groupEntries[j][1];
          if (areShapesSimilar(mesh.shape2d, groupMeshes[0].shape2d, 1)) {
            groupMeshes.push(mesh);
            foundGroup = true;
            break;
          }
        }

        if (!foundGroup) {
          groupLookup.set(nextGroupId, [mesh]);
          nextGroupId += 1;
        }
      }
    }
  }

  private calculateWallFragmentCentersForMeshes(): void {
    if (!this.meshes) return;
    const meshes = this.meshes;
    const fragmentGroups: { [key: number]: FPMesh[] } = {};

    // group
    for (let i = 0; i < meshes.length; i += 1) {
      const mesh = meshes[i];
      if (!fragmentGroups[mesh.wallFragmentId]) {
        fragmentGroups[mesh.wallFragmentId] = [];
      }
      fragmentGroups[mesh.wallFragmentId].push(mesh);
    }

    // calc
    const fragmentIds = Object.keys(fragmentGroups);
    for (let i = 0; i < fragmentIds.length; i += 1) {
      const fragmentId = fragmentIds[i];
      const group = fragmentGroups[fragmentId];
      let sumX = 0;
      let sumZ = 0;

      for (let j = 0; j < group.length; j += 1) {
        const [x, z] = group[j].center2d;
        sumX += x;
        sumZ += z;
      }

      const avgX = sumX / group.length;
      const avgZ = sumZ / group.length;
      const wallFragmentCenter: [number, number] = [avgX, avgZ];

      // assign
      for (let j = 0; j < group.length; j += 1) {
        group[j].wallFragmentCenter = wallFragmentCenter;
      }
    }
  }

  private resetCutaway() {
    if (!this.meshes) return;
    for (let i = 0; i < this.meshes.length; i += 1) {
      const mesh = this.meshes[i];
      if (!mesh.isWall) continue;
      mesh.isWallLowered = false;
      mesh.skipSolidRendering = false;
      mesh.skipFlatRendering = false;
    }
  }

  private cutawayAllWalls() {
    if (!this.meshes) return;
    for (let i = 0; i < this.meshes.length; i += 1) {
      const mesh = this.meshes[i];
      if (!mesh.isWall) continue;

      // Don't draw top walls
      if (mesh.isTopWall) {
        mesh.skipSolidRendering = true;
        mesh.skipFlatRendering = true;
      }
    }
  }

  /**
   * Rounding yaw, returns constant magic values for cases when yaw doesn't matter
   */
  // eslint-disable-next-line class-methods-use-this
  private roundYawForCutaways(yaw: Radian, topDownView: boolean, centeringOnRoom: string | null): number {
    const roundAmount: Radian = 0.2;
    const actualYaw = -yaw - Math.PI / 2;
    let roundedYaw = Math.round(actualYaw / roundAmount) * roundAmount;
    if (topDownView) {
      roundedYaw = 999; // don't recalculate low walls in top-down view based on angle (only if the mode changes)
    }
    if (centeringOnRoom === null) {
      roundedYaw = 998; //  don't recalculate low walls when no room is selected based on angle
    }
    return roundedYaw;
  }

  /**
   * Lower all walls with same wall fragment id
   * if any of them is lowered.
   *
   * This helps with some flat meshes that are part of a wall but split up and are located on the outside of the room.
   * Geometrically not caught to be lowered.
   *
   */
  private cutawayWallsByNudgingNeighbours() {
    if (!this.meshes) return;
    const meshes = this.meshes;

    const uniqueWallFragmentIds = new Set<number>();
    for (let i = 0; i < meshes.length; i += 1) {
      if (meshes[i].wallFragmentId !== undefined && meshes[i].wallFragmentId !== -1) {
        const groupHasLoweredWall = meshes.some(
          (mesh) => mesh.wallFragmentId === meshes[i].wallFragmentId && mesh.isWallLowered
        );
        if (groupHasLoweredWall) {
          uniqueWallFragmentIds.add(meshes[i].wallFragmentId);
        }
      }
    }

    uniqueWallFragmentIds.forEach((wallFragmentId) => {
      const meshesInGroup = meshes.filter((mesh) => mesh.wallFragmentId === wallFragmentId);
      for (let i = 0; i < meshesInGroup.length; i += 1) {
        tryCutawayWall(true, meshesInGroup[i]);
      }
    });
  }
}

export default ModelNavigation;
