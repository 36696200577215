import type { WatermarkConfig } from '@g360/vt-types';
import { multiplyMatrix3AndVec3, rotateM3Y, scalingM3 } from '@g360/vt-utils';

import Utils from '../../common/Utils';

/** Calculate 3d vertices for a single watermark plane without rotation
 * @returns [[x,y,z], [x,y,z], [x,y,z], [x,y,z]]
 * ```
 * [x,y,z]--[x,y,z]
 * |              |
 * |              |
 * [x,y,z]--[x,y,z]
 * x,y - vertex clip-space positions, calculated from count, yPosition and image aspect ratio
 * z - distance from camera to watermark, calculated from watermark scale
 * ```
 */
export function createWatermarkPlane(watermarkConfig: Required<WatermarkConfig>, aspectRatio: number): number[][] {
  const { count, scale, yPosition } = watermarkConfig;
  const segmentSizeDeg = 360 / count;
  // Calculates maximum size w/o watermarks overlapping at default scale 1, spacing is then done by changing scale (z)
  // which does not affect this function. At scale 1 there is no gap between watermarks, scale more than 1 will overlap
  const chordLen = Math.tan(Utils.toRad(segmentSizeDeg / 2)) * 2;
  const relativeHeight = aspectRatio * chordLen;
  const z = -1 / scale;

  // Prepare Vertex coordinates
  const topLeft = [-chordLen / 2, relativeHeight / 2 + yPosition, -z];
  const topRight = [chordLen / 2, relativeHeight / 2 + yPosition, -z];
  const bottomRight = [chordLen / 2, -relativeHeight / 2 + yPosition, -z];
  const bottomLeft = [-chordLen / 2, -relativeHeight / 2 + yPosition, -z];

  return [topLeft, topRight, bottomRight, bottomLeft];
}

/** Moves the watermark plane to a specific angle */
export function rotateWatermarkPlane(watermarkAngle: number, watermarkPlane: number[][]): number[][] {
  const position = rotateM3Y(scalingM3(-1, 1), Utils.toRad(watermarkAngle));
  return watermarkPlane.map((point) => multiplyMatrix3AndVec3(position, point));
}
