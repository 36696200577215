const getInitials = (str: string) => {
  const parts = str.split(" ");

  // if (!parts.length) {
  //   return "";
  // }

  if (parts.length === 1 && parts[0]) {
    return parts[0].charAt(0);
  }

  if (parts.length >= 2 && parts[0] && parts[1]) {
    return `${parts[0].charAt(0)}${parts[1].charAt(0)}`;
  }

  return "";
};

export default getInitials;
