import type Engine from "@g360/vt-engine";
import { Utils } from "@g360/vt-engine";
import { useTourEvents } from "hooks";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { ScenePos, Radian, Pixel, Pos } from "@g360/vt-types";
import { fromEvent, Observable } from "rxjs";
import { map, filter, pairwise } from "rxjs/operators";

const usePingAnimation = (
  isInControlOfTour: boolean,
  engine: Engine | null,
  canvasRef: RefObject<HTMLCanvasElement>
) => {
  const { receivedPingCoords, sendPingCoords } = useTourEvents();
  // Scene coords of the ping pointer
  const [pingHotSpotCoords, setPingHotSpotCoords] =
    useState<ScenePos<Radian> | null>(null);
  // This is used to update the position via subscriber, as it can only use mutated variables
  const pingHotSpotCoordsRef = useRef<ScenePos<Radian> | null>(null);
  // Pixel coords of the ping pointer, used by the React renderer
  const [pingPixelCoords, setPingPixelCoords] = useState<Pos<Pixel> | null>(
    null
  );

  // Allows to disable double click ping when animation is running or transition is in progress
  const disableDoubleClickPingRef = useRef<boolean>(false);

  const resetPingPointer = () => {
    pingHotSpotCoordsRef.current = null;
    disableDoubleClickPingRef.current = false;
    setPingPixelCoords(null);
  };

  useEffect(() => {
    resetPingPointer();
  }, [isInControlOfTour]);

  useEffect(() => {
    if (pingHotSpotCoords && engine) {
      // Send position to the receivers
      sendPingCoords(pingHotSpotCoords);

      // Mutate the ref so it is accessible by the Engine event subscribers
      // Ping coords are in hotspot (panorama layout) space w/o calibration offset applied
      pingHotSpotCoordsRef.current = pingHotSpotCoords;

      // Target scene coords with offset applied
      const pingHotSpotCoordsWithOffset = {
        pitch: pingHotSpotCoords.pitch,
        yaw: pingHotSpotCoords.yaw - engine.getYawOffset(),
      };

      // Sets ping pixel space coords for the React renderer
      setPingPixelCoords(
        Utils.spaceMapper(engine).cameraPointToPixel(pingHotSpotCoords)
      );

      // Center camera to the ping position
      engine.cameraAnimateTo(pingHotSpotCoordsWithOffset, "ping");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pingHotSpotCoords]);

  // Receiver side of the ping coords: save the position and handle the ping element
  useEffect(() => {
    // Use the ref so it is accessible by the scene.move.update subscriber
    pingHotSpotCoordsRef.current = receivedPingCoords;

    if (pingHotSpotCoordsRef.current && engine) {
      // Sets ping pixel space coords for the React renderer
      setPingPixelCoords(
        Utils.spaceMapper(engine).cameraPointToPixel(
          pingHotSpotCoordsRef.current
        )
      );
    } else {
      // If null is received, remove the ping element from the DOM
      setPingPixelCoords(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receivedPingCoords]);

  useEffect(() => {
    if (!engine) return;
    const subscriptions = [
      engine.subscribe("scene.move.update", () => {
        if (pingHotSpotCoordsRef.current) {
          // When transmitter starts producing move events, we need to reset the ping pointer
          if (isInControlOfTour) {
            resetPingPointer();
            // Also reset the receivers pointers just in case
            sendPingCoords(null);
          } else {
            // Receiver gets all move events (including animations) as move.update,
            // so we need to use this to update the ping pointer position
            // TODO(uzars): later we could refactor the Engine so the receiver also uses the proper events
            setPingPixelCoords(
              Utils.spaceMapper(engine).cameraPointToPixel(
                pingHotSpotCoordsRef.current
              )
            );
          }
        }
      }),

      engine.subscribe("scene.ping.update", () => {
        if (pingHotSpotCoordsRef.current && engine) {
          // After the scene.ping.update is emitted the position values in engine are not yet pitch limited,
          // so we need to limit them manually
          const scenePos = {
            yaw: engine.getYaw() + engine.getYawOffset(),
            pitch: engine.getClampedPitch(),
            fov: engine.getFov(),
          };

          // Transmitter needs to use the scene.ping.update event to update the ping pointer position
          setPingPixelCoords(
            Utils.cameraToPixel(
              pingHotSpotCoordsRef.current,
              scenePos,
              engine.getCanvasSize()
            )
          );
        }
      }),
    ];

    return () =>
      subscriptions.forEach((subscription) => subscription.unsubscribe());
  }, [engine, isInControlOfTour, sendPingCoords]);

  useEffect(() => {
    if (!engine) return;
    const subscriptions = [
      engine.subscribe("scene.ping.end", () => {
        disableDoubleClickPingRef.current = false;
      }),

      engine.subscribe("scene.transition.start", () => {
        resetPingPointer();
        disableDoubleClickPingRef.current = true;
      }),

      engine.subscribe("scene.transition.end", () => {
        disableDoubleClickPingRef.current = false;
      }),
    ];

    return () =>
      subscriptions.forEach((subscription) => subscription.unsubscribe());
  }, [engine]);

  const handleDoubleClick = useCallback(
    (event: MouseEvent) => {
      if (isInControlOfTour && engine) {
        disableDoubleClickPingRef.current = true;

        setPingHotSpotCoords(
          Utils.spaceMapper(engine).pixelPointToCamera({
            x: event.clientX,
            y: event.clientY,
          })
        );
      }
    },
    [isInControlOfTour, engine]
  );

  useEffect(() => {
    if (!canvasRef.current) return;

    const click$ = fromEvent(
      canvasRef.current,
      "click"
    ) as Observable<MouseEvent>;

    const doubleClick$ = click$.pipe(
      map((click) => ({ click, timestamp: Date.now() })),
      pairwise(),
      filter(
        ([prevClick, currentClick]) =>
          currentClick.timestamp - prevClick.timestamp < 500 &&
          Math.abs(prevClick.click.clientX - currentClick.click.clientX) < 10 &&
          Math.abs(prevClick.click.clientY - currentClick.click.clientY) < 10 &&
          !disableDoubleClickPingRef.current
      ),
      map(([_, curentClick]) => curentClick.click)
    );

    const doubleClickSub = doubleClick$.subscribe(handleDoubleClick);

    return () => doubleClickSub.unsubscribe();
  }, [canvasRef, handleDoubleClick]);

  return { pingPixelCoords, setPingPixelCoords };
};

export default usePingAnimation;
