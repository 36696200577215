import noop from "lodash/noop";
import { createContext, FC } from "react";
import {
  useLocalTracks,
  useLocalTrackToggles,
  useRoom,
  useTwilioMessageEmitter,
} from "hooks";
import {
  ConnectOptions,
  LocalAudioTrack,
  LocalDataTrack,
  LocalVideoTrack,
  LocalParticipant,
  Room,
} from "twilio-video";
import { TwilioMessageEmitterType } from "types/twilio.messages.types";

type RoomContextType = {
  localVideoTrack: LocalVideoTrack | undefined;
  isAcquiringLocalVideoTrack: boolean;
  localAudioTrack: LocalAudioTrack | undefined;
  isAcquiringLocalAudioTrack: boolean;
  localDataTrack: LocalDataTrack | undefined;
  room: Room | null;
  isConnecting: boolean;
  localParticipant: LocalParticipant | null;
  twilioConnect: () => Promise<void>;
  twilioMessageEmitter: TwilioMessageEmitterType;
};

interface RoomContextProps {
  children: JSX.Element;
  options: ConnectOptions;
}

const RoomContext = createContext<RoomContextType>({
  localVideoTrack: undefined,
  isAcquiringLocalVideoTrack: false,
  localAudioTrack: undefined,
  isAcquiringLocalAudioTrack: false,
  localDataTrack: undefined,
  room: null,
  isConnecting: false,
  localParticipant: null,
  twilioConnect: () => new Promise((resolve) => resolve()),
  twilioMessageEmitter: {
    emit: noop,
    destroyEventEmitter: noop,
    subscribe: () => ({ unsubscribe: noop }),
  },
});

const RoomContextProvider: FC<RoomContextProps> = ({ children, options }) => {
  const {
    localTracks,
    localVideoTrack,
    isAcquiringLocalVideoTrack,
    localAudioTrack,
    isAcquiringLocalAudioTrack,
    localDataTrack,
    getLocalVideoTrack,
    removeLocalVideoTrack,
    getLocalAudioTrack,
    removeLocalAudioTrack,
  } = useLocalTracks();

  const { room, isConnecting, localParticipant, twilioConnect } = useRoom(
    localTracks,
    options
  );

  useLocalTrackToggles(
    localParticipant,
    localVideoTrack,
    localAudioTrack,
    getLocalVideoTrack,
    removeLocalVideoTrack,
    getLocalAudioTrack,
    removeLocalAudioTrack
  );

  const twilioMessageEmitter = useTwilioMessageEmitter(room, localDataTrack);

  return (
    <RoomContext.Provider
      value={{
        localVideoTrack,
        isAcquiringLocalVideoTrack,
        localAudioTrack,
        isAcquiringLocalAudioTrack,
        localDataTrack,
        room,
        isConnecting,
        localParticipant,
        twilioConnect,
        twilioMessageEmitter,
      }}
    >
      {children}
    </RoomContext.Provider>
  );
};

export { RoomContext, RoomContextProvider };
