import { ContrastChecker } from '@g360/vt-engine';
import { linearScale } from '@g360/vt-utils';
import { useMemo } from 'react';
import useServices from '../contexts/useServices';
import useReactive from './useReactive';
/** Bias the accent color against black color. Result is in the range [0, 1],
 * where 1 means the accent color has very good contrast against dark color
 * and 0 means the accent color has better contrast against a light color */
const useContrastChecker = () => {
    const { tourConfigService } = useServices();
    const { accent } = useReactive(tourConfigService, ['accent']);
    const darkBias = useMemo(() => {
        const darkRatio = new ContrastChecker(accent || tourConfigService.defaultAccent).getContrastRatio('#000000') || 1;
        // Contrast ratios are in the range [1, 21], normalize to [0, 1] so it is easier to use
        return linearScale(darkRatio, [1, 21], [0, 1]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accent]);
    return darkBias;
};
export default useContrastChecker;
