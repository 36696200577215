import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Transition } from '@headlessui/react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import useGlobalContext from '../contexts/useGlobalContext';
import useServices from '../contexts/useServices';
import useResizeObserver from '../hooks/useResizeObserver';
import Header from '../modules/infoModal/components/Header';
const Portal = ({ children, rootElem }) => {
    const { layoutService } = useServices();
    if (!rootElem || layoutService.appContext !== 'guided-viewing')
        return _jsx(_Fragment, { children: children });
    return (_jsx(_Fragment, { children: createPortal(_jsx("div", { className: "vt-player-lib pointer-events-none absolute left-0 top-0 h-full w-full", children: children }), rootElem) }));
};
const Modal = forwardRef(({ children = null, className = '', headerClassName = '', headerBackButtonClassName = '', headerLabelClassName = '', headerCloseButtonClassName = '', show = true, title = '', showBackButton = false, showCloseButton = false, onBack = () => {
    /* do nothing */
}, onClose = () => {
    /* do nothing */
}, onOutsideClick = () => {
    /* do nothing */
}, }, forwardedRef) => {
    const { hostRootRef } = useGlobalContext();
    const internalScrollRef = useRef(null);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const [hasOverflowY, setHasOverflowY] = useState(false);
    const twHeaderBorder = hasOverflowY ? 'border-b border-modal-gray-light' : '';
    const handleResize = () => {
        var _a, _b;
        const osInstance = (_a = internalScrollRef.current) === null || _a === void 0 ? void 0 : _a.osInstance();
        setHasOverflowY((_b = osInstance === null || osInstance === void 0 ? void 0 : osInstance.state().hasOverflow.y) !== null && _b !== void 0 ? _b : false);
    };
    useResizeObserver(handleResize, internalScrollRef, true, 5);
    useImperativeHandle(forwardedRef, () => internalScrollRef.current);
    return (_jsx(Portal, { rootElem: hostRootRef === null || hostRootRef === void 0 ? void 0 : hostRootRef.current, children: _jsx(Transition, { show: show, className: `pointer-events-auto absolute bottom-0 left-0 right-0 top-0 flex select-none items-center justify-center bg-black bg-opacity-50 p-[10px] ${className}`, enter: "transition duration-400 ease-out", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "transition duration-400 ease-out", leaveFrom: "opacity-100", leaveTo: "opacity-0", onClick: (event) => {
                // Close on click outside
                if (event.target === event.currentTarget)
                    onOutsideClick();
            }, children: _jsxs("div", { className: "flex max-h-full w-full min-w-[18.5rem] max-w-[32.5rem] flex-col rounded bg-white", children: [_jsx(Header, { className: `${headerClassName} ${twHeaderBorder}`, backButtonClassName: headerBackButtonClassName, labelClassName: headerLabelClassName, closeButtonClassName: headerCloseButtonClassName, label: title, showBackButton: showBackButton, showCloseButton: showCloseButton, onBack: onBack, onClose: onClose }), _jsx(OverlayScrollbarsComponent, { ref: internalScrollRef, className: "overflow-y-auto", options: { scrollbars: { theme: 'os-theme-custom' } }, children: children })] }) }) }));
});
Modal.displayName = 'Modal';
export default Modal;
