import { MeResponseType } from "types/api.response.types";
import config from "config";

const me = async (): Promise<MeResponseType> => {
  const res = await fetch(`${config.baseUrl}/gapi/dashboard/me/`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });

  return await res.json();
};

export default me;
