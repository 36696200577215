import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Label from '../../../common/Label';
import Text from '../../../common/Text';
import { useTranslation } from '../../../contexts/useLocalizationContext';
import MessageIcon from '../../../icons/MessageIcon';
const VerifiedView = React.memo(() => {
    const t = useTranslation();
    return (_jsxs("div", { className: "flex flex-col items-center justify-center px-0 pb-[4.5rem] pt-12 text-center", children: [_jsx(MessageIcon, { className: "stroke-modal-dark mx-auto mb-[1.0625rem] h-[3.875rem] w-[6.25rem]", success: true }), _jsx(Label, { className: "mb-[0.3125rem]", size: "normal", testId: "gated-email-verified", children: t('gated-tour.email-verified') }), _jsx(Text, { className: "trailing-1 py-[0.125rem] tracking-[0.0075rem]", size: "medium", children: t('gated-tour.have-fun') })] }));
});
VerifiedView.displayName = 'VerifiedView';
export default VerifiedView;
