import type { FPMesh } from '@g360/vt-types';

import { getVec3Difference, normalizeVec3, vec3CrossProduct } from '../../vec3';
import {
  applyMatrix4,
  calculateTriangleNormal,
  getTriangleVertexOffsetDirections,
  multiplyMatrixAndPoint,
  rotateXMatrix,
} from '../utils';

/**
 * .glb uses explicit indexing (indexBuffer),
 * but that means each vertex is part of 1..n triangles.
 * Which is efficient, but we can't use Expand Coords effectively.
 * So we will use implicit indexing by duplicating vertices and assigning Expand Coord to each
 * now each vertex is part of 1 triangle and has an Expand Coord (direction)
 *
 * Expand Coords are used only for outline meshes.
 * But all meshes gets their triangles fixed to use implicit indexing.
 *
 * Doesn't return, fixes given mesh in place
 */
export const fixUpMesh = (mesh_: FPMesh, transformationMatrix: number[] | undefined) => {
  const vertices: number[] = [];
  const texCoords: number[] = [];
  let normals: number[] = [];

  for (let i = 0; i < mesh_.triangles.length; i += 3) {
    const indices = mesh_.triangles.slice(i, i + 3);
    const triangleVertices: number[][] = [];

    for (let j = 0; j < indices.length; j += 1) {
      const index = indices[j];
      let vertex = Array.from(mesh_.positions.slice(index * 3, index * 3 + 3));

      // optional scale->rotation->translation matrix
      if (transformationMatrix) {
        vertex = applyMatrix4(vertex, transformationMatrix);
      }

      // rotating to get the right orientation
      vertex = multiplyMatrixAndPoint(rotateXMatrix(-90), vertex);
      triangleVertices.push(vertex);
    }

    // make implicit triangles and give per-vertex data to right vertices
    for (let j = 0; j < triangleVertices.length; j += 1) {
      const vertex = triangleVertices[j];
      vertices.push(...vertex);

      // optional tex coords (uv)
      if (mesh_.texCoords.length > 0) {
        texCoords.push(...mesh_.texCoords.slice(indices[j] * 2, indices[j] * 2 + 2));
      }
    }
  }

  if (mesh_.isCeiling) {
    // @todo -- make thicker ceiling
  }

  // R-n-D models don't have normals, calculating on implicit triangles for solid meshes
  try {
    if (normals.length === 0 && !mesh_.isOutline) {
      for (let i = 0; i < vertices.length; i += 9) {
        const normal = calculateTriangleNormal(
          [vertices[i], vertices[i + 1], vertices[i + 2]],
          [vertices[i + 3], vertices[i + 4], vertices[i + 5]],
          [vertices[i + 6], vertices[i + 7], vertices[i + 8]]
        );

        normals.push(...normal);
        normals.push(...normal);
        normals.push(...normal);
      }
    }
  } catch (e) {
    // couldn't make normals, probably because of bad data, assuming this is an outline mesh
    // @todo -- get this info from RnD team and not from bad data
    normals = [];
    console.error('degenerate triangle', mesh_.unqId, e);
  }

  mesh_.positions = new Float32Array(vertices);
  mesh_.texCoords = new Float32Array(texCoords);
  mesh_.normals = new Float32Array(normals);
  mesh_.triangles = new Uint16Array(0); // delete data, not used in rendering
};

/**
 * Needed only for outline meshes
 */
export const createExpandCoordsForMesh = (msh_: FPMesh) => {
  const expandCoords: number[] = [];
  for (let i = 0; i < msh_.positions.length; i += 9) {
    const vertex1 = Array.from(msh_.positions.slice(i, i + 3));
    const vertex2 = Array.from(msh_.positions.slice(i + 3, i + 6));
    const vertex3 = Array.from(msh_.positions.slice(i + 6, i + 9));
    const expandedVertices = getTriangleVertexOffsetDirections([vertex1, vertex2, vertex3]);
    for (let j = 0; j < 3; j += 1) {
      expandCoords.push(...expandedVertices[j]);
    }
  }
  msh_.expandCoords = new Float32Array(expandCoords);
  msh_.texCoords = new Float32Array(0); // delete tex coords, not used in outline rendering
};

function perpendicular(v: number[]): number[] {
  const absX = Math.abs(v[0]);
  const absY = Math.abs(v[1]);
  const absZ = Math.abs(v[2]);

  if (absX <= absY && absX <= absZ) {
    return normalizeVec3([0, -v[2], v[1]]);
  }
  if (absY <= absX && absY <= absZ) {
    return normalizeVec3([-v[2], 0, v[0]]);
  }
  return normalizeVec3([-v[1], v[0], 0]);
}

function linesToPipes(lineVertices: number[], radius = 1.0, segments = 3): number[] {
  const pipeVertices: number[] = [];

  function addPoint(point: number[], perp1: number[], perp2: number[], cos: number, sin: number) {
    const x = point[0] + radius * (cos * perp1[0] + sin * perp2[0]);
    const y = point[1] + radius * (cos * perp1[1] + sin * perp2[1]);
    const z = point[2] + radius * (cos * perp1[2] + sin * perp2[2]);
    pipeVertices.push(x, y, z);
  }

  for (let i = 0; i < lineVertices.length; i += 6) {
    const start = lineVertices.slice(i, i + 3);
    const end = lineVertices.slice(i + 3, i + 6);

    const direction = normalizeVec3(getVec3Difference(start, end));
    const perp1 = perpendicular(direction);
    const perp2 = vec3CrossProduct(direction, perp1);

    // gen vertices
    for (let j = 0; j < segments; j += 1) {
      const angle1 = (j / segments) * Math.PI * 2;
      const angle2 = ((j + 1) / segments) * Math.PI * 2;

      const cos1 = Math.cos(angle1);
      const sin1 = Math.sin(angle1);
      const cos2 = Math.cos(angle2);
      const sin2 = Math.sin(angle2);

      // first triangle
      addPoint(start, perp1, perp2, cos1, sin1);
      addPoint(start, perp1, perp2, cos2, sin2);
      addPoint(end, perp1, perp2, cos1, sin1);

      // second triangle
      addPoint(start, perp1, perp2, cos2, sin2);
      addPoint(end, perp1, perp2, cos2, sin2);
      addPoint(end, perp1, perp2, cos1, sin1);
    }
  }

  return pipeVertices;
}

export const makePipesFromLines = (msh_: FPMesh) => {
  const lines: number[] = [];
  const numLines = Math.floor(msh_.positions.length / 6);
  if (Math.round(numLines * 6) !== Math.round(msh_.positions.length))
    console.error(
      `makePipesFromLines::weird number of positions for lines, numLines: ${numLines}, numPoints/6: ${
        msh_.positions.length / 6
      }`,
      msh_.name,
      msh_
    );

  for (let i = 0; i < numLines; i += 1) {
    // line is a pair of points (each 3 vertices)
    lines.push(msh_.positions[i * 6 + 0]);
    lines.push(msh_.positions[i * 6 + 1]);
    lines.push(msh_.positions[i * 6 + 2]);
    lines.push(msh_.positions[i * 6 + 3]);
    lines.push(msh_.positions[i * 6 + 4]);
    lines.push(msh_.positions[i * 6 + 5]);
  }

  msh_.positions = new Float32Array(linesToPipes(lines));
};
