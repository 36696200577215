import forOwn from 'lodash/forOwn';
import isEmptyString from './common';
/**
 * Functions copied from original library with minor refactoring.
 * https://github.com/jpuri/draftjs-to-html/commit/c02452e3ce6719f84f38d39f8f9b0a9e32bf871f
 */
/**
 * Mapping block-type to corresponding html tag.
 */
const blockTypesMapping = {
    unstyled: 'p',
    'header-one': 'h1',
    'header-two': 'h2',
    'header-three': 'h3',
    'header-four': 'h4',
    'header-five': 'h5',
    'header-six': 'h6',
    'unordered-list-item': 'ul',
    'ordered-list-item': 'ol',
    blockquote: 'blockquote',
    code: 'pre',
};
/**
 * Returns HTML tag for a block.
 * @param blockType - `string`: block type.
 * @returns `string`: HTML tag.
 */
export function getBlockTag(blockType) {
    return blockType && blockTypesMapping[blockType];
}
/**
 * Returns style string for a block.
 * @param data - `Record<string, any>` - `Optional`: Block data.
 * @returns `string`: Block style string.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getBlockStyle(data) {
    let styles = '';
    forOwn(data, (key, value) => {
        if (value)
            styles += `${key}:${value};`;
    });
    return styles;
}
/**
 * Returns an array of hashtag-sections in blocks.
 * These will be areas in block which have hashtags applicable to them.
 * @param blockText - `string`: Block text.
 * @param hashtagConfig - `HashtagConfig` - `Optional`: Hashtag configuration.
 * @returns `SectionRange[]`: Array of hashtag ranges.
 */
function getHashtagRanges(blockText, hashtagConfig) {
    const hashtagRanges = [];
    if (!hashtagConfig)
        return hashtagRanges;
    let counter = 0;
    let startIndex = 0;
    let text = blockText;
    const trigger = hashtagConfig.trigger || '#';
    const separator = hashtagConfig.separator || ' ';
    while (text.length > 0 && startIndex >= 0) {
        if (text[0] === trigger) {
            startIndex = 0;
            counter = 0;
            text = text.substr(trigger.length);
        }
        else {
            startIndex = text.indexOf(separator + trigger);
            if (startIndex >= 0) {
                text = text.substr(startIndex + (separator + trigger).length);
                counter += startIndex + separator.length;
            }
        }
        if (startIndex >= 0) {
            const endIndex = text.indexOf(separator) >= 0 ? text.indexOf(separator) : text.length;
            const hashtag = text.substr(0, endIndex);
            if ((hashtag === null || hashtag === void 0 ? void 0 : hashtag.length) > 0) {
                hashtagRanges.push({
                    offset: counter,
                    length: hashtag.length + trigger.length,
                    type: 'HASHTAG',
                });
            }
            counter += trigger.length;
        }
    }
    return hashtagRanges;
}
/**
 * Returns an array of entity-sections in blocks.
 * These will be areas in block which have same entity or no entity applicable to them.
 * @param block - `RawDraftContentBlock`: DraftJS block.
 * @param hashtagConfig - `HashtagConfig` - `Optional`: Hashtag configuration.
 * @returns `Section[]`: Array of entity-sections.
 */
function getSections(block, hashtagConfig) {
    const sections = [];
    let lastOffset = 0;
    let sectionRanges = block.entityRanges.map((range) => ({
        offset: range.offset,
        length: range.length,
        key: range.key,
        type: 'ENTITY',
    }));
    sectionRanges = sectionRanges.concat(getHashtagRanges(block.text, hashtagConfig));
    sectionRanges = sectionRanges.sort((left, right) => left.offset - right.offset);
    sectionRanges.forEach((sectionRange) => {
        if (sectionRange.offset > lastOffset) {
            sections.push({
                start: lastOffset,
                end: sectionRange.offset,
            });
        }
        sections.push({
            start: sectionRange.offset,
            end: sectionRange.offset + sectionRange.length,
            entityKey: sectionRange.key,
            type: sectionRange.type,
        });
        lastOffset = sectionRange.offset + sectionRange.length;
    });
    if (lastOffset < block.text.length) {
        sections.push({
            start: lastOffset,
            end: block.text.length,
        });
    }
    return sections;
}
/**
 * Checks if the block is an atomic entity block.
 * @param block - `RawDraftContentBlock`: DraftJS block.
 * @returns `boolean`: `true` if block is atomic entity block. `false` otherwise.
 */
function isAtomicEntityBlock(block) {
    if (block.entityRanges.length === 0)
        return false;
    if (!isEmptyString(block.text) && block.type !== 'atomic')
        return false;
    return true;
}
/**
 * Returns an array of inline styles applicable to the block.
 * @param block - `RawDraftContentBlock`: DraftJS block.
 * @returns `Styles`: Object with inline style arrays.
 */
function getStylesForBlock(block) {
    const { text, inlineStyleRanges } = block;
    const inlineStyles = {
        BOLD: new Array(text.length),
        CODE: new Array(text.length),
        ITALIC: new Array(text.length),
        STRIKETHROUGH: new Array(text.length),
        UNDERLINE: new Array(text.length),
        COLOR: new Array(text.length),
        BGCOLOR: new Array(text.length),
        FONTSIZE: new Array(text.length),
        FONTFAMILY: new Array(text.length),
        SUPERSCRIPT: new Array(text.length),
        SUBSCRIPT: new Array(text.length),
        length: text.length,
    };
    if (inlineStyleRanges.length === 0)
        return inlineStyles;
    inlineStyleRanges.forEach((range) => {
        const { offset, style } = range;
        const length = offset + range.length;
        for (let i = offset; i < length; i += 1) {
            if (style.indexOf('color-') === 0) {
                inlineStyles.COLOR[i] = style.substring(6);
                // eslint-disable-next-line no-continue
                continue;
            }
            if (style.indexOf('bgcolor-') === 0) {
                inlineStyles.BGCOLOR[i] = style.substring(8);
                // eslint-disable-next-line no-continue
                continue;
            }
            if (style.indexOf('fontsize-') === 0) {
                inlineStyles.FONTSIZE[i] = style.substring(9);
                // eslint-disable-next-line no-continue
                continue;
            }
            if (style.indexOf('fontfamily-') === 0) {
                inlineStyles.FONTFAMILY[i] = style.substring(11);
                // eslint-disable-next-line no-continue
                continue;
            }
            if (inlineStyles[style])
                inlineStyles[style][i] = true;
        }
    });
    return inlineStyles;
}
/**
 * Returns inline style applicable at some offset within a block.
 * @param inlineStyles - `Styles`: Inline styles for the block.
 * @param offset - `number`: Offset within the block.
 * @returns `Style`: Object with inline styles.
 */
export function getStylesAtOffset(inlineStyles, offset) {
    const style = {};
    if (inlineStyles.BOLD[offset])
        style.BOLD = true;
    if (inlineStyles.CODE[offset])
        style.CODE = true;
    if (inlineStyles.ITALIC[offset])
        style.ITALIC = true;
    if (inlineStyles.STRIKETHROUGH[offset])
        style.STRIKETHROUGH = true;
    if (inlineStyles.UNDERLINE[offset])
        style.UNDERLINE = true;
    if (inlineStyles.COLOR[offset])
        style.COLOR = inlineStyles.COLOR[offset];
    if (inlineStyles.BGCOLOR[offset])
        style.BGCOLOR = inlineStyles.BGCOLOR[offset];
    if (inlineStyles.FONTSIZE[offset])
        style.FONTSIZE = inlineStyles.FONTSIZE[offset];
    if (inlineStyles.FONTFAMILY[offset])
        style.FONTFAMILY = inlineStyles.FONTFAMILY[offset];
    if (inlineStyles.SUPERSCRIPT[offset])
        style.SUPERSCRIPT = true;
    if (inlineStyles.SUBSCRIPT[offset])
        style.SUBSCRIPT = true;
    return style;
}
/**
 * Checks if a set of styles is same at specific offset as that on the previous offset.
 * @param inlineStyles - `Styles`: Inline styles for the block.
 * @param styles - `(keyof Style)[]`: Array of style keys to check.
 * @param index - `number`: Offset within the block.
 * @returns `boolean`: `true` if styles are same. `false` otherwise.
 */
export function isSameStyleAsPrevious(inlineStyles, styles, index) {
    if (index === 0 || index >= inlineStyles.length)
        return false;
    let sameStyled = true;
    styles.forEach((style) => {
        sameStyled && (sameStyled = inlineStyles[style][index] === inlineStyles[style][index - 1]);
    });
    return sameStyled;
}
/**
 * Returns HTML markup for text depending on inline style tags applicable to it.
 * @param style - `keyof Style`: style key.
 * @param content - `string`: Text content.
 * @returns `string`: HTML markup string.
 */
export function addInlineStyleMarkup(style, content) {
    if (style === 'BOLD')
        return `<strong>${content}</strong>`;
    if (style === 'CODE')
        return `<code>${content}</code>`;
    if (style === 'ITALIC')
        return `<em>${content}</em>`;
    if (style === 'STRIKETHROUGH')
        return `<del>${content}</del>`;
    if (style === 'UNDERLINE')
        return `<ins>${content}</ins>`;
    if (style === 'SUPERSCRIPT')
        return `<sup>${content}</sup>`;
    if (style === 'SUBSCRIPT')
        return `<sub>${content}</sub>`;
    return content;
}
/**
 * Returns text for given section of block after doing required character replacements.
 * @param text - `string[]` - `Optional`: Array of characters in the section.
 * @returns `string`: Section text.
 */
function getSectionText(text) {
    if (!text)
        return '';
    const chars = text.map((char) => {
        switch (char) {
            case '\n':
                return '<br>';
            case '&':
                return '&amp;';
            case '<':
                return '&lt;';
            case '>':
                return '&gt;';
            default:
                return char;
        }
    });
    return chars.join('');
}
/**
 * Returns HTML markup for text depending on inline style tags applicable to it.
 * @param text - `string`: Text content.
 * @param style - `Style` - `Optional`: Inline styles applicable to the text.
 * @returns `string`: HTML string.
 */
export function addStylePropertyMarkup(text, style) {
    if (!style)
        return text;
    if (!style.COLOR && !style.BGCOLOR && !style.FONTSIZE && !style.FONTFAMILY)
        return text;
    let styleString = 'style="';
    if (style.COLOR)
        styleString += `color: ${style.COLOR};`;
    if (style.BGCOLOR)
        styleString += `background-color: ${style.BGCOLOR};`;
    if (style.FONTSIZE)
        styleString += `font-size: ${style.FONTSIZE}${/^\d+$/.test(style.FONTSIZE) ? 'px' : ''};`;
    if (style.FONTFAMILY)
        styleString += `font-family: ${style.FONTFAMILY};`;
    styleString += '"';
    return `<span ${styleString}>${text}</span>`;
}
/**
 * Return HTML markup for Entity.
 * @param entityMap - `EntityMap`: Entity map.
 * @param entityKey - `number`: Entity key.
 * @param text - `string` - `Optional`: Text content.
 * @param customEntityTransform - `(...args: any[]) => any` - `Optional`: Custom entity transform function.
 * @returns `string`: HTML markup string.
 */
function getEntityMarkup(entityMap, entityKey, text, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
customEntityTransform) {
    const entity = entityMap[entityKey];
    if (customEntityTransform) {
        const html = customEntityTransform(entity, text);
        if (html)
            return html;
    }
    if (entity.type === 'MENTION') {
        return `<a href="${entity.data.url}" class="wysiwyg-mention" data-mention data-value="${entity.data.value}">${text}</a>`;
    }
    if (entity.type === 'LINK') {
        const targetOption = entity.data.targetOption || '_self';
        return `<a href="${entity.data.url}" target="${targetOption}">${text}</a>`;
    }
    if (entity.type === 'IMAGE') {
        const { alignment } = entity.data;
        if (alignment === null || alignment === void 0 ? void 0 : alignment.length) {
            return `<div style="text-align:${alignment};"><img src="${entity.data.src}" alt="${entity.data.alt}" style="height: ${entity.data.height};width: ${entity.data.width}"/></div>`;
        }
        return `<img src="${entity.data.src}" alt="${entity.data.alt}" style="height: ${entity.data.height};width: ${entity.data.width}"/>`;
    }
    if (entity.type === 'EMBEDDED_LINK') {
        return `<iframe width="${entity.data.width}" height="${entity.data.height}" src="${entity.data.src}" frameBorder="0"></iframe>`;
    }
    return text !== null && text !== void 0 ? text : '';
}
/**
 * For a given section in a block returns a further list of sections,
 * with similar inline styles applicable to them.
 * @param block - `RawDraftContentBlock`: DraftJS block.
 * @param style - `(keyof Style)[]`: Array of style keys.
 * @param start - `number`: Start offset.
 * @param end - `number`: End offset.
 * @returns `Section[]`: Array of sections.
 */
function getInlineStyleSections(block, style, start, end) {
    var _a;
    const styleSections = [];
    const text = Array.from(block.text);
    if (text.length === 0)
        return styleSections;
    const inlineStyles = getStylesForBlock(block);
    let section;
    for (let i = start; i < end; i += 1) {
        if (i === start || !isSameStyleAsPrevious(inlineStyles, style, i)) {
            section = {
                styles: getStylesAtOffset(inlineStyles, i),
                text: [text[i]],
                start: i,
                end: i + 1,
            };
            styleSections.push(section);
            // eslint-disable-next-line no-continue
            continue;
        }
        // eslint-disable-next-line no-continue
        if (!section)
            continue;
        (_a = section.text) === null || _a === void 0 ? void 0 : _a.push(text[i]);
        section.end = i + 1;
    }
    return styleSections;
}
/**
 * Replaces leading whitespaces by `&nbsp;`.
 * @param sectionText - `string`: Section text.
 * @returns `string`: Trimmed section text.
 */
export function trimLeadingWhitespaces(sectionText) {
    if (!sectionText)
        return sectionText;
    let replacedText = sectionText;
    for (let i = 0; i < sectionText.length && sectionText[i] === ' '; i += 1) {
        replacedText = replacedText.replace(' ', '&nbsp;');
    }
    return replacedText;
}
/**
 * Replaces trailing whitespaces by `&nbsp;`.
 * @param sectionText - `string`: Section text.
 * @returns `string`: Trimmed section text.
 */
export function trimTrailingWhitespaces(sectionText) {
    if (!sectionText)
        return sectionText;
    let replacedText = sectionText;
    for (let i = replacedText.length - 1; i >= 0 && replacedText[i] === ' '; i -= 1) {
        replacedText = `${replacedText.substring(0, i)}&nbsp;${replacedText.substring(i + 1)}`;
    }
    return replacedText;
}
/**
 * Returns HTML markup for section to which inline styles
 * like `BOLD`, `ITALIC`, `UNDERLINE`, `STRIKETHROUGH`, `CODE`, `SUPERSCRIPT`, `SUBSCRIPT` are applicable.
 * @param styleSection - `Section`: Section with inline styles.
 * @returns `string`: HTML markup string.
 */
function getStyleTagSectionMarkup(styleSection) {
    const { styles, text } = styleSection;
    if (!styles || !text)
        return '';
    let content = getSectionText(text);
    forOwn(styles, (_, style) => {
        content = addInlineStyleMarkup(style, content);
    });
    return content;
}
/**
 * Returns HTML markup for section to which inline styles like color, background-color, font-size are applicable.
 * @param block - `RawDraftContentBlock`: DraftJS block.
 * @param styleSection - `Section`: Section with inline styles.
 * @returns `string`: HTML markup string.
 */
function getInlineStyleSectionMarkup(block, styleSection) {
    const styleTagSections = getInlineStyleSections(block, ['BOLD', 'ITALIC', 'UNDERLINE', 'STRIKETHROUGH', 'CODE', 'SUPERSCRIPT', 'SUBSCRIPT'], styleSection.start, styleSection.end);
    let styleSectionText = '';
    styleTagSections.forEach((stylePropertySection) => {
        styleSectionText += getStyleTagSectionMarkup(stylePropertySection);
    });
    styleSectionText = addStylePropertyMarkup(styleSectionText, styleSection.styles);
    return styleSectionText;
}
/*
 * Returns HTML markup for an entity section.
 * An entity section is a continuous section in a block to which same entity or no entity is applicable.
 * @param block - `RawDraftContentBlock`: DraftJS block.
 * @param entityMap - `EntityMap`: Entity map.
 * @param section - `Section`: Entity section.
 * @param customEntityTransform - `(...args: any[]) => any` - `Optional`: Custom entity transform function.
 * @returns `string`: HTML markup string.
 */
function getSectionMarkup(block, entityMap, section, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
customEntityTransform) {
    const entityInlineMarkup = [];
    const inlineStyleSections = getInlineStyleSections(block, ['COLOR', 'BGCOLOR', 'FONTSIZE', 'FONTFAMILY'], section.start, section.end);
    inlineStyleSections.forEach((styleSection) => {
        entityInlineMarkup.push(getInlineStyleSectionMarkup(block, styleSection));
    });
    let sectionText = entityInlineMarkup.join('');
    if (section.type === 'ENTITY') {
        if (section.entityKey !== undefined && section.entityKey !== null) {
            sectionText = getEntityMarkup(entityMap, section.entityKey, sectionText, customEntityTransform); // eslint-disable-line max-len
        }
    }
    else if (section.type === 'HASHTAG') {
        sectionText = `<a href="${sectionText}" class="wysiwyg-hashtag">${sectionText}</a>`;
    }
    return sectionText;
}
/**
 * Return HTML markup for block preserving the inline styles and special characters like newlines or blank spaces.
 * @param block - `RawDraftContentBlock`: DraftJS block.
 * @param entityMap - `EntityMap`: Entity map.
 * @param hashtagConfig - `HashtagConfig` - `Optional`: Hashtag configuration.
 * @param customEntityTransform - `(...args: any[]) => any` - `Optional`: Custom entity transform function.
 * @returns `string`: HTML markup string.
 */
export function getBlockInnerMarkup(block, entityMap, hashtagConfig, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
customEntityTransform) {
    const blockMarkup = [];
    const sections = getSections(block, hashtagConfig);
    sections.forEach((section, index) => {
        let sectionText = getSectionMarkup(block, entityMap, section, customEntityTransform);
        if (index === 0)
            sectionText = trimLeadingWhitespaces(sectionText);
        if (index === sections.length - 1)
            sectionText = trimTrailingWhitespaces(sectionText);
        blockMarkup.push(sectionText);
    });
    return blockMarkup.join('');
}
/**
 * Return HTML markup for the block.
 * @param block - `RawDraftContentBlock`: DraftJS block.
 * @param entityMap - `EntityMap`: Entity map.
 * @param hashtagConfig - `HashtagConfig` - `Optional`: Hashtag configuration.
 * @param isDirectional - `boolean` - `Optional`: Whether the block is directional.
 * @param customEntityTransform - `(...args: any[]) => any` - `Optional`: Custom entity transform function.
 * @returns `string`: HTML markup string.
 */
export function getBlockMarkup(block, entityMap, hashtagConfig, isDirectional, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
customEntityTransform) {
    const blockHtml = [];
    if (isAtomicEntityBlock(block)) {
        blockHtml.push(getEntityMarkup(entityMap, block.entityRanges[0].key, undefined, customEntityTransform));
        blockHtml.push('\n');
        return blockHtml.join('');
    }
    const blockTag = getBlockTag(block.type);
    if (!blockTag)
        return '\n';
    blockHtml.push(`<${blockTag}`);
    const blockStyle = getBlockStyle(block.data);
    if (blockStyle)
        blockHtml.push(` style="${blockStyle}"`);
    if (isDirectional)
        blockHtml.push(' dir = "auto"');
    blockHtml.push('>');
    blockHtml.push(getBlockInnerMarkup(block, entityMap, hashtagConfig, customEntityTransform));
    blockHtml.push(`</${blockTag}>`);
    blockHtml.push('\n');
    return blockHtml.join('');
}
