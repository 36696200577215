import { FC } from "react";
import { use100vh } from "hooks";
import { classNamesUtil } from "utils/classNamesUtil";
import { GiraffeLogoIcon } from "components/icons";

interface LoadingPageLayoutProps {
  headLine: string;
  paragraph?: string;
  icon?: JSX.Element;
}

const LoadingPageLayout: FC<LoadingPageLayoutProps> = ({
  icon,
  headLine,
  paragraph,
}) => {
  const { divRef } = use100vh();

  return (
    <div
      ref={divRef}
      className={classNamesUtil("flex flex-col justify-between w-full")}
    >
      <div className="w-11/12 mx-auto m-3.5 md:t-5 md:w-4/5 lg:w-5/6 mb-44 md:mb-0">
        <GiraffeLogoIcon className="h-10" />
      </div>
      <div className="md:flex items-center justify-center h-screen max-w-[514px] mx-auto transition-all duration-300 ease-in-out px-5">
        <div className="flex flex-col items-center justify-center text-center mb-40">
          {icon && <div className="mb-12">{icon}</div>}
          <h1 className="text-3xl mb-7">{headLine}</h1>
          {paragraph && (
            <p className="text-center text-giraffe-gray-lightest max-w-[480px] text-lg font-bold">
              {paragraph}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoadingPageLayout;
