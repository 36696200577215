import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Transition } from '@headlessui/react';
// import useGlobalContext from '../../../contexts/useGlobalContext';
import { useTranslation } from '../../../contexts/useLocalizationContext';
import useServices from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
import useDoubletapGuard from '../hooks/useDoubleTapGuard';
function IFrameScrollGuard() {
    // const { assetConfig } = useGlobalContext();
    const { layoutService } = useServices();
    const { showIFrameScrollGuard } = useReactive(layoutService, ['showIFrameScrollGuard']);
    const t = useTranslation();
    // @todo -- add gif when available
    // const [twGifOpacity, setTwGifOpacity] = useState('opacity-0');
    // const gifPath = urljoin(assetConfig.assetPath, 'images', `tutorial.gif?`); // doubletap.gif
    // const handleGifLoaded = () => {
    //   setTwGifOpacity('opacity-100');
    // };
    const handleDoubleTab = useDoubletapGuard(() => {
        var _a;
        layoutService.dismissIFrameScrollGuard();
        // try to center VT iframe in parent page
        (_a = window.frameElement) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
    return (_jsxs(Transition, { show: showIFrameScrollGuard, className: "z-tutorial-anim pointer-events-none absolute inset-0 flex select-none flex-col items-center justify-center", enter: "transition duration-[800ms] ease-out", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "transition duration-[800ms] ease-out", leaveFrom: "opacity-100", leaveTo: "opacity-0", "data-testid": "iframe-scroll-guard", children: [_jsx("div", { role: "button", tabIndex: 0, className: "pointer-events-auto absolute h-full w-full", "aria-label": "Interact with Virtual Tour", onClick: handleDoubleTab, onKeyDown: handleDoubleTab, onTouchEnd: handleDoubleTab }), _jsx("div", { className: "bg-modal-dark/30 absolute left-1/2 top-1/2 -z-10 h-[12.5rem] w-[25.625rem] -translate-x-1/2 -translate-y-1/2 blur-[77px]" }), _jsx("div", { className: "font-primary max-w-[16rem] text-center text-lg font-bold text-white", children: t('iframe-scroll-guard.intro-message') })] }));
}
export default IFrameScrollGuard;
