/**
 * Deletes a WebGL program and shaders attached to it.
 * @param gl - `WebGLRenderingContext`: An interface to the OpenGL ES 2.0 graphics rendering context for the drawing surface of an HTML `<canvas>` element.
 * @param program - `WebGLProgram | null`: A program to destroy.
 * @returns `WebGLProgram | null`: The created program or `null` if the program wasn't created or shader linking failed.
 */
function destroyProgram(gl: WebGLRenderingContext, program: WebGLProgram | null): void {
  if (!program) return;

  const shaders = gl.getAttachedShaders(program);
  gl.deleteProgram(program);

  if (!shaders) return;

  shaders.forEach((shader) => gl.deleteShader(shader));
}

export default destroyProgram;
