import { SVGProps } from "react";

const PhoneOffIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 35 35"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <path
      d="M16.005 21.995a9.33 9.33 0 0 0 2.357 1.719l1.373-1.373a.626.626 0 0 1 .647-.151 7.382 7.382 0 0 0 2.002.363.592.592 0 0 1 .426.169.64.64 0 0 1 .19.456v2.197a.62.62 0 0 1-.182.439.624.624 0 0 1-.443.186 10.606 10.606 0 0 1-7.256-3.119A10.606 10.606 0 0 1 12 15.625c0-.16.062-.319.186-.443a.62.62 0 0 1 .439-.182h2.197c.16 0 .323.058.456.191a.61.61 0 0 1 .173.429c.027.674.142 1.347.359 1.998a.626.626 0 0 1-.151.647l-1.373 1.373a9.33 9.33 0 0 0 1.719 2.357Z"
      style={{
        // fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="translate(-32 -35.2) scale(2.66667)"
    />
    <path
      d="m19.434 14.566 4 4a.802.802 0 0 0 1.132-1.132l-4-4a.802.802 0 0 0-1.132 1.132Z"
      style={
        {
          // fill: "#fff",
        }
      }
      transform="translate(-32 -35.2) scale(2.66667)"
    />
    <path
      d="m23.434 13.434-4 4a.802.802 0 0 0 1.132 1.132l4-4a.802.802 0 0 0-1.132-1.132Z"
      style={
        {
          // fill: "#fff",
        }
      }
      transform="translate(-32 -35.2) scale(2.66667)"
    />
  </svg>
);

export default PhoneOffIcon;
