/* eslint-disable no-restricted-syntax,no-continue,no-lonely-if */
import type { FPMesh, SceneConfig } from '@g360/vt-types';

import {
  createTexture,
  disableVertexAttributes,
  enableVertexAttributes,
  loadShaders,
  setTextureFromImage,
} from '../../../common/webglUtils';
import type FloorPlan3DProgram from '../FloorPlan3DProgram';
import FPWebGLProgram from '../FPWebGLProgram';
import type ModelNavigation from '../ModelNavigation';
import fragmentShader from './solid.fs.glsl';
import vertexShader from './solid.vs.glsl';

class SolidProgram extends FPWebGLProgram {
  sceneConfig?: SceneConfig;
  mainTex: WebGLTexture | null = null;
  depthMapTex: WebGLTexture | null = null;

  private navigation: ModelNavigation;

  constructor(
    gl: WebGL2RenderingContext,
    canvas: HTMLCanvasElement,
    parentProgram: FloorPlan3DProgram,
    navigation: ModelNavigation
  ) {
    super(gl, canvas, parentProgram);
    this.navigation = navigation;
  }

  init(): void {
    super.init(vertexShader, fragmentShader);

    this.vertexAttributes = [this.positionLocation, this.texCoordLocation, this.normalLocation];
    this.gl.vertexAttribPointer(this.positionLocation, 3, this.gl.FLOAT, false, 0, 0);
    this.gl.vertexAttribPointer(this.texCoordLocation, 2, this.gl.FLOAT, false, 0, 0);
    this.gl.vertexAttribPointer(this.normalLocation, 3, this.gl.FLOAT, false, 0, 0);
  }

  loadGeometry(meshes: FPMesh[]): void {
    this.meshes = meshes;

    for (let m = 0; m < meshes.length; m += 1) {
      const mesh = meshes[m];

      if (mesh.dataNum === 0) continue; // skip bad meshes
      if (mesh.isOutline || mesh.isCeiling || mesh.isSideCap || mesh.isWallCap) continue;

      this.goodMeshIds.push(m);
    }
  }

  /**
   * should be called after loadGeometry() since it loads shaders
   */
  setMainTex(mainTextureImage: HTMLImageElement | undefined): void {
    loadShaders(this.gl, this.program);

    if (!mainTextureImage) {
      console.error('SolidProgram::loadTextures:no mainTextureImage', mainTextureImage);
      return;
    }

    this.gl.uniform1i(this.mainTexLocation, 1);
    this.mainTex = createTexture(this.gl);
    setTextureFromImage(this.gl, this.mainTex, mainTextureImage);
  }

  setSunDepthTex(depthMapTexture: WebGLTexture): void {
    loadShaders(this.gl, this.program);
    this.gl.uniform1i(this.shadowMapLocation, 2);
    this.depthMapTex = depthMapTexture;
  }

  draw(): void {
    if (!this.gl) return;

    loadShaders(this.gl, this.program);
    enableVertexAttributes(this.gl, this.vertexAttributes);

    // make sure that LINES don't z-fight with solid geometry, this pushes lines towards camera
    // (actually anything that is not drawn by this shader)
    this.gl.enable(this.gl.POLYGON_OFFSET_FILL);
    this.gl.polygonOffset(1.0, 1.0);

    this.gl.depthMask(true);

    this.gl.uniformMatrix4fv(this.matrixWorldPosLocation, false, this.parentProgram.matrixWorldFloat32Array);
    this.gl.uniformMatrix4fv(this.matrixViewPosLocation, false, this.parentProgram.matrixViewFloat32Array);
    this.gl.uniformMatrix4fv(this.matrixProjectionPosLocation, false, this.parentProgram.matrixProjectionFloat32Array);

    const time = Math.abs(Math.sin(Date.now() * 0.001)); // oscillating between 0 and 1
    this.gl.uniform1f(this.timeLocation, time);

    this.gl.uniform1f(this.sunFarLocation, this.parentProgram.sunFar);
    this.gl.uniform2f(this.sunDepthMapSizeLocation, this.parentProgram.sunDepthMapSizeX, this.parentProgram.sunDepthMapSizeY); // prettier-ignore

    this.gl.uniformMatrix4fv(this.matrixSunWorldPosLocation, false, this.parentProgram.sunMatrixWorldFloat32Array);
    this.gl.uniformMatrix4fv(this.matrixSunViewPosLocation, false, this.parentProgram.sunMatrixViewFloat32Array);
    this.gl.uniformMatrix4fv(this.matrixSunProjectionPosLocation, false, this.parentProgram.sunMatrixProjectionFloat32Array); // prettier-ignore
    this.gl.uniform3fv(this.sunDirectionLocation, this.parentProgram.sun.direction);
    this.gl.uniform3fv(this.lightColorLocation, this.parentProgram.sun.ambientColor);

    this.gl.activeTexture(this.gl.TEXTURE1);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.mainTex);

    this.gl.activeTexture(this.gl.TEXTURE2);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.depthMapTex);

    this.numTrisDrawn = 0;
    // Draw each mesh separately
    for (const meshId of this.goodMeshIds) {
      const mesh = this.meshes[meshId];

      if (mesh.unfocusedRoom) continue; // unfocused rooms will be drawn with transparent shader
      if (mesh.skipSolidRendering) continue;

      if (this.parentProgram.skipMeshes.includes(mesh.unqId)) continue;
      if (this.parentProgram.onlyMeshes.length && !this.parentProgram.onlyMeshes.includes(mesh.unqId)) continue;

      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.parentProgram.positionBuffer);
      this.gl.vertexAttribPointer(this.positionLocation, 3, this.gl.FLOAT, false, 0, mesh.dataOffsetPositions);

      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.parentProgram.texCoordBuffer);
      this.gl.vertexAttribPointer(this.texCoordLocation, 2, this.gl.FLOAT, false, 0, mesh.dataOffsetTexCoords);

      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.parentProgram.normalBuffer);
      this.gl.vertexAttribPointer(this.normalLocation, 3, this.gl.FLOAT, false, 0, mesh.dataOffsetNormals);

      this.gl.drawArrays(this.gl.TRIANGLES, 0, mesh.dataNum);
      this.numTrisDrawn += mesh.dataNum / 3;
    }

    this.gl.disable(this.gl.POLYGON_OFFSET_FILL);
    this.gl.depthMask(true);
    disableVertexAttributes(this.gl, this.vertexAttributes);
  }

  /**
   * @todo -- simplify this method
   *          alt tex is for debug only
   */
  fetchTexture(texturePath: string, texturePathAlt: string): void {
    const fetchAndLoadTexture = (path: string): Promise<void> =>
      fetch(path)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to fetch texture: ${path}`);
          }
          return response.blob();
        })
        .then(
          (blob) =>
            new Promise<void>((resolve, reject) => {
              const image = new Image();
              image.src = URL.createObjectURL(blob);
              image.onload = () => {
                this.setMainTex(image);
                resolve();
              };
              image.onerror = () => {
                reject(new Error(`Failed to load image: ${path}`));
              };
            })
        );

    fetchAndLoadTexture(texturePath)
      .catch(() => {
        console.warn(`Failed to fetch primary texture: ${texturePath}. Trying alternative...`);
        return fetchAndLoadTexture(texturePathAlt);
      })
      .catch((error) => {
        console.error('Failed to fetch both primary and alternative textures:', error);
      });
  }

  destroy(): void {
    super.destroy();

    if (!this.mainTex) return;

    this.gl.deleteTexture(this.mainTex);
    this.mainTex = null;
  }
}

export default SolidProgram;
