/**
 * Functions copied from original library with minor refactoring.
 * https://github.com/jpuri/draftjs-to-html/commit/c02452e3ce6719f84f38d39f8f9b0a9e32bf871f
 */
/**
 * Determines if the string passed to it has no content.
 * @param stringToCheck - `string`: String to check.
 * @returns `boolean`: Whether the string is empty.
 */
function isEmptyString(stringToCheck) {
    if (!stringToCheck)
        return true;
    if (stringToCheck.trim().length === 0)
        return true;
    return false;
}
export default isEmptyString;
