import type { Milliseconds } from '@g360/vt-types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GenericFunction<TFunc extends (...args: any) => any> = (...args: Parameters<TFunc>) => ReturnType<TFunc>;

export type DebouncedFunction<TFunc extends GenericFunction<TFunc>> = TFunc & {
  cancel: () => void;
  flush: () => ReturnType<TFunc> | undefined;
  isOnCooldown: () => boolean;
  isPending: () => boolean;
};

export type DebounceOptions = {
  leading?: boolean;
  trailing?: boolean;
};

/**
 * Creates a debounced version of a function.
 *
 * When the function is invoked, it is always given `this` and `args` from the last debounced function call.
 *
 * When called: If the function is not on a cooldown and `leading = true`, invokes the function immediately and starts the cooldown timer.
 *
 * When called: If the function is on a cooldown, resets the cooldown timer.
 *
 * When the cooldown timer expires, if `trailing = true`, invokes the function.
 *
 * **Methods:**
 * - `cancel()` - `void`: cancels the pending invocation of the debounced function, if any, and destroys the cooldown timer.
 * - `flush()` - `ReturnType<TFunc>`: immediately invokes the debounced function, if there were any pending invocations, and destroys the cooldown timer.
 * - `isOnCooldown()` - `boolean`: returns `true` if the debounced function is on cooldown.
 * - `isPending()` - `boolean`: returns `true` if the debounced function is set to invoke.
 *
 * @example
 * const sayHello = (name: string) => console.log(`Hello, ${name}!`);
 * const debouncedSayHello = debounce(sayHello, 200);
 *
 * debouncedSayHello("John");
 * debouncedSayHello("Jane");
 * // => Only the second invocation of `debouncedSayHello` is executed, after a delay of 200ms.
 * @param functionToDebounce `TFunc`: The function to debounce.
 * @param cooldown `Milliseconds`: How long to debounce `functionToDebounce` for.
 * @param debounceOptions `DebounceOptions`: Additional options.
 * @param debounceOptions.leading `boolean`: If `true`, `functionToDebounce` is called on the leading edge of the cooldown, aka the first call. Default value is: `false`.
 * @param debounceOptions.trailing `boolean`: If `true`, `functionToDebounce` is called on the trailing edge of the cooldown. Default value is: `true`.
 * @returns - `DebouncedFunction<TFunc`: A debounced version of `functionToDebounce` with `cancel`, `flush`, `isOnCooldown` and `isPending` methods.
 */
export default function debounce<TFunc extends GenericFunction<TFunc>>(
  functionToDebounce: TFunc,
  cooldown: Milliseconds = 0,
  debounceOptions?: DebounceOptions
): DebouncedFunction<TFunc> {
  let leading = false;
  let trailing = true;

  let lastThis: unknown;
  let lastArgs: Parameters<TFunc> | undefined;

  let result: ReturnType<TFunc> | undefined;

  let timeoutId: ReturnType<typeof setTimeout> | undefined;
  let lastCallTime: Milliseconds | undefined;
  let lastInvokeTime: Milliseconds | undefined;

  if (debounceOptions) {
    leading = debounceOptions.leading ?? leading;
    trailing = debounceOptions.trailing ?? trailing;
  }

  function invoke(now: Milliseconds): ReturnType<TFunc> | undefined {
    lastInvokeTime = now;

    if (lastArgs === undefined) {
      result = functionToDebounce.apply(lastThis);
      return result;
    }

    result = functionToDebounce.apply(lastThis, lastArgs);
    return result;
  }

  function onTimerExpired(): ReturnType<TFunc> | undefined {
    timeoutId = undefined;

    if (lastCallTime === lastInvokeTime) return result;

    if (!trailing) return result;

    return invoke(Date.now());
  }

  function debounced(this: unknown, ...args: Parameters<TFunc>): ReturnType<TFunc> | undefined {
    lastCallTime = Date.now();

    lastThis = this;
    lastArgs = args;

    // First call
    if (!timeoutId) {
      // First call, invoke because leading is true
      if (leading) {
        timeoutId = setTimeout(onTimerExpired, cooldown);
        return invoke(lastCallTime);
      }
    }

    // Consequent call or first call with leading = false
    clearTimeout(timeoutId);
    timeoutId = setTimeout(onTimerExpired, cooldown);
    return result;
  }

  debounced.cancel = (): void => {
    clearTimeout(timeoutId);
    timeoutId = undefined;

    lastInvokeTime = undefined;
    lastCallTime = undefined;

    lastThis = undefined;
    lastArgs = undefined;
  };

  // Arrow functions can't have `this` as a parameter
  // eslint-disable-next-line func-names
  debounced.flush = function (this: unknown, ...args: Parameters<TFunc>): ReturnType<TFunc> | undefined {
    clearTimeout(timeoutId);
    timeoutId = undefined;

    if (lastInvokeTime !== lastCallTime) return result;

    lastThis = this;
    lastArgs = args;

    return invoke(Date.now());
  };

  // eslint-disable-next-line arrow-body-style
  debounced.isOnCooldown = (): boolean => {
    return timeoutId !== undefined;
  };

  debounced.isPending = (): boolean => {
    if (lastCallTime === undefined) return false;
    if (timeoutId === undefined) return false;
    if (lastInvokeTime !== undefined && lastCallTime > lastInvokeTime) return true;
    return true;
  };

  return debounced as DebouncedFunction<TFunc>;
}
