import { EndSessionModal, LinkButton } from "components/elements";
import { ErrorIcon, SandClockIcon, ThreeDotsIcon } from "components/icons";
import { InfoPageLayout, LoadingPageLayout } from "components/layout";
import {
  ParticipantGridWrapper,
  ParticipantSettingsContainer,
  VirtualTourWrapper,
} from "components/wrappers";
import {
  useAppStateContext,
  useDeviceStateContext,
  useParticipants,
  useRoomContext,
  useToasts,
  useTourControl,
} from "hooks";
import { FC, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { classNamesUtil } from "utils/classNamesUtil";
import InfoButton from "./components/InfoButton";
import FullScreenButton from "./components/FullScreenButton";

const VideoRoom: FC = () => {
  const { isHost, roomName, userName, token, userTwilioIdentity } =
    useAppStateContext();
  const { setActiveMediaErrorModal, audioAccess, videoAccess } =
    useDeviceStateContext();
  const { isConnecting, twilioConnect } = useRoomContext();

  const [isMinimized, setIsMinimized] = useState<boolean>(false);

  const [isSettingsModalVisible, setIsSettingsModalVisible] =
    useState<boolean>(false);
  const [isEndSessionModalVisible, setIsEndSessionModalVisible] =
    useState<boolean>(false);

  const [infoModalOpen, setInfoModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (token) {
      twilioConnect();
    }
  }, [token]);

  const { inControlParticipant, handleControlSwitch } = useTourControl();

  const {
    needApprovalUserIdentities,
    allowedRemoteParticipants,
    isAllowedInRoom,
    roomHostIdentity,
    approveParticipant,
    declineParticipant,
    handleEndMeeting,
  } = useParticipants();

  useToasts(
    inControlParticipant,
    needApprovalUserIdentities,
    approveParticipant,
    declineParticipant
  );

  if (isConnecting) {
    return (
      <LoadingPageLayout
        icon={<ThreeDotsIcon className=" fill-black" />}
        headLine="Loading..."
        paragraph="Please do not refresh this page"
      />
    );
  }

  if (!isHost && !isAllowedInRoom) {
    return (
      <InfoPageLayout
        icon={<SandClockIcon className="w-14 h-14" />}
        headLine="Please wait, the host will let you in soon"
        btn={
          <LinkButton
            text="Go back to lobby"
            handleButtonClick={() => {
              window.location.replace(
                `/${roomName}?name=${userName}`
              );
            }}
          />
        }
      />
    );
  }

  if (isHost || isAllowedInRoom) {
    return (
      <div className="overflow-hidden relative w-full h-full">
        <ToastContainer
          closeOnClick={false}
          toastClassName={() =>
            "flex justify-center overflow-hidden my-1.5 mx-auto rounded-md max-w-[393px]"
          }
        />

        <div
          className={classNamesUtil(
            isMinimized ? "-translate-y-[97%]" : "top-2.5",
            "right-3 absolute z-10 rounded-md w-[250px] max-h-3/4 bg-minimap-background bg-opacity-70 transition-all duration-300 ease-in-out transform-gpu"
          )}
        >
          <div className="flex flex-col">
            <ParticipantGridWrapper
              isMinimized={isMinimized}
              toggleMinimize={() => setIsMinimized((state) => !state)}
              roomHostIdentity={roomHostIdentity}
              remoteParticipants={allowedRemoteParticipants}
              inControlParticipant={inControlParticipant}
              handleSettingModal={() => {
                if (audioAccess || videoAccess) {
                  setIsSettingsModalVisible((state) => !state);
                  return;
                }
                setActiveMediaErrorModal("microphone");
              }}
              handleEndSessionModal={() =>
                setIsEndSessionModalVisible((state) => !state)
              }
              handleClickControlSwitch={handleControlSwitch}
            />
          </div>
          <div className="absolute top-full pt-2.5 w-full flex justify-end pointer-events-none">
            <InfoButton
              onClick={() => {
                setInfoModalOpen((prevState) => !prevState);
              }}
            />
            <FullScreenButton />
          </div>
        </div>

        <EndSessionModal
          isEndSessionModalVisible={isEndSessionModalVisible}
          handelCloseModal={() => setIsEndSessionModalVisible(false)}
          handelEndSession={(shouldDeleteLink) => {
            if (isHost) {
              handleEndMeeting(shouldDeleteLink);
              setIsEndSessionModalVisible(false);
            } else {
              window.location.replace(
                `/${roomName}?name=${userName}&rejoin=true`
              );
            }
          }}
        />

        <ParticipantSettingsContainer
          isSettingsModalVisible={isSettingsModalVisible}
          handleSettingsModalClose={() => setIsSettingsModalVisible(false)}
        />

        <VirtualTourWrapper
          isInControlOfTour={inControlParticipant === userTwilioIdentity}
          infoModalOpen={infoModalOpen}
          onInfoModalToggle={(isOpen) => setInfoModalOpen(isOpen)}
        />
      </div>
    );
  }

  return (
    <InfoPageLayout
      icon={<ErrorIcon className="w-[4.6rem] h-[4.6rem]" />}
      headLine="Something went wrong"
    />
  );
};

export default VideoRoom;
