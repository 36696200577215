import type { Pixel, Pos, ScenePos, Size } from '@g360/vt-types';

import { toDeg, toRad } from '../../Angle';

export default function pixelToCamera(point: Pos<Pixel>, canvasSize: Size, cameraPos: Required<ScenePos>): ScenePos {
  const { pitch: currentPitch, yaw: currentYaw, fov } = cameraPos;
  const spx = (point.x / canvasSize.width) * 2 - 1;
  const spy = ((1 - (point.y / canvasSize.height) * 2) * canvasSize.height) / canvasSize.width;

  const focal = 1 / Math.tan((toDeg(fov) * Math.PI) / 360);
  const s = Math.sin((toDeg(currentPitch) * Math.PI) / 180);
  const c = Math.cos((toDeg(currentPitch) * Math.PI) / 180);
  const a = focal * c - spy * s;
  const root = Math.sqrt(spx * spx + a * a);
  const pitch = (Math.atan((spy * c + focal * s) / root) * 180) / Math.PI;

  const yaw = (Math.atan2(spx / root, a / root) * 180) / Math.PI + toDeg(currentYaw);

  return { pitch: toRad(pitch), yaw: toRad(yaw) };
}
