import type { TourConfig } from '@g360/vt-types';
import cloneDeep from 'lodash/cloneDeep';

import { flattenSubScenes } from './flattenSubScenes';
import { insertSubScenes } from './insertSubScenes';
import { rerouteSubSceneHotSpots } from './rerouteSubSceneHotSpots';

/**
 * Convert array of sceneGroups to internal state structure of subScenes nested in scenes, without mutating the given tourConfig
 * @returns A new tourConfig with subScenes inserted
 */
export function sceneGroups2SubScenes(
  tourConfig: TourConfig,
  options?: {
    /** The given TourConfig already has subScenes and the new sceneGroups have different main scenes */
    newSceneGroupOrder?: boolean;
  }
): TourConfig {
  // Deep clone to avoid mutating the given config
  const clonedConfig = cloneDeep(tourConfig);

  if (clonedConfig.sceneGroups) {
    const firstSceneInGroup = clonedConfig.sceneGroups.findIndex((group) =>
      group.some((scene) => scene === tourConfig.firstScene)
    );

    if (firstSceneInGroup !== -1) {
      // If first scene is a subScene, change it to the main scene of that scene group
      clonedConfig.firstScene = clonedConfig.sceneGroups[firstSceneInGroup][0];
    }
  }

  if (options?.newSceneGroupOrder) flattenSubScenes(clonedConfig);

  const withSubScenes = insertSubScenes(clonedConfig);

  const withReroutedHotSpots = rerouteSubSceneHotSpots(withSubScenes);

  return withReroutedHotSpots;
}
