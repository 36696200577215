import { useAppStateContext, useRoomContext } from "hooks";
import { useCallback, useEffect, useState } from "react";

const useTourControl = () => {
  const { isHost } = useAppStateContext();
  const { twilioMessageEmitter, localParticipant } = useRoomContext();

  const [inControlParticipant, setInControlParticipant] = useState<string>("");

  // Host set hes inControlParticipant and sends it to all guests
  const handleControlSwitch = useCallback(
    (identity?: string) => {
      if (!identity) return;
      setInControlParticipant(identity);
      twilioMessageEmitter.emit("tour.control.send", identity);
    },
    [twilioMessageEmitter]
  );

  const handleInitialControlState = useCallback(
    (data: any) => {
      if (!inControlParticipant) {
        setInControlParticipant(data.roomHostIdentity);
      }
    },
    [inControlParticipant]
  );

  useEffect(() => {
    const subscriptions = [
      twilioMessageEmitter.subscribe(
        "tour.control.receive",
        setInControlParticipant
      ),
      twilioMessageEmitter.subscribe(
        "host.info.receive",
        handleInitialControlState
      ),
    ];

    return () =>
      subscriptions.forEach((subscription) => subscription.unsubscribe());
  }, [twilioMessageEmitter, handleInitialControlState]);

  useEffect(() => {
    if (localParticipant && isHost) {
      setInControlParticipant(localParticipant.identity);
    }
  }, [localParticipant, isHost]);

  return {
    inControlParticipant,
    handleControlSwitch,
  };
};

export default useTourControl;
