import type { AnalyticsEvent, Milliseconds, Subscription } from '@g360/vt-types';

import type { AnalyticsEventDetailsMeasureToolLoad, AnalyticsEventDetailsSceneLoad } from '..';
import type Analytics from '../Analytics';

/**
 * A helper class that handles load events and time-related things like loading time, idle time, active time.
 * On the first scene load it generates a load event without previous scene info.
 * Idle time is min `10000 ms` without any analytics events.
 */
export default class AnalyticsLoadingHelper {
  /** A threshold time which has to pass after the last event to consider the passed time as idle. */
  private static readonly IDLE_TIME_THRESHOLD: Milliseconds = 10000;

  /** An instance of the main `Analytics` class. */
  private analytics: Analytics;

  /** A timestamp when the previous scene finished loading. */
  private previousSceneLoadingEndTime: Milliseconds = 0;

  /** A timestamp when a new scene started loading. */
  private newSceneLoadingStartTime: Milliseconds = 0;
  /** A timestamp when a new scene finished loading */
  private newSceneLoadingEndTime: Milliseconds = 0;

  /** A timestamp when measure tool started loading. */
  private measureToolLoadingStartTime: Milliseconds = 0;

  /** A timestamp when the last analytics event happened. */
  private lastEventTime: Milliseconds = 0;
  /** A duration of how long there was no new analytics events. */
  private idleTime: Milliseconds = 0;

  /** Determines if the first scene loading is handled. Needed because we don't have previous scene info to send on the first scene load. */
  private isFirstSceneLoadHandled = false;
  /** Determines if the engine is currently loading the measure tool. */
  private isMeasureToolLoading = false;

  /** A list of external pubsub event subscriptions. */
  private subscriptions: Subscription[] = [];

  /**
   * @param analytics - `Analytics`: an instance of the main Analytics class.
   */
  constructor(analytics: Analytics) {
    this.analytics = analytics;

    this.onAnalyticsActivated = this.onAnalyticsActivated.bind(this);
    this.onAnalyticsDeactivated = this.onAnalyticsDeactivated.bind(this);
    this.onAnalyticsEvent = this.onAnalyticsEvent.bind(this);

    this.onMeasureToolLoadStart = this.onMeasureToolLoadStart.bind(this);
    this.onMeasureToolLoaded = this.onMeasureToolLoaded.bind(this);
    this.onMeasureToolLoadError = this.onMeasureToolLoadError.bind(this);

    this.onSceneLoadStart = this.onSceneLoadStart.bind(this);
    this.onSceneLoaded = this.onSceneLoaded.bind(this);

    analytics.pubSub.subscribe('analytics.activated', this.onAnalyticsActivated);
    analytics.pubSub.subscribe('analytics.deactivated', this.onAnalyticsDeactivated);
    analytics.pubSub.subscribe('analytics.event', this.onAnalyticsEvent);
  }

  /**
   * Adds an event subscription to the list of subscriptions.
   * @param subscription - `Subscription`: an event subscription to be added to the list of subscriptions.
   * @returns `void`
   */
  public addSubscription(subscription: Subscription): void {
    this.subscriptions.push(subscription);
  }

  /**
   * Unsubscribes from all event subscriptions and clears the list of subscriptions.
   * @returns `void`
   */
  public unsubscribeAll(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

  /**
   * An event that is triggered when an analytics event is generated.
   * @param event - `AnalyticsEvent<TDetails>`: analytics event object. Used to register the event timestamp and calculated idle time.
   * @returns `Promise<void>`
   */
  public async onAnalyticsEvent<TDetails>(event: AnalyticsEvent<TDetails>): Promise<void> {
    if (!this.analytics.isActive) return;

    const timeSinceLastEvent = event.timestamp - this.lastEventTime;
    this.lastEventTime = event.timestamp;

    if (timeSinceLastEvent > AnalyticsLoadingHelper.IDLE_TIME_THRESHOLD) this.idleTime += timeSinceLastEvent;
  }

  /**
   * An event that is triggered when the measure tool is toggled on and the loading starts.
   * @returns `Promise<void>`
   */
  public async onMeasureToolLoadStart(): Promise<void> {
    if (!this.analytics.isActive) return;

    this.isMeasureToolLoading = true;
    this.measureToolLoadingStartTime = Date.now();
  }

  /**
   * An event that is triggered when the measure tool is done loading.
   * @returns `Promise<void>`
   */
  public async onMeasureToolLoaded(): Promise<void> {
    if (!this.analytics.isActive) return;

    const measureToolLoadingEndTime: Milliseconds = Date.now();
    const measureToolLoadingTime: Milliseconds = measureToolLoadingEndTime - this.measureToolLoadingStartTime;

    this.analytics.push<AnalyticsEventDetailsMeasureToolLoad>('load', 'MSR', 'Scene', {
      scene_id: this.analytics.getCurrentSceneId(),
      loading_t: measureToolLoadingTime,
    });
  }

  /**
   * An event that is triggered when the measure tool loading throws an error.
   * @returns `Promise<void>`
   */
  public async onMeasureToolLoadError(): Promise<void> {
    this.isMeasureToolLoading = false;
  }

  /**
   * An event that is triggered when the scene loading starts.
   * @returns `Promise<void>`
   */
  public async onSceneLoadStart(): Promise<void> {
    if (!this.analytics.isActive) return;

    this.newSceneLoadingStartTime = Date.now();
  }

  /**
   * An event that is triggered when the scene is done loading.
   * @returns `Promise<void>`
   */
  public async onSceneLoaded(): Promise<void> {
    // This event is triggered right after `onMeasureToolLoaded` event so we have to skip this call.
    if (this.isMeasureToolLoading) {
      this.isMeasureToolLoading = false;
      return;
    }

    if (!this.analytics.isActive) return;

    this.previousSceneLoadingEndTime = this.newSceneLoadingEndTime;
    this.newSceneLoadingEndTime = Date.now();

    const newSceneLoadingTime: Milliseconds = this.newSceneLoadingEndTime - this.newSceneLoadingStartTime;

    const eventDetails: AnalyticsEventDetailsSceneLoad = {
      scene_id: this.analytics.getCurrentSceneId(),
      loading_t: newSceneLoadingTime,
    };

    if (this.isFirstSceneLoadHandled) {
      this.isFirstSceneLoadHandled = false;
    } else {
      const previousSceneTime: Milliseconds = this.newSceneLoadingStartTime - this.previousSceneLoadingEndTime;
      const previousSceneActiveTime: Milliseconds = previousSceneTime - this.idleTime;

      eventDetails.prev_scene_active_t = previousSceneActiveTime;
      eventDetails.prev_scene_idle_t = this.idleTime;
    }

    this.analytics.push<AnalyticsEventDetailsSceneLoad>('load', 'VT', 'Scene', eventDetails);
    this.idleTime = 0;
  }

  /**
   * An event that is triggered when analytics is activated.
   * @returns `Promise<void>`
   */
  private async onAnalyticsActivated(): Promise<void> {
    const currentTime: Milliseconds = Date.now();

    this.previousSceneLoadingEndTime = currentTime;
    this.newSceneLoadingEndTime = currentTime;
    this.lastEventTime = currentTime;
  }

  /**
   * An event that is triggered when analytics is deactivated.
   * @returns `Promise<void>`
   */
  private async onAnalyticsDeactivated(): Promise<void> {
    this.isFirstSceneLoadHandled = false;
    this.isMeasureToolLoading = false;
  }
}
