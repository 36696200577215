import { toDeg } from '../math';
import type { HotSpotGraph } from './HotSpotGraph';
import type { Position3, Scenes } from './types';

const calculatePitchDeg = (camera: Position3, point: Position3) => {
  const dx = point[0] - camera[0];
  const dy = point[1] - camera[1];
  const dz = point[2] - camera[2];

  const horizontalDistance = Math.sqrt(dx * dx + dy * dy);
  const pitch = Math.atan2(dz, horizontalDistance);

  return toDeg(pitch);
};

/**
 * Fix hotspots that are in the nadir (too low to be visible in camera)
 * by putting them at camera height
 */
const fixHotSpotsInNadir = (graph: HotSpotGraph, scenes: Scenes) => {
  let numFixed = 0;

  Object.keys(scenes).forEach((key) => {
    const scene = scenes[key];
    const scenePos = graph.nodes.get(key)?.position;
    if (!scenePos) return;
    const cameraPos = [scenePos[0], scenePos[1], 0] as Position3;
    scene.hotSpots.forEach((hotSpot) => {
      const pitch = calculatePitchDeg(cameraPos, hotSpot.pos as Position3);
      if (pitch < -55) {
        // eslint-disable-next-line no-param-reassign
        hotSpot.pos = [hotSpot.pos[0], hotSpot.pos[1], 0];
        numFixed += 1;
      }
    });
  });

  console.log(numFixed, 'hotspots fixed in nadir');
};

export const visuallyFixHotSpots = (graph: HotSpotGraph, scenes: Scenes) => {
  fixHotSpotsInNadir(graph, scenes);

  return scenes;
};
