import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useMemo } from 'react';
import useFullScreen from '../hooks/useFullscreen';
import useCanvasSize from '../hooks/useNavigationSize';
import getFloorplanImageZoomInFactor from '../utils/navigationHelpers';
const GlobalContext = createContext(null);
const floorplanImageZoomInFactor = getFloorplanImageZoomInFactor();
export const GlobalsContextProvider = ({ children, engine, assetConfig, hostRootRef, appContext, analytics, }) => {
    const { isFullscreen } = useFullScreen();
    const { squareSize, setSquareSize, navRadius } = useCanvasSize();
    const globalContext = useMemo(() => ({
        engine,
        assetConfig,
        isFullscreen,
        squareSize,
        navRadius,
        setSquareSize,
        floorplanImageZoomInFactor,
        hostRootRef,
        appContext,
        analytics,
    }), [analytics, appContext, assetConfig, engine, hostRootRef, isFullscreen, squareSize, setSquareSize, navRadius]);
    return _jsx(GlobalContext.Provider, { value: globalContext, children: children });
};
export default function useGlobalContext() {
    const context = useContext(GlobalContext);
    if (!context)
        throw new Error('useGlobalContext must be used within a GlobalsContextProvider');
    return context;
}
