var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ErrorLogger, MakeReactive, ReactiveBase } from '@g360/vt-utils/';
import urljoin from 'url-join';
import LibPhoneNumber from './LibPhoneNumber';
import PhoneCodes from './PhoneCodes';
class PhoneNumberServiceBase extends ReactiveBase {
    constructor(assetConfig, dataset = 'min') {
        super();
        /** @note kept in instance level so it is easier to access it in the react component */
        this.defaultLocation = {
            countryCode: 'GB',
            callingCode: '44',
        };
        this.phoneServiceReady = false;
        /** Helper class to handle phone-codes.json data and flag paths */
        this.phoneCodes = null;
        this.preferredCountry = null;
        this.libPhoneNumber = null;
        this.urls = {
            libMetadata: urljoin(assetConfig.assetPath, 'phone', `metadata.${dataset}.json`),
            phoneCodes: urljoin(assetConfig.assetPath, 'phone', 'phone-codes.json'),
            flags: urljoin(assetConfig.assetPath, 'images', 'flags'),
        };
    }
    initService() {
        return __awaiter(this, void 0, void 0, function* () {
            const libMetadata = yield this.downloadJsonData(this.urls.libMetadata, 3);
            const phoneCodesJson = yield this.downloadJsonData(this.urls.phoneCodes, 3);
            if (libMetadata) {
                this.libPhoneNumber = new LibPhoneNumber(libMetadata);
            }
            if (phoneCodesJson) {
                this.phoneCodes = new PhoneCodes(phoneCodesJson, this.urls.flags);
            }
            this.phoneServiceReady = true;
        });
    }
    setPreferredCountry(countryCode) {
        this.preferredCountry = countryCode;
    }
    getPreferredCallingCode() {
        var _a, _b;
        if (!this.preferredCountry)
            return null;
        return (_b = (_a = this.libPhoneNumber) === null || _a === void 0 ? void 0 : _a.getCountryCallingCode(this.preferredCountry)) !== null && _b !== void 0 ? _b : null;
    }
    /** Use cached value from geoLocation if available or fall back to default value */
    getFallbackCountryCode() {
        if (PhoneNumberServiceBase.geoLocationCache)
            return PhoneNumberServiceBase.geoLocationCache.countryCode;
        return this.defaultLocation.countryCode;
    }
    removePreferredCountry() {
        this.preferredCountry = null;
    }
    /** Get CountryCode, flag and formatted phone number string  */
    parsePhoneData(phoneNumber) {
        const fallbackResult = {
            formattedNumber: phoneNumber.replace(/[^0-9+]/g, ''),
            countryCode: this.preferredCountry || this.getFallbackCountryCode(),
        };
        if (!this.libPhoneNumber || !this.phoneCodes)
            return fallbackResult;
        let countryData;
        const libFormatted = this.libPhoneNumber.formatPhoneNumber(phoneNumber);
        const { countryCode, formattedNumber } = libFormatted;
        if (this.preferredCountry) {
            // If user has selected a country from the dropdown, use that country
            countryData = this.phoneCodes.getCountryData(this.preferredCountry);
        }
        else if (countryCode) {
            // Country code if found by libphonenumber-js, use that country
            countryData = this.phoneCodes.getCountryData(countryCode);
        }
        // libphonenumber-js parser worked so use the result + additional data from phoneCodes
        if (countryData) {
            return {
                formattedNumber,
                countryCode: countryData.countryCode,
                countryFlag: countryData.flagUrl,
            };
        }
        // If the number is not entered, return the fallbackResult
        if (formattedNumber === '+')
            return fallbackResult;
        // libphonenumber-js doesn't always return data, try to get the data from phoneCodes
        // This happens only if no preferred country is set by the user (selected from dropdown) and we need to guess the country
        // This also handles the case when multiple countries can match the same calling code, in that case use the priority list
        const possibleCountryCodes = this.phoneCodes.getCountryCodesForCallingCode(phoneNumber);
        // If we have multiple countries with the same calling code, use the priority list to get the first match
        if (possibleCountryCodes === null || possibleCountryCodes === void 0 ? void 0 : possibleCountryCodes.length) {
            const priorityCountryCode = possibleCountryCodes.find((cc) => PhoneNumberServiceBase.countryPriorityList.includes(cc));
            countryData = this.phoneCodes.getCountryData(priorityCountryCode || possibleCountryCodes[0]);
        }
        if (countryData) {
            return {
                formattedNumber,
                countryFlag: countryData.flagUrl,
                countryCode: countryData.countryCode,
            };
        }
        return fallbackResult;
    }
    /** Use libphonenumber-js to validate the phone number, this should be used as final validation, not as you type parsing */
    validatePhoneNumber(phoneNumber) {
        var _a;
        return ((_a = this.libPhoneNumber) === null || _a === void 0 ? void 0 : _a.validatePhoneNumber(phoneNumber)) || null;
    }
    /** Use backend api to get location by ip, fall back to default location if fails */
    getCountryCallingCodeByGeoLocation() {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            const geoLocationApi = (_a = process.env.REACT_APP_GEO_LOCATION_API_ENDPOINT) !== null && _a !== void 0 ? _a : null;
            if (!geoLocationApi)
                return this.defaultLocation;
            if (PhoneNumberServiceBase.geoLocationCache) {
                return PhoneNumberServiceBase.geoLocationCache;
            }
            // Try to get the local country code from our backend
            try {
                const response = yield fetch(geoLocationApi);
                const data = yield response.json();
                const isoCode = (_c = (_b = data === null || data === void 0 ? void 0 : data.client) === null || _b === void 0 ? void 0 : _b.country) === null || _c === void 0 ? void 0 : _c.iso_code;
                if (isoCode) {
                    const callingCode = (_d = this.libPhoneNumber) === null || _d === void 0 ? void 0 : _d.getCountryCallingCode(isoCode);
                    if (callingCode) {
                        const result = { callingCode, countryCode: isoCode };
                        PhoneNumberServiceBase.geoLocationCache = result;
                        return result;
                    }
                }
            }
            catch (e) {
                // eslint-disable-next-line no-console
                console.warn(e);
            }
            return this.defaultLocation;
        });
    }
    downloadJsonData(path, retry) {
        return __awaiter(this, void 0, void 0, function* () {
            if (PhoneNumberServiceBase.jsonCache.has(path))
                return PhoneNumberServiceBase.jsonCache.get(path);
            try {
                const response = yield fetch(path);
                const jsonData = yield response.json();
                PhoneNumberServiceBase.jsonCache.set(path, jsonData);
                return jsonData;
            }
            catch (error) {
                if (retry > 0) {
                    return this.downloadJsonData(path, retry - 1);
                }
                const msg = 'Failed to download PhoneNumberService json data';
                // eslint-disable-next-line no-console
                console.error(msg, path);
                // eslint-disable-next-line no-console
                console.error(error);
                ErrorLogger.captureMessage(msg);
                return null;
            }
        });
    }
}
PhoneNumberServiceBase.jsonCache = new Map();
PhoneNumberServiceBase.geoLocationCache = null;
PhoneNumberServiceBase.countryPriorityList = ['US', 'GB', 'FI', 'AU', 'NO'];
export default class PhoneNumberService extends MakeReactive(PhoneNumberServiceBase, [
    'phoneServiceReady',
    'phoneCodes',
]) {
}
