var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
// eslint-disable-next-line import/no-unresolved, import/extensions
import 'overlayscrollbars/overlayscrollbars.css';
import './styles/overlay-scrollbars.css';
import Engine, { TourConfigService } from '@g360/vt-engine';
import { ErrorLogger, readProjectUrl } from '@g360/vt-utils';
import noop from 'lodash/noop';
import { useEffect, useMemo, useState } from 'react';
import { CookieConsentContextProvider } from './contexts/useCookieConsentContext';
import { GlobalsContextProvider } from './contexts/useGlobalContext';
import { curriedTranslate, fetchTranslations, getLocaleString, LocalizationContext, } from './contexts/useLocalizationContext';
import { ServicesContextProvider } from './contexts/useServices';
import useFontFaces from './hooks/useFontFaces';
import Layout from './modules/layout';
import GatedTourService from './services/GatedTourService';
import LayoutService from './services/LayoutService';
import NavigationService from './services/NavigationService';
import PhoneNumberService from './services/PhoneNumberService';
import applyMods from './utils/applyMods';
import setCssAccentColorVariable from './utils/setCssAccentColorVariable';
const Skin = ({ tourConfig, assetConfig, canvasElem, appContext, language, requestedLsfMode = 'off', noWelcomeScreen = false, onInfoModalToggle, projectUrl, noCookieConsent = false, onCookiePreferencesConfirmed, hostRootRef, noHotSpot, noFullScreenOption, infoModalOpen, onHotSpotEditClick, onSelectHotSpot, playerMods: skinMods, onEngineReady, onEngineError, onEngineDestroy, onTourConfigReady, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
analytics, disableAltPano, tourVariant, isSentryEnabled = false, }) => {
    const [readyToRender, setReadyToRender] = useState(false);
    const [appState, setAppState] = useState(null);
    const [localization, setLocalization] = useState();
    const [localizationReady, setLocalizationReady] = useState(false);
    const [fontsReady, setFontsReady] = useState(false);
    // Preload necessary fonts before engine initialization
    useFontFaces([
        { family: 'Open Sans', weight: 400, style: 'normal' },
        { family: 'Gilroy', weight: 400, style: 'normal' },
        { family: 'Gilroy', weight: 500, style: 'normal' },
        { family: 'Gilroy', weight: 700, style: 'normal' },
    ], () => setFontsReady(true));
    const localizationContext = useMemo(() => ({
        translations: localization,
        localeString: getLocaleString(language),
    }), [localization, language]);
    const constructEngine = (engineProps) => {
        try {
            let engine = new Engine(engineProps);
            engine.setAppContext(appContext);
            // Apply mods
            if (skinMods === null || skinMods === void 0 ? void 0 : skinMods.engine)
                engine = applyMods(skinMods.engine, engine);
            return engine;
        }
        catch (err) {
            if (onEngineError)
                onEngineError(err);
            return null;
        }
    };
    const constructTourConfigService = () => __awaiter(void 0, void 0, void 0, function* () {
        let tourConfigService = new TourConfigService(tourConfig, assetConfig, {
            requestedLsfMode,
            tourVariant,
        });
        yield tourConfigService.loadFeatures();
        if (noWelcomeScreen) {
            tourConfigService.tourWelcomeScreen = false;
        }
        // Apply mods
        if (skinMods === null || skinMods === void 0 ? void 0 : skinMods.tourConfigService)
            tourConfigService = applyMods(skinMods.tourConfigService, tourConfigService);
        return tourConfigService;
    });
    const initTourConfigService = (engine, tourConfigService) => __awaiter(void 0, void 0, void 0, function* () {
        tourConfigService.setEngine(engine);
        // Add share link if provided to the tour config, which then will be used in the info modal share section
        // Or read from og:url html meta tag with a fallback to window.location.href
        tourConfigService.setProjectUrl(projectUrl || readProjectUrl());
        return tourConfigService;
    });
    const constructNavigationService = (parsedConfig) => {
        let navigationService = new NavigationService(parsedConfig);
        // Apply mods
        if (skinMods === null || skinMods === void 0 ? void 0 : skinMods.navigationService)
            navigationService = applyMods(skinMods.navigationService, navigationService);
        return navigationService;
    };
    const initNavigationService = (engine, navigationService) => {
        navigationService.setEngine(engine);
        navigationService.init();
    };
    const constructLayoutService = () => {
        let layoutService = new LayoutService(canvasElem, appContext);
        // Apply mods
        if (skinMods === null || skinMods === void 0 ? void 0 : skinMods.layoutService)
            layoutService = applyMods(skinMods.layoutService, layoutService);
        return layoutService;
    };
    const initLayoutService = (engine, layoutService_, tourConfigService) => {
        layoutService_.setEngine(engine);
        if (!tourConfigService.tourWelcomeScreen) {
            layoutService_.isWelcomeScreenOpen = false;
        }
        layoutService_.startCanvasAnimation();
        layoutService_.init();
    };
    const initGatedTourService = (engine, gatedTourService, tourConfigService, layoutService) => __awaiter(void 0, void 0, void 0, function* () {
        gatedTourService.setEngine(engine);
        gatedTourService.setTourConfigService(tourConfigService);
        gatedTourService.setLayoutService(layoutService);
        yield gatedTourService.init();
    });
    const constructPhoneNumberService = () => {
        let phoneNumberService = new PhoneNumberService(assetConfig);
        // Apply mods
        if (skinMods === null || skinMods === void 0 ? void 0 : skinMods.phoneNumberService)
            phoneNumberService = applyMods(skinMods.phoneNumberService, phoneNumberService);
        return phoneNumberService;
    };
    // eslint-disable-next-line arrow-body-style
    const constructGatedTourService = () => {
        return new GatedTourService();
    };
    /**
     * Initialization for engine and services.
     * Because of the dependencies this is still quite convoluted, we could remove the dependencies but then we would need
     * to implement some kind of signal system, which would be even more convoluted if not done right.
     * For now at least we we can properly initialize everything before we start the asset loading and rendering.
     */
    useEffect(() => {
        const abortController = new AbortController();
        const subs = [];
        let newEngine = null;
        // eslint-disable-next-line consistent-return
        function asyncUseEffect() {
            return __awaiter(this, void 0, void 0, function* () {
                if (!localizationReady || !fontsReady)
                    return noop;
                const tourConfigService = yield constructTourConfigService();
                const navigationService = constructNavigationService(tourConfigService.tourConfig);
                const layoutService = constructLayoutService();
                const phoneNumberService = constructPhoneNumberService();
                const gatedTourService = constructGatedTourService();
                const engineProps = {
                    canvas: canvasElem,
                    assetConfig,
                    tourConfigService,
                    analytics,
                };
                newEngine = constructEngine(engineProps);
                const services = {
                    navigationService,
                    tourConfigService,
                    layoutService,
                    phoneNumberService,
                    gatedTourService,
                };
                if (newEngine) {
                    yield initTourConfigService(newEngine, tourConfigService);
                    initNavigationService(newEngine, navigationService);
                    initLayoutService(newEngine, layoutService, tourConfigService);
                    yield initGatedTourService(newEngine, gatedTourService, tourConfigService, layoutService);
                    if (onTourConfigReady)
                        onTourConfigReady(tourConfigService, newEngine);
                    // Tell the host app that the info modal state changed
                    layoutService.onChange('showInfoModal', (isOpen) => {
                        onInfoModalToggle === null || onInfoModalToggle === void 0 ? void 0 : onInfoModalToggle(isOpen);
                    });
                    // Set the tailwind accent color variables
                    tourConfigService.onChange('accent', (accent) => {
                        setCssAccentColorVariable(tourConfigService.defaultAccent, tourConfigService.features.clientBranding ? accent : undefined);
                    });
                    tourConfigService.onChange('features', () => {
                        setCssAccentColorVariable(tourConfigService.defaultAccent, tourConfigService.features.clientBranding ? tourConfigService.accent : undefined);
                    });
                    setCssAccentColorVariable(tourConfigService.defaultAccent, tourConfigService.features.clientBranding ? tourConfigService.accent : undefined);
                    // Tell he host app that the engine is ready and provide the access to the services and engine
                    subs.push(newEngine.subscribe('scene.first.paint', () => __awaiter(this, void 0, void 0, function* () {
                        setReadyToRender(true);
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        onEngineReady(newEngine, services);
                    })));
                    // In case we missed the event
                    if (newEngine === null || newEngine === void 0 ? void 0 : newEngine.isSceneReady())
                        onEngineReady(newEngine, services);
                    // Start loading assets and rendering
                    newEngine.start();
                    setAppState({ services, engine: newEngine });
                }
            });
        }
        asyncUseEffect();
        return () => {
            abortController === null || abortController === void 0 ? void 0 : abortController.abort();
            subs.forEach((sub) => sub === null || sub === void 0 ? void 0 : sub.unsubscribe());
            newEngine === null || newEngine === void 0 ? void 0 : newEngine.destroy();
            if (onEngineDestroy)
                onEngineDestroy();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tourConfig, assetConfig, canvasElem, localizationReady, fontsReady]);
    // Listen for host apps info modal open state changes
    useEffect(() => {
        if (!appState)
            return;
        appState.services.layoutService.showInfoModal = infoModalOpen !== null && infoModalOpen !== void 0 ? infoModalOpen : false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infoModalOpen]);
    useEffect(() => {
        if (!appState)
            return;
        if (projectUrl) {
            appState.services.tourConfigService.projectUrl = projectUrl;
            return;
        }
        if (projectUrl === null) {
            appState.services.tourConfigService.setProjectUrl(readProjectUrl());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectUrl]);
    useEffect(() => {
        const abortController = new AbortController();
        fetchTranslations(language || null, assetConfig, abortController)
            .then((translations) => {
            setLocalization(translations);
            if (!localizationReady) {
                setLocalizationReady(true);
            }
        })
            .catch((err) => {
            ErrorLogger.captureMessage(`Translation download failed!`);
            // Failed to get translations, skin at this point is not usable, so throw the error page
            // We could fall back to engine only mode or english should be hardcoded and available as fallback?
            // TODO: generalize onError or make a separate callback for skin?
            if (onEngineError)
                onEngineError(err);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);
    useEffect(() => {
        if (!appState || !localization)
            return;
        const { layoutService } = appState.services;
        const t = curriedTranslate(localization);
        layoutService.watermarkInterruptedMessage = t('layout.error-watermark');
        layoutService.measureModeInitErrorMessage = t('measure-tool.init-failed');
        layoutService.measureModeMissingDataMessage = t('measure-tool.missing-data');
        layoutService.iframeEscapeMessage = t('iframe-scroll-guard.escape-message');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appState, localization]);
    useEffect(() => {
        appState === null || appState === void 0 ? void 0 : appState.engine.setAnalytics(analytics);
    }, [analytics, appState]);
    // useEffect(() => {
    //   const floorPlan3d = new FloorPlan3D();
    //   floorPlan3d.init(assetConfig.assetPath);
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
    // Crash the tour if tour variant lsfMode and LSF query parameter dont match
    if ((tourVariant === null || tourVariant === void 0 ? void 0 : tourVariant.lsfMode) === 'none' && requestedLsfMode !== 'off') {
        // If lsfMode is none, we don't allow any lsf query parameters
        throw new Error('Requested LSF mode is not allowed in this tour variant');
    }
    else if ((tourVariant === null || tourVariant === void 0 ? void 0 : tourVariant.lsfMode) === 'strict' && requestedLsfMode === 'off') {
        // If lsfMode is strict, lsf query parameter is mandatory and must not be off
        throw new Error('Requested LSF mode is not allowed in this tour variant');
    }
    if (!readyToRender || !appState)
        return null;
    return (_jsx(LocalizationContext.Provider, { value: localizationContext, children: _jsx(GlobalsContextProvider, { engine: appState.engine, assetConfig: assetConfig, hostRootRef: hostRootRef, appContext: appContext, analytics: analytics, children: _jsx(ServicesContextProvider, { services: appState.services, children: _jsx(CookieConsentContextProvider, { consentNecessary: !noCookieConsent, onPreferencesConfirmed: onCookiePreferencesConfirmed, children: _jsx(Layout, { noHotSpot: noHotSpot, noFullScreenOption: noFullScreenOption, onHotSpotEditClick: onHotSpotEditClick, onSelectHotSpot: onSelectHotSpot, disableAltPano: disableAltPano, isSentryEnabled: isSentryEnabled }) }) }) }) }));
};
export default Skin;
