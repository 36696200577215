/* eslint-disable no-continue */
import type { FPIntersection, FPMesh, FPMeshIntersection, FPRay } from '@g360/vt-types';
import {
  getVec3Difference,
  getVec3Dot,
  invertM4,
  multiplyM4AndPoint,
  normalizeVec3,
  vec3CrossProduct,
} from '@g360/vt-utils';

import FloorPlan3DProgram from './FloorPlan3DProgram';

function createRayFromMouse(
  mouseX: number,
  mouseY: number,
  canvasWidth: number,
  canvasHeight: number,
  viewMatrix: Float32Array,
  projectionMatrix: Float32Array
): FPRay {
  const x = (2.0 * mouseX) / canvasWidth - 1.0;
  const y = 1.0 - (2.0 * mouseY) / canvasHeight;
  const rayClip = [x, y, -1.0, 1.0];

  // typecast ir 100% safe, matrix functions does care if its number[] or Float32Array
  const rayEye = multiplyM4AndPoint(invertM4(projectionMatrix as unknown as number[]), rayClip);
  rayEye[2] = -1.0;
  rayEye[3] = 0.0;

  const rayWorld = multiplyM4AndPoint(invertM4(viewMatrix as unknown as number[]), rayEye);

  const invViewMatrix = invertM4(viewMatrix as unknown as number[]);
  const cameraPosition = [invViewMatrix[12], invViewMatrix[13], invViewMatrix[14]];

  const direction = normalizeVec3([rayWorld[0], rayWorld[1], rayWorld[2]]);

  return { origin: cameraPosition, direction, directionInv: [1 / direction[0], 1 / direction[1], 1 / direction[2]] };
}

export function rayTriangleIntersection(ray: FPRay, v0: number[], v1: number[], v2: number[]): FPIntersection | null {
  const edge1 = getVec3Difference(v1, v0);
  const edge2 = getVec3Difference(v2, v0);
  const h = vec3CrossProduct(ray.direction, edge2);
  const a = getVec3Dot(edge1, h);

  if (a > -1e-6 && a < 1e-6) return null;

  const f = 1.0 / a;
  const s = getVec3Difference(ray.origin, v0);
  const u = f * getVec3Dot(s, h);

  if (u < 0.0 || u > 1.0) return null;

  const q = vec3CrossProduct(s, edge1);
  const v = f * getVec3Dot(ray.direction, q);

  if (v < 0.0 || u + v > 1.0) return null;

  const t = f * getVec3Dot(edge2, q);

  if (t > 1e-6) {
    const intersectionPoint: [number, number, number] = [
      ray.origin[0] + t * ray.direction[0],
      ray.origin[1] + t * ray.direction[1],
      ray.origin[2] + t * ray.direction[2],
    ];
    return { point: intersectionPoint, distance: t };
  }

  return null;
}

export default class MeshRaycaster {
  private meshes: FPMesh[] = [];
  private goodMeshIds: number[] = [];
  private positionData: number[] = [];
  private canvas: HTMLCanvasElement;
  private floorPlan3DProgram: FloorPlan3DProgram;
  private debug: boolean; // @todo -- rem later prolly

  constructor(canvas: HTMLCanvasElement, floorPlan3DProgram: FloorPlan3DProgram, debug = false) {
    this.canvas = canvas;
    this.floorPlan3DProgram = floorPlan3DProgram;
    this.debug = debug;
  }

  loadGeometry(meshes: FPMesh[], positionData: number[]): void {
    this.meshes = meshes;
    this.positionData = positionData;

    for (let m = 0; m < meshes.length; m += 1) {
      const mesh = meshes[m];

      if (!this.debug) {
        // for simplicity this should be the same as solid program's check
        if (mesh.dataNum === 0) continue;
        if (mesh.isOutline || mesh.isCeiling || mesh.isSideCap) continue;
        if (mesh.isWallCap && mesh.isTopWall) continue;
      } else {
        // but for debugging purposes we need all the meshes
        if (mesh.dataNum === 0) continue;
        if (mesh.isOutline || mesh.isCeiling /* || mesh.isSideCap */) continue;
        // if (mesh.isWallCap && mesh.isTopWall) continue;
      }

      this.goodMeshIds.push(m);
    }
  }

  raycastMouse(event: MouseEvent): FPMeshIntersection[] | null {
    const boundingRect = this.canvas.getBoundingClientRect();
    try {
      const ray = createRayFromMouse(
        event.clientX,
        event.clientY,
        boundingRect.width,
        boundingRect.height,
        this.floorPlan3DProgram.matrixViewFloat32Array,
        this.floorPlan3DProgram.matrixProjectionFloat32Array
      );
      return this.findAllIntersectionsWithRay(ray);
    } catch (e) {
      // one matrix function throws error if matrix is not invertible (should never happen IRL)
      return null;
    }
  }

  raycastPoint(point: number[]): FPMeshIntersection | null {
    try {
      const ray = {
        origin: point,
        direction: [0, -1, 0],
        directionInv: [0, 0, 0], // not used
      };
      const intersections = this.findAllIntersectionsWithRay(ray);
      if (!intersections) return null;
      return intersections[0];
    } catch (e) {
      return null;
    }
  }

  private findAllIntersectionsWithRay(ray: FPRay): FPMeshIntersection[] | null {
    const allIntersections: FPMeshIntersection[] = [];
    const positions = this.positionData;

    for (let m = 0; m < this.goodMeshIds.length; m += 1) {
      const mesh = this.meshes[this.goodMeshIds[m]];

      if (!this.debug) {
        // @todo -- rem this check, when no debuggin takes place in this class
        if (mesh.skipSolidRendering) continue; // skip invisible meshes
      }

      const start = mesh.dataOffsetPositions / 4;
      const end = start + mesh.dataNum * 3;

      for (let i = start; i < end; i += 9) {
        const v0 = [positions[i], positions[i + 1], positions[i + 2]];
        const v1 = [positions[i + 3], positions[i + 4], positions[i + 5]];
        const v2 = [positions[i + 6], positions[i + 7], positions[i + 8]];

        const intersection = rayTriangleIntersection(ray, v0, v1, v2);
        if (intersection) {
          const meshExists = allIntersections.find((el) => el.mesh === mesh);
          if (meshExists) continue;

          allIntersections.push({
            mesh,
            point: intersection.point,
            distance: intersection.distance,
          });
        }
      }
    }

    if (allIntersections.length === 0) return null;

    // closest first
    return allIntersections.sort((a, b) => a.distance - b.distance);
  }
}
