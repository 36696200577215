import { setCookie } from '../misc';

type CookiePreferences = {
  isNecessary: boolean;
  isPerformance: boolean;
  isPreferences: boolean;
};

/**
 * @param cookieName - name of the cookie to set the preferences in
 * @param preferences - object with the preference
 * @param options - object with the options - e.g. { domain: '.giraffe360.com' }
 *
 * @example
 * setCookiePreferences('cookie_preferences', { isNecessary: true, isPerformance: true, isPreferences: true }, { domain: '.giraffe360.com' })
 */
const setCookiePreferences = (
  cookieName: string,
  preferences: CookiePreferences,
  options: { domain: string }
): void => {
  const preferencesMap = {
    isNecessary: 1,
    isPerformance: 2,
    isPreferences: 4,
  };

  const preferenceNumber = Object.entries(preferencesMap).reduce(
    (acc, [key, value]) => (preferences[key] ? acc + value : acc),
    0
  );

  const timestamp = new Date().getTime();

  setCookie({ name: cookieName, value: `${preferenceNumber}_${timestamp}`, days: 365 * 20, domain: options.domain });
};

export default setCookiePreferences;
