import type {
  ClipSpace,
  Degree,
  HotSpot2DType,
  HotSpotConfig,
  HotSpotInfoContent,
  Pos,
  ScenePos,
  Size,
} from '@g360/vt-types';
import { linearScale } from '@g360/vt-utils/';

import { cameraToPixel, toRad } from '../../common/Utils';

class HotSpot {
  static readonly textureCoords = new Float32Array([0, 1, 1, 1, 1, 0, 0, 0]);
  static readonly types: HotSpot2DType[] = [
    'hotspot-door',
    'hotspot-exact',
    'hotspot-forward',
    'hotspot-info',
    'hotspot-left-45',
    'hotspot-right-45',
    'hotspot-left-90',
    'hotspot-right-90',
    'hotspot-up',
    'hotspot-down',
  ];
  static readonly hotSpotFlatteningConfig = {
    pitchMin: -90,
    pitchMax: 0,
    sizeMin: 35,
    sizeMax: 95,
    flattenMin: 0,
    flattenMax: 80,
  };

  canvas: HTMLCanvasElement;
  type: string;
  pos: ScenePos<Degree>;
  target: string;
  infoContent: HotSpotInfoContent | null;

  flatten?: boolean | number;
  scale?: number;

  pixelWidth: number;
  pixelHeight: number;
  hover = false;

  geometry: Float32Array = new Float32Array([]);
  clipSpaceSize: Size | null = null;
  clipSpacePos: Pos<ClipSpace> | null = null;
  originalConfig: HotSpotConfig;
  /** If true, rendering and interaction is disabled */
  disabled = false;

  constructor(config: HotSpotConfig, canvas: HTMLCanvasElement, targetPixelSize = 50) {
    this.updateSize = this.updateSize.bind(this);

    this.canvas = canvas;
    this.type = config.type;
    this.pos = {
      pitch: config.pos[0],
      yaw: config.pos[1],
    };
    this.target = config.target;
    this.flatten = config.flatten;
    this.scale = config.scale ?? 1;
    this.infoContent = config.content || null;
    this.originalConfig = config;
    this.disabled = config.disabled || false;

    this.pixelWidth = targetPixelSize;
    this.pixelHeight = targetPixelSize;

    this.updateSize();
  }

  static getFlattenValue(pitch: number): number {
    const { pitchMin, pitchMax, flattenMin, flattenMax } = HotSpot.hotSpotFlatteningConfig;
    if (pitch > pitchMax) {
      return flattenMax;
    }
    const pZeroOffset = 0 - pitchMin;
    const pRange = 100 - ((pitch + pZeroOffset) * 100) / (pitchMax + pZeroOffset);

    const sizeZeroOffset = 0 - flattenMax;
    return ((flattenMin + sizeZeroOffset) * pRange) / 100 - sizeZeroOffset;
  }

  static getSizeValue(pitch: number): number {
    const { pitchMin, pitchMax, sizeMin, sizeMax } = HotSpot.hotSpotFlatteningConfig;
    if (pitch > pitchMax) {
      return sizeMin;
    }
    const pZeroOffset = 0 - pitchMin;
    const pRange = 100 - ((pitch + pZeroOffset) * 100) / (pitchMax + pZeroOffset);

    const sizeZeroOffset = 0 - sizeMin;
    return ((sizeMax + sizeZeroOffset) * pRange) / 100 - sizeZeroOffset;
  }

  updatePosition(pitch: number, yaw: number, fov: number): void {
    const { clientWidth, clientHeight } = this.canvas;

    const cameraPos = { pitch, yaw, fov };
    const spaceSize = { width: clientWidth, height: clientHeight };
    const point = cameraToPixel({ pitch: toRad(this.pos.pitch), yaw: toRad(this.pos.yaw) }, cameraPos, spaceSize);

    if (!point) {
      this.clipSpacePos = null;
      return;
    }

    // Convert from pixel space to clip space (-1, 1)
    this.clipSpacePos = {
      x: linearScale(point.x, [0, clientWidth], [-1, 1], false),
      y: linearScale(point.y, [0, clientHeight], [1, -1], false),
    };
  }

  updateSize(): void {
    // NOTE(uzars): custom 'flatten' angle and scale is probably never ever used and will be deprecated and removed
    // from HotSpotConfig, and then HotSpotConfig.flatten will become useless altogether and will be removed.
    // We can use HotSpotConfig.type to check if we need to flatten the hotspot (as it is done in the VTE)
    const { clientWidth: canvasWidth, clientHeight: canvasHeight } = this.canvas;
    let pixelHeight = this.pixelHeight;
    let pixelWidth = this.pixelWidth;

    if (this.flatten) {
      let angleDeg = this.flatten ? HotSpot.getFlattenValue(this.pos.pitch) : 0;

      // Custom angle defined in tourConfig
      if (typeof this.flatten === 'number') {
        angleDeg = this.flatten;
      }

      // Adjust size based on pitch value for 'flattened' hotspots
      pixelWidth = HotSpot.getSizeValue(this.pos.pitch);

      // Change flatten angle to the actual pixel height
      pixelHeight = Math.sin((90 - angleDeg) * (Math.PI / 180)) * pixelWidth;
    }

    // Custom scale defined in tourConfig
    if (this.scale) {
      pixelWidth *= this.scale;
      pixelHeight *= this.scale;
    }

    // Convert texture size from pixels to clip space
    this.clipSpaceSize = {
      width: linearScale(pixelWidth, [0, canvasWidth], [0, 2], false),
      height: linearScale(pixelHeight, [0, canvasHeight], [0, 2], false),
    };

    // Prepare geometry
    // prettier-ignore
    this.geometry = new Float32Array([
      0, 0, // top left
      this.clipSpaceSize.width, 0, // top right
      this.clipSpaceSize.width, this.clipSpaceSize.height, // bottom right
      0, this.clipSpaceSize.height, // bottom left
    ]);
  }
}

export default HotSpot;
