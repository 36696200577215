/* eslint-disable no-continue */
import type {
  AssetConfig,
  Degree,
  Destroyable,
  FPMesh,
  HasFPMatrices,
  Milliseconds,
  Radian,
  TourConfig,
} from '@g360/vt-types';
import {
  calculateCameraParameters,
  createOrthographicMatrix,
  createViewMatrix,
  drawShapesOnCanvas,
  getBoundingBoxesAndCenter,
  getOnlyMeshNumbers,
  getProjectIdFromUrl,
  getSkipMeshNumbers,
  getVec3Difference,
  getVec3Length,
  identityM4,
  lerp,
  lerpMatrix,
  linearScale,
  loadGlb,
  multiplyM4,
  perspectiveM4,
  scalingM4,
  toRad,
  transposeM4,
  vec3CrossProduct,
} from '@g360/vt-utils';

import { easeOutCubic, easeOutQuad } from '../../common/Utils';
import DebugControls from '../../DebugControls';
import Renderer from '../../mixins/Renderer';
import RenderTarget from '../../RenderTarget';
import type { ProgramName } from '../../types/internal';
import Camera from './Camera';
import FlatProgram from './FlatProgram/FlatProgram';
import MeshRaycaster from './MeshRaycaster';
import ModelNavigation from './ModelNavigation';
import OutlineProgram from './OutlineProgram/OutlineProgram';
import SolidProgram from './SolidProgram/SolidProgram';
import Sun from './Sun';
import SunDepthProgram from './SunDepthProgram/SunDepthProgram';
import TransparentProgram from './TransparentProgram/TransparentProgram';

class FloorPlan3DProgram implements Destroyable, HasFPMatrices {
  name: ProgramName = 'FloorPlan3DProgram';
  orderIndex = 0;
  gl: WebGL2RenderingContext;
  canvas: HTMLCanvasElement;
  /** reference to debugControls.cameraManualOffsets */
  debugCameraPosition: number[] = [];
  /** scene camera position + debug camera movement  */
  actualCameraPosition: number[] = [0, 0, 0];
  pitch = 0;
  /** some inverting needed to sync model with minimap/pano */
  actualPitch = 0;
  /** not used directly, see `actualYaw` */
  yaw = 0;
  /** some inverting and offset needed to sync model with minimap/pano */
  actualYaw = 0;
  /** not used as a FOV in this program */
  fov = 0;
  /** set by engine, same user input as fov (only when this program is active) */
  zoom = 0;
  simpleCameraPosFloat32Array: Float32Array = new Float32Array(0);
  matrixWorldFloat32Array: Float32Array = new Float32Array(0);
  matrixViewFloat32Array: Float32Array = new Float32Array(0);
  matrixProjectionFloat32Array: Float32Array = new Float32Array(0);
  sunMatrixWorldFloat32Array: Float32Array = new Float32Array(0);
  sunMatrixViewFloat32Array: Float32Array = new Float32Array(0);
  sunMatrixProjectionFloat32Array: Float32Array = new Float32Array(0);
  positionBuffer: WebGLBuffer | null = [];
  normalBuffer: WebGLBuffer | null = [];
  texCoordBuffer: WebGLBuffer | null = [];
  expandCoordBuffer: WebGLBuffer | null = [];
  /** vertices of all meshes: same data as in position buffer  */
  positionData: number[] = [];
  /** what time is the current matrices calculated (in Sun time) */
  sunMatrixRenderedTimeOfDay = 99;
  boundingRect: DOMRect;
  modelLoaded = false;
  assetConfig: AssetConfig;
  // interactive = true;
  /** calculated at model load to keep sun camera render depth at a minimum */
  sunFar = 0;
  /** calculated at model load to keep sun camera render depth at a minimum */
  sunNear = 0;
  readonly sunFov = 0.75;
  readonly sunDepthMapSizeX = 1024; // @todo -- should these SUN params be in this file?
  readonly sunDepthMapSizeY = 512; // @todo -- find good balance between quality and performance
  sun: Sun;
  debugXXX = 164;
  /**
   * on mouse click try to find mouse cursor orientation in screen space
   * if clicked on a mesh, then it is relative to the center of model in 3D
   * if clicked on empty space, then it is relative to the center of the screen
   */
  mouseRelativeToCenterX: 'left' | 'right' | 'center' = 'center';
  mouseRelativeToCenterY: 'above' | 'below' | 'center' = 'center';
  /** is the camera in top-down view */
  topDownView = false;
  /** url: ...#onlyMeshes=1,2,3  -- only draw meshes with given debugId @todo -- rem later */
  onlyMeshes: number[] = [];
  /** url: ...#skipMeshes=1,2,3  -- don't draw meshes with given debugId @todo -- rem later */
  skipMeshes: number[] = [];

  private outlineProgram: OutlineProgram;
  private flatProgram: FlatProgram;
  private solidProgram: SolidProgram;
  private transparentProgram: TransparentProgram;
  private sunDepthProgram: SunDepthProgram;
  private drawOutline = true;
  private drawFlat = true;
  private drawSolid = true;
  private drawSunPreview = false;
  private drawTransparent = true;
  private debugControls: DebugControls | undefined;
  private renderer: Renderer;
  private readonly camera: Camera;
  private readonly navigation: ModelNavigation;
  private currentPanoId = 0;
  private tourConfig: TourConfig;
  private midnightSunTexture: WebGLTexture | null = null;
  private debugToggleProgram = 0;
  private sunRenderTarget: RenderTarget;
  /** Field of View used in rendering */
  private actualFov: Radian = 0.8;
  private readonly defaultFov: Radian = 0.8;
  private readonly closeUpFov: Radian = 1.5;
  private readonly destroyables: Destroyable[] = [];
  private mouseDown = false;
  /** time of last mouse down event */
  private mouseDownTime: Milliseconds = 0;
  private meshRaycaster: MeshRaycaster;
  private meshRaycasterDebug: MeshRaycaster; // @todo -- rem later prolly
  private firstDraw = false;
  private active = false;
  private afterLoad: (() => void)[] = [];
  private topDownViewProgress = 0;
  private cameraMatrixChecksum = 0;

  constructor(
    gl: WebGL2RenderingContext,
    canvas: HTMLCanvasElement,
    assetConfig: AssetConfig,
    tourConfig: TourConfig,
    renderer: Renderer,
    debugControls: DebugControls | undefined
  ) {
    // ----------------------------------------------------------------------------------------------------------------
    const urlParams = new URLSearchParams(window.location.search);
    const debugFov = parseFloat(urlParams.get('fov') ?? '0.8');
    this.actualFov = debugFov;
    this.defaultFov = debugFov;
    console.log('got FOV', this.actualFov);
    // ----------------------------------------------------------------------------------------------------------------

    this.gl = gl;
    this.canvas = canvas;
    this.assetConfig = assetConfig;
    this.debugControls = debugControls;
    this.tourConfig = tourConfig;
    this.renderer = renderer;

    this.boundingRect = this.canvas.getBoundingClientRect();
    this.onResize = this.onResize.bind(this);
    this.onResize();

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);

    this.camera = new Camera(debugControls);
    this.navigation = new ModelNavigation(tourConfig);
    this.sun = new Sun([0, 0, 0]);
    this.sun.update();
    this.calculateSunMatrices(); // calculate matrices for the first time, or there will be WebGL warnings until we calculate them for real

    this.outlineProgram = new OutlineProgram(this.gl, this.canvas, this, this.navigation, this.camera);
    this.flatProgram = new FlatProgram(this.gl, this.canvas, this, this.navigation);
    this.solidProgram = new SolidProgram(this.gl, this.canvas, this, this.navigation);
    this.transparentProgram = new TransparentProgram(this.gl, this.canvas, this, this.navigation);
    this.sunDepthProgram = new SunDepthProgram(this.gl, this.canvas, this);
    this.sunRenderTarget = new RenderTarget(this.gl, this.sunDepthMapSizeX, this.sunDepthMapSizeY, true);
    this.destroyables.push(
      this.outlineProgram,
      this.flatProgram,
      this.solidProgram,
      this.transparentProgram,
      this.sunDepthProgram,
      this.sunRenderTarget
    );
    this.meshRaycaster = new MeshRaycaster(this.canvas, this);
    this.meshRaycasterDebug = new MeshRaycaster(this.canvas, this, true);

    const debugRenderSunDepthMap = () => {
      if (!this.sunRenderTarget.texture) return;

      // eslint-disable-next-line no-inner-declarations
      function debugTexture(ggl: WebGL2RenderingContext, texture: WebGLTexture, width: number, height: number) {
        // Create a framebuffer
        const framebuffer = ggl.createFramebuffer();
        ggl.bindFramebuffer(ggl.FRAMEBUFFER, framebuffer);

        // Attach the texture to the framebuffer
        ggl.framebufferTexture2D(ggl.FRAMEBUFFER, ggl.COLOR_ATTACHMENT0, ggl.TEXTURE_2D, texture, 0);

        // Check framebuffer status
        const status = ggl.checkFramebufferStatus(ggl.FRAMEBUFFER);
        if (status !== ggl.FRAMEBUFFER_COMPLETE) {
          console.error('Framebuffer is not complete:', status);
          return;
        }

        // Read the pixels
        const pixels = new Uint8Array(width * height * 4);
        ggl.readPixels(0, 0, width, height, ggl.RGBA, ggl.UNSIGNED_BYTE, pixels);

        const existingCc = document.getElementById('debugCc');
        if (existingCc) {
          existingCc.remove();
        }

        // Create a 2D canvas to display the texture
        const cc = document.createElement('canvas');
        cc.width = width;
        cc.height = height;
        const ctx = cc.getContext('2d')!;

        // Put the pixels on the canvas
        const imageData = ctx.createImageData(width, height);
        imageData.data.set(pixels);
        ctx.putImageData(imageData, 0, 0);

        // Flip the image vertically (WebGL has inverted Y-axis compared to canvas)
        ctx.scale(1, -1);
        ctx.drawImage(cc, 0, -height);

        // Clean up
        ggl.bindFramebuffer(ggl.FRAMEBUFFER, null);
        ggl.deleteFramebuffer(framebuffer);

        // Add the canvas to the document for viewing
        document.body.appendChild(cc);
        cc.id = 'debugCc';
        cc.style.position = 'absolute';
        cc.style.width = '300px';
        cc.style.height = '300px';
        cc.style.top = '0';
        cc.style.left = '0';
      }

      debugTexture(this.gl, this.sunRenderTarget.texture, this.sunDepthMapSizeX, this.sunDepthMapSizeY);
    };

    const debugToggleDrawingPrograms = (increment = true) => {
      if (increment) {
        this.debugToggleProgram = (this.debugToggleProgram + 1) % 6;
      }

      this.drawOutline = this.debugToggleProgram === 0 || this.debugToggleProgram === 1;
      this.drawFlat = this.debugToggleProgram === 0 || this.debugToggleProgram === 2;
      this.drawSolid = this.debugToggleProgram === 0 || this.debugToggleProgram === 3;
      this.drawTransparent = this.debugToggleProgram === 0 || this.debugToggleProgram === 4;
      this.drawSunPreview = this.debugToggleProgram === 5;
      console.log(this.drawOutline ? 'outline' : '', this.drawFlat ? 'flat' : '', this.drawSolid ? 'solid' : '', this.drawSunPreview ? 'sunPreview' : '', this.drawTransparent ? 'transparent' : ''); // prettier-ignore
    };

    // this.drawOutline = false;
    // this.drawFlat = false;
    // this.drawSolid = true;
    // this.drawSimpleSolid = false;

    this.debugToggleProgram = 0;
    debugToggleDrawingPrograms(false);

    // const pressDebugXXX = () => {
    //   this.debugXXX += 1;
    //   if (this.debugXXX > 10) {
    //     this.debugXXX = 0;
    //     this.actualFov = 1;
    //   }
    //   this.actualFov -= 0.1;
    //   console.log('debugXXX:', this.debugXXX, 'actualFOV:', this.actualFov);
    // };

    // setInterval(() => {
    //   pressDebugXXX();
    // }, 133);

    if (debugControls) {
      debugControls.setNumericalCallback((n) => {
        if (n === 1) {
          debugToggleDrawingPrograms();
        }

        if (n === 2) {
          console.log('nothing on 2');
        }

        if (n === 3) {
          console.log('nothing on 3');
        }

        if (n === 5) {
          console.log('5) gonna draw');
          drawShapesOnCanvas(this.solidProgram.meshes);
        }

        if (n === 6) {
          console.log('6::render sun depth map');
          debugRenderSunDepthMap();
        }

        if (n === 7) {
          const camera = this.camera;
          // eslint-disable-next-line🤷
          const mode = ((<any>camera).mode + 1) % 2;
          this.camera.setMode(mode);
          console.log('camera mode:', mode);
          if (mode === 1) {
            alert('please zoom in to PANO and then back to get unlimited camera pitch angle');
          }
        }

        if (n === 8) {
          this.navigation.debugNextCentering();
          this.camera.setCenter(this.navigation.getCurrentOrbitCenter());
        }

        if (n === 9) {
          this.sun.paused = !this.sun.paused;
          console.log('sun paused:', this.sun.paused);
        }
      });
    }

    this.debugCameraPosition = (this.debugControls && this.debugControls.cameraManualOffsets) ?? [0, 0, 0];
  }

  init(): void {
    const version = this.gl.getParameter(this.gl.VERSION);
    console.log('@FloorPlan3DProgram::init', 'WebGL version:', version);

    const projectId = getProjectIdFromUrl();

    const whitelistedProjects = [
      '5ddf9570f9694479b883dfcd7685585f', // new-camera-testing/5ddf9570f9694479b883dfcd7685585f
      'd9a3bd4bfd4c4d89997f0a0205545533', // morberga-studios/d9a3bd4bfd4c4d89997f0a0205545533
      '962e8d66e03640fb9555bac5d3e87ca7', // kern-geschaft/962e8d66e03640fb9555bac5d3e87ca7
      '74c95719dbba47378c441267af59b5c8', // je-productions/74c95719dbba47378c441267af59b5c8  4k 7.4507min | 2k 1.9484min
      '0bde140d52464e729797fa2914c5a3f7', // kinougarde/0bde140d52464e729797fa2914c5a3f7
    ];

    if (!whitelistedProjects.includes(projectId)) {
      alert(`FloorPlan3dProgram\nplease use only whitelisted projects\n${whitelistedProjects.join(', ')}`);
      throw new Error('Invalid project ID');
    }

    const url = window.location.href;
    const hashIndex = url.indexOf('#texPrefix=');
    const texPrefix = hashIndex >= 0 ? url.slice(hashIndex + '#texPrefix='.length) : '';
    console.log('texPrefix:', texPrefix);

    const glbPath = `${this.assetConfig.assetPath}/floorPlan3D/${projectId}.glb`;
    const texturePathProject = `${this.assetConfig.assetPath}/floorPlan3D/${texPrefix}texture_${projectId}.png`;
    const texturePathGeneric = `${this.assetConfig.assetPath}/floorPlan3D/texture.png`; // @todo -- rem later
    console.log({ glbPath, texturePathProject, texturePathGeneric });

    // const baseTexUrl = `${this.assetConfig.assetPath}/floorPlan3D/base_ao_texture.png`;
    // const image = new Image();
    // image.src = baseTexUrl;
    // image.onload = () => {
    //   document.body.appendChild(image);
    //   image.style.position = 'absolute';
    //   image.style.top = '0';
    //   image.style.left = '0';
    //   image.style.width = '100px';
    //   image.style.height = '100px';
    //   image.id = 'base_ao_texture'; // set ID if loaded correctly, AO baker will try to find by ID
    //   console.log('base AO tex loaded:', baseTexUrl, image);
    // };

    // Pre-fetching main tex and ignoring the result.
    // When .glb is loaded, programs initialized and texture could be uploaded to GPU
    // texture is fetched again - from browser cache.
    fetch(texturePathGeneric);
    fetch(texturePathProject);

    loadGlb(glbPath).then((meshes) => {
      console.log('FloorPlan3DProgram::init::loadGlb::done');
      this.navigation.init(meshes);

      const { center, boundingBoxMin, boundingBoxMax } = getBoundingBoxesAndCenter(meshes);

      this.sun.setModelCenter(center);
      this.navigation.setModelCenter(center);
      this.camera.setCenter(center);

      // initial camera distance - try to fit whole model into the screen
      // this is very dependent on the FOV, tweak it and this together!
      const { optimalDistance, modelRadius } = calculateCameraParameters(
        boundingBoxMin,
        boundingBoxMax,
        this.actualFov
      );
      this.camera.setRadius(optimalDistance * 2, modelRadius);

      const { optimalDistance: optimalSunDist, modelRadius: sunModelRadius } = calculateCameraParameters(
        boundingBoxMin,
        boundingBoxMax,
        this.sunFov
      );
      this.sun.setRadius(optimalSunDist * 0.9);
      this.sunNear = sunModelRadius * 0.9;
      this.sunFar = this.sunNear + sunModelRadius * 2.1;

      this.makeBuffers(meshes);

      this.outlineProgram.init();
      this.outlineProgram.loadGeometry(meshes);

      this.flatProgram.init();
      this.flatProgram.loadGeometry(meshes);

      this.solidProgram.init();
      this.solidProgram.loadGeometry(meshes);
      this.solidProgram.setSunDepthTex(this.sunRenderTarget.texture);
      this.solidProgram.fetchTexture(texturePathProject, texturePathGeneric);

      this.transparentProgram.init();
      this.transparentProgram.loadGeometry(meshes);

      this.sunDepthProgram.init();
      this.sunDepthProgram.loadGeometry(meshes);

      this.meshRaycaster.loadGeometry(meshes, this.positionData);
      this.meshRaycasterDebug.loadGeometry(meshes, this.positionData);

      this.boundingRect = this.canvas.getBoundingClientRect();
      this.modelLoaded = true;

      // functions to call after model is loaded
      for (let i = 0; i < this.afterLoad.length; i += 1) {
        this.afterLoad[i]();
      }
      this.afterLoad = [];
    });

    // @todo -- don't do it here, must be done onActivate and turned off onDeactivate
    window.addEventListener('resize', this.onResize);
    this.canvas.addEventListener('mousedown', this.onMouseDown);
    this.canvas.addEventListener('mouseup', this.onMouseUp);
    this.canvas.addEventListener('touchstart', this.onTouchStart);
    this.canvas.addEventListener('touchend', this.onTouchEnd);
  }

  /**
   *
   * @param panoCamera -- [x,y,z,yawOffset], in centimeters, z is up
   */
  onActivate(panoCamera: number[]) {
    this.boundingRect = this.canvas.getBoundingClientRect();
    console.log('FloorPlan3DProgram::onActivate::@todo -- reset activated rooms');
    this.active = true;

    this.actualYaw = Math.PI - this.yaw + Math.PI; // invert and offset to sync with minimap/pano
    this.actualPitch = -this.pitch; // need to invert to sync with pano

    const pos = [panoCamera[0], 0, panoCamera[1]];
    if (this.modelLoaded) {
      this.centerOnRoomByPosition(pos);
    } else {
      // do it after the model is loaded
      this.afterLoad.push(() => {
        this.centerOnRoomByPosition(pos);
      });
    }
  }

  onDeactivate() {
    this.active = false;
    return { cameraCenter: this.navigation.getCurrentOrbitCenter() };
  }

  destroy(): void {
    window.removeEventListener('resize', this.onResize);
    this.canvas.removeEventListener('mousedown', this.onMouseDown);
    this.canvas.removeEventListener('mouseup', this.onMouseUp);
    for (let i = 0; i < this.destroyables.length; i += 1) {
      this.destroyables[i].destroy();
    }
  }

  onResize(): void {
    this.boundingRect = this.canvas.getBoundingClientRect();
  }

  onMouseDown(event: MouseEvent): void {
    this.mouseDown = true;
    this.mouseDownTime = Date.now();

    // @todo -- rem this since "natural" dragging is canceled
    const res = this.meshRaycaster.raycastMouse(event);
    if (res) {
      const point = res[0].point;
      // mouse relative to model center in 3D space
      const modelCenter = this.navigation.getCurrentOrbitCenter();
      const cameraPosition = this.actualCameraPosition;

      const cameraToCenter = getVec3Difference(modelCenter, cameraPosition);
      const cameraToPoint = getVec3Difference(point, cameraPosition);
      const crossProduct = vec3CrossProduct(cameraToCenter, cameraToPoint);
      this.mouseRelativeToCenterX = crossProduct[1] > 0 ? 'left' : 'right';

      const distanceToClickPoint = getVec3Length(getVec3Difference(cameraPosition, point));
      const distanceToModelCenter = getVec3Length(getVec3Difference(cameraPosition, modelCenter));
      this.mouseRelativeToCenterY = distanceToClickPoint < distanceToModelCenter ? 'below' : 'above';
    } else {
      // mouse relative to screen center in 2D space
      this.mouseRelativeToCenterX = event.clientX < this.boundingRect.width / 2 ? 'left' : 'right';
      this.mouseRelativeToCenterY = event.clientY < this.boundingRect.height / 2 ? 'above' : 'below';
    }
  }

  /**
   * Not tracking actual "click" event, since long drag also counts as a "click"
   */
  onMouseUp(event: MouseEvent): void {
    this.boundingRect = this.canvas.getBoundingClientRect(); // @todo -- investigate: unless we get fresh version of this rect, raycast is wrong, everything else is fine

    this.mouseDown = false;
    const timeSinceDown = Date.now() - this.mouseDownTime;

    // @todo -- rem later -- debuggggggggggg
    if (event.shiftKey) {
      const res = this.meshRaycasterDebug.raycastMouse(event);
      console.log('clicked on:', event.clientX, event.clientY);
      if (res) {
        for (let i = 0; i < res.length; i += 1) {
          const mesh = res[i].mesh;

          if (this.skipMeshes.includes(mesh.unqId)) continue;
          if (this.onlyMeshes.length && !this.onlyMeshes.includes(mesh.unqId)) continue;

          console.log('mesh:', { dbg: mesh.unqId, wf: mesh.wallFragmentId }, mesh.wallFragmentCenter, mesh.debugTxt,{skipSolid:mesh.skipSolidRendering, skipFlat:mesh.skipFlatRendering}, mesh); // prettier-ignore
        }
      }
      return;
    }

    if (timeSinceDown < 200) {
      const res = this.meshRaycaster.raycastMouse(event);
      this.navigation.centerOnRoomByMesh(res ? res[0].mesh : null);
      this.camera.setCenter(this.navigation.getCurrentOrbitCenter());
    }
  }

  onTouchStart(event) {
    const touch = event.touches[0];
    const mouseEvent = new MouseEvent('mousedown', {
      clientX: touch.clientX,
      clientY: touch.clientY,
    });
    this.onMouseDown(mouseEvent);
  }

  onTouchEnd() {
    const mouseEvent = new MouseEvent('mouseup', {});
    this.onMouseUp(mouseEvent);
  }

  render() {
    if (!this.modelLoaded || this.renderer.renderMode !== 'floorplan3D') return;

    this.skipMeshes = getSkipMeshNumbers();
    this.onlyMeshes = getOnlyMeshNumbers();

    this.actualYaw = Math.PI - this.yaw + Math.PI; // invert and offset to sync with minimap/pano
    this.actualPitch = -this.pitch; // need to invert to sync with pano

    this.camera.setZoom(this.zoom);

    this.gl.enable(this.gl.CULL_FACE);
    this.gl.cullFace(this.gl.BACK);

    this.gl.enable(this.gl.DEPTH_TEST);
    this.gl.depthFunc(this.gl.LESS);

    this.drawSun();

    this.navigation.calculateCutaways(this.actualYaw, this.topDownView);
    this.calculateCameraMatrices();

    this.gl.clearColor(0.968627451, 0.968627451, 0.968627451, 1.0);
    this.gl.clear(this.gl.COLOR_BUFFER_BIT);

    if (this.drawOutline) this.outlineProgram.draw();
    if (this.drawFlat) this.flatProgram.draw();
    if (this.drawSolid) this.solidProgram.draw();
    if (this.drawTransparent) this.transparentProgram.draw();
    if (this.drawSunPreview) this.sunDepthProgram.draw();

    // console.log("num tris", 'Outline:', this.outlineProgram.numTrisDrawn, 'Flat:', this.flatProgram.numTrisDrawn, 'Solid:', this.solidProgram.numTrisDrawn, 'Transparent:', this.transparentProgram.numTrisDrawn); // prettier-ignore

    this.outlineProgram.numTrisDrawn = 0;
    this.flatProgram.numTrisDrawn = 0;
    this.solidProgram.numTrisDrawn = 0;
    this.transparentProgram.numTrisDrawn = 0;
    this.sunDepthProgram.numTrisDrawn = 0;

    this.gl.disable(this.gl.DEPTH_TEST);
    this.gl.disable(this.gl.CULL_FACE);
  }

  /**
   *  @todo -- remove when FPS mode is not available any more and just use readonly class vars
   */
  get minPitchClamp(): Degree {
    // we are using negative values for pitch, so the limits are inverse compared to the pano limits defined in renderer.ts
    return this.camera.getMode() === 1 ? -90 : -90;
  }

  get maxPitchClamp(): Degree {
    return this.camera.getMode() === 1 ? 90 : -10;
  }

  private drawSun() {
    this.sun.update();

    // temporarily disabled optimization for better FPS measurement
    // don't calculate (and render) sun if it's below horizon
    // use whatever matrices there is and a black texture§
    // if (this.sun.pitch < 0) {
    //   if (!this.midnightSunTexture) {
    //     this.midnightSunTexture = createBlackTexture(this.gl);
    //   }
    //   this.sunRenderTarget.texture = this.midnightSunTexture; // @todo -- does this hack work ?
    //   return;
    // }

    const age = Math.abs(this.sun.timeOfDay - this.sunMatrixRenderedTimeOfDay); // in decimal hours

    // only recalculate matrices (for shaders that use the sun for shadow casting)
    // and render sun depth map if sun has moved significantly
    if (age > 0.05 * 0.0001) {
      this.calculateSunMatrices();
      this.sunRenderTarget.bind();
      this.gl.clearColor(1.0, 1.0, 1.0, 1.0);
      // eslint-disable-next-line no-bitwise
      this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT);
      this.sunDepthProgram.draw();
      this.sunRenderTarget.unbind();
    }
  }

  /**
   * Calculate camera matrices for both programs
   *  @todo -- only if something has changed
   *           could also optimize lerpMatrix and other lerps - don't do if we are at the 0 or 100% of the effect
   *           but 99% of optimization will be not to calc if nothing has changed
   */
  private calculateCameraMatrices() {
    // @todo -- don't create new array, use existing
    // this is not much, since there are multiple "new array" and matrix operations that return new array

    // Don't calculate matrices if nothing has changed.
    // Very simple hashing, but works.
    // But this seems to be big code, maybe move somewhere else ?
    const camCenter = this.camera.getCenter();
    const currentCameraMatrixChecksum =
      this.debugCameraPosition[0] + // @todo -- rem, dbg only
      this.debugCameraPosition[1] +
      this.debugCameraPosition[2] +
      camCenter[0] +
      camCenter[1] +
      camCenter[2] +
      this.actualCameraPosition[0] +
      this.actualCameraPosition[2] +
      this.actualCameraPosition[2] +
      this.actualPitch +
      this.actualYaw +
      this.actualFov +
      this.zoom +
      this.gl.drawingBufferWidth +
      this.gl.drawingBufferHeight;

    if (this.cameraMatrixChecksum === currentCameraMatrixChecksum) return;
    this.cameraMatrixChecksum = currentCameraMatrixChecksum;

    this.calculateTopDownMods();
    this.calculateCloseUpMods();

    // ------------ move with keyboard ------------
    this.actualCameraPosition = this.camera.calculate(this.actualPitch, this.actualYaw);
    this.simpleCameraPosFloat32Array = new Float32Array([
      this.actualCameraPosition[0],
      this.actualCameraPosition[1],
      this.actualCameraPosition[2],
    ]);

    const { near, far } = this.camera.getClippingPlanes();

    const modelRotationMatrix = identityM4();
    const scaleMatrix = scalingM4(1, 1, 1);
    const worldMatrix = multiplyM4(modelRotationMatrix, scaleMatrix);
    this.matrixWorldFloat32Array = new Float32Array(worldMatrix);

    const viewMatrix = createViewMatrix(this.actualCameraPosition, this.actualPitch, this.actualYaw);
    this.matrixViewFloat32Array = new Float32Array(viewMatrix);

    // perspective matrix
    const size = { width: this.gl.drawingBufferWidth, height: this.gl.drawingBufferHeight };
    const perspectiveMatrix = transposeM4(perspectiveM4(this.actualFov, size, near, far));

    // ortho matrix
    const aspect = size.width / size.height;
    const perspectiveFrustumHeight = 2 * Math.tan(this.actualFov / 2) * this.camera.getCurrentDistanceFromCenter();
    const orthoSize = perspectiveFrustumHeight / aspect;
    const orthoMatrix = createOrthographicMatrix(
      (-orthoSize * aspect) / 2,
      (orthoSize * aspect) / 2,
      -orthoSize / 2,
      orthoSize / 2,
      near,
      far
    );

    const interpolatedMatrix = lerpMatrix(perspectiveMatrix, orthoMatrix, this.topDownViewProgress);
    this.matrixProjectionFloat32Array = new Float32Array(interpolatedMatrix);
  }

  /**
   * "Top-Down View"
   * Near the top of the pitch range, the camera will switch to ortho projection.
   */
  private calculateTopDownMods() {
    const startPitch = toRad(68);
    const endPitch = toRad(-this.minPitchClamp); // 90°   we are using inverse pitch values, so it's negative min to get actual logical max
    const pitchStrength = linearScale(this.actualPitch, [startPitch, endPitch], [0, 1], true);

    const minZoom = 0.1; // 0 if below this value
    const maxZoom = 0.2; // 1 if above, this value, so zoom is animating the FX only in this narrow range
    const zoomStrength = linearScale(this.zoom, [minZoom, maxZoom], [0, 1], true);

    const fxStrength = easeOutCubic(pitchStrength * zoomStrength);

    this.topDownViewProgress = fxStrength;
    this.topDownView = fxStrength > 0.05;
  }

  /**
   * "Close-Up View"
   * Near the bottom of the zoom range, the camera will switch to a higher FOV.
   * To look like Pano FOV.
   */
  private calculateCloseUpMods() {
    const startZoom = 0;
    const endZoom = 0.1;
    const fxZoom = easeOutQuad(linearScale(this.zoom, [startZoom, endZoom], [0, 1], true));
    this.actualFov = lerp(this.closeUpFov, this.defaultFov, fxZoom);
  }

  /**
   * Calculate sun matrices for depthmap rendering and shadow casting
   */
  private calculateSunMatrices() {
    const modelRotationMatrix = identityM4();
    const scaleMatrix = scalingM4(1, 1, 1);
    const worldMatrix = multiplyM4(modelRotationMatrix, scaleMatrix);
    this.sunMatrixWorldFloat32Array = new Float32Array(worldMatrix);

    const viewMatrix = createViewMatrix(this.sun.position, this.sun.pitch, this.sun.yaw);
    this.sunMatrixViewFloat32Array = new Float32Array(viewMatrix);

    const size = { width: this.gl.drawingBufferWidth, height: this.gl.drawingBufferHeight };
    const geometryPerspectiveMatrix = transposeM4(perspectiveM4(this.sunFov, size, this.sunNear, this.sunFar)); // prettier-ignore
    this.sunMatrixProjectionFloat32Array = new Float32Array(geometryPerspectiveMatrix);

    this.sunMatrixRenderedTimeOfDay = this.sun.timeOfDay;
  }

  /**
   * Create single buffer for each data type of all meshes
   * Deletes individual mesh data
   */
  private makeBuffers(meshes: FPMesh[]) {
    // create a single buffer containing vertex data of all meshes

    const noData32 = new Float32Array(0);
    const noData16 = new Uint16Array(0);

    const positionData: number[] = [];
    const expandCoordData: number[] = [];
    const texCoordData: number[] = [];
    const normalData: number[] = [];
    let offsetPositions = 0;
    let offsetExpandCoords = 0;
    let offsetTexCoords = 0;
    let offsetNormals = 0;
    for (let i = 0; i < meshes.length; i += 1) {
      const mesh = meshes[i];

      positionData.push(...mesh.positions);
      expandCoordData.push(...mesh.expandCoords);
      texCoordData.push(...mesh.texCoords);
      normalData.push(...mesh.normals);

      mesh.dataOffsetPositions = offsetPositions * 4;
      mesh.dataOffsetExpandCoords = offsetExpandCoords * 4;
      mesh.dataOffsetTexCoords = offsetTexCoords * 4;
      mesh.dataOffsetNormals = offsetNormals * 4;

      mesh.dataNum = mesh.positions.length / 3;
      offsetPositions += mesh.positions.length;
      offsetExpandCoords += mesh.expandCoords.length;
      offsetTexCoords += mesh.texCoords.length;
      offsetNormals += mesh.normals.length;

      if (mesh.positions.length !== mesh.normals.length && mesh.normals.length > 0) {
        console.error('positions and normals length mismatch', mesh.positions.length, mesh.normals.length, mesh);
      }

      // clear all original data

      if (Math.PI === 4) {
        // @todo -- rem
        mesh.positions = noData32;
        mesh.expandCoords = noData32;
        mesh.texCoords = noData32;
        mesh.normals = noData32;
        mesh.triangles = noData16;
      } else {
        console.log('FloorPlan3DProgram::makeBuffers:skipping clearing original data for debugging  @todo -- rem this');
      }
    }
    // console.log('make buffers done offsetPositions=', offsetPositions * 4 * 3, 'offsetExpandCoords=', offsetExpandCoords * 4 * 3, 'offsetTexCoords=', offsetTexCoords * 4 * 2, 'offsetNormals=', offsetNormals * 4 * 3); // prettier-ignore

    this.positionBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.positionBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(positionData), this.gl.STATIC_DRAW);

    this.expandCoordBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.expandCoordBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(expandCoordData), this.gl.STATIC_DRAW);

    this.texCoordBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.texCoordBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(texCoordData), this.gl.STATIC_DRAW);

    this.normalBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.normalBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(normalData), this.gl.STATIC_DRAW);

    // console.log('buffers wrtten', 'pos', positionData.length, 'exp', expandCoordData.length, 'tex', texCoordData.length, 'norm', normalData.length); // prettier-ignore

    this.positionData = positionData;
  }

  private centerOnRoomByPosition(pos: number[]) {
    const res = this.meshRaycaster.raycastPoint([pos[0], pos[1] + 10, pos[2]]); // 10 cm above the ground, fired downwards
    this.navigation.centerOnRoomByMesh(res?.mesh ?? null, pos);
    this.camera.setCenter(pos);
  }
}

export default FloorPlan3DProgram;
