import { InfoPageLayout } from "components/layout";
import { useQuery } from "hooks";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { NotSupportedBrowserIcon, PeopleIcon } from "../icons";

const ErrorPage: FC = () => {
  const query = useQuery();
  const { id } = useParams();
  // const navigate = useNavigate();
  const [errorCode, setErrorCode] = useState<number | null>(null);

  useEffect(() => {
    const error = query.get("error");

    if (error) {
      setErrorCode(parseInt(error, 10));
    }
  }, [id, query]);

  const handleIconRender = () => {
    if (errorCode === 53105) {
      return <PeopleIcon className="h-[4.6rem] w-[4.6rem]" />;
    }

    return <NotSupportedBrowserIcon className="h-[4.6rem] w-[4.6rem]" />;
  };

  const handelMsgRender = () => {
    if (errorCode === 1) {
      return "This browser is not supported";
    }

    if (errorCode === 53105) {
      return "Unfortunately, this session has reached the maximum number of participants";
    }

    return "Something went wrong.";
  };

  const handleParagraphRender = () => {
    if (errorCode === 1) {
      return "Please use a supported browser – Google Chrome, Mozilla Firefox or Safari to improve your experience.";
    }
    return undefined;
  };

  return (
    <InfoPageLayout
      icon={handleIconRender()}
      headLine={handelMsgRender()}
      paragraph={handleParagraphRender()}
    />
  );
};

export default ErrorPage;
