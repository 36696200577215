import type { Milliseconds } from '@g360/vt-types';

import type { GenericFunction } from '.';

export type ThrottledFunction<TFunc extends GenericFunction<TFunc>> = TFunc & {
  cancel: () => void;
  flush: () => ReturnType<TFunc> | undefined;
  isOnCooldown: () => boolean;
  isPending: () => boolean;
};

export type ThrottleOptions = {
  leading?: boolean;
  trailing?: boolean;
};

/**
 * Creates a throttled version of a function.
 *
 * When called: If the function is not on a cooldown and `leading = true`, invokes the function immediately and starts the cooldown timer.
 *
 * When called: If the function is on a cooldown and `trailing=true`, delays the function invocation until the cooldown timer has expired.
 *
 * Calls the function with the last arguments.
 *
 * **Methods:**
 * - `cancel()` - `void`: cancels the pending invocation of the throttled function, if any, and destroys the cooldown timer.
 * - `flush()` - `ReturnType<TFunc>`: immediately invokes the throttled function, if there were any pending invocations, and destroys the cooldown timer.
 * - `isOnCooldown()` - `boolean`: returns `true` if the debounced function is on cooldown.
 * - `isPending()` - `boolean`: returns `true` if the debounced function is set to invoke.
 *
 * @example
 * const throttled = throttle(() => console.log("Throttled!"), 1000);
 *
 * throttled();
 * throttled();
 * // => "Throttled!" is logged once per second.
 * @param functionToThrottle `TFunc`: The function to throttle.
 * @param cooldown `Milliseconds`: How long to delay the next `functionToDebounce` invocation.
 * @param throttleOptions `ThrottleOptions`: Additional options.
 * @param throttleOptions.leading `boolean`: If `true`, `functionToThrottle` is called on the leading edge of the cooldown, aka the first call. Default value is: `true`.
 * @param throttleOptions.trailing `boolean`: If `true`, `functionToThrottle` is called on the trailing edge of the cooldown. Default value is: `true`.
 * @returns - `ThrottledFunction`: A throttled version of `functionToThrottle` with `cancel`, `flush`, `isOnCooldown` and `isPending` methods.
 */
export default function throttle<TFunc extends GenericFunction<TFunc>>(
  functionToThrottle: TFunc,
  cooldown: Milliseconds = 0,
  throttleOptions?: ThrottleOptions
): ThrottledFunction<TFunc> {
  let leading = true;
  let trailing = true;

  let pending = false;

  let lastThis: unknown;
  let lastArgs: Parameters<TFunc> | undefined;

  let result: ReturnType<TFunc> | undefined;

  let timeoutId: ReturnType<typeof setTimeout> | undefined;
  let lastCallTime: Milliseconds | undefined;
  let lastInvokeTime: Milliseconds | undefined;

  if (throttleOptions) {
    leading = throttleOptions.leading ?? leading;
    trailing = throttleOptions.trailing ?? trailing;
  }

  function invoke(now: Milliseconds): ReturnType<TFunc> | undefined {
    lastInvokeTime = now;

    result = lastArgs === undefined ? functionToThrottle.apply(lastThis) : functionToThrottle.apply(lastThis, lastArgs);
    return result;
  }

  function onTimerExpired(): ReturnType<TFunc> | undefined {
    timeoutId = undefined;

    if (lastCallTime === undefined) {
      pending = false;
      return result;
    }

    if (lastInvokeTime !== undefined && lastInvokeTime >= lastCallTime) {
      pending = false;
      return result;
    }

    if (!trailing || !pending) {
      pending = false;
      return result;
    }

    pending = false;
    timeoutId = setTimeout(onTimerExpired, cooldown);

    invoke(Date.now());
    return result;
  }

  function throttled(this: unknown, ...args: Parameters<TFunc>): ReturnType<TFunc> | undefined {
    lastCallTime = Date.now();

    lastThis = this;
    lastArgs = args;

    // First call
    if (!timeoutId) {
      // First call, invoke because leading is true
      if (leading) {
        pending = false;
        timeoutId = setTimeout(onTimerExpired, cooldown);

        invoke(lastCallTime);
        return result;
      }

      // First call, don't invoke because leading is false
      pending = true;
      timeoutId = setTimeout(onTimerExpired, cooldown);

      return result;
    }

    // Consequent calls
    pending = true;

    return result;
  }

  throttled.cancel = (): void => {
    clearTimeout(timeoutId);
    timeoutId = undefined;

    pending = false;

    lastThis = undefined;
    lastArgs = undefined;
  };

  // Arrow functions can't have `this` as a parameter
  // eslint-disable-next-line func-names
  throttled.flush = function (this: unknown, ...args: Parameters<TFunc>): ReturnType<TFunc> | undefined {
    clearTimeout(timeoutId);
    timeoutId = undefined;

    pending = false;

    if (lastCallTime === lastInvokeTime) return result;

    lastThis = this;
    lastArgs = args;

    return invoke(Date.now());
  };

  throttled.isOnCooldown = (): boolean => timeoutId !== undefined;

  throttled.isPending = (): boolean => pending;

  return throttled as ThrottledFunction<TFunc>;
}
