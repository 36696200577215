import type {
  EngineEventMap,
  GuidedViewingConfig,
  GuidedViewingCurrentTourState,
  GuidedViewingInfoHotSpotStreamEvent,
  GuidedViewingKeyframeType,
  GuidedViewingMinimapLayoutStreamEvent,
  GuidedViewingMinimapNavigationStreamEvent,
  GuidedViewingMinimapPinMenuEvent,
  GuidedViewingMinimapStreamEvent,
  GuidedViewingTourConfigStreamEvent,
  GuidedViewingTourStreamEvent,
  GuidedViewingUiLayoutStreamEvent,
  Radian,
  SceneConfig,
  ScenePos,
  SyncData,
} from '@g360/vt-types';
import { linearScale } from '@g360/vt-utils/';
import { animationFrames, animationFrameScheduler, BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import {
  auditTime,
  bufferWhen,
  endWith,
  filter,
  map,
  observeOn,
  pairwise,
  startWith,
  switchMap,
  takeWhile,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { Mixin } from 'ts-mixer';

import Utils from '../common/Utils';
import EventEmitter from './EventEmitter';

abstract class GuidedViewing extends Mixin(EventEmitter) {
  static readonly throttleTimeout = 32;
  // Maximum allowed camera pitch/yaw difference before it is animated
  static readonly maxCameraDiff = Utils.toRad(5);
  static readonly cameraDiffAnimationDuration = 800;

  /** This is used for info hot-spot sync to decide if host needs to delay the animation for balloon opening */
  public isGVAnimatingCamera = false;

  // Specifies whether the scene move event be replayed by the normal buffer or fast replay (after scene move events)
  private bufferActive = false;

  private incomingMinimapEventStream$?: Observable<GuidedViewingMinimapStreamEvent>;
  private outgoingMinimapEventStream$?: Observable<GuidedViewingMinimapStreamEvent>;

  private incomingTourEventStream$?: Observable<GuidedViewingTourStreamEvent>;
  private outgoingTourEventStream$?: Observable<GuidedViewingTourStreamEvent>;

  private incomingUiLayoutEventStream$?: Observable<GuidedViewingUiLayoutStreamEvent>;
  private outgoingUiLayoutEventStream$?: Observable<GuidedViewingUiLayoutStreamEvent>;

  private incomingTourConfigEventStream$?: Observable<GuidedViewingTourConfigStreamEvent>;
  private outgoingTourConfigEventStream$?: Observable<GuidedViewingTourConfigStreamEvent>;

  private incomingHotSpotEventStream$?: Observable<GuidedViewingInfoHotSpotStreamEvent>;
  private outgoingHotSpotEventStream$?: Observable<GuidedViewingInfoHotSpotStreamEvent>;

  private pitchCached: Radian = 0;
  private yawCached: Radian = 0;
  private subscribedToInfoHotSpots = false;

  protected abstract guidedViewingConfig: GuidedViewingConfig;
  protected abstract _yaw: number;
  protected abstract _pitch: number;
  protected abstract zoomRatio: number;
  protected abstract animatingTransition;
  protected abstract sceneTransition;
  protected abstract get activeSceneConfig(): SceneConfig;
  protected abstract set activeSceneConfig(sceneConfig: SceneConfig);
  protected abstract canvas: HTMLCanvasElement;

  static isPingKeyframe = (keyframe: GuidedViewingTourStreamEvent) => {
    if (keyframe.start.eventType?.indexOf('ping') !== -1) {
      return true;
    }

    if (keyframe.end.eventType?.indexOf('ping') !== -1) {
      return true;
    }

    return false;
  };

  emitGuidedViewingMinimapEvent(data: GuidedViewingMinimapStreamEvent) {
    if (data.source === 'minimap-layout') {
      this.emit('minimap.layout.change', data);
    }
    if (data.source === 'minimap-navigation') {
      this.emit('minimap.navigation.change', data);
    }
    if (data.source === 'minimap-pin-menu') {
      this.emit('minimap.pinMenu.change', data);
    }
  }

  emitGuidedViewingUiLayoutEvent(data: GuidedViewingUiLayoutStreamEvent) {
    this.emit('ui.layout.change', data);
  }

  emitGuidedViewingTourConfigEvent(data: GuidedViewingTourConfigStreamEvent) {
    this.emit('tour.config.change', data);
  }

  receiveGuidedViewingMinimapEvent(data: GuidedViewingMinimapStreamEvent) {
    if (data.source === 'minimap-layout') {
      this.emit('minimap.layout.remoteUpdate', data);
    }
    if (data.source === 'minimap-navigation') {
      this.emit('minimap.navigation.remoteUpdate', data);
    }
    if (data.source === 'minimap-pin-menu') {
      this.emit('minimap.pinMenu.remoteUpdate', data);
    }
  }

  receiveGuidedViewingUiLayoutEvent(data: GuidedViewingUiLayoutStreamEvent) {
    this.emit('ui.layout.remoteUpdate', data);
  }

  receiveGuidedViewingTourConfigEvent(data: GuidedViewingTourConfigStreamEvent) {
    this.emit('tour.config.remoteUpdate', data);
  }

  receiveGuidedViewingHotSpotEvent(data: GuidedViewingInfoHotSpotStreamEvent) {
    this.emit('hotspots.info.remoteUpdate', data);
  }

  protected startGuidedViewingObservers(): void {
    this.initIncomingTourEvents();
    this.initOutgoingTourEvents();

    this.initIncomingTourConfigEvents();
    this.initOutgoingTourConfigEvents();

    this.initIncomingMiniMapEvents();
    this.initOutgoingMiniMapEvents();

    this.initIncomingLayoutEvents();
    this.initOutgoingLayoutEvents();

    this.initIncomingHotSpotEvents();
    this.initOutgoingHotSpotEvents();

    // Subscribe to info hot-spot events
    if (!this.subscribedToInfoHotSpots) {
      this.subscribedToInfoHotSpots = true;

      this.subscribe('hotspots.info.click', (data) => {
        if (this.guidedViewingConfig.isInControlOfTour) {
          const [pitchDeg, yawDeg] = data.config.pos;

          const pitchOffset = Utils.calculateAngularDifference(
            this._pitch,
            this.getClampedPitch(Utils.toRad(pitchDeg))
          );
          const yawOffset = Utils.calculateAngularDifference(
            this._yaw,
            Utils.subtractAngle(Utils.toRad(yawDeg), this.getYawOffset())
          );
          const targetYaw = this._yaw - yawOffset;

          // If the camera is already close to the target position, don't animate
          this.isGVAnimatingCamera = false;
          if (
            Math.abs(pitchOffset) > GuidedViewing.maxCameraDiff ||
            Math.abs(yawOffset) > GuidedViewing.maxCameraDiff
          ) {
            this.isGVAnimatingCamera = true;
            this.cameraAnimateTo(
              { pitch: Utils.toRad(pitchDeg), yaw: targetYaw },
              'ping',
              GuidedViewing.cameraDiffAnimationDuration
            );
          }

          this.emit('hotspots.info.change', {
            source: 'info-hotspot',
            isAnimating: this.isGVAnimatingCamera,
            ...data,
          });
        }
      });

      this.subscribe('hotspots.info.release', () => {
        this.emit('hotspots.info.change', {
          source: 'info-hotspot',
        });
      });
    }
  }

  // Outgoing Events

  private initOutgoingTourEvents(): void {
    if (this.outgoingTourEventStream$) return;

    const tourEvents$ = new Observable<GuidedViewingCurrentTourState>((observer) => {
      const eventTypes: (keyof EngineEventMap)[] = [
        'scene.move.update',
        'scene.anim.update',
        'scene.transition.start',
        'scene.transition.end',
      ];

      for (let i = 0; i < eventTypes.length; i += 1) {
        this.subscribe(eventTypes[i], () => {
          const data = {
            yaw: this._yaw,
            pitch: this._pitch,
            timestamp: new Date().getTime(),
            sceneKey: this.activeSceneConfig.sceneKey,
            zoomRatio: this.zoomRatio,
            eventType: eventTypes[i],
          };
          observer.next(data);
        });
      }
    });

    const pingEvents$ = new Observable<GuidedViewingCurrentTourState>((observer) => {
      const eventTypes: (keyof EngineEventMap)[] = ['scene.ping.start', 'scene.ping.update', 'scene.ping.end'];

      for (let i = 0; i < eventTypes.length; i += 1) {
        this.subscribe(
          eventTypes[i] as 'scene.ping.end' | 'scene.ping.start' | 'scene.ping.update',
          (currentPos: ScenePos<Radian>, targetPos: ScenePos<Radian>, progress: number) => {
            const data = {
              yaw: currentPos.yaw,
              pitch: currentPos.pitch,
              timestamp: new Date().getTime(),
              sceneKey: this.activeSceneConfig.sceneKey,
              zoomRatio: this.zoomRatio,
              eventType: eventTypes[i],
              progress,
              targetPos,
            };
            observer.next(data);
          }
        );
      }
    });

    this.outgoingTourEventStream$ = merge(
      tourEvents$.pipe(throttleTime(GuidedViewing.throttleTimeout)),
      pingEvents$
    ).pipe(
      filter(() => this.guidedViewingConfig.isInControlOfTour),
      pairwise(),
      map(([prevData, newData]) => ({
        start: prevData ?? newData,
        end: newData,
        source: 'tour',
      }))
    );

    this.outgoingTourEventStream$.subscribe(this.guidedViewingConfig.onHostEmit);
  }

  private initOutgoingMiniMapEvents(): void {
    if (this.outgoingMinimapEventStream$) return;

    const minimapEvents$ = new Observable<GuidedViewingMinimapStreamEvent>((observer) => {
      this.subscribe('minimap.layout.change', (data: GuidedViewingMinimapLayoutStreamEvent) => {
        observer.next(data);
      });
      this.subscribe('minimap.navigation.change', (data: GuidedViewingMinimapNavigationStreamEvent) => {
        observer.next(data);
      });
      this.subscribe('minimap.pinMenu.change', (data: GuidedViewingMinimapPinMenuEvent) => {
        observer.next(data);
      });
    });

    this.outgoingMinimapEventStream$ = minimapEvents$.pipe(filter(() => this.guidedViewingConfig.isInControlOfTour));
    this.outgoingMinimapEventStream$.subscribe(this.guidedViewingConfig.onHostEmit);
  }

  private initOutgoingLayoutEvents(): void {
    if (this.outgoingUiLayoutEventStream$) return;

    const uiLayoutEvents$ = new Observable<GuidedViewingUiLayoutStreamEvent>((observer) => {
      this.subscribe('ui.layout.change', (data: GuidedViewingUiLayoutStreamEvent) => {
        observer.next(data);
      });
    });

    this.outgoingUiLayoutEventStream$ = uiLayoutEvents$.pipe(filter(() => this.guidedViewingConfig.isInControlOfTour));
    this.outgoingUiLayoutEventStream$.subscribe(this.guidedViewingConfig.onHostEmit);
  }

  private initOutgoingTourConfigEvents(): void {
    if (this.outgoingTourConfigEventStream$) return;

    const tourConfigEvents$ = new Observable<GuidedViewingTourConfigStreamEvent>((observer) => {
      this.subscribe('tour.config.change', (data: GuidedViewingTourConfigStreamEvent) => {
        observer.next(data);
      });
    });

    this.outgoingTourConfigEventStream$ = tourConfigEvents$.pipe(
      filter(() => this.guidedViewingConfig.isInControlOfTour)
    );

    this.outgoingTourConfigEventStream$.subscribe(this.guidedViewingConfig.onHostEmit);
  }

  private initOutgoingHotSpotEvents(): void {
    if (this.outgoingHotSpotEventStream$) return;

    const hotSpotEvents$ = new Observable<GuidedViewingInfoHotSpotStreamEvent>((observer) => {
      this.subscribe('hotspots.info.change', (data: GuidedViewingInfoHotSpotStreamEvent) => {
        observer.next(data);
      });
    });

    this.outgoingHotSpotEventStream$ = hotSpotEvents$.pipe(filter(() => this.guidedViewingConfig.isInControlOfTour));

    this.outgoingHotSpotEventStream$.subscribe(this.guidedViewingConfig.onHostEmit);
  }

  // Incoming Events

  private initIncomingMiniMapEvents() {
    if (this.incomingMinimapEventStream$) return;

    const filterFn = (data) =>
      !this.guidedViewingConfig.isInControlOfTour &&
      ['minimap-layout', 'minimap-navigation', 'minimap-pin-menu'].includes(data.source);

    this.incomingMinimapEventStream$ = new Observable<GuidedViewingMinimapStreamEvent>(
      this.guidedViewingConfig.onGuestReceive
    ).pipe(filter(filterFn));

    this.incomingMinimapEventStream$.subscribe((data) => {
      this.receiveGuidedViewingMinimapEvent(data);
    });
  }

  private initIncomingLayoutEvents() {
    if (this.incomingUiLayoutEventStream$) return;

    const filterFn = (data) => !this.guidedViewingConfig.isInControlOfTour && ['ui-layout'].includes(data.source);

    this.incomingUiLayoutEventStream$ = new Observable<GuidedViewingUiLayoutStreamEvent>(
      this.guidedViewingConfig.onGuestReceive
    ).pipe(filter(filterFn));

    this.incomingUiLayoutEventStream$.subscribe((data) => {
      this.receiveGuidedViewingUiLayoutEvent(data);
    });
  }

  private initIncomingTourConfigEvents() {
    if (this.incomingTourConfigEventStream$) return;

    const filterFn = (data) => !this.guidedViewingConfig.isInControlOfTour && ['tour-config'].includes(data.source);

    this.incomingTourConfigEventStream$ = new Observable<GuidedViewingTourConfigStreamEvent>(
      this.guidedViewingConfig.onGuestReceive
    ).pipe(filter(filterFn));

    this.incomingTourConfigEventStream$.subscribe((data) => {
      this.receiveGuidedViewingTourConfigEvent(data);
    });
  }

  private initIncomingHotSpotEvents() {
    if (this.incomingHotSpotEventStream$) return;

    const filterFn = (data) => !this.guidedViewingConfig.isInControlOfTour && ['info-hotspot'].includes(data.source);

    this.incomingHotSpotEventStream$ = new Observable<GuidedViewingInfoHotSpotStreamEvent>(
      this.guidedViewingConfig.onGuestReceive
    ).pipe(filter(filterFn));

    this.incomingHotSpotEventStream$.subscribe((data) => {
      this.receiveGuidedViewingHotSpotEvent(data);
    });
  }

  private initIncomingTourEvents() {
    if (this.incomingTourEventStream$) return;

    const filterFn = (data) => !this.guidedViewingConfig.isInControlOfTour && ['tour'].includes(data.source);

    this.incomingTourEventStream$ = new Observable<GuidedViewingTourStreamEvent>(
      this.guidedViewingConfig.onGuestReceive
    ).pipe(filter(filterFn));

    // Handle scene change events
    this.incomingTourEventStream$
      .pipe(filter((data) => this.activeSceneConfig.sceneKey !== data.end.sceneKey))
      .subscribe((data) => {
        // enable buffer during the transition.
        this.bufferActive = true;
        this.loadSceneKey(data.end.sceneKey);
      });

    // Subject that triggers the release of the buffer
    const triggerBufferRelease$ = new Subject();

    // release the first buffer after accumulating them for 500 ms since the first move event
    this.incomingTourEventStream$
      .pipe(
        filter((data) => !this.bufferActive && this.activeSceneConfig.sceneKey === data.end.sceneKey),
        auditTime(500)
      )
      .subscribe(() => {
        this.bufferActive = true;
        triggerBufferRelease$.next(true);
      });

    // also release the first buffer when the scene change is over
    this.subscribe('scene.transition.end', () => {
      triggerBufferRelease$.next(true);
    });

    // Stream accumulates the events in a buffer and emits buffer
    // when the triggerBufferRelease$ subject emits.
    this.incomingTourEventStream$
      .pipe(
        filter((data) => this.activeSceneConfig.sceneKey === data.end.sceneKey),
        bufferWhen(() => triggerBufferRelease$),
        switchMap((buffer) => {
          if (buffer.length > 0) {
            return this.replayBuffer(buffer).pipe(
              tap({
                // trigger the next buffer release when the previous buffer has finished rendering
                complete: () => {
                  triggerBufferRelease$.next(true);
                },
              })
            );
          }
          // If the buffer is executed and no more events in the buffer
          // then return null, which stops the buffer and enables back the main stream.
          return of(null);
        })
      )
      .subscribe((syncData: SyncData | null) => {
        if (syncData) {
          this.onGuidedViewingSceneMove(syncData);
        } else {
          // Also signals that there are no active buffers happening
          this.bufferActive = false;
        }
      });
  }

  // Processing

  private processKeyFrame(data: GuidedViewingTourStreamEvent): GuidedViewingKeyframeType {
    const keyframe = {
      yaw: {
        start: data.start.yaw,
        diff: data.end.yaw - data.start.yaw,
      },
      pitch: {
        start: data.start.pitch,
        diff: data.end.pitch - data.start.pitch,
      },
      zoomRatio: {
        start: data.start.zoomRatio,
        diff: data.end.zoomRatio - data.start.zoomRatio,
      },
      duration: data.end.timestamp - data.start.timestamp,
    };

    if (keyframe.duration > GuidedViewing.throttleTimeout * 6) {
      keyframe.duration = GuidedViewing.throttleTimeout;
    }

    // This is ping animation keyframe
    if (GuidedViewing.isPingKeyframe(data)) {
      if (data.end.eventType === 'scene.ping.start') {
        // Initial keyframe - set to current scene position
        keyframe.pitch = { start: this._pitch, diff: 0 };
        keyframe.yaw = { start: this._yaw, diff: 0 };

        // Cache current pitch and yaw so the next keyframes can be properly interpolated
        this.pitchCached = this._pitch;
        this.yawCached = this._yaw;
      } else if (data.end.targetPos && data.start.progress !== undefined && data.end.progress !== undefined) {
        // Interpolate between current scene position and target position using progress
        const fromPitch = this.pitchCached || this._pitch;
        const fromYaw = this.yawCached || this._yaw;
        const toPitch = data.end.targetPos.pitch;
        const toYaw = data.end.targetPos.yaw;

        keyframe.pitch.start = linearScale(data.start.progress, [0, 100], [fromPitch, toPitch]);
        keyframe.pitch.diff = linearScale(data.end.progress, [0, 100], [fromPitch, toPitch]) - keyframe.pitch.start;
        keyframe.yaw.start = linearScale(data.start.progress, [0, 100], [fromYaw, toYaw]);
        keyframe.yaw.diff = linearScale(data.end.progress, [0, 100], [fromYaw, toYaw]) - keyframe.yaw.start;
      }

      if (
        (Math.abs(keyframe.pitch.diff) > GuidedViewing.maxCameraDiff ||
          Math.abs(keyframe.yaw.diff) > GuidedViewing.maxCameraDiff) &&
        data.start.eventType !== data.end.eventType
      ) {
        // This is guig from final ping keyframe to regular move/anim keyframe but with large pitch/yaw diff.
        // Need to animate slowly in this case.
        keyframe.duration = GuidedViewing.cameraDiffAnimationDuration;
      }
    }

    return keyframe;
  }

  private tweenKeyframe(data: GuidedViewingTourStreamEvent) {
    const keyframe = this.processKeyFrame(data);

    if (!keyframe.yaw.diff && !keyframe.pitch.diff && !keyframe.zoomRatio.diff) {
      return of({
        yaw: keyframe.yaw.start + keyframe.yaw.diff,
        pitch: keyframe.pitch.start + keyframe.pitch.diff,
        zoomRatio: keyframe.zoomRatio.start + keyframe.zoomRatio.diff,
      }).pipe(observeOn(animationFrameScheduler));
    }

    return animationFrames().pipe(
      map(({ elapsed }) => elapsed / keyframe.duration),
      startWith(0),
      pairwise(),
      // If the next ratio is too close to 1 then just finish with 1 to avoid redundant animation frames
      takeWhile(([prevRatio, newRatio]) => newRatio + (newRatio - prevRatio) / 2 < 1),
      map(([, newRatio]) => newRatio),
      endWith(1),
      map((v: number) => ({
        yaw: v * keyframe.yaw.diff + keyframe.yaw.start,
        pitch: v * keyframe.pitch.diff + keyframe.pitch.start,
        zoomRatio: v * keyframe.zoomRatio.diff + keyframe.zoomRatio.start,
      }))
    );
  }

  private replayBuffer(data: GuidedViewingTourStreamEvent[]) {
    const tweenSubject$ = new BehaviorSubject<GuidedViewingTourStreamEvent>(data[0]);

    return tweenSubject$.pipe(
      map((event, index) => ({ event, index })),
      takeWhile(({ index }) => index + 1 < data.length),
      endWith({ event: data[data.length - 1], index: data.length - 1 }),
      switchMap(({ event, index }) =>
        this.tweenKeyframe(event).pipe(
          tap({
            // trigger the next buffer release when the previous buffer has finished rendering
            complete: () => tweenSubject$.next(data[index + 1]),
          })
        )
      )
    );
  }

  protected abstract loadSceneKey(sceneKey: string): void;
  protected abstract onGuidedViewingSceneMove(syncData: SyncData): void;
  protected abstract cameraAnimateTo(targetPos: ScenePos<Radian>, animType, duration?: number);
  protected abstract getYawOffset(): Radian;
  protected abstract getClampedPitch(customPitch?: Radian): Radian;
}

export default GuidedViewing;
