import { Participant } from "components/elements";
import { FC } from "react";
import { RemoteParticipant } from "twilio-video";

interface RemoteParticipantsWrapperProps {
  isHost: boolean;
  roomHostIdentity: string;
  inControlParticipant: string;
  isControlButtonMargin: boolean;
  remoteParticipants: RemoteParticipant[];
  handleClickControlSwitch: (id: string) => void;
  selectedAudioOutput: MediaDeviceInfo | undefined | null;
}

const RemoteParticipantsWrapper: FC<RemoteParticipantsWrapperProps> = ({
  isHost,
  roomHostIdentity,
  remoteParticipants,
  inControlParticipant,
  isControlButtonMargin,
  handleClickControlSwitch,
  selectedAudioOutput,
}) => {
  return (
    <>
      {remoteParticipants.map((participant) => (
        <Participant
          isRemote
          isHost={isHost}
          isInControl={inControlParticipant === participant.identity}
          key={participant.sid}
          participant={participant}
          roomHostIdentity={roomHostIdentity}
          isControlBtnDisplayed={remoteParticipants.length >= 1}
          isControlButtonMargin={isControlButtonMargin}
          handleClickControlSwitch={handleClickControlSwitch}
          selectedAudioOutput={selectedAudioOutput}
        />
      ))}
    </>
  );
};

export default RemoteParticipantsWrapper;
