import { SVGProps } from "react";

const ArrowRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 18 30"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <path
      d="m18.207 24.207 4.5-4.5a.999.999 0 0 0 0-1.414l-4.5-4.5a1 1 0 0 0-1.414 1.414L20.586 19l-3.793 3.793a1 1 0 0 0 1.414 1.414Z"
      transform="translate(-44 -36) scale(2.66667)"
    />
  </svg>
);

export default ArrowRightIcon;
