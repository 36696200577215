export default class KeyboardRotation {
  private readonly moveCameraFn: (deltaPos: { pitch: number; yaw: number }) => void;

  private rotateKeyUp = false;
  private rotateKeyDown = false;
  private rotateKeyLeft = false;
  private rotateKeyRight = false;
  private rotateKeyAnimationInterval: NodeJS.Timeout | null = null;
  private rotateKeyUpTime = 0;
  private rotateKeyDownTime = 0;
  private rotateKeyLeftTime = 0;
  private rotateKeyRightTime = 0;

  public constructor(moveCameraFn: (deltaPos: { pitch: number; yaw: number }) => void) {
    this.moveCameraFn = moveCameraFn;
  }

  public start(htmlEl: HTMLCanvasElement) {
    this.resetKeyRotate();
    htmlEl.addEventListener('keydown', this.handleKeyPressDown.bind(this));
    htmlEl.addEventListener('keyup', this.handleKeyPressUp.bind(this));
  }

  public stop(htmlEl: HTMLCanvasElement) {
    this.stopKeyRotateAnimation();
    htmlEl.removeEventListener('keydown', this.handleKeyPressDown.bind(this));
    htmlEl.removeEventListener('keyup', this.handleKeyPressUp.bind(this));
  }

  private handleKeyPressDown(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowUp':
        this.rotateKeyUp = true;
        break;
      case 'ArrowDown':
        this.rotateKeyDown = true;
        break;
      case 'ArrowLeft':
        this.rotateKeyLeft = true;
        break;
      case 'ArrowRight':
        this.rotateKeyRight = true;
        break;
      default:
        break;
    }
    this.startKeyRotateAnimation();
  }

  private handleKeyPressUp(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowUp':
        this.rotateKeyUp = false;
        break;
      case 'ArrowDown':
        this.rotateKeyDown = false;
        break;
      case 'ArrowLeft':
        this.rotateKeyLeft = false;
        break;
      case 'ArrowRight':
        this.rotateKeyRight = false;
        break;
      default:
        break;
    }
  }

  private resetKeyRotate() {
    this.rotateKeyUp = false;
    this.rotateKeyDown = false;
    this.rotateKeyLeft = false;
    this.rotateKeyRight = false;
  }

  private doKeyRotateAnimation() {
    const maxAccel = 20;
    const accelPerTick = 1;

    const up = this.rotateKeyUp ? 1 : 0;
    const down = this.rotateKeyDown ? 1 : 0;
    const left = this.rotateKeyLeft ? 1 : 0;
    const right = this.rotateKeyRight ? 1 : 0;

    this.rotateKeyUpTime = Math.min(maxAccel, this.rotateKeyUpTime + accelPerTick);
    this.rotateKeyDownTime = Math.min(maxAccel, this.rotateKeyDownTime + accelPerTick);
    this.rotateKeyLeftTime = Math.min(maxAccel, this.rotateKeyLeftTime + accelPerTick);
    this.rotateKeyRightTime = Math.min(maxAccel, this.rotateKeyRightTime + accelPerTick);

    this.rotateKeyUpTime = up === 0 ? 0 : this.rotateKeyUpTime;
    this.rotateKeyDownTime = down === 0 ? 0 : this.rotateKeyDownTime;
    this.rotateKeyLeftTime = left === 0 ? 0 : this.rotateKeyLeftTime;
    this.rotateKeyRightTime = right === 0 ? 0 : this.rotateKeyRightTime;

    const deltaUp = up * this.rotateKeyUpTime - down * this.rotateKeyDownTime;
    const deltaLeft = right * this.rotateKeyRightTime - left * this.rotateKeyLeftTime;

    if (deltaUp !== 0 || deltaLeft !== 0) {
      const deltaPos = {
        pitch: deltaUp * 0.001,
        yaw: deltaLeft * 0.001,
      };
      this.moveCameraFn(deltaPos);
    } else {
      this.stopKeyRotateAnimation();
    }
  }

  private startKeyRotateAnimation() {
    if (this.rotateKeyAnimationInterval !== null) return;
    this.rotateKeyAnimationInterval = setInterval(this.doKeyRotateAnimation.bind(this), 1000 / 120); // 120 fps, even the browser can't render that fast
  }

  private stopKeyRotateAnimation() {
    if (this.rotateKeyAnimationInterval === null) return;
    clearInterval(this.rotateKeyAnimationInterval);
    this.rotateKeyAnimationInterval = null;
  }
}
