import { GiraffeLogoIcon } from "components/icons";
import { Lobby, VideoRoom } from "components/module";
import { MediaErrorModal } from "components/elements";
import { use100vh, useAppStateContext, useConnectionIssues } from "hooks";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { classNamesUtil } from "utils/classNamesUtil";

const VideoChatPageContent: FC = () => {
  useConnectionIssues(3000);
  const { divRef } = use100vh();
  const navigate = useNavigate();
  const { error, token } = useAppStateContext();

  // Guided Viewing was not found in DB
  if (error === "Not found.") {
    navigate("/guided-viewing-not-found", { replace: true });
  }

  const renderVideoChatPageContent = () => {
    if (!token) {
      return (
        <div ref={divRef} className={classNamesUtil("flex flex-col w-full")}>
          <div className="w-11/12 mx-auto m-2.5 md:t-5 md:w-4/5 lg:w-5/6">
            <GiraffeLogoIcon className="h-10" />
          </div>
          <div className="w-11/12 mx-auto md:w-4/5 lg:w-5/6 max-w-[1290px] h-full flex pt-5 md:pt-10 lg:pt-44 flex-col justify-start lg:justify-center">
            <Lobby />
          </div>
        </div>
      );
    }
    return <VideoRoom />;
  };

  return (
    <>
      {renderVideoChatPageContent()}
      <MediaErrorModal />
    </>
  );
};

export default VideoChatPageContent;
