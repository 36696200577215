import { Modal } from "components/layout";
import { PrimaryButton } from "components/elements";
import { FC } from "react";
import { useBrowser, useDeviceStateContext, useMixpanelContext } from "hooks";

const MediaErrorModal: FC = () => {
  const browser = useBrowser();
  const { Mixpanel, MixpanelEvents } = useMixpanelContext();
  const { activeMediaErrorModal, setActiveMediaErrorModal } =
    useDeviceStateContext();

  const mediaErrorTexts = {
    microphone: [
      {
        browsers: ["Google Chrome or Chromium", "Mozilla Firefox"],
        title: "Microphone is blocked",
        upperText:
          "Please make sure you have allowed access to microphone in your Browser Settings.",
        lowerText:
          "Click the blocked microphone/camera icon in your browser’s address bar.",
      },
      {
        browsers: ["default"],
        title: "Allow access to your microphone",
        upperText:
          "Guided viewing needs access to your microphone so that other participants can hear you.",
        lowerText:
          "Try refreshing the page and start the session again through the lobby.",
      },
    ],
    camera: [
      {
        browsers: ["Google Chrome or Chromium", "Mozilla Firefox"],
        title: "Camera is blocked",
        upperText:
          "Please make sure you have allowed access to camera in your Browser Settings.",
        lowerText:
          " Click the blocked camera/microphone icon in your browser’s address bar.",
      },
      {
        browsers: ["default"],
        title: "Allow access to your camera",
        upperText:
          "Guided viewing needs access to your camera so that other participants can see you.",
        lowerText:
          "Try refreshing the page and start the session again through the lobby.",
      },
    ],
  };

  const renderContent = () => {
    if (!activeMediaErrorModal) return null;
    let content = mediaErrorTexts[activeMediaErrorModal].find((texts) =>
      texts.browsers.includes(browser)
    );
    if (!content) {
      content = mediaErrorTexts[activeMediaErrorModal].find((texts) =>
        texts.browsers.includes("default")
      );
    }

    return (
      <>
        <p className="mb-6 text-base font-bold leading-6 font-primary">
          {content?.title}
        </p>
        <p className="mb-8 text-sm text-giraffe-gray-darkest leading-6">
          {content?.upperText}
        </p>
        <p className="mb-10 text-sm text-giraffe-gray-darkest leading-6">
          {content?.lowerText}
        </p>
      </>
    );
  };

  return (
    <Modal
      isCloseX={false}
      modalWidth="medium"
      isModalVisible={!!activeMediaErrorModal}
      handleCancelClick={() => setActiveMediaErrorModal(null)}
    >
      <div className="pt-8 pb-6 px-11">
        {renderContent()}
        <div className="flex items-center justify-end w-[100px] ml-auto">
          <PrimaryButton
            text="Dismiss"
            size="small"
            handleButtonClick={() => {
              Mixpanel.track(MixpanelEvents.Button.Click("Dismiss"));
              setActiveMediaErrorModal(null);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default MediaErrorModal;
