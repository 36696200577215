import { useInterval } from "hooks";
import { useState } from "react";

const useInternetSpeed = (
  imgUrl: string,
  downloadSizeBytes: number,
  delay: number
) => {
  const [isSlowInternet, setIsSlowInternet] = useState<boolean>(false);
  const [lastCheckedIsSlowInternet, setLastCheckedIsSlowInternet] =
    useState<boolean>(false);

  const MeasureConnectionSpeed = () => {
    let endTime: number;
    let startTime: number;

    const download = new Image();
    download.onload = function () {
      endTime = new Date().getTime();
      showResults();
    };

    download.onerror = function (err, msg) {
      console.error("Invalid image, or error downloading", err, msg);
    };

    startTime = new Date().getTime();
    const cacheBuster = "?nnn=" + startTime;
    download.src = imgUrl + cacheBuster;

    const showResults = () => {
      const duration = (endTime - startTime) / 1000;
      const bitsLoaded = downloadSizeBytes * 8;
      const speedBps = (bitsLoaded / duration).toFixed(2);
      const speedKbps = (parseFloat(speedBps) / 1024).toFixed(2);
      const speedMbps = (parseFloat(speedKbps) / 1024).toFixed(2);

      // No throttling
      // speedMbps 0.24
      // speedMbps 0.31
      // speedMbps 0.35

      // Fasts 3G
      // speedMbps 0.25
      // speedMbps 0.24
      // speedMbps 0.25

      // Slow 3G
      // speedMbps 0.10
      // speedMbps 0.07
      // speedMbps 0.06

      const currentIsSlowInternet = 0.13 >= parseFloat(speedMbps);
      // Check internet speed in two steps. Give "benefit of the doubt" because of the tour loading. Also don't want it to disappear too quickly.
      if (lastCheckedIsSlowInternet === currentIsSlowInternet) {
        setIsSlowInternet(currentIsSlowInternet);
      } else {
        setLastCheckedIsSlowInternet(currentIsSlowInternet);
      }
    };
  };

  useInterval(MeasureConnectionSpeed, delay);

  return isSlowInternet;
};

export default useInternetSpeed;
