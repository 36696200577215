/* eslint-disable no-continue,class-methods-use-this */

const directions = [
  [0, -1],
  [1, 0],
  [0, 1],
  [-1, 0], // Cardinal
  [-1, -1],
  [1, -1],
  [1, 1],
  [-1, 1], // Diagonal
];

export class PixelHolder {
  readonly width: number;
  readonly height: number;
  readonly pixels: Uint16Array; // 0..255 are colors, above 255 are padding colors

  readonly skippedColor = 800; // skipped to be later interpolated by neighbors
  readonly paddingColor = 1000; // not written yet
  // ----------- values greater than 300 are not yet checked for AO------
  readonly toBeIgnored = 300; // not to be checked for AO
  readonly paddingColorFinal = 255; // empty space (nothing was written here), but as color to be saved in png

  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
    this.pixels = new Uint16Array(width * height);
    for (let i = 0; i < this.pixels.length; i += 1) {
      this.pixels[i] = this.paddingColor;
    }
  }

  setPixel(x: number, y: number, value: number) {
    this.pixels[y * this.width + x] = value;
  }

  getPixel(x: number, y: number): number {
    return this.pixels[y * this.width + x];
  }

  /**
   * Is the color not written (or written as skipped)
   * i.e. not yet calculated
   */
  isThisColorFree(color: number) {
    return color > 300;
  }

  /**
   * Is the color calculated and valid AO value
   * i.e. color to be drawn in .png
   */
  isThisColorValid(color: number) {
    return color <= 255;
  }

  /**
   * Extend the pixels by one pixel in each direction for each UV "island"
   */
  extendIslands() {
    // read from a snapshot of pixels and write to current pixels
    const oldPixels: Uint16Array = new Uint16Array(this.width * this.height);
    for (let i = 0; i < oldPixels.length; i += 1) {
      oldPixels[i] = this.pixels[i];
    }

    for (let y = 0; y < this.height; y += 1) {
      for (let x = 0; x < this.width; x += 1) {
        const color = oldPixels[y * this.width + x];

        if (!this.isThisColorValid(color)) {
          for (let i = 0; i < directions.length; i += 1) {
            const [dx, dy] = directions[i];
            const neighborColor = oldPixels[(y + dy) * this.width + x + dx];
            if (neighborColor === undefined) continue; // out of bounds prolly
            if (neighborColor && this.isThisColorValid(neighborColor)) {
              this.setPixel(x, y, neighborColor);
              break;
            }
          }
        }
      }
    }
  }

  /**
   * Remove padding color from pixels
   * This must be done after all parts are combined
   */
  removePaddingColor() {
    for (let i = 0; i < this.pixels.length; i += 1) {
      if (this.pixels[i] === this.paddingColor) {
        this.pixels[i] = this.paddingColorFinal;
      }
    }
  }

  /**
   * @param data -- same size as this object's texture, but will take only non-padding colors
   */
  addPatch(data: number[]) {
    for (let i = 0; i < data.length; i += 1) {
      const c = data[i];
      if (c > this.paddingColorFinal) continue;
      this.pixels[i] = c;
    }
  }

  /**
   * Randomize colors by adding noise
   */
  addNoise(noise: number) {
    for (let i = 0; i < this.pixels.length; i += 1) {
      let color = this.pixels[i];
      if (color >= this.paddingColorFinal) continue;
      color += (Math.random() * noise - noise / 2) * 255;
      color = Math.max(0, Math.min(255, color));
      this.pixels[i] = Math.round(color);
    }
  }

  /**
   * This blur preserves UV "islands" and blurs only inside them
   */
  blur(radius: number, edgeThreshold: number) {
    // blur a copy of pixels, read originals
    const tempPixels = new Uint16Array(this.width * this.height);

    for (let y = 0; y < this.height; y += 1) {
      for (let x = 0; x < this.width; x += 1) {
        const centerValue = this.getPixel(x, y);
        if (centerValue === this.paddingColor) continue;

        let totalWeight = 0;
        let weightedSum = 0;

        for (let dy = -radius; dy <= radius; dy += 1) {
          for (let dx = -radius; dx <= radius; dx += 1) {
            const neighborX = x + dx;
            const neighborY = y + dy;

            if (neighborX >= 0 && neighborX < this.width && neighborY >= 0 && neighborY < this.height) {
              const neighborValue = this.getPixel(neighborX, neighborY);
              if (neighborValue === this.paddingColor) continue;
              const spatialWeight = 1 / (1 + dx * dx + dy * dy);
              const valueDifference = Math.abs(centerValue - neighborValue);
              const edgeWeight = valueDifference < edgeThreshold ? 1 : 0;

              const weight = spatialWeight * edgeWeight;
              totalWeight += weight;
              weightedSum += weight * neighborValue;
            }
          }
        }

        tempPixels[y * this.width + x] = totalWeight > 0 ? Math.round(weightedSum / totalWeight) : centerValue;
      }
    }

    // copy back
    for (let i = 0; i < this.pixels.length; i += 1) {
      if (this.pixels[i] !== this.paddingColor) {
        this.pixels[i] = tempPixels[i];
      }
    }
  }
}
