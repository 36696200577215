import type { ClipSpace, GoToSnapshotParams, Pixel, Pos, Radian, ScenePos, SceneSpeed } from '@g360/vt-types';
import { isInIframe, linearScale, toDeg, toRad } from '@g360/vt-utils/';
import { Gesture } from '@use-gesture/vanilla';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import { interval, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeWith, pairwise, scan, throttle } from 'rxjs/operators';

import {
  MODE_SWITCH_CLICK_THRESHOLD,
  MODE_SWITCH_ZOOM_TIME_OUT,
  MODE_SWITCH_ZOOM_TIME_OUT_FRUSTRATION,
} from '../../common/Globals';
import Utils, { boundToRange, canvasToCamera, xyToPos } from '../../common/Utils';
import type { SelectedHotSpot } from '../../types/internal';
import BaseController from '../BaseController';
import { isThis3DHotSpotHovered, isThisSpriteHotSpotHovered } from '../utils';
import KeyboardRotation from './KeyboardRotation';
import type { DragEvent, ExtDragEvent, MoveEvent, ZoomEvent } from './types';

export default class GestureController extends BaseController {
  private static readonly speedRange: [number, number] = [toRad(-180), toRad(180)];
  private static zoomSessionId: number | null = null;
  private static lastZoomEventTime = 0;
  /** "zoom frustration" is ignored zoom event near the mode switch event,
   * to prevent "zooming out to max" event also being "swithing to next mode" event  */
  private static firstZoomFrustrationTime: number | null = null;

  protected onMove?: () => void;
  protected selectedHotSpot: SelectedHotSpot = { index: -1 };
  protected isTransitioning = false;
  private cleanUp = noop;
  private lastTap = 0;
  private keyboardRotation = new KeyboardRotation((deltaPos) => {
    this.moveCamera(this.getFinalScenePos(deltaPos));
  });

  connect(): void {
    if (!this.connected) {
      super.connect();
      this.cleanUp = this.addEventListeners(); // addEventListeners returns a cleanUp function
      GestureController.zoomSessionId = null;
      GestureController.firstZoomFrustrationTime = null;
    }
  }

  disconnect(): void {
    this.cleanUp();
    this.cleanUp = noop;
    super.disconnect();
  }

  // camera pitch/yaw are in degrees, fov is in radians
  public async goToSnapshot({ scene, camera, actions }: GoToSnapshotParams) {
    // Close all things (hotspots) if necessary.
    await this.emptyClickCallback();

    const { pitch: currentUnNormalisedPitch, yaw: currentUnNormalisedYaw, sceneKey } = this.getEngineState();
    // Move camera to essentially same pitch and yaw, but normalised to 0-2PI
    await this.moveCamera({
      yaw: currentUnNormalisedYaw % (2 * Math.PI),
      pitch: currentUnNormalisedPitch % (2 * Math.PI),
    });

    // If scene is different, change scene
    if (scene && scene !== sceneKey) {
      await this.changeScene(scene);
    }

    // Move camera to new position
    if (camera) {
      const { yawOffset, yaw: currentNormalisedYaw } = this.getEngineState();

      let yaw = (toRad(camera.yaw) % (2 * Math.PI)) - yawOffset;
      let pitch = toRad(camera.pitch) % (2 * Math.PI);

      // Make sure that the yaw is the shortest distance to the current yaw
      const yawDiff = Math.abs(yaw - currentNormalisedYaw);
      if (yawDiff > Math.PI) {
        yaw = yaw > currentNormalisedYaw ? yaw - 2 * Math.PI : yaw + 2 * Math.PI;
      }

      if (pitch > Math.PI / 2) {
        pitch -= Math.PI * 2;
      } else if (pitch < -Math.PI / 2) {
        pitch += Math.PI * 2;
      }

      const duration = Math.min(1000, Math.max(100, Math.abs((yawDiff % Math.PI) / Math.PI) * 1000));
      await this.moveCamera({ ...camera, yaw, pitch, duration });
    }

    if (actions?.selectedHotSpot) {
      const { hotSpots } = this.getEngineState();

      if (!hotSpots) return;

      const selectedHotSpot = {
        index: hotSpots.findIndex((hotSpot) => hotSpot.originalConfig?.id === actions.selectedHotSpot),
      };

      this.clickHotSpot(selectedHotSpot);
      this.setSelectedHotSpot(selectedHotSpot);
    }
  }

  public handleInfoHotSpotHover(hotSpotId: string | null) {
    const { hotSpots } = this.getEngineState();

    if (!hotSpots) return;

    const selectedHotSpot = { index: hotSpots.findIndex((hotSpot) => hotSpot.originalConfig?.id === hotSpotId) };
    this.setSelectedHotSpot(selectedHotSpot);
  }

  public handleSceneHotSpotHover(sceneKey: string | null) {
    const { hotSpots } = this.getEngineState();

    if (!hotSpots) return;

    const selectedHotSpot = { index: hotSpots.findIndex((hotSpot) => hotSpot.originalConfig?.target === sceneKey) };
    this.setSelectedHotSpot(selectedHotSpot);
  }

  protected addEventListeners() {
    const inIframe = isInIframe();
    const dragSubject$ = new Subject<DragEvent>();
    const moveSubject$ = new Subject<MoveEvent>();
    const wheelSubject$ = new Subject<ZoomEvent>();
    const pinchSubject$ = new Subject<ZoomEvent>();

    // Recommended by the @use-gesture
    if (this.canvas) {
      this.canvas.style.touchAction = 'none';
      this.canvas.style.setProperty('-webkit-tap-highlight-color', 'transparent');

      // This is only to explicitly set wheel event listener with passive: false
      // to optionally preventDefault, that stops the scrolling of the parent window
      // if correct keys are pressed
      if (inIframe) {
        this.canvas.addEventListener(
          'wheel',
          (event) => {
            const allowedScroll = event.metaKey || event.ctrlKey;
            if (allowedScroll) {
              event.preventDefault(); // zooming correctly, just don't propagate the event to the parent window
              this.emit('scene.zoom.succeeded');
            } else {
              this.emit('scene.zoom.prevented');
            }
          },
          { passive: false }
        );

        this.canvas.addEventListener('click', this.handleDoubleTapDetection.bind(this));
        this.canvas.addEventListener('touchend', this.handleDoubleTapDetection.bind(this));
      }
    }

    let isDragging = false;
    const gesture = new Gesture(
      this.canvas,
      {
        onDragStart: (state: any) => {
          isDragging = true;
          dragSubject$.next({ xy: state.xy, type: 'start', timestamp: Date.now() });
        },
        onDrag: (state: any) => {
          isDragging = true;
          dragSubject$.next({ xy: state.xy, type: 'drag', timestamp: Date.now() });
        },
        onDragEnd: (state: any) => {
          dragSubject$.next({
            xy: state.xy,
            type: 'end',
            timestamp: Date.now(),
            initial: state.initial,
          });
          isDragging = false;
        },
        onMove: (state: any) => {
          moveSubject$.next({ xy: state.xy, timestamp: Date.now() });
        },
        onWheel: (state: any) => {
          const allowedScroll = !inIframe || state.event.metaKey || state.event.ctrlKey;
          if (!allowedScroll) return;
          this.registerZoomStart();
          const zoomDelta = state.event.deltaY > 0 ? 25 : -25;
          wheelSubject$.next({ delta: zoomDelta, source: 'wheel', sessionId: GestureController.zoomSessionId });
        },
        onPinchStart: (state: any) => {
          this.registerZoomStart();
          pinchSubject$.next({ delta: state.da[0], source: 'pinch-start', sessionId: GestureController.zoomSessionId });
        },
        onPinch: (state: any) => {
          pinchSubject$.next({ delta: state.da[0], source: 'pinch', sessionId: GestureController.zoomSessionId });
        },
      },
      {
        transform: ([x, y]) => [x - (this.boundingRect?.left || 0), y - (this.boundingRect?.top || 0)],
      }
    );

    const zoomEvents$ = pinchSubject$.pipe(
      distinctUntilChanged((prev, curr) => prev.delta === curr.delta),
      pairwise(),
      // ignore the event if its 'pinch-start' and just keep it to the pairwise memory for next event
      filter(([, current]) => current.source !== 'pinch-start'),
      map(([prev, current]) => {
        const deltaDist = prev.delta - current.delta;
        const horizontalAngleDelta = this.getScenePosDelta({ x: 0, y: 0 }, { x: deltaDist, y: 0 }).yaw;
        return { delta: toDeg(horizontalAngleDelta) * 3.0, source: current.source, sessionId: current.sessionId };
      }),
      mergeWith(wheelSubject$.pipe(throttle(() => interval(100))))
    );

    const zoomSubscription = zoomEvents$.pipe(filter(() => !this.isTransitioning)).subscribe((event: ZoomEvent) => {
      // if the zoom event started in a different controller instance, ignore it
      if (event.sessionId === null) return;
      // Extracted to a separate function so editors can override it
      this.onZoom(event);
    });

    const moveSubscription = moveSubject$
      .pipe(
        filter(() => !this.isTransitioning && !isDragging),
        distinctUntilChanged((prev, current) => isEqual(prev.xy, current.xy))
      )
      .subscribe(({ xy: [x, y] }) => {
        const selectedHotSpot = this.getHotSpotOnPosition({ x, y });
        this.setSelectedHotSpot(selectedHotSpot);

        if (this.onMove) {
          this.onMove();
        }
      });

    const dragSubscription = dragSubject$
      .pipe(
        filter(() => !this.isTransitioning),
        distinctUntilChanged((prev, current) => prev.type === current.type && isEqual(prev.xy, current.xy)),
        scan<DragEvent, ExtDragEvent>(
          (prev, current) => {
            const currentPos = Utils.xyToPos(current.xy);
            const lastPos = Utils.xyToPos(prev.xy);
            const deltaPos = this.getScenePosDelta(currentPos, lastPos);

            if (current.type === 'start' || prev.type === 'end') {
              this.sceneMoveStart(this.getFinalScenePos());

              return {
                ...current,
                sceneSpeed: { pitch: 0, yaw: 0 },
                deltaPos: { pitch: 0, yaw: 0 },
                isMoved: false,
              };
            }

            const sceneSpeed = this.getSceneSpeed(deltaPos, current.timestamp - prev.timestamp, prev.sceneSpeed);

            return {
              ...current,
              sceneSpeed,
              deltaPos,
              isMoved: lastPos.x !== currentPos.x || lastPos.y !== currentPos.y,
            };
          },
          {
            type: 'end',
            xy: [0, 0],
            timestamp: Date.now(),
            initial: [0, 0],
            sceneSpeed: { pitch: 0, yaw: 0 },
            deltaPos: { pitch: 0, yaw: 0 },
            isMoved: false,
          }
        ),
        filter(({ type, isMoved }) => type !== 'drag' || isMoved)
      )
      .subscribe((current) => {
        this.onDrag(current);
      });

    let canvas = document.querySelector('#container canvas');
    if (canvas) {
      this.keyboardRotation.start(canvas as HTMLCanvasElement);
      canvas.setAttribute('tabindex', '0');
    }

    const cleanUp = () => {
      this.selectedHotSpot = { index: -1 };
      moveSubscription.unsubscribe();
      dragSubscription.unsubscribe();
      zoomSubscription.unsubscribe();
      gesture.destroy();
      canvas = document.querySelector('#container canvas');
      if (canvas) {
        this.keyboardRotation.stop(canvas as HTMLCanvasElement);
      }
    };

    return cleanUp;
  }

  protected onZoom({ delta, source }: ZoomEvent) {
    this.zoomPano(delta, source !== 'wheel');
  }

  protected onDrag(current: ExtDragEvent) {
    const currentPos = Utils.xyToPos(current.xy);
    switch (current.type) {
      case 'start': {
        const selectedHotSpot = this.getHotSpotOnPosition(currentPos);
        this.setSelectedHotSpot(selectedHotSpot);
        // stop any ongoing animation
        this.moveCamera({});
        break;
      }
      case 'drag': {
        this.moveCamera(this.getFinalScenePos(current.deltaPos));
        break;
      }
      case 'end': {
        this.moveCameraEnd(this.getFinalScenePos(current.deltaPos));

        const initialPos = Utils.xyToPos(current.initial);
        const distance = Math.sqrt((currentPos.x - initialPos.x) ** 2 + (currentPos.y - initialPos.y) ** 2);

        if (this.selectedHotSpot.index > -1 && distance < MODE_SWITCH_CLICK_THRESHOLD) {
          this.clickSelectedHotSpot();
        } else {
          this.moveCameraToTargetPos(current);
        }

        break;
      }
      default:
        console.warn(`type is not supported`);
    }
  }

  protected getHotSpotOnPosition({ x, y }: Pos<Pixel>): SelectedHotSpot {
    const mouseClipSpace: Pos<ClipSpace> = {
      x: Utils.toClipSpace(x, this.boundingRect.width),
      y: -Utils.toClipSpace(y, this.boundingRect.height),
    };

    const mousePixels = { x, y };
    const { hotSpots } = this.getEngineState();
    const allTargeted = hotSpots.filter((hotSpot) => {
      // TODO: this should not happen but somehow it happens in the Editor app. find the root cause
      if (!hotSpot || hotSpot.disabled) return false;

      const {
        clipSpacePos,
        clipSpaceSize,
        clipSpacePosAround,
        arrowClipSpacePos,
        arrowClipSpaceSize,
        arrowVisible,
        obstructed,
        obstructedByHs,
        farAway,
        type,
      } = hotSpot;
      const hotSpotTargetable = !obstructed && !obstructedByHs && !farAway;
      const hotSpot3D = type === 'normal'; // currently only normal (direct) hotspots are 3D, rest are 2D sprites
      const screenSize = { width: this.boundingRect.width, height: this.boundingRect.height };

      return (
        (hotSpotTargetable && hotSpot3D && isThis3DHotSpotHovered(mouseClipSpace, clipSpacePosAround)) ||
        (arrowVisible &&
          hotSpot3D &&
          isThisSpriteHotSpotHovered(arrowClipSpacePos, arrowClipSpaceSize, mousePixels, screenSize)) ||
        (!hotSpot3D && isThisSpriteHotSpotHovered(clipSpacePos, clipSpaceSize, mousePixels, screenSize))
      );
    });

    if (allTargeted.length === 0) {
      return { index: -1 };
    }

    const distance = (position: Pos<ClipSpace> | null) => {
      if (!position) return Number.POSITIVE_INFINITY;
      return Math.sqrt((position.x - mouseClipSpace.x) ** 2 + (position.y - mouseClipSpace.y) ** 2); // generally on computer screen, x is bigger than y and vice versa on mobiles
    };

    // sort all targeted and find the closest one to the cursor
    allTargeted.sort((a, b) => {
      const arrowDistanceA = distance(a.arrowClipSpacePos);
      const hotSpotDistanceA = distance(a.clipSpacePos);
      const arrowDistanceB = distance(b.arrowClipSpacePos);
      const hotSpotDistanceB = distance(b.clipSpacePos);

      // either arrow or the HS that is closest to mouse for each hotspot
      const closestItemToMouseA = Math.min(arrowDistanceA, hotSpotDistanceA);
      const closestItemToMouseB = Math.min(arrowDistanceB, hotSpotDistanceB);

      if (closestItemToMouseA < closestItemToMouseB) {
        return -1;
      }
      if (closestItemToMouseA > closestItemToMouseB) {
        return 1;
      }
      return 0;
    });

    const bestHs = allTargeted[0];
    const isArrow = distance(bestHs.arrowClipSpacePos) < distance(bestHs.clipSpacePos);
    return { index: hotSpots.findIndex((h) => h === bestHs), isArrow };
  }

  protected setSelectedHotSpot(selectedHotSpot: SelectedHotSpot) {
    if (
      this.selectedHotSpot.index !== selectedHotSpot.index ||
      this.selectedHotSpot.isArrow !== selectedHotSpot.isArrow
    ) {
      this.selectedHotSpot = selectedHotSpot;
      this.selectHotSpot(selectedHotSpot);
      this.canvas.style.cursor = selectedHotSpot.index > -1 ? 'pointer' : 'default';
    }
  }

  protected moveCamera(patch: {
    yaw?: Radian;
    pitch?: Radian;
    fov?: Radian;
    animate?: boolean | undefined;
    duration?: number | undefined;
  }) {
    return this.changeCamera({ ...this.getEngineState(), ...patch });
  }

  protected moveCameraToTargetPos(
    current: { xy: [x: Pixel, y: Pixel]; timestamp: number } & { type: 'end'; initial: [x: Pixel, y: Pixel] } & {
      sceneSpeed: SceneSpeed;
      deltaPos: ScenePos<Radian>;
      isMoved: boolean;
    }
  ) {
    const targetScenePos: ScenePos<Radian> = this.getPosAfterStop(current);
    const yawVec = targetScenePos.yaw - this.yaw;
    const pitchVec = targetScenePos.pitch - this.pitch;
    const distRad = Math.sqrt(yawVec ** 2 + pitchVec ** 2);
    if (distRad > 0.1 && (current.sceneSpeed.pitch !== 0 || current.sceneSpeed.yaw !== 0)) {
      this.moveCamera({ ...targetScenePos, animate: true, duration: 1000 });
    }
  }

  protected moveCameraEnd(finalScenePos: ScenePos<Radian>): void {
    this.sceneMoveEnd(finalScenePos);
  }

  protected async clickSelectedHotSpot() {
    this.isTransitioning = true;
    await this.clickHotSpot(this.selectedHotSpot);
    this.isTransitioning = false;
  }

  protected getFinalScenePos(delta?: ScenePos): ScenePos<Radian> {
    const { pitch, yaw, fov } = this.getEngineState();

    if (!delta) return { pitch, yaw, fov };

    return {
      pitch: delta.pitch + pitch,
      yaw: delta.yaw + yaw,
      fov,
    };
  }

  protected getScenePosDelta(eventPos: Pos<Pixel>, lastPos: Pos<Pixel>): ScenePos<Radian> {
    const p1 = canvasToCamera(lastPos, this.boundingRect, this.fov);
    const p2 = canvasToCamera(eventPos, this.boundingRect, this.fov);
    return {
      pitch: p1.pitch - p2.pitch,
      yaw: p1.yaw - p2.yaw,
    };
  }

  protected getSceneSpeed = (posDelta: ScenePos, deltaTime: number, prevSceneSpeed: SceneSpeed): SceneSpeed => {
    let pitchSpeed = 0;
    let yawSpeed = 0;

    if (deltaTime > 30) {
      return { pitch: 0, yaw: 0 };
    }

    if (deltaTime > 0) {
      pitchSpeed = boundToRange((posDelta.pitch / deltaTime) * 1000, GestureController.speedRange); // rad/s
      yawSpeed = boundToRange((posDelta.yaw / deltaTime) * 1000, GestureController.speedRange); // rad/s
    }

    // Average speed of last two move-events to reduce abrupt terminations
    const sceneSpeed = {
      pitch: (pitchSpeed + (prevSceneSpeed.pitch || pitchSpeed)) / 2,
      yaw: (yawSpeed + (prevSceneSpeed.yaw || yawSpeed)) / 2,
    };

    return sceneSpeed;
  };

  private getPosAfterStop(current: { sceneSpeed: SceneSpeed; initial: [number, number]; xy: [number, number] }) {
    const sceneSpeed = current.sceneSpeed;
    const initial = xyToPos(current.initial);
    const currentPixelPos = xyToPos(current.xy);

    const initialScenePos = canvasToCamera(initial, this.boundingRect, this.fov);
    const currentScenePos = canvasToCamera(currentPixelPos, this.boundingRect, this.fov);
    const distDeg = Math.sqrt(
      toDeg(initialScenePos.yaw - currentScenePos.yaw) ** 2 + toDeg(initialScenePos.pitch - currentScenePos.pitch) ** 2
    );

    const speedRatio = linearScale(distDeg, [0, 50], [0, 1], true);

    const targetScenePos: ScenePos = {
      pitch: this.pitch + sceneSpeed.pitch * speedRatio,
      yaw: this.yaw + sceneSpeed.yaw * speedRatio,
    };

    return targetScenePos;
  }

  /**
   * This tries to combat superzoom event that scrolls out of pano mode and keeps scrolling well into floorplan mode (or vice versa)
   * by ignoring zoom just after the switch between the controllers.
   */
  // eslint-disable-next-line class-methods-use-this -- using static members since ths is a cross-instance solution
  private registerZoomStart() {
    // if no input for a while, count it as a new zoom gesture
    const zoomFreshness = Date.now() - GestureController.lastZoomEventTime;
    if (zoomFreshness > MODE_SWITCH_ZOOM_TIME_OUT) {
      GestureController.zoomSessionId = performance.now();
    }
    GestureController.lastZoomEventTime = Date.now();

    // After switching controllers, zoomSessionId is reset
    // and letting go of zooming for a little while re-enables it.
    // But if you keep on scrolling after switching controllers, there is frustration timeout
    // after this zooming is re-enabled.
    if (GestureController.zoomSessionId === null) {
      if (GestureController.firstZoomFrustrationTime === null) {
        GestureController.firstZoomFrustrationTime = Date.now();
      }

      if (Date.now() - GestureController.firstZoomFrustrationTime > MODE_SWITCH_ZOOM_TIME_OUT_FRUSTRATION) {
        GestureController.zoomSessionId = performance.now();
      }
    }
  }

  private handleDoubleTapDetection(): void {
    const currentTime = new Date().getTime();
    const tapLength = currentTime - this.lastTap;
    if (tapLength < 400 && tapLength > 10) {
      // 10ms is the minimum time between taps, otherwise single tap is erroneously detected as double tap
      this.emit('scene.interaction.doubleTap');
    }
    this.lastTap = currentTime;
  }
}
