import type { AnalyticsContextType, AnalyticsEvent, Milliseconds, Pixel, Size } from '@g360/vt-types';
import chunk from 'lodash/chunk';
import urljoin from 'url-join';

import EventEmitter from '../EventEmitter';
import type { DebouncedFunction } from '../misc';
import throttle from '../misc/throttle';
import AnalyticsLoadingHelper from './AnalyticsEventHelpers/AnalyticsLoadingHelper';
import AnalyticsMiniMapDragHelper from './AnalyticsEventHelpers/AnalyticsMiniMapDragHelper';
import AnalyticsMiniMapZoomHelper from './AnalyticsEventHelpers/AnalyticsMiniMapZoomHelper';
import AnalyticsResizeHelper from './AnalyticsEventHelpers/AnalyticsResizeHelper';
import AnalyticsSceneDragHelper from './AnalyticsEventHelpers/AnalyticsSceneDragHelper';
import AnalyticsSceneInertiaHelper from './AnalyticsEventHelpers/AnalyticsSceneInertiaHelper';
import AnalyticsSceneZoomHelper from './AnalyticsEventHelpers/AnalyticsSceneZoomHelper';
import getCookiePreferences from './getCookiePreferences';
import getOrCreateSessionId from './getOrCreateSessionId';

export type AnalyticsPubSubEvents = 'analytics.activated' | 'analytics.deactivated' | 'analytics.event';

/**
 * A class that handles analytics events, accumulates and sends them to the backend in chunks periodically.
 * It should be de facto a singleton, only one instance should be created and used everywhere: in Player and in Engine.
 *
 * Disabled by default and needs to be enabled explicitly by calling `analytics.setIsEnabled(true)`.
 * There are company IDs that are blacklisted and analytics should be disabled for them.
 *
 * Disallowed by default and needs to be allowed by cookies explicitly by calling `analytics.setIsAllowed(true)`.
 * Also should be enabled in iframes.
 *
 * `env.REACT_APP_ANALYTICS_BASE_URL` should be set for analytics to work.
 *
 * Internally creates several helper classes that handle specific types of events related to scene/minimap dragging,
 * zooming, window resizing and scene/measuretool loading. The helper classes subscribe to the main class pubsub events,
 * to the engine pubsub events and to the window events.
 *
 * @example
 * To generate an event:
 * ```
 * analytics.push<TDetails>(event: string, feature: string, element: string, details?: TDetails);
 * ```
 */
export default class Analytics {
  /** Determines how many events one request holds. If total amount of events is greater than this, several requests are sent. */
  private static readonly MAX_EVENTS = 8;
  /** Determines how often we send event. Always waits for this long after the first event is added to the pending list. */
  private static readonly SEND_TIMEOUT_DELAY: Milliseconds = 5000;

  /** Project ID from `tour.json`. */
  private projectId: string;
  /** Company ID from `tour.json`. */
  private companyId_: string;
  /** Tour ID from tour-variant.json */
  private tourId_?: string;

  /** An instance of a helper class that handles load events and time-related things like loading time, idle time, active time. */
  private analyticsLoadingHelper_: AnalyticsLoadingHelper;
  /** An instance of a helper class that handles window resize events.
   * Subscribes to events internally, ignore `is declared but its value is never read` error. */
  private analyticsResizeHelper_: AnalyticsResizeHelper;
  /** An instance of a helper class that handles drag events in the VT. */
  private analyticsSceneDragHelper_: AnalyticsSceneDragHelper;
  /** An instance of a helper class that handles inertia animation events in the VT after dragging. */
  private analyticsSceneInertiaHelper_: AnalyticsSceneInertiaHelper;
  /** An instance of a helper class that handles zoom events in the VT. */
  private analyticsSceneZoomHelper_: AnalyticsSceneZoomHelper;
  /** An instance of a helper class that handles drag events in the minimap. */
  private analyticsMiniMapDragHelper_: AnalyticsMiniMapDragHelper;
  /** An instance of a helper class that handles zoom events in the minimap. */
  private analyticsMiniMapZoomHelper_: AnalyticsMiniMapZoomHelper;

  /** A publish-subscribe event emitter. Helper classes subscribe to its events. */
  private pubSub_: EventEmitter<Record<AnalyticsPubSubEvents, unknown[]>>;

  /** a unique ID of the current virtual tour visit. */
  private visitId: string;
  /** `true` when `isEnabled` and `isAllowed` are `true` and `env.REACT_APP_ANALYTICS_BASE_URL` is set. */
  private isActive_ = false;
  /** Determines if analytics is enabled. if `tourConfig.analyticsEnabled` is explicitly set to `false`, analytics will remain disabled.
   * There are also blacklisted company IDs that have it disabled.
   * If enabled, analytics still need permission from cookies to start registering events. */
  private isEnabled_ = false;
  /** Determines if analytics have permission from cookies or if in iframe. */
  private isAllowed_ = false;
  /** A list of analytics event pending to be sent to the backend. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private events: AnalyticsEvent<any>[] = [];
  /** Backend analytics URL. */
  private analyticsBaseURL: string;

  /**
   * Sends all pending events to the analytics backend. A throttled version of {@link sendEvents}.
   * Limits how often we generate resize analytics events to one every `5000 ms`.
   * Always waits for this long after the first event is added to the pending list.
   *
   * Splits events into chunks of 8 events and sends them in several requests.
   * @returns `void`
   */
  private sendEventsThrottled: DebouncedFunction<() => void>;

  /**
   * @param projectId - `string`: project ID from `tour.json`.
   * @param companyId - `string`: company ID from `tour.json`.
   */
  constructor(projectId: string, companyId: string, tourId?: string) {
    this.visitId = '';
    this.projectId = projectId;
    this.companyId_ = companyId;
    this.tourId_ = tourId;

    this.analyticsBaseURL = process.env.REACT_APP_ANALYTICS_BASE_URL ?? '';

    this.pubSub_ = new EventEmitter();

    this.analyticsLoadingHelper_ = new AnalyticsLoadingHelper(this);
    this.analyticsResizeHelper_ = new AnalyticsResizeHelper(this);
    this.analyticsSceneDragHelper_ = new AnalyticsSceneDragHelper(this);
    this.analyticsSceneInertiaHelper_ = new AnalyticsSceneInertiaHelper(this);
    this.analyticsSceneZoomHelper_ = new AnalyticsSceneZoomHelper(this);
    this.analyticsMiniMapDragHelper_ = new AnalyticsMiniMapDragHelper(this);
    this.analyticsMiniMapZoomHelper_ = new AnalyticsMiniMapZoomHelper(this);

    this.sendEvents = this.sendEvents.bind(this);

    this.sendEventsThrottled = throttle(this.sendEvents, Analytics.SEND_TIMEOUT_DELAY, { leading: false });

    this.readCookiePreferences();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const x = this.isActive;
  }

  /**
   * @returns `boolean`: `true` when `isEnabled` and`isAllowed` are `true` and `env.REACT_APP_ANALYTICS_BASE_URL` is set. Otherwise, `false`.
   */
  public get isActive(): boolean {
    return this.isActive_;
  }

  /**
   * @returns `AnalyticsLoadingHelper`: an instance of a helper class that handles load events and time-related things like loading time, idle time, active time.
   */
  public get analyticsTimeHelper(): AnalyticsLoadingHelper {
    return this.analyticsLoadingHelper_;
  }

  /**
   * @returns `AnalyticsSceneDragHelper`: an instance of a helper class that handles drag events in the VT.
   */
  public get analyticsSceneDragHelper(): AnalyticsSceneDragHelper {
    return this.analyticsSceneDragHelper_;
  }

  /**
   * @returns `AnalyticsSceneInertiaHelper`: an instance of a helper class that handles inertia animation events in the VT after dragging.
   */
  public get analyticsSceneInertiaHelper(): AnalyticsSceneInertiaHelper {
    return this.analyticsSceneInertiaHelper_;
  }

  /**
   * @returns `AnalyticsSceneZoomHelper`: an instance of a helper class that handles zoom events in the VT.
   */
  public get analyticsSceneZoomHelper(): AnalyticsSceneZoomHelper {
    return this.analyticsSceneZoomHelper_;
  }

  /**
   * @returns `AnalyticsMiniMapDragHelper`: an instance of a helper class that handles drag events in the minimap.
   */
  public get analyticsMiniMapDragHelper(): AnalyticsMiniMapDragHelper {
    return this.analyticsMiniMapDragHelper_;
  }

  /**
   * @returns `AnalyticsMiniMapZoomHelper`: an instance of a helper class that handles zoom events in the minimap.
   */
  public get analyticsMiniMapZoomHelper(): AnalyticsMiniMapZoomHelper {
    return this.analyticsMiniMapZoomHelper_;
  }

  /**
   * @returns `EventEmitter<Record<string, any[]>>`: a publish-subscribe event emitter. Helper classes subscribe to its events.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public get pubSub(): EventEmitter<Record<string, any[]>> {
    return this.pubSub_;
  }

  /**
   * Enable or disable the analytics.
   * @param isEnabled - `boolean`: `true` if the analytics is enabled. Otherwise, `false`.
   * @returns `void`
   */
  public setIsEnabled(isEnabled: boolean): void {
    this.isEnabled_ = isEnabled;
    this.updateIsActive();
  }

  /**
   * Allow or disallow the analytics.
   * @param isAllowed - `boolean`: `true` if the user allowed analytics by cookies or if in iframe. Otherwise, `false`.
   * @returns `void`
   */
  public setIsAllowed(isAllowed: boolean): void {
    this.isAllowed_ = isAllowed;
    this.updateIsActive();

    if (isAllowed && !this.visitId) this.visitId = `${Date.now()}${getOrCreateSessionId().slice(0, 5)}`;
  }

  /**
   * Get current scene id. The actual functionality is set by {@link setGetCurrentSceneIdFunction} called in the engine and is meant to be `engine.getActiveSceneKey()`.
   * @returns `string`: the current scene id.
   */
  public getCurrentSceneId: () => string = () => '';

  /**
   * Sets the `getCurrentSceneId` function. It is meant to be called from the engine which should provide the `engine.getActiveSceneKey()` callback.
   * @param getCurrentSceneIdFunction - `() => string`: A function that returns the current scene id.
   * @returns `void`
   */
  public setGetCurrentSceneIdFunction(getCurrentSceneIdFunction: () => string): void {
    this.getCurrentSceneId = getCurrentSceneIdFunction;
  }

  /**
   * Get engine canvas client size. The actual functionality is set by {@link setGetCanvasSizeFunction} called in the engine and is meant to be `engine.getCanvasSize()`.
   * @returns `Size<Pixel>`: the canvas client size.
   */
  public getCanvasSize: () => Size<Pixel> = () => ({ width: 0, height: 0 });

  /**
   * Sets the `getCanvasSize` function. It is meant to be called from the engine which should provide the `engine.getCanvasSize()` callback.
   * @param getCanvasSizeFunction - `() => Size<Pixel>`: A function that returns the engine canvas client size.
   * @returns `void`
   */
  public setGetCanvasSizeFunction(getCanvasSizeFunction: () => Size<Pixel>): void {
    this.getCanvasSize = getCanvasSizeFunction;
  }

  /**
   * Sends all pending events to the analytics backend.
   * Prefer using {@link sendEventsThrottled} instead of calling this directly.
   * Calling this manually will force-send events immediately, which is used to send pageview event.
   *
   * Splits events into chunks of 8 events and sends them in several requests.
   * @returns `void`
   */
  public sendEvents(): void {
    if (typeof window === 'undefined') return;
    if (!this.isActive_) return;
    if (this.events.length === 0) return;

    const perRequestEvents = chunk(this.events, Analytics.MAX_EVENTS);

    perRequestEvents.forEach((requestEvents) =>
      this.sendPayload({
        events: JSON.stringify(requestEvents),
        vid: this.visitId,
        pid: this.projectId,
        cid: this.companyId_,
        ...(this.tourId_ ? { tid: this.tourId_ } : {}),
        path: encodeURIComponent(window.location.href),
        sid: getOrCreateSessionId(),
        referrer: encodeURIComponent(document.referrer) || null,
      })
    );

    this.events = [];
  }

  /**
   * Register an analytics event for sending.
   * @param event - `string` - interaction type of the event.
   * @param feature - `string` - name of the feature that was interacted with.
   * @param element - `string` - name of the element that was interacted with.
   * @param details - `TDetails` - generic type for details of the event.
   * @returns `Promise<void>`
   *
   * @example
   * To generate an event:
   * ```
   * analytics.push<TDetails>(event: string, feature: string, element: string, details?: TDetails);
   * ```
   */
  public async push<TDetails>(event: string, feature: string, element: string, details?: TDetails): Promise<void> {
    if (!this.isActive_) return;

    const analyticsEvent: AnalyticsEvent<TDetails> = { event, feature, element, details, timestamp: Date.now() };
    this.pushEvent<TDetails>(analyticsEvent);
  }

  /**
   * Reads the cookie preferences and sets the `isAllowed` property if cookies permit analytics.
   * @returns `void`
   */
  private readCookiePreferences(): void {
    const cookiePreferences = getCookiePreferences('vt_consentid');

    if (!cookiePreferences) return;

    const { isPerformance } = cookiePreferences;
    this.setIsAllowed(isPerformance);
  }

  /**
   * Updates the `isActive` property based on the `isEnabled` and `isAllowed` properties.
   * Analytics can't be activated if `env.REACT_APP_ANALYTICS_BASE_URL` is not set.
   * @returns `void`
   */
  private updateIsActive(): void {
    if (!this.analyticsBaseURL) return;

    const oldIsActive = this.isActive_;
    const newIsActive = this.isEnabled_ && this.isAllowed_;

    this.isActive_ = newIsActive;

    if (oldIsActive !== newIsActive) {
      if (newIsActive) this.pubSub_.emit('analytics.activated');
      else this.pubSub_.emit('analytics.deactivated');
    }

    if (!this.isActive_) {
      this.events = [];
      this.sendEventsThrottled.cancel();
    }
  }

  /**
   * Add a constructed event object to the event pending list.
   * @param event - `AnalyticsEvent<TDetails>`: A constructed event object.
   * @returns `void`
   */
  private pushEvent<TDetails>(event: AnalyticsEvent<TDetails>): void {
    this.events.push(event);
    this.pubSub_.emit('analytics.event', event);

    this.sendEventsThrottled();
  }

  /**
   * Send the payload to the analytics backend. The payload is encoded in `base64` format, `+` replaced with `-`, `/` replaced with `_`.
   * @param context - `AnalyticsContextType`: payload object to send to the analytics backend.
   * @param context.events - `string`: a list of events in JSON format.
   * @param context.vid - `string`: a unique ID of the current virtual tour visit.
   * Consists of the timestamp at the moment when analytics became active + 5 symbols of the session ID.
   * @param context.pid - `string`: project ID from `tour.json`.
   * @param context.cid - `string` - `Optional`: company ID from `tour.json`.
   * @param context.sid - `string`: a unique session ID.
   * @param context.path - `string`: current page URL.
   * @param context.referrer - `string`: the URL of the location that referred the user to the current page.
   * @returns `void`
   */
  private sendPayload(context: AnalyticsContextType): void {
    if (['vid', 'pid', 'cid', 'sid', 'path'].some((key) => !context[key])) return;

    const query = Object.entries(context)
      .reduce((acc: string[], [key, value]) => {
        if (!value) return acc;
        return [...acc, `${key}=${value}`];
      }, [])
      .join('&');

    const base64EncodedPayload = btoa(query).replace(/[+]/g, '-').replace(/[/]/g, '_');

    const url = urljoin(this.analyticsBaseURL, `image.png?p=${base64EncodedPayload}&t=${Date.now()}`);

    fetch(url);
  }
}
