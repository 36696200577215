/* eslint-disable no-unused-expressions */
// @todo -- REM "eslint-disable no-unused-expressions" its there for debug only

import type { Graph, Pano, Panos } from '@g360/vt-types';
import cloneDeep from 'lodash/cloneDeep';

import { debugPrintPanoPath, debugPrintSubGraphFromArray } from './debugUtils';
import {
  clearNeighboringDuplicates,
  countGraphConnections,
  createGraphMST,
  createInterBuildingPath,
  createPathSmart,
  createSmartPathForOutsideOnly,
  createSubGraphAndAddToPanos,
  createSuperGraph,
  createWholeGraph,
  findAllSubGraphIdsForBuildingAndFloor,
  findPanoPathBetweenPanoAndSubGraph,
  findPath,
  getAllFloorsForBuildingSorted,
  getAPanoBySubGraphId,
  getBuildingInfoForSubGraph,
  getPanoGeometricDistance,
  getUnvisitedOutsidePanos,
  optimizeSubGraphs,
  removeOneWayConnections,
  sortSubGraphsByDistanceFromPano,
} from './graphUtils';

// ------------------------------------------------------------------------------------------------------------------
// @todo -- REM debug
const D = false; // console.logs
// ------------------------------------------------------------------------------------------------------------------

/**
 * Don't use this unless tou HAVE to
 *
 * @param subGraphId
 * @param panos
 */
const getMaybeFirstPanoInSubGraph = (subGraphId: string, panos: Panos): Pano | undefined => {
  let entrancePano: Pano | undefined;
  let firstPano: Pano | undefined;
  let startPano: Pano | undefined;

  Object.keys(panos).forEach((sceneKey) => {
    const pano = panos[sceneKey];
    if (pano.subGraphId !== subGraphId) return;
    if (pano.isStartNode) {
      startPano = pano;
    }
    if (!firstPano) {
      firstPano = pano;
    }
    // -- look for entrance fact in data.json if start/entrance is not good enough
    // if(!entrancePano && pano.portals.length > 0) {
    //   entrancePano = pano;
    // }
  });

  return startPano || entrancePano || firstPano;
};

/**
 * Find the closest pano to the startPano from given list
 */
const findClosestPanoFromList = (startPanoKey: string, panoList: string[], wholeGraph: Graph) => {
  let closestPano: string | undefined;
  let closestDistance = Infinity;
  panoList.forEach((sceneKey) => {
    const path = findPath(wholeGraph, sceneKey, startPanoKey);
    if (!path) return;
    if (path.length < closestDistance) {
      closestDistance = path.length;
      closestPano = sceneKey;
    }
  });

  if (!closestPano) {
    return panoList[0];
  }
  return closestPano;
};

export const generatePath: (
  panos: Panos,
  firstSceneKey: string
) => {
  path: string[];
  wholeGraph: Graph;
  subGraphs: Map<string, Graph>;
} = (panos: Panos, firstSceneKey: string) => {
  D && console.log('------------------generatePath------------------');

  // console.log(" ↓↓↓   input for unit tests generatePath() cont input = ...   ↓↓↓")
  // const cleanedPanos = deleteUnwantedProperties(cloneDeep(panos));
  // console.log({panos:cleanedPanos, firstSceneKey});

  const wholeGraph: Graph = createWholeGraph(panos); //  info from tour.json for connections between panos
  D && console.log({ wholeGraph });

  createSubGraphAndAddToPanos(panos, wholeGraph); // panos get subGraphId field

  const superGraph = createSuperGraph(panos, firstSceneKey); // each subGraph is a node in superGraph
  D && console.log({ superGraph });

  const subGraphs = optimizeSubGraphs(panos, wholeGraph);
  D && console.log({ subGraphs });

  // inter building path contains only building subGraphs (except if starting pano is outside, then that subGraph too)
  const interBuildingPath = createInterBuildingPath(superGraph, panos, firstSceneKey);
  D && console.log('inter building path (subGraph ids)');
  D && console.log({ interBuildingPath });
  D && debugPrintSubGraphFromArray(interBuildingPath, panos);

  // @note -- if first node is outside - then it is the start pano
  // @note -- if first node is inside - then we need to find the actual first pano and start from there (minding the floor)

  D && console.log({ good_old_pano_boys: panos });

  const firstPano = panos[firstSceneKey];
  D && console.log('start pano:', firstSceneKey, `#${firstPano.subGraphId}`, firstPano.outside ? 'outside' : `${firstPano.building}/${firstPano.floor}`); // prettier-ignore
  D && console.log('Big walkabout:');

  const finalPath: string[] = [];
  let lastSubGraphId: string | undefined;
  let lastVisitedPano: string | undefined;

  interBuildingPath.forEach((interBuildingSubGraphId) => {
    const subGraph = subGraphs.get(interBuildingSubGraphId);
    if (!subGraph) {
      console.error(`interBuildingPath: #${interBuildingSubGraphId} no subgraph`);
      return;
    }

    const randomSubGraphPano = getAPanoBySubGraphId(panos, interBuildingSubGraphId); // random pano from this subGraph
    if (!randomSubGraphPano) {
      console.error(`interBuildingPath: #${interBuildingSubGraphId} no randomSubGraphPano here`);
      return;
    }

    // only time an inter-building-path node is outside is when it is the starting node
    const outside = randomSubGraphPano.outside;
    if (outside) {
      if (finalPath.length > 0) {
        throw new Error('outside node in the middle of the path! this should not be happening!');
      }

      D && console.log(`#${interBuildingSubGraphId} outside`);
      finalPath.push(randomSubGraphPano.sceneKey);
      lastVisitedPano = randomSubGraphPano.sceneKey;
      lastSubGraphId = interBuildingSubGraphId;
      return;
    }

    const { startFloor, building } = getBuildingInfoForSubGraph(
      lastSubGraphId,
      interBuildingSubGraphId,
      wholeGraph,
      panos,
      firstSceneKey
    );

    if (!building || !startFloor) {
      D && console.log({ lastSubGraphId, building, startFloor });
      throw new Error('no building or startFloor, why?');
    }
    if (!lastVisitedPano) {
      lastVisitedPano = firstSceneKey;
    }

    const allFloorsSorted = getAllFloorsForBuildingSorted(building, panos, startFloor);
    D && console.log(`#${interBuildingSubGraphId} is in building "${building}" starting from floor ${startFloor} and has these floors: `, allFloorsSorted); // prettier-ignore
    // We can't just create paths for every floor and then glue them together.
    // Because a floor can can be split into multiple subGraphs that are not accessible from each other.
    // So we need to visit all subGraphs that correspond to this floor in the order of floors.
    const subGraphsToVisit: string[] = []; // IDs
    allFloorsSorted.forEach((floor) => {
      const vSubGraphs = findAllSubGraphIdsForBuildingAndFloor(building as string, floor, false, panos); // @note -- not guaranteed order of subGraphs to visit in this floor
      const vSubGraphsSorted = sortSubGraphsByDistanceFromPano(
        vSubGraphs,
        lastVisitedPano as string,
        wholeGraph,
        panos
      ); // this is only to fix issue in  ->  care-3d/34aa9139243b4d2e9f8f0a8e04315e2b where starting NODE is not int the first subgraph to visit, but the second one
      D && console.log('sorting subGraphs in single floor:', vSubGraphs, '-->', vSubGraphsSorted, 'are they different?'); // prettier-ignore
      subGraphsToVisit.push(...vSubGraphsSorted);
    });

    D && console.log(`#${interBuildingSubGraphId} should visit all floors in building "${building}", in this order: `, allFloorsSorted); // prettier-ignore
    D && console.log('that corresponds to these subGraphs: ', subGraphsToVisit);

    subGraphsToVisit.forEach((subGraphIdToVisit) => {
      let pathToGetToThisSubGraph = findPanoPathBetweenPanoAndSubGraph(lastVisitedPano as string, subGraphIdToVisit, wholeGraph, panos); // prettier-ignore

      if (!pathToGetToThisSubGraph) {
        // this happens only for properties with BADLY configured pano paths
        const maybeFirstPano = getMaybeFirstPanoInSubGraph(subGraphIdToVisit, panos);
        if (!maybeFirstPano) throw new Error(`no maybeFirstPano for subGraphIdToVisit #${subGraphIdToVisit}`);
        pathToGetToThisSubGraph = [lastVisitedPano as string, maybeFirstPano.sceneKey];
      }

      D && console.log(`path from last visited: ${lastVisitedPano} to #${subGraphIdToVisit}:`);
      D && debugPrintPanoPath(pathToGetToThisSubGraph, panos);

      finalPath.push(...pathToGetToThisSubGraph);
      const firstNode = pathToGetToThisSubGraph[pathToGetToThisSubGraph.length - 1];

      const subGraphToVisit = subGraphs.get(subGraphIdToVisit);
      if (!subGraphToVisit) {
        console.error(`no subGraphToVisit #${subGraphIdToVisit}`);
        return;
      }
      D && console.log(`path to explore #${subGraphIdToVisit}, starting from `, firstNode, '( #', panos[firstNode].subGraphId, ')', ' subGraph size:', subGraphToVisit.size, { subGraphToVisit }); // prettier-ignore

      const debugOnSubGraphId = undefined; // '1';
      const pathInSubGraph = createPathSmart(
        subGraphToVisit,
        firstNode,
        panos,
        subGraphIdToVisit === debugOnSubGraphId
      );
      D && console.log({ pathInSubGraph });

      // all panos are not supposed to be in the path, one per room is plenty!
      // right ?
      // Object.keys(panos).forEach((sceneKey) => {
      //   const pano = panos[sceneKey];
      //   if (pano.subGraphId === subGraphIdToVisit && !pathInSubGraph.includes(sceneKey)) {
      //     console.log(`%cWARNING: sceneKey ${sceneKey} is missing from the subGraph #${subGraphIdToVisit} path!`,'color: #F00');
      //   }
      // });

      finalPath.push(...pathInSubGraph);
      lastVisitedPano = finalPath[finalPath.length - 1];
    });

    lastSubGraphId = interBuildingSubGraphId;
  });

  if (D) {
    const preFinalPath = [...finalPath];
    console.log('preFinalPath, before loop around to start', preFinalPath);
  }

  let unvisitedOutsidePanos = getUnvisitedOutsidePanos(panos, finalPath);

  if (D && unvisitedOutsidePanos.length > 0) {
    console.error('unvisited outside panos:', unvisitedOutsidePanos);
  }

  if (unvisitedOutsidePanos.length > 0) {
    D && console.error('unvisited outside panos:', unvisitedOutsidePanos);

    const currentPano = panos[lastVisitedPano as string];

    let closestOutsidePano = findClosestPanoFromList(currentPano.sceneKey, unvisitedOutsidePanos, wholeGraph);
    if (!closestOutsidePano) {
      // bad hotspot data, happens, just jump to first unvisited outside pano (or "first scene" if it's outside)
      const firstScene = panos[firstSceneKey];
      if (firstScene.outside) {
        closestOutsidePano = firstSceneKey;
      } else {
        closestOutsidePano = unvisitedOutsidePanos[0];
      }
    }

    unvisitedOutsidePanos.push(closestOutsidePano); // maybe closestOutsidePano was not in this list
    const unvisitedOutsidePanosSubGraphIds = unvisitedOutsidePanos.map((sceneKey) => panos[sceneKey].subGraphId);

    // make outside only superGraph: filter out nodes that not in unvisitedOutsidePanos
    const superGraphOutside = new Map<string, string[]>(
      Array.from(superGraph)
        .filter(([key]) => unvisitedOutsidePanosSubGraphIds.includes(key))
        .map(([key, adjacencyList]) => [
          key,
          adjacencyList.filter((node) => unvisitedOutsidePanosSubGraphIds.includes(node)),
        ])
    );

    // all outside panos are unique subGraphs and super-graph is graph of subGraphs, do the next logic using subGraphIds
    const sumDistances = countGraphConnections(superGraphOutside);

    let outsidePathSubGraphIds: string[] = [];

    const startSubGraphId = `${panos[closestOutsidePano].subGraphId}`;

    // First try to make outside path using original superGraph
    // It might fail if the graph is too large or have weird patterns/loops
    // The DFS (Depth First Search) algorithm crashes in recursive loop
    // It seems like it happens more likely in large projects with manually generated hot spots
    try {
      outsidePathSubGraphIds = createSmartPathForOutsideOnly(
        superGraphOutside,
        startSubGraphId,
        panos,
        false,
        sumDistances
      );
    } catch (e) {
      D && console.log('outside path failed, trying MST version', { superGraphOutside });

      // Then try using MST version of it.
      // It will definitely get rid of cycles and make the graph MUCH simpler and guarantees a success for DFS
      // This is not our first options, because it might generate a bit longer path, since some connections are removed
      // but will definitely work

      const clonedSuperGraphOutside = cloneDeep(superGraphOutside);
      const superGraphOutsideMST = createGraphMST(clonedSuperGraphOutside, panos, sumDistances, startSubGraphId, false);
      const sumDistancesMST = countGraphConnections(superGraphOutsideMST);
      outsidePathSubGraphIds = createSmartPathForOutsideOnly(
        superGraphOutsideMST,
        startSubGraphId,
        panos,
        false,
        sumDistancesMST
      );

      D && console.log('forced MST graph for createSmartPathForOutsideOnly()');
      // D && console.log("OG outside\n",createGraphistryCSVFromGraph(superGraphOutside));
      // D && console.log("MST outside\n",createGraphistryCSVFromGraph(superGraphOutsideMST));
    }

    // path to the first unvisited outside pano
    const pathToOutsidePath = findPath(wholeGraph, lastVisitedPano as string, closestOutsidePano);
    if (pathToOutsidePath) {
      D && console.log('adding path to first unvisited outside panos:', pathToOutsidePath);
      finalPath.push(...pathToOutsidePath);
    } else {
      D && console.log('no path to closestOutsidePano, just jump there');
      finalPath.push(closestOutsidePano);
    }

    // since outside panos are unique subGraphs, we can just take their sceneKeys
    const outsidePath = outsidePathSubGraphIds
      .map((subGraphId) => getAPanoBySubGraphId(panos, subGraphId)?.sceneKey)
      .filter(Boolean) as string[];
    D && console.log('adding path to visit unvisited outside panos:', outsidePath);
    finalPath.push(...outsidePath);
    lastVisitedPano = finalPath[finalPath.length - 1];
  }

  // second try to include unvisited outside panos, try brute-force there
  unvisitedOutsidePanos = getUnvisitedOutsidePanos(panos, finalPath);

  for (let i = 0; i < unvisitedOutsidePanos.length; i += 1) {
    const unvisitedOutsidePano = unvisitedOutsidePanos[i];
    const pathToUnvisitedOutsidePano = findPath(wholeGraph, lastVisitedPano as string, unvisitedOutsidePano);
    if (pathToUnvisitedOutsidePano) {
      D && console.log('ZZ: adding path to unvisited outside panos:', pathToUnvisitedOutsidePano);
      finalPath.push(...pathToUnvisitedOutsidePano);
      lastVisitedPano = finalPath[finalPath.length - 1];
    }
  }

  unvisitedOutsidePanos = getUnvisitedOutsidePanos(panos, finalPath);

  if (D && unvisitedOutsidePanos.length > 0) {
    console.error('still there are unvisited outside panos:', unvisitedOutsidePanos);
  }

  // loop around: add return path to the first pano
  let returnPath = findPath(wholeGraph, lastVisitedPano as string, firstSceneKey);

  if (D) {
    const returnPathCopy = [...(returnPath || [])];
    console.log('returnPathCopy', returnPathCopy);
  }

  if (!returnPath) {
    D && console.error('No return path! Will try reverse search');
    const reversedPath = findPath(wholeGraph, firstSceneKey, lastVisitedPano as string);
    if (reversedPath) {
      returnPath = reversedPath.reverse();
    } else {
      D && console.error("No reverse return path either! I guess we'll just jump home");
      returnPath = [firstSceneKey];
    }
  }

  finalPath.push(...returnPath);

  const finalFinalPath = clearNeighboringDuplicates(finalPath);

  D && console.log({ finalFinalPath });

  return { path: finalFinalPath, wholeGraph, subGraphs };
};

/**
 * special case for projects with only outside panos, no data.json exists
 * if there is one way connections (maybe a cycle, maybe not), then we can't use MST, otherwise use MST on graph
 * to find the shortest path that visits all panos
 */
export const generatePathForOutsideOnly = (panos: Panos, firstSceneKey: string): string[] => {
  D && console.log('------------------OutsideOnly::generatePath------------------');
  const wholeGraph: Graph = createWholeGraph(panos);
  let oneWayConnections = true;

  // try to remove one way connections, just to see if there are any
  const numConnectionsBefore = countGraphConnections(wholeGraph);
  const noOneWayConnectionsGraph = removeOneWayConnections(cloneDeep(wholeGraph));
  const numConnectionsAfter = countGraphConnections(noOneWayConnectionsGraph);

  if (numConnectionsAfter === numConnectionsBefore) {
    oneWayConnections = false;
  }

  D && console.log({ numConnectionsBefore, numConnectionsAfter, oneWayConnections, wholeGraph });

  let sumDistances = 0;

  const allHaveOutsidePositions = Object.keys(panos).every(
    (sceneKey) => panos[sceneKey].camera[0] !== 0 && panos[sceneKey].camera[1] !== 0
  );

  wholeGraph.forEach((destinations, sceneKey) => {
    destinations.forEach((destinationSceneKey) => {
      // use actual geometric distance only if all panos have outside positions, otherwise imagine they are all spaced equally
      let distance = 2;
      if (allHaveOutsidePositions) {
        distance = getPanoGeometricDistance(sceneKey, destinationSceneKey, panos);
      }
      sumDistances += distance / 2; // since we are counting both ways: a->b and b->a
    });
  });

  const graphMST = oneWayConnections ? wholeGraph : createGraphMST(wholeGraph, panos, sumDistances, firstSceneKey);
  D && console.log({ oneWayConnections, graphMST });

  let smartPath: string[] = [];
  try {
    sumDistances = countGraphConnections(graphMST);
    smartPath = createSmartPathForOutsideOnly(graphMST, firstSceneKey, panos, allHaveOutsidePositions, sumDistances);
  } catch (e) {
    // retry with forced MST,
    // check comment @ other usages of createSmartPathForOutsideOnly() for explanation
    const graphForcedMst = createGraphMST(wholeGraph, panos, sumDistances, firstSceneKey);
    sumDistances = countGraphConnections(graphForcedMst);
    smartPath = createSmartPathForOutsideOnly(
      graphForcedMst,
      firstSceneKey,
      panos,
      allHaveOutsidePositions,
      sumDistances
    );
    D && console.log('forced MST graph for createSmartPathForOutsideOnly()');
  }

  D && console.log({ smartPath });

  const firstPano = smartPath[0];
  const lastPano = smartPath[smartPath.length - 1];
  if (!wholeGraph.get(firstPano)?.includes(lastPano)) {
    // Can you go directly from 1st to last pano?
    // if not, find a path to the last pano
    const homePath = findPath(wholeGraph, lastPano, firstPano);
    if (homePath) {
      smartPath.push(...homePath);
    } else {
      console.error('generatePathForOutsideOnly::no home path');
    }
  } else {
    // if you can, just go there directly
    smartPath.push(firstPano);
  }

  return clearNeighboringDuplicates(smartPath);
};
