import { getVec3Difference, normalizeVec3 } from '@g360/vt-utils';

import { lerpColor } from './utils/color';

class Sun {
  paused = false;
  // private _paused = false;
  private _yaw = 0;
  private _pitch = 0;
  private _position = [0, 0, 0];
  private _direction = [0, 0, 0];
  private _timeOfDay = 0; // 0..24 with decimal minutes
  private _ambientColor: number[] = [1, 1, 1];

  get yaw() {
    return this._yaw;
  }
  get pitch() {
    return this._pitch;
  }
  get position() {
    return this._position;
  }
  get timeOfDay() {
    return this._timeOfDay;
  }
  get direction() {
    return this._direction;
  }
  get ambientColor() {
    return this._ambientColor;
  }

  private center: number[];
  private radius = 2000;
  private currentAngle: number;
  private readonly rotationSpeed = 0.0005;
  private lastTime = 0;
  private readonly tilt = 23.5 * (Math.PI / 180); // Earth's axial tilt in radians
  private readonly northAngleOffset = -0.5 * Math.PI; // offset compass rotation to make light shine through the window @todo -- make it dynamic

  constructor(center: number[]) {
    this.center = center;
    this.currentAngle = 0;
  }

  update() {
    const time = performance.now();
    let deltaTime = time - this.lastTime;
    if (deltaTime > 32) deltaTime = 32;
    this.lastTime = time;

    if (!this.paused) {
      // debug speed up night time
      let nightSpeedUp = 1;
      if (this._timeOfDay < 5.5 || this._timeOfDay > 18.5) {
        nightSpeedUp = 10;
      }

      this.currentAngle += deltaTime * this.rotationSpeed * nightSpeedUp;
      this.currentAngle %= Math.PI * 2;
    }

    const horizonAngle = this.currentAngle + this.northAngleOffset;
    const verticalAngle = Math.sin(horizonAngle) * this.tilt;

    this.position[0] = this.center[0] + this.radius * Math.cos(horizonAngle) * Math.cos(verticalAngle);
    this.position[1] = this.center[1] + this.radius * Math.sin(verticalAngle);
    this.position[2] = this.center[2] + this.radius * Math.sin(horizonAngle) * Math.cos(verticalAngle);

    this._direction = normalizeVec3(getVec3Difference(this.center, this.position));
    this._yaw = Math.atan2(this._direction[0], this._direction[2]) + Math.PI;
    this._pitch = -Math.asin(this._direction[1]);

    this._timeOfDay = ((this.currentAngle / (Math.PI * 2)) * 24) % 24;

    this.updateAmbientColor();
  }

  setModelCenter(modelCenter: number[]) {
    this.center = modelCenter;
  }

  setRadius(radius: number) {
    this.radius = radius;
  }

  private updateAmbientColor() {
    const hour = this._timeOfDay;

    const colors = {
      night: [0.7, 0.7, 0.85], // Dark blue-grey
      sunrise: [1.0, 0.8, 0.7], // Orange
      day: [1.0, 1.0, 1.0], // White
      sunset: [1.0, 0.8, 0.4], // Red-orange
    };

    const transitions = {
      nightToSunrise: { start: 5, end: 6 },
      sunriseToDay: { start: 6, end: 7 },
      dayToSunset: { start: 17, end: 18 },
      sunsetToNight: { start: 18, end: 19 },
    };

    if (hour < transitions.nightToSunrise.start || hour >= transitions.sunsetToNight.end) {
      // console.log('night h:', hour, this._pitch);
      this._ambientColor = colors.night;
    } else if (hour >= transitions.nightToSunrise.start && hour < transitions.nightToSunrise.end) {
      // console.log('sunrise h:', hour, this._pitch);
      this._ambientColor = lerpColor(
        colors.night,
        colors.sunrise,
        (hour - transitions.nightToSunrise.start) / (transitions.nightToSunrise.end - transitions.nightToSunrise.start)
      );
    } else if (hour >= transitions.nightToSunrise.end && hour < transitions.sunriseToDay.end) {
      // console.log('day h:', hour, this._pitch);
      this._ambientColor = lerpColor(
        colors.sunrise,
        colors.day,
        (hour - transitions.sunriseToDay.start) / (transitions.sunriseToDay.end - transitions.sunriseToDay.start)
      );
    } else if (hour >= transitions.sunriseToDay.end && hour < transitions.dayToSunset.start) {
      // console.log('day h:', hour, this._pitch);
      this._ambientColor = colors.day;
    } else if (hour >= transitions.dayToSunset.start && hour < transitions.dayToSunset.end) {
      // console.log('sunset h:', hour, this._pitch);
      this._ambientColor = lerpColor(
        colors.day,
        colors.sunset,
        (hour - transitions.dayToSunset.start) / (transitions.dayToSunset.end - transitions.dayToSunset.start)
      );
    } else if (hour >= transitions.dayToSunset.end && hour < transitions.sunsetToNight.end) {
      // console.log('night h:', hour, this._pitch);
      this._ambientColor = lerpColor(
        colors.sunset,
        colors.night,
        (hour - transitions.sunsetToNight.start) / (transitions.sunsetToNight.end - transitions.sunsetToNight.start)
      );
    }
  }
}

export default Sun;
