import { Spinner } from "components/elements";
import { MicIcon } from "components/icons";
import { FC } from "react";
import { useAudioLevelIndicator, useRoomContext } from "hooks";

const AudioVolumeTestWrapper: FC = () => {
  const { localAudioTrack, isAcquiringLocalAudioTrack } = useRoomContext();
  const { audioLevel } = useAudioLevelIndicator(
    localAudioTrack,
    Boolean(localAudioTrack)
  );

  return (
    <div className="flex justify-start items-center w-full md:w-80">
      <div className="w-8 h-8  mr-1">
        <div className="relative">
          {isAcquiringLocalAudioTrack && (
            <div
              className="absolute top-0 left-0 w-8 h-8 flex justify-center items-center"
              style={{ zIndex: "-1" }}
            >
              <Spinner width="w-8" height="h-8" />
            </div>
          )}
        </div>
        <div className="w-8 h-8 flex justify-center items-center">
          <MicIcon className="w-4 h-4" />
        </div>
      </div>
      <div className="flex items-center justify-evenly w-full h-full">
        {Array.from({ length: 25 }, (_, i) => i).map((val) => {
          const normalised = val / 25;
          return (
            <div
              key={`audio-indicator-${val}`}
              className="flex w-[6px] h-[14px] rounded-lg"
              style={{
                backgroundColor:
                  audioLevel > normalised ? "#1A222E" : "#A0A6AE",
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AudioVolumeTestWrapper;
