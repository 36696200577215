import { TourConfigService } from '../services/TourConfigService';

const TOUR_CONFIG_SERVICE_DEFAULTS = {
  projectDataConfig: {},
  defaultAccent: '#ffc600',
  configVersion: [0, 0, 0],
  configStructureType: 'buildings',
  themeConfig: 'auto',
  theme: 'dark',
  welcomeImage: null,
  defaultWelcomeImage: null,
  welcomeImageEnabled: false,
  tourWelcomeScreen: false,
  showRoomArea: true,
  showTotalAreas: true,
  units: 'metric',
  unitsShort: 'm',
  unitSwitch: true,
  floorIndexing: 0,
  projectUrl: null,
  timestampFormat: 12,
  timestampTimezone: 'UTC',
  lsfRenderingMode: 'off',
  navigationMode: 'full',
  disabledControlsTop: false,
  region: '',
  gatedTourEnabled: false,
  features: {
    cookieConsent: true,
    cookiePopup: true,
    giraffe360Branding: true,
    clientBranding: true,
    welcomeScreen: true,
    hotspotClickableLinks: true,
    infoSection: true,
    fullscreen: true,
    contactSection: true,
    propertyInfo: true,
    linkSharing: true,
    defaultUnits: 'floorplan-based',
    isMinimapOpenByDefault: true,
    timestamp: true,
    gatedTour: true,
  },
  minPitch: -90,
} satisfies Partial<TourConfigService>;

export default TOUR_CONFIG_SERVICE_DEFAULTS;
