import type { Milliseconds, Pixel } from '@g360/vt-types';
import { toRad } from '@g360/vt-utils';
import isMobile from 'is-mobile';

export const MAX_FOV_DEG = isMobile() ? 100 : 120;
export const MAX_FOV_RAD = toRad(MAX_FOV_DEG);

export const DEFAULT_FOV_DEG = MAX_FOV_DEG;

export const transitionSettings = {
  geometryResolution: 175, // maybe this can be lowered for mobile devices, 128 is plenty (or even lower)
  depthmapResolution: 175,
  cubemapResolution: 512, // if using equirect panos, cube textures for depth transition are created on the fly - equivalent of 1st level tiles for regular pano
  wallskipMinWidth: 150, // in geometry's "centimeters"
  wallskipPerceivableDistance: 1, // when skipping a wall, move this amount of "centimeters" between the rooms (0 means walls are edge to edge, and 100 will feel like rooms are 1 meter apart)
};

export const DEFAULT_ASSET_CONFIG = {
  basePath: '.',
  assetPath: '.',
  signatureData: {},
  equirectAssets: {
    primaryAssets: 'auto',
  },
  layoutImageBase: 'https://premium.giraffe360.com',
};

export const DEFAULT_ENGINE_CONFIG = {
  renderHotSpots: true,
  transitionDuration: undefined,
  blurMode: false,
  colorCorrectionMode: false,
  forceBlendTransition: false,
  textMode: false,
  recordingMode: false,
};

export const STARTING_EDITOR_BLUR = 20;

export const MODE_SWITCH_CLICK_THRESHOLD: Pixel = 20;
export const MODE_SWITCH_ZOOM_TIME_OUT: Milliseconds = 500;
export const MODE_SWITCH_ZOOM_TIME_OUT_FRUSTRATION: Milliseconds = 1000;
/** user has to scroll for this amount of time to trigger mode change (not constantly, stopping and resuming still counts) */
export const MODE_SWITCH_WAIT_TIME: Milliseconds = 1000;
/** mode switch timer is reset if no scrolling occurs in this amount of time */
export const MODE_SWITCH_WAIT_TIMEOUT: Milliseconds = 2000;

export default {
  MAX_FOV_DEG,
  MAX_FOV_RAD,
  DEFAULT_ASSET_CONFIG,
  DEFAULT_FOV_DEG,
  STARTING_EDITOR_BLUR,
  MODE_SWITCH_CLICK_THRESHOLD,
  MODE_SWITCH_ZOOM_TIME_OUT,
  MODE_SWITCH_ZOOM_TIME_OUT_FRUSTRATION,
  MODE_SWITCH_WAIT_TIMEOUT,
};
