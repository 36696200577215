import { useContext } from "react";

import { MixpanelContext, MixpanelEvents } from "components/contexts";

/**
 * @returns - Hook for using passing through mixpanel events and acitons
 */
const useMixpanelContext = () => {
  const { actions } = useContext(MixpanelContext);

  return { Mixpanel: actions, MixpanelEvents };
};

export default useMixpanelContext;
