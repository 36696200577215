/** Check if any room count to show, zeros count as valid */
export default function isThereAnyInfoSlot(projectDataConfig) {
    var _a;
    if (!projectDataConfig)
        return false;
    const roomCounts = !!((_a = projectDataConfig.roomCounts) === null || _a === void 0 ? void 0 : _a.find((r) => r.count !== null && !Number.isNaN(r.count)));
    const floorCount = projectDataConfig.floorCount !== undefined &&
        projectDataConfig.floorCount !== null &&
        !Number.isNaN(projectDataConfig.floorCount);
    const totalArea = projectDataConfig.totalArea !== undefined &&
        projectDataConfig.totalArea !== null &&
        !Number.isNaN(projectDataConfig.totalArea);
    return roomCounts || floorCount || totalArea;
}
