var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getCookie, setCookie } from '@g360/vt-utils';
import * as Sentry from '@sentry/react';
import VerificationFailedError from './VerificationFailedError';
// Actual backend testing
// const requestEmailEndpoint = 'https://g360lab.com/gapi/gated-tours/';
// const verifyCodeEndpoint = 'https://g360lab.com/gapi/gated-tours/verify-code/';
// const verifyCookieEndpoint = 'https://g360lab.com/gapi/gated-tours/verify-cookie/';
const requestEmailEndpoint = process.env.REACT_APP_BACKEND_ENDPOINT
    ? `${process.env.REACT_APP_BACKEND_ENDPOINT}/gapi/gated-tours/`
    : '';
const verifyCodeEndpoint = process.env.REACT_APP_BACKEND_ENDPOINT
    ? `${process.env.REACT_APP_BACKEND_ENDPOINT}/gapi/gated-tours/verify-code/`
    : '';
const verifyCookieEndpoint = process.env.REACT_APP_BACKEND_ENDPOINT
    ? `${process.env.REACT_APP_BACKEND_ENDPOINT}/gapi/gated-tours/verify-cookie/`
    : '';
const requestEmailSentryMessagePrefix = 'gatedTourUtils -> requestEmailApi -> ';
const verifyCodeSentryMessagePrefix = 'gatedTourUtils -> verifyCodeApi -> ';
const verifyTokenSentryMessagePrefix = 'gatedTourUtils -> verifyTokenApi -> ';
export const genericGatedTourErrorMessage = 'There is an issue with the verification process, try again later!';
export const throttledGatedTourErrorMessage = 'You have reached the maximum number of requests, try again later!';
/**
 * Removes the trailing period (full stop, period, full point) from the provided string.
 * @param value - `string`: The string to remove the trailing period from.
 * @returns `string`: The string without the trailing period.
 *
 * @example
 * removeTrailingPeriod('Hello.'); // 'Hello'
 *
 * @example
 * removeTrailingPeriod('Hello. World.'); // 'Hello. World'
 */
export function removeTrailingPeriod(value) {
    return value.replace(/\.$/, '');
}
/**
 * Capitalizes the first letter of the provided string.
 * @param value -`string`: The string to capitalize the first letter of.
 * @returns `string`: The string with the first letter capitalized.
 */
export function capitalizeFirstLetter(value) {
    if (!value)
        return '';
    return value[0].toUpperCase() + value.slice(1);
}
/**
 * Sends a request to the backend API that asks it to send an email with a verification code.
 * @param payload - `RequestEmailPayload`: The payload to send to the backend API.
 * @param payload.project_id - `string`: ID of the current project.
 * @param payload.email - `string`: Email to send the verification code to.
 * @param payload.contact_name - `string`: Name of the contact person.
 * @param payload.phone_number - `string`: Phone number of the contact person.
 * @param payload.session_id - `string`: ID of the current session.
 * @param endpoint - `string` - `Optional`: The endpoint to send the request to.
 *
 * Default: `{{process.env.REACT_APP_BACKEND_ENDPOINT}}/gapi/gated-tours/`.
 * @returns `Promise<void>`: A promise that resolves if the request is successful and an email has been sent.
 * @throws `Error`: If the request fails or if it was successful but an email was not sent due to incorrect/insufficient data in the payload.
 */
export function requestEmailApi(payload, endpoint = requestEmailEndpoint) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!endpoint)
            throw new Error('No endpoint');
        try {
            const response = yield fetch(endpoint, {
                method: 'POST',
                headers: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
                credentials: 'include',
            });
            const json = yield response.json();
            if (response.status === 400) {
                const { detail, project_id: projectId, email, contact_name: contactName, phone_number: phoneNumber, session_id: sessionId, } = json;
                if (detail !== undefined)
                    throw new Error(removeTrailingPeriod(detail));
                if (email !== undefined && email.length > 0 && email[0].includes('valid')) {
                    throw new Error(removeTrailingPeriod(email[0]));
                }
                if (phoneNumber !== undefined && phoneNumber.length > 0 && phoneNumber[0].includes('valid')) {
                    throw new Error(removeTrailingPeriod(phoneNumber[0]));
                }
                const isProjectIdMissing = projectId !== undefined && projectId.length > 0 && projectId[0].includes('required');
                const isEmailMissing = email !== undefined && email.length > 0 && email[0].includes('required');
                const isContactNameMissing = contactName !== undefined && contactName.length > 0 && contactName[0].includes('required');
                const isPhoneNumberMissing = phoneNumber !== undefined && phoneNumber.length > 0 && phoneNumber[0].includes('required');
                const isSessionIdMissing = sessionId !== undefined && sessionId.length > 0 && sessionId[0].includes('required');
                if (isProjectIdMissing)
                    throw new Error('Project ID is required');
                if (isEmailMissing)
                    throw new Error('Email is required');
                if (isContactNameMissing)
                    throw new Error('Contact name is required');
                if (isPhoneNumberMissing)
                    throw new Error('Phone number is required');
                if (isSessionIdMissing)
                    throw new Error('Session ID is required');
            }
            if (response.status === 429)
                throw new Error(throttledGatedTourErrorMessage);
            if (!response.ok)
                throw new Error(`${response.status} ${response.statusText}`);
        }
        catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Unknown error';
            Sentry.captureMessage(`${requestEmailSentryMessagePrefix}${errorMessage}`);
            throw error;
        }
    });
}
/**
 * Sends a request to the backend API to verify the gated tour using a verification code.
 * @param payload - `VerifyCodePayload`: The payload to send to the backend API.
 * @param payload.project_id - `string`: ID of the current project.
 * @param payload.email - `string`: Email to which the verification code has been sent.
 * @param payload.verification_code - `number`: Verification code to use to verify the gated tour.
 * @param endpoint - `string` - `Optional`: The endpoint to send the request to.
 *
 * Default values: `{{process.env.REACT_APP_BACKEND_ENDPOINT}}/gapi/gated-tours/verify-code/`.
 * @returns `Promise<string>`: A promise that resolves with a new verification token if the request and the verification were successful.
 * @throws `Error`: If the request fails or if it was successful but the verification failed due to incorrect/insufficient data in the payload.
 */
export function verifyCodeApi(payload, endpoint = verifyCodeEndpoint) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!endpoint)
            throw new Error('No endpoint');
        try {
            const response = yield fetch(endpoint, {
                method: 'POST',
                headers: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
                credentials: 'include',
            });
            const json = yield response.json();
            if (response.status === 400) {
                const { message, detail, email, project_id: projectId, verification_code: verificationCode } = json;
                if (message !== undefined)
                    throw new VerificationFailedError();
                if (detail !== undefined)
                    throw new Error(removeTrailingPeriod(detail));
                if (email !== undefined && email[0].includes('valid'))
                    throw new Error(removeTrailingPeriod(email[0]));
                const isProjectIdMissing = projectId !== undefined && projectId.length > 0 && projectId[0].includes('required');
                const isEmailMissing = email !== undefined && email.length > 0 && email[0].includes('required');
                const isVerificationCodeMissing = verificationCode !== undefined && verificationCode.length > 0 && verificationCode[0].includes('required');
                if (isProjectIdMissing)
                    throw new Error('Project ID is required');
                if (isEmailMissing)
                    throw new Error('Email is required');
                if (isVerificationCodeMissing)
                    throw new Error('Verification code is required');
            }
            if (response.status === 429)
                throw new Error(throttledGatedTourErrorMessage);
            if (!response.ok)
                throw new Error(`${response.status} ${response.statusText}`);
            const { verification_token: verificationToken } = json;
            if (verificationToken)
                return verificationToken;
            throw new Error('No verification token');
        }
        catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Unknown error';
            Sentry.captureMessage(`${verifyCodeSentryMessagePrefix}${errorMessage}`);
            throw error;
        }
    });
}
/**
 * Sends a request to the backend API to verify the gated tour using a verification token.
 * @param payload - `VerifyTokenPayload`: The payload to send to the backend API.
 * @param payload.project_id - `string`: ID of the current project.
 * @param payload.verification_token - `string`: Verification token to use to verify the gated tour.
 * @param endpoint - `string` - `Optional`: The endpoint to send the request to.
 *
 * Default value: `{{process.env.REACT_APP_BACKEND_ENDPOINT}}/gapi/gated-tours/verify-cookie/`.
 * @returns `void`: A promise that resolves with a new verification token if the request and the verification were successful.
 * @throws `Error`: If the request fails or if it was successful but the verification failed due to incorrect/insufficient data in the payload.
 */
export function verifyTokenApi(payload, endpoint = verifyCookieEndpoint) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!endpoint)
            throw new Error('No endpoint');
        try {
            const response = yield fetch(endpoint, {
                method: 'POST',
                headers: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
                credentials: 'include',
            });
            const json = yield response.json();
            if (response.status === 400) {
                const { message, detail, project_id: projectId, verification_token: verificationToken } = json;
                if (message !== undefined)
                    throw new VerificationFailedError();
                if (detail !== undefined)
                    throw new Error(removeTrailingPeriod(detail));
                const isProjectIdMissing = projectId !== undefined && projectId.length > 0 && projectId[0].includes('required');
                const isVerificationTokenMissing = verificationToken !== undefined && verificationToken.length > 0 && verificationToken[0].includes('required');
                if (isProjectIdMissing)
                    throw new Error('Project ID is required');
                if (isVerificationTokenMissing)
                    throw new Error('Verification token is required');
            }
            if (response.status === 429)
                throw new Error(throttledGatedTourErrorMessage);
            if (!response.ok)
                throw new Error(`${response.status} ${response.statusText}`);
            if (json.verified)
                return;
            throw new Error('No verification confirmation');
        }
        catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Unknown error';
            Sentry.captureMessage(`${verifyTokenSentryMessagePrefix}${errorMessage}`);
            throw error;
        }
    });
}
/**
 * Reads the verification token if it exists from the cookies for this specific virtual tour.
 * @returns `string` - The verification token if it exists in the cookies, otherwise an empty string.
 */
export function readVerificationTokenFromCookies() {
    const cookieVerificationToken = getCookie('verification_token');
    if (!cookieVerificationToken)
        return '';
    return cookieVerificationToken;
}
/**
 * Saves the verification token to the cookies for this specific virtual tour.
 * @param `verificationToken` - `string`: The verification token to save to the cookies.
 * @returns `void`
 */
export function saveVerificationTokenToCookies(verificationToken) {
    if (!verificationToken)
        return;
    setCookie({
        name: 'verification_token',
        value: verificationToken,
        days: 20 * 365,
        domain: window !== undefined ? `.${window.location.hostname}` : undefined,
        path: window.location.pathname,
    });
}
/**
 * Validates an email address.
 * @param email - `string`: The email to validate.
 * @returns `boolean`: `true` if the email is valid, otherwise `false`.
 */
export function validateEmail(email) {
    // Same regex as in dashboard
    return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email);
}
/**
 * Validates if the provided string is a single digit.
 * @param value - `string`: The value to validate.
 * @returns `boolean`: `true` if the value is a single digit, otherwise `false`.
 */
export function validateDigit(value) {
    return /^[\d]$/.test(value);
}
/**
 * Validates if the provided string consists only of digits.
 * @param value - `string`: The value to validate.
 * @returns `boolean`: `true` if the value consists only of digits, otherwise `false`.
 */
export function validateNumber(value) {
    return /^\d+$/.test(value);
}
