import type { CaptionTextFrame } from '@g360/vt-types';

/** Take in text caption frames and scale the timeline by the speed value
 * Smaller speed means longer timeline
 * @param captionFrames - the CaptionTextFrame frames to scale
 * @param speed - the speed value to apply to the caption frames
 * @returns scaled CaptionTextFrame frames
 */
export default function scaleCaptionFrames(captionFrames: CaptionTextFrame[], speed: number) {
  if (speed === 1) return captionFrames;

  return captionFrames.map((captionFrame) => ({
    ...captionFrame,
    // NOTE: division is used because we need to scale the playback speed
    // and not the duration of the playback
    startTime: captionFrame.startTime / speed,
    endTime: captionFrame.endTime / speed,
  }));
}
