import { useCallback, useEffect, useRef, useState } from 'react';
/** Hook for getting getBoundingClientRect for an element using ref, updates on resize events
 * @note This is not very efficient so use it with caution when really necessary
 */
export const useBbox = () => {
    const ref = useRef(null);
    const [rect, setRect] = useState(null);
    const set = () => {
        // For some reason getBoundingClientRect is unreliable if you read it immediately after resize or mount.
        // To get the correct values we need to check it multiple times in an animation-frame loop and set them
        // when they are not changing anymore, or after some timeout. Usually it takes 1-5 frames to get the correct values.
        var _a, _b;
        if (typeof window === 'undefined')
            return;
        const startTime = Date.now();
        let prevRect;
        const checkSize = () => {
            var _a;
            const currentRect = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
            if (prevRect && currentRect && currentRect.width === prevRect.width && currentRect.height === prevRect.height) {
                // If the size is not changing anymore, we set the value
                setRect(currentRect);
            }
            else if (Date.now() - startTime < 1000) {
                // If the timeout has not passed yet, we continue checking
                prevRect = currentRect;
                window === null || window === void 0 ? void 0 : window.requestAnimationFrame(checkSize);
            }
            else if (currentRect) {
                // After the timeout we just set the last value we got, even if it is not the final one
                setRect(currentRect);
            }
        };
        window === null || window === void 0 ? void 0 : window.requestAnimationFrame(checkSize);
        setRect((_b = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect()) !== null && _b !== void 0 ? _b : null);
    };
    const refCB = useCallback((node) => {
        ref.current = node;
        set();
    }, []);
    useEffect(() => {
        if (typeof window === 'undefined')
            return () => undefined;
        window.addEventListener('resize', set);
        return () => {
            if (typeof window === 'undefined')
                return;
            window.removeEventListener('resize', set);
        };
    }, []);
    return [rect, refCB, set];
};
export default useBbox;
