import type { TourConfig } from '@g360/vt-types';

/** Flattens subScenes into main `scenes` property of TourConfig, mutates the given object */
export function flattenSubScenes(tourConfig: TourConfig) {
  const conf = tourConfig;
  const scenes = Object.values(tourConfig.scenes);

  for (let i = 0; i < scenes.length; i += 1) {
    const scene = scenes[i];
    if (scene.subScenes) {
      Object.entries(scene.subScenes).forEach(([subSceneKey, subScene]) => {
        conf.scenes[subSceneKey] = subScene;
      });
      delete scene.subScenes;
    }
  }
}
