import { useCallback, useRef } from 'react';
const useDoubletapGuard = (toggleAction, cooldown = 3000, tapLength = 400) => {
    const lastTapRef = useRef(0);
    const lastToggleRef = useRef(0);
    const handleInteraction = useCallback(() => {
        const currentTime = new Date().getTime();
        const currentTapLength = currentTime - lastTapRef.current;
        const timeSinceLastToggle = currentTime - lastToggleRef.current;
        if (currentTapLength < tapLength && currentTapLength > 0) {
            // Prevent rapid toggling
            if (timeSinceLastToggle >= cooldown) {
                toggleAction();
                lastToggleRef.current = currentTime;
            }
        }
        lastTapRef.current = currentTime;
    }, [toggleAction, cooldown, tapLength]);
    return handleInteraction;
};
export default useDoubletapGuard;
