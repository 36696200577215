import { useEffect, useState } from "react";
import { LocalDataTrack, Room } from "twilio-video";

import {
  TwilioMessageMap,
  TwilioMessagePayload,
  CallBack,
  TwilioMessageEmitterType,
  ReceivedTwilioMessageType,
} from "types/twilio.messages.types";

const TwilioMessageEmitterFactory = (): TwilioMessageEmitterType => {
  let listeners: Record<string, any[]> = {};

  const unsubscribe = <K extends keyof TwilioMessageMap>(
    event: K,
    fn: CallBack<K>
  ) => {
    listeners[event] = listeners[event]?.filter((c) => c !== fn) ?? [];
  };

  const subscribe = <K extends keyof TwilioMessageMap>(
    type: K,
    fn: (payload: TwilioMessagePayload<K>) => void
  ) => {
    if (!listeners[type]) {
      listeners[type] = [];
    }

    if (listeners[type]?.indexOf(fn) === -1) {
      listeners[type]?.push(fn);
    }

    return {
      unsubscribe: () => {
        unsubscribe(type, fn);
      },
    };
  };

  const emit = <K extends keyof TwilioMessageMap>(
    event: K,
    payload: TwilioMessagePayload<K>
  ) => {
    if (listeners[event] && listeners[event]?.length) {
      listeners[event]?.forEach((listener) => listener(payload));
    }
  };

  const destroyEventEmitter = () => {
    listeners = {};
  };

  return { subscribe, emit, destroyEventEmitter };
};

const useTwilioMessageEmitter = (
  room: Room | null,
  localDataTrack: LocalDataTrack
) => {
  const [TwilioMessageEmitter] = useState<TwilioMessageEmitterType>(
    TwilioMessageEmitterFactory()
  );

  useEffect(() => {
    // Outgoing events
    if (!localDataTrack) return;
    const outgoingEvents: (keyof TwilioMessageMap)[] = [
      "tour.control.send",
      "tour.keyframe.send",
      "tour.pointer.send",
      "handshake.ping.send",
      "handshake.pong.send",
      "host.info.send",
      "room.close.send",
      "participant.decline.send",
    ];

    const subscriptions = outgoingEvents.map((event) =>
      TwilioMessageEmitter.subscribe(event, (payload) => {
        localDataTrack.send(JSON.stringify({ event, payload }));
      })
    );

    return () =>
      subscriptions.forEach((subscription) => subscription.unsubscribe());
  }, [localDataTrack]);

  useEffect(() => {
    // Incoming events
    if (!room) return;

    room.on("trackSubscribed", (track) => {
      track.on("message", (jsonData: string) => {
        const data = JSON.parse(jsonData) as ReceivedTwilioMessageType<
          keyof TwilioMessageMap
        >;

        if (!data.event) return;

        const receivedData = {
          event: data.event.replace(".send", ".receive"),
          payload: data.payload,
        } as ReceivedTwilioMessageType<keyof TwilioMessageMap>;
        TwilioMessageEmitter.emit(receivedData.event, receivedData.payload);
      });
    });
  }, [room]);

  return TwilioMessageEmitter;
};

export default useTwilioMessageEmitter;
