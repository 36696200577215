import { useRef, useEffect } from "react";

const use100vh = () => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      const el = divRef.current;
      if (!el) return;
      el.style.minHeight = window.innerHeight + "px";
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { divRef };
};

export default use100vh;
