import { useState, useEffect } from "react";

const ensureMediaPermissions = async () => {
  const devices = await navigator.mediaDevices.enumerateDevices();
  const shouldAskForMediaPermissions = devices.every(
    (device) => !(device.deviceId && device.label)
  );
  if (shouldAskForMediaPermissions) {
    return navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then((mediaStream) =>
        mediaStream.getTracks().forEach((track) => track.stop())
      )
      .catch((err) => {
        console.log("Error get devices", err);
      });
  } else {
    return;
  }
};

const useDevices = (
  videoAccess: boolean | undefined,
  audioAccess: boolean | undefined
) => {
  const [audioOutputDevices, setAudioOutputDevices] = useState<
    MediaDeviceInfo[]
  >([]);
  const [audioInputDevices, setAudioInputDevices] = useState<MediaDeviceInfo[]>(
    []
  );
  const [videoInputDevices, setVideoInputDevices] = useState<MediaDeviceInfo[]>(
    []
  );

  const areDeviceListsEqual = (
    oldList: MediaDeviceInfo[],
    newList: MediaDeviceInfo[]
  ) => {
    const oldDeviceIdSet = new Set(oldList.map((device) => device.deviceId));
    const newDeviceIdSet = new Set(newList.map((device) => device.deviceId));
    return (
      oldDeviceIdSet.size === newDeviceIdSet.size &&
      Array.from(oldDeviceIdSet).every((deviceId) =>
        newDeviceIdSet.has(deviceId)
      )
    );
  };

  useEffect(() => {
    if (!audioAccess && !videoAccess) return;

    const getDevices = () =>
      ensureMediaPermissions().then(() =>
        navigator.mediaDevices.enumerateDevices().then((devices) => {
          setAudioOutputDevices((prevAudioOutputDevices) => {
            const newAudioOutputDevices = devices.filter(
              (device) => device.kind === "audiooutput" && device.deviceId
            );

            if (
              areDeviceListsEqual(prevAudioOutputDevices, newAudioOutputDevices)
            ) {
              return prevAudioOutputDevices;
            }
            return newAudioOutputDevices;
          });

          setAudioInputDevices((prevAudioInputDevices) => {
            if (!audioAccess) return [];
            const newAudioInputDevices = devices.filter(
              (device) => device.kind === "audioinput" && device.deviceId
            );

            if (
              areDeviceListsEqual(prevAudioInputDevices, newAudioInputDevices)
            ) {
              return prevAudioInputDevices;
            }
            return newAudioInputDevices;
          });

          setVideoInputDevices((prevVideoInputDevices) => {
            if (!videoAccess) return [];
            const newVideoInputDevices = devices.filter(
              (device) => device.kind === "videoinput" && device.deviceId
            );

            if (
              areDeviceListsEqual(prevVideoInputDevices, newVideoInputDevices)
            ) {
              return prevVideoInputDevices;
            }
            return newVideoInputDevices;
          });
        })
      );

    getDevices();
    navigator.mediaDevices.addEventListener("devicechange", getDevices);

    return () => {
      navigator.mediaDevices.removeEventListener("devicechange", getDevices);
    };
  }, [audioAccess, videoAccess]);

  return { audioOutputDevices, audioInputDevices, videoInputDevices };
};

export default useDevices;
