import type { Destroyable, FPMesh } from '@g360/vt-types';

import { destroyProgram, initShaders } from '../../common/webglUtils';
import type FloorPlan3DProgram from './FloorPlan3DProgram';

/**
 * Base for all programs in FloorPlan3D
 * All programs try to get locations of all the mentioned uniforms and attributes
 * No biggie if a shader doesn't have all of them
 */
class FPWebGLProgram implements Destroyable {
  program: WebGLProgram | null = null;

  gl: WebGL2RenderingContext;
  canvas: HTMLCanvasElement;
  parentProgram: FloorPlan3DProgram;

  goodMeshIds: number[] = [];
  meshes: FPMesh[] = [];

  isInitialized = false;

  positionLocation = 0;
  texCoordLocation = 0;
  normalLocation = 0;
  expandCoordLocation = 0;
  mainTexLocation: WebGLUniformLocation | null = null;
  shadowMapLocation: WebGLUniformLocation | null = null;
  farLocation: WebGLUniformLocation | null = null;
  nearLocation: WebGLUniformLocation | null = null;
  actualCameraPosLocation: WebGLUniformLocation | null = null;
  matrixWorldPosLocation: WebGLUniformLocation | null = null;
  matrixViewPosLocation: WebGLUniformLocation | null = null;
  matrixProjectionPosLocation: WebGLUniformLocation | null = null;
  matrixSunWorldPosLocation: WebGLUniformLocation | null = null;
  matrixSunViewPosLocation: WebGLUniformLocation | null = null;
  matrixSunProjectionPosLocation: WebGLUniformLocation | null = null;
  sunFarLocation: WebGLUniformLocation | null = null;
  sunDepthMapSizeLocation: WebGLUniformLocation | null = null;
  sunDirectionLocation: WebGLUniformLocation | null = null;
  lightColorLocation: WebGLUniformLocation | null = null;
  alphaLocation: WebGLUniformLocation | null = null;

  timeLocation: WebGLUniformLocation | null = null; // only for debug
  /** Number of triangles drawn in the last draw call  @todo -- rem later */
  numTrisDrawn = 0;

  protected vertexAttributes: number[] = [];

  constructor(gl: WebGL2RenderingContext, canvas: HTMLCanvasElement, parentProgram: FloorPlan3DProgram) {
    this.gl = gl;
    this.canvas = canvas;
    this.parentProgram = parentProgram;
  }

  init(vertexShader: string, fragmentShader: string): void {
    this.program = initShaders(this.gl, vertexShader, fragmentShader);
    this.isInitialized = true;
    if (!this.program) return;
    this.positionLocation = this.gl.getAttribLocation(this.program, 'a_position');
    this.texCoordLocation = this.gl.getAttribLocation(this.program, 'a_texCoord');
    this.normalLocation = this.gl.getAttribLocation(this.program, 'a_normal');
    this.expandCoordLocation = this.gl.getAttribLocation(this.program, 'a_expandCoord');
    this.mainTexLocation = this.gl.getUniformLocation(this.program, 'u_mainTex');
    this.shadowMapLocation = this.gl.getUniformLocation(this.program, 'u_shadowMap');
    this.timeLocation = this.gl.getUniformLocation(this.program, 'u_time');
    this.actualCameraPosLocation = this.gl.getUniformLocation(this.program, 'u_actualCameraPos');
    this.matrixWorldPosLocation = this.gl.getUniformLocation(this.program, 'u_worldMatrix');
    this.matrixViewPosLocation = this.gl.getUniformLocation(this.program, 'u_viewMatrix');
    this.matrixProjectionPosLocation = this.gl.getUniformLocation(this.program, 'u_projectionMatrix');
    this.matrixSunWorldPosLocation = this.gl.getUniformLocation(this.program, 'u_sunWorldMatrix');
    this.matrixSunViewPosLocation = this.gl.getUniformLocation(this.program, 'u_sunViewMatrix');
    this.matrixSunProjectionPosLocation = this.gl.getUniformLocation(this.program, 'u_sunProjectionMatrix');
    this.sunFarLocation = this.gl.getUniformLocation(this.program, 'u_sunFar');
    this.nearLocation = this.gl.getUniformLocation(this.program, 'u_near');
    this.farLocation = this.gl.getUniformLocation(this.program, 'u_far');
    this.sunDepthMapSizeLocation = this.gl.getUniformLocation(this.program, 'u_sunDepthMapSize');
    this.sunDirectionLocation = this.gl.getUniformLocation(this.program, 'u_sunDirection');
    this.lightColorLocation = this.gl.getUniformLocation(this.program, 'u_lightColor');
    this.alphaLocation = this.gl.getUniformLocation(this.program, 'u_alpha');
  }

  destroy(): void {
    destroyProgram(this.gl, this.program);
  }
}

export default FPWebGLProgram;
