import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import useGlobalContext from '../../../contexts/useGlobalContext';
import RoundedButton from '../../common/components/RoundedButton';
import useAutoPlay from '../hooks/useAutoPlay';
import PauseIcon from '../icons/PauseIcon';
import PlayIcon from '../icons/PlayIcon';
import SpeedIcon from '../icons/SpeedIcon';
import AutoPlayProgressBar from './AutoPlayProgressBar';
import SpeedButton from './SpeedButton';
const { recordVtApi } = typeof window !== 'undefined' ? window : {};
const AutoPlay = ({ scenes, autorun, // start autoplay automatically, we use it for video generation
 }) => {
    const { play, pause, seek, status, setPlaybackSpeed: setSpeed, playbackSpeed, progress } = useAutoPlay(scenes);
    const isPlaying = status === 'playing';
    const [showSpeeds, setShowSpeeds] = useState(false);
    const { engine } = useGlobalContext();
    const tcService = engine.getTourConfigService();
    const panos = tcService.tourConfig.scenes;
    useEffect(() => {
        const pauseOnClick = () => {
            pause();
        };
        if (isPlaying) {
            setTimeout(() => {
                document.addEventListener('click', pauseOnClick);
            }, 100);
        }
        return () => document.removeEventListener('click', pauseOnClick);
    }, [isPlaying]);
    useEffect(() => {
        if (autorun && !isPlaying && recordVtApi.capturing) {
            recordVtApi.stopCapturing();
        }
    }, [isPlaying]);
    useEffect(() => {
        if (engine && autorun) {
            if (recordVtApi.playbackSpeed) {
                setSpeed(recordVtApi.playbackSpeed);
            }
            recordVtApi.startCapturing(engine.getCanvasElement()).then(play);
        }
    }, [engine, autorun]);
    const setPlaybackSpeed = (speed) => {
        setSpeed(speed);
        setShowSpeeds(false);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "fixed bottom-[75px] right-[20px] transition-all duration-300 ease-in-out", children: [showSpeeds && isPlaying && (_jsxs("div", { className: "mb-[18px] flex w-[165px] flex-col rounded bg-[#485363]", children: [_jsx(SpeedButton, { setSpeed: setPlaybackSpeed, speed: 1.75, value: playbackSpeed, children: "Faster" }), _jsx(SpeedButton, { setSpeed: setPlaybackSpeed, speed: 1, value: playbackSpeed, children: "Normal" }), _jsx(SpeedButton, { setSpeed: setPlaybackSpeed, speed: 0.5, value: playbackSpeed, children: "Slower" })] })), _jsxs("div", { className: "flex justify-end space-x-[15px]", children: [isPlaying && (_jsx(RoundedButton, { onClick: (e) => {
                                    e.stopPropagation();
                                    setShowSpeeds(!showSpeeds);
                                }, children: _jsx(SpeedIcon, {}) })), _jsxs(RoundedButton, { onClick: () => {
                                    if (isPlaying) {
                                        pause();
                                    }
                                    else {
                                        play();
                                    }
                                }, children: [isPlaying && _jsx(PauseIcon, {}), !isPlaying && _jsx(PlayIcon, {})] })] })] }), !autorun && (_jsx(AutoPlayProgressBar, { scenes: scenes, progress: progress, seek: seek, status: status, panos: panos }))] }));
};
export default AutoPlay;
