import { Modal } from "components/layout";
import { useAppStateContext, useDeviceStateContext } from "hooks";
import { FC, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import ParticipantSettingWrapperDesktop from "./ParticipantSettingWrapperDesktop/ParticipantSettingWrapperDesktop";
import ParticipantSettingWrapperMobile from "./ParticipantSettingWrapperMobile/ParticipantSettingWrapperMobile";

export type SettingTabType = "audio" | "video" | "copy invite link" | "none";

interface ParticipantSettingsContainerProps {
  isSettingsModalVisible: boolean;
  handleSettingsModalClose: () => void;
}

const ParticipantSettingsContainer: FC<ParticipantSettingsContainerProps> = ({
  isSettingsModalVisible,
  handleSettingsModalClose,
}) => {
  const { isHost } = useAppStateContext();
  const {
    selectedAudioInput,
    selectedAudioOutput,
    selectedVideoInput,
    setSelectedAudioInput,
    setSelectedVideoInput,
    setSelectedAudioOutput,
  } = useDeviceStateContext();

  const isSmallScreen = useMediaQuery({ query: "(max-width: 720px)" });

  const [initialAudioInput, setInitialAudioInput] = useState<
    MediaDeviceInfo | undefined | null
  >();
  const [initialVideoInput, setInitialVideoInput] = useState<
    MediaDeviceInfo | undefined | null
  >();
  const [initialAudioOutput, setInitialAudioOutput] = useState<
    MediaDeviceInfo | undefined | null
  >();

  const [settingTab, setSettingTab] = useState<SettingTabType>(
    isHost ? "copy invite link" : "audio"
  );

  useEffect(() => {
    if (!initialAudioInput) setInitialAudioInput(selectedAudioInput);
    if (!initialAudioOutput) setInitialAudioOutput(selectedAudioOutput);
    if (!initialVideoInput) setInitialVideoInput(selectedVideoInput);
  }, [
    selectedAudioInput,
    selectedAudioOutput,
    selectedVideoInput,
    initialAudioInput,
    initialAudioOutput,
    initialVideoInput,
  ]);

  const cleanUpState = () => {
    setInitialAudioInput(undefined);
    setInitialVideoInput(undefined);
    setInitialAudioOutput(undefined);
    handleSettingsModalClose();
    setSettingTab(isHost ? "copy invite link" : "audio");
  };

  const handleCancel = () => {
    setSelectedAudioInput(initialAudioInput);
    setSelectedVideoInput(initialVideoInput);
    setSelectedAudioOutput(initialAudioOutput);
    cleanUpState();
  };

  const handleDone = () => {
    cleanUpState();
  };

  return (
    <Modal
      minHeight={isSmallScreen ? "500" : "auto"}
      modalWidth="3xl"
      modalTitle="Settings"
      isModalVisible={isSettingsModalVisible}
      overflow={isSmallScreen ? "auto" : "hidden"}
      handleCancelClick={handleCancel}
      topPosition={isSmallScreen ? "2" : "20"}
    >
      {isSmallScreen ? (
        <ParticipantSettingWrapperMobile
          settingTab={settingTab}
          handleDone={handleDone}
          handleCancel={handleCancel}
          handelTabChange={setSettingTab}
        />
      ) : (
        <ParticipantSettingWrapperDesktop
          settingTab={settingTab}
          handleDone={handleDone}
          handleCancel={handleCancel}
          handelTabChange={setSettingTab}
          isSettingsModalVisible={isSettingsModalVisible}
        />
      )}
    </Modal>
  );
};

export default ParticipantSettingsContainer;
