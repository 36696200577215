import type { HotSpot3DConfig, HotSpotConfig, SceneConfig } from '@g360/vt-types';
import cloneDeep from 'lodash/cloneDeep';

/** Translates the hotspot position according to the difference in scene yawOffsets */
export function translateHotspotPosition<T extends HotSpotConfig | HotSpot3DConfig>(
  fromScene: SceneConfig,
  toScene: SceneConfig,
  hotspot: T
): T {
  // This is a 3D hotspot, we don't translate these
  if (hotspot.pos.length === 3) return hotspot;

  const fromYaw = fromScene.camera[3];
  const toYaw = toScene.camera[3];

  const cloned = cloneDeep(hotspot);

  cloned.pos[1] += toYaw - fromYaw;

  return cloned;
}
