class EventEmitter<EventPair extends Record<string, any[]>> {
  private listeners: Record<keyof EventPair, any[]> = {} as any;

  subscribe<Key extends keyof EventPair>(type: Key, fn: (...payload: EventPair[Key]) => void) {
    if (!this.listeners[type]) {
      this.listeners[type] = [];
    }

    if (this.listeners[type].indexOf(fn) === -1) {
      this.listeners[type].push(fn);
    }

    return {
      unsubscribe: () => {
        this.unsubscribe(type, fn);
      },
    };
  }

  /**
   * run the listener only once if one of the events happens
   */
  once<Key extends keyof EventPair>(types: Key[], listener: () => void) {
    let cleaners: Array<() => void> = [];

    function clean() {
      cleaners.forEach((c) => c());
    }

    cleaners = types.map((type) => {
      const subscription = this.subscribe(type, () => {
        clean();
        listener();
      });
      return () => subscription.unsubscribe();
    });

    return clean;
  }

  unsubscribe<Key extends keyof EventPair>(event: Key, fn: (...payload: EventPair[Key]) => void): void {
    this.listeners[event] = this.listeners[event].filter((c) => c !== fn);
  }

  emit<Key extends keyof EventPair>(event: Key, ...payload: EventPair[Key]): void {
    if (this.listeners[event] && this.listeners[event].length) {
      this.listeners[event].forEach((listener) => listener(...payload));
    }
  }
}

export default EventEmitter;
