import type { AssetConfig, EquirectAssetConfig, EquirectAssetPath, TileAssetPath, TourConfig } from '@g360/vt-types';
import urljoin from 'url-join';

// eslint-disable-next-line import/prefer-default-export
export function makeTilesPath(tourConfig: TourConfig, basePath: string, sceneKey: string): TileAssetPath {
  // Handle custom path to the panos tiles
  if (tourConfig?.tiles?.path) {
    return `${tourConfig.tiles.path.replace('%p', `${sceneKey}`)}`;
  }

  return urljoin(basePath, `/panos/${sceneKey}.tiles`);
}

/**
 * Make a path to the specific equirectangular asset based on the asset config and the scene key.
 * Returns a tuple of [primaryAssetPath, secondaryAssetPath]
 */
export function makeEquirectPath(sceneKey: string, assetConfig: AssetConfig, customPath?: string): EquirectAssetPath {
  if (!assetConfig.equirectAssets) {
    throw new Error("Equirect assets are not defined in the asset config. Can't load equirect pano.");
  }

  const { equirectAssets, basePath } = assetConfig;
  const { primaryAssets, secondaryAssets, primaryOptions, secondaryOptions } = equirectAssets;

  const getAssetPath = (config?: EquirectAssetConfig): string | undefined => {
    if (customPath) {
      // Used in development / local testing
      return urljoin(basePath, `${customPath.replace('%p.tiles', `${sceneKey}`)}.jpg`);
    }

    if (config === 'auto') {
      return urljoin(basePath, 'panos', `${sceneKey}.jpg`);
    }

    if (config instanceof Map) {
      return config.get(sceneKey);
    }

    if (typeof config === 'string') {
      return urljoin(config, `${sceneKey}.jpg`);
    }

    return undefined;
  };

  const primaryPath = getAssetPath(primaryAssets);
  const secondaryPath = getAssetPath(secondaryAssets);

  if (!primaryPath) {
    throw new Error(`Equirect primary assets are required!`);
  }

  return {
    primaryPath,
    secondaryPath,
    primaryOptions,
    secondaryOptions,
  };
}
