import type { Degree, Radian, Size } from '@g360/vt-types';

import Utils from '../../Utils';
import getMinMaxPitch from './getMinMaxPitch';

export default function clampPitch(
  targetPitch: Radian,
  targetFov: Radian,
  canvasSize: Size,
  minPitchConfig?: Degree,
  maxPitchConfig?: Degree
): Radian {
  const pitchDeg = Utils.toDeg(targetPitch);

  // hard clamping if both limits are given
  if (minPitchConfig !== undefined && maxPitchConfig !== undefined) {
    return Utils.toRad(Utils.clamp(pitchDeg, minPitchConfig, maxPitchConfig));
  }

  // alternatively: magical calculation using FOV
  const { minPitch, maxPitch } = getMinMaxPitch(targetFov, canvasSize, minPitchConfig);

  return Utils.toRad(Utils.clamp(pitchDeg, minPitch, maxPitch));
}
