import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Transition } from '@headlessui/react';
import { useTranslation } from '../../../contexts/useLocalizationContext';
import useServices from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
function IFrameZoomGuard() {
    const { layoutService } = useServices();
    const { showIFrameZoomGuard } = useReactive(layoutService, ['showIFrameZoomGuard']);
    const t = useTranslation();
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.userAgent.toUpperCase().indexOf('MAC') >= 0;
    const controlKey = !isMac;
    return (_jsxs(Transition, { show: showIFrameZoomGuard, className: "z-tutorial-anim pointer-events-none absolute inset-0 flex select-none flex-col items-center justify-center", enter: "transition duration-[800ms] ease-out", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "transition duration-[800ms] ease-out", leaveFrom: "opacity-100", leaveTo: "opacity-0", "data-testid": "iframe-zoom-guard", children: [_jsx("div", { className: "bg-modal-dark/30 absolute left-1/2 top-1/2 -z-10 h-[12.5rem] w-[25.625rem] -translate-x-1/2 -translate-y-1/2 blur-[77px]" }), _jsx("div", { className: " relative h-[65px] text-[11px]/[27px] font-medium text-white", children: _jsxs("div", { className: "relative top-[13px] flex gap-1.5", children: [_jsx("div", { className: "flex-shrink-0", children: _jsx("div", { className: "grid min-w-[40px] place-items-center rounded-2xl border-[2px] border-white px-3 py-1", children: _jsx("p", { className: "whitespace-nowrap", children: controlKey ? t('iframe-zoom-guard.key-control') : t('iframe-zoom-guard.key-command') }) }) }), _jsx("div", { className: "mx-1 flex-shrink-0", children: _jsx("div", { className: "grid place-items-center text-lg/[36px]", children: _jsx("p", { children: "+" }) }) }), _jsx("div", { className: "flex-shrink-0", children: _jsx("div", { className: "grid min-w-[40px] place-items-center rounded-2xl border-[2px] border-white px-3 py-1", children: _jsxs("p", { className: "whitespace-nowrap", children: [" ", t('iframe-zoom-guard.action')] }) }) })] }) }), _jsx("div", { className: "font-primary max-w-[16rem] text-center text-lg font-bold text-white", children: controlKey ? t('iframe-zoom-guard.main-text-control') : t('iframe-zoom-guard.main-text-command') })] }));
}
export default IFrameZoomGuard;
