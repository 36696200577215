import { useEffect, useState } from 'react';
import useGlobalContext from '../../../contexts/useGlobalContext';
const usePreloading = () => {
    const { engine } = useGlobalContext();
    const [preloading, setPreloading] = useState(false);
    useEffect(() => {
        const preloadStartSub = engine.subscribe('scene.preload.start', () => {
            setPreloading(true);
        });
        const preloadEndSub = engine.subscribe('scene.preload.end', () => {
            setPreloading(false);
        });
        return () => {
            preloadStartSub.unsubscribe();
            preloadEndSub.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { preloading };
};
export default usePreloading;
