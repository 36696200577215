/**
 * Creates a WebGL program from a vertex and fragment shaders.
 * @param gl - `WebGLRenderingContext`: An interface to the OpenGL ES 2.0 graphics rendering context for the drawing surface of an HTML `<canvas>` element.
 * @param vertexShader - `WebGLShader`: A compiled vertex shader to attach to the program.
 * @param fragmentShader - `WebGLShader`: A compiled fragment shader to attach to the program.
 * @returns `WebGLProgram | null`: The created program or `null` if the program wasn't created or shader linking failed.
 */
function createProgram(
  gl: WebGLRenderingContext,
  vertexShader: WebGLShader,
  fragmentShader: WebGLShader
): WebGLProgram | null {
  const program = gl.createProgram();

  if (!program) return null;

  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);
  gl.linkProgram(program);

  if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
    console.warn(gl.getProgramInfoLog(program));
    return null;
  }

  return program;
}

export default createProgram;
