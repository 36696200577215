/* eslint-disable no-await-in-loop,no-continue */
import type { FPMesh } from '@g360/vt-types';
import type { GltfAsset } from 'gltf-loader-ts';
import { GLTF_COMPONENT_TYPE_ARRAYS, GltfLoader } from 'gltf-loader-ts';
import type { Accessor, GlTf, MeshPrimitive } from 'gltf-loader-ts/lib/gltf';

import { identityM4, multiplyM4, scalingM4, translateM4 } from '../../matrix';
import { isThereANan } from '../utils';
import { createExpandCoordsForMesh, fixUpMesh, makePipesFromLines } from './fixUpMesh';

let meshUnqId = 0;

/**
 * Position data ( VEC3::FLOAT32 )
 */
const getPosData = async (primitive: MeshPrimitive, accessors: Accessor[], asset: GltfAsset): Promise<Float32Array> => {
  const index = primitive.attributes.POSITION;
  const info = accessors[index];
  const num = info.count * 3; // 3 floats per point
  const DataType = GLTF_COMPONENT_TYPE_ARRAYS[info.componentType];

  const posDataRaw: Uint8Array = await asset.accessorData(index);
  return new DataType(posDataRaw.buffer, posDataRaw.byteOffset, num);
};

/**
 * Triangle index data ( SCALAR::UINT16 )
 */
const getIndexData = async (
  primitive: MeshPrimitive,
  accessors: Accessor[],
  asset: GltfAsset
): Promise<Uint16Array | null> => {
  if (primitive.indices === undefined) return null;
  const index = primitive.indices;
  const info = accessors[index];
  if (!info) return null;
  const num = info.count;
  const DataType = GLTF_COMPONENT_TYPE_ARRAYS[info.componentType]; // `new indexDataType` to dynamically get right data type

  const indexDataRaw: Uint8Array = await asset.accessorData(index);
  return new DataType(indexDataRaw.buffer, indexDataRaw.byteOffset, num);
};

/**
 * UV map (texture coordinates) data ( VEC2::FLOAT32 )
 */
const getTexCoordData = async (
  primitive: MeshPrimitive,
  accessors: Accessor[],
  asset: GltfAsset
): Promise<Float32Array | null> => {
  const index = primitive.attributes.TEXCOORD_0;
  if (index === undefined) return null;
  const info = accessors[index];
  const num = info.count * 2; // 2 floats per point
  const DataType = GLTF_COMPONENT_TYPE_ARRAYS[info.componentType];

  const texCoordDataRaw: Uint8Array = await asset.accessorData(index);
  return new DataType(texCoordDataRaw.buffer, texCoordDataRaw.byteOffset, num);
};

export const getBoundingBoxesAndCenter = (meshes: FPMesh[]) => {
  let minX = Infinity;
  let minY = Infinity;
  let minZ = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;
  let maxZ = -Infinity;

  meshes.forEach((mesh) => {
    for (let i = 0; i < mesh.positions.length; i += 3) {
      const x = mesh.positions[i];
      const y = mesh.positions[i + 1];
      const z = mesh.positions[i + 2];

      minX = Math.min(minX, x);
      minY = Math.min(minY, y);
      minZ = Math.min(minZ, z);
      maxX = Math.max(maxX, x);
      maxY = Math.max(maxY, y);
      maxZ = Math.max(maxZ, z);
    }
  });

  const boundingBoxMin = [minX, minY, minZ];
  const boundingBoxMax = [maxX, maxY, maxZ];

  //  center of the bounding box (volume center)
  const center = [(minX + maxX) / 2, (minY + maxY) / 2, (minZ + maxZ) / 2];

  return { center, boundingBoxMin, boundingBoxMax };
};

/**
 * Calculate normals for the mesh,
 * Only when not converting to internal format,
 */
function calculateNormals(msh: FPMesh) {
  const positions = msh.positions;
  const normals = new Float32Array(msh.positions.length);
  const indices = msh.triangles;

  normals.fill(0);

  for (let i = 0; i < indices.length; i += 3) {
    const i0 = indices[i] * 3;
    const i1 = indices[i + 1] * 3;
    const i2 = indices[i + 2] * 3;
    const p0 = [positions[i0], positions[i0 + 1], positions[i0 + 2]];
    const p1 = [positions[i1], positions[i1 + 1], positions[i1 + 2]];
    const p2 = [positions[i2], positions[i2 + 1], positions[i2 + 2]];
    const edge1 = [p1[0] - p0[0], p1[1] - p0[1], p1[2] - p0[2]];
    const edge2 = [p2[0] - p0[0], p2[1] - p0[1], p2[2] - p0[2]];
    const normal = [
      edge1[1] * edge2[2] - edge1[2] * edge2[1],
      edge1[2] * edge2[0] - edge1[0] * edge2[2],
      edge1[0] * edge2[1] - edge1[1] * edge2[0],
    ];
    const length = Math.sqrt(normal[0] * normal[0] + normal[1] * normal[1] + normal[2] * normal[2]);
    if (length > 0) {
      normal[0] /= length;
      normal[1] /= length;
      normal[2] /= length;
    }
    for (let j = 0; j < 3; j += 1) {
      normals[i0 + j] += normal[j];
      normals[i1 + j] += normal[j];
      normals[i2 + j] += normal[j];
    }
  }
  for (let i = 0; i < normals.length; i += 3) {
    const nx = normals[i];
    const ny = normals[i + 1];
    const nz = normals[i + 2];
    const length = Math.sqrt(nx * nx + ny * ny + nz * nz);
    if (length > 0) {
      normals[i] /= length;
      normals[i + 1] /= length;
      normals[i + 2] /= length;
    }
  }
  // eslint-disable-next-line no-param-reassign
  msh.normals = normals;
}

export const createFPMesh = async (
  nodeName: string,
  matId: number,
  posData: Float32Array,
  indexData: Uint16Array,
  texCoordData: Float32Array,
  convertToInternal: boolean,
  meshes: FPMesh[],
  nodePath: string,
  roomIds: string[],
  transformationMatrix: number[] | undefined
) => {
  const isCeiling = nodePath.includes('ceilings');
  const isFloor = nodePath.includes('floors');
  const isBottomWall = nodePath.includes('/bot_walls');
  const isTopWall = nodePath.includes('/top_walls');
  const isWall = nodePath.includes('wall') || nodePath.includes('fillblock');
  const isWallCap = nodePath.includes('cap') && matId !== 1;
  const isSideCap = !isWallCap && matId === 2;
  const isOutline = matId === 3; // material 3 is outline material

  // let skip = false;
  // // if(primitive.material === 2) skip = true;
  // if (isFloor) skip = false;
  // if (skip) continue;

  // @todo -- varbūt nevanag manus isXxxxxx, bet visu ar materiāliem?
  // mat 0 - sienu augšiņas un griesti
  // mat 1 - mēbeles sienu apakšiņas
  // mat 2 - wall caps: augšējās un vidējās, side caps: sāni, kas var tikt zīmēti melni pie šķērsgriezuma
  // mat 3 - outline
  // mat 4 -
  // mat 5 -

  if (indexData.length === 0) return; // not really a concern with models seen so far
  meshUnqId += 1;

  const msh: FPMesh = {
    name: nodeName,
    nodePath,
    isCeiling,
    isFloor,
    isBottomWall,
    isTopWall,
    isWall,
    isWallCap,
    isSideCap,
    isOutline,
    panoIds: roomIds, // roomIds are panoIds, @todo -- maybe later get actual room IDs ?
    shape2d: [],
    center2d: [0, 0],
    wallFragmentId: -1,
    wallFragmentCenter: [0, 0],
    unfocusedRoom: false,
    skipSolidRendering: false,
    skipFlatRendering: false,
    isWallLowered: false,
    roomIds: [],
    positions: posData,
    expandCoords: new Float32Array(0),
    triangles: indexData,
    normals: new Float32Array(0),
    texCoords: texCoordData,
    dataNum: 0,
    dataOffsetExpandCoords: 0,
    dataOffsetNormals: 0,
    dataOffsetPositions: 0,
    dataOffsetTexCoords: 0,
    unqId: meshUnqId,
    debugTxt: `mat:${matId}`,
  };

  if (convertToInternal) {
    fixUpMesh(msh, transformationMatrix);

    if (msh.isOutline) {
      makePipesFromLines(msh);
      createExpandCoordsForMesh(msh);
    }
  } else {
    // converting to internal creates normals, so we need to calculate them here
    calculateNormals(msh);
  }
  console.log(msh);

  if (
    ![
      -1,
      // 493
    ].includes(msh.unqId)
  ) {
    meshes.push(msh);
  }

  // console.log(msh.debugId, msh.name, nodePath, matId, msh);
  if (isThereANan(msh.positions)) console.error('there is a NaN in positions', msh.unqId, msh.name);
  if (isThereANan(msh.normals)) console.error('there is a NaN in normals', msh.unqId, msh.name);
  if (isThereANan(msh.texCoords)) console.error('there is a NaN in texCoords', msh.unqId, msh.name);
  if (isThereANan(msh.expandCoords)) console.error('there is a NaN in expandCoords', msh.unqId, msh.name);
  // }
};

const loadNode = async (
  asset: GltfAsset,
  convertToInternal: boolean,
  nodeIndex: number,
  meshes: FPMesh[],
  nodePath: string,
  roomIds: string[],
  transformationMatrix: number[] | undefined
) => {
  const gltf: GlTf = asset.gltf;
  if (!gltf.nodes) throw new Error('FloorPlan3DProgram::loadImages::no nodes in .glb');
  if (!gltf.meshes) throw new Error('FloorPlan3DProgram::loadImages::no meshes in .glb');
  const node = gltf.nodes[nodeIndex];

  const meshIndex = node.mesh ?? -1;
  if (meshIndex === -1) return;

  const accessors = asset.gltf.accessors || [];
  const mesh = gltf.meshes[meshIndex];

  for (let i = 0; i < mesh.primitives.length; i += 1) {
    const primitive = mesh.primitives[i];
    // await in loop is not the best,
    // better would be to await all promises and then process data,
    // but this is good enough for small .glb files
    const posData = await getPosData(primitive, accessors, asset);
    const indexData = await getIndexData(primitive, accessors, asset);
    if (indexData === null) continue;
    const texCoordData = (await getTexCoordData(primitive, accessors, asset)) ?? new Float32Array(0);

    await createFPMesh(
      node.name,
      primitive.material || 0,
      posData,
      indexData,
      texCoordData,
      convertToInternal,
      meshes,
      nodePath,
      roomIds,
      transformationMatrix
    );
  }
};

function quaternionToRotationMatrix(q: [number, number, number, number]): number[] {
  const [x, y, z, w] = q;
  return [
    1 - 2 * y * y - 2 * z * z,
    2 * x * y - 2 * z * w,
    2 * x * z + 2 * y * w,
    0,
    2 * x * y + 2 * z * w,
    1 - 2 * x * x - 2 * z * z,
    2 * y * z - 2 * x * w,
    0,
    2 * x * z - 2 * y * w,
    2 * y * z + 2 * x * w,
    1 - 2 * x * x - 2 * y * y,
    0,
    0,
    0,
    0,
    1,
  ];
}

function getTransformationMatrix(node: any) {
  const matrixNext = node?.matrix;

  // Grīnbergs' models have a combo matrix
  if (matrixNext) return matrixNext;

  // no transformation data, that's fine
  if (!node?.rotation && !node?.scale && !node?.translation) return undefined;

  // defaults
  const rotation: [number, number, number, number] = node?.rotation || [0, 0, 0, 1];
  const scale: [number, number, number] = node?.scale || [1, 1, 1];
  const translation: [number, number, number] = node?.translation || [0, 0, 0];

  // Initialize an identity matrix
  let transformMatrix = identityM4();

  // Apply scale
  transformMatrix = multiplyM4(transformMatrix, scalingM4(scale[0], scale[1], scale[2]));

  // Apply rotation (assuming rotation is in quaternion format)
  const rotationMatrix = quaternionToRotationMatrix(rotation);
  transformMatrix = multiplyM4(transformMatrix, rotationMatrix);

  // Apply translation
  transformMatrix = translateM4(transformMatrix, translation[0], translation[1], translation[2]);

  return transformMatrix;
}

/**
 *
 * @param asset
 * @param convertToInternal -- true if data is used for rendering, false if data is used for other processes
 * @param nodeIndices
 * @param meshes
 * @param pathAcc -- accumulated path: parent/child/grand-child
 * @param roomIdAcc -- accumulated roomIds: all roomIds from parent nodes
 * @param transformationMatrixAcc -- accumulated scale/rotation/translation matrix -- currently not accumulated, just latest found
 */
const loadNodesRecursively = async (
  asset: GltfAsset,
  convertToInternal: boolean,
  nodeIndices: number[],
  meshes: FPMesh[],
  pathAcc = '',
  roomIdAcc: string[] = [],
  transformationMatrixAcc: number[] | undefined = undefined
) => {
  const gltf: GlTf = asset.gltf;
  if (!gltf.nodes) throw new Error('FloorPlan3DProgram::loadImages::no nodes in .glb');

  for (let i = 0; i < nodeIndices.length; i += 1) {
    const nodeIndex = nodeIndices[i];
    const node = gltf.nodes[nodeIndex];

    // if no name is present, use parent name (it's a problem for furniture and portals)
    if (node.name === undefined) {
      node.name = pathAcc.split('/').pop() ?? 'no-name';
    }

    const pathAccNext = `${pathAcc}/${node.name}`;
    const roomIdAccNext = [...roomIdAcc, ...(node?.extras?.room_ids ?? [])];
    // const matrixNext = transformationMatrixAcc ?? node?.matrix; // not accumulating, just using the latest found "

    const matrixNext = getTransformationMatrix(node) ?? transformationMatrixAcc; // Does this still work with Grīnbergs' models?

    await loadNode(asset, convertToInternal, nodeIndex, meshes, pathAccNext, roomIdAccNext, matrixNext);
    if (node.children) {
      await loadNodesRecursively(
        asset,
        convertToInternal,
        node.children,
        meshes,
        pathAccNext,
        roomIdAccNext,
        matrixNext
      );
    }
  }
};

// @todo -- rem "convertToInternal" it's always true for browser loading
export const loadMeshes = async (asset: GltfAsset, convertToInternal: boolean) => {
  const meshes: FPMesh[] = [];

  const gltf: GlTf = asset.gltf; // @todo -- get rid of "asset", all functions down the line use only "gltf"
  const sceneIndex = gltf.scene ?? 0;
  if (!gltf.scenes) throw new Error('FloorPlan3DProgram::loadImages::no scenes in .glb');
  if (!gltf.nodes) throw new Error('FloorPlan3DProgram::loadImages::no nodes in .glb');
  if (!gltf.meshes) throw new Error('FloorPlan3DProgram::loadImages::no meshes in .glb');
  const scene = gltf.scenes[sceneIndex];
  const rootNodes = scene.nodes;
  if (!rootNodes) throw new Error(`FloorPlan3DProgram::loadImages::no root nodes in .glb`);

  await loadNodesRecursively(asset, convertToInternal, rootNodes, meshes);

  return meshes;
};

/**
 * Loading minimal info from the .glb model, using assumptions and hardcoded values.
 * Definitely not supporting any .glb file.
 * @param uri
 * @param convertToInternal -- change data format from indexed vertices to implicit vertices + other stuff
 */
export const loadGlb = async (uri: string, convertToInternal = true) => {
  const loader = new GltfLoader();
  const asset = await loader.load(uri);
  const gltf: GlTf = asset.gltf;
  if (!gltf || !gltf.scenes) throw new Error('FloorPlan3DProgram::loadGlb::no gltf data');
  const meshes = await loadMeshes(asset, convertToInternal);
  return meshes;
};
