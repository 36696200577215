import type { TourConfig } from '@g360/vt-types';

/** Convert array of sceneGroups to internal state structure of subScenes nested in scenes */
export function insertSubScenes(tourConfig: TourConfig): TourConfig {
  const sceneGroups = tourConfig.sceneGroups;
  if (!sceneGroups) return tourConfig;

  const scenes = tourConfig.scenes;

  // Insert scene groups as subScenes nested into main scenes
  sceneGroups.forEach((sceneGroup) => {
    const mainScene = scenes[sceneGroup[0]];

    if (!mainScene) throw new Error(`Scene with key ${sceneGroup[0]} not found in tourConfig`);

    for (let i = 1; i < sceneGroup.length; i += 1) {
      const sceneKey = sceneGroup[i];

      if (mainScene.subScenes === undefined) mainScene.subScenes = {};
      const subScene = scenes[sceneKey];

      // Account for this tourConfig already having nested scenes
      // This subScene has already been inserted, and a new one is not available to overwrite
      if (!subScene && mainScene.subScenes?.[sceneKey]) return;

      if (!subScene) throw new Error(`SubScene with key ${sceneKey} not found in tourConfig`);

      mainScene.subScenes[sceneKey] = subScene;
      delete scenes[sceneKey];
    }
  });

  return tourConfig;
}
