import { ErrorLogger } from '@g360/vt-utils';
import { useEffect, useState } from 'react';
function useSessionStorageFormState(key, initialState) {
    const [formState, setFormState] = useState(() => {
        const storedState = sessionStorage.getItem(key);
        if (storedState) {
            try {
                return JSON.parse(storedState);
            }
            catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
                ErrorLogger.captureMessage('Failed to parse cta form state from sessionStorage');
                sessionStorage.removeItem(key);
            }
        }
        return initialState;
    });
    useEffect(() => {
        try {
            sessionStorage.setItem(key, JSON.stringify(formState));
        }
        catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            ErrorLogger.captureMessage('Failed to save cta form state to sessionStorage');
        }
    }, [formState, key]);
    return { formState, setFormState };
}
export default useSessionStorageFormState;
