import compileShader from './compileShaders';
import createProgram from './createProgram';

/**
 * Compiles shaders from provided shader source code, create a program, attaches the shaders to the program and sets the program to be used as a part of the current rendering state.
 * @param gl - `WebGLRenderingContext`: An interface to the OpenGL ES 2.0 graphics rendering context for the drawing surface of an HTML `<canvas>` element.
 * @param vertexShaderSource - `string`: A vertex shader source code.
 * @param fragmentShaderSource - `string`: A fragment shader source code.
 * @returns `WebGLProgram | null`: The created program or `null` if shader compilation or program creation failed.
 */
function initShaders(
  gl: WebGLRenderingContext,
  vertexShaderSource: string,
  fragmentShaderSource: string
): WebGLProgram | null {
  const vertexShader = compileShader(gl, 'VERTEX_SHADER', vertexShaderSource);
  const fragmentShader = compileShader(gl, 'FRAGMENT_SHADER', fragmentShaderSource);

  if (!vertexShader) {
    // eslint-disable-next-line no-console
    console.warn('Failed to compile vertex shader?');
    throw new Error('SHADER_ERROR');
  }

  const vertexShaderCompileStatus = gl.getShaderParameter(vertexShader, gl.COMPILE_STATUS);

  if (!vertexShaderCompileStatus) {
    const vertexShaderInfoLog: string | null = gl.getShaderInfoLog(vertexShader);

    // eslint-disable-next-line no-console
    console.warn('Failed to compile vertex shader: ', vertexShaderInfoLog);
    throw new Error(`SHADER_ERROR: ${vertexShaderInfoLog}`);
  }

  if (!fragmentShader) {
    // eslint-disable-next-line no-console
    console.warn('Failed to compile fragment shader?');
    throw new Error('SHADER_ERROR');
  }

  const fragmentShaderCompileStatus = gl.getShaderParameter(fragmentShader, gl.COMPILE_STATUS);

  if (!fragmentShaderCompileStatus) {
    const fragmentShaderInfoLog: string | null = gl.getShaderInfoLog(vertexShader);

    // eslint-disable-next-line no-console
    console.warn('Failed to compile fragment shader: ', fragmentShaderInfoLog);
    throw new Error(`SHADER_ERROR: ${fragmentShaderInfoLog}`);
  }

  const program = createProgram(gl, vertexShader, fragmentShader);

  if (!program) {
    // eslint-disable-next-line no-console
    console.warn('Failed to create program?');
    throw new Error('SHADER_ERROR');
  }

  gl.useProgram(program);

  return program;
}

export default initShaders;
