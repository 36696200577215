// eslint-disable-next-line import/no-extraneous-dependencies
import type { ColorCorrectionSettings } from '@g360/vt-types';
import { identityM4, multiplyM4 } from '@g360/vt-utils';

import type { ColorCorrectionUniformSettings } from '../../types/internal';

function getColorCorrectionUniformSettings(values?: ColorCorrectionSettings): ColorCorrectionUniformSettings {
  if (!values)
    return {
      colorCorrectionMatrix: identityM4(),
      colorCorrectionOffset: [0, 0, 0, 0],
      colorBalanceVector: [1, 1, 1],
      shadows: 1, // 0..2; 1 - no change
      highlights: 1,
    };

  const { saturation, contrast, exposure, temperature, shadows, highlights } = values;
  const vTint = 0;

  const s = 1 + saturation;

  // https://www.w3.org/TR/WCAG20/#relativeluminancedef
  const lr = 0.2126;
  const lg = 0.7152;
  const lb = 0.0722;

  const sr = (1 - s) * lr;
  const sg = (1 - s) * lg;
  const sb = (1 - s) * lb;

  const saturationMatrix = [sr + s, sr, sr, 0, sg, sg + s, sg, 0, sb, sb, sb + s, 0, 0, 0, 0, 1];

  const c = 1 + contrast;
  const o = 0.5 * (1 - c);
  const contrastMatrix = [c, 0, 0, 0, 0, c, 0, 0, 0, 0, c, 0, 0, 0, 0, 1];
  const contrastOffset = [o, o, o, 0];

  const e = 1 + exposure;
  const exposureMatrix = [e, 0, 0, 0, 0, e, 0, 0, 0, 0, e, 0, 0, 0, 0, 1];

  const colorCorrectionMatrix = multiplyM4(multiplyM4(saturationMatrix, contrastMatrix), exposureMatrix);
  const colorCorrectionOffset = contrastOffset;

  // temp & tint

  // Range ~[-1.67;1.67] works best
  const t1 = (temperature * 10.0) / 6.0;
  const t2 = (vTint * 10.0) / 6.0;

  // Get the CIE xy chromaticity of the reference white point.
  // Note: 0.31271 = x value on the D65 white point
  const x = 0.31271 - t1 * (t1 < 0.0 ? 0.1 : 0.05);
  const standardIlluminantY = 2.87 * x - 3.0 * x * x - 0.27509507;
  const y = standardIlluminantY + t2 * 0.05;

  // Calculate the coefficients in the LMS space.
  const w1 = [0.949237, 1.03542, 1.08728]; // D65 white point

  // CIExyToLMS
  const Y = 1.0;
  const X = (Y * x) / y;
  const Z = (Y * (1.0 - x - y)) / y;
  const L = 0.7328 * X + 0.4296 * Y - 0.1624 * Z;
  const M = -0.7036 * X + 1.6975 * Y + 0.0061 * Z;
  const S = 0.003 * X + 0.0136 * Y + 0.9834 * Z;
  const w2 = [L, M, S];
  const colorBalanceVector = [w1[0] / w2[0], w1[1] / w2[1], w1[2] / w2[2]];

  return {
    colorCorrectionMatrix,
    colorCorrectionOffset,
    colorBalanceVector,
    shadows,
    highlights,
  };
}

export default getColorCorrectionUniformSettings;
