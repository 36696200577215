const basicIndices = new Uint16Array([0, 1, 2, 0, 2, 3]);

/**
 * Loads basic index vertices to a buffer.
 * @param gl - `WebGLRenderingContext`: An interface to the OpenGL ES 2.0 graphics rendering context for the drawing surface of an HTML `<canvas>` element.
 * @param basicIndexBuffer - `WebGLBuffer | null` - `Optional`: A buffer to load basic index vertices into. If not provided or `null`, a new buffer will be created.
 * @returns `WebGLBuffer | null`: The created buffer if `basicIndexBuffer` parameter was not provided or `null`, otherwise returns `basicIndexBuffer` parameter.
 */
function loadBasicVertexIndices(gl: WebGLRenderingContext, basicIndexBuffer?: WebGLBuffer | null): WebGLBuffer | null {
  const indexBuffer = basicIndexBuffer ?? gl.createBuffer();

  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
  gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, basicIndices, gl.STATIC_DRAW);

  return indexBuffer;
}

export default loadBasicVertexIndices;
