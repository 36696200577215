import { getCookie } from '../misc';
import destructureGeometricSequenceSum from '../misc/destructureGeometricSequenceSum';

type CookiePreferences = {
  isNecessary: boolean;
  isPerformance: boolean;
  isPreferences: boolean;
};

/**
 * Get the cookie preferences from the cookie
 * Assumes that the preferences are encoded as a geometric sequence sum, i.e. 1 + 2 + 4 = 7
 * @param cookieName - name of the cookie to get the preferences from
 * @returns - object with the preferences
 * @example
 * getCookiePreferences('cookie_preferences')
 * // => { isNecessary: true, isPerformance: true, isPreferences: true }
 */
const getCookiePreferences = (cookieName: string): CookiePreferences | null => {
  const preferencesMap = {
    isNecessary: 1,
    isPerformance: 2,
    isPreferences: 4,
  };

  const cookie = getCookie(cookieName);
  if (!cookie) return null;

  const [preference] = cookie.split('_');
  const preferenceNumber = parseInt(preference, 10);
  const geometricSequence = destructureGeometricSequenceSum(preferenceNumber);

  return Object.entries(preferencesMap).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: geometricSequence.includes(value),
    }),
    {}
  ) as CookiePreferences;
};

export default getCookiePreferences;
