import { GiraffeLogoIcon } from "components/icons";
import { use100vh } from "hooks";
import { FC } from "react";

interface InfoPageLayoutProps {
  headLine: string;
  btn?: JSX.Element;
  paragraph?: string;
  icon?: JSX.Element;
}

const InfoPageLayout: FC<InfoPageLayoutProps> = ({
  btn,
  icon,
  headLine,
  paragraph,
}) => {
  const { divRef } = use100vh();

  return (
    <div ref={divRef} className="flex flex-col justify-between w-full">
      <div className="w-11/12 mx-auto m-3.5 md:t-5 md:w-4/5 lg:w-5/6 md:mb-0">
        <GiraffeLogoIcon className="h-10" />
      </div>
      <div className="flex items-center justify-center max-w-[514px] mx-auto transition-all duration-300 ease-in-out px-5 h-screen">
        <div className="flex flex-col items-center justify-center text-center mb-40">
          {icon && <div className="mb-4">{icon}</div>}
          <h2>{headLine}</h2>
          {paragraph && (
            <p className="text-center text-giraffe-gray-darkest max-w-[480px] mt-4 text-sm">
              {paragraph}
            </p>
          )}
          {btn && (
            <div className="mt-5 w-[180px] flex items-center justify-center">
              {btn}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfoPageLayout;
