/* eslint-disable no-continue */
import type { FPMesh, FPPoint2D, FPPoint3D, FPRay, FPTriangle, Pixel } from '@g360/vt-types';
import clamp from 'lodash/clamp';

import BVHRaycaster from './BVH';
import { PixelHolder } from './PixelHolder';

const GOLDEN_RATIO = (1 + Math.sqrt(5)) / 2;

export function alignSampleToNormal(sample: FPPoint3D, normal: FPPoint3D): FPPoint3D {
  const [nx, ny, nz] = normal;
  let tx: number;
  let ty: number;
  let tz: number;

  if (Math.abs(nx) > Math.abs(ny)) {
    const invLen = 1 / Math.sqrt(nx * nx + nz * nz);
    tx = -nz * invLen;
    ty = 0;
    tz = nx * invLen;
  } else {
    const invLen = 1 / Math.sqrt(ny * ny + nz * nz);
    tx = 0;
    ty = nz * invLen;
    tz = -ny * invLen;
  }

  const bx = ny * tz - nz * ty;
  const by = nz * tx - nx * tz;
  const bz = nx * ty - ny * tx;

  const [sx, sy, sz] = sample;

  return [sx * tx + sy * bx + sz * nx, sx * ty + sy * by + sz * ny, sx * tz + sy * bz + sz * nz];
}

export function generateCosineWeightedHemisphereSamples(numSamples: number): FPPoint3D[] {
  const samples: FPPoint3D[] = [];
  for (let i = 0; i < numSamples; i += 1) {
    const u = (i + 0.5) / numSamples;
    const v = (i * GOLDEN_RATIO) % 1;

    const phi = 2 * Math.PI * v;
    const cosTheta = Math.sqrt(1 - u);
    const sinTheta = Math.sqrt(u);

    const x = Math.cos(phi) * sinTheta;
    const y = Math.sin(phi) * sinTheta;
    const z = cosTheta;

    samples.push([x, y, z]);
  }

  // Fisher-Yates shuffle with a deterministic seed
  for (let i = numSamples - 1; i > 0; i -= 1) {
    const j = Math.floor((i + 1) * ((i * 3 + 7) % 1)); // "random"
    [samples[i], samples[j]] = [samples[j], samples[i]];
  }

  return samples;
}

export function calculateAO(
  raycaster: BVHRaycaster,
  surfacePoint: FPPoint3D,
  normal: FPPoint3D,
  hemisphereSamples: FPPoint3D[],
  maxDistance: number
): number {
  let accumulatedOcclusion = 0;
  const numSamples = hemisphereSamples.length;

  // a) num back hits in a row -- lets exit quickly
  // b) num back hits total -- no quick exit, but more reliable -- still not reliable enough and no fast exit :(
  // c) [mind_blown.gif] don't check em here, don't let raycaster to see back walls

  for (let i = 0; i < numSamples; i += 1) {
    const rayDir = alignSampleToNormal(hemisphereSamples[i], normal);
    const rayDirInv = [1 / rayDir[0], 1 / rayDir[1], 1 / rayDir[2]];

    const ray: FPRay = { origin: surfacePoint, direction: rayDir, directionInv: rayDirInv };
    const intersectionDistance = raycaster.rayIntersectsGeometry(ray);

    if (intersectionDistance !== null) {
      const occlusion = 1 - Math.min(intersectionDistance / maxDistance, 1);
      accumulatedOcclusion += occlusion;
    }
  }

  return accumulatedOcclusion / numSamples;
}

export function calculateBarycentricCoordinates(
  p: FPPoint2D,
  a: FPPoint2D,
  b: FPPoint2D,
  c: FPPoint2D
): [number, number, number] {
  const [px, py] = p;
  const [ax, ay] = a;
  const [bx, by] = b;
  const [cx, cy] = c;
  const v0x = bx - ax;
  const v0y = by - ay;
  const v1x = cx - ax;
  const v1y = cy - ay;
  const v2x = px - ax;
  const v2y = py - ay;
  const d00 = v0x * v0x + v0y * v0y;
  const d01 = v0x * v1x + v0y * v1y;
  const d11 = v1x * v1x + v1y * v1y;
  const d20 = v2x * v0x + v2y * v0y;
  const d21 = v2x * v1x + v2y * v1y;
  const denom = d00 * d11 - d01 * d01;
  const v = (d11 * d20 - d01 * d21) / denom;
  const w = (d00 * d21 - d01 * d20) / denom;
  const u = 1.0 - v - w;
  return [u, v, w];
}

export function isPointInOrOnTriangle(
  p: FPPoint2D,
  a: FPPoint2D,
  b: FPPoint2D,
  c: FPPoint2D,
  textureSize: number
): boolean {
  const [b1, b2, b3] = calculateBarycentricCoordinates(p, a, b, c);
  const epsilon = 0.5 / textureSize;
  return b1 >= -epsilon && b2 >= -epsilon && b3 >= -epsilon && b1 + b2 + b3 <= 1 + epsilon;
}

export function colorUVPointOnImage(
  position: FPPoint3D,
  uv: FPPoint2D,
  normal: FPPoint3D,
  skip: boolean,
  textureSize: number,
  pixelHolder: PixelHolder,
  raycaster: BVHRaycaster,
  hemisphereSamples: FPPoint3D[],
  maxDistance: number,
  triangleId: number
) {
  const roundUvX = Math.round(uv[0] * textureSize);
  const roundUvY = Math.round(uv[1] * textureSize);
  const existingAOValue = pixelHolder.getPixel(roundUvX, roundUvY);

  // debug
  if (Math.PI === 3) {
    pixelHolder.setPixel(roundUvX, roundUvY, Math.round(triangleId ** 2 % 200) + 55);
    return;
  }

  // explicitly skipped -- mark as skipped if pixel not written already
  if (skip && pixelHolder.isThisColorFree(existingAOValue)) {
    pixelHolder.setPixel(roundUvX, roundUvY, pixelHolder.skippedColor);
    return;
  }

  // normal mode -- write if pixel not written already
  if (pixelHolder.isThisColorFree(existingAOValue)) {
    const aoValue = calculateAO(raycaster, position, normal, hemisphereSamples, maxDistance);
    const ao255 = clamp(Math.round((1 - aoValue) * 255), 0, 255);
    pixelHolder.setPixel(roundUvX, roundUvY, ao255);
  }
}

const directions = [
  { dx: 0, dy: 1 },
  { dx: 1, dy: 0 },
];

export function tryColorPointOnImageFromNeighbors(
  x: Pixel,
  y: Pixel,
  uvs: [FPPoint2D, FPPoint2D, FPPoint2D],
  textureSize: number,
  pixelHolder: PixelHolder
) {
  for (let i = 0; i < directions.length; i += 1) {
    const { dx, dy } = directions[i];
    const prevX = x + dx;
    const prevY = y + dy;
    const nextX = x - dx;
    const nextY = y - dy;

    if (!isPointInOrOnTriangle([prevX / textureSize, prevY / textureSize], uvs[0], uvs[1], uvs[2], textureSize))
      continue;
    if (!isPointInOrOnTriangle([nextX / textureSize, nextY / textureSize], uvs[0], uvs[1], uvs[2], textureSize))
      continue;

    const prevPixel = pixelHolder.getPixel(prevX, prevY);
    const nextPixel = pixelHolder.getPixel(nextX, nextY);

    if (prevPixel === nextPixel && !pixelHolder.isThisColorFree(prevPixel)) {
      pixelHolder.setPixel(x, y, prevPixel);
      return;
    }
  }
}

export function findMinMax(pixelCoords: number[][]) {
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;

  for (let i = 0; i < pixelCoords.length; i += 1) {
    const x = pixelCoords[i][0];
    const y = pixelCoords[i][1];

    if (x < minX) minX = x;
    if (y < minY) minY = y;
    if (x > maxX) maxX = x;
    if (y > maxY) maxY = y;
  }

  return { minX, minY, maxX, maxY };
}

export function makeTriangles(meshes: FPMesh[]) {
  const triangles: FPTriangle[] = [];
  for (let i = 0; i < meshes.length; i += 1) {
    const mesh = meshes[i];
    for (let t = 0; t < mesh.triangles.length; t += 3) {
      // typecasting slices of Array32 as Point3D and Point2D, it's the same interface as far as the next functions are concerned
      const v0 = mesh.positions.slice(mesh.triangles[t] * 3, mesh.triangles[t] * 3 + 3) as unknown as FPPoint3D;
      const v1 = mesh.positions.slice(mesh.triangles[t + 1] * 3, mesh.triangles[t + 1] * 3 + 3) as unknown as FPPoint3D;
      const v2 = mesh.positions.slice(mesh.triangles[t + 2] * 3, mesh.triangles[t + 2] * 3 + 3) as unknown as FPPoint3D;
      const uv0 = mesh.texCoords.slice(mesh.triangles[t] * 2, mesh.triangles[t] * 2 + 2) as unknown as FPPoint2D;
      const uv1 = mesh.texCoords.slice(
        mesh.triangles[t + 1] * 2,
        mesh.triangles[t + 1] * 2 + 2
      ) as unknown as FPPoint2D;
      const uv2 = mesh.texCoords.slice(
        mesh.triangles[t + 2] * 2,
        mesh.triangles[t + 2] * 2 + 2
      ) as unknown as FPPoint2D;
      const triangle: FPTriangle = {
        vertices: [v0, v1, v2],
        uvs: [uv0, uv1, uv2],
        normal: mesh.normals.slice(mesh.triangles[t] * 3, mesh.triangles[t] * 3 + 3) as unknown as FPPoint3D,
        meshId: mesh.unqId,
      };
      triangles.push(triangle);
    }
  }

  return triangles;
}

export function calculateTextureArea(texCoords: Float32Array): number {
  let minU = Infinity;
  let maxU = -Infinity;
  let minV = Infinity;
  let maxV = -Infinity;

  // Iterate through the texture coordinates
  for (let i = 0; i < texCoords.length; i += 2) {
    const u = texCoords[i];
    const v = texCoords[i + 1];

    minU = Math.min(minU, u);
    maxU = Math.max(maxU, u);
    minV = Math.min(minV, v);
    maxV = Math.max(maxV, v);
  }

  // Calculate the area of the bounding box in UV space
  const width = maxU - minU;
  const height = maxV - minV;
  return width * height;
}

export function sortMeshesByTextureSize(meshes: FPMesh[]): FPMesh[] {
  return meshes.sort((a, b) => {
    const areaA = calculateTextureArea(a.texCoords);
    const areaB = calculateTextureArea(b.texCoords);
    return areaB - areaA;
  });
}

export function calculateAverageCmPerUV(mesh: FPMesh): number {
  const { positions, texCoords } = mesh;
  let totalRatio = 0;
  let count = 0;

  for (let i = 0; i < positions.length - 3; i += 3) {
    const pos1 = {
      x: positions[i],
      y: positions[i + 1],
      z: positions[i + 2],
    };
    const pos2 = {
      x: positions[i + 3],
      y: positions[i + 4],
      z: positions[i + 5],
    };

    const uv1 = {
      u: texCoords[(i / 3) * 2],
      v: texCoords[(i / 3) * 2 + 1],
    };
    const uv2 = {
      u: texCoords[(i / 3) * 2 + 2],
      v: texCoords[(i / 3) * 2 + 3],
    };

    const distance3D = Math.sqrt((pos2.x - pos1.x) ** 2 + (pos2.y - pos1.y) ** 2 + (pos2.z - pos1.z) ** 2);

    const distanceUV = Math.sqrt((uv2.u - uv1.u) ** 2 + (uv2.v - uv1.v) ** 2);

    if (distanceUV > 0) {
      totalRatio += distance3D / distanceUV;
      count += 1;
    }
  }

  // Calculate the average ratio
  return count > 0 ? totalRatio / count : 0;
}

type AreaStatistics = {
  mean: number;
  median: number;
  min: number;
  max: number;
  details: { name: string; area: number }[];
};

export function calculateAreaStatistics(meshes: FPMesh[]): AreaStatistics {
  const areas = meshes.map((mesh) => ({
    name: mesh.name,
    area: calculateAverageCmPerUV(mesh),
  }));

  areas.sort((a, b) => a.area - b.area);

  const sum = areas.reduce((acc, curr) => acc + curr.area, 0);
  const mean = sum / areas.length;
  const median =
    areas.length % 2 === 0
      ? (areas[areas.length / 2 - 1].area + areas[areas.length / 2].area) / 2
      : areas[Math.floor(areas.length / 2)].area;
  const min = areas[0].area;
  const max = areas[areas.length - 1].area;

  return { mean, median, min, max, details: areas };
}

export function printAreaStatistics(statistics: AreaStatistics) {
  console.log(`Mean area: ${statistics.mean.toFixed(2)} cm² per UV unit`);
  console.log(`Median area: ${statistics.median.toFixed(2)} cm² per UV unit`);
  console.log(`Min area: ${statistics.min.toFixed(2)} cm² per UV unit (${statistics.details[0].name})`);
  console.log(`Max area: ${statistics.max.toFixed(2)} cm² per UV unit (${statistics.details[statistics.details.length - 1].name})`); // prettier-ignore

  // console.log('\nDetailed breakdown:');
  // statistics.details.forEach(({ name, area }) => {
  //   console.log(`${name}: ${area.toFixed(2)} cm² per UV unit`);
  // });
}
