import { MediaDeviceButton } from "components/elements";
import { AudioVolumeTestWrapper } from "components/wrappers";
import { Dropdown } from "components/layout";
import { FC, useState } from "react";
import getDeviceName from "utils/getDeviceName";
import { useMixpanelContext } from "hooks";

interface AudioSettingWrapperProps {
  audioInputDevices: MediaDeviceInfo[];
  audioOutputDevices: MediaDeviceInfo[];
  selectedAudioInput: MediaDeviceInfo | null | undefined;
  selectedAudioOutput: MediaDeviceInfo | null | undefined;
  setSelectedAudioInput: (input: MediaDeviceInfo) => void;
  setSelectedAudioOutput: (output: MediaDeviceInfo) => void;
}

const AudioSettingWrapper: FC<AudioSettingWrapperProps> = ({
  audioInputDevices,
  audioOutputDevices,
  selectedAudioInput,
  selectedAudioOutput,
  setSelectedAudioInput,
  setSelectedAudioOutput,
}) => {
  const { Mixpanel, MixpanelEvents } = useMixpanelContext();
  const [isMicrophoneDropdown, setIsMicrophoneDropdown] =
    useState<boolean>(false);
  const [isSpeakerDropdown, setIsSpeakerDropdown] = useState<boolean>(false);

  const displayAudioInputName = () => {
    if (selectedAudioInput) {
      return getDeviceName(selectedAudioInput).name;
    }

    return "Selected Microphone";
  };

  const displayAudioOutputName = () => {
    if (selectedAudioOutput) {
      return getDeviceName(selectedAudioOutput).name;
    }

    return "Selected Speaker";
  };

  return (
    <>
      {audioOutputDevices.length >= 1 && (
        <div className="flex flex-col justify-between w-full md:mb-8 mb-2 md:items-center md:flex-row">
          <div className="flex items-center justify-start md:justify-center mb-2.5 md:mb-0 pl-5 mt-5 md:mt-0">
            <p className="text-sm font-primary leading-5 tracking-[1%]">
              Speakers
            </p>
          </div>
          <Dropdown
            bgColor="white"
            buttonSize="full"
            dropDownWidth="full"
            isDropdownArrow={true}
            customNames="w-full md:w-80"
            isDropdownOpen={isSpeakerDropdown}
            handleDropdownClose={() => {
              setIsSpeakerDropdown(false);
            }}
            handleDropdownButtonClick={() => {
              Mixpanel.track(MixpanelEvents.Dropdown.Open("Speakers"));
              setIsSpeakerDropdown((state) => !state);
            }}
            bntTitle={displayAudioOutputName()}
          >
            <div className="w-full py-2">
              {audioOutputDevices.map((audioOutput) => {
                return (
                  <MediaDeviceButton
                    key={audioOutput.deviceId}
                    title={getDeviceName(audioOutput).name}
                    handleClick={() => {
                      Mixpanel.track(
                        MixpanelEvents.Dropdown.ItemClick(
                          getDeviceName(audioOutput).name || "Unknown"
                        )
                      );
                      setIsSpeakerDropdown(false);
                      setSelectedAudioOutput(audioOutput);
                    }}
                  />
                );
              })}
            </div>
          </Dropdown>
        </div>
      )}
      {audioInputDevices.length >= 1 && (
        <div className="flex flex-col justify-between w-full md:mb-8 mb-2 md:items-center md:flex-row">
          <div className="flex items-center justify-start md:justify-center mb-2.5 md:mb-0 pl-5 mt-5 md:mt-0">
            <p className="text-sm font-primary leading-5 tracking-[1%]">
              Microphone
            </p>
          </div>
          <Dropdown
            bgColor="white"
            buttonSize="full"
            dropDownWidth="full"
            isDropdownArrow={true}
            customNames="w-full md:w-80"
            isDropdownOpen={isMicrophoneDropdown}
            handleDropdownClose={() => {
              setIsMicrophoneDropdown(false);
            }}
            handleDropdownButtonClick={() => {
              Mixpanel.track(MixpanelEvents.Dropdown.Open("Microphone"));
              setIsMicrophoneDropdown((state) => !state);
            }}
            bntTitle={displayAudioInputName()}
          >
            <div className="w-full py-2">
              {audioInputDevices.map((audioInput) => {
                return (
                  <MediaDeviceButton
                    key={audioInput.deviceId}
                    title={getDeviceName(audioInput).name}
                    handleClick={() => {
                      Mixpanel.track(
                        MixpanelEvents.Dropdown.ItemClick(
                          getDeviceName(audioInput).name || "Unknown"
                        )
                      );
                      setIsMicrophoneDropdown(false);
                      setSelectedAudioInput(audioInput);
                    }}
                  />
                );
              })}
            </div>
          </Dropdown>
        </div>
      )}
      {audioInputDevices.length >= 1 && (
        <div className="flex flex-col justify-between w-full md:mb-8 mb-2 md:items-center md:flex-row">
          <div className="flex items-center justify-start md:justify-center mb-2.5 md:mb-0 pl-5 mt-5 md:mt-0">
            <p className="text-sm font-primary leading-5 tracking-[1%]">
              Input Level
            </p>
          </div>

          <AudioVolumeTestWrapper />
        </div>
      )}
    </>
  );
};

export default AudioSettingWrapper;
