import { getCookie, getRandomId, setCookie } from '../misc';

let createdSessionId = '';

/**
 * Get or create a session id.
 *
 * On the first call, it will try to get session id from cookies.
 *
 * If the session id is already set in the cookie, it will return that.
 *
 * If the session id is not set in the cookie, it will generate a random id, store it in memory and set it in the cookie.
 *
 * On consequent calls, it will either return session id from cookies, or the one stored in memory.
 *
 * Cookies can be rejected for some reason, so if saving the cookie fails,
 * we store the generated session id in memory and use that, to avoid creating a new id on each call.
 *
 * @returns the session id that was set in the cookie, or a new one.
 */
export default function getOrCreateSessionId(): string {
  if (createdSessionId) return createdSessionId;

  let sessionId = getCookie('sid');
  if (!sessionId) sessionId = getRandomId(32);
  // TODO: this will reset cookies for all people for whom they are already set. Need to reset, because the pre cookie does not have the correct domain.
  setCookie({ name: 'sid', value: sessionId, days: 365 * 20 }); // 20 years
  createdSessionId = sessionId;
  return sessionId;
}
