export type AccessMode = 'public' | 'sign' | 'password' | 'token';

export type SignBody = {
  url: string;
  entrypoint: string;
  password?: string;
  token?: string;
};

export type AccessData = {
  isProtected?: boolean;
  token?: string;
};

export type ResponseBody = {
  Policy: string;
  Signature: string;
  'Key-Pair-Id': string;
};

type SignErrorType =
  | 'MANDATORY_PARAMS_NOT_PROVIDED'
  | 'NO_TOKEN'
  | 'NO_PASSWORD'
  | 'FORBIDDEN'
  | 'INVALID_PASSWORD'
  | 'INVALID_TOKEN'
  | 'MODE_NOT_SUPPORTED'
  | 'NETWORK_ERROR';

export class SignError extends Error {
  public statusCode?: number;
  public name: SignErrorType;
  constructor(name: SignErrorType, message?: string, statusCode?: number, options?: ErrorOptions) {
    super(message, options);
    this.name = name;
    this.statusCode = statusCode;
  }
}

const resAsJson = (res: Pick<Response, 'status' | 'json'>) =>
  res
    .json()
    .then((result) => ({ error: null, result }))
    .catch((error) => ({ error, result: null }));

const forbiddenMap: Record<AccessMode, SignErrorType> = {
  token: 'INVALID_TOKEN',
  password: 'INVALID_PASSWORD',
  public: 'FORBIDDEN',
  sign: 'FORBIDDEN',
};

export async function accessResponseParser(
  res: Pick<Response, 'status' | 'json'>,
  mode: AccessMode = 'sign'
): Promise<ResponseBody> {
  if (res.status === 403) {
    throw new SignError(forbiddenMap[mode] || 'FORBIDDEN', 'Forbidden response', res.status);
  }

  const { result, error } = await resAsJson(res);

  if (error) {
    throw new SignError('NETWORK_ERROR', error && error.message ? error.message : error || 'unknown error', res.status);
  }

  if (res.status === 200) {
    return result;
  }

  throw new SignError('NETWORK_ERROR', 'invalid status code', res.status);
}
// remove export
export function signRequest(endpoint: string, body: SignBody, mode: AccessMode): Promise<ResponseBody> {
  const fetchWithRetry = (retryCount: number): Promise<ResponseBody> =>
    fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify(body),
      credentials: 'include',
    })
      .then((res) => accessResponseParser(res, mode))
      .catch((err) => {
        if (retryCount > 0) {
          return fetchWithRetry(retryCount - 1);
        }

        throw new SignError('NETWORK_ERROR', 'failed to fetch - it might be a CORS issue', undefined, {
          cause: err,
        });
      });

  return fetchWithRetry(1);
}

export function getAccessMode(isProtected?: boolean, token?: string): AccessMode {
  if (isProtected === undefined && !token) return 'public';

  if (isProtected) return 'password';

  if (token) return 'token';

  return 'sign';
}

// the server responds with Set-Cookie
export async function signTourAndSetCookie(
  signEndpoint: string,
  mode: AccessMode,
  { url, entrypoint, password, token }: SignBody
) {
  if (!url || !entrypoint || !signEndpoint)
    throw new SignError('MANDATORY_PARAMS_NOT_PROVIDED', 'url, entrypoint and signEndpoint are mandatory');

  switch (mode) {
    case 'public':
      return {};

    case 'sign':
      return signRequest(signEndpoint, { url, entrypoint }, mode);

    case 'token':
      if (!token) throw new SignError('NO_TOKEN', 'mode is "token" but token is not provided');
      return signRequest(signEndpoint, { url, entrypoint, token }, mode);

    case 'password':
      if (!password) throw new SignError('NO_PASSWORD', 'mode is "password" but password is not provided');
      return signRequest(signEndpoint, { url, entrypoint, password }, mode);

    default:
      throw new SignError('MODE_NOT_SUPPORTED', `mode '${mode}' is not supported`);
  }
}

// the server responds with the cloudfront keys that need to be added in all the request query params
export async function signTourAndGetKeys(
  signEndpoint: string,
  mode: AccessMode,
  { url, entrypoint, password, token }: SignBody
): Promise<ResponseBody | Record<string, string>> {
  if (!url || !entrypoint || !signEndpoint)
    throw new SignError('MANDATORY_PARAMS_NOT_PROVIDED', 'url, entrypoint and signEndpoint are mandatory');

  switch (mode) {
    case 'public':
      return Promise.resolve({});

    case 'sign':
      return signRequest(signEndpoint, { url, entrypoint }, mode);

    case 'token':
      if (!token) throw new SignError('NO_TOKEN', 'mode is "token" but token is not provided');
      return signRequest(signEndpoint, { url, entrypoint, token }, mode);

    case 'password':
      if (!password) throw new SignError('NO_PASSWORD', 'mode is "password" but password is not provided');
      return signRequest(signEndpoint, { url, entrypoint, password }, mode);

    default:
      throw new SignError('MODE_NOT_SUPPORTED', `mode '${mode}' is not supported`);
  }
}
