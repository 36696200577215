import { timer } from "func";
import { useAppStateContext, useRoomContext } from "hooks";
import { useCallback, useEffect, useRef, useState } from "react";

const RETRIES = 25;
const RETRY_DELAY = 750;

const useHandshake = (
  handleUserRespoinding: (identity: string) => void,
  handleUserNotRespoinding: (identity: string) => void
) => {
  const { isHost } = useAppStateContext();
  const { twilioMessageEmitter, localParticipant } = useRoomContext();

  const [perndingHandshakeUserIdentities, setPendingHandshakeUserIdentities] =
    useState<string[]>([]);
  const pendingHandshakeRetries = useRef<
    { identity: string; retries: number }[]
  >([]);

  const getPendingHandshakeRetryCount = () => {
    const retries = pendingHandshakeRetries.current.map(
      (handshake) => handshake.retries
    );
    return retries.length > 0 ? Math.max(...retries) : 0;
  };

  const performHandshake = useCallback(
    (identity: string) => {
      if (!isHost) return;

      setPendingHandshakeUserIdentities((prevIdentities) => [
        ...prevIdentities,
        identity,
      ]);
      pendingHandshakeRetries.current = [
        ...pendingHandshakeRetries.current,
        { identity, retries: RETRIES },
      ];
    },
    [setPendingHandshakeUserIdentities, isHost]
  );

  const removePendingHandshake = useCallback(
    (identity: string) => {
      setPendingHandshakeUserIdentities((prevIdentities) =>
        prevIdentities.filter((i) => i !== identity)
      );
      pendingHandshakeRetries.current = pendingHandshakeRetries.current.filter(
        (handshake) => handshake.identity !== identity
      );
    },
    [setPendingHandshakeUserIdentities]
  );

  useEffect(() => {
    const executeHandshakes = async () => {
      if (!perndingHandshakeUserIdentities.length) return;
      while (getPendingHandshakeRetryCount() > 1) {
        pendingHandshakeRetries.current.forEach((handshake) => {
          if (handshake.retries === 1) {
            handleUserNotRespoinding(handshake.identity);
            removePendingHandshake(handshake.identity);
          }

          twilioMessageEmitter.emit("handshake.ping.send", handshake.identity);
          handshake.retries -= 1;
        });

        await timer(RETRY_DELAY);
      }
    };

    executeHandshakes();
  }, [
    perndingHandshakeUserIdentities,
    handleUserNotRespoinding,
    twilioMessageEmitter,
    removePendingHandshake,
  ]);

  useEffect(() => {
    const subscriptions = [
      twilioMessageEmitter.subscribe(
        "handshake.ping.receive",
        (pingIdentity) => {
          if (pingIdentity === localParticipant?.identity) {
            twilioMessageEmitter.emit("handshake.pong.send", pingIdentity);
          }
        }
      ),
      twilioMessageEmitter.subscribe(
        "handshake.pong.receive",
        (pongIdentity) => {
          if (perndingHandshakeUserIdentities.includes(pongIdentity)) {
            handleUserRespoinding(pongIdentity);
            removePendingHandshake(pongIdentity);
          }
        }
      ),
    ];

    return () =>
      subscriptions.forEach((subscription) => subscription.unsubscribe());
  }, [
    twilioMessageEmitter,
    localParticipant,
    perndingHandshakeUserIdentities,
    handleUserRespoinding,
    removePendingHandshake,
  ]);

  return {
    performHandshake,
  };
};

export default useHandshake;
