import type { ComponentType } from 'react';

type Props = { className?: string };

const asIconWrapper = <P extends object>(WrappedComponent: ComponentType<P>) => {
  const Icon = ({ className, ...props }: Props & P) => <WrappedComponent {...(props as P)} className={className} />;

  return Icon;
};

export default asIconWrapper;
