import React from 'react';
// Provide name for better error reporting
export default function bindUseContext(bindableContext, name = 'Unknown Context') {
    return () => {
        const context = React.useContext(bindableContext);
        if (context === undefined) {
            throw new Error(`${name} must be within its Context.Provider`);
        }
        return context;
    };
}
