import { useCallback, useRef } from "react";

const useFocus = <T extends HTMLElement>() => {
  const htmlElRef = useRef<T>(null);
  const setFocus = useCallback(() => {
    htmlElRef.current && htmlElRef.current.focus();
  }, [htmlElRef]);

  return [htmlElRef, setFocus] as const;
};

export default useFocus;
